export const userTypes = [
    {
    name:"Cliente",
    value:"CLIENT"
  },
    {
    name:"Testing",
    value:"TESTING"
  },
    {
    name:"Interno",
    value:"INTERNAL"
  },
]