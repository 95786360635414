import empty from 'is-empty';
import UtilsHelper from '../../helpers/Utils.helper';
const queryString = require('query-string');

export const getWithdraws = async (userId, params) => {
  try {
    let path = '/api/withdrawTickets';

    if (!empty(params)) {
      path += '?' + queryString.stringify(params);
    }
    const configRequest = {
      userId,
      method: 'get',
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return response.data.allTickets;
  } catch (error) {
    throw error;
  }
};

export const getWithdrawTicket = async (userId, accountId, requestCode) => {
  try {
    const path = `/api/withdrawTickets/${accountId}/${requestCode}`;
    const configRequest = {
      userId,
      method: 'get',
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
};

export const submitComment = async (userId, data) => {
  const path = '/api/withdrawTickets/comment';
  const model = {
    comment: data.comment,
    ticketCode: data.ticketCode,
    ticketId: data.ticketId
  };
  return UtilsHelper.sendRequest({
    userId,
    method: 'post',
    path,
    data: model
  });
};

export const acceptWithdraw = async (userId, data) => {
  const path = `/api/withdrawTickets/accept`;
  const configRequest = {
    userId,
    method: 'post',
    path,
    data,
    headers:{
      'Content-Type': 'multipart/form-data'
    }
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const updatetWithdraw = async (userId, id, data) => {
  const path = `/api/withdraw-ticket/log/${id}`;
  const configRequest = {
    userId,
    method: 'put',
    path,
    data,
    headers:{
      'Content-Type': 'multipart/form-data'
    }
  };
  return await UtilsHelper.sendRequest(configRequest);
};


export const rejectWithdraw = async (userId, data) => {  
  const path = '/api/withdrawTickets/reject';
  const configRequest = {
    userId,
    method: 'post',
    path,
    data,
    headers:{
      'Content-Type': 'multipart/form-data'
    }
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const getLevelByAccountId = async (userId, accountId) => {
  try {
    const path = `/api/levels/accountid/${accountId}`;
    const configRequest = {
      userId,
      method: 'get',
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
};

export const bankTransactions = async (userId, data) => {
  const path = '/api/services/bank/transactions';
  return UtilsHelper.sendRequest({
    userId,
    method: 'POST',
    data,
    path
  }).catch(e => {
    throw e;
  });
};

export const freezeBankTransactions = async (userId, data) => {
  const path = '/api/services/bank/freezedeposit';
  return UtilsHelper.sendRequest({
    userId,
    method: 'POST',
    data,
    path
  }).catch(e => {
    throw e;
  });
};

export const getBankListByCurrencyCode = async (userId,currencyCode) => {  
  const path = `/api/v1/banexcoin/banks`;
  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data: {currencyCode}
  }).catch(e => {
    throw e;
  });
};
