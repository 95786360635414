import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import Download from '../../../images/new_icons/download.svg';
import { GroupFilters } from "../Filters/Filters";
import { tableHeaders, toastTypes } from "../../../constants";
import xlsx from "json-as-xlsx";
import ComponentsHelper from "../../../helpers/Components.helper";
import isEmpty from "is-empty";
import DateHelper from "../../../helpers/Date.helper";
import './allProofPaymentData.css'

const AllProofPaymentData = (props, context) => {
  const {
    totalRows,
    rows,
    loading,
    setFilterSearch,
    resetData
  } = props;
  const initialState = {
    from: "",
    to: "",
  }
  const [filterInputs, setFilterInputs] = useState(initialState);
  const [showFilters, setShowFilters] = useState(true);
  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon:Download,
      action: () => downloadNewExcelFormat()
    },
    {
      icon: RotateRight,
      action: () => {
        setFilterInputs(initialState)
        resetData()
      },
    },
  ];

  const handleSearchFilters = () => {
    if(isEmpty(filterInputs.to) || isEmpty(filterInputs.from)){
      ComponentsHelper.toast({type:toastTypes.warn,message:"Ingrese un rango de fechas adecuado"})
      return
    }
    setFilterSearch(filterInputs)
  };
  
  const downloadNewExcelFormat = () => {
    const columns = tableHeaders.map(item => ({value:item.tag,label:item.title}))
    const data = [
       {
          sheet: "Datos",
          columns,
          content: rows
       }
    ];
    const settings = {
       fileName: `comprobantes_pago_${DateHelper.formatDateToYear(filterInputs.to)}_${DateHelper.formatDateToYear(filterInputs.to)}`,
       extraLength: 10,
       writeOptions: {}
    };
    xlsx(data, settings)
 }

  const filters = [
    {
      values: {
        title: "Fecha de inicio",
        name: "from",
        value: filterInputs.from,
        type: "date",
        placeholder: "Seleccione una fecha",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Fecha de fin",
        name: "to",
        value: filterInputs.to,
        type: "date",
        placeholder: "Seleccione una fecha",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
  ];

  return (
    <div className="all-container-data-admin-v2 proof-payment-data">
      <HeaderPage
        title={context.t("Comprobantes de pago")}
        subTitle="Finanzas / Comprobantes de pago"
        headerOptions={headerOptions}
      />
      <TableContainer
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        headers={tableHeaders}
        rowIdSelected={" "}
        enablePagination={false}
      >
        {showFilters && (
          <GroupFilters
            inputs={filters}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllProofPaymentData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllProofPaymentData);
