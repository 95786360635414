import React from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Button, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { optionsStatusAcuantLogs } from './../../../helpers/constants';

const TableContainerBeta = (props, context) => {
  const { rows, loadRows, downloadRows, filterConfig, filterSearch, changePage, onChangeLimit, searchData, onChangeField } = props;

  const limit = filterConfig.limit;
  const totalItems = filterConfig.totalCount;
  const currentPage = filterConfig.page;
  const offset = (currentPage - 1) * limit + 1;
  const showingTo = currentPage * limit > totalItems ? totalItems : currentPage * limit;

  if (empty(context)) {
    return null;
  }

  const PaginationField = () => {
    return (
      <div className="row react-bootstrap-table-pagination">
        <div className="col-md-3 col-xs-3 col-sm-3 col-lg-3">
          <span className="react-bootstrap-table-pagination-total">
            {context.t(
              'Showing {offset} to {showingTo} of {totalCount} Results',
              {
                offset: offset,
                showingTo: showingTo,
                totalCount: filterConfig.totalCount
              }
            )}
          </span>
        </div>
        <div className="col-md-3 col-xs-3 col-sm-3 col-lg-3">
          <span className="react-bootstrap-table-pagination-limit">
            <b>{context.t("Number of records per page")}</b>
            <select onChange={onChangeLimit} defaultValue={limit}>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="400">400</option>
            </select>
          </span>
        </div>
        <div className="react-bootstrap-table-pagination-list col-md-4 col-xs-4 col-sm-4 col-lg-4">
          <Pagination
            innerClass="pagination react-bootstrap-table-page-btns-ul"
            itemClass="page-item"
            linkClass="page-link"
            activePage={currentPage}
            itemsCountPerPage={limit}
            totalItemsCount={totalItems || 0}
            pageRangeDisplayed={5}
            onChange={changePage.bind(this)}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="table-banexcoin">
        <Col xl="12">
          <div
            style={{
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              paddingBottom: '20px'
            }}>
            <Button
              variant="info"
              onClick={() => {
                loadRows();
              }}>
              {context.t('Update table')}
            </Button>
            <Button
              variant="info"
              style={{marginLeft: '1rem'}}
              onClick={() => {
                downloadRows();
              }}>
              {context.t('Download Excel')}
            </Button>
          </div>
        </Col>
        <Table striped hover responsive>
          <thead>
            <tr>
              <th className="roleName">#</th>
              <th className="roleName">{context.t("Id")}</th>
              <th className="roleName">{context.t("Created")}</th>
              <th className="roleName">{context.t("Updated")}</th>
              <th className="roleName">{context.t("Created by")}</th>
              <th className="roleName">{context.t("Analyzed User")}</th>
              <th className="roleName">
                <select name="status" onChange={onChangeField}>
                  {optionsStatusAcuantLogs.map((value, index) => {
                    return <option key={"s"+index} value={value.value}>{context.t(value.name)}</option>
                  })}
                </select>
              </th>
              <th className="roleName">{context.t("Provider Info")}</th>
              <th className='roleName'>
                <input name="banexcoinUsername" placeholder={context.t("Client User")} onChange={onChangeField}></input>
              </th>
              <th className='roleName'>
                <input name="userId" placeholder={context.t("Customer User Id")} onChange={onChangeField}></input>
              </th>
              <th className='roleName'>
                <input name="accountId" placeholder={context.t("Customer account Id")} onChange={onChangeField}></input>
              </th>
              <th className='roleName'>
                <Button onClick={searchData}>{context.t("Search")}</Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {!empty(rows) ? (
              rows.map((row, i) => {
                const foundStatus = optionsStatusAcuantLogs.find(s => s.value === row.status);
                const newStatus = !empty(foundStatus) ? foundStatus.name : row.status;
                return (
                  <tr key={i}>
                    <td>{i + 1 + limit * (currentPage - 1)}</td>
                    <td>{row.id}</td>
                    <td>{moment(row.createdAt).format('DD-MM-YYYY HH:mm')}</td>
                    <td>{moment(row.updatedAt).format('DD-MM-YYYY HH:mm')}</td>
                    <td>{row.executeByName}</td>
                    <td>
                      <div className={'container-td'}>
                        <p>
                          {context.t("Username")}:{' '}
                          {row.banexcoinUsername}
                        </p>
                        <p>{context.t("Email")}: {row.email}</p>
                        <p>
                          {context.t("Account Id")}:{' '}
                          {!empty(
                            row.accountId
                          )
                            ? row.accountId
                            : ''}
                        </p>
                      </div>
                    </td>
                    <td>
                      <b>{context.t(newStatus)}</b>
                    </td>
                    <td>
                      {!empty(row.acuantId) && !empty(row.acuantLogExecuteByName)
                        ? (
                          <div className={'container-td'}>
                            <p>Acuant Id: {row.acuantId}</p>
                            <p>{context.t("Executed by")}: {row.acuantLogExecuteByName}</p>
                          </div>
                        )
                        : '-'}
                    </td>
                    <td>{row.banexcoinUsername}</td>
                    <td>{row.userId}</td>
                    <td>{row.accountId}</td>
                    <td/>
                  </tr>
                );
              })
            ) : (
              <React.Fragment>
                <tr className="emptydata">
                  <td colSpan={12}>{context.t("There are no data")}</td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </Table>
        <PaginationField />
      </div>
    </React.Fragment>
  );
};

TableContainerBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TableContainerBeta;
