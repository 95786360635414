import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from '../../../../hooks/formHooks';
import InformationSection from '../../InformationSection/InformationSection';
import isEmpty from 'is-empty';
import { LoaderButton } from '../../Loader/Loader';

const VersionsByOperatingSystem = (props, context) => {

    const { allInitialSetting = {}, loaderInitialSetting, handleEditSettingByKey } = props;
    const { ANDROID_VERSION_CONFIG, IOS_VERSION_CONFIG } = allInitialSetting;

    const initialValues = {
        android: !isEmpty(ANDROID_VERSION_CONFIG) ? ANDROID_VERSION_CONFIG.CURRENT_VERSION : '',
        ios: !isEmpty(IOS_VERSION_CONFIG) ? IOS_VERSION_CONFIG.CURRENT_VERSION : ''
    }

    const { inputs, onInputChangeV2, setInputs } = useForm(initialValues);

    const resetValues = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = initialValues[name];
        });
        setInputs({
            ...inputs,
            ...newInitialvalues
        })
    };

    const handleEdit = async (type, value, name) => {
        const data = {
            CURRENT_VERSION: value
        }
        const response = await handleEditSettingByKey(type, data);
        if (!response) {
            setInputs({
                ...inputs,
                [name]: initialValues[name]
            })
        }
    }

    const informationAndroid = {
        title: 'Dispositivo Android',
        resetValues: resetValues,
        submitData: () => handleEdit('android', inputs.android, 'android'),
        fields: [
            {
                label: 'Versión actual',
                name: 'android',
                type: 'text',
                showCheckbox: false
            }
        ]
    };

    const informationIos = {
        title: 'Dispositivo IOS',
        resetValues: resetValues,
        submitData: () => handleEdit('ios', inputs.ios, 'ios'),
        fields: [
            {
                label: 'Versión actual',
                name: 'ios',
                type: 'text',
                showCheckbox: false
            }
        ]
    };

    useEffect(() => {
        if (!isEmpty(allInitialSetting)) {
            setInputs({
                android: !isEmpty(ANDROID_VERSION_CONFIG) ? ANDROID_VERSION_CONFIG.CURRENT_VERSION : '',
                ios: !isEmpty(IOS_VERSION_CONFIG) ? IOS_VERSION_CONFIG.CURRENT_VERSION : ''
            })
        }
    }, [allInitialSetting])
    return (
        <section>
            <h2 className='sub-title'>Información del Aplicativo</h2>
            {loaderInitialSetting ?
                <div className='section-loader'>
                    <LoaderButton color='orange' />
                    <p>Cargando información...</p>
                </div> :
                <div className='device-section'>
                    <InformationSection
                        data={informationAndroid}
                        inputs={inputs}
                        onChange={onInputChangeV2}
                        context={context}
                    />
                    <InformationSection
                        data={informationIos}
                        inputs={inputs}
                        onChange={onInputChangeV2}
                        context={context}
                    />
                </div>
            }
        </section >
    )
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

VersionsByOperatingSystem.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(VersionsByOperatingSystem)