import React from 'react'
import ConfirmModal from '../../../../ConfirmModal/ConfirmModal'

const ConfirmAssetRestrictedChange = ({ showModal, handleToggleModal, checkedData, handleActiveAsset }) => {
    const actions = {
        [false]: {
            text: 'restringir el',
            onClick: () => handleActiveAsset(checkedData.id, { restricted: true }, 'El activo fue restringido en compra/venta exitosamente')
        },
        [true]: {
            text: 'quitar la restricción del',
            onClick: () => handleActiveAsset(checkedData.id, { restricted: false }, 'Se quito la restricción del activo en compra/venta de manera exitosa')
        }
    }
    return <ConfirmModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
        title="Restringir activo en compra/venta"
        message={`¿Deseas ${actions[checkedData.isChecked].text} activo?`}
        confirmButtonProps={{
            onClick: () => actions[checkedData.isChecked].onClick(),
            text: "Si"
        }}
        cancelButtonProps={{
            text: "No",
        }}
    />
}

export default ConfirmAssetRestrictedChange