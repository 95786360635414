import React from 'react';
import isEmpty from 'is-empty';
import { Form } from 'react-bootstrap';
import frame from '../../../images/new_icons/frame.svg';
import InputElement from '../InputElement/InputElement';
import UrlHelper from '../../../helpers/Url.helper';
import PropTypes from 'prop-types';
export const EditableText = ({
  editable,
  type = 'text',
  accept = '*',
  fileType = '',
  option,
  titleFile = '',
  name,
  nameCheck = name,
  check = true,
  value = '',
  onChange,
  onInputFileChange,
  placeholder = 'Seleccione una opción',
  options,
  showCheckbox,
  status,
  handleImagesToZoom,
  onSetReturnDataInput = () => { },
  className = '',
  ...restProps
}, context) => {
  const defineValue = () => {
    const specialCases_text = [
      '',
      'null',
      'undefined',
      'NaN',
      null,
      undefined,
      NaN
    ];
    if (specialCases_text.includes(value)) return '--';
    if ((!isEmpty(fileType) && fileType === 'image' && typeof value === 'object')) return URL.createObjectURL(value);
    if (type === 'select' && !isEmpty(value) && !isEmpty(options)) {
      const data = options.find(item => item.value === value) || {};
      return !isEmpty(data) ? context.t(data.name) : '--';
    }
    if (name === 'level') return (options.find(it => it.value === value) || {}).name

    if(type === "number"){
      return value
    }
    if(typeof value === "string" && value.length > 1) return value
    return value == 0 ? 'No' : value == 1 ? 'Si' : value;
  };
  const urlValue = defineValue();
  return (
    <div className="item-content-info">
      {type === 'file' ? (
        <React.Fragment>
          <div className="no-editable-text">
            <p>{option}</p>
            <p className={className}>
              {check &&
                showCheckbox &&
                status === 1 && (
                  <input
                    type="checkbox"
                    name={nameCheck}
                    onChange={onSetReturnDataInput}
                    id=""
                    style={{ marginRight: '10px' }}
                  />
                )}
              {urlValue !== '--' ?
                ((!isEmpty(fileType) && fileType === 'image') ?
                  <img src={urlValue} alt={option} width='200px' onError={ev => UrlHelper.addDefaultSrcImage(ev)} onClick={() => handleImagesToZoom([{ src: urlValue, alt: 'profile image' }])} /> :
                  (typeof value ==='object') ? '--' :
                  <a href={value} target="_blank">
                    {titleFile}
                    <img src={frame} alt="download image" />
                  </a>
                ) : (
                  urlValue
                )}
            </p>
          </div>
          {editable && (
            <Form.Control
              name={name}
              type="file"
              accept={accept}
              onChange={onInputFileChange}
              placeholder={'Upload a new pep document image'}
            />
          )}
        </React.Fragment>
      ) : !editable ? (
        <div className="no-editable-text">
          <p>{option}</p>
          <p className={className}>
            {check &&
              showCheckbox &&
              status === 1 && (
                <input
                  type="checkbox"
                  name={nameCheck}
                  onChange={onSetReturnDataInput}
                  id=""
                  style={{ marginRight: '10px' }}
                />
              )}
            {defineValue()}
          </p>
        </div>
      ) : (
        <InputElement
          values={{
            title: option,
            name,
            type,
            placeholder: placeholder,
            options: options || option,
            value,
            accept,
            required: true,
            className,
            ...restProps
          }}
          actions={{ onChange }}
        />
      )}
    </div>
  );
};

EditableText.contextTypes = {
  t: PropTypes.func.isRequired
};