import React from 'react'
import { CustomButton } from '../../../../components/FallComponents/CustomButton/CustomButton';
import HeaderPage from '../../../../components/FallComponents/HeaderPage/HeaderPage'
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns'
import { useHistory } from "react-router-dom";

const CoinkTransactionsPage = () => {

   const history = useHistory();

   const navigate = ({ url, state = {} }) => {
      history.push({ pathname: url, state });
   };

   const listCard = [
      {
         cardId: 'reporte',
         cardTitle: 'Reporte',
         items: [
            {
               itemId: 'deposito-coink',
               title: 'Depósito Coink'
            },
            {
               itemId: 'retiro-coink',
               title: 'Retiro Coink'
            }
         ]
      }
   ]

   return (
      <div className="beta-content-container">
         <ResizableColumns
            childrenOne={
               <div className="all-container-data">
                  <HeaderPage
                     title="Transacciones  Coink"
                     subTitle="Coink / Transacciones coink"
                  />
                  <div className=''>
                     {listCard.map(({ cardId, cardTitle, items }, key) =>
                        <div key={key} className='card-report'>
                           <h2>{cardTitle}</h2>
                           <div className='card-section'>
                              {items.map(({ itemId, title }, key2) =>
                                 <div key={key2} className='card-items'>
                                    <p className='item-text'>- {title}</p>
                                    <CustomButton
                                       text='Ver detalle'
                                       className="purple"
                                       onClick={() => navigate({ url: `/coink-transactions/${cardId}/${itemId}` })}
                                    />
                                 </div>
                              )}
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            }
         />
      </div>
   )
}

export default CoinkTransactionsPage
