import moment from 'moment-timezone';
import UtilsHelper from '../../helpers/Utils.helper';

export const getAllNotifications = async(userId) => {
  const path = `/api/bannerpopup`;
  return await UtilsHelper.sendRequest({
    userId,
    method: 'GET',
    path
  });
};

export const updateNotification = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = `/api/bannerpopup/update`;
  let model = new FormData();
  model.append('id', data.id);
  model.append(
    'startAt',
    moment
      .tz(data.startAt, 'America/Lima')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
  );
  model.append(
    'endAt',
    moment
      .tz(data.endAt, 'America/Lima')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
  );
  model.append('type', data.type);
  model.append('eventType', data.eventType);
  model.append('eventTitleES', data.eventTitleES || '');
  model.append('eventTitleEN', data.eventTitleEN || '');
  model.append('eventTextES', data.eventTextES || '');
  model.append('eventTextEN', data.eventTextEN || '');
  model.append('eventTextOnlyES', data.eventTextOnlyES || '');
  model.append('eventTextOnlyEN', data.eventTextOnlyEN || '');
  model.append('eventExternalLinkES', data.eventExternalLinkES || '');
  model.append('eventExternalLinkEN', data.eventExternalLinkEN || '');
  if (
    data.type === 'popup' ||
    data.type === 'popup_app' ||
    data.type === 'carousel'
  ) {
    model.append('eventImagesES', data.eventImagesES);
    model.append('eventImagesEN', data.eventImagesEN);
  }
  model.append('status', data.status);
  model.append('statusLink', data.statusLink);
  return await UtilsHelper.sendRequest({
    method: 'POST',
    path,
    headers,
    userId,
    data: model
  });
};

export const deleteNotification = async (userId,id) => {
  const path = `/api/bannerpopup/delete`;
  return await UtilsHelper.sendRequest({
    method: 'POST',
    path,
    userId,
    data: { id }
  });
};
