import UtilsHelper from '../../helpers/Utils.helper';

export const getAllRestrictedDomains = async (userId, data) => {
  const path = `/api/disposable-domain`;
  return await UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path,
    data
  });
}
export const createRestrictedDomain = async (userId, data) => {
  const path = `/api/disposable-domain`;
  return await UtilsHelper.sendRequest({
    userId,
    method: 'post',
    path,
    data
  });
}

export const updateRestrictedDomain = async (userId, id, data) => {
    const path = `/api/disposable-domain/${id}`;
    return await UtilsHelper.sendRequest({
        userId,
        method: 'put',
        path,
        data
    });
    }

export const deleteRestrictedDomain = async (userId, id) => {
    const path = `/api/disposable-domain/${id}`;
    return await UtilsHelper.sendRequest({
        userId,
        method: 'delete',
        path,
    });
    }