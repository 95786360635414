import isEmpty from 'is-empty';
import React, { useState } from 'react'
import ComponentsHelper from '../../../helpers/Components.helper';
import DataFormatterHelper from '../../../helpers/DataFormatter.helper';
import DateHelper from '../../../helpers/Date.helper';
import { useForm } from '../../../hooks/formHooks';
import { GroupFilters } from '../Filters/Filters';
import HeaderPage from '../HeaderPage/HeaderPage'
import TableContainer from '../Table/TableContainer'
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import Download from '../../../images/new_icons/download.svg';
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import statusComponent from '../StatusComponent/StatusComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { useModal } from '../../../hooks/useModal';

const AllTicketsAndInvoicesPage = (props) => {

    const { loading, setFilterSearch, onChangeLimit, filterConfig, totalRows, offset, showingTo, changePage, rows, withRightSection, initialConfig, context, handleDeleteCreditNote, handleDownload } = props;

    const filterInitialValues = {
        correlative: "",
        faStatus: "",
        serie: "",
        type: ""
    };
    const { showModal, handleToggleModal } = useModal();
    const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(filterInitialValues);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [rowInfo, setRowInfo] = useState({});

    const handleShowFilters = () => setShowFilters(!showFilters);

    const handleResetFilters = () => {
        setInputs(filterInitialValues);
        setFilters({})
        initialConfig();
    };

    const defineFilter = (e) => {
        if (e.name === 'correlative') return { in: Number(e.value) }
        return { includesInsensitive: String(e.value) }
    }

    const handleOnChange = (e) => {
        onInputChangeV2(e);
        setFilters({
            ...filters,
            [e.name]: !isEmpty(e.value) ? defineFilter(e) : ''
        });
    }

    const buildData = (data) => ([{ id: data.faStatus, name: context.t(data.faStatus), value: data.faStatus }])

    const buildDeleteButton = (data) => {
        let response = '-';
        if (data.faStatus !== 'CANCELED' && data.faStatus !== 'REJECTED' && data.type !== 'NOTA DE CREDITO') {
            response = <FontAwesomeIcon icon={faTrash} title='Anular documento con una nota de crédito' className='trash-icon' onClick={() => handleDeleteButton(data)} />;
        }
        return response;
    }

    const handleDeleteButton = (data) => {
        setRowInfo(data)
        handleToggleModal()
    }

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: '',
            title: 'Hora y fecha de operación',
            cell: ({ data }) => DateHelper.formatDate(data.createdAt)
        },
        {
            tag: 'type',
            title: 'Tipo'
        },
        {
            tag: 'serie',
            title: 'Serie'
        },
        {
            tag: 'correlative',
            title: 'Correlativo'
        },
        {
            tag: 'apFeeProductSymbol',
            title: 'Moneda',
            cell: ({ data }) => data.documento.tipoMoneda
        },
        {
            tag: 'apFeeOnSymbol',
            title: 'Total',
            cell: ({ data }) => data.documento.mntTotal.toFixed(2)
        },
        {
            tag: '',
            title: 'Estado',
            cell: ({ data }) => statusComponent(data.faStatus, false, buildData(data))
        },
        {
            tag: '',
            title: '¿Hay observaciones?',
            cell: ({ data }) => !isEmpty(data.observaciones) ? 'Si' : 'No'
        },
        {
            tag: 'apValue',
            title: 'Acciones',
            cell: ({ data }) => buildDeleteButton(data)
        }
    ]

    const handleSearchFilters = () => {
        const newFilters = DataFormatterHelper.cleanJSONFilters(filters);
        Object.keys(newFilters).forEach(element => {
            newFilters[element] === '' && delete newFilters[element];
        });
        setFilterSearch(newFilters)
    };

    const headerOptions = [
        {
            icon: showFilters ? SettingLight : Setting,
            action: handleShowFilters,
            className: showFilters ? 'header-option-active' : ''
        },
        {
            icon: Download,
            action: handleDownload
        },
        {
            icon: RotateRight,
            action: handleResetFilters
        }
    ];

    const voucherType = [
        { value: "BOLETA", name: "Boleta" },
        { value: "FACTURA", name: "Factura" },
        { value: "NOTA DE CREDITO", name: "Nota de crédito" }
    ]

    const statusType = [
        { value: "SENT", name: "Enviado a FA" },
        { value: "REJECTED", name: "Rechazado por FA" },
        { value: "CANCELED", name: "Anulado por nota de crédito" }
    ]

    const inputs = [
        {
            values: {
                title: "Tipo de comprobante",
                name: "type",
                value: inputsFilters.type,
                type: "select",
                options: voucherType,
                required: true,
            },
            actions: {
                onChange: handleOnChange,
            },
        },
        {
            values: {
                title: 'Serie',
                name: 'serie',
                value: inputsFilters.serie,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Correlativo',
                name: 'correlative',
                value: inputsFilters.correlative,
                type: 'number',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: "Estado",
                name: "faStatus",
                value: inputsFilters.faStatus,
                type: "select",
                options: statusType,
                required: true,
            },
            actions: {
                onChange: handleOnChange,
            },
        }
    ]

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Boletas y Facturas"
                subTitle="Facturación / Boletas y Facturas"
                headerOptions={headerOptions}
            />
            <TableContainer
                onChangeLimit={onChangeLimit}
                filterConfig={filterConfig}
                totalRows={totalRows}
                rows={rows}
                loading={loading}
                offset={offset}
                showingTo={showingTo}
                changePage={changePage}
                headers={tableHeaders}
                rowIdSelected={''}
            >
                {
                    showFilters &&
                    <GroupFilters
                        handleSearchFilters={handleSearchFilters}
                        withRightSection={withRightSection}
                        inputs={inputs}
                    />
                }
            </TableContainer>
            <ConfirmModal
                showModal={showModal}
                handleToggleModal={handleToggleModal}
                title='Generar nota de crédito'
                message={`¿Está seguro que deseas anular el documento ${rowInfo.serie}-${rowInfo.correlative} con una orden de crédito?`}
                confirmButtonProps={{
                    text: "Si",
                    onClick: () => handleDeleteCreditNote(rowInfo)
                }}
                cancelButtonProps={{
                    text: "No"
                }}
            />
        </div>
    )
}

export default AllTicketsAndInvoicesPage