import React, { useEffect } from "react";
import empty from "is-empty";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { syncUsersAppMarketing } from "./AppMarketingController";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
const AppMarketingModalSync = (props, context) => {
  const {
    userInfo,
    showModalSync,
    setShowModalSync,
    isChecked,
    setIsChecked,
    loading,
    setLoading,
    getListsAppMarketing,
    inputs,
    setInputs,
    errors,
    data,
    setShowLottie
  } = props;

  const [checked, setChecked] = React.useState(false);

  const onInputsChange = (e) => {
    e.persist();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.checked
    });
  };

      const onChangeCheck = (value) => {
    if (isChecked.includes(value)) {
      const newlevel = isChecked.filter((level) => level !== value);
      setIsChecked(newlevel);
    } else {
      setIsChecked([...isChecked, value]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const inputsTemp = {
      ...inputs,
      mode: "subscribe",
      delim: ",",
      level: (isChecked || []).map((i) => Number(i)),
      validated: true,
    };
    setInputs(inputsTemp);
    callBackSubmit(inputsTemp);
  };

  const callBackSubmit = async (inputs) => {
    try {
      setShowModalSync(false);
      setShowLottie(true);
      const syncList = await syncUsersAppMarketing(userInfo.UserId, inputs);
      if (!empty(syncList)){
        setShowLottie(false);
        toast.success(context.t("Information updated successfully"), {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(0);
        setTimeout(getListsAppMarketing(), 5000);
      }
    } catch (error) {
      toast.warn(context.t("The information could not be saved"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  
  const validCheckbox = (value, value2) => {
    return (value || []).includes(value2) ? true : false;
  };

  const validCheckboxDisable = (value) => {
    return (isChecked || []).includes(value) ? 'no-permit' : 'check-none';
  };
  const naturalLevel = ['0', '10', '11', '12', '13', '14']
  const corporateLevel = ['17', '18']

  const validCheckboxTitle = (value) => {
    return isChecked.some(checked => value.includes(checked)) ? 'level-nat' : 'check-none';
  }

  const isValidCheck = (!empty(errors.tags)) ? "invalid label-check levels" : "label-check levels";

    useEffect(() => {
     if(!empty(data.id)){
      setInputs({ lists: [data.id]})
     }
  }, [data]);

  useEffect(() => {
    if(!empty(inputs) && inputs.subscription_status){
      setChecked(!checked)
    }
 }, [inputs]);

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={showModalSync}>
          <Form noValidate validated={inputs.validated} onSubmit={onSubmit}>
            <ModalHeader
             className='modal-header'
              charCode="X"
              toggle={() => {
                setShowModalSync(false);
              }}>
              {context.t('Sync list:{list} ?',
              {
                list: <p>{data.name}</p>
              }
              )}
            </ModalHeader>
            <ModalBody>
            <div className="check-group">
            <FormGroup tag="fieldset" className="check-levels">
                <legend className="overwrite_switch">
                  {context.t("Confirm status?")}
                </legend>
                <FormGroup check inline>
                  <Input
                    defaultChecked={checked}
                    name="subscription_status"
                    type="checkbox"
                    className="switch_1"
                    id="switch"
                    onChange={(e) => onInputsChange(e)}/>
                </FormGroup>
              </FormGroup>
              <FormGroup tag="fieldset" className="check-levels">
                <legend className="overwrite_switch">
                  {context.t("Overwrite?")}
                </legend>
                <FormGroup check inline>
                  <Input
                    defaultChecked={checked}
                    name="overwrite"
                    type="checkbox"
                    className="switch_1"
                    id="switch"
                    onChange={(e) => onInputsChange(e)}/>
                </FormGroup>
              </FormGroup>
            </div>
              <FormGroup tag="fieldset" className="check-levels">
                <legend className={validCheckboxTitle(naturalLevel)}>{context.t("Natural")}</legend>
                <FormGroup check inline className={validCheckboxDisable('0')}>
                  <Input
                    name="nivel-0"
                    type="checkbox"
                    onChange={() => {onChangeCheck("0");}}
                    value="0"
                    checked={validCheckbox(isChecked, "0")}/>{" "}
                    <p check className={isValidCheck} title="Sin verificar - natural">Nivel 0</p>
                </FormGroup>
                <FormGroup check inline className={validCheckboxDisable('10')}>
                  <Input
                    name="nivel-10"
                    type="checkbox"
                    onChange={() => {onChangeCheck("10");}}
                    value="10"
                    checked={validCheckbox(isChecked, "10")}
                  />{" "}
                    <p check className={isValidCheck} title="Explorador">Nivel 10</p>
                </FormGroup>
                <FormGroup check inline className={validCheckboxDisable('11')}>
                  <Input
                    name="nivel-11"
                    type="checkbox"
                    onChange={() => {onChangeCheck("11");}}
                    value="11"
                    checked={validCheckbox(isChecked, "11")}
                  />{" "}
                    <p check className={isValidCheck} title="Amateur">Nivel 11</p>
                </FormGroup>
                <FormGroup check inline className={validCheckboxDisable('12')}>
                  <Input
                    name="nivel-12"
                    type="checkbox"
                    onChange={() => {onChangeCheck("12");}}
                    value="12"
                    checked={validCheckbox(isChecked, "12")}
                  />{" "}
                    <p check className={isValidCheck} title="Intermedio">Nivel 12</p>
                </FormGroup>
                <FormGroup check inline className={validCheckboxDisable('13')}>
                  <Input
                    name="nivel-13"
                    type="checkbox"
                    onChange={() => {onChangeCheck("13");}}
                    value="13"
                    checked={validCheckbox(isChecked, "13")}
                  />{" "}
                    <p check className={isValidCheck} title="Avanzado">Nivel 13</p>
                </FormGroup>
                <FormGroup check inline className={validCheckboxDisable('14')}>
                  <Input
                    name="nivel-14"
                    type="checkbox"
                    onChange={() => {onChangeCheck("14");}}
                    value="14"
                    checked={validCheckbox(isChecked, "14")}
                  />{" "}
                    <p check className={isValidCheck} title="Maestro">Nivel 14</p>
                </FormGroup>
                <legend className={validCheckboxTitle(corporateLevel)}>{context.t("Corporate")}</legend>
                <FormGroup check inline className={validCheckboxDisable('17')}>
                  <Input
                    name="nivel-17"
                    type="checkbox"
                    onChange={() => {onChangeCheck("17");}}
                    value="17"
                    checked={validCheckbox(isChecked, "17")}
                  />{" "}
                    <p check className={isValidCheck} title="Sin verificar - corporativo">Nivel 17</p>
                </FormGroup>
                <FormGroup check inline className={validCheckboxDisable('18')}>
                  <Input
                    name="nivel-18"
                    type="checkbox"
                    onChange={() => {onChangeCheck("18");}}
                    value="18"
                    checked={validCheckbox(isChecked, "18")}
                  />{" "}
                    <p check className={isValidCheck} title="Corporativo">Nivel 18</p>
                </FormGroup>
              </FormGroup>
              {!empty(errors.tags) && (
                <p className="tag-error"><span>{errors.tags}</span></p>)}
            </ModalBody>
            <ModalFooter>
              <Button className="submit-btn" type="submit" disabled={loading}>
                Aceptar
              </Button>
              <Button
                className="cancel-btn"
                onClick={() => {setShowModalSync(false);}}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AppMarketingModalSync.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AppMarketingModalSync);
