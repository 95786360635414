import React from 'react'
import './boxLimits.css'

function BoxLimits({
    NotionalCurrent,
    NotionalLimit,
    OriginalCurrent,
    OriginalLimit,
    notional_product,
    original_product,
    amount,
    title
}) {

    return (
        <div className='box-limits'>
            <h2>{title}</h2>
            <div>
                <div className='box-limits-details'>
                    <p>{OriginalCurrent} {original_product && original_product.ProductName} <span title={`${NotionalCurrent} ${notional_product && notional_product.ProductName}`}>Equivalente en {notional_product && notional_product.ProductName}</span></p>
                    <p>{OriginalLimit} {original_product && original_product.ProductName} <span title={`${NotionalLimit} ${original_product && original_product.ProductName}`}>Equivalente en {notional_product && notional_product.ProductName}</span></p>
                </div>
                <div id="progress-bar">
                    <div style={{ width: `${amount}%` }}></div>
                </div>
            </div>
        </div>
    )
}

export default BoxLimits