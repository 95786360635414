import UtilsHelper from '../../helpers/Utils.helper';

export const getAllSeriesCorrelatives = async (userId, page) => {
    const path = '/api/facturactiva/seriescorrelativos';
    const configRequest = {
      userId,
      method: 'GET',
      path
    };
   return await UtilsHelper.sendRequest(configRequest);
};

export const changeStatus = async (userId, form) => {
    const path = '/api/facturactiva/seriescorrelativos/actdeact';
    const data = {
      id: form.id,
      status: form.status === 1 ? 0 : 1
    };
    const configRequest = {
      userId,
      method: 'POST',
      path,
      data
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const deleteInvoice = async (userId, id) => {
    const path = '/api/facturactiva/seriescorrelativos/delete';
    const configRequest = {
      userId,
      method: 'post',
      path,
      data: {id}
    };
   return await UtilsHelper.sendRequest(configRequest);
};

export const addSerieCorrelative = async (userId, form) => {
    const path = '/api/facturactiva/seriescorrelativos/add';
    const valueConfig = {
      SERIE: form.serial,
      CORRELATIVE: form.correlative,
      CURRENCY: form.currency
    };
    const model = {
      status: Number(form.status),
      key: form.list_type,
      value: JSON.stringify(valueConfig)
    };

    const configRequest = {
      userId,
      method: 'post',
      path,
      data: model
    };
    return await UtilsHelper.sendRequest(configRequest);
};
