export const WARNING_STATUS = [
    {
      status: 'OWN_LIST',
      title: 'Usuario en lista propia'
    },
    {
      status: 'LOCAL_PEP',
      title: 'Usuario en PEP local'
    },
    {
      status: 'EXTERNAL_PEP',
      title: 'Usuario en PEP externo'
    },
    {
      status: 'REQUESTED',
      title: 'Usuario requisitoriado'
    },
    {
      status: 'NEGATIVE_NEWS',
      title: 'Usuario en noticias negativas'
    },
    {
      status: 'RELATED_PEP',
      title: 'Usuario en PEP relacionado'
    },
    {
      status: 'OWN',
      title: 'Usuario en lista propia'
    },
    {
      status: 'LOCAL',
      title: 'Usuario en PEP local'
    },
    {
      status: 'EXTERNAL',
      title: 'Usuario en PEP externo'
    },
    {
      status: 'NEGATIVE',
      title: 'Usuario en noticias negativas'
    },
    {
      status: 'RELATED',
      title: 'Usuario en PEP relacionado'
    },
  ];