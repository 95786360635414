import React from 'react';
import { ClipLoader } from 'react-spinners';
import empty from 'is-empty';

class Loading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let showLoading = false;

    if (!empty(this.props.loading)) {
      if (this.props.loading > 0) {
        showLoading = true;
      }
    }

    return (
      <div
        className={showLoading ? 'loading-overlay' : 'loading-overlay noshow'}>
        <div className="loading-wheel">
          <ClipLoader
            sizeUnit={'px'}
            size={60}
            color={'#f28e2a'}
            loading={true}
          />
        </div>
      </div>
    );
  }
}

export default Loading;
