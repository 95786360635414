import React, { useEffect, useState } from "react";
import { CustomButton } from "../CustomButton/CustomButton";
import "./allProfileData.css";
import crossIcon from "../../../images/new_icons/cross.svg";
import checkIcon from "../../../images/new_icons/check.svg";
import editIcon from "../../../images/new_icons/edit.svg";
import { EditableText } from "../EditableText/EditableText";
import ProfilesController from "../../../pages/FallControllers/ProfilesController";
import { useHistory } from "react-router-dom";
import ConfirmModal from '../../ConfirmModal/ConfirmModal'
import { useModal } from "../../../hooks/useModal";

function ProfilesDetails({
  data,
  userInfo,
  modules,
  handleSubmitNewPermits,
  handleDeleteProfile,
  loading,
  reloadData,
  isEditable,
  isEditable2,
  setIsEditable,
  setIsEditable2
}) {
  const [name, setName] = useState(data.name);
  const history = useHistory();
  const {handleToggleModal, showModal } = useModal()

  let newModules = {};
  modules.forEach(element => {
    if (element.completeTitle) {
      const moduleName = element.completeTitle.split("- ")[1];
      if (!newModules[moduleName]) newModules[moduleName] = [];
      newModules[moduleName].push(element);
    }
  });

  const handleCloseEditableName = async () => {
    setIsEditable(!isEditable);
    setName(data.name);
  };
  const handleEditProfiles = async () => {
    if (!isEditable2) {
      setIsEditable2(!isEditable2);
      return;
    }
    handleSubmitNewPermits(data.profileId);
  };

  const handleEditName = async () => {
    setIsEditable(!isEditable);
    if (!isEditable) return;
    const response = await ProfilesController.updateProfile({
      userInfo,
      id: data.profileId,
      name,
    });
    if (!response) {
      setName(data.name);
      return;
    }
    reloadData();
  };

  const handleCloseEditablePermits = () => {
    setIsEditable2(!isEditable2);
  };

  const verificationIfHasPermits = (tag, value) => {
    const hasPermits = data.hasOwnProperty(tag);
    if (!hasPermits) return false;
    return data[tag].includes(value);
  };

  const handleRedirectToUseProfiles = () => {
    history.push("/usuarios-internos", {
      profile: data,
    });
  };

  useEffect(() => {
    setName(data.name);
    setIsEditable(false)
    setIsEditable2(false)
  }, [data]);

  const nameInputProps = {
    editable: isEditable,
    type: "text",
    option: "Nombre",
    name: "name",
    value: name,
    onChange: (target) => setName(target.value),
    rootClassname: "input-element-aml-alert",
  };

  return (
    <div className="ticket-detail-main profiles-details">
      <div>
        <div className="header-section">
          <h2>Permisos para {data.name}</h2>
          <p className="link-profile-redirect" onClick={handleRedirectToUseProfiles}>
            Ver usuarios asignados a este perfil
          </p>
        </div>
        <div className="profiles-details-list">
          <div className="flex items-center gap-1">
            <h3>Detalles de perfil</h3>
            <img
              onClick={handleEditName}
              src={isEditable ? checkIcon : editIcon}
            />
            {isEditable && (
              <img onClick={handleCloseEditableName} src={crossIcon} />
            )}
          </div>
          <br />
          <EditableText {...nameInputProps} />
          <br />
          <div className="flex items-center gap-1 profiles-permits-row-h3">
            <h3>Detalle de permisos</h3>
            <img
              onClick={handleEditProfiles}
              src={isEditable2 ? checkIcon : editIcon}
            />
            {isEditable2 && (
              <img onClick={handleCloseEditablePermits} src={crossIcon} />
            )}
          </div>
          <div className="profiles-permits-row title">
            <p>Módulo</p>
            <p>Lectura</p>
            <p>Escritura</p>
          </div>
          <div className="profiles-permits-list-modules">
            {Object.keys(newModules)
            .sort((a, b) => a.localeCompare(b))
            .map(categoryName => {
              return (
                <React.Fragment>
                  <div className="profiles-permits-row">
                    <p><b>{categoryName}</b></p>
                  </div>
                  {newModules[categoryName]
                    .filter((item) => item.tag !== "name")
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((module) => {
                      const tagRead = `${module.id}/R`;
                      const tagWrite = `${module.id}/W`;
                      const verificationIfHasReadPermitValue =
                        verificationIfHasPermits(module.title, "R");
                      const verificationIfHasWritePermitValue =
                        verificationIfHasPermits(module.title, "W");
                      return (
                        <div className="profiles-permits-row">
                          <p>- {module.title}</p>
                          {isEditable2 ? (
                            <input
                              type="checkbox"
                              className="input-check-module-permits"
                              name={tagRead}
                              id={tagRead}
                              defaultChecked={verificationIfHasReadPermitValue}
                              onChange={(ev) => {
                                if(!ev.target.checked){
                                  document.getElementById(tagWrite).checked = false
                                }
                              }}
                            />
                          ) : (
                            <p>{verificationIfHasReadPermitValue ? "✔️" : "-"}</p>
                          )}
                          {isEditable2 ? (
                            <input
                              type="checkbox"
                              className="input-check-module-permits"
                              name={tagWrite}
                              id={tagWrite}
                              defaultChecked={verificationIfHasWritePermitValue}
                              onChange={(ev) => {
                                if(ev.target.checked){
                                  document.getElementById(tagRead).checked = true
                                }
                              }}
                            />
                          ) : (
                            <p>{verificationIfHasWritePermitValue ? "✔️" : "-"}</p>
                          )}
                        </div>
                      );
                    })}
                </React.Fragment>
              )})}
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <div className="buttons-left"></div>
        <CustomButton
          text="Eliminar perfil"
          onClick={handleToggleModal}
          className="red"
        />
      </div>
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
        title="Eliminar perfil"
        message="¿Deseas eliminar el perfil seleccionado?"
        confirmButtonProps={{
          onClick: () => handleDeleteProfile(data.profileId),
          text: "Si",
          customClassName:"delete-comment-button"
        }}
        cancelButtonProps={{
          text: "No",
          customClassName:"delete-comment-button"
        }}
      />
    </div>
  );
}

export default ProfilesDetails;
