import React, { useEffect, useState } from "react";
import empty from "is-empty";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createListAppMarketing,
  editListAppMarketing,
} from "./AppMarketingController";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

const AppMarketingModal = (props, context) => {
  const {
    userInfo,
    showModal,
    setShowModal,
    action,
    isChecked,
    setIsChecked,
    loading,
    setLoading,
    getListsAppMarketing,
    inputs,
    setInputs,
    errors,
    setErrors,
  } = props;

  const onInputsChange = (e) => {
    e.persist();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
      type: "private",
      optin: "single"
    });
  };

  const onChangeCheck = (value) => {
    if (isChecked.includes(value)) {
      const newlevel = isChecked.filter((level) => level !== value);
      setIsChecked(newlevel);
    } else {
      setIsChecked([...isChecked, value]);
    }
  };

  const customValidations = (inputs) => {
    let inputsErrors = {};
    if (empty(inputs.name)) {
      inputsErrors.name = "Debes llenar este campo";
    }
    if (empty(isChecked)) {
      inputsErrors.tags = "Debe seleccionar al menos un nivel";
    }
    setErrors(inputsErrors);
    return inputsErrors;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const inputsTemp = {
      ...inputs,
      id: inputs.id,
      tags: isChecked,
      validated: true
    };
    setInputs(inputsTemp);
    const errorsCustom = customValidations(inputsTemp);
    if (empty(errorsCustom)) {
      callBackSubmit(inputsTemp);
    }
  };
  const callBackSubmit = async (inputs) => {
    setLoading(1);
    try {
      if (action == "edit") {
                setShowModal(false);
        const updateList = await editListAppMarketing(userInfo.UserId, inputs);
        if (!empty(updateList)) {
          getListsAppMarketing();
          toast.success(context.t("Information updated successfully"), {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(0);
        }
      } else {
        setShowModal(false);
        const createList = await createListAppMarketing(
          userInfo.UserId,
          inputs
        );
        if (!empty(createList)){
          getListsAppMarketing();
          toast.success(context.t("Information created successfully"), {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(0);
        }
      }
    } catch (error) {
      toast.warn(context.t("The information could not be saved"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  const isValid = (value) => {
    return !empty(value) ? true : false;
  };

  const isInvalid = (value, value2) => (!empty(value) && value2) ? true : false;

  const validCheckbox = (value, value2) => {
    return (value || []).includes(value2) ? true : false;
  };
  const isValidCheck = (!empty(errors.tags)) ? "invalid label-check levels" : "label-check levels";
  
  return (
    <React.Fragment>
      <div>
        <Modal style={{maxWidth:"800px"}} isOpen={showModal}>
          <Form noValidate validated={inputs.validated} onSubmit={onSubmit}>
            <ModalHeader
              charCode="X"
              toggle={() => {
                setShowModal(false);
              }}
            >
              {action === "edit" ? "Editar " : "Nueva "} lista
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="name">Nombre</Label>
                <Input
                  className="text-list"
                  id="name"
                  name="name"
                  placeholder="Nombre de Lista"
                  defaultValue={inputs.name}
                  type="text"
                  autoComplete="off"
                  onChange={onInputsChange}
                  valid={isValid(inputs.name)}
                  invalid={isInvalid(errors.name, inputs.validated)}/>
                <FormFeedback tooltip>{errors.name} </FormFeedback>
              </FormGroup>
              <FormGroup tag="fieldset" className="check-levels">
                <legend className="level-nat">{context.t("Natural")}</legend>
                <FormGroup check inline>
                  <Input
                     name="nivel-0"
                     type="checkbox"
                     invalid={isInvalid(errors.tags, inputs.validated)}
                     onChange={() => onChangeCheck("0")}
                     value="10"
                     checked={validCheckbox(isChecked, "0")}
                  />{" "}
                    <p check className={isValidCheck} title="Sin verificar - natural">Nivel 0</p>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="nivel-10"
                    type="checkbox"
                    invalid={isInvalid(errors.tags, inputs.validated)}
                    onChange={() => {onChangeCheck("10");}}
                    value="10"
                    checked={validCheckbox(isChecked, "10")}
                  />{" "}
                    <p check className={isValidCheck} title="Explorador">Nivel 10</p>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="nivel-11"
                    type="checkbox"
                    onChange={() => {onChangeCheck("11");}}
                    invalid={isInvalid(errors.tags, inputs.validated)}
                    value="11"
                    checked={validCheckbox(isChecked, "11")}
                  />{" "}
                    <p check className={isValidCheck} title="Amateur">Nivel 11</p>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="nivel-12"
                    type="checkbox"
                    onChange={() => {onChangeCheck("12");}}
                    invalid={isInvalid(errors.tags, inputs.validated)}
                    value="12"
                    checked={validCheckbox(isChecked, "12")}
                  />{" "}
                    <p check className={isValidCheck} title="Intermedio">Nivel 12</p>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="nivel-13"
                    type="checkbox"
                    onChange={() => {onChangeCheck("13");}}
                    invalid={isInvalid(errors.tags, inputs.validated)}
                    value="13"
                    checked={validCheckbox(isChecked, "13")}
                  />{" "}
                    <p check className={isValidCheck} title="Avanzado">Nivel 13</p>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="nivel-14"
                    type="checkbox"
                    onChange={() => {onChangeCheck("14")}}
                    invalid={isInvalid(errors.tags, inputs.validated)}
                    value="14"
                    checked={validCheckbox(isChecked, "14")}
                  />{" "}
                    <p check className={isValidCheck} title="Maestro">Nivel 14</p>
                </FormGroup>
                <legend className="level-corp">{context.t("Corporate")}</legend>
                <FormGroup check inline>
                  <Input
                    name="nivel-17"
                    type="checkbox"
                    onChange={() => {onChangeCheck("17");}}
                    invalid={isInvalid(errors.tags, inputs.validated)}
                    value="17"
                    checked={validCheckbox(isChecked, "17")}
                  />{" "}
                    <p check className={isValidCheck} title="Sin verificar - corporativo">Nivel 17</p>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="nivel-18"
                    type="checkbox"
                    onChange={() => {onChangeCheck("18");}}
                    invalid={isInvalid(errors.tags, inputs.validated)}
                    value="18"
                    checked={validCheckbox(isChecked, "18")}
                  />{" "}
                    <p check className={isValidCheck} title="Corporativo">Nivel 18</p>
                </FormGroup>
              </FormGroup>
              {!empty(errors.tags) && (
                <p className="tag-error"><span>{errors.tags}</span></p>
              )}
            </ModalBody>
            <ModalFooter>
              {action == "edit" ? (
                <Button className="submit-btn" type="submit" disabled={loading}>
                  Editar
                </Button>
              ) : (
                <Button className="submit-btn" type="submit" disabled={loading}>
                  Crear
                </Button>
              )}{" "}
              <Button
                className="cancel-btn"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AppMarketingModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AppMarketingModal);
