import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import Papa from 'papaparse';
import Loading from '../../components/Loading';
import TableContainer from './TableContent/TableContainer';
import { getUsers, createAcuantQueue } from './AcuantHooks';
import RotateRight from '../../images/new_icons/rotate-right.svg';
import { DatePicker, DatePickerInput } from 'rc-datepicker';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const AcuantPage = (props, context) => {
  const { userInfo, accounts } = props;

  if (empty(userInfo) || empty(accounts)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [filterConfig, setFilterConfig] = useState({ limit: 500, page: 1 });
  const [users, setUsers] = useState([]);
  const [filterSearch, setFilterSearch] = useState({
    userIds: '',
    usernames: '',
    excludeAccountIds: '',
    level: '12',
    updatedUntilDate: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD')
  });

  async function loadUsers(config, search) {
    setLoading(1);
    try {
      let result = await getUsers(
        userInfo.UserId,
        !empty(config) ? config : filterConfig,
        !empty(search) ? search : {}
      );
      setRows(result.nodes);
      setFilterConfig({
        ...filterConfig,
        totalCount: result.totalCount
      });
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information is not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setLoading(0);
  }

  async function sendInfo(ids) {
    try {
      setLoading(1);
      await createAcuantQueue(userInfo.UserId, { ids: ids });
      toast.success(context.t('Your data is being sent'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000
      });
    } catch (error) {
      console.error('Error updating event details: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000
      });
    }

    setLoading(0);
  }

  const FilterRows = ({ filterSearch }) => {
    setFilterSearch(filterSearch);
    loadUsers(filterConfig, filterSearch);
  };

  const changePage = num => {
    filterConfig.page = num;
    loadUsers(filterConfig, filterSearch);
  };

  const onChangeLimit = e => {
    e.persist();
    let name = e.target.name;
    let value = Number(e.target.value);
    filterConfig.limit = value;
    filterConfig.page = 1;
    loadUsers(filterConfig, filterSearch);
  };

  const changeHandler = event => {

    try {
      let dataFile = [];
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
          dataFile = results.data;
          const userIds = [],
            usernames = [],
            excludeAccountIds = [];
          if (!empty(dataFile)) {
            for (const line of dataFile) {
              if (line.type === 'user_id') {
                userIds.push(line.value.trim());
              }
              if (line.type === 'username') {
                usernames.push(line.value.trim());
              }
              if (line.type === 'exclude_account_id') {
                excludeAccountIds.push(line.value.trim());
              }
            }

            setFilterSearch({
              ...filterSearch,
              userIds: !empty(userIds)
                ? userIds.join(',')
                : filterSearch.userIds,
              usernames: !empty(usernames)
                ? usernames.join(',')
                : filterSearch.usernames,
              excludeAccountIds: !empty(excludeAccountIds)
                ? excludeAccountIds.join(',')
                : filterSearch.excludeAccountIds
            });
          }
        }
      });
      event.target.value = null;
    } catch (error) {}
  };

  function resetFilters (){
    const defaultSearchFilter = {
      userIds: '',
      usernames: '',
      excludeAccountIds: '',
      level: '12',
      updatedUntilDate: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD')
    }
    setFilterSearch(defaultSearchFilter)
    loadUsers(filterConfig, defaultSearchFilter);
  }

  function FiltersComponent() {
    return (
      <Row>
        <Col xl="12">
          <Form>
            <Row style={{ margin: 0 }}>
              <Col xl="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Form.Group className="mb-3" controlId="formBasicUsernames">
                  <Form.Label>Archivo de Filtros (csv)</Form.Label>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={changeHandler}
                    style={{ display: 'block', margin: '10px auto' }}
                  />
                </Form.Group>
              </Col>
              <Col xl="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicExcludeAccountIds">
                  <Form.Label>BANEXCOIN USER ID (,)</Form.Label>
                  <Form.Control
                    key={'usersIds'}
                    type="text"
                    placeholder="Ingrese User IDs"
                    value={
                      !empty(filterSearch.userIds) ? filterSearch.userIds : ''
                    }
                    onChange={e =>
                      setFilterSearch({
                        ...filterSearch,
                        userIds: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicExcludeAccountIds">
                  <Form.Label>BANEXCOIN USER ACCOUNT ID (,)</Form.Label>
                  <Form.Control
                    key={'userAccountIds'}
                    type="text"
                    placeholder="Ingrese Banexcoin User Account IDs"
                    value={
                      !empty(filterSearch.userAccountIds) ? filterSearch.userAccountIds : ''
                    }
                    onChange={e =>
                      setFilterSearch({
                        ...filterSearch,
                        userAccountIds: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Form.Group className="mb-3" controlId="formBasicUsernames">
                  <Form.Label>BANEXCOIN USERNAME (,)</Form.Label>
                  <Form.Control
                    key={'usernames'}
                    type="text"
                    placeholder="Ingrese Usernames"
                    value={
                      !empty(filterSearch.usernames)
                        ? filterSearch.usernames
                        : ''
                    }
                    onChange={e =>
                      setFilterSearch({
                        ...filterSearch,
                        usernames: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl="4" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicExcludeAccountIds">
                  <Form.Label>AccountIds Excluidos (,)</Form.Label>
                  <Form.Control
                    key={'excludeAccountIds'}
                    type="text"
                    placeholder="Ingrese Banexcoin User Account IDs"
                    value={
                      !empty(filterSearch.excludeAccountIds)
                        ? filterSearch.excludeAccountIds
                        : ''
                    }
                    onChange={e =>
                      setFilterSearch({
                        ...filterSearch,
                        excludeAccountIds: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ margin: 0 }}>
              <Col xl="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Form.Group className="mb-3" controlId="formBasicToDate">
                  <Form.Label>Fecha Hasta</Form.Label>
                  <DatePickerInput
                    onChange={(jsDate, dateString) => {
                      setFilterSearch({
                        ...filterSearch,
                        updatedUntilDate: moment(jsDate).format('YYYY-MM-DD')
                      });
                    }}
                    defaultValue={moment().format('YYYY-MM-DD')}
                    value={
                      !empty(filterSearch.updatedUntilDate)
                        ? filterSearch.updatedUntilDate
                        : ''
                    }
                    className="banex-admin-datepicker"
                  />
                </Form.Group>
              </Col>
              <Col xl="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{'Nivel Usuario >='}</Form.Label>
                  <Form.Control
                    key={'level'}
                    type="text"
                    placeholder="Ingrese nivel"
                    value={!empty(filterSearch.level) ? filterSearch.level : ''}
                    onChange={e =>
                      setFilterSearch({
                        ...filterSearch,
                        level: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col
                xl="1"
                style={{ paddingRight: 0, paddingLeft: 0, paddingTop: '3em' }}>
                <Button
                  style={{ marginBottom: '1em', marginLeft: '1em' }}
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    FilterRows({ filterSearch });
                  }}>
                  Filtrar
                </Button>
              </Col>
              <Col
                xl="1"
                style={{ paddingRight: 0, paddingLeft: 0, paddingTop: '3em' }}>
                <div style={{cursor:"pointer"}} onClick={resetFilters}><span className='mr-2'>Reset filters</span><img src={RotateRight} width="20px" alt="reset option" height="20px" /></div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }

  useEffect(() => {
    if (!empty(userInfo)) {
      loadUsers();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="content-container">
        <Loading loading={loading} />
        <Col xs="12" lg="12">
          <h1 style={{ textAlign: 'left' }}>
            {context.t('Reenvío de clientes a Acuant')}
          </h1>
        </Col>
        {FiltersComponent()}
        <TableContainer
          setLoading={setLoading}
          loading={loading}
          filterConfig={filterConfig}
          rows={rows}
          changePage={changePage}
          onChangeLimit={onChangeLimit}
          sendInfo={sendInfo}
          {...props}
        />
      </div>
    </React.Fragment>
  );
};

AcuantPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AcuantPage);
