import empty from 'is-empty';
import jwt_decode from 'jwt-decode';

import IDashboard from '../images/new_icons/category.svg';
import Market from '../images/new_icons/market.svg';
import SecurityUser from '../images/new_icons/security-user.svg';
import ProfileUser from '../images/new_icons/profile-user.svg';
import ReceiptText from '../images/new_icons/receipt-text.svg';
import DocumentText from '../images/new_icons/document-text.svg';
import DollarCircle from '../images/new_icons/dollar-circle2.svg';
import ProfileTick from '../images/new_icons/profile-tick2.svg';

export const eventStatusInput = [
  {
    value: 'PENDING',
    label: 'PENDING'
  },
  {
    value: 'REVIEWING',
    label: 'REVIEWING'
  },
  {
    value: 'RESOLVED',
    label: 'RESOLVED'
  }
];

export const userLevels = {
  0: 'Nivel 0: Usuario registrado',
  1: 'Nivel 1: KYC 1 aprobado',
  2: 'Nivel 2: KYC 2 aprobado',
  3: 'Nivel 3: KYC 3 aprobado',
  4: 'Nivel 4: Usuario corporativo',
  5: 'Nivel 5: uso interno',
  10: 'Nivel 10: KYC 10 aprobado',
  11: 'Nivel 11: KYC 11 aprobado',
  12: 'Nivel 12: KYC 12 aprobado',
  13: 'Nivel 13: KYC 13 aprobado',
  14: 'Nivel 14: KYC 14 aprobado',
  17: 'Nivel 17: Corporativo sin verificacón',
  10: 'Nivel 18: KYC 18 corporativo aprobado'
};

export const alertCalificationInput = [
  {
    value: 'No Inusual',
    label: 'No Inusual'
  },
  {
    value: 'Justificada',
    label: 'Justificada'
  },
  {
    value: 'Con Riesgo LAFT',
    label: 'Con Riesgo LAFT'
  }
];

export const userStatusInput = [
  {
    value: '0',
    label: 'ACTIVE'
  },
  {
    value: '1',
    label: 'BLOCKED'
  },
  {
    value: '2',
    label: 'DELETED'
  },
  {
    value: '3',
    label: 'INACTIVE'
  }
];

export const userRoleIdInput = [
  {
    value: 1,
    label: 'Client'
  },
  {
    value: 2,
    label: 'Admin'
  },
  {
    value: 3,
    label: 'API Permission'
  }
];

export const userRoles = [
  {
    value: 'admin_rw',
    label: 'Super admin'
  },
  {
    value: 'cum_rw',
    label: 'Cumplimiento acceso escritura'
  },
  {
    value: 'cum_r',
    label: 'Cumplimiento solo lectura'
  },
  {
    value: 'ope_rw',
    label: 'Operaciones acceso escritura'
  },
  {
    value: 'ope_r',
    label: 'Operaciones acceso lectura'
  },
  {
    value: 'con_rw',
    label: 'Contabilidad acceso escritura'
  },
  {
    value: 'con_r',
    label: 'Contabilidad acceso lectura'
  },
  {
    value: 'sop_r',
    label: 'Soporte acceso lectura'
  },
  {
    value: 'onb_rw',
    label: 'Onboarding acceso escritura'
  },
  {
    value: 'onb_r',
    label: 'Onboarding acceso lectura'
  },
  {
    value: 'apr_r',
    label: 'Aprobación acceso lectura'
  },
  {
    value: 'apr_rw',
    label: 'Aprobación acceso escritura'
  }
];

export const MenuItemsAccessV2 = [
  {
    name: 'Dashboard',
    icon: IDashboard,
    iconMain: 'DashboardIcon',
    childrens: [
      {
        menu: {
          text: 'Dashboard',
          icon: 'DashboardIcon',
          path: '/dashboard'
        },
        roleAccess: [
          'norole_r',
          'admin_rw',
          'cum_rw',
          'cum_r',
          'ope_rw',
          'ope_r',
          'con_rw',
          'con_r',
          'sop_r',
          'onb_rw',
          'onb_r',
          'apr_r',
          'apr_rw'
        ]
      }
    ]
  },
  {
    name: 'Marketing',
    icon: Market,
    iconMain: 'EmojiObjectsIcon',
    childrens: [
      {
        menu: {
          text: 'Sendgrid',
          icon: 'EmailIcon',
          path: '/marketing'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      },
      {
        menu: {
          text: 'Notifications',
          icon: 'AnnouncementIcon',
          path: '/notificaciones'
        },
        roleAccess: ['admin_rw', 'ope_rw']
      },
      {
        menu: {
          text: 'Listmonk',
          icon: 'ReceiptLongIcon',
          path: '/appmarketing'
        },
        roleAccess: ['admin_rw', 'ope_rw']
      }
    ]
  },
  {
    name: 'Compliance',
    icon: SecurityUser,
    iconMain: 'SecurityIcon',
    childrens: [
      {
        menu: {
          text: 'Black List',
          icon: 'PersonAddDisabledIcon',
          path: '/blacklist'
        },
        roleAccess: ['admin_rw', 'cum_rw', 'cum_r']
      },
      {
        menu: {
          text: 'Aml Alerts',
          icon: 'VerifiedUserIcon',
          path: '/audit'
        },
        roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
      },
      {
        menu: {
          text: 'Countries',
          icon: 'PublicIcon',
          path: '/countries'
        },
        roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
      },
      {
        menu: {
          text: 'Reniec Search',
          icon: 'SearchIcon',
          path: '/reniec-search'
        },
        roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
      },
      {
        menu: {
          text: 'Acuant',
          icon: 'AddBoxIcon',
          path: '/acuant'
        },
        roleAccess: ['admin_rw', 'cum_rw', 'cum_r']
      },
      {
        menu: {
          text: 'Acuant Logs',
          icon: 'SearchIcon',
          path: '/acuant/logs'
        },
        roleAccess: ['admin_rw', 'cum_rw', 'cum_r']
      }
    ]
  },
  {
    name: 'Customers',
    icon: ProfileUser,
    iconMain: 'PeopleAltIcon',
    childrens: [
      {
        menu: {
          text: 'Natural',
          icon: 'NaturePeopleIcon',
          path: '/admin-levels'
        },
        roleAccess: [
          'admin_rw',
          'cum_r',
          'ope_rw',
          'ope_r',
          'con_r',
          'sop_r',
          'onb_rw',
          'onb_r'
        ]
      },
      {
        menu: {
          text: 'Legal',
          icon: 'BusinessIcon',
          path: '/admin-levels/corporate'
        },
        roleAccess: [
          'admin_rw',
          'cum_r',
          'ope_rw',
          'ope_r',
          'con_r',
          'sop_r',
          'onb_rw',
          'onb_r'
        ]
      },
      {
        menu: {
          text: 'Bank Accounts',
          icon: 'AccountBalanceIcon',
          path: '/fiat-accounts'
        },
        roleAccess: ['admin_rw', 'cum_r', 'ope_rw', 'ope_r', 'con_r', 'sop_r']
      }
    ]
  },
  {
    name: 'Tickets',
    icon: ReceiptText,
    iconMain: 'AssignmentIcon',
    childrens: [
      {
        menu: {
          text: 'Deposits',
          icon: 'SystemUpdateAltIcon',
          path: '/deposits'
        },
        roleAccess: ['admin_rw', 'cum_r', 'ope_r', 'con_r', 'sop_r']
      },
      {
        menu: {
          text: 'Withdraws',
          icon: 'OpenInNewIcon',
          path: '/withdraws'
        },
        roleAccess: ['admin_rw', 'cum_r', 'ope_r', 'con_rw', 'con_r', 'sop_r']
      },
      {
        menu: {
          text: 'Tx Hash',
          icon: 'PlaylistAddCheckIcon',
          path: '/tx-ids'
        },
        roleAccess: ['admin_rw', 'cum_r', 'ope_rw', 'ope_r', 'sop_r']
      }
    ]
  },
  {
    name: 'Billing',
    icon: DocumentText,
    iconMain: 'ListAltIcon',
    childrens: [
      {
        menu: {
          text: 'Ticket & invoices',
          icon: 'DescriptionIcon',
          path: '/admin-invoices-tickets'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      },
      {
        menu: {
          text: 'Active Invoice',
          icon: 'ReceiptIcon',
          path: '/invoice-settings'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      },
      {
        menu: {
          text: 'Series/Correlative',
          icon: 'BuildIcon',
          path: '/serial-correlative'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      }
    ]
  },
  {
    name: 'Finance',
    icon: DollarCircle,
    iconMain: 'CreditCardIcon',
    childrens: [
      {
        menu: {
          text: 'Bank Transactions',
          icon: 'ImportExportIcon',
          path: '/bank-transactions'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      },
      {
        menu: {
          text: 'Payment attempts (TDC)',
          icon: 'AccountBalanceWalletIcon',
          path: '/payment-attempts-tdc/10/1'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      },
      {
        menu: {
          text: 'Fees',
          icon: 'TollIcon',
          path: '/admin-fees'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      },
      {
        menu: {
          text: 'Banking movements',
          icon: 'DescriptionIcon',
          path: '/banking-movements'
        },
        roleAccess: ['admin_rw', 'con_rw', 'con_r']
      }
    ]
  },
  {
    name: 'Reports',
    icon: DocumentText,
    iconMain: 'ReportIcon',
    childrens: [
      {
        menu: {
          text: 'KPIs report',
          icon: 'TocIcon',
          path: '/admin-reports/kpis'
        },
        roleAccess: ['admin_rw', 'cum_r', 'ope_r']
      },
      {
        menu: {
          text: 'Trades report',
          icon: 'TocIcon',
          path: '/admin-reports/trades'
        },
        roleAccess: ['admin_rw', 'cum_r', 'ope_r']
      }
    ]
  },
  {
    name: 'Rates',
    icon: IDashboard,
    iconMain: 'AttachMoneyIcon',
    childrens: [
      {
        menu: {
          text: 'Fx Rates',
          icon: 'AttachMoneyIcon',
          path: '/rates'
        },
        roleAccess: ['admin_rw', 'ope_rw', 'sop_r', 'con_r']
      }
    ]
  },
  {
    name: 'Permits',
    icon: ProfileTick,
    iconMain: 'HowToRegSharpIcon',
    childrens: [
      {
        menu: {
          text: 'Users',
          icon: 'SupervisedUserCircleIcon',
          path: '/admin-users'
        },
        roleAccess: ['admin_rw', 'sop_r']
      }
    ]
  }
];

const roleActionAccess = [
  {
    action: 'deposit_find_transaction',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'deposit_accept',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'deposit_reject',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'bank_buttons_show',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'process_info_withdraw',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'withdraw_accept',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'withdraw_update',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'withdraw_reject',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'withdraw_find_transaction',
    rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
  },
  {
    action: 'user_edit_role',
    rolesAccess: ['admin_rw']
  },
  {
    action: 'rates_add_update',
    rolesAccess: ['admin_rw', 'ope_rw']
  },
  {
    action: 'lock_and_delete_uers',
    rolesAccess: ['admin_rw', 'ope_rw']
  }
];

export const hasAccesstoAction = action => {
  let rolesAccess = getCurrentRoleAccess();
  let currentAction = roleActionAccess.find(r => r.action === action);
  let result = false;

  if (!empty(currentAction)) {
    let hasAccess = currentAction.rolesAccess.find(r =>
      rolesAccess.includes(r)
    );
    if (!empty(hasAccess)) {
      result = true;
    }
  }

  return result;
};

export const getCurrentRoleAccess = () => {
  let roleAccess = ['norole_r'];

  let userToken = getCurrentUser();

  if (!empty(userToken) && !empty(userToken.role_admin_access)) {
    roleAccess = JSON.parse(userToken.role_admin_access);
  }

  return roleAccess;
};

export const getCurrentUser = () => {
  let userToken = localStorage.getItem('tokenDB');
  window.addEventListener('storage', () => {
    let token = localStorage.getItem('tokenDB');
    if (!empty(token)) {
      userToken = token;
    }
  });

  let result = {};
  if (!empty(userToken)) {
    let decoded = jwt_decode(userToken);
    result = {
      ...decoded
    };
  }
  return result;
};

export const constructMenu = rolesIn => {
  let MenuV2 = MenuItemsAccessV2;
  let menuComplete = [];
  MenuV2.forEach(function(valor, indice, array) {
    let menuPadre = {
      name: valor.name,
      icon: valor.icon,
      iconMain: valor.iconMain
    };
    let tempChildrens = [];
    for (let r of rolesIn) {
      let filtrarChildrens = valor.childrens.filter(c =>
        c.roleAccess.includes(r)
      );
      if (!empty(filtrarChildrens)) {
        tempChildrens.push(...filtrarChildrens);
      }
    }
    let childrens = [];
    for (let th of tempChildrens) {
      let ifExist = childrens.find(f => f.menu.text === th.menu.text);
      if (empty(ifExist)) {
        childrens.push(th);
      }
    }
    menuPadre.childrens = childrens;
    menuComplete.push(menuPadre);
  });
  return menuComplete;
};
