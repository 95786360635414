import UtilsHelper from "../../helpers/Utils.helper";

export const getDepositAlertsByDate = async (userId,date) => {
  const path = `/api/v1/levels_limit/logs/${date.year}/${date.month}`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const getDepositAlertsByAccountId = async (userId,date,accountId) => {
  const path = `/api/v1/levels_limit/logs/${date.year}/${date.month}/${accountId}`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const updateAlertStatus = async (userId,data) => {
  const path = `/api/v1/levels_limit/month_log`;
  const configRequest = {
    userId,
    method: "POST",
    path,
    data
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const getLogs = async (userId,id) => {
  const path = `/api/v1/levels_limit/action_logs/${id}`;
  const configRequest = {
    userId,
    method: "GET",
    path
    };
  return await UtilsHelper.sendRequest(configRequest);
};
export const getUser = async (userId,id) => {
  const path = `/api/${id}/users`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};