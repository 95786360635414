export const province = {
  Amazonas: [
    { id: 'Chachapoyas', name: 'Chachapoyas' },
    { id: 'Bagua', name: 'Bagua' },
    { id: 'Bongará', name: 'Bongará' },
    { id: 'Condorcanqui', name: 'Condorcanqui' },
    { id: 'Luya', name: 'Luya' },
    { id: 'Rodríguez de Mendoza', name: 'Rodríguez de Mendoza' },
    { id: 'Utcubamba', name: 'Utcubamba' }
  ],
  Ancash: [
    { id: 'Aija', name: 'Aija' },
    { id: 'Antonio Raymondi', name: 'Antonio Raymondi' },
    { id: 'Asunción', name: 'Asunción' },
    { id: 'Bolognesi', name: 'Bolognesi' },
    { id: 'Carhuaz', name: 'Carhuaz' },
    { id: 'Carlos Fermín Fitzcarrald', name: 'Carlos Fermín Fitzcarrald' },
    { id: 'Casma', name: 'Casma' },
    { id: 'Corongo', name: 'Corongo' },
    { id: 'Huari', name: 'Huari' },
    { id: 'Huaraz', name: 'Huaraz' },
    { id: 'Huarmey', name: 'Huarmey' },
    { id: 'Huaylas', name: 'Huaylas' },
    { id: 'Mariscal Luzuriaga', name: 'Mariscal Luzuriaga' },
    { id: 'Ocros', name: 'Ocros' },
    { id: 'Pallasca', name: 'Pallasca' },
    { id: 'Pomabamba', name: 'Pomabamba' },
    { id: 'Recuay', name: 'Recuay' },
    { id: 'Santa', name: 'Santa' },
    { id: 'Sihuas', name: 'Sihuas' },
    { id: 'Yungay', name: 'Yungay' }
  ],
  Apurimac: [
    { id: 'Abancay', name: 'Abancay' },
    { id: 'Andahuaylas', name: 'Andahuaylas' },
    { id: 'Antabamba', name: 'Antabamba' },
    { id: 'Cotabambas', name: 'Cotabambas' },
    { id: 'Chincheros', name: 'Chincheros' },
    { id: 'Grau', name: 'Grau' }
  ],
  Arequipa: [
    { id: 'Arequipa', name: 'Arequipa' },
    { id: 'Camaná', name: 'Camaná' },
    { id: 'Caravelí', name: 'Caravelí' },
    { id: 'Castilla', name: 'Castilla' },
    { id: 'Caylloma', name: 'Caylloma' },
    { id: 'Condesuyos', name: 'Condesuyos' },
    { id: 'Islay', name: 'Islay' },
    { id: 'La Uniòn', name: 'La Uniòn' }
  ],
  Ayacucho: [
    { id: 'Cangallo', name: 'Cangallo' },
    { id: 'Huamanga', name: 'Huamanga' },
    { id: 'Sancos', name: 'Huanca Sancos' },
    { id: 'Huanta', name: 'Huanta' },
    { id: 'Mar', name: 'La Mar' },
    { id: 'Lucanas', name: 'Lucanas' },
    { id: 'Parinacochas', name: 'Parinacochas' },
    { id: 'Pàucar del Sara Sara', name: 'Pàucar del Sara Sara' },
    { id: 'Sucre', name: 'Sucre' },
    { id: 'Víctor Fajardo', name: 'Víctor Fajardo' },
    { id: 'Vilcas Huamán', name: 'Vilcas Huamán' }
  ],
  Cajamarca: [
    { id: 'Cajamarca', name: 'Cajamarca' },
    { id: 'Cajabamba', name: 'Cajabamba' },
    { id: 'Celendín', name: 'Celendín' },
    { id: 'Chota', name: 'Chota' },
    { id: 'Contumazá', name: 'Contumazá' },
    { id: 'Cutervo', name: 'Cutervo' },
    { id: 'Hualgayoc', name: 'Hualgayoc' },
    { id: 'Jaén', name: 'Jaén' },
    { id: 'San Ignacio', name: 'San Ignacio' },
    { id: 'San Marcos', name: 'San Marcos' },
    { id: 'San Miguel', name: 'San Miguel' },
    { id: 'San Pablo', name: 'San Pablo' },
    { id: 'Santa Cruz', name: 'Santa Cruz' }
  ],
  Callao: [{ id: 'Prov. Const. del Callao', name: 'Prov. Const. del Callao' }],
  Cusco: [
    { id: 'Acomayo', name: 'Acomayo' },
    { id: 'Anta', name: 'Anta' },
    { id: 'Calca', name: 'Calca' },
    { id: 'Canas', name: 'Canas' },
    { id: 'Canchis', name: 'Canchis' },
    { id: 'Cusco', name: 'Cusco' },
    { id: 'Chumbivilcas', name: 'Chumbivilcas' },
    { id: 'Espinar', name: 'Espinar' },
    { id: 'La Convención', name: 'La Convención' },
    { id: 'Paruro', name: 'Paruro' },
    { id: 'Paucartambo', name: 'Paucartambo' },
    { id: 'Quispicanchi', name: 'Quispicanchi' },
    { id: 'Urubamba', name: 'Urubamba' }
  ],
  Huancavelica: [
    { id: 'Acobamba', name: 'Acobamba' },
    { id: 'Angaraes', name: 'Angaraes' },
    { id: 'Castrovirreyna', name: 'Castrovirreyna' },
    { id: 'Churcampa', name: 'Churcampa' },
    { id: 'Huaytará', name: 'Huaytará' },
    { id: 'Huancavelica', name: 'Huancavelica' },
    { id: 'Tayacaja', name: 'Tayacaja' }
  ],
  Huanuco: [
    { id: 'Ambo', name: 'Ambo' },
    { id: 'Mayo', name: 'Dos de Mayo' },
    { id: 'Huacaybamba', name: 'Huacaybamba' },
    { id: 'Huánuco', name: 'Huánuco' },
    { id: 'Huamalíes', name: 'Huamalíes' },
    { id: 'Marañón', name: 'Marañón' },
    { id: 'Leoncio Prado', name: 'Leoncio Prado' },
    { id: 'Pachitea', name: 'Pachitea' },
    { id: 'Puerto Inca', name: 'Puerto Inca' },
    { id: 'Lauricocha', name: 'Lauricocha' },
    { id: 'Yarowilca', name: 'Yarowilca' }
  ],
  Ica: [
    { id: 'Chincha', name: 'Chincha' },
    { id: 'Ica', name: 'Ica' },
    { id: 'Nasca', name: 'Nasca' },
    { id: 'Palpa', name: 'Palpa' },
    { id: 'Pisco', name: 'Pisco' }
  ],
  Junin: [
    { id: 'Chanchamayo', name: 'Chanchamayo' },
    { id: 'Chupaca', name: 'Chupaca' },
    { id: 'Concepción', name: 'Concepción' },
    { id: 'Huancayo', name: 'Huancayo' },
    { id: 'Jauja', name: 'Jauja' },
    { id: 'Junín', name: 'Junín' },
    { id: 'Satipo', name: 'Satipo' },
    { id: 'Tarma', name: 'Tarma' },
    { id: 'Yauli', name: 'Yauli' }
  ],
  'La Libertad': [
    { id: 'Ascope', name: 'Ascope' },
    { id: 'Bolívar', name: 'Bolívar' },
    { id: 'Daniel Alcides Carrión', name: 'Daniel Alcides Carrión' },
    { id: 'Chepén', name: 'Chepén' },
    { id: 'Chimú', name: 'Gran Chimú' },
    { id: 'Julcán', name: 'Julcán' },
    { id: 'Otuzco', name: 'Otuzco' },
    { id: 'Pacasmayo', name: 'Pacasmayo' },
    { id: 'Pataz', name: 'Pataz' },
    { id: 'Santiago de Chuco', name: 'Santiago de Chuco' },
    { id: 'Trujillo', name: 'Trujillo' },
    { id: 'Virú', name: 'Virú' }
  ],
  Lambayeque: [
    { id: 'Chiclayo', name: 'Chiclayo' },
    { id: 'Ferreñafe', name: 'Ferreñafe' },
    { id: 'Lambayeque', name: 'Lambayeque' }
  ],
  Lima: [
    { id: 'Barranca', name: 'Barranca' },
    { id: 'Cajatambo', name: 'Cajatambo' },
    { id: 'Canta', name: 'Canta' },
    { id: 'Cañete', name: 'Cañete' },
    { id: 'Huaral', name: 'Huaral' },
    { id: 'Huarochirí', name: 'Huarochirí' },
    { id: 'Huaura', name: 'Huaura' },
    { id: 'Lima', name: 'Lima' },
    { id: 'Oyón', name: 'Oyón' },
    { id: 'Yauyos', name: 'Yauyos' }
  ],
  Loreto: [
    { id: 'Alto Amazonas', name: 'Alto Amazonas' },
    { id: 'Datem del Marañón', name: 'Datem del Marañón' },
    { id: 'Loreto', name: 'Loreto' },
    { id: 'Mariscal Ramón Castilla', name: 'Mariscal Ramón Castilla' },
    { id: 'Maynas', name: 'Maynas' },
    { id: 'Putumayo', name: 'Putumayo' },
    { id: 'Requena', name: 'Requena' },
    { id: 'Ucayali', name: 'Ucayali' }
  ],
  'Madre de Dios': [
    { id: 'Manu', name: 'Manu' },
    { id: 'Tambopata', name: 'Tambopata' },
    { id: 'Tahuamanu', name: 'Tahuamanu' }
  ],
  Moquegua: [
    { id: 'General Sánchez Cerro', name: 'General Sánchez Cerro' },
    { id: 'Ilo', name: 'Ilo' },
    { id: 'Mariscal Nieto', name: 'Mariscal Nieto' }
  ],
  Pasco: [
    { id: 'Daniel Alcides Carrión', name: 'Daniel Alcides Carrión' },
    { id: 'Pasco', name: 'Pasco' },
    { id: 'Oxapampa', name: 'Oxapampa' }
  ],
  Piura: [
    { id: 'Ayabaca', name: 'Ayabaca' },
    { id: 'Huancabamba', name: 'Huancabamba' },
    { id: 'Morropón', name: 'Morropón' },
    { id: 'Paita', name: 'Paita' },
    { id: 'Piura', name: 'Piura' },
    { id: 'Sullana', name: 'Sullana' },
    { id: 'Talara', name: 'Talara' },
    { id: 'Sechura', name: 'Sechura' }
  ],
  Puno: [
    { id: 'Azángaro', name: 'Azángaro' },
    { id: 'Carabaya', name: 'Carabaya' },
    { id: 'Chucuito', name: 'Chucuito' },
    { id: 'El Collao', name: 'El Collao' },
    { id: 'Huancané', name: 'Huancané' },
    { id: 'Lampa', name: 'Lampa' },
    { id: 'Melgar', name: 'Melgar' },
    { id: 'Moho', name: 'Moho' },
    { id: 'Puno', name: 'Puno' },
    { id: 'San Antonio de Putina', name: 'San Antonio de Putina' },
    { id: 'San Román', name: 'San Román' },
    { id: 'Sandia', name: 'Sandia' },
    { id: 'Yunguyo', name: 'Yunguyo' }
  ],
  'San Martin': [
    { id: 'Bellavista', name: 'Bellavista' },
    { id: 'El Dorado', name: 'El Dorado' },
    { id: 'Huallaga', name: 'Huallaga' },
    { id: 'Lamas', name: 'Lamas' },
    { id: 'Mariscal Cáceres', name: 'Mariscal Cáceres' },
    { id: 'Moyobamba', name: 'Moyobamba' },
    { id: 'Picota', name: 'Picota' },
    { id: 'Rioja', name: 'Rioja' },
    { id: 'San Martín', name: ' San Martín' },
    { id: 'Tocache', name: 'Tocache' }
  ],
  Tacna: [
    { id: 'Candarave', name: 'Candarave' },
    { id: 'Jorge Basadre', name: 'Jorge Basadre' },
    { id: 'Tacna', name: 'Tacna' },
    { id: 'Tarata', name: 'Tarata' }
  ],
  Tumbes: [
    { id: 'Contralmirante Villa', name: 'Contralmirante Villa' },
    { id: 'Tumbes', name: 'Tumbes' },
    { id: 'Zarumilla', name: 'Zarumilla' }
  ],
  Ucayali: [
    { id: 'Atalaya', name: 'Atalaya' },
    { id: 'Coronel Portillo', name: 'Coronel Portillo' },
    { id: 'Padre Abad', name: 'Padre Abad' },
    { id: 'Purús', name: 'Purús' }
  ]
};
