import React, { useContext, useEffect, useState } from 'react'
import AllBankAccountData from '../../../../components/FallComponents/BankAccountPage/AllBankAccountData';
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns'
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable';
import { useFilter } from '../../../../hooks/useFilter';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import close from '../../../../images/new_icons/close-circle.svg';
import isEmpty from 'is-empty';
import BankAccountController from '../../../FallControllers/BankAccountController';
import BankAccountDetails from '../../../../components/FallComponents/BankAccountPage/BankAccountDetails/BankAccountDetails';
import { Context as ContextUser } from '../../../../providers/UserProvider'

const BankAccountPage = (props, context) => {

   const { userInfo } = props;

   const [changeLimit, setChangeLimit] = useState(false);
   const [withRightSection, setWithRightSection] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [rows, setRows] = useState([]);
   const [loading, setLoading] = useState(true);
   const [dataAccount, setDataAccount] = useState({});
   const [approvedButton, setApproveButton] = useState(false);
   const [rejectedButton, setRejectedButton] = useState(false);
   const [editPermission, setEditPermission] = useState(false);

   const {
      filterSearch,
      filterConfig,
      offset,
      showingTo,
      changePage,
      onChangeLimit,
      resetFilters,
      setFilterSearch
   } = useFilter({ totalRows });

   const { 
      modulesByUserProfile,
      handleWritePermission
   } = useContext(ContextUser);

   const headerOptions = [
      {
         image: close,
         alt: 'close option',
         action: () => {
            setWithRightSection(false);
         }
      }
   ];

   const handleInitialConfig = () => {
      resetFilters(30, { status: { in: 0 } })
      setChangeLimit(true);
   }

   const handleLoadBanksAccounts = () => {
      BankAccountController.loadBankAccount({
         userInfo,
         filterConfig,
         filterSearch,
         setLoading,
         setRows,
         setTotalRows,
         context
      })
   }

   const handleDownloadRows = () => {
      BankAccountController.downloadAccounts({
         userInfo,
         context
      })
   }

   const handleSelectRow = ({ row }) => {
      setWithRightSection(true);
      setDataAccount(row);
   };

   const handleApproveAccount = (id) => {
      BankAccountController.approveAccount({
         userInfo,
         id,
         handleLoadBanksAccounts,
         setWithRightSection,
         setApproveButton,
         context
      })
   }

   const handleRejectedAccount = (id) => {
      BankAccountController.rejectedAccount({
         userInfo,
         id,
         handleLoadBanksAccounts,
         setWithRightSection,
         setRejectedButton,
         context
      })
   }

   useEffect(() => {
      if (!changeLimit) {
         handleInitialConfig();
      }
      if (!isEmpty(userInfo) && changeLimit && !isEmpty(modulesByUserProfile)) {
         setEditPermission(handleWritePermission('cuentas-bancarias'))
         handleLoadBanksAccounts();
      }
   }, [modulesByUserProfile, userInfo, filterConfig, filterSearch])


   return (
      <div className="beta-content-container">
         <ResizableColumns
            withRightSection={withRightSection}
            minWidth={'585px'}
            childrenOne={
               <AllBankAccountData
                  setFilterSearch={setFilterSearch}
                  handleInitialConfig={handleInitialConfig}
                  setWithRightSection={setWithRightSection}
                  handleDownloadRows={handleDownloadRows}
                  handleSelectRow={handleSelectRow}
                  withRightSection={withRightSection}
                  onChangeLimit={onChangeLimit}
                  filterConfig={filterConfig}
                  filterSearch={filterSearch}
                  totalRows={totalRows}
                  rows={rows}
                  loading={loading}
                  offset={offset}
                  showingTo={showingTo}
                  changePage={changePage}
                  selectedId={dataAccount.id}
               />
            }
            childrenTwo={
               <RightResizable
                  headerOptions={headerOptions}
                  loadingData={{
                     title: 'Cargando cuentas bancarias',
                     isLoading: false
                  }}>
                  <BankAccountDetails
                     detailBankAccount={dataAccount}
                     approvedButton={approvedButton}
                     rejectedButton={rejectedButton}
                     handleApproveAccount={handleApproveAccount}
                     handleRejectedAccount={handleRejectedAccount} 
                     editPermission={editPermission} />
               </RightResizable>
            }
         />
      </div>
   )
}

const mapStateToProps = ({ user: { userInfo } }) => {
   return { userInfo };
};

BankAccountPage.contextTypes = {
   t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(BankAccountPage);