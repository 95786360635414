import isEmpty from "is-empty";
import moment from "moment-timezone";

export default class DateHelper {
  static formatDate = (date) => {
    if (isEmpty(date)) return "-";
    return moment(date)
      .tz("America/Lima")
      .format("DD/MM/YYYY hh:mm a")
      .toString();
  };
  static formatDateToYear = (date) => {
    if (isEmpty(date)) return "";
    return moment(date).tz("America/Lima").format("DD/MM/YYYY").toString();
  };

  static invertedYearFormat = (date) => {
    if (isEmpty(date)) return "";
    return moment(date).tz("America/Lima").format("YYYY-MM-DD").toString();
  };

  static timezoneFormatDate = (date) => {
    if (isEmpty(date)) return "";
    return date.split("T")[0].split("-").reverse().join("-");
  };

  static utcFormatter = (date) => {
    if (isEmpty(date)) return "";
    return moment(date).utc().format("DD/MM/YYYY hh:mm a")
  };

  static utcFormatter24h = (date) => {
    if (isEmpty(date)) return "";
    return moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
  };

  static formatDateTimezone= (date) => {
    if (isEmpty(date)) return "";
    return moment(date).tz("America/Lima").format("DD/MM/YYYY HH:mm:ss");
  };

  static getCurrentDate = () => {
    const currentDate = new Date(Date.now());
    const currentYear = currentDate.toDateString().split(" ").reverse()[0];
    const currentMonth = currentDate.getMonth() + 1;
    return { year: currentYear, month: currentMonth };
  };

  static ticksToTimestamp = (ticks) => {
    const newDate = Number.isInteger(+ticks)
      ? +ticks / 1e4 + new Date("0001-01-01T00:00:00Z").getTime()
      : null;
    return moment(new Date(newDate)).utc().format("DD/MM/YYYY HH:mm");
  };

  static convertNumberToMonth = (number) => {
    if (number === null) {
      return null;
    }  
    const validNumberRegex = /^([1-9]|1[0-2])$/;
    if (validNumberRegex.test(number)) {
      const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ];
      return months[parseInt(number, 10) - 1];
    } else {
      return 'The number must be between 1 and 12';
    }
  };
}
