import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../hooks/useFilter";
import isEmpty from "is-empty";
import AllNotificationsData from "../../../components/FallComponents/NotificationsPage/AllNotificationsData";
import NotificationsController from "../../FallControllers/NotificationsController";
import { useModal } from "../../../hooks/useModal";
import EditModalForm from "../../News/ModalForm";

const NotificationsPage = (props, context) => {
  const { userInfo } = props;
  const { showModal, handleToggleModal } = useModal();
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [editInputs, setEditInputs] = useState({})
  const [selectedNotification, setSelectedNotification] = useState({});
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const handleSelectRow = ({ row }) => {
    handleToggleModal()
    setSelectedNotification(row);
    const selectedNotification = {
      ...row,
      eventTitleES: row.eventTitle.es,
      eventTitleEN: row.eventTitle.en,
      eventTextES: row.eventText.es,
      eventTextEN: row.eventText.en,
      eventTextOnlyES: row.eventTextOnly.es,
      eventTextOnlyEN: row.eventTextOnly.en,
      eventExternalLinkES: row.eventExternalLink.es,
      eventExternalLinkEN: row.eventExternalLink.en,
      status: row.status === 1,
      statusLink: row.statusLink === 1
    };
    setEditInputs(selectedNotification)
  };

  const handleEditModal = () => handleToggleModal();
  const handleUpdateNotification = async (checkedData) => {
    const notificationData = rows.find((item) => item.id === checkedData.id);
    const {
      eventImages,
      eventExternalLink,
      eventText,
      eventTextOnly,
      eventTitle,
    } = notificationData;
    const data = {
      ...notificationData,
      status: checkedData.isChecked === 0,
      eventTitleES: eventTitle.es,
      eventTitleEN: eventTitle.en,
      eventTextES: eventText.es,
      eventTextEN: eventText.en,
      eventTextOnlyES: eventTextOnly.es,
      eventTextOnlyEN: eventTextOnly.en,
      eventExternalLinkES: eventExternalLink.es,
      eventExternalLinkEN: eventExternalLink.en,
      eventImagesES: eventImages.es,
      eventImagesEN: eventImages.en,
    };
    const response = await NotificationsController.updateNotification({
      userInfo,
      data,
    });
    setSelectedNotification({})
    if (response) {
      setRows((rows) =>
        rows.map((item) => {
          if (item.id === checkedData.id)
            return { ...item, status: checkedData.isChecked === 0 };
          return item;
        })
      );
    }
  };

  const getAllNotifications = () => {
    NotificationsController.getAllNotifications({
      userInfo,
      setLoading,
      setRows,
      setTotalRows,
      filterConfig,
      filterSearch,
    });
  };

  const handleDeleteNotification = () => NotificationsController.deleteNotification({userInfo, id:selectedNotification.id})
  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllNotifications();
  }, [userInfo, filterConfig, filterSearch]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        childrenOne={
          <AllNotificationsData
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            handleSelectRow={handleSelectRow}
            getAllNotifications={getAllNotifications}
            handleEditModal={handleEditModal}
            handleUpdateNotification={handleUpdateNotification}
          />
        }
      />
      <EditModalForm
        showModal={showModal}
        closeModal={() => {
          handleToggleModal()
          setSelectedNotification({})
          setEditInputs({})
        }}
        setInputs={setEditInputs}
        inputs={editInputs}
        modalType={isEmpty(selectedNotification) ? "add" : "edit"}
        getAllIncomingMessages={getAllNotifications}
        setLoading={setEditLoading}
        selectedNotification={selectedNotification}
        loading={editLoading}
        handleDeleteNotification={handleDeleteNotification}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

NotificationsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(NotificationsPage);
