import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';
const queryString = require('query-string');

export const getLevelById = async (userId, levelId) => {
  let result = {};
  try {
    let path = `/api/corporate/get/${levelId}`;
    let configRequest = {
      userId,
      method: 'get',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getBanexUserById = async (userId, updateById) => {
  let result = {};
  try {
    let path = `/api/banexcoin-user/${updateById}`;
    let configRequest = {
      userId,
      method: 'get',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getLevels = async (userId, page) => {
  let result = [];
  try {
    let path = '/api/levels';
    let params = {
      page: 1
    };

    if (!empty(page)) {
      params.page = page;
    }

    if (!empty(params)) {
      path += '?' + queryString.stringify(params);
    }

    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.levels : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const getLevelsBeta = async (userId, filterConfig, filterSearch) => {
  let result = [];
  try {
    let path = '/api/allcorporatelevel';

    let params = {
      filterConfig,
      filterSearch
    };

    let configRequest = {
      userId,
      method: 'post',
      path,
      data: params
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.levels : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const downloadAlllevelsBasic = async userId => {
  let result = [];
  try {
    let path = '/api/download/all-corporatelevelbeta';
    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let currentdate = new Date();
    let datetime =
      currentdate.getDate() +
      '_' +
      (currentdate.getMonth() + 1) +
      '_' +
      currentdate.getFullYear() +
      '___' +
      currentdate.getHours() +
      '_' +
      currentdate.getMinutes() +
      '_' +
      currentdate.getSeconds();
    let result = await HandlerBackend.sendRequest(configRequest);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `corporate_level_report_${datetime}.csv`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    throw error;
  }
  return result;
};

export const updateToIdentityMind = async (userId, levelId) => {
  let path = `/api/corporate/sent-idm/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const sendToCorfid = async (userId, data) => {
  let path = `/api/services/corfid/processuser`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
};

export const incrementLevel = async (
  userId,
  levelId,
  banexcoin_user_account_id,
  user_level
) => {
  let path = `/api/corporate/increment/${levelId}/${banexcoin_user_account_id}/${user_level}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const rejectedLevel = async (
  userId,
  levelId,
  banexcoin_user_account_id,
  user_level
) => {
  let path = `/api/levels/rejected/${levelId}/${banexcoin_user_account_id}/${user_level}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const infoIncorrect = async (
  userId,
  levelId,
  banexcoin_user_account_id
) => {
  let path = `/api/levels/info-incorrect/${levelId}/${banexcoin_user_account_id}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const correctFields = async (userId, levelId, fields) => {
  let headers = {
    // 'Content-Type': 'json/aplication'
  };
  let path = `/api/corporate/return-fields/${levelId}`;

  let model = {
    fields: fields
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const deleteAddress = async (userId, levelId) => {
  let path = `/api/levels/delete-address/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deletePhone = async (userId, levelId) => {
  let path = `/api/levels/delete-phone/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deleteDocument = async (userId, levelId) => {
  let path = `/api/levels/delete-document-data/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deleteBackDocument = async (userId, levelId) => {
  let path = `/api/levels/delete-back-document-data/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deleteFaceImage = async (userId, levelId) => {
  let path = `/api/levels/delete-face-image/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const modifyInfoByAdmin = async (userId, levelId) => {
  let path = `/api/levels/modify/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const editFormData = async (userId, data) => {
  let headers = {
    // 'Content-Type': 'json/aplication'
  };
  let path = '/api/levels/edit';
  let model = {
    id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    middle_name: data.middle_name,
    second_last_name: data.second_last_name,
    address_main: data.address_main,
    phone: data.phone,
    is_pep: data.is_pep,
    billing_country: data.billing_country,
    billing_address: data.billing_address,
    billing_zip: data.billing_zip,
    billing_state: data.billing_state,
    billing_city: data.billing_city,
    document_type: data.document_type,
    document_country: data.document_country,
    document_number: data.document_number,
    nationality: data.nationality,
    birth_country: data.birth_country,
    birthday: data.birthday,
    status: 8,
    occupation: data.occupation,
    work_center: data.work_center,
    residence_country: data.residence_country
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getBlacklistNews = async (userId, arrDocNum) => {
  let result = [];
  try {
    let path = '/api/blacklist-news';

    let configRequest = {
      userId,
      method: 'post',
      path,
      data: arrDocNum
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.blacklistList : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const getCountries = async userId => {
  let path = '/api/countries?restricted=true';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getLogs = async (userId, id) => {
  let result = [];
  let path = `/api/levels/getLogs/${id}`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });

  result = !empty(response.data) ? response.data.resultLogs : [];
  return result;
};

/********************************************************SERVICES***********************************************/

export const reniecRequest = async (userId, data) => {
  let result = [];
  let path = `/api/services/identity/faceverify`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const reniecLogRequest = async (userId, data) => {
  let result = [];
  let path = `/api/levels/renieclogs/`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const getLogReniecById = async (userId, id) => {
  let result = [];
  let path = `/api/levels/renieclogs/${id}`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'GET',
    path
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const sendToIDM = async (userId, id) => {
  let result = [];
  let path = `/api/corporate/sendShareholderToIdm/${id}`;

  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const getAllCorporateLogReniecById = async (userId, id) => {
  let result = [];
  let path = `/api/levels/corporaterenieclogs/${id}`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'GET',
    path
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const editCorporateInfo = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };

  let path = '/api/corporate/editcorporateInfo';
  let model = new FormData();
  for (let i in data) {
    let r = data[i];
    model.append(i, r);
  }

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const returnCorporateInfoFields = async (
  userId,
  data,
  id,
  textObservacion
) => {
  let headers = {
    //'Content-Type': 'multipart/form-data'
  };
  let path = '/api/corporate/returncorporateInfofields';
  let modal = {
    id,
    returnData: data,
    textObservacion
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: modal
  });
};

export const editCorporateShareholderInfo = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/corporate/editcorporateshareholderInfo';
  let model = new FormData();
  for (let i in data) {
    let r = data[i];
    model.append(i, r);
  }
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const returnCorporateShareholderInfoFields = async (
  userId,
  data,
  id,
  textObservacion
) => {
  let headers = {
    //'Content-Type': 'multipart/form-data'
  };
  let path = '/api/corporate/returncorporateShareholderInfofields';
  let modal = {
    id,
    returnData: data,
    textObservacion
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: modal
  });
};

export const generalActionLog = async (userId, data) => {
  let result = [];
  let path = `/api/action-logs`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};
