import React from "react";
import { statusList } from "../../../constants";
import EditForm from "./EditFormV2";

const currencies = {
  PEN: "Sol peruano (PEN)",
  USD: "Dólar estadounidense (USD)",
};
const HeaderSection = ({ data }) => {

  const status = data.status === 1 ? statusList[0].name : statusList[1].name
  return (
    <div className="header-section">
      <img
        className="profile-image"
        src={data.accountLogo}
        alt="profile image"
        style={{ height: "auto" }}
      />
      <div className="text-right">
        <h2>{data.swiftBankBySwiftBankId.bankName}</h2>
        <div className="info">
          <div className="option">
            <p>Moneda:</p>
            <p>{currencies[data.currencyCode]}</p>
          </div>
          <div>
            <p>Estado:</p>
            <p className={`color-status-${status}`}>{status}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

function BanexcoinAccountDetails(props) {
  const { buttonProps,countries, data, userInfo, selectedId, getAllBanexcoinAccounts,accessToAction } =
    props;

  const handleDataToEdit = data.find((item) => item.id === selectedId);

  return (
    <div className="layout-details banexcoin-accounts-page">
      <HeaderSection data={handleDataToEdit} />
      <div className="tab-content">
        <EditForm
          buttonProps={buttonProps}
          data={handleDataToEdit}
          userInfo={userInfo}
          getAllBanexcoinAccounts={getAllBanexcoinAccounts}
          accessToAction={accessToAction}
          countries={countries}
        />
      </div>
    </div>
  );
}

export default BanexcoinAccountDetails;
