export const LABELS = [
    {
      id: 'company_legal_name',
      name: 'Denomination or Full Legal Name of Company'
    },
    {
      id: 'legal_entity_type',
      name: 'Type of Legal Entity'
    },
    {
      id: 'non_profit',
      name: 'Non-Profit'
    },
    {
      id: 'other_non_profit',
      name: 'Other Non-Profit'
    },
    {
      id: 'other_legal_entity_type',
      name: 'Other Type of Legal Entity'
    },
    {
      id: 'taxpayer_identification_number_type',
      name: 'Type of Taxpayer Identification Number'
    },
    {
      id: 'other_taxpayer_identification_number_type',
      name: 'Other Type of Taxpayer Identification Number'
    },
    {
      id: 'taxpayer_identification_number',
      name: 'Taxpayer Identification Number'
    },
    {
      id: 'corporate_purpose',
      name: 'Corporate Purpose'
    },
    {
      id: 'company_address',
      name: 'Company Address'
    },
    {
      id: 'city',
      name: 'City'
    },
    {
      id: 'state',
      name: 'State'
    },
    {
      id: 'zip_code',
      name: 'Zip Code'
    },
    {
      id: 'country_incorporation',
      name: 'Country of incorporation'
    },
    {
      id: 'registration_date',
      name: 'Registration Date (Constitution)'
    },
    {
      id: 'linked_economic_group',
      name: 'Linked economic group (if applicable)'
    },
    {
      id: 'company_phone',
      name: 'Company Phone'
    },
    {
      id: 'full_name',
      name: 'Full name'
    },
    {
      id: 'job_position',
      name: 'Job Position'
    },
    {
      id: 'phone',
      name: 'Phone'
    },
    {
      id: 'company_constitution_act',
      name: 'Company Constitution Act'
    },
    {
      id: 'incorporation_document',
      name:
        'Incorporation Document / Validity of Power of Attorney no later than 3 months'
    },
    {
      id: 'receipt_public_service',
      name:
        'Receipt of Public Service (Electricity, Telephone, Water, etc. not more than 3 months)'
    },
    {
      id: 'last_income_tax_return',
      name: 'Last income tax return'
    },
    {
      id: 'affidavit_origins_funds',
      name: 'Affidavit of Origins of funds'
    },
    {
      id: 'is_legal_representative',
      name: 'Is a Legal Representative?'
    },

    {
      id: 'percentage_number',
      name: 'Percentage Number %'
    },
    {
      id: 'last_name',
      name: 'Last Name'
    },
    {
      id: 'second_last_name',
      name: 'Second Last Name'
    },
    {
      id: 'birthday',
      name: 'Date of Birth'
    },
    {
      id: 'address_main',
      name: 'Main address, Zip Code, City, State'
    },
    {
      id: 'occupation',
      name: 'Activity, occupation, trade or profession'
    },
    {
      id: 'work_center',
      name: 'Work Center'
    },
    {
      id: 'first_name',
      name: 'First Name'
    },

    {
      id: 'is_pep',
      name: 'Is a PEP? (People Exposed Politically)'
    },
    {
      id: 'pep_document',
      name: 'Upload required PEP Document'
    },
    {
      id: 'nationality',
      name: 'Nationality'
    },
    {
      id: 'birth_country',
      name: 'Birth Country'
    },
    {
      id: 'residence_country',
      name: 'Country of Residence'
    },
    {
      id: 'billing_country',
      name: 'Billing Country'
    },
    {
      id: 'billing_address',
      name: 'Billing Address'
    },
    {
      id: 'billing_state',
      name: 'Billing State'
    },
    {
      id: 'billing_city',
      name: 'Billing City'
    },
    {
      id: 'billing_zip',
      name: 'Billing Zip'
    },
    {
      id: 'document_type',
      name: 'Document Type'
    },
    {
      id: 'document_country',
      name: 'Document Country'
    },
    {
      id: 'document_number',
      name: 'Document Number'
    },
    {
      id: 'document_data',
      name: 'Document Data'
    },
    {
      id: 'back_document_data',
      name: 'Back Document Data'
    },
    {
      id: 'faces_images',
      name: 'Selfie Photo'
    },
    {
      id: 'NEW_RECORD',
      name: 'New Record'
    },
    {
      id: 'REVIEW_REQUESTED',
      name: 'Review Requested'
    },
    {
      id: 'PERSON_SHAREHOLDER',
      name: 'Shareholder Person'
    },
    {
      id: 'COMPANY_SHAREHOLDER',
      name: 'Shareholder Company'
    },
    {
      id: 'NO_SHAREHOLDER',
      name: 'No Shareholder'
    },
    {
      id: '0',
      name: 'No'
    },
    {
      id: '1',
      name: 'Yes'
    }
  ];