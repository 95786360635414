import React, { useEffect, useState } from "react";
import DragAndDropContainer from "../DragAndDrop/DragAndDropContainer";
import AddIcon from "../../../images/add-icon.svg";
import InputElement from "../../../components/FallComponents/InputElement/InputElement";
import { useForm } from "../../../hooks/formHooks";
import { CustomButton } from "../CustomButton/CustomButton";
import { v4 as uuid } from "uuid";
import BanexcoinAccountsController from "../../../pages/FallControllers/BanexcoinAccountsController";
import isEmpty from "is-empty";
import DeleteIcon from "../../../images/delete-icon.svg";
import { accountUtilityList, fiatCurrencyList } from "../../../constants";
import { useUploadFile } from "../../../hooks/useUploadFile";
import detailsAccountSs from "../../../images/details-bnx-account-ss.png";
import instructionsAccountSs from "../../../images/instructions-bnx-account-ss.png";
import infoIcon from "../../../images/new_icons/info-circle.svg";

function AddForm({
  buttonProps,
  handleToggleModal,
  userInfo,
  getAllBanexcoinAccounts,
}) {
  const initialState = {
    currencyCode: "",
    countryCode: "",
    accountUtility: "",
    swiftBankId: "",
    accountNumber: "",
    description: "",
    accountMessageEs: "",
    accountMessageEn: ""
  };
  const [listComponents, setListComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rulesListComponents, setRulesListComponents] = useState([]);
  const [rulesSelectDynamicInputs, setRulesSelectDynamicInputs] = useState({});
  const [countries, setCountries] = useState([]);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [allDynamicInputsForm,setAllDynamicInputsForm] = useState({})
  const [bankListOptions, setBankListOptions] = useState([]);
  const { inputs, errors, setErrors, setInputs } = useForm(
    initialState,
    null,
    customValidations
  );
  const {
    uploadFile,
    previewFile,
    fileData,
    deleteImageData,
    handleErrors: handleFileErrors,
  } = useUploadFile({
    fileType: "image",
    maxFileDimension: {
      maxWidth: 200,
      maxHeight: 100,
    },
  });
  const handleAllDynamicInputsForm = ({ name, value }) => {
    setAllDynamicInputsForm({...allDynamicInputsForm,[name]:value})
  }
  const handleRulesDynamicInputs = ({ name, value }, data) => {
    setRulesSelectDynamicInputs({
      ...rulesSelectDynamicInputs,
      [`value_type/${data.id}/es`]: value,
      [`value_type/${data.id}/en`]: value,
    });
  };

  const Component = (data, i) => {
    const titlePropsEs = {
      values: {
        title: "Título (es)",
        name: data.es.title.name,
        value: data.es.title.value,
      },
      actions:{
        onChange:handleAllDynamicInputsForm
      }
    };
    const valuePropsEs = {
      values: {
        title: "Valor (es)",
        name: data.es.value.name,
        value: data.es.value.value,
      },
      actions:{
        onChange:handleAllDynamicInputsForm
      }
    };
    const titlePropsEn = {
      values: {
        title: "Título (en)",
        name: data.en.title.name,
        value: data.en.title.value,
      },
      actions:{
        onChange:handleAllDynamicInputsForm
      }
    };
    const valuePropsEn = {
      values: {
        title: "Valor (en)",
        name: data.en.value.name,
        value: data.en.value.value,
      },
      actions:{
        onChange:handleAllDynamicInputsForm
      }
    };
    return (
      <div className="input-drag-add-form-container">
        <div>
          <div className="container-column">
            <InputElement {...titlePropsEs} />
            <InputElement {...valuePropsEs} />
          </div>
          <div className="container-column">
            <InputElement {...titlePropsEn} />
            <InputElement {...valuePropsEn} />
          </div>
        </div>
        <span>
          {!isEmpty(errorsValidation.dynamicInputsErrors) &&
            !isEmpty(errorsValidation.dynamicInputsErrors["accountDetails"]) &&
            !isEmpty(
              errorsValidation.dynamicInputsErrors["accountDetails"][i]
            ) &&
            "Títulos y valores deben tener más de 2 caracteres"}
        </span>
      </div>
    );
  };

  const optionsRulesSelect = [
    { value: "title", name: "Título" },
    { value: "subtitle", name: "Subtítulo" },
    { value: "listitem", name: "Item de lista" },
    { value: "paragraph", name: "Párrafo" },
    { value: "link", name: "Enlace" },
  ];

  const RuleComponent = (data, i) => {
    const typePropsEs = {
      values: {
        title: "Tipo de dato (es)",
        name: data.es.value_type.name,
        value: rulesSelectDynamicInputs[data.es.value_type.name],
        type: "select",
        options: optionsRulesSelect,
      },
      actions: {
        onChange: ({ name, value }) =>
          handleRulesDynamicInputs({ name, value }, data),
      },
    };
    const typePropsEn = {
      values: {
        title: "Tipo de dato (en)",
        name: data.en.value_type.name,
        value: rulesSelectDynamicInputs[data.en.value_type.name],
        type: "select",
        options: optionsRulesSelect,
      },
      actions: {
        onChange: ({ name, value }) =>
          handleRulesDynamicInputs({ name, value }, data),
      },
    };
    const valuePropsEs = {
      values: {
        title: "Valor (es)",
        name: data.es.value.name,
        value: data.es.value.value,
      },
      actions:{
        onChange:handleAllDynamicInputsForm
      }
    };
    const valuePropsEn = {
      values: {
        title: "Valor (en)",
        name: data.en.value.name,
        value: data.en.value.value,
      },
      actions:{
        onChange:handleAllDynamicInputsForm
      }
    };
    return (
      <div className="input-drag-add-form-container">
        <div>
          <div className="container-column">
            <InputElement {...typePropsEs} />
            <InputElement {...valuePropsEs} />
          </div>
          <div className="container-column">
            <InputElement {...typePropsEn} />
            <InputElement {...valuePropsEn} />
          </div>
        </div>
        <span>
          {!isEmpty(errorsValidation.dynamicInputsErrors) &&
            !isEmpty(
              errorsValidation.dynamicInputsErrors["accountRulesInstructions"]
            ) &&
            !isEmpty(
              errorsValidation.dynamicInputsErrors["accountRulesInstructions"][
                i
              ]
            ) &&
            "Tipos y valores deben tener más de 2 caracteres"}
        </span>
      </div>
    );
  };

  const formattedDataEsEn = (lang, inputs, key, separator) => {
    let res = Object.keys(inputs).filter((item) => item.includes(lang));

    res = res
      .filter((item) => item.includes(key))
      .map((item) => {
        const parts = item.split(separator);
        return JSON.stringify({
          [key]: inputs[`${key}${separator}${parts[1]}${separator}${parts[2]}`],
          value: inputs[`value${separator}${parts[1]}${separator}${parts[2]}`],
        });
      });

    return res;
  };

  async function handleSubmitInputs() {
    setErrorsValidation({ ...errorsValidation, dynamicInputsErrors: null });
    let errors = customValidations(inputs);
    const fileErrors = handleFileErrors();
    if (!isEmpty(fileErrors)) {
      errors = { ...errors, accountLogo: fileErrors };
    }
    setErrors(errors);
    if (!isEmpty(errors)) return;
    let dynamicsInputs = {...allDynamicInputsForm};
    let rulesDynamicInputs = {};
    Object.keys(dynamicsInputs).forEach((input) => {
      if (!input.includes("$")) {
        if (input.includes("/")) {
          rulesDynamicInputs[input] = dynamicsInputs[input];
        }
        delete dynamicsInputs[input];
      }
    });

    let spanishAccountDetails = formattedDataEsEn(
      "es",
      dynamicsInputs,
      "title",
      "$"
    );
    let englishAccountDetails = formattedDataEsEn(
      "en",
      dynamicsInputs,
      "title",
      "$"
    );
    spanishAccountDetails = spanishAccountDetails.map((item) =>
      JSON.parse(item)
    );
    englishAccountDetails = englishAccountDetails.map((item) =>
      JSON.parse(item)
    );

    rulesDynamicInputs = { ...rulesDynamicInputs, ...rulesSelectDynamicInputs };

    let spanishRulesDetails = formattedDataEsEn(
      "es",
      rulesDynamicInputs,
      "value_type",
      "/"
    );
    let englishRulesDetails = formattedDataEsEn(
      "en",
      rulesDynamicInputs,
      "value_type",
      "/"
    );
    spanishRulesDetails = spanishRulesDetails.map((item) => JSON.parse(item));
    englishRulesDetails = englishRulesDetails.map((item) => JSON.parse(item));

    let data = {
      ...inputs,
      accountDetails: JSON.stringify({
        es: spanishAccountDetails,
        en: englishAccountDetails,
      }),
      accountRulesInstructions: JSON.stringify({
        es: spanishRulesDetails,
        en: englishRulesDetails,
      }),
      accountMessage: JSON.stringify({
        es: inputs.accountMessageEs,
        en: inputs.accountMessageEn,
      }),
    };
    delete data["accountMessageEn"];
    delete data["accountMessageEs"];

    data = { ...data, status: 0, accountLogo: fileData };

    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });

    setLoading(true);
    const response = await BanexcoinAccountsController.sendBanexcoinAccount({
      userId: userInfo.UserId,
      data: formData,
      setErrorsValidation,
    });
    if (response) {
      handleToggleModal();
      getAllBanexcoinAccounts();
    }
    setLoading(false);
  }

  const handleAddInput = () => {
    const id = uuid();
    const newSection = {
      id,
      es: {
        title: {
          name: `title$${id}$es`,
        },
        value: {
          name: `value$${id}$es`,
        },
      },
      en: {
        title: {
          name: `title$${id}$en`,
        },
        value: {
          name: `value$${id}$en`,
        },
      },
    };
    setListComponents([...listComponents, newSection]);
  };
  const handleRulesAddInput = () => {
    const id = uuid();
    const newSection = {
      id,
      es: {
        value_type: {
          name: `value_type/${id}/es`,
        },
        value: {
          name: `value/${id}/es`,
        },
      },
      en: {
        value_type: {
          name: `value_type/${id}/en`,
        },
        value: {
          name: `value/${id}/en`,
        },
      },
    };
    setRulesListComponents([...rulesListComponents, newSection]);
  };

  function customValidations(inputs) {
    let errors = {};
    Object.keys(initialState).forEach((element) => {
      if (isEmpty(inputs[element])) {
        errors[element] = "Required field";
      }
    });
    return errors;
  }

  const onDefaultChange = ({ name, value }) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
    delete errorsValidation[name];
    delete errors[name];
  };

  const getAllCountries = async () => {
    const countriesResponse =
      await BanexcoinAccountsController.getListCountries({
        setCountries,
        userInfo,
      });
    let countriesOptions = [];
    if (!isEmpty(countriesResponse)) {
      countriesOptions = countriesResponse.map((country) => {
        return { value: country.countryCode, name: country.countryName };
      });
    }
    setCountries(countriesOptions);
  };

  const handleDeleteItem = (item) => {
    setListComponents(
      [...listComponents].filter((component, i) => {
        if (
          component.es.title.name.includes(item.id) &&
          errorsValidation &&
          errorsValidation.dynamicInputsErrors &&
          errorsValidation.dynamicInputsErrors["accountDetails"] &&
          errorsValidation.dynamicInputsErrors["accountDetails"][i]
        ) {
          errorsValidation.dynamicInputsErrors["accountDetails"][i] = null;
        }
        return !component.es.title.name.includes(item.id);
      })
    );
  };
  const handleRulesDeleteItem = (item) => {
    setRulesListComponents(
      rulesListComponents.filter(
        (component) => !component.es.value_type.name.includes(item.id)
      )
    );
    Object.keys(rulesSelectDynamicInputs).forEach((el) => {
      if (el.includes(item.id)) {
        delete rulesSelectDynamicInputs[el];
      }
    });
  };

  const getListBank = async (countryCode) => {
    const response = await BanexcoinAccountsController.getAllBankList({
      userInfo,
      countryCode,
    });
    if (response.success) {
      const banks = response.data.map((bank) => {
        return { value: bank.id, name: bank.bankName };
      });
      setBankListOptions(banks);
    } else {
      setBankListOptions([]);
    }
  };

  const handleDeleteImageData = () => {
    deleteImageData();
    document.getElementById("input-file-bnx-form").value = "";
    delete errors["accountLogo"];
  };

  const existPreviewData = previewFile && previewFile.data;

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      getAllCountries();
    }
  }, [userInfo]);

  const currencyCodeInputProps = {
    values: {
      title: "Tipo de moneda",
      value: inputs.currencyCode || "",
      name: "currencyCode",
      type: "select",
      required: true,
      options: fiatCurrencyList,
    },
    actions: {
      onChange: ({ name, value }) => {
        onDefaultChange({ name, value });
      },
    },
    error: errors.currencyCode || errorsValidation["currencyCode"],
  };
  const countryCodeInputProps = {
    values: {
      title: "País",
      value: inputs.countryCode || "",
      name: "countryCode",
      type: "select",
      required: true,
      options: countries,
    },
    actions: {
      onChange: ({ name, value }) => {
        onDefaultChange({ name, value });
        getListBank(value);
      },
    },
    error: errors.countryCode || errorsValidation["countryCode"],
  };
  const accountUtilityInputProps = {
    values: {
      title: "Utilidad de cuenta",
      value: inputs.accountUtility || "",
      name: "accountUtility",
      type: "select",
      required: true,
      options: accountUtilityList,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.accountUtility || errorsValidation["accountUtility"],
  };
  const swiftBankIdInputProps = {
    values: {
      title: "Nombre de banco",
      value: inputs.swiftBankId || "",
      name: "swiftBankId",
      type: "select",
      required: true,
      disabled: isEmpty(inputs.currencyCode) || isEmpty(inputs.countryCode),
      options: bankListOptions,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.swiftBankId || errorsValidation["swiftBankId"],
  };
  const accountNumberInputProps = {
    values: {
      title: "Número de cuenta bancaria",
      value: inputs.accountNumber || "",
      name: "accountNumber",
      type: "text",
      required: true,
      rootClassname: "width-200",
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.accountNumber || errorsValidation["accountNumber"],
  };
  const accountLogoInputProps = {
    values: {
      title:
        previewFile && previewFile.data
          ? previewFile.name
          : "Seleccione logo del banco",
      value: inputs.accountLogo || "",
      name: "accountLogo",
      type: "file",
      required: true,
      helperText: [
        "Formatos válidos: jpg, jpeg, png, gif",
        "Dimensiones máximas de la imagen: 200 x 100 px",
      ],
    },
    actions: {
      onChange: (ev) => {
        delete errors["accountLogo"];
        uploadFile(ev);
      },
    },
    error: errors.accountLogo,
  };
  const descriptionInputProps = {
    values: {
      title: "Descripción",
      value: inputs.description || "",
      name: "description",
      type: "text",
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.description || errorsValidation["description"],
  };
  const accountMessageEsInputProps = {
    values: {
      title: "Mensaje en pantalla (es)",
      value: inputs.accountMessageEs || "",
      name: "accountMessageEs",
      type: "text",
      helperText: ["Ejemplo: Solo tranferencias interbancarias"],
      required: true,
    },
    actions: {
      onChange: ({ name, value }) => {
        onDefaultChange({ name, value });
        delete errorsValidation["accountMessage/es"];
      },
    },
    error: errors.accountMessageEs || errorsValidation["accountMessage/es"],
  };
  const accountMessageEnInputProps = {
    values: {
      title: "Mensaje en pantalla (en)",
      value: inputs.accountMessageEn || "",
      name: "accountMessageEn",
      type: "text",
      helperText: ["Example: Only interbank tranfers"],
      required: true,
    },
    actions: {
      onChange: ({ name, value }) => {
        onDefaultChange({ name, value });
        delete errorsValidation["accountMessage/en"];
      },
    },
    error: errors.accountMessageEn || errorsValidation["accountMessage/en"],
  };

  const referenceInputProps = {
    values: {
      title: "Referencia",
      value: inputs.reference || "",
      name: "reference",
      type: "text",
      required: false,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.description || errorsValidation["description"],
  };


  const TooltipImage = ({ image }) => {
    return (
      <div class="tooltip-info-bnx-accounts">
        <img src={infoIcon} alt="" />
        <img src={image} width={300} alt="Banexcoin account details" />
      </div>
    );
  };

  const isBankInternational = () => !inputs || (inputs.countryCode !== "PE" && inputs.currencyCode === "USD");
  
  return (
    <div noValidate className="drag-drop-container">
      <div>
        <InputElement {...descriptionInputProps} />
        <h3 className="title-general-form">Datos generales de cuenta </h3>
        <div className="static-form-add-container">
          <InputElement {...currencyCodeInputProps} />
          <InputElement {...countryCodeInputProps} />
          <InputElement {...accountUtilityInputProps} />
          <InputElement {...swiftBankIdInputProps} />
          <InputElement {...accountNumberInputProps} />
          <div />
          <InputElement {...accountMessageEsInputProps} />
          <InputElement {...accountMessageEnInputProps} />
          <InputElement {...accountLogoInputProps} />
          {isBankInternational() &&
                 <InputElement {...referenceInputProps} />
                }
          {existPreviewData && (
            <div className="input-file-preview-container">
              <img
                className="logo-preview-img"
                id="logo-preview-img"
                src={previewFile.data}
                alt={previewFile.name}
              />
              <img
                src={DeleteIcon}
                alt="delete"
                width="16px"
                onClick={handleDeleteImageData}
              />
            </div>
          )}
        </div>
      </div>
      <h3 className="title-general-form">
        Detalles extra de cuenta <TooltipImage image={detailsAccountSs} />
      </h3>
      <DragAndDropContainer
        Component={Component}
        listComponents={listComponents}
        handleDeleteItem={handleDeleteItem}
        handleAction={() => {
          if (errorsValidation && errorsValidation.dynamicInputsErrors) {
            errorsValidation.dynamicInputsErrors["accountDetails"] = [];
          }
        }}
      />
      <div className="container-button">
        <button
          className="add-drag-field-button"
          type="button"
          onClick={handleAddInput}
        >
          {" "}
          <img src={AddIcon} alt="" width="16px" height="16px" /> Añadir campo
        </button>
      </div>
      <h3 className="title-general-form">
        Instrucciones de cuenta <TooltipImage image={instructionsAccountSs} />
      </h3>
      <DragAndDropContainer
        Component={RuleComponent}
        listComponents={rulesListComponents}
        handleDeleteItem={handleRulesDeleteItem}
        handleAction={() => {
          if (errorsValidation && errorsValidation.dynamicInputsErrors) {
            errorsValidation.dynamicInputsErrors["accountRulesInstructions"] =
              [];
          }
        }}
      />
      <div className="container-button">
        <button
          className="add-drag-field-button"
          type="button"
          onClick={handleRulesAddInput}
        >
          {" "}
          <img src={AddIcon} alt="" width="16px" height="16px" /> Añadir campo
        </button>
      </div>

      <div className="container-submit-cancel-buttons">
        <CustomButton
          onClick={handleToggleModal}
          className="red"
          text="Cancelar"
          disabled={loading}
        />
        <CustomButton
          {...buttonProps}
          onClick={handleSubmitInputs}
          disabled={loading}
          type="submit"
        />
      </div>
    </div>
  );
}

export default AddForm;
