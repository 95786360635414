import UtilsHelper from "../../helpers/Utils.helper";

export const bankAccountBalance = async (userId, data) => {
    const path = '/api/utils/bank/accounts/balance';
    return UtilsHelper.sendRequest({
      userId,
      method: 'POST',
      data,
      path
    })
  };

  export const deleteBankTransaction = async (userId, data) => {
    const path = '/api/services/bank/transaction/delete';
    return UtilsHelper.sendRequest({
      userId,
      method: 'POST',
      data,
      path
    })
  };
  
  export const syncBankTransactions = async (userId, data) => {
    const path = '/api/services/bank/transactions/sync';
    return UtilsHelper.sendRequest({
      userId,
      method: 'POST',
      data,
      path
    })
  };

  export const freezeFeeTransactions = async (userId, data) => {
    try {
      const path = '/api/services/bank/freezedeposit/fee';
      const configRequest = {
        userId,
        method: 'POST',
        data,
        path
      };
      return UtilsHelper.sendRequest(configRequest);
    } catch (error) {
      throw error;
    }
  };