import empty from 'is-empty';
import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';
const queryString = require('query-string');

export const createComment = async (userId, levelId, data) => {
  let result = [];
  try {
    let headers = {
      'Content-Type': 'multipart/form-data'
    };
    let path = '/api/levels/internal/message';

    let model = new FormData();
    model.append('level_id', levelId);
    model.append('message', data.message);
    model.append('type', data.type);
    for (let i of data.images) {
      model.append('image', i);
    }
    let configRequest = {
      userId,
      method: 'post',
      headers,
      path,
      data: model
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const deleteComment = async (userId, message_id) => {
  let result = [];
  try {
    let headers = {
      'Content-Type': 'multipart/form-data'
    };
    let path = `/api/levels/internal/message/${message_id}`;

    let model = new FormData();
    model.append('deleted', true);

    let configRequest = {
      userId,
      method: 'put',
      headers,
      path,
      data: model
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const editComment = async (userId, id, data) => {
  let result = [];
  try {
    let headers = {
      'Content-Type': 'multipart/form-data'
    };
    let path = `/api/levels/internal/message/${id}`;

    let model = new FormData();
    model.append('message', data.messageedit);
    let configRequest = {
      userId,
      method: 'put',
      headers,
      path,
      data: model
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const getComments = async (userId, levelId) => {
  let result = [];
  try {
    let headers = {};
    let path = `/api/levels/internal/message/${levelId}`;

    let configRequest = {
      userId,
      method: 'get',
      headers,
      path
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
  return result;
};
