import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getLevel = async userId => {
  let path = '/api/level';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/intermediate-level';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('full_name', data.full_name);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('address_main', data.address_main);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('identification_number', data.identification_number);
  model.append('is_pep', data.is_pep);
  model.append('address_billing', data.address_billing);
  model.append('upload[]', data.proof_residence);
  model.append('upload[]', data.photo);
  model.append('occupation', data.occupation);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
