import React, { useEffect, useState } from "react";
import isEmpty from "is-empty";
import { Link } from "react-router-dom";
import DataFormatterHelper from "../../../../../helpers/DataFormatter.helper";
import DateHelper from "../../../../../helpers/Date.helper";
import Table from "../../../Table/Table";
import RotateRight from "../../../../../images/new_icons/rotate-right.svg";

export const AssociatedDeposits = ({
  loadingDepositsByAccount,
  depositsByAccount,
  handleReloadDeposits,
  userAccountId
}) => {
  const [totalAmounts, setTotalAmounts] = useState({
    notionalValue: 0,
  });

  const getSourceOfFunds = (data) => {
    let sourceOfFunds = JSON.parse(data.DepositInfo).sourceOfFunds;
    if (isEmpty(sourceOfFunds) || sourceOfFunds === "null") {
      sourceOfFunds = "-";
    }
    return sourceOfFunds;
  };

  useEffect(() => {
    const totalNotionalValue = !isEmpty(depositsByAccount)
      ? depositsByAccount
          .filter((deposit) => deposit.Status === "FullyProcessed")
          .reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.NotionalValue,
            0
          )
      : 0;
    setTotalAmounts({
      notionalValue: DataFormatterHelper.currencyFormatter({
        currency: "USD",
        amount: totalNotionalValue,
      }),
    });
  }, [depositsByAccount]);

  const tableHeaders = [
    {
      tag: "AssetName",
      title: "Producto",
    },
    {
      tag: "Amount",
      title: "Monto",
      cell: ({ data }) => (
        <p>
          {data.AssetName ===  "US Dollar" ? DataFormatterHelper.currencyFormatter({
            currency: "USD",
            amount: data.Amount,
          }) : DataFormatterHelper.bigNumberFormatter(data.Amount)
          }
        </p>
      ),
    },
    {
      tag: "NotionalValue",
      title: "NotionalValue",
      cell: ({ data }) => (
        <p>
          {DataFormatterHelper.currencyFormatter({
            currency: "USD",
            amount: data.NotionalValue,
          })}
        </p>
      ),
    },
    {
      tag: "Status",
      title: "Estado",
    },
    {
      tag: "",
      title: "Origen de fondos",
      cell: ({ data }) => <p>{getSourceOfFunds(data)}</p>,
    },
    {
      tag: "createdAt",
      title: "Fecha de creación",
      cell: ({ data }) => DateHelper.utcFormatter(data.CreatedTimestamp),
    },
    {
      tag: "DateLima",
      title: "Fecha de creación (Local)",
      cell: ({ data }) => DateHelper.formatDate(data.DateLima),
    },
    {
      tag: "DateTx",
      title: "Fecha de transacción",
      cell: ({ data }) => DateHelper.formatDateToYear(data.DateTx),
    },
    {
      tag: "",
      title: "",
      cell: ({ data }) => (
        <Link to={`/depositos/${data.TicketNumber}`} target="_blank">
          Ver más detalles
        </Link>
      ),
    },
  ];

  return (
    <div className="aml-alerts-actions-content">
      <div className="flex items-center gap-1">
        <h2 className="subtitle">Detalles de depósitos asociados</h2>
        <img src={RotateRight} alt="" onClick={() => handleReloadDeposits(userAccountId)} />
      </div>
      <div className="table-wrapper">
        <Table
          loading={loadingDepositsByAccount}
          showTooltipToCopy={false}
          rowIdSelected=""
          rowHead={tableHeaders}
          dataBody={depositsByAccount}
        />
        <br />
        <h3>
          NotionalValue acumulado (FullyProcessed): <span>{totalAmounts.notionalValue}</span>
        </h3>
      </div>
    </div>
  );
};
