import React, { useState, useEffect } from "react";
import editIcon from "../../../images/new_icons/edit.svg";
import checkIcon from "../../../images/new_icons/check.svg";
import crossIcon from "../../../images/new_icons/cross.svg";
import { EditableText } from "../EditableText/EditableText";
import { providerTypes } from "../../../constants";
import './providersDetails.css'
import { CustomButton } from "../CustomButton/CustomButton";

function UsersDetails({ data, updateProviderData,deleteProvider }) {
  const [isEditable, setIsEditable] = useState(false);
  const [fields, setFields] = useState({
    type:data.type,
    businessName:data.businessName,
    ruc:data.ruc
  });
  const handleIsEditable = () => setIsEditable(!isEditable);

  const handleResetInputs = () => {
    setFields(data);
    setIsEditable(isEditable => !isEditable);
  };

  const handleCloseEdition = async () => {
    handleIsEditable();
    if (isEditable) {
      updateProviderData(fields,data.id,() => setFields({
        type:data.type,
        businessName:data.businessName,
        ruc:data.ruc
      }))
    }
  };

  const handleDeleteProvider = () => {
    deleteProvider(data,data.id)
  }


  const HeaderSection = () => {
    return (
      <div className="header-section">
        <h2>{data.businessName}</h2>
      </div>
    );
  };

  useEffect(() => {
    setFields({
      type:data.type,
      businessName:data.businessName,
      ruc:data.ruc
    })
  },[data])

  return (
    <div className="resizable-details-container">
      <HeaderSection />
      <div className="content-tab">
        <div className="content-tab-container provider-details">
          <div className="flex items-center gap-1">
            <h2 className="subtitle-first-tab">Detalles de proveedor</h2>
            <img
              onClick={handleCloseEdition}
              src={isEditable ? checkIcon : editIcon}
            />
            {isEditable && <img onClick={handleResetInputs} src={crossIcon} />}
          </div>
          <br/>
          <EditableText
            editable={isEditable}
            option="Razón social"
            name="businessName"
            value={fields.businessName}
            onChange={(target) => setFields({...fields,[target.name]:target.value})}
            rootClassname="max-w-250"
            />
            <br/>
          <EditableText
            type="select"
            editable={isEditable}
            option="Tipo"
            name="type"
            options={Object.keys(providerTypes).map((item) => ({
              name: providerTypes[item],
              value: item,
            }))}
            value={fields.type}
            onChange={(target) => setFields({...fields,[target.name]:target.value})}
            rootClassname="max-w-250"
            />
            <br/>
          <EditableText
            editable={isEditable}
            option="RUC"
            name="ruc"
            value={fields.ruc}
            onChange={(target) => setFields({...fields,[target.name]:target.value})}
            rootClassname="max-w-250"
          />
          
        </div>
        <div className="buttons-container">
            <div className="buttons-left">
              <CustomButton text="Eliminar proveedor" disabled={false} showLoader={true} onClick={handleDeleteProvider} className="red" />
            </div>
          </div>
      </div>
    </div>
  );
}

export default UsersDetails;
