import UtilsHelper from "../../helpers/Utils.helper";

export const getAllAlphapointAccount = async (userId, page, limit, newFilters) => {
    const path = `/api/v2/accounts-alphapoint?Page=${page}&Limit=${limit}${newFilters}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getAlphapointAccountById = async (userId, accountId) => {
    const path = `/api/accountsDetail/${accountId}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}