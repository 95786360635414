export const CURRENT_LEVEL = [
    {
      value: 0,
      title: 'Registro 0'
    },
    {
      value: 10,
      title: 'Explorador'
    },
    {
      value: 11,
      title: 'Amateur'
    },
    {
      value: 12,
      title: 'Intermedio'
    },
    {
      value: 13,
      title: 'Avanzado'
    },
    {
      value: 14,
      title: 'Maestro'
    },
    {
      value: 100,
      title: 'Sin verificar'
    },
    {
      value: 18,
      title: 'Corporativo'
    },
    {
      value: 101,
      title: 'Coink'
    }
  ];