import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './login.css';
import user from '../../../images/new_icons/user.svg';
import diamond from '../../../images/diamond.png';
import diamond2 from '../../../images/diamond2.png';
import lockicon from '../../../images/new_icons/lock.svg';
import eyeSlash from '../../../images/new_icons/eye-slash.svg';
import eye from '../../../images/new_icons/eye.svg';
import InputElement from '../../../components/FallComponents/InputElement/InputElement';
import { CustomButton } from '../../../components/FallComponents/CustomButton/CustomButton';
import { defaultPath } from 'apex-web/lib/routeTemplates';
import TradingServerComponent from 'apex-web/lib/components/TradingServer/TradingServerComponent';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import LogoBnx from './../../../images/logo-banexcoin-white-middleorange.svg';

const FormContainer = ({ handleSubmit, isConnected, doSubmit, submitting }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const EmailInputProps = {
    values: {
      title: 'Usuario',
      value: username,
      name: 'username',
      type: 'text',
      icon: user,
      autoComplete: 'new-password',
      required:true
    },
    actions: {
      onChange: e => setUsername(e.value)
    }
  };
  const PasswordInputProps = {
    values: {
      title: 'Contraseña',
      value: password,
      name: 'password',
      icon: lockicon,
      rightIcon: !showPassword ? eyeSlash : eye,
      type: showPassword ? 'text' : 'password',
      autoComplete: 'new-password',
      required:true
    },
    actions: {
      onChange: e => setPassword(e.value),
      onChangeSecondary: () => setShowPassword(!showPassword)
    }
  };
  const handleLogin = handleSubmit(() =>
    doSubmit({ username, password }, isConnected)
  );

  return (
    <div className="form-container">
      <form onSubmit={handleLogin} autoComplete="off">
        <h3>Iniciar sesión</h3>
        <InputElement {...EmailInputProps} />
        <InputElement {...PasswordInputProps} />
        <div>
          <p id='message-error-login'></p>
        </div>
        <CustomButton
          type="submit"
          text={submitting ? 'Cargando...' : 'Iniciar sesión'}
          disabled={submitting}
          className="orange-gradient"
        />
      </form>
    </div>
  );
};
function Login(props, context) {
  var handleSubmit = props.handleSubmit,
    errorMsg = props.errorMsg,
    submitting = props.submitting,
    doSubmit = props.doSubmit,
    isConnected = props.isConnected,
    gateway = props.gateway;

  return (
    <div>
      <img src={LogoBnx} className="logo-admin-login" />
      <div className="circle1" />
      <div className="circle2" />
      <div className="circle3" />
      <div className="login-v2">
        <div className="login-container">
          <div />

          <img className="diamond" src={diamond} alt="aux diamond background" />
          <img
            className="diamond2"
            src={diamond2}
            alt="aux diamond background"
          />
          <FormContainer
            handleSubmit={handleSubmit}
            submitting={submitting}
            isConnected={isConnected}
            doSubmit={doSubmit}
          />
        </div>
      </div>
      {Array.isArray(gateway) && <TradingServerComponent />}
    </div>
  );
}

Login.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

Login.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string
};

Login.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(Login, defaultPath.path);
