import React from 'react'
import ComponentsHelper from '../../../../../helpers/Components.helper';
import DateHelper from '../../../../../helpers/Date.helper';
import HeaderPage from '../../../../../components/FallComponents/HeaderPage/HeaderPage'
import TableContainer from '../../../../../components/FallComponents/Table/TableContainer'
import Download from '../../../../../images/new_icons/download.svg';
import { formatAmountCoink  } from '../../../../../helpers/numberFormatter';

const CoinkWithdrawalsPage = (props) => {

    const { loading, onChangeLimit, filterConfig, totalRows, offset, showingTo, changePage, rows, handleDownload } = props;

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: 'id',
            title: 'Id',
        },
        {
            tag: 'banexcoinUserProviderClientId',
            title: 'Id de Proveedor'
        },
        {
            tag: 'requestId',
            title: 'Id de solicitud'
        },
        {
            tag: 'accountId',
            title: 'Id de cuenta'
        },
        {
            tag: '',
            title: 'Hora y fecha de operación',
            cell: ({ data }) => DateHelper.formatDate(data.createdAt)
        },
        {
            tag: '',
            title: 'Hora y fecha de actualización',
            cell: ({ data }) => DateHelper.formatDate(data.updatedAt)
        },
        {
            tag: 'ticketNumber',
            title: 'Número de Ticket'
        },
        {
            tag: 'amount',
            title: 'Monto',
            cell: ({ data }) => formatAmountCoink(data.amount)
        },
        {
            tag: 'status',
            title: 'Estado'
        }
    ]


    const headerOptions = [
        {
            icon: Download,
            action: handleDownload
        }
    ];

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Retiro Coink"
                subTitle="Coink / Transacciones coink / Retiro Coink"
                headerOptions={headerOptions}
            />
            <TableContainer
                onChangeLimit={onChangeLimit}
                filterConfig={filterConfig}
                totalRows={totalRows}
                rows={rows}
                loading={loading}
                offset={offset}
                showingTo={showingTo}
                changePage={changePage}
                headers={tableHeaders}
                enablePagination={true}
                rowIdSelected={''}
            >
            </TableContainer>
        </div>
    )
}

export default CoinkWithdrawalsPage