export const typeOfLegalPerson = [
    {
        name: 'Ltd',
        value: 'Ltd'
    },
    {
        name: 'SE',
        value: 'SE'
    },
    {
        name: 'SPE',
        value: 'SPE'
    },
    {
        name: 'EEIG',
        value: 'EEIG'
    },
    {
        name: 'PLC',
        value: 'PLC'
    },
    {
        name: 'Corp',
        value: 'Corp'
    },
    {
        name: 'SA',
        value: 'SA'
    },
    {
        name: 'SAA',
        value: 'SAA'
    },
    {
        name: 'SAC',
        value: 'SAC'
    },
    {
        name: 'SRL',
        value: 'SRL'
    },
    {
        name: 'EIRL',
        value: 'EIRL'
    },
    {
        name: 'CA.',
        value: 'CA.'
    },
    {
        name: 'Sin fines de lucro (especifique)',
        value: 'Non-Profit'
    },
    {
        name: 'Otro (especifique)',
        value: 'Other'
    }
];