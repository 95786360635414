export const LEVELS_STATUS = [
    {
      id: 1,
      name: 'Pendiente',
      className: 'primary'
    },
    {
      id: 2,
      name: 'Enviado a ACUANT',
      className: 'secondary'
    },
    {
      id: 3,
      name: 'Recibido por ACUANT',
      className: 'secondary'
    },
    {
      id: 4,
      name: 'Rechazado',
      className: 'warning'
    },
    {
      id: 5,
      name: 'Información incorrecta',
      className: 'warning'
    },
    {
      id: 6,
      name: 'Modificar información',
      className: 'secondary'
    },
    {
      id: 7,
      name: 'Aprobado',
      className: 'success'
    },
    {
      id: 8,
      name: 'Información editada',
      className: 'secondary'
    }
  ];