import React from 'react'
import HeaderPage from '../HeaderPage/HeaderPage';
import AssetsList from './GlobalConfigSections/AssetsList';
import MinimumAssetValues from './GlobalConfigSections/MinimumAssetValues';
import VersionsByOperatingSystem from './GlobalConfigSections/VersionsByOperatingSystem';

const AllGlobalConfigPage = (props) => {

    const { handleSelectRow, loading, listActives, totalRows, handleLoadActives, handleDisableActive, handleActiveAsset, selectedId, allInitialSetting, loaderInitialSetting, handleEditSettingByKey, onChangeLimit, filterConfig, offset, showingTo, changePage, handleCreateNewAsset, withRightSection } = props;

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Configuración General"
                subTitle="Configuraciones / Configuración General"
            />
            <div className={`global-config-data ${withRightSection ? 'withRightSection' : ''}`}>
                <div className='content-config-data'>
                    <MinimumAssetValues
                        allInitialSetting={allInitialSetting}
                        loaderInitialSetting={loaderInitialSetting}
                        handleEditSettingByKey={handleEditSettingByKey}
                    />
                    <VersionsByOperatingSystem
                        allInitialSetting={allInitialSetting}
                        loaderInitialSetting={loaderInitialSetting}
                        handleEditSettingByKey={handleEditSettingByKey}
                    />
                </div>
                <AssetsList
                    handleLoadActives={handleLoadActives}
                    loading={loading}
                    handleSelectRow={handleSelectRow}
                    listActives={listActives}
                    totalRows={totalRows}
                    selectedId={selectedId}
                    handleDisableActive={handleDisableActive}
                    handleActiveAsset={handleActiveAsset}
                    onChangeLimit={onChangeLimit}
                    filterConfig={filterConfig}
                    offset={offset}
                    showingTo={showingTo}
                    changePage={changePage}
                    handleCreateNewAsset={handleCreateNewAsset}
                />
            </div>
        </div>
    )
}

export default AllGlobalConfigPage