import isEmpty from "is-empty";
import UtilsHelper from "../../helpers/Utils.helper";
const queryString = require("query-string");

export const getUsers = async (userId, filterConfig, filterSearch) => {
  const path = `/api/users/get-all`;
  const params = {
    filterConfig,
    filterSearch,
  };
  const configRequest = {
    userId,
    method: "POST",
    path,
    data: params,
  };

  return await UtilsHelper.sendRequest(configRequest);
};

export const getUserById = async (userId, id) => {
  const path = `/api/users/${id}`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const freeUserSmsById = async (userId, id) => {
  const path = `/api/users/free-sms/${id}`;
  const configRequest = {
    userId,
    method: "POST",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const getUserByBanexcoinId = async (userId, id) => {
  const path = `/api/${id}/users`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return UtilsHelper.sendRequest(configRequest);
};

export const updateUserById = async (userId, id, data) => {
  const path = `/api/users/${id}`;
  const configRequest = {
    userId,
    method: "PUT",
    path,
    data,
  };
  return UtilsHelper.sendRequest(configRequest);
};
export const updateUserType = async (userId, id, data) => {
  const path = `/api/users/userType/${id}`;
  const configRequest = {
    userId,
    method: "PATCH",
    path,
    data,
  };
  return UtilsHelper.sendRequest(configRequest);
};
export const updateProfile = async (userId, id,data) => {
  const path = `/api/users/assignProfile/${id}`;
  const configRequest = {
    userId,
    method: "PATCH",
    path,
    data
  };
  return UtilsHelper.sendRequest(configRequest);
};
export const getLastLogin = async (userId,bnxUserId) => {
  const path = `/api/lastLogin/${bnxUserId}`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const getLogs = async (userId,type,selectedUserId) => {
  const path = `/api/profile/logs/${encodeURIComponent(type)}/${selectedUserId}`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const updateUserInfo = async (userId, id, data) => {
  const path = `/api/v1/banexcoin/accounts/editInfoAccounts/${id}`;
  const configRequest = {
    userId,
    method: "PATCH",
    path,
    data,
  };
  return UtilsHelper.sendRequest(configRequest);
};

export const getLogsUsers = async (userId,type,aditionalInfo) => {
const path = `/api/profile/logs/${type}/${aditionalInfo}`;
const configRequest = {
  userId,
  method: "GET",
  path,
  type
  };
return await UtilsHelper.sendRequest(configRequest);
};

export const getAllLogsUsers = async (userId, aditionalInfo) => {
  const path = `/api/profile/logs-by-id/${aditionalInfo}`;
  const configRequest = {
    userId,
    method: "GET",
    path
  };
  return await UtilsHelper.sendRequest(configRequest);
};