export const filesQueueTypes = [
    {
        id:"DONE_WITH_OBSERVATIONS",
        name:"DONE_WITH_OBSERVATIONS"
    },
    {
        id:"DONE",
        name:"DONE"
    },
    {
        id:"PENDING",
        name:"PENDING"
    }
]