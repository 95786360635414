export const status = [
    {
        name: 'Sin filtro',
        value: ''
    },
    {
        name: 'Si',
        value: 1
    },
    {
        name: 'No',
        value: 0
    },
];