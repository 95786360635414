import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import KpiReportsController from "../../../FallControllers/KpiReportsController";
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns";
import HeaderPage from "../../../../components/FallComponents/HeaderPage/HeaderPage";
import InputElement from "../../../../components/FallComponents/InputElement/InputElement";
import { Loader } from "../../../../components/FallComponents/Loader/Loader";
import RotateRight from "../../../../images/new_icons/rotate-right.svg";

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts },
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const AdminTradesPageBeta = (props, context) => {
  const { userInfo, accounts } = props;
  const [loading, setLoading] = useState(true);
  const [allKpis, setAllKpis] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState({
    id: "",
    embedUrl: "",
    title: "",
  });

  const getAllKpiReports = () => {
    KpiReportsController.getKpiReport({
        setLoading,
        setAllKpis,
        userInfo,
        setSelectedKpi,
      });
  }
  useEffect(() => {
    if (!isEmpty(userInfo) && !isEmpty(accounts)) {
        getAllKpiReports()
    }
  }, [userInfo, accounts]);

  const onChangeKpi = (e) => {
    if (selectedKpi.id === e.value) return;

    const kpiById = allKpis.data.find((kpi) => kpi.id === e.value);

    if (isEmpty(kpiById)) return;

    setSelectedKpi({
      id: selectedKpi.id,
      embedUrl: selectedKpi.embedUrl,
      title: selectedKpi.title,
    });
  };

  const selectKpiOptions = !isEmpty(allKpis)
    ? allKpis.data.map((kpi) => ({ value: kpi.id, name: kpi.description }))
    : [];

  const selectKpiInputProps = {
    values: {
      type: "select",
      name: "kpiId",
      value: selectedKpi.id,
      title: context.t("Seleccionar reporte KPI"),
      options: selectKpiOptions,
    },
    actions: { onChange: onChangeKpi },
  };

  const headerOptions = [
    {
      icon: RotateRight,
      action: getAllKpiReports,
    },
  ];

  return (
    <React.Fragment>
      <div className="beta-content-container reports-page">
        <ResizableColumns
          childrenOne={
            <div className="content-container">
              <HeaderPage title="Reportes de Kpi" subTitle="Reportes / Kpi" headerOptions={headerOptions} />
              <br />
              <div className="select-container">
                <InputElement {...selectKpiInputProps} />
              </div>
              <br />
              <div className="iframe-container">
                {loading ? (
                  <Loader message="Cargando reporte" />
                ) : (
                  !isEmpty(selectedKpi.embedUrl) && (
                    <React.Fragment>
                      <h2>{selectedKpi.title}</h2>
                      <iframe
                        width="100%"
                        height="100%"
                        src={selectedKpi.embedUrl}
                        frameBorder={"0"}
                        style={{ border: "0" }}
                        allowFullScreen
                      ></iframe>
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

AdminTradesPageBeta.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AdminTradesPageBeta);
