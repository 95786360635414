import React, { useState, useCallback } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from './../AdminLevels/PopUp';
import Cropper from 'react-easy-crop';
import FormComments from './../AdminFormMessages/FormComment';
import {
  getLevelById,
  getBanexUserById,
  updateToIdentityMind,
  incrementLevel,
  rejectedLevel,
  infoIncorrect,
  modifyInfoByAdmin,
  editFormData,
  correctFields,
  getCountries,
  getLogs,
  reniecRequest,
  reniecLogRequest,
  getAllCorporateLogReniecById,
  returnCorporateInfoFields,
  sendToCorfid,
  sendToIDM,
  generalActionLog
} from './AdminLevelsHooks';
import empty from 'is-empty';
import queryString from 'query-string';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Form, Button, Row, Col, Card, Modal } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSecureGetParams } from '../../helpers/lib';
import { LEVELS_STATUS, ACTION_TYPE, getLabel } from '../../helpers/constants';
import { preparePaginationOptions } from '../../helpers/pagination';
import { submitFormData } from '../Level/IntermediateLevelPageHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faMinusCircle,
  faQuestionCircle,
  faPaperPlane,
  faPen,
  faUserLock,
  faUserEdit,
  faSave,
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle,
  faShareSquare
} from '@fortawesome/free-solid-svg-icons';
import { DatePickerInput } from 'rc-datepicker';
import PhoneInput from 'react-phone-input-2';
import { useForm } from '../../hooks/formHooks';
import Sidebar from 'react-sidebar';
import AdminLevelsCorporateViewPageFormBeta from './AdminLevelsCorporateViewPageFormBeta';
import AdminLevelsCorporateViewPageFormShareholdersBeta from './AdminLevelsCorporateViewPageFormShareholdersBeta';
import Table from 'react-bootstrap/Table';
import TableLegalsShareholders from './TableContent/TableLegalsShareholders';

const GetFilename = url => {
  if (url) {
    return decodeURI(url.split('/').pop());
  }
  return '';
};

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

const fixStorageLink = originalLink => {
  if (!empty(originalLink)) {
    let failedPrelink = 'sgp1.digitaloceanspaces.com/banexcoin';
    let containts = originalLink.includes(failedPrelink);
    if (containts) {
      let res = originalLink.replace(
        failedPrelink,
        'https://banexcoin.sgp1.digitaloceanspaces.com'
      );
      return res;
    } else {
      return originalLink;
    }
  } else {
    return null;
  }
};

const layoutClasses = getBEMClasses('admin-levels-page');

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, instruments, level1, userInfo, accounts };
};

const AdminLevelsViewPageBeta = (props, context) => {
  const { instruments, userInfo, accounts } = props;

  if (empty(userInfo) || empty(instruments) || empty(accounts)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading...')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);
  const [id, setId] = useState('');
  const [levelBasicInfo, setLevelBasicInfo] = useState({});
  const [shareholders, setShareholders] = useState(Array());
  const [banexcoinUserInfo, setBanexcoinUserInfo] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarFormOpen, setSidebarFormOpen] = useState(false);
  const [sidebarFormShareholderOpen, setSidebarFormShareholderOpen] = useState(
    false
  );
  const [statusReturn, setStatusReturn] = useState(false);
  const [countries, setCountries] = useState({});
  const [logs, setLogs] = useState({});

  const [logReniecInfo, setLogReniecInfo] = useState([]);
  const [reniecAditionalInfo, setreniecAditionalInfo] = useState({});
  const [reniecResultLog, setReniecResultLog] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [currentImages, setCurrentImages] = useState({});
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropData, setCropData] = useState({});
  const [schemaFull, setSchemaFull] = useState({});
  const [selectedShareholderFields, setSelectedShareholderFields] = useState(
    {}
  );
  const [showModal, setShowModal] = useState(false);
  const [textObservacion, setTextObservacion] = useState({});

  useEffect(
    () => {
      if (!empty(shareholders)) {
        getReniecLogs(`CORPORATE::${levelBasicInfo.banexcoinUserid}`);
        let cImg = [];
        for (let i in shareholders) {
          let row = shareholders[i];
          if (row.shareholderType !== 'COMPANY_SHAREHOLDER') {
            let fields = JSON.parse(row.dataForm);
            cImg[i] = {
              url: fields.faces_images,
              document_country: fields.document_country,
              document_number: fields.document_number
            };
          }
        }
        setCurrentImages(cImg);
      }
    },
    [shareholders]
  );

  const findFieldsInSchema = field => {
    let schema = getSchema();
    if (!empty(schema)) {
      for (let i in schema) {
        let g = schema[i];
        for (let f in g.fields) {
          let c = g.fields[f];
          if (c.name === field) {
            return {
              label: c.label,
              name: c.name
            };
          }
        }
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropData(croppedAreaPixels);
  }, []);

  const [inputsReturn, setInputsReturn] = useState({
    company_legal_name: '',
    legal_entity_type: '',
    non_profit: '',
    other_non_profit: '',
    other_legal_entity_type: '',
    taxpayer_identification_number_type: '',
    other_taxpayer_identification_number_type: '',
    taxpayer_identification_number: '',
    corporate_purpose: '',
    company_address: '',
    city: '',
    state: '',
    zip_code: '',
    country_incorporation: '',
    registration_date: '',
    linked_economic_group: '',
    company_phone: '',
    full_name: '',
    job_position: '',
    phone: '',
    company_constitution_act: '',
    incorporation_document: '',
    receipt_public_service: '',
    last_income_tax_return: '',
    affidavit_origins_funds: '',
    ip: '',
    ubigeo: '',
    incomes: 0,
    patrimony: 0,
    audited_financial_statements: '',
    compliance_officer: '',
    institutional_investor: ''
  });

  const [shareholderInputsReturn, setShareholderInputsReturn] = useState({});

  const onSetSidebarOpen = open => {
    setSidebarOpen(open);
  };
  const closeSidebarForm = open => {
    setSidebarFormOpen(false);
  };
  const onSetSidebarFormOpen = open => {
    setSidebarFormOpen(open);
  };
  const closeSidebarFormShareholder = () => {
    setSidebarFormShareholderOpen(false);
  };
  const onSetSidebarFormShareholderOpen = (open, schema, fields) => {
    if (!empty(schema)) {
      setSchemaFull(schema);
    }
    if (!empty(fields)) {
      setSelectedShareholderFields(fields);
    }
    setSidebarFormShareholderOpen(open);
  };

  const getListCountries = async () => {
    setLoading(1);
    try {
      let result = await getCountries(userInfo.UserId);
      setCountries(result.data.countries);
    } catch (error) {
      getListCountries();
      // toast.warn(context.t('Information could not accesible'), {
      //   position: toast.POSITION.TOP_CENTER
      // });
    }
    setLoading(0);
  };

  const getAllLogs = async id => {
    setLoading(1);
    try {
      let result = await getLogs(userInfo.UserId, id);
      setLogs(result.nodes);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(0);
  };

  const onSetObservation = e => {
    e.persist();
    let value = e.target.value;
    setTextObservacion({
      [e.target.name]: value
    });
  };

  const onSetReturnDataInput = e => {
    e.persist();
    let value;
    if (e.target.checked) {
      value = e.target.name;
    } else {
      console.log('no checkado');
      value = null;
    }
    setInputsReturn(inputsReturn => ({
      ...inputsReturn,
      [e.target.name]: value
    }));
  };

  const onRemoveReturnDataInput = e => {
    e.persist();
    let value = null;
    setInputsReturn(inputsReturn => ({
      ...inputsReturn,
      [e.target.name]: value
    }));
  };

  const onSetShareholderReturnDataInput = e => {
    e.persist();
    let value;
    if (e.target.checked) {
      value = e.target.name;
    } else {
      console.log('no checkado');
      value = null;
    }
    setShareholderInputsReturn(shareholderInputsReturn => ({
      ...shareholderInputsReturn,
      [e.target.name]: value
    }));
  };

  /**
   * Method to allow the modal to be show
   */
  const togglePopup = index => {
    setIsOpen(!isOpen);
    setIndex(index);
    if (isOpen) {
      sendRequestReniec(index);
    }
    //
  };

  const sendToIdmRequest = async id => {
    setLoading(1);
    try {
      toast.info(
        context.t('Enviando data... tal vez demore unos momentos...'),
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true
        }
      );
      let idm_result = await sendToIDM(userInfo.UserId, id);
      if (idm_result.message === 'success') {
        toast.dismiss();
        toast.success(
          context.t('Registro enviado a DIG_iD_VERIF exitosamente'),
          {
            position: toast.POSITION.TOP_CENTER
          }
        );
      }
    } catch (error) {
      console.log('Error sendTo DIG_iD_VERIF', error);
      toast.warn(context.t('An error ocurred'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const sendToIdm = async id => {
    confirmAlert({
      title: '¿Enviar a DIG_iD_VERIF?',
      message: '¿Está seguro de que desea ENVIAR la solicitud a DIG_iD_VERIF?',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => sendToIdmRequest(id)
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  };

  /**
   * Method to send data to Reniec to validate idenitity
   * @param {*} url
   * @returns reniec result
   * @throws exception
   */
  const requestReniec = async index => {
    let error_reniec = null;
    try {
      setLoading(1);
      let dataUser = currentImages[index];
      let url = dataUser.url;
      let document_country = dataUser.document_country;
      let document_number = dataUser.document_number;

      let body = {
        path: '/api/v1/identity/biometrics/faceverify', //poner como env variable
        provider: 'RENIECPROD', //poner como env variable
        country: document_country,
        dni: document_number,
        ipCliente: '192.168.5.0',
        macCliente: 'D4:C9:EF:D6:4C:0C',
        facialCapture: url,
        cropData: cropData
      };

      toast.success(context.t('Your data is being sent'), {
        position: toast.POSITION.TOP_CENTER
      });

      let reniec_result = await reniecRequest(userInfo.UserId, body).catch(
        e => {
          if (e.hasOwnProperty('response') && !empty(e.response.data)) {
            console.error(
              'Error from reniec service verify face: ',
              e.response.data
            );
            error_reniec = e.response.data.errors;
          } else if (
            e.hasOwnProperty('response') &&
            e.response.data.includes('Gateway')
          ) {
            throw e.response.data;
          }
        }
      );

      let log = {
        description: `USER::CORPORATE::${
          levelBasicInfo.banexcoinUserid
        }::${document_number}`,
        aditional_info: {
          reniec_response: {
            validated: false,
            reason: !empty(error_reniec)
              ? error_reniec
              : 'Imagen facial capturada no es válida',
            result: JSON.stringify(reniec_result)
          }
        },
        id: levelBasicInfo.id
      };

      if (!empty(reniec_result) && !reniec_result.hasOwnProperty('error')) {
        log.aditional_info.reniec_response.reason = '';
        log.aditional_info.reniec_response.validated = true;
      }

      let res = await reniecLogRequest(userInfo.UserId, log).catch(e => {
        throw e;
      });

      if (!empty(res.id)) {
        await getUserLevelById();
      }
    } catch (error) {
      toast.warn(context.t('Reniec service is down'), {
        position: toast.POSITION.TOP_CENTER
      });
      let data = {
        description: `USER::${levelBasicInfo.banexcoinUserId}`,
        aditional_info: {
          server_error: error
        },
        type: 'SERVER_ERROR',
        id: levelBasicInfo.id
      };
      await generalActionLog(userInfo.UserId, data).catch(e => {
        console.error('Error creating general action log: ', e);
      });
      await getUserLevelById();
    }
    setLoading(0);
  };

  /**
   * Method to get reniec logs from database
   * @param {*} id
   * @throws exception
   */
  const getReniecLogs = async id => {
    let logReniec = {};
    try {
      logReniec = await getAllCorporateLogReniecById(userInfo.UserId, id).catch(
        e => {
          console.error('Error from getRequestReniec: ', e);
          throw e;
        }
      );

      if (!empty(logReniec)) {
        let logData = [];
        for (let i in logReniec) {
          let log = logReniec[i];
          let des = log.description.split('::');
          logData.push({
            ...log,
            userId: des[2],
            documentNumber: des[3],
            reniec_response: JSON.parse(log.aditionalInfo).reniec_response
          });
        }
        setLogReniecInfo(logData);
      }
    } catch (error) {
      console.log('Error from getReniecLogs: ', error);
      throw error;
    }
  };

  /**
   * Function to send to reniec the info to be validate
   */
  function sendRequestReniec(index) {
    confirmAlert({
      title: 'Enviar a RENIEC',
      message:
        '¿Está seguro de que desea ENVIAR la solicitud de validación a RENIEC?',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => requestReniec(index)
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }

  const isValidUrl = string => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  async function updateToIdentityMindById() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.params);
      setId(params.id);
      let result = await updateToIdentityMind(userInfo.UserId, params.id);
      toast.success(context.t('Your data was sending'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getUserLevelById();
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function sendDataToCorfid() {
    try {
      setLoading(1);

      if (empty(shareholders)) {
        toast.warn(
          context.t('Corporate must have at least 1 legal respresentative'),
          {
            position: toast.POSITION.TOP_CENTER
          }
        );
      }

      let body = {
        username: levelBasicInfo.banexcoinUsername,
        account_id: levelBasicInfo.banexcoinUserAccountId,
        user_type: 'JURIDICO',
        id: levelBasicInfo.id
      };

      await sendToCorfid(userInfo.UserId, body);

      toast.success(context.t('The data has been sent'), {
        position: toast.POSITION.TOP_CENTER
      });

      await getUserLevelById();
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be process by TRUST_PE'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function getUserLevelById() {
    setLoading(1);
    try {
      let result = {};
      let resultUserBanex = {};
      if (
        !empty(props) &&
        !empty(props.match) &&
        !empty(props.match.params) &&
        !empty(props.match.params.params)
      ) {
        const params = getSecureGetParams(props.match.params.params);

        if (!empty(params) && !empty(params.id)) {
          setId(params.id);
          result = await getLevelById(userInfo.UserId, params.id);
          if (!empty(result)) {
            if (!empty(result.level)) {
              setLevelBasicInfo(result.level.nodes[0]);
              if (!empty(result.shareholders.nodes)) {
                setShareholders(result.shareholders.nodes);
              }
            }
          }
          getAllLogs(params.id);
          getListCountries();
        }
        if (!empty(result) && !empty(result.level)) {
          if (!empty(result.level.updateBy)) {
            resultUserBanex = await getBanexUserById(
              userInfo.UserId,
              result.level.updateBy
            );
          }
          if (!empty(resultUserBanex)) {
            if (!empty(resultUserBanex.banexcoinUser)) {
              setBanexcoinUserInfo(resultUserBanex.banexcoinUser);
            }
          }
        }
      }
    } catch (error) {}
    setLoading(0);
  }

  useEffect(() => {
    getUserLevelById();
  }, []);

  async function incrementLevelOne() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.params);
      setId(params.id);
      let result = await incrementLevel(
        userInfo.UserId,
        params.id,
        levelBasicInfo.banexcoinUserAccountId,
        levelBasicInfo.nextLevel
      );
      toast.success(context.t('Your data was sending'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getUserLevelById();
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function incrementLevelTwo() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.params);
      setId(params.id);
      let result = await incrementLevel(
        userInfo.UserId,
        params.id,
        levelBasicInfo.banexcoinUserAccountId,
        levelBasicInfo.nextLevel
      );
      toast.success(context.t('Your data was sending'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getUserLevelById();
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function incrementLevelCorporate() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.params);
      setId(params.id);
      let result = await incrementLevel(
        userInfo.UserId,
        params.id,
        levelBasicInfo.banexcoinUserAccountId,
        18
      );
      toast.success(context.t('Your data was sending'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getUserLevelById();
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function rejectedLevelBanexcoinUser() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.params);
      setId(params.id);
      let result = await rejectedLevel(
        userInfo.UserId,
        params.id,
        levelBasicInfo.banexcoinUserAccountId,
        levelBasicInfo.nextLevel
      );
      toast.success(context.t('Your data was sending'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getUserLevelById();
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function getIncorrectFields() {
    try {
      let toReturn = [];
      for (let i in inputsReturn) {
        if (!empty(inputsReturn[i])) {
          toReturn.push(inputsReturn[i]);
        }
      }
      return JSON.stringify(toReturn);
    } catch (error) {
      console.log('error returnIncorrectFields', error);
    }
  }

  async function sendToCorrectField() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.params);
      setId(params.id);
      if (!empty(JSON.parse(await getIncorrectFields()))) {
        let fields = await getIncorrectFields();
        let result = await correctFields(userInfo.UserId, params.id, fields);
        if (result.data.message === 'success') {
          setInputsReturn({});
        }

        toast.success(context.t('Your request was sending'), {
          position: toast.POSITION.TOP_CENTER
        });
        await getUserLevelById();
      } else {
        toast.warn(context.t('No haz seleccionado ningun campo a enviar'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function returnIncorrectFields() {
    confirmAlert({
      title: 'Solicitar correccion de campos',
      message:
        '¿Está seguro que desea solicitar la CORRECCIÓN de los campos seleccionados?',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => sendToCorrectField()
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }

  async function viewPersonShareholder(fields, row, schema) {
    let rows;
    if (!empty(fields)) {
      let result = [];
      for (let i in fields) {
        result.push({ [i]: fields[i] });
      }
      rows = result.map((option, index) => {
        var keyName = Object.keys(option);
        var keyValue = Object.values(option);
        if (keyName[0] === 'id' || keyName[0] === 'status') {
          return null;
        }
        if (keyValue[0] === 'undefined' || keyValue[0] === '') {
          return null;
        }
        let name = getLabel(keyName[0]);
        return (
          <React.Fragment key={index}>
            <Col xl="4">
              <div className="field-row">
                <div className="left">
                  <Form.Check
                    name={keyName[0]}
                    onChange={onSetShareholderReturnDataInput}
                    aria-label="Return"
                    disabled={edit_fields.includes(keyName[0]) ? true : false}
                  />
                </div>
                <div>
                  <b>{context.t(name)}:</b>
                  <p>
                    {!empty(keyValue[0]) ? (
                      validURL(keyValue[0]) ? (
                        keyName[0] === 'document_data' ||
                        keyName[0] === 'back_document_data' ||
                        keyName[0] === 'faces_images' ? (
                          <a href={keyValue[0]}>
                            <img src={keyValue[0]} width="100%" />
                          </a>
                        ) : (
                          <a href={keyValue[0]}>{GetFilename(keyValue[0])}</a>
                        )
                      ) : keyName[0] === 'country_incorporation' ? (
                        levelBasicInfo.countryIncorporationName.toUpperCase()
                      ) : (
                        context.t(getLabel(keyValue[0].toUpperCase()))
                      )
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </div>
            </Col>
          </React.Fragment>
        );
      });
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-alert">
            <button
              className="close"
              onClick={() => {
                onClose();
              }}>
              X
            </button>
            <h1>
              Ver Datos de{' '}
              {row.shareholderType === 'COMPANY_SHAREHOLDER'
                ? fields.company_legal_name
                : fields.first_name}
            </h1>
            <div>
              <Row>{rows}</Row>
            </div>
          </div>
        );
      }
    });
  }

  async function modifyInfo() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.params);
      setId(params.id);
      let result = await modifyInfoByAdmin(userInfo.UserId, params.id);
      toast.success(context.t('Your data was sending'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getUserLevelById();
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  useEffect(
    () => {
      inputs = {
        ...inputs,
        id: levelBasicInfo.id,
        first_name: levelBasicInfo.firstName,
        middle_name: levelBasicInfo.middleName,
        last_name: levelBasicInfo.lastName,
        second_last_name: levelBasicInfo.secondLastName,
        birthday: moment(levelBasicInfo.birthday, 'YYYY-MM-DD'),
        nationality: levelBasicInfo.nationality,
        birth_country: levelBasicInfo.birthCountry,
        address_main: levelBasicInfo.addressMain,
        phone: levelBasicInfo.phone,
        is_pep: levelBasicInfo.isPep,
        billing_country: levelBasicInfo.billingCountry,
        billing_address: levelBasicInfo.billingAddress,
        billing_state: levelBasicInfo.billingState,
        billing_city: levelBasicInfo.billingCity,
        billing_zip: levelBasicInfo.billingZip,
        document_type: levelBasicInfo.documentType,
        document_country: levelBasicInfo.documentCountry,
        document_number: levelBasicInfo.documentNumber,
        occupation: levelBasicInfo.occupation,
        work_center: levelBasicInfo.workCenter,
        residence_country: levelBasicInfo.residenceCountry
      };
      setInputs(inputs);
    },
    [levelBasicInfo]
  );

  const onSubmitCallback = async inputs => {
    try {
      setLoading(1);
      let result = await editFormData(userInfo.UserId, inputs);
      if (result.data.message === 'success') {
        onSetSidebarFormOpen(false);
      } else {
        toast.success(context.t('Registro editado exitosamente'), {
          position: toast.POSITION.TOP_CENTER
        });
      }

      await getUserLevelById();
    } catch (error) {
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const customValidations = inputs => {
    let errors = {};
    if (empty(inputs.birthday)) {
      errors.birthday = true;
    } else {
      var m = moment(inputs.birthday, 'YYYY-MM-DD');
      if (!m.isValid()) {
        errors.birthday = true;
      }
    }

    return errors;
  };

  let initialState = {
    validated: false
  };

  let {
    inputs,
    setInputs,
    onInputChange,
    onSubmit,
    onInputChangeByName
  } = useForm(initialState, onSubmitCallback, customValidations);

  function confirmSubmit() {
    confirmAlert({
      title: 'Aprobar solicitud',
      message:
        '¿Está seguro de que desea APROBAR la solicitud?Al aprobar la solicitud el cliente será promovido al nivel de verificación solicitado',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => {
            incrementLevelCorporate();
          }
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }

  function rejectedSubmit() {
    confirmAlert({
      title: 'Rechazar solicitud',
      message:
        '¿Está seguro de que desea RECHAZAR la solicitud?Al rechazar la solicitud el cliente no tendra accesos a los servicios de Banexcoin',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => rejectedLevelBanexcoinUser()
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }

  function sendIdentityMindSubmit() {
    confirmAlert({
      title: 'Enviar a DIG_iD_VERIF',
      message: '¿Está seguro de que desea ENVIAR la solicitud?',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => updateToIdentityMindById()
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }

  function sendCorfidSubmit() {
    confirmAlert({
      title: 'Enviar data a TRUST_PE',
      message: '¿Está seguro de que desea ENVIAR la solicitud?',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => sendDataToCorfid()
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }

  let edit_fields = JSON.parse(
    !empty(levelBasicInfo.editFields) ? levelBasicInfo.editFields : '[]'
  );

  const getSchema = () => {
    let json = JSON.parse(levelBasicInfo.dataForm);
    let structureScheme = [
      {
        title: 'Corporate Data',
        hint: [
          {
            p: ''
          },
          {
            p:
              'To increase the level of Client to the Corporate Level, you must supply the Detailed Information of the Company, this registration must be carried out only using a Banexcoin account duly created by its current Legal Representative.',
            links: [
              {
                title: 'See limits',
                href:
                  'https://soporte.banexcoin.com/support/solutions/articles/60000609834'
              }
            ]
          }
        ],
        fields: [
          {
            label: 'Denomination or Full Legal Name of Company',
            name: 'company_legal_name',
            type: 'text'
          },
          {
            label: 'Type of Legal Entity',
            name: 'legal_entity_type',
            type: 'select',
            options: [
              { value: 'Ltd', name: 'Ltd' },
              { value: 'SE', name: 'SE' },
              { value: 'SPE', name: 'SPE' },
              { value: 'EEIG', name: 'EEIG' },
              { value: 'PLC', name: 'PLC' },
              { value: 'Corp', name: 'Corp' },

              { value: 'SA', name: 'SA' },
              { value: 'SAA', name: 'SAA' },
              { value: 'SAC', name: 'SAC' },
              { value: 'SRL', name: 'SRL' },
              { value: 'EIRL', name: 'EIRL' },
              { value: 'CA.', name: 'CA.' },

              { value: 'Non-Profit', name: 'Non-Profit (specify)' },
              { value: 'Other', name: 'Other (specify)' }
            ]
          },
          {
            label: 'Non-Profit',
            name: 'non_profit',
            type: 'select',
            options: [
              { value: 'Association', name: 'Association' },
              { value: 'Foundation', name: 'Foundation' },
              { value: 'Committee', name: 'Committee' },
              { value: 'Other', name: 'Other (specify)' }
            ],
            displayOn: [
              {
                parent: 'legal_entity_type',
                conditionValue: 'Non-Profit'
              }
            ]
          },
          {
            label: 'Other Non-Profit',
            name: 'other_non_profit',
            type: 'text',
            displayOn: [
              {
                parent: 'legal_entity_type',
                conditionValue: 'Non-Profit'
              },
              {
                parent: 'non_profit',
                conditionValue: 'Other'
              }
            ]
          },
          {
            label: 'Other Type of Legal Entity',
            name: 'other_legal_entity_type',
            type: 'text',
            displayOn: [
              {
                parent: 'legal_entity_type',
                conditionValue: 'Other'
              }
            ]
          },
          {
            label: 'Type of Taxpayer Identification Number',
            name: 'taxpayer_identification_number_type',
            type: 'select',
            options: [
              { value: 'TIN', name: 'TIN' },
              { value: 'EIN', name: 'EIN' },
              { value: 'VAT', name: 'VAT' },
              { value: 'NINO', name: 'NINO' },

              { value: 'RUC', name: 'RUC' },
              { value: 'RUT', name: 'RUT' },
              { value: 'NIF', name: 'NIF' },
              { value: 'RIF', name: 'RIF' },

              { value: 'Other', name: 'Other (specify)' }
            ]
          },
          {
            label: 'Other Type of Taxpayer Identification Number',
            name: 'other_taxpayer_identification_number_type',
            type: 'text',
            displayOn: [
              {
                parent: 'taxpayer_identification_number_type',
                conditionValue: 'Other'
              }
            ]
          },
          {
            label: 'Taxpayer Identification Number',
            name: 'taxpayer_identification_number',
            type: 'text'
          },
          {
            label:
              'Describe Corporate Purpose / Products or Services that the company provides',
            name: 'corporate_purpose',
            type: 'text'
          },
          {
            label: 'IP',
            name: 'ip',
            type: 'text'
          },
          {
            label: 'Enter Street / Avenue',
            name: 'company_address',
            type: 'text',
            size: '6'
          },
          {
            label: 'Building number or name',
            name: 'corporate_building',
            type: 'text',
            size: '6'
          },
          {
            label: 'Interior # / Apartment # / Office #',
            name: 'corporate_apt_number',
            type: 'text',
            size: '6'
          },
          {
            label: 'Zonification / Town',
            name: 'corporate_town',
            type: 'text',
            size: '6'
          },
          {
            label: 'Country',
            name: 'country_incorporation',
            type: 'select',
            options: []
          },
          {
            label: 'State',
            name: 'state',
            type: 'select',
            options: []
          },
          json.country_incorporation === 'PE'
            ? {
                label: 'City / Province',
                name: 'city',
                type: 'select',
                options: []
              }
            : {
                label: 'City / Province',
                name: 'city',
                type: 'text'
              },
          json.country_incorporation === 'PE'
            ? {
                label: 'Municipality / District',
                name: 'corporate_district',
                type: 'select',
                options: []
              }
            : {
                label: 'Municipality / District',
                name: 'corporate_district',
                type: 'text'
              },
          {
            label: 'Zip Code',
            name: 'zip_code',
            type: 'text',
            size: '6'
          },
          {
            label: 'Ubigeo',
            name: 'ubigeo',
            type: 'text',
            size: '6'
          },
          {
            label: 'Registration Date (Constitution)',
            name: 'registration_date',
            type: 'date'
          },
          {
            label: 'Activity begin date',
            name: 'begin_date',
            type: 'date'
          },
          {
            label: 'Linked economic group (if applicable)',
            name: 'linked_economic_group',
            type: 'text'
          },
          {
            label: 'Company Phone',
            name: 'company_phone',
            type: 'tel'
          },
          {
            label: 'Incomes',
            name: 'incomes',
            type: 'number',
            size: '6'
          },
          {
            label: 'Patrimony',
            name: 'patrimony',
            type: 'text',
            size: '6'
          },
          {
            label: 'Audited financial statements',
            name: 'audited_financial_statements',
            type: 'select',
            options: [
              { value: 'Yes', name: 'Yes' },
              { value: 'No', name: 'No' }
            ],
            size: '6'
          },
          {
            label: 'Compliance officer',
            name: 'compliance_officer',
            type: 'select',
            options: [
              { value: 'Yes', name: 'Yes' },
              { value: 'No', name: 'No' }
            ],
            size: '6'
          },
          {
            label: 'Institutional investor',
            name: 'institutional_investor',
            type: 'select',
            options: [
              { value: 'Yes', name: 'Yes' },
              { value: 'No', name: 'No' }
            ],
            size: '6'
          }
        ]
      },
      {
        title: 'Contact person information',
        hint: [
          {
            p:
              'Enter information for a contact person in your company or entity.'
          }
        ],
        fields: [
          {
            label: 'Full name',
            name: 'full_name',
            type: 'text',
            size: '12'
          },
          {
            label: 'Job Position',
            name: 'job_position',
            type: 'text'
          },
          {
            label: 'Phone',
            name: 'phone',
            type: 'tel'
          }
        ]
      },
      {
        title: 'Upload Corporate documents',
        hint: [
          {
            p: 'Crop the images before uploading if you wish'
          }
        ],
        fields: [
          {
            label: 'Company Constitution Act',
            name: 'company_constitution_act',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
              }
            ]
          },
          {
            label:
              'Incorporation Document / Validity of Power of Attorney no later than 3 months',
            name: 'incorporation_document',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
              }
            ]
          },
          {
            label:
              'Receipt of Public Service (Electricity, Telephone, Water, etc. not more than 3 months)',
            name: 'receipt_public_service',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
              }
            ]
          },
          {
            label: 'Last income tax return',
            name: 'last_income_tax_return',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
              }
            ]
          },
          {
            label: 'Affidavit of Origins of funds',
            name: 'affidavit_origins_funds',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
              }
            ]
          },
          {
            label: 'RUC file',
            name: 'ruc_file',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
              }
            ]
          }
        ]
      }
    ];
    return structureScheme;
  };

  const FieldsForm = (
    formsString,
    schema,
    rowSize = '12',
    fieldsType = 'normal'
  ) => {
    if (!empty(formsString.dataForm)) {
      let json = JSON.parse(formsString.dataForm);

      let structureScheme = !empty(schema) ? schema : getSchema();

      let checkedInput =
        fieldsType === 'normal' ? inputsReturn : shareholderInputsReturn;

      const onSetInput = e => {
        if (fieldsType === 'normal') {
          onSetReturnDataInput(e);
        } else {
          onSetShareholderReturnDataInput(e);
        }
      };

      let Groups = [];

      for (let i in structureScheme) {
        let g = structureScheme[i];
        let Fields = [];
        for (let f in g.fields) {
          let field = g.fields[f];
          let { size, name, label, type } = field;
          let value =
            json[name] !== 'undefined'
              ? !empty(json[name])
                ? context.t(json[name])
                : null
              : null;
          if (!empty(value)) {
            Fields.push(
              <Col key={f} xl={size || '6'}>
                <Form.Group>
                  <Form.Label>{context.t(label)}</Form.Label>
                  <div className="container-check-input">
                    <Form.Check
                      className="left-check"
                      name={name}
                      onChange={onSetInput}
                      aria-label="Return"
                      checked={!empty(checkedInput[name]) ? true : false}
                      disabled={edit_fields.includes(name) ? true : false}
                    />
                    {type === 'file' ? (
                      field.hasOwnProperty('filetype') &&
                      field.filetype === 'image' ? (
                        <img
                          src={value}
                          className="image-preview"
                          width="95%"
                        />
                      ) : (
                        <a
                          className="form-control btn btn-success"
                          href={value}
                          target="_blank">
                          {' Descargar '}
                          <b>{`${GetFilename(value)}`}</b>
                        </a>
                      )
                    ) : (
                      <Form.Control
                        name={`${name}_field`}
                        type="text"
                        readOnly={true}
                        defaultValue={
                          !empty(value)
                            ? value.toString().toUpperCase()
                            : 'NO INGRESADO'
                        }
                        className={!empty(value) ? '' : 'no-aplica'}
                      />
                    )}
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must put {name}', { name: label })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            );
          } else {
            Fields.push(<React.Fragment key={f} />);
          }
        }
        Groups.push(
          <Card className={`group-form col-xl-${rowSize}`} key={i}>
            <Card.Header>{g.title}</Card.Header>
            <Card.Body>
              <Row>{Fields}</Row>
            </Card.Body>
          </Card>
        );
      }
      return Groups;
    }
  };

  async function viewShareholderBySchema(fields, row, schema) {
    //setShareholderInputsReturn({});
    let share = {
      dataForm: JSON.stringify(fields)
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-alert">
            <button
              className="close"
              onClick={() => {
                onClose();
              }}>
              X
            </button>
            <h1>
              Ver Datos de{' '}
              {row.shareholderType === 'COMPANY_SHAREHOLDER'
                ? fields.company_legal_name
                : fields.first_name}
            </h1>
            <div>
              <Row>{FieldsForm(share, schema, '6', 'shareholder')}</Row>
            </div>
          </div>
        );
      }
    });
  }

  const FieldsToReturn = () => {
    const handleModalClose = () => {
      setShowModal(false);
    };
    const handleModalShow = () => {
      setShowModal(true);
    };

    //findFieldsInSchema
    let ListItems = [];
    let ListItemsLi = [];
    let ListItemsToReturn = [];
    let contador = 0;
    for (let d in inputsReturn) {
      if (!empty(inputsReturn[d])) {
        let { label, name } = findFieldsInSchema(inputsReturn[d]);
        ListItems.push(
          <ListGroup.Item key={d} style={{ display: 'flex' }}>
            <b style={{ width: '100%' }}>{context.t(label)}</b>
            <Button
              variant="outline-danger"
              name={name}
              onClick={onRemoveReturnDataInput}
              style={{
                marginLeft: '1rem',
                float: 'right',
                verticalAlign: 'middle'
              }}>
              X
            </Button>
          </ListGroup.Item>
        );
        ListItemsLi.push(
          <li key={d} style={{ width: '100%' }}>
            {context.t(label)}
          </li>
        );
        ListItemsToReturn.push({
          label_en: label,
          label_es: context.t(label),
          name
        });
        contador++;
      }
    }
    if (contador < 1) {
      ListItems.push(
        <ListGroup.Item key={1} variant="dark">
          Ningun campo ha sido seleccionado
        </ListGroup.Item>
      );
    }
    let nationality = [
      'PE',
      'CO',
      'VE',
      'ES',
      'PR',
      'PY',
      'PA',
      'NI',
      'MX',
      'CR',
      'CL',
      'BO',
      'AR',
      'CU',
      'NI'
    ];
    let { country_incorporation } = !empty(levelBasicInfo)
      ? JSON.parse(levelBasicInfo.dataForm)
      : {};
    return (
      <ListGroup>
        {ListItems}
        <Modal
          show={showModal}
          onHide={handleModalClose}
          className="modal-return">
          <Modal.Header closeButton />
          <div className="custom-ui">
            <h1>¿Estas seguro en devolver los siguientes campos?</h1>
            <ul>{ListItemsLi}</ul>
            <Form noValidate encType="multipart/form-data" autoComplete="off">
              <Form.Group controlId="text_observacion">
                <Form.Label>
                  Agregar Observación{' '}
                  <b>(Esta observación llegará en el correo del cliente)</b>
                  <br />
                  Idioma del mensaje:{' '}
                  <b>
                    {!empty(country_incorporation)
                      ? nationality.includes(country_incorporation)
                        ? 'ES'
                        : 'EN'
                      : 'EN'}
                  </b>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="text_observacion"
                  rows={3}
                  onChange={onSetObservation}
                  value={textObservacion.text_observacion || ''}
                />
              </Form.Group>
              <div className="button-fields-botton">
                <Button
                  disabled={
                    !empty(textObservacion.text_observacion)
                      ? textObservacion.text_observacion.trim().length < 1
                        ? true
                        : false
                      : true
                  }
                  onClick={async () => {
                    try {
                      setLoading(1);
                      handleModalClose();
                      toast.info('Enviando...', {
                        position: toast.POSITION.TOP_CENTER
                      });
                      let response = await returnCorporateInfoFields(
                        userInfo.UserId,
                        ListItemsToReturn,
                        id,
                        textObservacion.text_observacion
                      );
                      if (response.data.message === 'success') {
                        toast.success('Registro guardado exitosamente', {
                          position: toast.POSITION.TOP_CENTER
                        });
                        toast.info('Actualizando pagina...', {
                          position: toast.POSITION.TOP_CENTER
                        });
                        window.location.reload();
                      }
                    } catch (error) {
                      console.log('errorrrrr', error.message);
                      handleModalClose();
                      toast.warn(context.t('Information could not be saved'), {
                        zIndex: 9999,
                        position: toast.POSITION.TOP_CENTER
                      });
                    }
                    setLoading(0);
                  }}
                  className="confirmation">
                  Si, devolver campos
                </Button>
                <button type="button" onClick={handleModalClose}>
                  No
                </button>
              </div>
            </Form>
          </div>
        </Modal>
        {contador > 0 ? (
          <Button
            style={{
              margin: '1rem 0',
              padding: '1rem',
              fontSize: '1.3rem',
              fontWeight: '500'
            }}
            onClick={() => {
              handleModalShow();
              //returnCorporateInfoFieldsAlert(inputsReturn, inputs.id);
            }}>
            Solicitar corrección de campos
          </Button>
        ) : (
          ''
        )}
      </ListGroup>
    );
  };

  return (
    <React.Fragment>
      <div className="content-container">
        <Loading loading={loading} />
        <Sidebar
          sidebar={
            <div className="sidebar-content">
              <div>
                <h3>{context.t('Instructions')}</h3>
              </div>
              <div className="card-line">
                <b>Rechazar</b>
                <div className="card-line-body">
                  <div className="ex-row">
                    <Button
                      variant="danger"
                      disabled={true}
                      type="button"
                      style={{ padding: '1em', width: 'auto' }}>
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </Button>
                  </div>
                  <p>
                    Cuando rechazes a un cliente, el mismo ya no deberia ser
                    capaz de solicitar otro aumento de nivel.
                  </p>
                </div>
              </div>
              <div className="card-line">
                <b>Aprobar</b>
                <div className="card-line-body">
                  <div className="ex-row">
                    <Button
                      disabled={true}
                      variant="success"
                      type="button"
                      style={{ padding: '1em' }}>
                      <FontAwesomeIcon icon={faCheckSquare} className="spc" />
                      <span>{context.t('Approve')}</span>
                    </Button>
                  </div>
                  <p>
                    Según el nivel solicitado, cuando apruebes al cliente,
                    automaticamente cambiará a estado Aprobado y subira de nivel
                    en Banexcoin.
                  </p>
                </div>
              </div>
              <div className="card-line">
                <b>Enviar a TRUST_PE</b>
                <div className="card-line-body">
                  <div className="ex-row">
                    <Button
                      variant="dark"
                      type="button"
                      style={{ padding: '1em' }}>
                      <FontAwesomeIcon icon={faShareSquare} className="spc" />
                      <span>{context.t('')}</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-line">
                <b>Información incorrecta</b>
                <div className="card-line-body">
                  <div className="ex-row">
                    <Button
                      variant="danger"
                      disabled={true}
                      type="button"
                      style={{ padding: '1em', width: 'auto' }}>
                      <FontAwesomeIcon icon={faPen} className="spc" />
                      <span>{context.t('Incorrect information')}</span>
                    </Button>
                  </div>
                  <p>
                    Rechaza los campos que desees! Selecciona los campos a
                    devolver y luego haz click al botón{' '}
                    <b>{context.t('Incorrect information')}</b> para solicitar
                    al cliente que los corrija. Una vez rechazado le llegará un
                    email al cliente con el detalle.
                  </p>
                </div>
              </div>
              <div className="card-line">
                <b>Editar Info</b>
                <div className="card-line-body">
                  <div className="ex-row">
                    <Button
                      variant="warning"
                      disabled={true}
                      type="button"
                      style={{ padding: '1em', width: 'auto' }}>
                      <FontAwesomeIcon icon={faUserEdit} className="spc" />
                      <span>{context.t('Edit Info')}</span>
                    </Button>
                  </div>
                  <p>
                    Edita los campos que quieras! Si hay algun inconveniente con
                    la data ingresada por el usuario, la puedes corregir.
                  </p>
                </div>
              </div>
              <div className="card-line">
                <b>Enviar a DIG_iD_VERIF</b>
                <div className="card-line-body">
                  <div className="ex-row">
                    <Button
                      variant="success"
                      disabled={true}
                      type="button"
                      style={{ padding: '1em', width: 'auto' }}>
                      <FontAwesomeIcon icon={faPaperPlane} className="spc" />
                      <span>{context.t('Send to DIG_iD_VERIF')}</span>
                    </Button>
                  </div>
                  <p>
                    Si la información esta completa y válida, puedes enviarla a
                    DIG_iD_VERIF para evaluación.
                  </p>
                </div>
              </div>
            </div>
          }
          children={''}
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={{
            overlay: { zIndex: 3 },
            sidebar: {
              background: '#F5F5F5',
              position: 'fixed',
              overflow: 'auto',
              zIndex: 9
            }
          }}
          pullRight={true}
        />
        <Sidebar
          sidebar={
            <AdminLevelsCorporateViewPageFormBeta
              levelBasicInfo={levelBasicInfo}
              onInputChange={onInputChange}
              inputs={inputs}
              onSubmit={onSubmit}
              onInputChangeByName={onInputChangeByName}
              countries={countries}
              setLoading={setLoading}
              loading={loading}
              getUserLevelById={getUserLevelById}
              closeSidebarForm={closeSidebarForm}
            />
          }
          children={''}
          open={sidebarFormOpen}
          onSetOpen={onSetSidebarFormOpen}
          styles={{
            overlay: { zIndex: 3 },
            sidebar: {
              background: '#F5F5F5',
              position: 'fixed',
              overflow: 'auto',
              zIndex: 9
            }
          }}
          pullRight={true}
        />
        <Sidebar
          sidebar={
            <AdminLevelsCorporateViewPageFormShareholdersBeta
              levelBasicInfo={selectedShareholderFields}
              countries={countries}
              schemaFull={schemaFull}
              setLoading={setLoading}
              loading={loading}
              getUserLevelById={getUserLevelById}
              closeSidebarFormShareholder={closeSidebarFormShareholder}
              userId={levelBasicInfo.banexcoinUserid}
            />
          }
          children={''}
          open={sidebarFormShareholderOpen}
          onSetOpen={onSetSidebarFormShareholderOpen}
          styles={{
            overlay: { zIndex: 3 },
            sidebar: {
              background: '#F5F5F5',
              position: 'fixed',
              overflow: 'auto',
              zIndex: 9
            }
          }}
          pullRight={true}
        />
        <div className="topbar-actions">
          <Row className="direct-actions">
            <Col xl="7" className="vertical-middle">
              <Row>
                <Col xl="1">
                  {levelBasicInfo.reviewStatus === 'ACCEPTED' ? (
                    <Button
                      variant="danger"
                      disabled
                      type="button"
                      style={{ padding: '1em' }}>
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      onClick={() => rejectedSubmit()}
                      type="button"
                      style={{ padding: '1em' }}>
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </Button>
                  )}
                </Col>
                <Col xl="2">
                  {levelBasicInfo.reviewStatus === 'ACCEPTED' ? (
                    <Button
                      variant="success"
                      type="button"
                      disabled={true}
                      style={{ padding: '1em' }}>
                      <span className="levelmax">{context.t('Level Max')}</span>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => confirmSubmit()}
                      disabled={levelBasicInfo.status === 7 ? true : false}
                      variant="success"
                      type="button"
                      style={{ padding: '1em' }}>
                      <FontAwesomeIcon icon={faCheckSquare} className="spc" />
                      <span>{context.t('Approve')}</span>
                    </Button>
                  )}
                </Col>
                <Col xl="2">
                  {levelBasicInfo.reviewStatus === 'ACCEPTED' ? (
                    <Button
                      variant="danger"
                      disabled
                      type="button"
                      style={{ padding: '1em' }}>
                      <FontAwesomeIcon icon={faPen} className="spc" />
                      <span>{context.t('Incorrect info')}</span>
                    </Button>
                  ) : (
                    // Bloqueo Temporal
                    // <Button
                    //   variant="danger"
                    //   onClick={() => returnIncorrectFields()}
                    //   disabled={statusReturn}
                    //   type="button"
                    //   style={{ padding: '1em' }}>
                    //   <FontAwesomeIcon icon={faPen} className="spc" />
                    //   <span>{context.t('Incorrect information')}</span>
                    // </Button>
                    <React.Fragment />
                  )}
                </Col>
                <Col xl="2">
                  <Button
                    onClick={() => sendCorfidSubmit()}
                    variant="dark"
                    type="button"
                    style={{ padding: '1em' }}>
                    <FontAwesomeIcon icon={faShareSquare} className="spc" />
                    <span>{context.t('Send to TRUST_PE')}</span>
                  </Button>
                </Col>
                <Col xl="2">
                  {/* Bloqueo Temporal */}
                  <Button
                    onClick={() => onSetSidebarFormOpen(true)}
                    variant="warning"
                    type="button"
                    style={{ padding: '1em' }}>
                    <FontAwesomeIcon icon={faUserEdit} className="spc" />
                    <span>{context.t('Modify Info')}</span>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xl="5">
              <Row className="subinfo">
                <Col xl="4">
                  <Row>
                    <div className="line-row">
                      <b>{context.t('Actualizado Por')}</b>
                      <p>{banexcoinUserInfo.banexcoinUsername}</p>
                      <p>{banexcoinUserInfo.email}</p>
                    </div>
                  </Row>
                </Col>
                <Col xl="6" className="vertical-middle">
                  <Row>
                    <Col xl="1">
                      <b className="level-blob">1</b>
                    </Col>
                    <Col xl="10">
                      <div className="container-bar">
                        <div
                          className={
                            'progress-bar level' + levelBasicInfo.nextLevel
                          }
                        />
                      </div>
                    </Col>
                    <Col xl="1">
                      <b className="level-blob">4</b>
                    </Col>
                  </Row>
                </Col>
                <Col xl="2">
                  <Button
                    onClick={() => onSetSidebarOpen(true)}
                    variant="secondary"
                    className="text-center sidebar-button-call"
                    type="button"
                    style={{ padding: '1em' }}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className={
            layoutClasses() + ' viewbiodata fixedcenter corporate-full'
          }>
          {isOpen ? (
            <React.Fragment>
              <Popup
                content={
                  <div>
                    <div className="crop-container">
                      <Cropper
                        style="width: 500px; height: 500px; align:center"
                        image={currentImages[index].url}
                        crop={crop}
                        zoom={zoom}
                        aspect={10 / 14}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                  </div>
                }
                handleClose={() => {
                  togglePopup(index);
                }}
              />
              <div>asdasdasdasdasd</div>
            </React.Fragment>
          ) : (
            ''
          )}
          <Row>
            <Col xl="9">
              <Card>
                <Card.Header>
                  <h3>General info</h3>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl="5">
                      <b>{context.t('Email')}:</b>
                      <p>{levelBasicInfo.bnxEmail}</p>
                    </Col>
                    <Col xl="2">
                      <b>{context.t('Account Id')}:</b>
                      <p>{levelBasicInfo.banexcoinUserAccountId}</p>
                    </Col>
                    <Col xl="2">
                      <b>{context.t('User Id')}:</b>
                      <p>
                        {!empty(levelBasicInfo.banexcoinUserid)
                          ? levelBasicInfo.banexcoinUserid
                          : ''}
                      </p>
                    </Col>
                    <Col xl="3">
                      <b>{context.t('Nombre de usuario')}:</b>
                      <p>
                        {!empty(levelBasicInfo.banexcoinUsername)
                          ? levelBasicInfo.banexcoinUsername
                          : ''}
                      </p>
                    </Col>
                    <Col xl="3">
                      <b>{context.t('Review Status')}:</b>
                      <p className="text-body">
                        {!empty(levelBasicInfo)
                          ? context.t(levelBasicInfo.reviewStatus)
                          : ''}{' '}
                        para nivel 4
                      </p>
                    </Col>
                    <Col xl="3">
                      <b>{context.t('Current Level')}:</b>
                      <p>{levelBasicInfo.userLevel}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <h3>KYC Info</h3>
                </Card.Header>
                <Card.Body className="data-readonly">
                  {FieldsForm(levelBasicInfo, null)}
                  <Row>
                    {/* <Col xl="4">
                    <div className="field-row">
                      <div className="left">
                        <Form.Check
                          name="first_name"
                          onChange={onSetReturnDataInput}
                          aria-label="Return"
                          disabled={
                            levelBasicInfo.nextLevel === 1
                              ? edit_fields.includes('first_name')
                                ? true
                                : false
                              : true
                          }
                        />
                      </div>
                      <div>
                        <b>{context.t('First Name')}:</b>
                        <p>{levelBasicInfo.firstName}</p>
                      </div>
                    </div>
                  </Col> */}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="3">
              <Card
                className={
                  !empty(levelBasicInfo)
                    ? 'status' + levelBasicInfo.reviewStatus
                    : ''
                }>
                <Card.Header>
                  <h3>{context.t('Request Status')}</h3>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <h2>
                      {!empty(levelBasicInfo)
                        ? context.t(levelBasicInfo.reviewStatus)
                        : ''}
                    </h2>
                  </Row>
                </Card.Body>
              </Card>
              <Card
                style={{
                  position: 'sticky',
                  top: '95px'
                }}>
                <Card.Header>
                  <h3>{context.t('Campos a devolver')}</h3>
                </Card.Header>
                <Card.Body
                  style={{
                    padding: '1rem',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginBottom: '1rem'
                  }}>
                  {FieldsToReturn()}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <FormComments
                levelId={levelBasicInfo.id}
                userInfo={userInfo}
                type="corporate"
              />
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <Card.Header>
                  <h3>{context.t('Shareholders')}</h3>
                </Card.Header>
                <Card.Body style={{ padding: '1rem 0' }}>
                  <TableLegalsShareholders
                    shareholders={shareholders}
                    getLabel={getLabel}
                    //viewPersonShareholder={viewShareholderBySchema}
                    togglePopup={togglePopup}
                    sendToIdm={sendToIdm}
                    logReniecInfo={logReniecInfo}
                    onSetSidebarFormShareholderOpen={
                      onSetSidebarFormShareholderOpen
                    }
                    countries={countries}
                    setLoading={setLoading}
                    loading={Loading}
                    userInfo={userInfo}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <Card.Header>
                  <h3>{context.t('History log')}</h3>
                </Card.Header>
                <Card.Body>
                  <Table
                    striped
                    responsive
                    hover
                    size="sm"
                    className="changelog">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Accion</th>
                        <th>Descripcion</th>
                        <th>Ejecutado por</th>
                        <th className="created_at">Fecha</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!empty(logs) ? (
                        logs.map((row, i) => {
                          let action = ACTION_TYPE.find(l => l.id === row.type);
                          return (
                            <tr key={i} className={'rowstatus' + row.status}>
                              <td>{i + 1}</td>
                              <td>{!empty(action) ? action.name : ''}</td>
                              <td>{row.description}</td>
                              <td>{row.executeByName}</td>
                              <td>
                                {moment(row.createdAt)
                                  .format('DD-MM-YYYY h:mm:ss a')
                                  .toString()}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <React.Fragment>
                          <tr className="emptydata">
                            <td colSpan="5">No existen datos</td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

AdminLevelsViewPageBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AdminLevelsViewPageBeta);
