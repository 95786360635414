import React from 'react';
import PropTypes from 'prop-types';
import ScriptLoader from 'apex-web/lib/helpers/scriptLoader';

var RazorpayDepositWorkflowComponent = function RazorpayDepositWorkflowComponent(
  _ref
) {
  var options = _ref.options;
  return React.createElement(ScriptLoader, {
    url: 'https://checkout.razorpay.com/v1/checkout.js',
    onLoadCallback: function onLoadCallback() {
      var optionsObj = Object.assign({}, options, {
        handler: function handler() {
          window.location.href = options.redirectUrl;
        }
      });
      var rzp = new Razorpay(optionsObj); // eslint-disable-line no-undef

      rzp.open();
    }
  });
};

RazorpayDepositWorkflowComponent.defaultProps = {
  options: {
    key: '',
    amount: '',
    order_id: '',
    handler: '',
    prefill: {
      contact: '',
      email: ''
    }
  }
};

RazorpayDepositWorkflowComponent.propTypes = {
  options: PropTypes.shape({
    key: PropTypes.string,
    amount: PropTypes.string,
    order_id: PropTypes.string,
    handler: PropTypes.string,
    prefill: PropTypes.shape({
      contact: PropTypes.string,
      email: PropTypes.string
    })
  })
};

export default RazorpayDepositWorkflowComponent;
