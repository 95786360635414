import isEmpty from "is-empty";
import moment from "moment";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import DateHelper from "../../helpers/Date.helper";
import {
  bankAccountBalance,
  deleteBankTransaction,
  freezeFeeTransactions,
  syncBankTransactions,
} from "../FallServices/BankTransactionsServices";
import {
  bankTransactions,
  freezeBankTransactions,
} from "../FallServices/DepositServices";
import { getCurrencyByBankAccount } from "../../components/FallComponents/BankTransactionsPage/getCurrencyByBankAccount";
import { getAllProviders } from "../FallServices/ProvidersServices";
class BankTransactionsController {
  static getAllBankTransactions = async ({
    userInfo,
    setLoading,
    setRows,
    setTotalRows,
    filterSearch,
    filterConfig
  }) => {
    try {
      setLoading(true);
      const data = {
        ...filterSearch,
        from: filterSearch.from || moment().format("YYYY/MM/DD").toString(),
        to: filterSearch.to || moment().format("YYYY/MM/DD").toString(),
        path: "/api/v1/bank/transactions",
      };
      const response = await bankTransactions(userInfo.UserId, data);
      if (!response.hasOwnProperty("data")) return false;
      setTotalRows(response.total);
      let temporaryRows = [];
      for (let r of response.data) {
        const payloadObj = JSON.parse(r.payload);
        if (
          !isEmpty(filterSearch.bankCurrency) &&
          getCurrencyByBankAccount(r.sourceBankAccountNumber) !=
            filterSearch.bankCurrency
        )
          continue;

        if (
          !isEmpty(filterSearch.bankTransactionType) &&
          r.transactionType != filterSearch.bankTransactionType
        )
          continue;

        temporaryRows.push({
          ...r,
          created: DateHelper.invertedYearFormat(r.created),
          currency: getCurrencyByBankAccount(r.sourceBankAccountNumber),
          payload: payloadObj.detail,
        });
      }
      setRows(temporaryRows.slice(filterConfig.limit * (filterConfig.page - 1),filterConfig.limit * filterConfig.page));
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static getBankAccountBalance = async ({ userInfo, setBalance }) => {
    try {
      const response = await bankAccountBalance(userInfo.UserId);
      if (!response.hasOwnProperty("data")) return false;
      setBalance(response.data);
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no es accesible",
      });
      return false;
    }
  };
  static syncBankTransactions = async ({
    userInfo,
    data,
    setLoading,
    setBalance,
  }) => {
    try {
      const body = {
        from: data.from,
        to: data.to,
        bankCode: data.bankCode,
        path: "/api/v1/bank/transactions/sync",
      };
      setLoading(true);
      await syncBankTransactions(userInfo.UserId, body);
      BankTransactionsController.getBankAccountBalance({
        userInfo,
        setBalance,
      });
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "No pudo realizarse la sincronización",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static processTransaction = async ({ userInfo, data }) => {
    try {
      if (isEmpty(data.id)) {
        return false;
      }
      const body = {
        id: data.id,
        details: !isEmpty(data.details) ? data.details : null,
        reference: !isEmpty(data.reference) ? data.reference : null,
        path: "/api/v1/bank/freezedeposit",
      };
      const response = await freezeBankTransactions(userInfo.UserId, body);
      if(!isEmpty(response.data) && response.data.hasOwnProperty("id")){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Reclamado exitosamente",
        });
        return response.data.id
      }
      return false;
    } catch (e) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Error al procesar la transacción",
      });
      return false;
    }
  };
  static processClaimTransactionFee =async ({ userInfo, data }) => {
    try {
      const paramsProcess = {
        id: data.id,
        details: {
          admin: {
            type: "FEE",
            message: `${data.payload}`,
            user: userInfo,
          },
        },
      };
      paramsProcess.details = new Buffer.from(
        JSON.stringify(paramsProcess.details)
      ).toString("base64");
      return await BankTransactionsController.processTransaction({
        data: paramsProcess,
        userInfo,
      });
    } catch (e) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Error al procesar la transacción",
      });
      return false;
    }
  };
  static deleteTransaction = async ({ data, userInfo }) => {
    try {
      if (isEmpty(data.id)) return false;
      const body = {
        id: data.id,
        path: "/api/v1/bank/transaction/delete",
      };
      await deleteBankTransaction(userInfo.UserId, body);
    } catch (e) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Error al borrar la transacción",
      });
      return false;
    }
  };
  static freezeFeeTransactions = async ({ userInfo, data }) => {
    try {
      ComponentsHelper.toast({
        type: toastTypes.info,
        message: "Asignando a comisiones",
      });
      const response = await freezeFeeTransactions(userInfo.UserId, data);
      if(typeof response === "object"){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Asignaciones completadas de manera exitosa.",
        });
        return true
      }
      return false
    } catch (e) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Error al procesar las transacciones.",
      });
      return false;
    }
  }
  static getAllProviders = async ({ userInfo, setProvider }) => {
    try {
      const response = await getAllProviders(userInfo.UserId);
      if(!isEmpty(response.data)) setProvider(response.data)
      return true
    } catch (e) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Error al consultar proveedores.",
      });
      return false;
    }
  }
}

export default BankTransactionsController;
