import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import moment from 'moment-timezone';
import { useForm } from '../../hooks/formHooks';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import {
  Button,
  Row,
  Col,
  Card,
  InputGroup,
  Form,
  FormControl,
  Badge,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import Loading from '../../components/Loading';
import {
  createComment,
  getComments,
  deleteComment,
  editComment
} from './FormCommentHooks';

const FormComment = (props, context) => {
  const { levelId, userInfo, type } = props;
  const [loading, setLoading] = useState(0);
  const [allComments, setAllComments] = useState([]);
  const [filesInput, setFilesInput] = useState([]);
  const [editing, setEditing] = useState(null);
  // if (fetching) {
  //   return (
  //     <React.Fragment>
  //       <Col xs lg="8">
  //         <h1 style={{ textAlign: 'center', padding: 20 }}>
  //           {context.t('Loading')}
  //         </h1>
  //       </Col>
  //     </React.Fragment>
  //   );
  // }
  const addFileInput = () => {
    let inputFiles = filesInput;
    let length = inputFiles.length;
    setFilesInput(inputFiles => [...inputFiles, { id: length + 1 }]);
  };

  const removeFileInput = id => {
    setFilesInput(filesInput.filter((s, sindex) => s.id !== id));
  };

  const getCommentaries = async () => {
    setLoading(1);
    try {
      let comments = await getComments(userInfo.UserId, levelId);
      setAllComments(comments);
    } catch (error) {
      console.log('Error on getComments', error);
    }
    setLoading(0);
  };

  const sendConfirmRemoveComment = async id => {
    setLoading(1);
    try {
      let deleteCommentResult = await deleteComment(userInfo.UserId, id);
      if (!empty(deleteCommentResult.id)) {
        await getCommentaries();
        toast.success(context.t('Comentario eliminado exitosamente'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.log('Error on delete comment', error);
    }
    setLoading(0);
  };

  const alertRemove = id => {
    confirmAlert({
      title: 'Eliminar comentario',
      message: '¿Está seguro de eliminar el comentario?',
      buttons: [
        {
          label: 'Si',
          onClick: async () => {
            sendConfirmRemoveComment(id);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const GetFilename = url => {
    if (url) {
      return decodeURI(url.split('/').pop());
    }
    return '';
  };

  function isUrlanImage(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  const fixStorageLink = originalLink => {
    if (!empty(originalLink)) {
      let failedPrelink = 'sgp1.digitaloceanspaces.com/banexcoin';
      let containts = originalLink.includes(failedPrelink);
      if (containts) {
        let result = decodeURI(originalLink);
        result = result.replace(/\s/g, '%20');
        return result;
      } else {
        return originalLink;
      }
    } else {
      return null;
    }
  };

  const AvatarName = props => {
    let returnedText = '?';
    if (!empty(props.fullname)) {
      let arr = props.fullname.split(' ');
      if (arr.length > 1) {
        returnedText = arr[0].charAt(0) + arr[1].charAt(0);
      } else {
        returnedText = arr[0].substring(0, 2);
      }
    }
    return (
      <div
        className="avatar-img"
        style={{
          backgroundColor: !empty(props.color) ? props.color : 'orange'
        }}>
        {returnedText}
      </div>
    );
  };

  const customValidations = inputs => {
    let errors = {};
    let message = inputs.message || '';
    if (empty(message.trim())) {
      errors.message = 'Debe ingresar un mensaje';
    }
    return errors;
  };

  const alertEditComentary = id => {
    if (empty(inputs.messageedit)) {
      alert('Oops! No puedes dejar vacío este campo');
    } else {
      confirmAlert({
        title: 'Editar comentario',
        message: '¿Está seguro de editar el comentario?',
        buttons: [
          {
            label: 'Si',
            onClick: async () => {
              editCommentary(id);
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const editCommentary = async id => {
    setLoading(1);
    try {
      let data = {
        messageedit: inputs.messageedit
      };
      let result = await editComment(userInfo.UserId, id, data);
      if (!empty(result.id)) {
        setEditing(null);
        await getCommentaries();
      }
    } catch (error) {
      console.log('ERROR:::::', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const onSubmitCallback = async inputs => {
    try {
      setLoading(1);
      if (empty(errors)) {
        let data = {
          message: inputs.message,
          type: !empty(type) ? type : null
        };
        data.images = [];
        for (let i of filesInput) {
          data.images.push(inputs[`images-${i.id}`]);
        }
        let result = await createComment(userInfo.UserId, levelId, data);
        if (!empty(result.id)) {
          setInputs(inputs => ({
            validated: false,
            message: ''
          }));
          setFilesInput([]);
          await getCommentaries();
          toast.success(context.t('Information saved successfully'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
    } catch (error) {
      console.log('ERROR:::::', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  let initialState = {
    validated: false
  };
  let {
    inputs,
    errors,
    onSubmit,
    setInputs,
    onInputChange,
    onInputFileChange
  } = useForm(initialState, onSubmitCallback, customValidations);

  useEffect(
    () => {
      if (!empty(levelId) && !empty(userInfo)) {
        getCommentaries();
      }
    },
    [levelId, userInfo]
  );

  const editComent = (index, message) => {
    setInputs(inputs => ({
      ...inputs,
      messageedit: message
    }));
    setEditing(index);
  };

  const elementComments = comments => {
    let comentary = [];
    for (let c of comments) {
      if (c.deleted) {
        continue;
      }

      let attach = [];
      let files = JSON.parse(c.files);
      let filesLength = files.length;
      for (let i of files) {
        let link = fixStorageLink(i.location);
        if (isUrlanImage(link)) {
          attach.push(
            <li key={files.indexOf(i)}>
              <a target="_blank" href={link} className="btn btn-primary attach">
                <img src={link} />
                <Badge variant="light">{i.name}</Badge>
              </a>
            </li>
          );
        } else {
          attach.push(
            <li key={files.indexOf(i)}>
              <a target="_blank" href={link} className="btn btn-primary attach">
                <Badge variant="light">{i.name}</Badge>
              </a>
            </li>
          );
        }
      }
      comentary.push(
        <div
          className={
            'commentary-box ' +
            (c.banexcoinUserByCreatedBy.banexcoinUserid === userInfo.UserId
              ? 'own-comment'
              : '')
          }
          key={comments.indexOf(c)}>
          <div className="comment-avatar">
            <AvatarName
              fullname={c.banexcoinUserByCreatedBy.banexcoinUsername}
            />
          </div>
          <div className="comment-container">
            <div className="comment-header">
              <div className="comment-header-box">
                <b>{c.banexcoinUserByCreatedBy.banexcoinUsername}</b>
                <span className="comment-time">
                  {moment(c.createdAt).fromNow()}
                  {c.createdAt !== c.updatedAt ? <b> (Actualizado)</b> : ''}
                </span>
              </div>
              {userInfo.UserId ===
              c.banexcoinUserByCreatedBy.banexcoinUserid ? (
                editing !== comments.indexOf(c) ? (
                  <DropdownButton
                    title="Más"
                    id="dropdown-menu-align-right"
                    className="more-actions">
                    <Dropdown.Item
                      onClick={() => {
                        editComent(comments.indexOf(c), c.message);
                      }}>
                      Editar
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="remove-style"
                      onClick={() => {
                        alertRemove(c.id);
                      }}>
                      Eliminar comentario
                    </Dropdown.Item>
                  </DropdownButton>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </div>
            {editing === comments.indexOf(c) ? (
              <div>
                <InputGroup>
                  <Form.Control
                    required
                    className="edit-field"
                    as="textarea"
                    name="messageedit"
                    value={inputs.messageedit}
                    onChange={onInputChange}
                    rows={3}
                  />
                  <InputGroup.Append>
                    <Button
                      type="button"
                      onClick={() => {
                        alertEditComentary(c.id);
                      }}
                      variant="success">
                      Actualizar comentario
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <Button
                  type="button"
                  variant="danger"
                  className="cancel-edit"
                  onClick={() => {
                    setEditing(null);
                  }}>
                  Cancelar
                </Button>
              </div>
            ) : (
              <React.Fragment>
                <div className="text-coment">{c.message}</div>
                <details className="attachs">
                  <summary>Adjuntos ({filesLength})</summary>
                  <ul>{attach}</ul>
                </details>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    }
    return comentary;
  };

  const listingAll = comments => {
    let commentaries = !empty(comments) ? comments : [];
    return (
      <React.Fragment>
        {elementComments(commentaries)}
        <Form
          noValidate
          validated={inputs.validated}
          encType="multipart/form-data"
          onSubmit={onSubmit}>
          {editing === null ? (
            <React.Fragment>
              <Form.Group controlId="message">
                <Form.Label>Escribe tu comentario</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    as="textarea"
                    name="message"
                    value={inputs.message}
                    onChange={onInputChange}
                    rows={3}
                    isInvalid={
                      !empty(errors) && !empty(errors.message) ? true : false
                    }
                  />
                  <InputGroup.Append>
                    <Button type="submit" variant="success">
                      Enviar comentario
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              <div className="images-inputs">
                <div className="head-attach">
                  <h2>Adjuntar archivos al comentario</h2>
                  <Button
                    type="button"
                    variant="success"
                    onClick={() => {
                      addFileInput();
                    }}>
                    Agregar
                  </Button>
                </div>
                {filesInput.map((field, index) => (
                  <Form.Group controlId={`images-${field.id}`} key={field.id}>
                    <Form.Control
                      required
                      type="file"
                      name={`images-${field.id}`}
                      onChange={onInputFileChange}
                    />
                    <Button
                      variant="danger"
                      onClick={() => {
                        removeFileInput(field.id);
                      }}>
                      Remove
                    </Button>
                  </Form.Group>
                ))}
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
        </Form>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <Card>
        <Card.Header className="comentary-header">
          <h3>{context.t('Comments')}</h3>
          <Button
            type="button"
            onClick={() => {
              getCommentaries();
            }}>
            (Actualizar)
          </Button>
        </Card.Header>
        <Card.Body className="p-0 comentary-container">
          {listingAll(allComments)}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

FormComment.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormComment;
