import HandlerBackend from '../../helpers/HandlerBackend';

export const getAllFiatAccounts = async (userId, filterConfig, filterSearch) => {
   try {
      const path = '/api/fiat-allaccounts';
      const params = {
         filterConfig,
         filterSearch
      };
      const configRequest = {
         userId,
         method: 'post',
         path,
         data: params
      };
      const response = await HandlerBackend.sendRequest(configRequest);
      return (response.hasOwnProperty('status')) ? response.data.fiatAccounts : {};
   } catch (error) {
      return false;
   }
};

export const downloadAllFiatAccounts = async (userId) => {
   try {
      const path = '/api/download/fiat-allaccounts';
      const configRequest = {
         userId,
         method: 'get',
         path
      };
      const currentDate = new Date();
      const dateTime = currentDate.getDate() + '_' + (currentDate.getMonth() + 1) + '_' + currentDate.getFullYear() + '___' + currentDate.getHours() + '_' + currentDate.getMinutes() + '_' + currentDate.getSeconds();
      const response = await HandlerBackend.sendRequest(configRequest);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `fiat_accounts_report_${dateTime}.csv`);
      document.body.appendChild(link);
      link.click();
      return (response.hasOwnProperty('status')) ? true : false;
   } catch (error) {
      return false;
   }
};

export const approveFiatAccount = async (userId, id) => {
   try {
      const path = `/api/fiat-accounts/approve/${id}`;
      const configRequest = {
         userId,
         method: 'post',
         path
      };
      const response = await HandlerBackend.sendRequest(configRequest);
      return (response.hasOwnProperty('status')) ? true : false;
   } catch (error) {
      return false;
   }
};

export const rejectedFiatAccount = async (userId, id) => {
   try {
      const path = `/api/fiat-accounts/rejected/${id}`;
      const configRequest = {
         userId,
         method: 'post',
         path
      };
      const response = await HandlerBackend.sendRequest(configRequest);
      return (response.hasOwnProperty('status')) ? true : false;
   } catch (error) {
      return false;
   }
};