import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modal.css";
import ClaimTransactionForm from "./ClaimTransactionForm";
import { CustomButton } from "../CustomButton/CustomButton";
import TicketTransacionForm from "./TicketTransacionForm";
const CustomModal = (props) => {
  const {
    showModal,
    handleToggleModal,
    processTransaction,
    userInfo,
    transactionData,
    extraData,
    action,
    provider,
    edit = false
  } = props;

  const [claimForm, setClaimForm] = useState(edit)
  const [showTicketForm, setShowTicketForm] = useState(false)

  return (
    <Modal
      className="bank-transactions-custom-modal-container"
      isOpen={showModal}
    >
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {claimForm || showTicketForm ? `${edit ? 'Editar' : 'Añadir'} detalles de transacción` : 'Seleccione una opción'}
      </ModalHeader>
      <ModalBody>
        {showTicketForm ?
          <TicketTransacionForm
            handleClose={handleToggleModal}
            extraData={extraData}
            transactionData={transactionData}
            userInfo={userInfo}
            processTransaction={processTransaction}
            action={action}
          /> :
          edit || claimForm ?
            <ClaimTransactionForm
              extraData={extraData}
              transactionData={transactionData}
              handleClose={handleToggleModal}
              processTransaction={processTransaction}
              userInfo={userInfo}
              action={action}
              provider={provider}
            /> :
            <div className="content-all-buttons">
              <CustomButton
                className="green"
                text="Proveedor"
                onClick={() => setClaimForm(true)}
              />
              <CustomButton
                className="orange"
                text="Ticket"
                onClick={() => setShowTicketForm(true)}
              />
            </div>
        }
      </ModalBody>
    </Modal>
  );
};

export default CustomModal;
