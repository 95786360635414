import UtilsHelper from "../../helpers/Utils.helper";

export const postAllTradesReport = async (userId, params) => {
    const path = '/api/alltradesreport';
    const configRequest = {
        userId,
        method: "POST",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getDownloadTradesReport = async (userId) => {
    const path = '/api/download/all-tradesreport';
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}