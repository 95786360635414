import isEmpty from 'is-empty';
import React, { useState } from 'react'

export const Context = React.createContext();

const UserProvider = ({ children }) => {
    const [modulesByUserProfile, setModulesByUserProfile] = useState([]);

    const handleWritePermission = (pathname) => {
        const allModules = modulesByUserProfile.map(({ category }) => (category.modules)).flatMap(item => item);
        const module = allModules.find(module => (module.name.replaceAll(' ', '-')).toLowerCase() === pathname);
        if (!isEmpty(module) && module.permissions.includes('W')) return true;
        return false;
    }

    const value = {
        modulesByUserProfile,
        setModulesByUserProfile,
        handleWritePermission
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default UserProvider