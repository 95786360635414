import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { deleteAsset, deleteAssetAuthorizedById, editAssetById, editSettingByKey, getAllAssetById, getAllAssets, postNewAsset, createAuthorizedAssetById, updateAuthorizedAssetById, getAllSetting } from "../FallServices/GlobalConfigServices";

class GlobalConfigController {
    static getAllAssets = async ({
        userInfo,
        setLoading,
        setListActives,
        setTotalRows,
        filterConfig
    }) => {
        try {
            setLoading(true);
            const { limit, page } = filterConfig;
            const response = await getAllAssets(userInfo.UserId, page, limit);
            if (!isEmpty(response.data)) {
                const logsSortByUpdatedAt = !isEmpty(response.data.data)
                    ? response.data.data
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        .map(item => ({ ...item, restrictedLevels: item.restrictedLevels ? item.restrictedLevels.split(",").sort((a, b) => a - b) : [] }))
                    : [];
                setListActives(logsSortByUpdatedAt);
                setTotalRows(response.data.countData);
            }
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally {
            setLoading(false);
        }
    };

    static postNewAsset = async ({
        userInfo,
        data,
        handleLoadActives
    }) => {
        try {
            const response = await postNewAsset(userInfo.UserId, data);
            if (response.data.success) {
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "El activo fue creado exitosamente"
                });
                handleLoadActives();
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        };
    };

    static activeAsset = async ({
        userInfo,
        assetId,
        data,
        message,
        changeAssetsById
    }) => {
        try {
            const response = await editAssetById(userInfo.UserId, assetId, data);
            if (response.data.success) {
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: message
                });
                changeAssetsById(assetId, Object.keys(data), Object.values(data)[0]);
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static deleteAsset = async ({
        userInfo,
        assetId,
        changeAssetsById
    }) => {
        try {
            const response = await deleteAsset(userInfo.UserId, assetId);
            if (response.data.success) {
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "El activo fue desactivado exitosamente"
                });
                changeAssetsById(assetId, 'status', 0);
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static getAllAssetById = async ({
        userInfo,
        activeId,
        setListAssetAuthorizedById,
        setRightLoading,
        setTotalRows2,
        filterConfig2
    }) => {
        try {
            setRightLoading(true);
            const { limit, page } = filterConfig2;
            const response = await getAllAssetById(userInfo.UserId, activeId, page, limit);
            if (response.data.success) {
                const logsSortByUpdatedAt = !isEmpty(response.data.data) ? response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                setListAssetAuthorizedById(logsSortByUpdatedAt);
                setTotalRows2(response.data.countData);
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally {
            setRightLoading(false);
        }
    };

    static editAssetById = async ({
        userInfo,
        assetId,
        data,
        changeAssetsById
    }) => {
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Enviando..."
            });
            const response = await editAssetById(userInfo.UserId, assetId, data);
            if (response.data.success) {
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "El activo fue editado exitosamente"
                });
                changeAssetsById('value', data.value);
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static createAuthorizedAssetById = async ({
        userInfo,
        activeId,
        data,
        handleLoadActiveById
    }) => {
        try {
            const newData = {
                ...data,
                parentId: activeId
            }
            await createAuthorizedAssetById(userInfo.UserId, newData);
            ComponentsHelper.toast({
                type: toastTypes.success,
                message: "El usuario fue agregado exitosamente"
            });
            handleLoadActiveById()
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static updateAuthorizedAssetById = async ({
        userInfo,
        assetId,
        changeAssetAuthorizedById
    }) => {
        try {
            const data = {
                status: 1
            }
            await updateAuthorizedAssetById(userInfo.UserId, assetId, data);
            ComponentsHelper.toast({
                type: toastTypes.success,
                message: "Activado de manera exitosa para el usuario"
            });
            changeAssetAuthorizedById(assetId, 'status', 1);
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static updateAuthorizedModal = async ({
        userInfo,
        assetId,
        name,
        status,
        changeAssetAuthorizedById
    }) => {
        try {
            const data = {
                [name]: status
            }
            await updateAuthorizedAssetById(userInfo.UserId, assetId, data);
            ComponentsHelper.toast({
                type: toastTypes.success,
                message: `${status ? 'Activado' : 'Desactivado'} de manera exitosa para el usuario`
            });
            changeAssetAuthorizedById(assetId, name, status)
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static deleteAssetAuthorizedById = async ({
        userInfo,
        assetId,
        changeAssetAuthorizedById
    }) => {
        try {
            await deleteAssetAuthorizedById(userInfo.UserId, assetId);
            ComponentsHelper.toast({
                type: toastTypes.success,
                message: "El activo fue desactivado para el usuario"
            });
            changeAssetAuthorizedById(assetId, 'status', 0);
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static getAllInitialSetting = async ({
        userInfo,
        setAllInitialSetting,
        setLoaderInitialSetting
    }) => {
        try {
            const response = await getAllSetting(userInfo.UserId);
            if (!isEmpty(response.data.data)) {
                let newData = {};
                response.data.data.map((data) => {
                    newData[data.settingsKey] = data.settingsValue
                })
                setAllInitialSetting(newData);
                return true;
            }
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally {
            setLoaderInitialSetting(false)
        }
    };

    static editSettingByKey = async ({
        userInfo,
        type,
        data
    }) => {
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Enviando..."
            });
            const response = await editSettingByKey(userInfo.UserId, type, data);
            if (response.data.success) {
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "Editado exitosamente"
                });
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };
}

export default GlobalConfigController;