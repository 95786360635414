import UtilsHelper from "../../helpers/Utils.helper"

export const getAllLegals = async (userId, filterConfig, filterSearch) => {
    try {
        const path = '/api/allcorporatelevel';
        const params = {
            filterConfig,
            filterSearch
        };
        const configRequest = {
            userId,
            method: 'POST',
            path,
            data: params
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data.levels;
    } catch (error) {
        return false;
    }
}

export const getDownloadAllLegals = async (userId) => {
    try {
        const path = '/api/download/all-corporatelevelbeta';
        const configRequest = {
            userId,
            method: 'GET',
            path
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const getLegalUserById = async (userId, legalUserId) => {
    try {
        const path = `/api/corporate/get/${legalUserId}`;
        const configRequest = {
            userId,
            method: 'GET',
            path
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const getAllLogReniecById = async (userId, id) => {
    try {
        const path = `/api/levels/corporaterenieclogs/CORPORATE::${id}`;
        const configRequest = {
            userId,
            method: 'GET',
            path
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const getBanexcoinUserById = async (userId, updateById) => {
    try {
        const path = `/api/banexcoin-user/${updateById}`;
        const configRequest = {
            userId,
            method: 'GET',
            path
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const getLogs = async (userId, legalUserId) => {
    try {
        const path = `/api/levels/getLogs/${legalUserId}`;
        const configRequest = {
            userId,
            method: 'POST',
            path
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data.resultLogs.nodes;
    } catch (error) {
        return false;
    }
};

export const postTrustPe = async (userId, data) => {
    try {
        const path = `/api/services/corfid/processuser`;
        const configRequest = {
            userId,
            method: 'POST',
            path,
            data
        };
        await UtilsHelper.sendRequest(configRequest);
        return true;
    } catch (error) {
        return false;
    }
}

export const getIncrementLegalUserById = async (userId, legalUserId, legalUserAccountId) => {
    try {
        const path = `/api/corporate/increment/${legalUserId}/${legalUserAccountId}/18`;
        const configRequest = {
            userId,
            method: 'GET',
            path
        };
        await UtilsHelper.sendRequest(configRequest);
        return true;
    } catch (error) {
        return false;
    }
}

//----- Por definir en una V2
export const getRejectLegalUserById = async (userId, legalUserId, legalUserAccountId) => {
    try {
        const path = `/api/levels/rejected/${legalUserId}/${legalUserAccountId}/18`;
        const configRequest = {
            userId,
            method: 'GET',
            path
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const postReturnCorporateInfoFields = async (userId, data, legalUserId, message) => {
    try {
        const path = '/api/corporate/returncorporateInfofields';
        const modal = {
            id: legalUserId,
            returnData: data,
            textObservacion: message
        }
        const configRequest = {
            userId,
            method: 'POST',
            path,
            data: modal
        };
        await UtilsHelper.sendRequest(configRequest);
        return true;
    } catch (error) {
        return false;
    }
}

export const postReturnCorporateShareholderInfoFields = async (userId, data, legalShareholderUserId, message) => {
    try {
        const path = '/api/corporate/returncorporateShareholderInfofields';
        const modal = {
            id: legalShareholderUserId,
            returnData: data,
            textObservacion: message
        }
        const configRequest = {
            userId,
            method: 'POST',
            path,
            data: modal
        };
        await UtilsHelper.sendRequest(configRequest);
        return true;
    } catch (error) {
        return false;
    }
}

export const getCountries = async (userId) => {
    try {
        const path = '/api/countries?restricted=true';
        const configRequest = {
            userId,
            method: 'GET',
            path
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data.countries;
    } catch (error) {
        return false;
    }
}

export const postCorporateInfo = async (userId, data) => {
    try {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const path = '/api/corporate/editcorporateInfo';
        const configRequest = {
            userId,
            method: 'POST',
            headers,
            path,
            data
        };
        await UtilsHelper.sendRequest(configRequest);
        return true;
    } catch (error) {
        return false;
    }
}

export const postCorporateShareholderInfo = async (userId, data) => {
    try {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const path = '/api/corporate/editcorporateshareholderInfo';
        const configRequest = {
            userId,
            method: 'POST',
            headers,
            path,
            data
        };
        await UtilsHelper.sendRequest(configRequest);
        return true;
    } catch (error) {
        return false;
    }
}

export const postSendToIDM = async (userId, id) => {
    try {
        const path = `/api/corporate/sendShareholderToIdm/${id}`;
        const configRequest = {
            userId,
            method: 'POST',
            path
        };
        await UtilsHelper.sendRequest(configRequest);
        return true;
    } catch (error) {
        return false;
    }
}

export const postReniecRequest = async (userId, data) => {
    const path = `/api/services/identity/faceverify`;
    const configRequest = {
        userId,
        method: 'POST',
        path,
        data
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return response.data;
}

export const postReniecLogRequest = async (userId, data) => {
    try {
        const path = `/api/levels/renieclogs/`;
        const configRequest = {
            userId,
            method: 'POST',
            path,
            data
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const postGeneralActionLog = async (userId, data) => {
    try {
        const path = `/api/action-logs`;
        const configRequest = {
            userId,
            method: 'POST',
            path,
            data
        };
        const response = await UtilsHelper.sendRequest(configRequest);
        return response.data;
    } catch (error) {
        return false;
    }
}

export const updatedUserBlockStatus = async (userId, data,type) => {
    const path = `/api/levels/${type}-corporate-user`;
    return await UtilsHelper.sendRequest({
      userId,
      method: 'POST',
      path,
      data
    });
  };
export const deleteUserKYC = async (userId, id, data) => {
    const path = `/api/levels/internal/message/delete-kyc-master/${id}`;
    return await UtilsHelper.sendRequest({
      userId,
      method: 'DELETE',
      path,
      data
    });
  };