import isEmpty from "is-empty";
import { toastTypes } from "../../../constants";
import ComponentsHelper from "../../../helpers/Components.helper";
import Papa from 'papaparse'

export const handlerFilterByFile = (ev,callback,filters) => {
  const handleFile = (results) => {
    const dataFile = results.data,
      userIds = [],
      usernames = [],
      excludeAccountIds = [];
    if (isEmpty(dataFile)) return;
    for (const line of dataFile) {
      if (line.type === "user_id") {
        userIds.push(line.value.trim());
      }
      if (line.type === "username") {
        usernames.push(line.value.trim());
      }
      if (line.type === "exclude_account_id") {
        excludeAccountIds.push(line.value.trim());
      }
    }

    callback({
      ...filters,
      userIds: !isEmpty(userIds) ? userIds.join(",") : filters.userIds,
      usernames: !isEmpty(usernames)
        ? usernames.join(",")
        : filters.usernames,
      excludeAccountIds: !isEmpty(excludeAccountIds)
        ? excludeAccountIds.join(",")
        : filters.excludeAccountIds,
    });
  };
  try {
    Papa.parse(ev.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: handleFile,
    });
    ev.target.value = null;
    return true
  } catch (error) {
    ComponentsHelper.toast({type:toastTypes.error,message:"Error al cargar archivo"})
    return false
  }
};
