import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { required } from 'apex-web/lib/helpers/formValidations';
import APPasswordInput from 'apex-web/lib/components/common/APPasswordInput/APPasswordInput';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';

import { defaultPath } from 'apex-web/lib/routeTemplates';
import path from '../../helpers/path';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import TradingServerComponent from 'apex-web/lib/components/TradingServer/TradingServerComponent';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';

import '../../styles/components/common/StandaloneForm.css';
import './LoginFormComponent.css';

var baseClasses = getBEMClasses('standalone-form');
var loginFormClasses = getBEMClasses('login-form');

export var LoginFormComponent = function LoginFormComponent(props, context) {
  var handleSubmit = props.handleSubmit,
    errorMsg = props.errorMsg,
    submitting = props.submitting,
    doSubmit = props.doSubmit,
    isConnected = props.isConnected,
    useEmailAsUsername = props.useEmailAsUsername,
    active = props.active,
    siteName = props.siteName,
    gateway = props.gateway;

  return React.createElement(
    'div',
    {
      className:
        baseClasses('container') +
        ' ' +
        loginFormClasses('container') +
        ' ' +
        'v2container'
    },
    // React.createElement(
    //   'img',
    //   {className: 'logo'}
    // ),
    React.createElement(
      'div',
      { className: baseClasses('header') + ' ' + loginFormClasses('header') },
      React.createElement(
        'div',
        {
          className:
            baseClasses('header-text') + ' ' + loginFormClasses('header-text')
        },
        context.t('Log In to {n}', {
          n: siteName
        })
      ),
      React.createElement(
        Link,
        { to: path('/'), className: baseClasses('close-icon') },
        React.createElement(APIcon, { name: 'close', classModifiers: 'big' })
      )
    ),
    React.createElement(
      'form',
      {
        onSubmit: handleSubmit(function(values) {
          return doSubmit(values, isConnected);
        }),
        className: baseClasses('form') + ' ' + loginFormClasses('form')
      },
      errorMsg &&
        React.createElement('p', { className: baseClasses('error') }, errorMsg),
      React.createElement(APInput, {
        type: 'text',
        name: 'username',
        customClass: loginFormClasses(),
        label: useEmailAsUsername ? context.t('Email') : context.t('Username'),
        validate: [required]
      }),
      React.createElement(APPasswordInput, {
        type: 'password',
        name: 'password',
        customClass: loginFormClasses(),
        label: context.t('Password'),
        validate: [required]
      }),
      Array.isArray(gateway) &&
        React.createElement(TradingServerComponent, null),
      React.createElement('hr', { className: loginFormClasses('separator') }),
      React.createElement(
        APButton,
        {
          type: 'submit',
          disabled: submitting,
          customClass: loginFormClasses(),
          styleName: styleNames.additive
        },
        submitting ? context.t('Processing...') : context.t('Log In')
      )
    ),
    React.createElement(
      'div',
      { className: baseClasses('footer') + ' ' + loginFormClasses('footer') },
      active &&
        React.createElement(
          React.Fragment,
          null,
          React.createElement(
            Link,
            {
              to: path('/signup'),
              className: loginFormClasses('link'),
              disabled: true
            },
            context.t('Sign Up')
          ),
          React.createElement('div', {
            className: baseClasses('footer-separator')
          })
        ),
      React.createElement(
        Link,
        {
          to: path('/problem-logging-in'),
          className: loginFormClasses('link'),
          disabled: true
        },
        context.t('Problem Logging In?')
      )
    )
  );
};

LoginFormComponent.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

LoginFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(LoginFormComponent, defaultPath.path);
