import React, { useState } from 'react';
import isEmpty from 'is-empty';
import { CustomButton } from '../../CustomButton/CustomButton';
import InputElement from '../../InputElement/InputElement';
import './txHashDetails.css';

const TxHashDetails = ({ data,handleSetTxHash,loading,context,editPermission }) => {
    const [txId,setTxId] = useState("")
    const [error,setError] = useState(null)

  const slots = [
    { title: 'Número de ticket', value: data.ticketNumber },
    { title: 'Monto', value: data.ticketAmount},
    { title: 'Nombre de producto', value: data.ticketAssetName},
    { title: 'Nombre de cuenta', value:data.ticketAccountName },
     { title: 'Tipo', value:context.t(data.ticketType) },
  ]

  const OptionDetail = ({ title, value,classname="" }) => {
    return (
      <div className={`option-ticket-tx-hash ${classname}`}>
        <p>
          <b>{title}:</b>
        </p>
        <p>{value || '-'}</p>
      </div>
    )
  };

  const txIdInputProps = {
    values: {
      title: 'txId',
      name: 'txId',
      type: 'text',
      required:true,
      value:txId,
    },
    actions: {
      onChange: ({value}) => {
        setError(null)
        setTxId(value)
      }
    },
    error
  };

  const handleUpdateHash = () => {
    if(isEmpty(txId)){
      setError("Ingrese un TxId válido")
      return
    }
    handleSetTxHash(txId,() => setTxId(""))
  }

  return (
    <div className='ticket-detail-main'>
      <div className='ticket-body-container'>
          <h2>Detalles de ticket</h2>
        <div className="slot-details body-section">
        {slots.map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
        </div>
        <OptionDetail title="ID de transacción" value={data.ticketTxid} classname="tx-id-w-100" />
        {editPermission &&
          <React.Fragment>
            <h3>Ingrese nuevo ID de transacción</h3>
            <br/>
            <div>
                <InputElement {...txIdInputProps}/>
            </div>
          </React.Fragment>
        }
      </div>
      {editPermission &&
        <div className='buttons-container'>
          <div className='buttons-left'/>
            <CustomButton
              text="Actualizar Tx Hash"
              onClick={handleUpdateHash}
              className="green"
              disabled={loading}
              customClassName="button-tx-hash"
            />
        </div>
      }
    </div>
  )
}

export default TxHashDetails