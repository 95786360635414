import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TableContainer from "../Table/TableContainer";
import DateHelper from "../../../helpers/Date.helper";
import SubHeaderPage from "../HeaderPage/SubHeaderPage";
import statusComponent from "../StatusComponent/StatusComponent";
import { fixedStatus } from "../../../constants";

const AllFxRates = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    rows,
    offset,
    showingTo,
    changePage,
    selectedId,
    loading
  } = props;

  const tableHeaders = [
    {
      tag: "",
      title: "Par",
      cell: ({ data }) => <p>{data.baseCurrency}/{data.currency}</p>,
    },
    {
      tag: "rate",
      title: "Precio",
    },
    {
      tag: "usernameBanexcoin",
      title: "Usuario",
    },
    {
      tag: "",
      title: "Fecha de actualización",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.updatedAt)}</p>,
    },
    {
      tag: "rate",
      title: "Estado",
      cell: ({ data }) =>
        statusComponent(data.isFixed ? "lock" : "unlock", false, fixedStatus),
    },
  ];

  return (
    <div className="space-data-table">
      <SubHeaderPage title="Últimos tipos de cambio" />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        rows={rows}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}
        enablePagination={false}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllFxRates.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllFxRates);
