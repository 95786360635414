export const ocupations = [
    {
      name: 'Housewife',
      value: 'Housewife'
    },
    {
      name: 'Unemployed',
      value: 'Unemployed'
    },
    {
      name: 'Employee',
      value: 'Employee'
    },
    {
      name: 'Employer',
      value: 'Employer'
    },
    {
      name: 'Student',
      value: 'Student'
    },
    {
      name: 'Retired',
      value: 'Retired'
    },
    {
      name: 'Member of the Armed Forces',
      value: 'Member of the Armed Forces'
    },
    {
      name: 'Clergy member',
      value: 'Clergy member'
    },
    {
      name: 'Laborer',
      value: 'Laborer'
    },
    {
      name: 'Domestic Worker',
      value: 'Domestic Worker'
    },
    {
      name: 'Independent Worker',
      value: 'Independent Worker'
    },
    {
      name: 'Does not declare',
      value: 'Does not declare'
    }
  ];
  