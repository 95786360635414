import React from 'react';
import PropTypes from 'prop-types';

import TrustpayDepositWorkflowComponent from '../PaymentProcessors/TrustpayDepositWorkflowComponent';
import RazorPayDepositWorkflowComponent from '../PaymentProcessors/RazorPayDepositWorkflowComponent';
import PsigateDepositWorkflowComponent from '../PaymentProcessors/PsigateDepositWorkflowComponent';
import InterswitchDepositWorkflowComponent from '../PaymentProcessors/InterswitchDepositWorkflowComponent';
import FennasDepositWorkflowComponent from '../PaymentProcessors/FennasDepositWorkflowComponent';
import HyphenDepositWorkflowContainer from '../PaymentProcessors/Hyphen/HyphenDepositWorkflowContainer';

var GenericPaymentWorkflowComponent = function GenericPaymentWorkflowComponent(
  _ref,
  context
) {
  var depositInfo = _ref.depositInfo,
    templateType = _ref.templateType;

  var _ref2 = depositInfo[0] ? JSON.parse(depositInfo[0]) : {},
    PaymentAPI = _ref2.PaymentAPI,
    URL = _ref2.URL,
    RedirectURL = _ref2.RedirectURL,
    SerializedPaymentTransactionObject =
      _ref2.SerializedPaymentTransactionObject;

  if (templateType === 'Hyphen') {
    return React.createElement(HyphenDepositWorkflowContainer, null);
  }

  if (RedirectURL) {
    window.location = RedirectURL;
    return React.createElement('p', null, 'Redirecting...');
  }

  if (PaymentAPI === 'TrustPay') {
    return React.createElement(TrustpayDepositWorkflowComponent, { url: URL });
  }

  if (SerializedPaymentTransactionObject) {
    var options = JSON.parse(SerializedPaymentTransactionObject);

    if (PaymentAPI === 'Razorpay') {
      return React.createElement(RazorPayDepositWorkflowComponent, {
        options: options
      });
    }
    if (PaymentAPI === 'Psigate') {
      return React.createElement(PsigateDepositWorkflowComponent, {
        options: Object.assign({}, options, { Email: '' })
      });
    }
    if (PaymentAPI === 'Interswitch') {
      return React.createElement(InterswitchDepositWorkflowComponent, {
        options: options
      });
    }
    if (PaymentAPI === 'Fennas') {
      return React.createElement(FennasDepositWorkflowComponent, {
        options: options
      });
    }
  }

  if (PaymentAPI === null) {
    return React.createElement(
      'p',
      null,
      context.t('Server provided an unexpected response.')
    );
  }

  return null;
};

GenericPaymentWorkflowComponent.defaultProps = {
  depositInfo: []
};

GenericPaymentWorkflowComponent.propTypes = {
  depositInfo: PropTypes.array.isRequired
};

GenericPaymentWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default GenericPaymentWorkflowComponent;
