import HandlerBackend from '../../helpers/HandlerBackend';

export const syncAllUsers = async (userId, id) => {
  let path = '/api/sync/all-users';
  let syncList = {};
  let newUsers = {};
  let data = {
    id
  };
  return HandlerBackend.sendRequest({
    method: 'post',
    userId,
    path,
    data
  });
};

export const deleteListById = async (userId, id) => {
  let path = '/api/sync/delete-lists';
  let syncList = {};
  let newUsers = {};
  let data = {
    id
  };
  return HandlerBackend.sendRequest({
    method: 'post',
    userId,
    path,
    data
  });
};

export const createListByName = async (userId, name) => {
  let path = '/api/sync/create-lists';
  let syncList = {};
  let newUsers = {};
  let data = {
    name
  };
  return HandlerBackend.sendRequest({
    method: 'post',
    userId,
    path,
    data
  });
};

export const getContactList = async userId => {
  let path = '/api/get/all-contacts';
  let newUsers = {};
  let data = {};
  return HandlerBackend.sendRequest({
    method: 'get',
    userId,
    path,
    data
  });
};
