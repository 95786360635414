import React from 'react';
import { actionTypes } from '../../../../../constants';
import DateHelper from '../../../../../helpers/Date.helper';
import Table from '../../../Table/Table';

const History = ({ logUser }) => {

    const handleSelectActionType = (type) => actionTypes.find(action => type === action.id).name;

    const tableHeaders = [
        {
            tag: 'position',
            title: '#'
        },
        {
            tag: '',
            title: 'Acción',
            cell: ({ data }) => handleSelectActionType(data.type)
        },
        {
            tag: 'description',
            title: 'Descripción'
        },
        {
            tag: 'executeByName',
            title: 'Ejecutado por'
        },
        {
            tag: '',
            title: 'Fecha',
            cell: ({ data }) => DateHelper.formatDate(data.createdAt)
        }
    ]
    return (
        <div style={{ position: 'relative' }}>
            <div className="table-wrapper">
                <Table rowHead={tableHeaders} dataBody={logUser} />
            </div>
        </div>
    )
}

export default History;