import React, { useEffect, useState } from "react";
import DragAndDropContainer from "../DragAndDrop/DragAndDropContainer";
import AddIcon from "../../../images/add-icon.svg";
import InputElement from "../../../components/FallComponents/InputElement/InputElement";
import { useForm } from "../../../hooks/formHooks";
import { CustomButton } from "../CustomButton/CustomButton";
import { v4 as uuid } from "uuid";
import BanexcoinAccountsController from "../../../pages/FallControllers/BanexcoinAccountsController";
import isEmpty from "is-empty";
import DeleteIcon from "../../../images/delete-icon.svg";
import {
  accountUtilityList,
  fiatCurrencyList,
  optionsRulesSelect,
  statusList,
} from "../../../constants";
import addImageLogo from "../../../images/new_icons/folder-add.svg";
import { useUploadFile } from "../../../hooks/useUploadFile";
import { Loader } from "../Loader/Loader";
import UtilsHelper from "../../../helpers/Utils.helper";

const EditForm = ({
  buttonProps,
  userInfo,
  getAllBanexcoinAccounts,
  data,
  accessToAction,
  countries,
}) => {
  const fields = {
    currencyCode: "",
    countryCode: "",
    accountUtility: "",
    swiftBankId: "",
    status: "",
    accountNumber: "",
    description: "",
    accountMessageEs: "",
    accountMessageEn: ""
  };
  const [listComponents, setListComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rulesListComponents, setRulesListComponents] = useState([]);
  const [rulesSelectDynamicInputs, setRulesSelectDynamicInputs] = useState({});
  const [errorsValidation, setErrorsValidation] = useState({});
  const [bankListOptions, setBankListOptions] = useState([]);
  const { inputs, errors, setErrors, setInputs } = useForm(
    {
      ...data,
      accountMessageEs: data.accountMessage ? data.accountMessage.es : "",
      accountMessageEn: data.accountMessage ? data.accountMessage.en : "",
      reference: UtilsHelper.sanitizeValue(data.reference)
    },
    null,
    customValidations
  );

  const [loading2, setLoading2] = useState(true);
  const [searchMessage, setSearchMessage] = useState('');
  const {
    uploadFile,
    previewFile,
    fileData,
    deleteImageData,
    handleErrors: handleFileErrors,
  } = useUploadFile({
    fileType: "image",
    maxFileDimension: {
      maxWidth: 120,
      maxHeight: 30,
    },
  });

  const Component = (data, i) => {
    const validationErrors =
      !isEmpty(errorsValidation.dynamicInputsErrors) &&
      !isEmpty(errorsValidation.dynamicInputsErrors["accountDetails"]) &&
      !isEmpty(errorsValidation.dynamicInputsErrors["accountDetails"][i]);
    const titlePropsEs = {
      values: {
        title: "Título (es)",
        name: data.es.title.name,
        value: inputs[`title$${data.id}$es`],
        required: true,
      },
      actions: {
        onChange: (ev) => onDefaultChange(ev, i),
      },
    };
    const valuePropsEs = {
      values: {
        title: "Valor (es)",
        name: data.es.value.name,
        value: inputs[`value$${data.id}$es`],
        required: true,
      },
      actions: {
        onChange: (ev) => onDefaultChange(ev, i),
      },
    };
    const titlePropsEn = {
      values: {
        title: "Título (en)",
        name: data.en.title.name,
        value: inputs[`title$${data.id}$en`],
        required: true,
      },
      actions: {
        onChange: (ev) => onDefaultChange(ev, i),
      },
    };
    const valuePropsEn = {
      values: {
        title: "Valor (en)",
        name: data.en.value.name,
        value: inputs[`value$${data.id}$en`],
        required: true,
      },
      actions: {
        onChange: (ev) => onDefaultChange(ev, i),
      },
    };
    return (
      <div className="input-drag-add-form-container">
        <div>
          <div className="container-column">
            <InputElement {...titlePropsEs} />
            <InputElement {...valuePropsEs} />
          </div>
          <div className="container-column">
            <InputElement {...titlePropsEn} />
            <InputElement {...valuePropsEn} />
          </div>
        </div>
        <span>
          {validationErrors &&
            "Títulos y valores deben tener más de 2 caracteres"}
        </span>
      </div>
    );
  };

  const handleRulesDynamicInputs = ({ name, value }, data) => {
    setRulesSelectDynamicInputs({
      ...rulesSelectDynamicInputs,
      [`value_type/${data.id}/es`]: value,
      [`value_type/${data.id}/en`]: value,
    });
  };

  const RuleComponent = (data, i) => {
    const validationErrors =
      !isEmpty(errorsValidation.dynamicInputsErrors) &&
      !isEmpty(
        errorsValidation.dynamicInputsErrors["accountRulesInstructions"]
      ) &&
      !isEmpty(
        errorsValidation.dynamicInputsErrors["accountRulesInstructions"][i]
      );

    const typePropsEs = {
      values: {
        title: "Tipo de dato (es)",
        name: data.es.value_type.name,
        value: rulesSelectDynamicInputs[`value_type/${data.id}/es`],
        type: "select",
        options: optionsRulesSelect,
        required: true,
        disabled: !accessToAction,
      },
      actions: {
        onChange: ({ name, value }) =>
          handleRulesDynamicInputs({ name, value }, data),
      },
    };
    const typePropsEn = {
      values: {
        title: "Tipo de dato (en)",
        name: data.en.value_type.name,
        value: rulesSelectDynamicInputs[`value_type/${data.id}/es`],
        type: "select",
        options: optionsRulesSelect,
        required: true,
        disabled: !accessToAction,
      },
      actions: {
        onChange: ({ name, value }) =>
          handleRulesDynamicInputs({ name, value }, data),
      },
    };
    const valuePropsEs = {
      values: {
        title: "Valor (es)",
        name: data.es.value.name,
        value: inputs[`value/${data.id}/es`],
        required: true,
      },
      actions: {
        onChange: (ev) => onDefaultChange(ev, i),
      },
    };
    const valuePropsEn = {
      values: {
        title: "Valor (en)",
        name: data.en.value.name,
        value: inputs[`value/${data.id}/en`],
        required: true,
      },
      actions: {
        onChange: (ev) => onDefaultChange(ev, i),
      },
    };

    return (
      <div className="input-drag-add-form-container">
        <div>
          <div className="container-column">
            <InputElement {...typePropsEs} />
            <InputElement {...valuePropsEs} />
          </div>
          <div className="container-column">
            <InputElement {...typePropsEn} />
            <InputElement {...valuePropsEn} />
          </div>
        </div>
        <span>
          {validationErrors &&
            "Tipos y valores deben tener más de 2 caracteres"}
        </span>
      </div>
    );
  };

  const formattedDataEsEn = (lang, inputs, key, separator, array) => {
    const inputKeys = Object.keys(inputs);

    let res = inputKeys.filter((item) => item.split(separator)[2] === lang);

    const orderInputs = array.map((item) => item.id);
    res = orderInputs.map((item) => {
      const el = inputKeys.find(
        (key) => key.includes(item) && key.includes(`${separator}${lang}`)
      );
      const parts = el.split(separator);
      return JSON.stringify({
        [key]: inputs[`${key}${separator}${parts[1]}${separator}${parts[2]}`],
        value: inputs[`value${separator}${parts[1]}${separator}${parts[2]}`],
      });
    });

    return res;
  };

  async function handleSubmitInputs(ev) {
    ev.preventDefault();
    setErrorsValidation({ ...errorsValidation, dynamicInputsErrors: null });

    let errors = customValidations({
      ...inputs,
      status: String(inputs.status),
    });

    if (!isEmpty(fileData)) {
      const fileErrors = handleFileErrors();
      if (!isEmpty(fileErrors)) {
        errors = { ...errors, accountLogo: fileErrors };
      }
    }
    setErrors(errors);
    if (!isEmpty(errors)) return;

    let dynamicsInputs = {};
    let rulesDynamicInputs = {};

    const form = new FormData(ev.target);
    form.forEach((val, key) => (dynamicsInputs[key] = val));

    Object.keys(dynamicsInputs).forEach((input) => {
      if (!input.includes("$")) {
        if (input.includes("/")) {
          rulesDynamicInputs[input] = dynamicsInputs[input];
        }
        delete dynamicsInputs[input];
      }
    });

    let spanishAccountDetails = !isEmpty(dynamicsInputs)
      ? formattedDataEsEn("es", dynamicsInputs, "title", "$", listComponents)
      : [];

    let englishAccountDetails = !isEmpty(dynamicsInputs)
      ? formattedDataEsEn("en", dynamicsInputs, "title", "$", listComponents)
      : [];

    spanishAccountDetails = spanishAccountDetails.map((item) =>
      JSON.parse(item)
    );
    englishAccountDetails = englishAccountDetails.map((item) =>
      JSON.parse(item)
    );

    rulesDynamicInputs = { ...rulesSelectDynamicInputs, ...rulesDynamicInputs };

    let spanishRulesDetails = formattedDataEsEn(
      "es",
      rulesDynamicInputs,
      "value_type",
      "/",
      rulesListComponents
    );
    let englishRulesDetails = formattedDataEsEn(
      "en",
      rulesDynamicInputs,
      "value_type",
      "/",
      rulesListComponents
    );

    spanishRulesDetails = spanishRulesDetails.map((item) => JSON.parse(item));
    englishRulesDetails = englishRulesDetails.map((item) => JSON.parse(item));

    let data = {
      ...inputs,
      accountDetails: JSON.stringify({
        es: spanishAccountDetails,
        en: englishAccountDetails,
      }),
      accountRulesInstructions: JSON.stringify({
        es: spanishRulesDetails,
        en: englishRulesDetails,
      }),
      accountMessage: JSON.stringify({
        es: inputs.accountMessageEs,
        en: inputs.accountMessageEn,
      }),
    };

    delete data["accountMessageEn"];
    delete data["accountMessageEs"];

    data = { ...data, status: Number(data.status) };

    Object.keys(data).forEach((item) => {
      if (!item.includes("$") && !item.includes("/")) return;
      delete data[item];
    });
    delete data["accountLogo"];

    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });

    if (!isEmpty(fileData)) {
      formData.append("accountLogo", fileData);
    }

    setLoading(true);
    const response = await BanexcoinAccountsController.updateBanexcoinAccount({
      userId: userInfo.UserId,
      data: formData,
      setErrorsValidation,
    });
    if (response) {
      deleteImageData();
      getAllBanexcoinAccounts();
    }
    setLoading(false);
  }

  const handleAddInput = ({
    titleEs,
    titleEn,
    valueEs,
    valueEn,
    actionToRender = true,
  }) => {
    const id = uuid();
    const newSection = {
      id,
      es: {
        title: {
          name: `title$${id}$es`,
          value: titleEs || "",
        },
        value: {
          name: `value$${id}$es`,
          value: valueEs || "",
        },
      },
      en: {
        title: {
          name: `title$${id}$en`,
          value: titleEn || "",
        },
        value: {
          name: `value$${id}$en`,
          value: valueEn || "",
        },
      },
      values: {
        [`title$${id}$es`]: titleEs || "",
        [`value$${id}$es`]: valueEs || "",
        [`title$${id}$en`]: titleEn || "",
        [`value$${id}$en`]: valueEn || "",
      },
    };
    if (actionToRender) {
      setListComponents([...listComponents, newSection]);
    }
    return newSection;
  };
  const handleRulesAddInput = ({
    value_typeEs,
    value_typeEn,
    valueEs,
    valueEn,
    actionToRender = true,
  }) => {
    const id = uuid();
    const newSection = {
      id,
      es: {
        value_type: {
          name: `value_type/${id}/es`,
          value: value_typeEs || "",
        },
        value: {
          name: `value/${id}/es`,
          value: valueEs || "",
        },
      },
      en: {
        value_type: {
          name: `value_type/${id}/en`,
          value: value_typeEn || "",
        },
        value: {
          name: `value/${id}/en`,
          value: valueEn || "",
        },
      },
      values: {
        [`value_type/${id}/es`]: value_typeEs || "",
        [`value/${id}/es`]: valueEs || "",
        [`value_type/${id}/en`]: value_typeEn || "",
        [`value/${id}/en`]: valueEn || "",
      },
    };
    if (actionToRender) {
      setRulesListComponents([...rulesListComponents, newSection]);
    }
    return newSection;
  };

  function customValidations(inputs) {
    let errors = {};
    Object.keys(fields).forEach((element) => {
      if (isEmpty(inputs[element])) {
        errors[element] = "Required field";
      }
    });
    setErrors(errors);
    return errors;
  }

  const onDefaultChange = ({ name, value }, i) => {
    if (!accessToAction) return;
    setInputs({
      ...inputs,
      [name]: value,
    });
    delete errorsValidation[name];
    delete errors[name];
    if (
      !isEmpty(errorsValidation.dynamicInputsErrors) &&
      !isEmpty(errorsValidation.dynamicInputsErrors["accountDetails"])
    ) {
      delete errorsValidation.dynamicInputsErrors["accountDetails"][`${i}`];
    }
    if (
      !isEmpty(errorsValidation.dynamicInputsErrors) &&
      !isEmpty(errorsValidation.dynamicInputsErrors["accountRulesInstructions"])
    ) {
      delete errorsValidation.dynamicInputsErrors["accountRulesInstructions"][
        `${i}`
      ];
    }
  };

  const getListBank = async (countryCode) => {
    setLoading2(true);
    const response = await BanexcoinAccountsController.getAllBankList({
      userInfo,
      countryCode,
    });
    if (response.success) {
      const banks = response.data.map((bank) => {
        return { value: bank.id, name: bank.bankName };
      });
      setBankListOptions(banks);
    } else {
      setBankListOptions([]);
    }
    setLoading2(false);
  };

  const handleDeleteItem = (item) => {
    setListComponents(
      [...listComponents].filter((component, i) => {
        if (
          component.es.title.name.includes(item.id) &&
          errorsValidation &&
          errorsValidation.dynamicInputsErrors &&
          errorsValidation.dynamicInputsErrors["accountDetails"] &&
          errorsValidation.dynamicInputsErrors["accountDetails"][i]
        ) {
          errorsValidation.dynamicInputsErrors["accountDetails"][i] = null;
        }
        return !component.es.title.name.includes(item.id);
      })
    );
  };
  const handleRulesDeleteItem = (item) => {
    setRulesListComponents(
      rulesListComponents.filter(
        (component) => !component.es.value_type.name.includes(item.id)
      )
    );
    Object.keys(rulesSelectDynamicInputs).forEach((el) => {
      if (el.includes(item.id)) {
        delete rulesSelectDynamicInputs[el];
      }
    });
  };

  const splitDynamicInputs = (data) => {
    const accountDetails = data.accountDetails;
    const accountRulesInstructions = data.accountRulesInstructions;
    const dynamicAccountDetails = accountDetails
      ? accountDetails.en.map((item, i) => {
          return handleAddInput({
            titleEs: accountDetails.es[i].title,
            titleEn: item.title,
            valueEs: accountDetails.es[i].value,
            valueEn: item.value,
            actionToRender: false,
          });
        })
      : [];
    let dynamicInputsValues = {};
    dynamicAccountDetails.forEach((el) => {
      dynamicInputsValues = { ...dynamicInputsValues, ...el.values };
    });
    const dynamicRules = accountRulesInstructions
      ? accountRulesInstructions.en.map((item, i) => {
          return handleRulesAddInput({
            value_typeEs: accountRulesInstructions.es[i].value_type,
            value_typeEn: item.value_type,
            valueEs: accountRulesInstructions.es[i].value,
            valueEn: item.value,
            actionToRender: false,
          });
        })
      : [];
    let dynamicRulesInputsValues = {};
    dynamicRules.forEach((el) => {
      dynamicRulesInputsValues = { ...dynamicRulesInputsValues, ...el.values };
    });
    setRulesSelectDynamicInputs(dynamicRulesInputsValues);
    const newEditDynamicInputs = {
      ...dynamicRulesInputsValues,
      ...dynamicInputsValues,
    };
    setListComponents(dynamicAccountDetails);

    setRulesListComponents(dynamicRules);

    return newEditDynamicInputs;
  };

  const bankNameBySwiftId = (value) => {
    const bank = bankListOptions.find((item) => item.value === value);
    if (!bank) return "";
    return bank.value;
  };

  const existPreviewData = previewFile && previewFile.data;

  const handleDeleteImageData = () => {
    deleteImageData();
    document.getElementById("input-file-bnx-form").value = "";
    delete errors["accountLogo"];
  };

  const filterBank = (array, inputValue) => {
    return array.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleCallBack = (inputValue,callback) => {
    const array = bankListOptions.map(option => ({
      value: option.value,
      label: option.name
    }))
    if(inputValue.length < 3){
        callback(filterBank([], inputValue));
    } else {
      setTimeout(() => {
        callback(filterBank(array, inputValue));
      }, 1000);
    };
  }

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      getListBank(data.countryCode);
      const newEditDynamicInputs = splitDynamicInputs(data);
      setInputs({
        ...data,
        ...newEditDynamicInputs,
        accountMessageEs: data.accountMessage ? data.accountMessage.es : "",
        accountMessageEn: data.accountMessage ? data.accountMessage.en : "",
        reference: UtilsHelper.sanitizeValue(data.reference)
      });
      deleteImageData();
    }
  }, [userInfo, data]);

  const currencyCodeInputProps = {
    values: {
      title: "Tipo de moneda",
      value: inputs.currencyCode || "",
      name: "currencyCode",
      type: "select",
      required: true,
      options: fiatCurrencyList,
      disabled: !accessToAction,
    },
    actions: {
      onChange: ({ name, value }) => {
        if (!accessToAction) return;
        onDefaultChange({ name, value });
      },
    },
    error: errors.currencyCode || errorsValidation["currencyCode"],
  };
  const countryCodeInputProps = {
    values: {
      title: "País",
      value: inputs.countryCode || "",
      name: "countryCode",
      type: "select",
      required: true,
      options: countries,
      disabled: !accessToAction,
    },
    actions: {
      onChange: ({ name, value }) => {
        if (!accessToAction) return;
        onDefaultChange({ name, value });
        getListBank(value);
      },
    },
    error: errors.countryCode || errorsValidation["countryCode"],
  };
  const accountUtilityInputProps = {
    values: {
      title: "Utilidad de cuenta",
      value: inputs.accountUtility || "",
      name: "accountUtility",
      type: "select",
      required: true,
      options: accountUtilityList,
      disabled: !accessToAction,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.accountUtility || errorsValidation["accountUtility"],
  };
  const swiftBankIdInputProps = {
    values: {
      title: "Nombre de banco",
      value: bankNameBySwiftId(inputs.swiftBankId),
      name: "swiftBankId",
      type: "asyncSelect",
      required: true,
      disabled: isEmpty(inputs.currencyCode) || isEmpty(inputs.countryCode),
      options: bankListOptions,
      handleCallBack: handleCallBack,
      disabled: !accessToAction,
      noOptionsMessage: () => searchMessage.length < 3 ? 'Ingresa al menos tres letras para comenzar la búsqueda' : 'No hay resultados',
      inputValue: searchMessage,
      onInputChange: (e) => setSearchMessage(e)
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.swiftBankId || errorsValidation["swiftBankId"],
  };
  const statusInputProps = {
    values: {
      title: "Estado",
      value: String(inputs.status),
      name: "status",
      type: "select",
      required: true,
      options: statusList,
      disabled: !accessToAction,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.status || errorsValidation["status"],
  };
  const accountNumberInputProps = {
    values: {
      title: "Número de cuenta bancaria",
      value: inputs.accountNumber || "",
      name: "accountNumber",
      type: "text",
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.accountNumber || errorsValidation["accountNumber"],
  };
  const accountLogoInputProps = {
    values: {
      title: "Logo del banco",
      value: isEmpty(previewFile.data) ? inputs.accountLogo : previewFile.name,
      name: "accountLogo",
      type: "text",
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.accountLogo || errorsValidation["accountLogo"],
  };
  const descriptionInputProps = {
    values: {
      title: "Descripción",
      value: inputs.description || "",
      name: "description",
      type: "text",
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.description || errorsValidation["description"],
  };
  const accountMessageEsInputProps = {
    values: {
      title: "Mensaje en pantalla (es)",
      value: inputs.accountMessageEs || "",
      name: "accountMessageEs",
      type: "text",
      helperText: ["Ejemplo: Solo tranferencias interbancarias"],
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.accountMessageEs,
  };
  const accountMessageEnInputProps = {
    values: {
      title: "Mensaje en pantalla (en)",
      value: inputs.accountMessageEn || "",
      name: "accountMessageEn",
      type: "text",
      helperText: ["Example: Only interbank tranfers"],
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.accountMessageEs,
  };

  const referenceInputProps = {
    values: {
      title: "Referencia",
      value: inputs.reference || "",
      name: "reference",
      type: "text",
      required: false,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.description || errorsValidation["description"],
  };

  const isBankInternational = () => !inputs || (inputs.countryCode !== "PE" && inputs.currencyCode === "USD");

  return (
    <form className="content-tab" onSubmit={handleSubmitInputs}>
      <div className="content-tab-container" style={{ padding: "2rem" }}>
        <div>
          <h3>Datos generales de cuenta</h3>
          {!loading2 ? (
            <React.Fragment>
              <InputElement {...descriptionInputProps} />
              <div className="static-form-add-container">
                <InputElement {...currencyCodeInputProps} />
                <InputElement {...countryCodeInputProps} />
                <InputElement {...accountUtilityInputProps} />
                <InputElement {...swiftBankIdInputProps} />
                <InputElement {...statusInputProps} />
                <InputElement {...accountNumberInputProps} />
                <InputElement {...accountMessageEsInputProps} />
                <InputElement {...accountMessageEnInputProps} />
                <div className="input-file-preview-container">
                  <InputElement {...accountLogoInputProps} />
                  {accessToAction && (
                    <label htmlFor="input-file-bnx-form">
                      <input
                        hidden
                        type="file"
                        name=""
                        id="input-file-bnx-form"
                        onChange={uploadFile}
                      />
                      <img src={addImageLogo} alt="delete" width="16px" />
                    </label>
                  )}
                </div>
                {isBankInternational() &&
                 <InputElement {...referenceInputProps} />
                }
                {existPreviewData && (
                  <div className="input-file-preview-container">
                    <img
                      className="logo-preview-img"
                      id="logo-preview-img"
                      src={previewFile.data}
                      alt={previewFile.name}
                    />
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      width="16px"
                      onClick={handleDeleteImageData}
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
           <div className="container-loader-bnx-account">
             <Loader message="Cargando datos" />
           </div>
          )}
        </div>
        <br />
        <br />
        <h3>Detalles extra de cuenta</h3>
        <DragAndDropContainer
          accessToAction={accessToAction}
          Component={Component}
          listComponents={listComponents}
          handleDeleteItem={handleDeleteItem}
          handleAction={() => {
            if (errorsValidation && errorsValidation.dynamicInputsErrors) {
              errorsValidation.dynamicInputsErrors["accountDetails"] = [];
            }
          }}
        />
        {accessToAction && (
          <div className="container-button">
            <button
              className="add-drag-field-button"
              type="button"
              onClick={handleAddInput}
            >
              {" "}
              <img src={AddIcon} alt="" width="16px" height="16px" /> Añadir
              campo
            </button>
          </div>
        )}
        <br />
        <br />
        <h3>Instrucciones de cuenta</h3>
        <DragAndDropContainer
          accessToAction={accessToAction}
          Component={RuleComponent}
          listComponents={rulesListComponents}
          handleDeleteItem={handleRulesDeleteItem}
          handleAction={() => {
            if (errorsValidation && errorsValidation.dynamicInputsErrors) {
              errorsValidation.dynamicInputsErrors["accountRulesInstructions"] =
                [];
            }
          }}
        />
        {accessToAction && (
          <div className="container-button">
            <button
              className="add-drag-field-button"
              type="button"
              onClick={handleRulesAddInput}
            >
              {" "}
              <img src={AddIcon} alt="" width="16px" height="16px" /> Añadir
              campo
            </button>
          </div>
        )}
      </div>
      {accessToAction && (
        <div className="buttons-container">
          <div className="buttons-left"></div>
          <CustomButton {...buttonProps} disabled={loading} type="submit" />
        </div>
      )}
    </form>
  );
};

export default EditForm;
