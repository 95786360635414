import React from 'react';
import empty from 'is-empty';
import './tableContainer.css';
import { Table } from 'react-bootstrap';
import { Loader } from '../Loader/Loader';
import isEmpty from 'is-empty';
export default function TableComponent({
  rowHead,
  dataBody,
  rowFoot,
  loading = false,
  handleClickOnRow,
  rowIdSelected,
  identifierSlot="id"
}) {
  const handleClassname = (row) => !empty(rowIdSelected) && rowIdSelected == row[identifierSlot] ? 'select' : ''
  return (
    <Table
      bordered
      hover
      style={{ width: '100%', minWidth: 'calc(100vw - 40px)' }}
      className="m-0">
      <thead>
        <tr>
          {rowHead && rowHead.map((row, key) => (
            <th className={row.className || ''} key={key}>
              {row.title}
            </th>
          ))}
        </tr>
      </thead>
      {loading ? (
        <Loader message="Cargando registros..." />
      ) : !empty(dataBody) ? (
        <tbody style={{ minWidth: '1800px' }}>
          {dataBody.map((row, index) => (
            <tr
              key={index}
              className={handleClassname(row)}>
              {rowHead.map((col, key) => (
                <td
                  className={col.className || ""}
                  onClick={() => col.handleSelectRow && col.handleSelectRow({row,handleClickOnRow})}
                  key={key}>
                  {col.cell ? (
                    <col.cell data={row} />
                  ) : col.tag && !empty(row[col.tag]) ? (
                    row[col.tag]
                  ) : (
                    '-'
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      ) : (
        <div className='no_data_table'>
          <p>No se encontraron registros 👻</p>
        </div>
      )}
      {!isEmpty(rowFoot) && 
      <tfoot>
        <tr>
          {rowFoot && rowFoot.map((row, key) => (
            <th className={row.className || ''} key={key}>
              {row.title}
            </th>
          ))}
        </tr>
      </tfoot>
      }
    </Table>
  );
}
