export  const userLevels = {
    0: 'Nivel 0: Usuario registrado',
    1: 'Nivel 1: KYC 1 aprobado',
    2: 'Nivel 2: KYC 2 aprobado',
    3: 'Nivel 3: KYC 3 aprobado',
    4: 'Nivel 4: Usuario corporativo',
    5: 'Nivel 5: uso interno',
    10: 'Nivel 10: KYC 10 aprobado',
    11: 'Nivel 11: KYC 11 aprobado',
    12: 'Nivel 12: KYC 12 aprobado',
    13: 'Nivel 13: KYC 13 aprobado',
    14: 'Nivel 14: KYC 14 aprobado',
    17: 'Nivel 17: Corporativo sin verificación',
    18: 'Nivel 18: KYC 18 corporativo aprobado'
  };