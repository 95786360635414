import React from "react";
import DateHelper from "../../../../../helpers/Date.helper";
import { EditableText } from "../../../../FallComponents/EditableText/EditableText";
import editIcon from "../../../../../images/new_icons/edit.svg";
import checkIcon from "../../../../../images/new_icons/check.svg";
import crossIcon from "../../../../../images/new_icons/cross.svg";

export const GeneralDetails = ({
  data,
  isEditable,
  handleResetValues,
  dailyAmountInputProps,
  monthlyAmountInputProps,
  handleCloseEdition
}) => {
  return (
    <div className="deposit-limits-details-content">
      <div className="details-option">
        <p>Fecha de creación:</p>
        <p> {DateHelper.formatDate(data.createdAt)}</p>
      </div>
      <div className="details-option">
        <p>Fecha de actualización:</p>
        <p>{DateHelper.formatDate(data.updatedAt)}</p>
      </div>
      <div className="flex items-center gap-1">
        <h3>Detalle de umbrales</h3>
        <img
          onClick={handleCloseEdition}
          src={isEditable ? checkIcon : editIcon}
        />
        {isEditable && <img onClick={handleResetValues} src={crossIcon} />}
      </div>
      <EditableText {...dailyAmountInputProps} />
      <EditableText {...monthlyAmountInputProps} />
    </div>
  );
};
