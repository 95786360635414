import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getBankMovements } from "../FallServices/BankingMovementsService";

class BankingMovementsController {
    static buildBankMovements = async ({
        setLoading,
        inputs,
        userInfo,
        setBankingMovements,
        context
    }) => {
        setLoading(true);
        const result = await getBankMovements(inputs, userInfo.UserId);
        if (result) {
            setBankingMovements(result);
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('Information could not be processed') })
        }
        setLoading(false);
    };
};

export default BankingMovementsController;