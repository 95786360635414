import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../hooks/useFilter";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../images/new_icons/close-circle.svg";
import { useModal } from "../../../hooks/useModal";
import AllProfilesData from "../../../components/FallComponents/ProfilesPage/AllProfilesData";
import ProfilesController from "../../FallControllers/ProfilesController";
import ProfilesDetails from "../../../components/FallComponents/ProfilesPage/ProfilesDetails";
import AddModal from "../../../components/FallComponents/ProfilesPage/AddModal";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";

const ProfilesPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditable2, setIsEditable2] = useState(false);
  const { showModal, handleToggleModal: handleToggleAddModal } = useModal();
  const { showModal: showModalConfirmClose, handleToggleModal: handleToggleModalConfirmClose } = useModal();
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => isEditable || isEditable2 ? handleToggleModalConfirmClose() : setWithRightSection(false),
    },
  ];

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        isEditable || isEditable2 ? handleToggleModalConfirmClose() : setWithRightSection(false)
      }
    }
    document.addEventListener("keydown",handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleSelectRow = ({ row }) => {
    setWithRightSection(value => {
      if(!value || (value && !isEditable && !isEditable2)){
        setSelectedRow(row);
      }
      return true
    });
  };

  const handleSubmitNewPermits = async (id) => {
    const arrayCheckbox = document.getElementsByClassName(
      "input-check-module-permits"
    );
    let selectedsArray = [];
    for (let item of arrayCheckbox) {
      if (item.checked) {
        selectedsArray.push(item.name);
      }
    }
    const response = await ProfilesController.updatePermits({userInfo,data:selectedsArray,id,defaultData:selectedRow})
    if(response){
      window.location.reload()
    }
  }
  const handleAddProfile = async (name) => {
    const response = await ProfilesController.createProfile({
      userInfo,
      setLoadingAction,
      name,
    });
    if (response) {
      handleToggleAddModal();
      reloadData();
    }
  };

  const handleDeleteProfile = async (id) => {
    const response = await ProfilesController.deleteProfile({
      userInfo,
      setLoadingAction,
      id,
    });
    if (response) {
      setWithRightSection(false);
      setRows((rows) => rows.filter((row) => row.profileId !== id));
    }
  };

  const getAllProfiles = () => {
    ProfilesController.getAllProfiles({
      userInfo,
      setLoading,
      setRows,
      setTotalRows,
      filterConfig,
    });
  };
  const getAllModules = () => {
    ProfilesController.getAllModules({
      userInfo,
      setTableHeaders,
    });
  };

  const reloadData = () => {
    getAllModules();
    getAllProfiles();
    setWithRightSection(false);
  };

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllProfiles();
    if (!isEmpty(tableHeaders)) return;
    getAllModules()
  }, [userInfo, filterConfig]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllProfilesData
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            tableHeaders={tableHeaders.map((item) => ({...item, handleSelectRow}))}
            reloadData={reloadData}
            selectedId={selectedRow.profileId}
            handleToggleAddModal={handleToggleAddModal}
          />
        }
        childrenTwo={
          <RightResizable loadingData={{}} headerOptions={headerOptions}>
            <ProfilesDetails
              modules={tableHeaders}
              data={selectedRow}
              handleSubmitNewPermits={handleSubmitNewPermits}
              handleDeleteProfile={handleDeleteProfile}
              loading={loadingAction}
              userInfo={userInfo}
              reloadData={reloadData}
              isEditable={isEditable}
              isEditable2={isEditable2}
              setIsEditable={setIsEditable}
              setIsEditable2={setIsEditable2}
            />
          </RightResizable>
        }
      />
      {showModal && (
        <AddModal
          showModal={showModal}
          handleToggleAddModal={handleToggleAddModal}
          addProfile={handleAddProfile}
          loading={loadingAction}
        />
      )}
      {showModalConfirmClose && (
        <ConfirmModal
          showModal={showModalConfirmClose}
          handleToggleModal={() => {
            handleToggleModalConfirmClose();
          }}
          title="Confirmar cambios"
          message="¿Desea salir sin guardar los cambios?"
          confirmButtonProps={{
            onClick: () => setWithRightSection(false),
            text: "Sí",
          }}
          cancelButtonProps={{
            text: "No",
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

ProfilesPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ProfilesPage);
