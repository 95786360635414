import React from 'react';
import PropTypes from 'prop-types';

const stepTitle = (props, context) => {
  return (
    <div className="TourInfoBox">
      <h2>{context.t(props.title)}</h2>
      <p>{context.t(props.content)}</p>
    </div>
  );
};
stepTitle.contextTypes = {
  t: PropTypes.func.isRequired
};
export default stepTitle;
