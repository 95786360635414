import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import DateHelper from "../../../helpers/Date.helper";
import { GroupFilters } from "../Filters/Filters";
import { alertStatus } from "../../../constants";
import statusComponent from "../StatusComponent/StatusComponent";

const AllAmlAlertsData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    handleSelectRow,
    selectedId,
    withRightSection,
    amlCodes,
    resetFilters,
    setFilterSearch
  } = props;
  const [filterInputs, setFilterInputs] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: RotateRight,
      action: () => {
        setFilterInputs({
          from: "",
          to: "",
          status:""
        });
        resetFilters(100,{status:""});
      },
    },
  ];

  const getBanexcoinAccountUserId = ({ data }) =>
    !isEmpty(
      data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId.nodes
    )
      ? data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId
          .nodes[0].banexcoinUserAccountId
      : "";

  const handleSearchFilters = () => {
    const filtersToSend = {...filterInputs}
    Object.keys(filterInputs).forEach(item => {
      if(isEmpty(filterInputs[item])){
        delete filtersToSend[item]
      }
      if(item !== "to" && item !== "from"){
        filtersToSend[item] = `"${filterInputs[item]}"`
      }
    })
    setFilterSearch(filtersToSend);
  };
  const tableHeaders = [
    {
      tag: "idAmlAlert",
      title: "Id de alerta",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Banexcoin user ID",
      handleSelectRow,
      cell: ({ data }) => (
        <p>{data.banexcoinUserByBanexcoinUserId.banexcoinUserid}</p>
      ),
    },
    {
      tag: "",
      title: "Nombre de usuario",
      handleSelectRow,
      cell: ({ data }) => (
        <p>{data.banexcoinUserByBanexcoinUserId.banexcoinUsername}</p>
      ),
    },
    {
      tag: "",
      title: "Banexcoin account ID",
      handleSelectRow,
      cell: getBanexcoinAccountUserId,
    },
    {
      tag: "",
      title: "Código de alerta",
      handleSelectRow,
      cell: ({ data }) => (
        <p>{!isEmpty(amlCodes) ? amlCodes[data.eventType] : "-"}</p>
      ),
    },
    {
      tag: "eventType",
      title: "Tipo de evento",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Fecha de creación",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "",
      title: "Estado",
      handleSelectRow,
      cell: ({ data }) =>
        statusComponent(
          data.status,
          false,
          alertStatus.map((status) => ({ id: status.value, name: status.name }))
        ),
    },
  ];

  const filters = [
    {
      values: {
        title: "Correo electrónico",
        name: "email",
        value: filterInputs.email,
        type: "text",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Estado",
        name: "status",
        value: filterInputs.status,
        type: "select",
        options: alertStatus,
        placeholder: "Seleccione un estado",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Desde",
        name: "from",
        value: filterInputs.from,
        type: "date",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Hasta",
        name: "to",
        value: filterInputs.to,
        type: "date",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
  ];

  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Alertas AML")}
        subTitle="Cumplimiento / Alertas AML"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}
      >
        {showFilters && (
          <GroupFilters
            inputs={filters}
            withRightSection={withRightSection}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllAmlAlertsData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllAmlAlertsData);
