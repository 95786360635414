import UtilsHelper from "../../helpers/Utils.helper";

export const getAllTransactionsDeposit = async (userId, params) => {
    const path = '/api/coink-transactions/deposits';
    const configRequest = {
        userId,
        method: "GET",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
}


export const getAllTransactionsWithdrawals = async (userId, params) => {
    const path = '/api/coink-transactions/withdrawals';
    const configRequest = {
        userId,
        method: "GET",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
}
