import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { createCategory, deleteCategory, getAllCategories, updateCategory } from "../FallServices/CategoryServices";

class CategoriesController {
    static getAllCategories = async ({
        userInfo,
        setLoading,
        setCategoryList
    }) => {
        try {
            setLoading(true);
            const response = await getAllCategories(userInfo.UserId);
            if (response.status === 200) {
                if (!isEmpty(response.status)) {
                    let newData = response.data.map(element => {
                        return { ...element, modulesList: element.modulesByCategoryId.nodes }
                    });
                    setCategoryList(newData);
                }
                return true;
            };
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo."
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo."
            });
            return false;
        } finally {
            setLoading(false);
        };
    };

    static createCategory = async ({
        userInfo,
        name,
        setCategoryList,
        setMessage
    }) => {
        try {
            const data = { name };
            const response = await createCategory(userInfo.UserId, data);
            if (response.status === 201) {
                const newCategory = {
                    ...response.data.id,
                    modulesList: []
                };
                setCategoryList(element => [newCategory, ...element]);
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "Categoría creada exitosamente."
                });
                return true;
            }
            setMessage('Hubo un problema. Por favor, vuelva a intentarlo.');
            return false;
        } catch (err) {
            setMessage(err.response.data.message);
            return false;
        };
    };

    static updateCategory = async ({
        userInfo,
        categoryId,
        name,
        setCategoryList,
        context
    }) => {
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Enviando..."
            });
            const data = { name };
            const response = await updateCategory(userInfo.UserId, categoryId, data);
            if (response.status === 200) {
                setCategoryList(categoryList => categoryList.map((element) => {
                    if (element.id !== categoryId) return element;
                    return { ...element, name };
                }));
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "Categoría actualizada exitosamente."
                });
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo."
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: `No se pudo actualizar la categoría. ${context.t(err.response.data.message)}`
            });
            return false;
        };
    };

    static deleteCategory = async ({
        userInfo,
        categoryId,
        setCategoryList,
        setWithRightSection,
        context
    }) => {
        try {
            const response = await deleteCategory(userInfo.UserId, categoryId);
            if (response.status === 200) {
                setCategoryList(categoryList => categoryList.filter(data => data.id !== categoryId));
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "Categoría eliminada exitosamente."
                });
                setWithRightSection(false);
                return true;
            }
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo."
            });
            return false;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: context.t(err.response.data.message)
            });
            return false;
        };
    };
}

export default CategoriesController;