import { connect } from 'react-redux';
import CryptoWalletDepositWorkflowComponent from './CryptoWalletDepositWorkflowComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

var mapStateToProps = function mapStateToProps(state) {
  return {
    addressList: state.deposit.depositInfo,
    product: depositProductSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(
        _showSnack({ id: 'depositCopyAddress', text: text, type: 'success' })
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CryptoWalletDepositWorkflowComponent);
