import React from "react";
import addImageLogo from "../../../../images/new_icons/folder-add.svg";

export const File = ({ id, title, name, onChange, accept }) => {

  return (
    <label className="input-file-label" htmlFor={`input-file-bnx-form-${id}`}>
      <p>{title}</p>
      <input
        type="file"
        name={name}
        id={`input-file-bnx-form-${id}`}
        hidden
        onChange={onChange}
        accept={accept}
      />
      <img src={addImageLogo} alt="file-icon" />
    </label>
  );
};
