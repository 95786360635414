import React from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
//import APIcon from '../components/common/APIcon';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
//import path from '../helpers/path';
import StandAloneLayout from '../layouts/StandAloneLayout/StandAloneLayout';

import LoginFormContainer from './Login/LoginFormContainer';

import '../styles/components/common/StandaloneForm.css';
import './LoginPage.css';
import bg1 from './../images/admin-bgs/1.jpg';
import bg2 from './../images/admin-bgs/2.jpg';
import bg3 from './../images/admin-bgs/3.jpg';
import bg4 from './../images/admin-bgs/4.jpg';

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const rndInt = randomIntFromInterval(1, 4);

let bgs = {
  bg1: bg1,
  bg2: bg2,
  bg3: bg3,
  bg4: bg4
};

export const LoginFormComponent = (props, context) => {
  return (
    <StandAloneLayout className="v2container-box">
      <div
        className={'container-left'}
        style={{ backgroundImage: `url(${bgs['bg' + rndInt]})` }}
      />
      <div className="container-right">
        <LoginFormContainer />
      </div>
    </StandAloneLayout>
  );
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  LoginFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
