import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getSummaryDashboard } from "../FallServices/DashboardServices";

class DashboardController {
    static handleDashboard = async ({
        userInfo,
        setLoading,
        setAllData
    }) => {
        try {
            setLoading(true);
            const response = await getSummaryDashboard(userInfo.UserId);
            if (!isEmpty(response.data)) {
                const newData = {
                    allVCurrentYearQuantities: response.data.allVCurrentYearQuantities.nodes,
                    allVThisMonthSignupQuantities: response.data.allVThisMonthSignupQuantities.nodes,
                    allVTodayQuantities: response.data.allVTodayQuantities.nodes
                }
                setAllData(newData);
            };
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    };
}

export default DashboardController;