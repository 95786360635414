export const nationality = [
    'PE',
    'CO',
    'VE',
    'ES',
    'PR',
    'PY',
    'PA',
    'NI',
    'MX',
    'CR',
    'CL',
    'BO',
    'AR',
    'CU',
    'NI'
];