import UtilsHelper from "../../helpers/Utils.helper";

export const getSummaryDashboard = async (userId) => {
    const path = '/api/v2/dashboard/summary';
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
};