import React from 'react';
import PropTypes from 'prop-types';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var PsigateDepositWorkflowComponent = (function(_React$Component) {
  _inherits(PsigateDepositWorkflowComponent, _React$Component);

  function PsigateDepositWorkflowComponent() {
    _classCallCheck(this, PsigateDepositWorkflowComponent);

    return _possibleConstructorReturn(
      this,
      (
        PsigateDepositWorkflowComponent.__proto__ ||
        Object.getPrototypeOf(PsigateDepositWorkflowComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(PsigateDepositWorkflowComponent, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.form.submit();
      }
    },
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var options = this.props.options;

        return React.createElement(
          'div',
          null,
          React.createElement('p', null, 'Redirecting to Psigate...'),
          React.createElement(
            'form',
            {
              id: 'psigateForm',
              style: { visibility: 'hidden' },
              action: options.APIAddress,
              method: 'post',
              ref: function ref(form) {
                return (_this2.form = form);
              }
            },
            React.createElement('input', {
              name: 'MerchantID',
              type: 'text',
              value: options.MerchantID
            }),
            React.createElement('input', {
              name: 'PaymentType',
              type: 'text',
              value: options.PaymentType
            }),
            React.createElement('input', {
              name: 'OrderID',
              type: 'text',
              value: options.OrderID
            }),
            React.createElement('input', {
              name: 'UserID',
              type: 'text',
              value: options.UserID
            }),
            React.createElement('input', {
              name: 'SubTotal',
              type: 'text',
              value: options.SubTotal
            }),
            React.createElement('input', {
              name: 'CardAction',
              type: 'text',
              value: 0
            }),
            React.createElement('input', {
              name: 'ResponseFormat',
              type: 'text',
              value: options.ResponseFormat
            }),
            React.createElement('input', {
              name: 'Email',
              type: 'text',
              value: options.Email
            }),
            React.createElement('input', {
              name: 'ThanksURL',
              type: 'text',
              value: options.ThanksURL
            }),
            React.createElement('input', {
              name: 'NoThanksURL',
              type: 'text',
              value: options.NoThanksURL
            })
          )
        );
      }
    }
  ]);

  return PsigateDepositWorkflowComponent;
})(React.Component);

PsigateDepositWorkflowComponent.defaultProps = {
  options: {
    MerchantID: '',
    PaymentType: '',
    OrderID: '',
    UserID: '',
    SubTotal: '',
    ResponseFormat: '',
    Email: ''
  }
};

PsigateDepositWorkflowComponent.propTypes = {
  options: PropTypes.shape({
    MerchantID: PropTypes.string,
    PaymentType: PropTypes.string,
    OrderID: PropTypes.string,
    UserID: PropTypes.string,
    SubTotal: PropTypes.string,
    ResponseFormat: PropTypes.string,
    Email: PropTypes.string
  })
};

export default PsigateDepositWorkflowComponent;
