import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import InformationSection from '../../InformationSection/InformationSection'
import { useForm } from '../../../../hooks/formHooks';
import { LoaderButton } from '../../Loader/Loader';
import isEmpty from 'is-empty';

const CardConfiguration = (props, context) => {

    const { editPermission, loaderInitialCard, allInitialSetting, handleSubmitB89Card } = props;
    const { enabled } = allInitialSetting

    const initialValues = {
        enabled: !isEmpty(enabled) ? enabled : ''
    }

    const { inputs, onInputChangeV2, setInputs } = useForm(initialValues);

    const resetValues = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = initialValues[name];
        });
        setInputs({
            ...inputs,
            ...newInitialvalues
        })
    };

    const handleSubmit = async () => {
        const data = {
            enabled: inputs.enabled === 'true'
        }
        const response = await handleSubmitB89Card(data)
        if (!response) {
            setInputs({
                ...inputs,
                enabled: initialValues.enabled
            })
        }
    }

    const informationB89 = {
        title: 'Tarjeta B89',
        resetValues: resetValues,
        submitData: handleSubmit,
        fields: [
            {
                label: 'Habilitado',
                name: 'enabled',
                type: 'select',
                showCheckbox: false,
                options: [
                    {
                        name: 'Si',
                        value: "true",
                    },
                    {
                        name: 'No',
                        value: "false",
                    }
                ]
            }
        ]
    };

    useEffect(() => {
        if (!isEmpty(allInitialSetting)) {
            setInputs({
                enabled: allInitialSetting.enabled
            })
        }
    }, [allInitialSetting])

    return (
        <section>
            <h2 className='sub-title'>Configuración de tarjetas</h2>
            {loaderInitialCard ?
                <div className='section-loader'>
                    <LoaderButton color='orange' />
                    <p>Cargando información...</p>
                </div> :
                <div id='minimun-section'>
                    <InformationSection
                        data={informationB89}
                        inputs={inputs}
                        onChange={onInputChangeV2}
                        enableButtons={editPermission}
                        context={context}
                    />
                </div>
            }
        </section >
    )
}

CardConfiguration.contextTypes = {
    t: PropTypes.func.isRequired
};

export default CardConfiguration