import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import xlsx from "json-as-xlsx";
import { Form, Badge, CustomInput, Button, Row, Col, Table } from 'reactstrap'
import HeaderPage from '../../components/FallComponents/HeaderPage/HeaderPage';
import BankingMovementsController from '../FallControllers/BankingMovementsController';
import isEmpty from 'is-empty';
import ComponentsHelper from '../../helpers/Components.helper';
import { toastTypes } from '../../constants';

const BankingMovementsToolPage = (props, context) => {

   const { userInfo } = props;

   const [bankingMovements, setBankingMovements] = useState([]);
   const [tableHeaders, setTableHeaders] = useState([]);
   const [fineName, setFileName] = useState('');
   const [loading, setLoading] = useState(false);
   const [showInput, setShowInput] = useState(true);
   const [errors, setErrors] = useState({});
   const [inputs, setInputs] = useState({
      bankingMovementsFile: null
   });

   const onInputFileChange = (e) => {
      if (typeof e.persist === 'function') { e.persist(); };
      const { name, files } = e.target;
      setInputs({
         ...inputs,
         [name]: files[0]
      });
      delete errors[name];
      if (files[0]) {
         setFileName(`${(files[0].name).slice(0, -5)}_Nuevo_Formato`);
      }
   };

   const customValidations = (inputs) => {
      let inputsErrors = {};
      if (isEmpty(inputs.bankingMovementsFile)) {
         inputsErrors.bankingMovementsFile = 'Debes llenar este campo';
      }
      setErrors(inputsErrors);
      return inputsErrors;
   };

   const getBankingMovements = () => {
      BankingMovementsController.buildBankMovements({
         setLoading,
         inputs,
         userInfo,
         setBankingMovements,
         context
      })
   }

   const onSubmit = e => {
      e.preventDefault();
      const errorsCustom = customValidations(inputs);
      if (isEmpty(errorsCustom)) {
         getBankingMovements();
      }
   };

   const buildColumns = (data) => {
      const getKeys = Object.keys(data[0]);
      const columns = getKeys.map((name) => {
         return {
            label: name,
            value: (row) => row[name],
         };
      });
      return columns;
   };

   const downloadNewExcelFormat = () => {
      ComponentsHelper.toast({
         type: toastTypes.info,
         message: "Descargando...",
      });
      const columns = buildColumns(bankingMovements);
      const data = [
         {
            sheet: "Datos",
            columns,
            content: bankingMovements
         }
      ];
      const settings = {
         fileName: fineName,
         extraLength: 10,
         writeOptions: {}
      };
      setTimeout(() => {
         xlsx(data, settings)
      }, 1);
   }

   const cleanData = () => {
      setBankingMovements([]);
      setTableHeaders([]);
      setFileName('');
      setLoading(false);
      setInputs({});
      setShowInput(false);
      setTimeout(() => {
         setErrors({});
         setShowInput(true);
      }, 1);
   }

   useEffect(() => {
      if (!isEmpty(bankingMovements)) {
         const getKeys = Object.keys(bankingMovements[0]);
         const headers = getKeys.map((name) => {
            return {
               tag: name,
               title: name,
            };
         });
         setTableHeaders(headers);
      }
   }, [bankingMovements])


   return (
      <div className='content-container table_banking_transactions'>
         <HeaderPage
            title="Herramienta de Movimientos Bancarios"
            subTitle="Finanzas / Herramienta de Movimientos Bancarios"
         />
         <div>
            <Form noValidate onSubmit={onSubmit}>
               <Row form style={{ width: '400px' }}>
                  <Col className='p-2'>
                     <label style={{ fontSize: '14px' }}><b>Documento</b></label>
                     <br />
                     {showInput &&
                        <React.Fragment>
                           <CustomInput
                              type="file"
                              name="bankingMovementsFile"
                              label="Adjunte un archivo excel"
                              accept=".xlsx"
                              onChange={onInputFileChange}
                              invalid={!isEmpty(errors.bankingMovementsFile) ? true : false}
                           />
                           {!isEmpty(errors.bankingMovementsFile) &&
                              <Badge color="danger" style={{ fontSize: '10px', marginTop: '6px' }}>{errors.bankingMovementsFile}</Badge>
                           }
                        </React.Fragment>
                     }
                  </Col>
               </Row>
               <Row form>
                  <Col md={5}>
                     {!isEmpty(tableHeaders) ?
                        <Button className="mr-0 mt-3 button-tool" type="button" onClick={cleanData}>
                           Limpiar datos
                        </Button>
                        :
                        <Button color="warning" className="mr-0 mt-3" type="submit" disabled={loading}>
                           Subir archivo
                        </Button>
                     }
                  </Col>
               </Row>
            </Form>
            {loading &&
               <React.Fragment>
                  <br />
                  <h2>Cargando información...</h2>
               </React.Fragment>
            }
         </div>
         <div className='over_table'>
            {!isEmpty(tableHeaders) &&
               <React.Fragment>
                  <h2 className='title-tool'>
                     Vista previa de {fineName}
                  </h2>
                  <Button color="success" className="mr-0 mt-3 button-tool" type="button" onClick={downloadNewExcelFormat}>
                     Descargar
                  </Button>
                  <br />
                  <br />
                  <div>
                     <Table className="table-tools table-striped table-hover table-bordered">
                        <thead>
                           <tr>
                              {tableHeaders.map(({ title }, key) => <th key={key}>{title}</th>)}
                           </tr>
                        </thead>
                        <tbody>
                           {bankingMovements.map((data, key) =>
                              <tr key={key}>
                                 {tableHeaders.map(({ tag }, key2) => <td ey={key2}>{data[tag]}</td>)}
                              </tr>
                           )}
                        </tbody>
                     </Table>
                  </div>
               </React.Fragment>
            }
         </div>
      </div >
   )
}

const mapStateToProps = ({ user: { userInfo } }) => {
   return { userInfo };
};

BankingMovementsToolPage.contextTypes = {
   t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(BankingMovementsToolPage);