import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Download from "../../../images/new_icons/download.svg";
import Setting from "../../../images/new_icons/setting.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import AllBalancesReportData from "../../../components/FallComponents/BalanceReports/AllBalancesReportData";
import BalancesReportController from "../../FallControllers/BalancesReportController";
import HeaderPage from "../../../components/FallComponents/HeaderPage/HeaderPage";
import ComponentsHelper from "../../../helpers/Components.helper";
import { toastTypes } from "../../../constants";
import { GroupFilters } from "../../../components/FallComponents/Filters/Filters";
import "../../../components/FallComponents/BalanceReports/allBalancesReportData.css";
import AllBalancesReportHistoryData from "../../../components/FallComponents/BalanceReports/AllBalancesReportHistoryData";
import DateHelper from "../../../helpers/Date.helper";
import xlsx from "json-as-xlsx";

const BalanceReportsPage = (props, context) => {
  const initialState = {
    date: "",
  };
  const { userInfo, decimalPlaces:productsDecimalPlaces } = props;
  const [rows, setRows] = useState([]);
  const [rowsNewReport, setRowsNewReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNewReport, setLoadingNewReport] = useState(false);
  const [showFilters, setShowFilters] = useState(true);

  const [filterInputs, setFilterInputs] = useState(initialState);

  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const resetData = () => {
    setRows([]);
  };

  const handleSearchFilters = () => {
    if (isEmpty(filterInputs.date)) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Ingrese una fecha válida",
      });
      return;
    }
    BalancesReportController.getBalanceReportsByDate({
      userInfo,
      setRows: setRowsNewReport,
      setLoading: setLoadingNewReport,
      date: filterInputs.date,
    });
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: Download,
      action: () => downloadNewExcelFormat(),
    },
    {
      icon: RotateRight,
      action: () => {
        getAllBalanceReports()
        setRowsNewReport([])
        setFilterInputs(initialState)
      },
    },
  ];

  const downloadNewExcelFormat = () => {
    const columns = Object.keys(rowsNewReport[0]).map((item) => ({
      value: item,
      label: item,
    }));
    const data = [
      {
        sheet: `reporte de balances`,
        columns,
        content: rowsNewReport,
      },
    ];
    const settings = {
      fileName: `reporte_balances_${DateHelper.formatDate(filterInputs.date)}`,
      extraLength: 12,
      writeOptions: {},
    };
    xlsx(data, settings);
  };

  const getAllBalanceReports = async () => {
    await BalancesReportController.getAllBalanceReports({
      userInfo,
      setRows,
      setLoading,
    });
  };

  const filters = [
    {
      values: {
        title: "Fecha",
        name: "date",
        value: filterInputs.date,
        type: "date",
        placeholder: "Seleccione una fecha",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
  ];

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllBalanceReports();
  }, [userInfo]);

  return (
    <div className="dashboardPage container-page-data beta-content-container">
      <div className="header-filter-container">
        <HeaderPage
          title={context.t("Reportes de balance")}
          subTitle="Finanzas / Reportes de balance"
          headerOptions={headerOptions}
        />
        {showFilters && (
          <GroupFilters
            inputs={filters}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </div>
      <div className="dashboard-container-main">
        <AllBalancesReportData
          rows={rowsNewReport}
          loading={loadingNewReport}
          resetData={resetData}
          productsDecimalPlaces={productsDecimalPlaces}
        />
        <AllBalancesReportHistoryData
          rows={rows}
          loading={loading}
          resetData={resetData}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ user:{userInfo},product:{ decimalPlaces } }) => {
  return { userInfo,decimalPlaces };
};

BalanceReportsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BalanceReportsPage);
