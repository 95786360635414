import UtilsHelper from "../../helpers/Utils.helper";

export const createModuleByCategoryId = async (userId, data) => {
    const path = '/api/module';
    const configRequest = {
        userId,
        method: "POST",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const updateModuleByCategoryId = async (userId, moduleId, data) => {
    const path = `/api/module/${moduleId}`;
    const configRequest = {
        userId,
        method: "PUT",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const deleteModuleById = async (userId, moduleId) => {
    const path = `/api/module/${moduleId}`;
    const configRequest = {
        userId,
        method: "DELETE",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}