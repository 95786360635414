import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { getBEMClasses, addModifier } from '../../../helpers/cssClassesHelper';

import './APIcon.css';

// navigation
import buySellIcon from '../../../images/icons/icon-nav-buy-sell.svg';
import dashboardIcon from '../../../images/icons/icon-nav-dashboard-on.svg';
import tradingIcon from '../../../images/icons/icon-nav-trading.svg';
import userIcon from '../../../images/icons/icon-nav-user.svg';
import userSettingsIcon from '../../../images/icons/icon-nav-settings.svg';
import walletIcon from '../../../images/icons/icon-nav-wallet.svg';
import balanceIcon from '../../../images/icons/icon-balance.svg';
// navigations new
import compraVentaIcon from '../../../images/icons/icon-nav-compra-venta.svg';
import ajustesIcon from '../../../images/icons/icon-nav-ajustes.svg';
import billeterasIcon from '../../../images/icons/icon-nav-billetera.svg';
import desplegableIcon from '../../../images/icons/icon-nav-desplegable.svg';
import exchangeIcon from '../../../images/icons/icon-nav-exchange.svg';
import tableroIcon from '../../../images/icons/icon-nav-tablero.svg';
import easyBuyIcon from '../../../images/icons/icon-nav-easybuy.svg';

// user settings
import contactsIcon from '../../../images/icons/icon-contacts.svg';
import keyIcon from '../../../images/icons/icon-key.svg';
import reportsIcon from '../../../images/icons/icon-reports.svg';
import securityIcon from '../../../images/icons/icon-security.svg';
import tasksIcon from '../../../images/icons/icon-tasks.svg';
import heartIcon from '../../../images/icons/icon-heart.svg';
import friendsIcon from '../../../images/icons/icon-friends.svg';
import paintIcon from '../../../images/icons/icon-paint.svg';
// tables
import sendWithBorderIcon from '../../../images/icons/icon-send-with-border.svg';
import receiveWithBorderIcon from '../../../images/icons/icon-receive-with-border.svg';
import depositWithBorderIcon from '../../../images/icons/icon-deposit-with-border.svg';
import withdrawWithBorderIcon from '../../../images/icons/icon-withdraw-with-border.svg';
import openBuy from '../../../images/icons/icon-open-buy-big.svg';
import openSell from '../../../images/icons/icon-open-sell-big.svg';
//pagination
import caretPreviousIcon from '../../../images/icons/icon-caret-prev.svg';
import caretNextIcon from '../../../images/icons/icon-caret-next.svg';
import doubleCaretPreviousIcon from '../../../images/icons/icon-double-caret-prev.svg';
import doubleCaretNextIcon from '../../../images/icons/icon-double-caret-next.svg';
//forms
import dropdownIcon from '../../../images/icons/icon-dropdown.svg';
import barcodeIcon from '../../../images/icons/icon-barcode.svg';
import calendarIcon from '../../../images/icons/icon-calendar.svg';
import eyeIcon from '../../../images/icons/icon-eye.svg';
import infoIcon from '../../../images/icons/icon-info.svg';
import cancelIcon from '../../../images/icons/icon-cancel.svg';
import downloadIcon from '../../../images/icons/icon-download.svg';
import checkIcon from '../../../images/icons/icon-check.svg';
import simpleCheckIcon from '../../../images/icons/icon-simple-check.svg';
import refreshIcon from '../../../images/icons/icon-refresh.svg';
import closeIcon from '../../../images/icons/icon-close.svg';
import buyIcon from '../../../images/icons/icon-buy.svg';
import sellIcon from '../../../images/icons/icon-sell.svg';
import sendIcon from '../../../images/icons/icon-send.svg';
import receiveIcon from '../../../images/icons/icon-receive.svg';
import depositIcon from '../../../images/icons/icon-deposit.svg';
import withdrawIcon from '../../../images/icons/icon-withdraw.svg';
import copyIcon from '../../../images/icons/icon-copy.svg';
import starIcon from '../../../images/icons/icon-star.svg';
import stopIcon from '../../../images/icons/icon-stop.svg';
//ap
import logoPowered from '../../../images/icons/logo-powered.svg';
//misc
import notificationIcon from '../../../images/icons/icon-nav-notification.svg';
import centerIcon from '../../../images/icons/icon-center.svg';
import checkboxIcon from '../../../images/icons/icon-checkbox.svg';
import menuToggleIcon from '../../../images/icons/icon-menu-toggle.svg';
import bigKeyIcon from '../../../images/icons/icon-key-big.svg';
import arrowPreviousIcon from '../../../images/icons/icon-arrow-prev.svg';
import arrowNextIcon from '../../../images/icons/icon-arrow-next.svg';
import pendingIcon from '../../../images/icons/icon-pending.svg';
import settingsIcon from '../../../images/icons/icon-settings.svg';
import alertIcon from '../../../images/icons/icon-alert.svg';
import menuIcon from '../../../images/icons/icon-menu.svg';

const baseClass = 'ap-icon';

const icons = {
  // navigation
  'buy-sell': buySellIcon,
  dashboard: dashboardIcon,
  trading: tradingIcon,
  user: userIcon,
  'user-settings': userSettingsIcon,
  wallet: walletIcon,
  balance: balanceIcon,
  // new navigation
  'compra-venta': compraVentaIcon,
  ajustes: ajustesIcon,
  billeteras: billeterasIcon,
  desplegable: desplegableIcon,
  exchange: exchangeIcon,
  tablero: tableroIcon,
  easybuy: easyBuyIcon,
  // user-settings
  contacts: contactsIcon,
  key: keyIcon,
  reports: reportsIcon,
  security: securityIcon,
  tasks: tasksIcon,
  heart: heartIcon,
  paint: paintIcon,
  friends: friendsIcon,
  // table
  sendWithBorder: sendWithBorderIcon,
  receiveWithBorder: receiveWithBorderIcon,
  depositWithBorder: depositWithBorderIcon,
  withdrawWithBorder: withdrawWithBorderIcon,
  'open-buy': openBuy,
  'open-sell': openSell,
  //pagination
  caretPrevious: caretPreviousIcon,
  caretNext: caretNextIcon,
  doubleCaretPrevious: doubleCaretPreviousIcon,
  doubleCaretNext: doubleCaretNextIcon,
  // forms
  dropdown: dropdownIcon,
  barcode: barcodeIcon,
  calendar: calendarIcon,
  eye: eyeIcon,
  info: infoIcon,
  cancel: cancelIcon,
  download: downloadIcon,
  check: checkIcon,
  'simple-check': simpleCheckIcon,
  refresh: refreshIcon,
  close: closeIcon,
  buy: buyIcon,
  sell: sellIcon,
  receive: receiveIcon,
  send: sendIcon,
  deposit: depositIcon,
  withdraw: withdrawIcon,
  copy: copyIcon,
  star: starIcon,
  stop: stopIcon,
  arrowPrevious: arrowPreviousIcon,
  arrowNext: arrowNextIcon,
  //ap
  'logo-powered': logoPowered,
  //misc and possibly unused
  notification: notificationIcon,
  center: centerIcon,
  checkbox: checkboxIcon,
  'menu-toggle': menuToggleIcon,
  bigKey: bigKeyIcon,
  pending: pendingIcon,
  settings: settingsIcon,
  alert: alertIcon,
  menu: menuIcon
};

const APIcon = props => {
  const { name, customClass, classModifiers } = props;
  const modifiers = addModifier(classModifiers, name);
  const bemClasses = getBEMClasses([baseClass, customClass]);
  const icon = icons[name];

  return <SVG src={icon} className={bemClasses(null, modifiers)} />;
};

APIcon.defaultProps = {
  name: '',
  customClass: 'custom-icon',
  classModifiers: '',
  disabled: false
};

APIcon.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  disabled: PropTypes.bool
};

export default APIcon;
