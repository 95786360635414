import isEmpty from "is-empty";
import React, { useState } from "react";
import { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "../../../../hooks/formHooks";
import ReportTypeController from "../../../../pages/FallControllers/ReportTypeController";
import { GroupFilters } from "../../../../components/FallComponents/Filters/Filters";
import HeaderPage from "../../../../components/FallComponents/HeaderPage/HeaderPage";
import TableContainer from "../../../../components/FallComponents/Table/TableContainer";
import Download from "../../../../images/new_icons/download.svg";
import moment from "moment";
import { getCountries } from "../../../../components/AccountComponents/AccountHooks";


const ReportTrades = (props, context) => {
  const { userInfo, decimalPlaces } = props;
  const filterInitialValues = {
    initDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  const { inputs: filterInputs, onInputChangeV2 } =
    useForm(filterInitialValues);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableFooters, setTableFooters] = useState([]);
  const [countries, setCountries] = useState([]);

  const headerOptions = [
    {
      icon: Download,
      action: () => handleDownload(),
    },
  ];

  useEffect(() => {
    const getCountriesName = async () => {
      if (!userInfo) return "";
      const countries = await getCountries(userInfo.UserId);
      if (!countries) return "Error al obtener los paises";
      setCountries(countries.data.countries);
    };
    getCountriesName();
  }, [userInfo]);

  const handleSearchFilters = () => {
    if (isEmpty(filterInputs.initDate) || isEmpty(filterInputs.endDate)) return;
    ReportTypeController.AllTradeDataReport({
      userInfo,
      params: filterInputs,
      setLoading,
      setRows,
      countries
    });
  };

  const handleDownload = () => {
    if (isEmpty(rows)) return;
    ReportTypeController.DownloadAllTrade({
      rows,
      tableFooters,
    });
  };

  const tableHeaders = [
    {
      tag: "idUsuario",
      title: "IDUsuario",
    },
    {
      tag: "idCuenta",
      title: "IDCuenta",
    },
    {
      tag: "nombreCuenta",
      title: "NombreCuenta",
      className: "table-title-v2",
    },
    {
      tag: "fechaPeru",
      title: "FechaRegistro",
    },
    {
      tag: "fechaNacimiento",
      title: "FechaNacimiento",
    },
    {
      tag: "edad",
      title: "Edad",
    },
    {
      tag: "rangoEdad",
      title: "RangoEdad",
    },
    {
      tag: "generoTexto",
      title: "Genero",
    },
    {
      tag: "idAfiliado",
      title: "IDAfiliado",
    },
    {
      tag: "idReferido",
      title: "IDReferido",
    },
    {
      tag: "person",
      title: "TipoPersona",
    },
    {
      tag: "tipoCliente",
      title: "TipoCliente",
    },
    {
      tag: "paisResidencia",
      title: "PaisResidencia",
    },
    {
      tag: "residente",
      title: "Residente",
    },
    {
      tag: "nivelKyc",
      title: "NivelKYC",
    },
    {
      tag: "fechaTrade",
      title: "FechaTrade",
    },
    {
      tag: "diaTradePeru",
      title: "DiaTradePeru",
    },
    {
      tag: "mesTradePeru",
      title: "MesTradePeru",
    },
    {
      tag: "anioTradePeru",
      title: "AnioTradePeru",
    },
    {
      tag: "diaSemanaTradePeru",
      title: "DiaSemanaTradePeru",
    },
    {
      tag: "intercambioTipo",
      title: "TipoTrade",
    },
    {
      tag: "cantidad",
      title: "Cantidad",
    },
    {
      tag: "activoIntercambio",
      title: "ActivoTradeado",
    },
    {
      tag: "precio",
      title: "Precio",
    },
    {
      tag: "tipoCambio",
      title: "TipoCambio",
    },
    {
      tag: "parIntercambio",
      title: "ParTradeado",
    },
    {
      tag: "montoIntercambio",
      title: "MontoTradeado",
    },
    {
      tag: "activoRecibeCliente",
      title: "ActivoRecibido",
    },
    {
      tag: "montoIntercambioUsd",
      title: "MontoTradeadoUSD",
    },
    {
      tag: "porcentajeComision",
      title: "PorcentajeComision",
    },
    {
      tag: "montoComision",
      title: "MontoComision",
    },
    {
      tag: "activoRecibeComision",
      title: "ActivoComision",
    },
    {
      tag: "comisionExpresadoUsd",
      title: "ComisionEnUSD",
    },
    {
      tag: "comisionExpresadoUsdSinIgv2",
      title: "ComisionEnUSDSinIGV",
    },
    {
      tag: "comisionExpresadoPen",
      title: "ComisionEnPEN",
    },
    {
      tag: "comisionExpresadoPenSinIgv",
      title: "ComisionEnPENSinIGV",
    },
    {
      tag: "rango",
      title: "Rango",
  },
  {
    tag: "transactionId",
    title: "TransactionID",
  },
  {
    tag: "orderId",
    title: "OrderID",
  },
  {
    tag: "executionId",
    title: "ExecutionID",
  }
  ];

  const filters = [
    {
      values: {
        title: "Desde",
        name: "initDate",
        value: filterInputs.initDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Hasta",
        name: "endDate",
        value: filterInputs.endDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="beta-content-container">
            <div className="all-page-data">
               <HeaderPage
                title="Trade - Reporte de trade"
                subTitle="Reportes / Reporte general / Trade - Reporte de trade"
                headerOptions={headerOptions}
              />
              <TableContainer
                rows={rows}
                loading={loading}
                headers={tableHeaders}
                footers={!isEmpty(rows) && !loading ? tableFooters : null}
                rowIdSelected={""}
                enablePagination={false}
              >
                <GroupFilters
                  handleSearchFilters={handleSearchFilters}
                  withRightSection={false}
                  inputs={filters}
                />
              </TableContainer>
            </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user:{userInfo},product:{ decimalPlaces } }) => {
    return { userInfo,decimalPlaces };
  };

  
ReportTrades.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ReportTrades);
