import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import closeIcon from "../../../images/new_icons/close-circle.svg";
import AllRestrictedDomainsData from "../../../components/FallComponents/RestrictedDomainsPage/AllRestrictedDomainsData";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import { useFilter } from "../../../hooks/useFilter";
import RestrictedDomainsController from "../../FallControllers/RestrictedDomainsController";
import { useModal } from "../../../hooks/useModal";
import AddModal from "../../../components/FallComponents/RestrictedDomainsPage/AddModal";
import RestrictedDomainsDetails from "../../../components/FallComponents/RestrictedDomainsPage/RestrictedDomainsDetails";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import { connect } from "react-redux";
import DateHelper from "../../../helpers/Date.helper";
import xlsx from "json-as-xlsx";

const DisposableDomains = (props, context) => {
  const { userInfo } = props;
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [withRightSection, setWithRightSection] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedData, setSelectedData] = useState({});
  const [errors, setErrors] = useState({});
  const { showModal: showAddModal, handleToggleModal: handleToggleAddModal } =
    useModal();
  const {
    showModal: showConfirmModal,
    handleToggleModal: handleToggleConfirmModal,
  } = useModal();
  const [action, setAction] = useState({});

  const actions = {
    delete: {
      title: "Borrar dominio",
      acceptedAction: async () => {
        const response = await RestrictedDomainsController.delete({
          userInfo,
          setLoadingAction,
          id: selectedData.id,
        });
        if (response) {
          handleToggleConfirmModal();
          handleGetAll();
          setWithRightSection(false);
        }
      },
      description: "¿Está seguro que desea borrar el dominio?",
    },
    cancel: {
      title: "Salir sin guardar cambios",
      acceptedAction: () => {
        handleToggleConfirmModal();
        setWithRightSection(false);
        setEditable(false)
      },
      description: "¿Está seguro que desea salir sin guardar cambios?",
    },
    updateStatus: {
      title: "Cambiar estado",
      acceptedAction: () => { },
      description: "¿Está seguro que desea cambiar el estado del dominio?",
    },
  };
  const {
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
    resetFilters,
    filterSearch,
    setFilterSearch,
  } = useFilter({ totalRows });

  const handleSelectRow = ({ row }) => {
    setSelectedData(row);
    setWithRightSection(true);
  };

  const handleToggleStatusByCheckbox = async (id) => {
    const data = (rows.find((item) => item.id === id) || {})
    const response = await RestrictedDomainsController.update({
      userInfo,
      setLoadingAction,
      id,
      data: {
        domain: data.domain,
        status: !data.status,
      },
    });
    if (response) {
      handleToggleConfirmModal();
      handleGetAll();
    }
  };
  const handleGetAll = () => {
    RestrictedDomainsController.getAll({
      setLoading,
      userInfo,
      setTotalRows,
      setRows,
      filterConfig,
      filterSearch,
    });
  };

  const handleCreateNewDomain = async (domain) => {
    const response = await RestrictedDomainsController.create({
      userInfo,
      setLoadingAction,
      data: { domain },
      setErrors
    });
    if (response) {
      handleToggleAddModal();
      handleGetAll();
    }
  };

  const handleUpdateDomain = async (data) => {
    const response = await RestrictedDomainsController.update({
      userInfo,
      setLoadingAction,
      data,
      id: selectedData.id,
    });
    if (response) {
      setWithRightSection(false);
      handleGetAll();
    }
  };

  const downloadExcelFormat = (tableHeaders) => {
    const columns = tableHeaders.map(item => ({ value: item.tag, label: item.title }))
    const data = [
      {
        sheet: "Datos",
        columns,
        content: rows
      }
    ];
    const settings = {
      fileName: `dominios_restringidos${DateHelper.getCurrentDate()}`,
      extraLength: 10,
      writeOptions: {}
    };
    xlsx(data, settings)
  }

  const handleDeleteDomain = async (id) => {
    setAction(actions.delete);
    handleToggleConfirmModal();
  };

  const handleClose = () => {
    if (editable) {
      setAction(actions.cancel);
      handleToggleConfirmModal();
    } else {
      setWithRightSection(false);
    }
  };

  const handleToggleStatus = (id) => {
    setAction({
      ...actions.updateStatus,
      acceptedAction: async () => await handleToggleStatusByCheckbox(id),
    });
    handleToggleConfirmModal();
  };

  const headerOptions = [
    {
      image: closeIcon,
      alt: "Close option",
      action: handleClose,
    },
  ];

  useEffect(() => {
    if (!isEmpty(userInfo)) handleGetAll();
  }, [userInfo, filterConfig, filterSearch]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        handleClose();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <React.Fragment>
      <div className="beta-content-container">
        <ResizableColumns
          withRightSection={withRightSection}
          minWidth="500px"
          childrenOne={
            <AllRestrictedDomainsData
              handleSelectRow={handleSelectRow}
              loading={loading}
              totalRows={totalRows}
              onChangeLimit={onChangeLimit}
              filterConfig={filterConfig}
              offset={offset}
              showingTo={showingTo}
              changePage={changePage}
              withRightSection={withRightSection}
              setWithRightSection={setWithRightSection}
              context={context}
              setFilterSearch={setFilterSearch}
              resetFilters={resetFilters}
              rows={rows}
              handleToggleAddModal={handleToggleAddModal}
              handleToggleStatus={handleToggleStatus}
              downloadExcelFormat={downloadExcelFormat}
            />
          }
          childrenTwo={
            <RightResizable headerOptions={headerOptions}>
              <RestrictedDomainsDetails
                data={selectedData}
                handleUpdateDomain={handleUpdateDomain}
                handleDeleteDomain={handleDeleteDomain}
                editable={editable}
                setEditable={setEditable}
              />
            </RightResizable>
          }
        />
      </div>
      {showAddModal && (
        <AddModal
          showModal={showAddModal}
          handleToggleAddModal={handleToggleAddModal}
          addDomain={handleCreateNewDomain}
          loading={loadingAction}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      <ConfirmModal
        showModal={showConfirmModal}
        handleToggleModal={handleToggleConfirmModal}
        title={action.title}
        message={action.description}
        confirmButtonProps={{
          onClick: action.acceptedAction,
          text: "Sí",
          customClassName: "delete-comment-button",
        }}
        cancelButtonProps={{
          text: "No",
          customClassName: "delete-comment-button",
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

DisposableDomains.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DisposableDomains);
