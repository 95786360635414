import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { CustomButton } from "../CustomButton/CustomButton";
import InputElement from "../InputElement/InputElement";
import FxRatesController from "../../../pages/FallControllers/FxRatesController";
import { currencies } from "../../../constants";

const isFixedValues = [
  {
    value: "true",
    name: "Si",
  },
  {
    value: "false",
    name: "No",
  },
]
function AddRatesForm(props, context) {
  const { handleToggleModal, data,userInfo,reloadFunctions } = props;
  const [inputs, setInputs] = useState({
    base_currency: "USD",
    base_rate: 1,
  });
  const [errors, setErrors] = useState({});
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const handleInputChange = (target) => {
    setInputs({ ...inputs, [target.name]: target.value });
    delete errors[target.name];
    if(showAlertMessage){
      setShowAlertMessage(false)
    }
  };

  const validationToAddNewRate = () => {
    let findCurrentRate = {};

    if (isEmpty(data.lastRatesRows)) return;
    findCurrentRate = data.lastRatesRows.find((rate) => rate.currency == inputs.currency);

    if (isEmpty(findCurrentRate)) return;
    const currentRate = findCurrentRate.rate;
    const maxPercentageChange = 3;
    const diffRate = currentRate - inputs.rate;
    let diffRatePercentage = (diffRate * 100) / currentRate;
    diffRatePercentage =
      diffRatePercentage < 0 ? diffRatePercentage * -1 : diffRatePercentage;

    return diffRatePercentage < maxPercentageChange;
  };

  const addNewRate = async () => {
    let response = await FxRatesController.addNewRate({userInfo,data:inputs,setLoading});
    if (response) {
      handleToggleModal();
      reloadFunctions.getHistoricalRates()
      reloadFunctions.getLastRates()
    }
  };

  const customValidations = () => {
    let errors = {};
    if (isEmpty(inputs.currency)) {
      errors.currency = "Campo requerido";
    }
    if (isEmpty(inputs.rate)) {
      errors.rate = "Campo requerido";
    }
    setErrors(errors);
    return errors;
  };

  const handleAddNewRate = () => {
    const errors = customValidations();
    if (!isEmpty(errors)) return;
    let validationToAddNewRateValue = true
    if(!showAlertMessage){
       validationToAddNewRateValue = validationToAddNewRate()
    }
    if(!validationToAddNewRateValue){
      return setShowAlertMessage(true)
    }
    addNewRate()
  };

  const productTypeInputProps = {
    values: {
      title: "Elige producto",
      value: inputs.currency,
      name: "currency",
      type: "select",
      required: true,
      options:currencies,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.currency,
  };
  const activeInputProps = {
    values: {
      title: "¿Bloquear tipo de cambio?",
      value: inputs.currency,
      name: "is_fixed",
      type: "select",
      required: true,
      options: isFixedValues,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.currency,
  };
  const valueInputProps = {
    values: {
      title: "Ingresa nuevo valor",
      value: inputs.rate,
      name: "rate",
      type: "number",
      required: true,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.rate,
  };

  return (
    <div>
      <div className="add-modal-inputs">
        <InputElement {...productTypeInputProps} />
        <br />
        <InputElement {...valueInputProps} />
        <br />
        <InputElement {...activeInputProps} />
      </div>
      {showAlertMessage && (
        <p className="add-rate-alert-message">
          {context.t(
            "Important: The new rate has been change over/under on 3% please confirm this change."
          )}
          <br/>
          <b>
            {" "}
            {context.t("This change may disable the remarketing service.")}
          </b>
        </p>
      )}

      <div className="container-submit-cancel-buttons">
        <CustomButton
          onClick={handleToggleModal}
          className="red"
          text="Cancelar"
          disabled={loading}
          showLoader={false}
        />
        <CustomButton
          text={showAlertMessage ? "Confirmar" : "Guardar"}
          className="green"
          onClick={handleAddNewRate}
          disabled={loading}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};
AddRatesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(AddRatesForm);
