import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import MoneySend from "../../../images/new_icons/money-send.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import ReceiptLight from "../../../images/new_icons/receipt-search-light.svg";
import Receipt from "../../../images/new_icons/receipt-search.svg";
import DateHelper from "../../../helpers/Date.helper";
import { GroupFilters } from "../Filters/Filters";
import { banksListTransactions, fiatCurrencyList, toastTypes, transactionsStatus, transactionTypes } from "../../../constants";
import statusComponent from "../StatusComponent/StatusComponent";
import "./allBankTransactionsData.css";
import BankButtons from "./BankButtons";
import DataFormatterHelper from "../../../helpers/DataFormatter.helper";
import Download from "../../../images/new_icons/download.svg";
import moment from "moment";
import UtilsHelper from "../../../helpers/Utils.helper";

const AllBankTransactionsData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    handleSelectRow,
    selectedId,
    setFilterSearch,
    balance,
    syncBankTransactions,
    restartData,
    downloadRows,
    total,
    handleActionSendFeeTransactions,
    editPermission
  } = props;
  const [filterInputs, setFilterInputs] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const [showFilters, setShowFilters] = useState(false);
  const [showTransactionDetails, setTransactionDetails] = useState(false);
  const handleShowFilters = () => setShowFilters(!showFilters);
  const handleTransactionDetails = () => setTransactionDetails(!showTransactionDetails);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: showTransactionDetails ? ReceiptLight : Receipt,
      action: handleTransactionDetails,
      className: showTransactionDetails ? "header-option-active" : "",
    },
    {
      icon:MoneySend,
      action: handleActionSendFeeTransactions,
      className: "",
      visible: editPermission
    },
    {
      icon: Download,
      action: downloadRows,
    },
    {
      icon: RotateRight,
      action: () => {
        setFilterInputs({
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
          amount: "",
        });
        restartData()
      },
    },
  ];

  const handleSearchFilters = () => {
    const filtersToSend = { ...filterInputs };
    Object.keys(filterInputs).forEach((item) => {
      if (isEmpty(filterInputs[item])) {
        delete filtersToSend[item];
      }
    });
    setFilterSearch(filtersToSend);
  };

  const handleClaimDetails = (row, key) => {
    let response = '-';
    if (!isEmpty(row.claimDetails)) {
      const detailsBase64 = row.claimDetails;
      let detailsJson = "";
      let detailsJsonString = new Buffer.from(detailsBase64, "base64").toString("ascii");
      detailsJsonString = detailsJsonString.replace(/[^\x20-\x7E]+/g, "");
      try {
        detailsJson = JSON.parse(detailsJsonString);
      } catch (error) { }
      const type = !isEmpty(detailsJson.admin) ? 'admin' : !isEmpty(detailsJson.deposit) ? 'deposit' : !isEmpty(detailsJson.withdrawal) ? 'withdrawal' : '';
      if (!isEmpty(type) && !isEmpty(detailsJson) && !isEmpty(detailsJson[type])) {
        response = !isEmpty(detailsJson[type][key]) ? detailsJson[type][key] : '-';
      }
    }
    return response
  }

  const payloadConstructor = (row) => {
    let result = [];
    const dateClaim = DateHelper.invertedYearFormat(row.claimDate);

    if (!isEmpty(row.claimDetails)) {
      const detailsBase64 = row.claimDetails;
      let detailsJson = "";
      let detailsJsonString = new Buffer.from(detailsBase64, "base64").toString(
        "ascii"
      );
      detailsJsonString = detailsJsonString.replace(/[^\x20-\x7E]+/g, "");

      try {
        detailsJson = JSON.parse(detailsJsonString);
      } catch (error) {
      }

      if (!isEmpty(detailsJson) && !isEmpty(detailsJson.deposit)) {
        result.push(
          <div>
            <p>Deposit Ticket: {detailsJson.deposit.TicketNumber}</p>
            <p style={{ fontStyle: "italic" }}>
              {!isEmpty(detailsJson.user)
                ? `${detailsJson.user.UserName} - `
                : ""}
              {dateClaim}
            </p>
          </div>
        );
      }

      if (!isEmpty(detailsJson) && !isEmpty(detailsJson.withdrawal)) {
        result.push(
          <div>
            <p>Withdraw Ticket: {detailsJson.withdrawal.TicketNumber}</p>
            <p style={{ fontStyle: "italic" }}>
              {!isEmpty(detailsJson.user)
                ? `${detailsJson.user.UserName} - `
                : ""}
              {dateClaim}
            </p>
          </div>
        );
      }

      if (
        !isEmpty(detailsJson) &&
        !isEmpty(detailsJson.admin) &&
        detailsJson.admin.type === "FEE" &&
        !isEmpty(detailsJson.admin.user)
      ) {
        result.push(
          <div>
            <p style={{ fontStyle: "italic" }}>
              {detailsJson.admin.user.UserName} - {dateClaim}
            </p>
          </div>
        );
      }

      if (
        !isEmpty(detailsJson) &&
        !isEmpty(detailsJson.admin) &&
        detailsJson.admin.type == "FEE" &&
        isEmpty(detailsJson.admin.user)
      ) {
        result.push(
          <div>
            <p style={{ fontStyle: "italic" }}>{dateClaim}</p>
          </div>
        );
      }

      if (
        !isEmpty(detailsJson) &&
        !isEmpty(detailsJson.admin) &&
        detailsJson.admin.type == "ADMIN" &&
        !isEmpty(detailsJson.admin.user)
      ) {
        const category = detailsJson.admin.category;
        if(category === 'DEPOSIT' || category === 'WITHDRAW'){
          result.push(
            <div>
              <p style={{ fontStyle: "italic" }}>
                {UtilsHelper.firtsLetterInUpperCase(category)} Ticket: {detailsJson.admin.payment_number}
              </p>
              <p style={{ fontStyle: "italic" }}>
                {detailsJson.admin.user.UserName} - {dateClaim}
              </p>
            </div>
          );
        } else {
          result.push(
            <div>
              <p style={{ fontStyle: "italic" }}>
                {detailsJson.admin.category} - {detailsJson.admin.message}
              </p>
              <p style={{ fontStyle: "italic" }}>
                {detailsJson.admin.user.UserName} - {dateClaim}
              </p>
            </div>
          );
        }
      }

      if (
        !isEmpty(detailsJson) &&
        !isEmpty(detailsJson.admin) &&
        detailsJson.admin.type == "ADMIN" &&
        isEmpty(detailsJson.admin.user)
      ) {
        result.push(
          <div>
            <p style={{ fontStyle: "italic" }}>
              {detailsJson.admin.category} - {detailsJson.admin.message}
            </p>
            <p style={{ fontStyle: "italic" }}>{dateClaim}</p>
          </div>
        );
      }
    }

    let extraData = "";
    if (!isEmpty(row.accountDetails)) {
      extraData =
        `- ${row.accountDetails.lastName} ${row.accountDetails.secondLastName}, ${row.accountDetails.firstName} ${row.accountDetails.middleName}`.toUpperCase();
    }

    return (
      <div>
        <p>
          {row.payload}
          {extraData}
        </p>
        {result}
      </div>
    );
  };

  const creditAmountConstructor = (row) => {
    let amount = "-";
    if (row.transactionType === "CREDIT") {
      amount = DataFormatterHelper.currencyFormatter({
        currency: row.currency,
        amount: parseFloat(row.transactionAmount),
      });
    }

    if (row.transactionType === "TOTAL") {
      amount = DataFormatterHelper.currencyFormatter({
        currency: row.currency,
        amount: parseFloat(row.transactionAmountCredit),
      });
    }

    return <p>{amount}</p>;
  };
  const debitAmountConstructor = (row) => {
    let amount = "-";

    if (row.transactionType === "DEBIT") {
      amount = DataFormatterHelper.currencyFormatter({
        currency: row.currency,
        amount: parseFloat(row.transactionAmount),
      });
    }

    if (row.transactionType === "TOTAL") {
      amount = DataFormatterHelper.currencyFormatter({
        currency: row.currency,
        amount: parseFloat(row.transactionAmountDebit),
      });
    }

    return <p>{amount}</p>;
  };

  const generateSelector = row => {
    return (
      <label>
        <input
          className="input-check-select-bank-transaction"
          type="checkbox"
          name={row.id}
          hidden
        />
        <div className="input-radio" />
      </label>
    );
  };

  const validateDate = (date, other) => {
    let newDate = date;
    if (newDate === '-') newDate = other;
    return DateHelper.formatDateToYear(newDate);
  }

  const tableHeaders = [
    {
      tag: 'selected',
      title: ' ',
      cell: ({ data }) => generateSelector(data),
      className: 'minimizer no-Selection'
    },
    {
      tag: "transactionDate",
      title: "Fecha de transacción",
      cell: ({ data }) => DateHelper.formatDateToYear(data.transactionDate),
      handleSelectRow,
    },
    {
      tag: "bankCode",
      title: "Banco",
      handleSelectRow,
    },
    {
      tag: "sourceBankAccountNumber",
      title: "Número de cuenta",
      handleSelectRow,
    },
    {
      tag: "payload",
      title: "Detalles",
      cell: ({ data }) => payloadConstructor(data),
      handleSelectRow,
    },
    {
      tag: "transactionAmountCredit",
      title: "Crédito",
      cell: ({ data }) => creditAmountConstructor(data),
      handleSelectRow,
    },
    {
      tag: "transactionAmountDebit",
      title: "Débito",
      cell: ({ data }) => debitAmountConstructor(data),
      handleSelectRow,
    },

    {
      tag: "",
      title: "Fecha de documento",
      cell: ({ data }) => validateDate(handleClaimDetails(data, 'document_date'), data.transactionDate),
      handleSelectRow,
    },
    {
      tag: "",
      title: "Fecha de vencimiento",
      cell: ({ data }) => validateDate(handleClaimDetails(data, 'due_date'), data.transactionDate),
      handleSelectRow,
    },
    {
      tag: "",
      title: "Número de comprobante de pago",
      cell: ({ data }) => handleClaimDetails(data, 'payment_number'),
      handleSelectRow,
    },
    {
      tag: "",
      title: "Tipo de documento de la operación",
      cell: ({ data }) => handleClaimDetails(data, 'document_operation'),
      handleSelectRow,
    },
    {
      tag: "",
      title: "Tipo de documento de identidad",
      cell: ({ data }) => handleClaimDetails(data, 'identification_document_type'),
      handleSelectRow,
    },
    {
      tag: "",
      title: "Documento de identidad",
      cell: ({ data }) => handleClaimDetails(data, 'identification_document'),
      handleSelectRow,
    },
    {
      tag: "",
      title: "Nombre o razón social",
      cell: ({ data }) => handleClaimDetails(data, 'name'),
      handleSelectRow,
    },


    {
      tag: "",
      title: "Estado",
      cell: ({ data }) =>
        statusComponent(data.status, false, transactionsStatus),
      handleSelectRow,
    },
  ];

  const filters = [
    {
      values: {
        title: "Desde",
        name: "from",
        value: filterInputs.from,
        type: "date",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Hasta",
        name: "to",
        value: filterInputs.to,
        type: "date",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Bancos",
        name: "bankCode",
        value: filterInputs.bankCode,
        type: "select",
        options:banksListTransactions,
        placeholder: "Seleccione un banco",
        isClearable: true,
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Moneda",
        name: "bankCurrency",
        value: filterInputs.bankCurrency,
        type: "select",
        options: fiatCurrencyList,
        placeholder: "Seleccione una moneda",
        required: true,
        isClearable: true,
        rootClassname: "min-width-filter-input-bank-transactions",
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Tipo de transacción",
        name: "bankTransactionType",
        value: filterInputs.bankTransactionType,
        type: "select",
        options:transactionTypes,
        placeholder: "Seleccione un tipo",
        isClearable: true,
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Estado",
        name: "status",
        value: filterInputs.status,
        type: "select",
        options: transactionsStatus.map(item => ({...item,value:item.id})),
        placeholder: "Seleccione un estado",
        isClearable: true,
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Monto",
        name: "amount",
        value: filterInputs.amount,
        type: "number",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
  ];

  return (
    <div className="all-container-data-admin-v2 bank-transactions-data">
      <HeaderPage
        title={context.t("Transacciones bancarias")}
        subTitle="Finanzas / Transacciones bancarias"
        headerOptions={headerOptions}
      />
      <div className="totals-buttons-bank-transactions">
        {
          showTransactionDetails && (
            <>
              <div className="totals-bank-transactions">
                <div>
                  {total.totalCredits.map((item) => (
                    <p>
                      Total crédito ({item.currency}):{" "}
                      {DataFormatterHelper.currencyFormatter({
                        currency: item.currency,
                        amount: item.total,
                      })}
                    </p>
                  ))}
                </div>
                <div>
                  {total.totalDebits.map((item) => (
                    <p>
                      Total debito ({item.currency}):{" "}
                      {DataFormatterHelper.currencyFormatter({
                        currency: item.currency,
                        amount: item.total,
                      })}
                    </p>
                  ))}
                </div>
              </div>
              <BankButtons
                balance={balance}
                syncBankTransactions={syncBankTransactions}
                editPermission={editPermission}
              /></>
          )
        }
      </div>
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}
      >
        {showFilters && (
          <GroupFilters
            inputs={filters}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllBankTransactionsData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllBankTransactionsData);
