import React, { useContext, useEffect, useState } from 'react';
import InputElement from '../../InputElement/InputElement';
import editableicon from '../../../../images/new_icons/vuesax-linear-user-edit.svg';
import { Context } from '../../../../pages/FallPages/Customers/Natural/ProviderLevels';
import { CustomButton } from '../../CustomButton/CustomButton';
import { Form } from 'react-bootstrap';
import { useForm } from '../../../../hooks/formHooks';
import isEmpty from 'is-empty';
import NaturalCustomersController from '../../../../pages/FallControllers/NaturalCustomersController';
import DateHelper from '../../../../helpers/Date.helper'
import { CancelToken } from 'axios';
import { WARNING_STATUS } from '../../../../constants';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import { useModal } from '../../../../hooks/useModal';
import moment from 'moment';

export const RegisterData = ({ userInfo, getUserDataByIdWithLogs, editPermission }) => {
  const { userInfo: newUserInfo,warningStatus } = useContext(Context);
  const { userData, userId } = newUserInfo;
  const [loadingMigrations, setLoadingMigrations] = useState(false);
  const [initialData, setInitialData] = useState({
    dataApellidos: '',
    dataNombres: ''
  });
  const [action, setAction] = useState({
    title:"",
    message:"",
    function: () => {}
  })
  const { showModal: showActionModal, handleToggleModal: handleToggleActionModal } = useModal();

  const onClickCancel = () => {
    setInputs({
      ...inputs,
      ...initialData
    });
  };

  const onSubmitCallback = async inputs => {
    await NaturalCustomersController.updateRegisterData({
      userInfo,
      userId,
      inputs
    });
    getUserDataByIdWithLogs();
  };

  let { inputs, setInputs, onInputChangeV2 } = useForm(
    {},
    onSubmitCallback,
    () => {}
  );

  const sendDataToMigrations = async () => {
    const response = await NaturalCustomersController.sendToMigrations({userInfo,levelId:userId,data:inputs,setLoadingMigrations,source})
    if(response){
      getUserDataByIdWithLogs()
    }
  }

  const validExpirationDocumentDate = (registerFields) => {
    if(!registerFields) return null;
    if (registerFields && registerFields.documentExpirationDate) {
      const parsedDate = moment(registerFields.documentExpirationDate, ['YYYY-MM-DD', 'DD/MM/YYYY']);
      return parsedDate.isValid() ? parsedDate.format('DD/MM/YYYY') : registerFields.documentExpirationDate;
    }
  }

  const [source,setSource] = useState(null)

  const blakcListValue = (WARNING_STATUS.find(item => item.status === warningStatus) || {}).title

  const formattedDate = validExpirationDocumentDate(userData.registerFields);
  
  const handleActionToSave = (ev) => {
    ev.preventDefault();
    setAction({
      title:"Datos de registro",
      message:"¿Está seguro que desea guardar los datos de registro?",
      function: async (ev) => {
        await onSubmitCallback(inputs)
      }
    })
    handleToggleActionModal()
  }
  
  useEffect(() => {
      const source = CancelToken.source();
      setSource(source)
    if (!isEmpty(userData.registerFields)) {
      inputs = {
        ...inputs,
        banexcoinUserAccountId: userData.registerFields.banexcoinUserAccountId,
        birthCountry: userData.registerFields.birthCountry,
        ceBirthday: userData.registerFields.ceBirthday,
        dataApellidos: userData.registerFields.dataApellidos,
        dataNombres: userData.registerFields.dataNombres,
        documentNumber: userData.registerFields.documentNumber,
        documentType: userData.registerFields.documentType,
        email: userData.banexcoinUserByBanexcoinUserId.email,
        residenceCountry: userData.registerFields.residenceCountry
      };
      setInputs(inputs);
      setInitialData({
        dataApellidos: userData.registerFields.dataApellidos,
        dataNombres: userData.registerFields.dataNombres
      });
    }

    return function() {
      source.cancel();
    };
  }, []);

  return (
    <div className="content-tab">
      {!isEmpty(userData.registerFields) ? (
        <Form
          noValidate
          validated={false}
          encType="multipart/form-data"
          onSubmit={handleActionToSave}
          id="form-admin-levels"
          style={{ overflow: 'auto' }}
          className="content-tab">
          <div className="user-blocked-grid">
            {editPermission ?
              <InputElement
                values={{
                  title: 'Nombres',
                  name: 'dataNombres',
                  value: inputs.dataNombres,
                  type: 'text',
                  icon: editableicon
                }}
                actions={{ onChange: onInputChangeV2 }}
              /> : 
              <div className="item-content-info">
                <p>Nombres</p>
                <p>{inputs.dataNombres}</p>
              </div>
            }
            {editPermission ?
              <InputElement
                values={{
                  title: 'Apellidos',
                  name: 'dataApellidos',
                  value: inputs.dataApellidos,
                  type: 'text',
                  icon: editableicon
                }}
                actions={{ onChange: onInputChangeV2 }}
              />: 
              <div className="item-content-info">
                <p>Apellidos</p>
                <p>{inputs.dataApellidos}</p>
              </div>
            }
            <div className="item-content-info">
              <p>Account ID</p>
              <p>{inputs.banexcoinUserAccountId}</p>
            </div>
            <div className="item-content-info">
              <p>Numero de documento</p>
              <p>{inputs.documentNumber}</p>
            </div>
            <div className="item-content-info">
              <p>Blacklist</p>
              <p>{blakcListValue || "Sin advertencia"}</p>
            </div>
            <div className="item-content-info">
              <p>Fecha de expiración de doc</p>
              <p>
                {(userData.registerFields &&
                  formattedDate) ||
                  '-'}
              </p>
            </div>
            <div className="item-content-info">
              <p>Creado el</p>
              <p>{DateHelper.formatDate(userData.createdAt)}</p>
            </div>
            <div className="item-content-info">
              <p>Documento de calidad migratoria</p>
              <p>
                {(userData.registerFields &&
                  userData.registerFields.documentMigrationQuality) ||
                  '-'}
              </p>
            </div>
            <div className="item-content-info">
              <p>Emisión de último documento</p>
              <p>
                {(userData.registerFields &&
                  userData.registerFields.documentLastEmission) ||
                  '-'}
              </p>
            </div>
            <div className="item-content-info">
              <p>Email</p>
              <p>{inputs.email}</p>
            </div>
          </div>
          {editPermission &&
            <div className="buttons-container">
              <div className="buttons-left">
                <CustomButton text="Guardar" type="submit" className="green" />
                <CustomButton
                  text="Cancelar"
                  type="button"
                  onClick={onClickCancel}
                  className="red"
                />
              </div>
              {userData.registerFields && userData.registerFields.documentType === "RP" && (
                <CustomButton
                text="Obtener datos de migraciones"
                type="button"
                disabled={loadingMigrations}
                onClick={sendDataToMigrations}
                className="orange"
                customClassName="migrations-button"
                />
              )}
            </div>
          }
        </Form>
      ) : (
        <h4 style={{ padding: '10px' }}>
          No hay información de registro de usuario
        </h4>
      )}
      <ConfirmModal
        showModal={showActionModal}
        handleToggleModal={() => {
          handleToggleActionModal();
        }}
        title={action.title}
        message={action.message}
        confirmButtonProps={{
          onClick: action.function,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};
