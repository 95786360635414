import isEmpty from "is-empty"
import { createComment, deleteComment, editComment, getComments } from "../FallServices/CommentsService"
import ComponentsHelper from '../../helpers/Components.helper'
class CommentsController {
    static createNewComment = async ({userInfo,levelId,data}) => {
        try {
            const response = await createComment(userInfo.UserId,levelId,data) 
            if(isEmpty(response.data)){
                ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
                return false
            }           
            ComponentsHelper.toast({type:"success",message:"Comentario enviado exitosamente."})
            return true
        } catch (error) {
            ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
            return false
        }
    }
    static getAllComments = async ({userInfo,levelId,setComments,type, filterDataCallback}) => {
        try {
            const response = await getComments(userInfo.UserId,levelId)  
            if(!response.hasOwnProperty("data")){
                ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
                return false
            }
            let data = response.data.reverse()
            if(type === "aml-deposit"){
                data = data.filter(item => new Date(item.createdAt).getMonth() >= new Date(Date.now()).getMonth()).map(item => {
                    const comment = CommentsController.parsedComment(item.message)
                    return {
                        ...item,
                        message: !isEmpty(comment) ? comment : item.message
                    }
                })
            }
            if(filterDataCallback){
                data = filterDataCallback(data)
            }
            setComments(data)           
            return true          
        } catch (error) {
            ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
            return false
        }
    }
    static deleteCommentById = async ({userInfo,commentId}) => {
        try {
            const response = await deleteComment(userInfo.UserId,commentId)
            if(isEmpty(response.data)){
                ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
                return false
            }
            ComponentsHelper.toast({type:"success",message:"Comentario eliminado exitosamente."})
            return true
        } catch (error) {
            ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
            return false
        }
    }
    static editCommentById = async ({userInfo,commentId,comment}) => {
        try {
            const response = await editComment(userInfo.UserId,commentId,comment)
            if(isEmpty(response.data)){
                ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
                return false
            }
            ComponentsHelper.toast({type:"success",message:"Comentario editado exitosamente."})
            return true
        } catch (error) {
            ComponentsHelper.toast({type:"warn",message:"Ha ocurrido un error. Por favor, vuelva a intentarlo."})
            return false
        }
    }
    static parsedComment = (comment) => {
        try {
            return JSON.parse(comment)
        } catch (error) {
            return ""
        }
    }
}

export default CommentsController