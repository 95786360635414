import React, { useState } from 'react';
import { EditableText } from '../../FallComponents/EditableText/EditableText';
import edit from '../../../images/new_icons/edit.svg';
import check from '../../../images/new_icons/check.svg';
import cross from '../../../images/new_icons/cross.svg';

const InformationSection = ({ data, inputs, onChange, checkReturnData, onInputFileChange, handleImagesToZoom, enableButtons = true, context }) => {

   const { title, resetValues, submitData, fields } = data;

   const [editable, setEditable] = useState(false);

   const changeEdit = () => setEditable(editable => !editable);

   const handleSubmit = () => {
      changeEdit();
      submitData();
   };

   const handleResetValues = () => {
      changeEdit();
      resetValues(fields);
   }

   return (
      <div>
         <div className="content-level-title with-img">
            <h2>{title}</h2>
            {enableButtons && 
               <React.Fragment>
                  <button type='button' onClick={() => !editable ? changeEdit() : handleSubmit()}>
                     <img src={!editable ? edit : check} alt="edit image" />
                  </button>
                  {editable && (
                     <button type="button" onClick={handleResetValues}>
                        <img src={cross} alt="cross" style={{ marginLeft: '10px' }} />
                     </button>
                  )}
               </React.Fragment>
            }
         </div>
         <div className="content-level-grid">
            {fields.map(({ label, name, type, titleFile, readOnly = false, options = [], visible = true, accept = '*', fileType = '', showCheckbox = true }, key) => {
               if (visible) {
                  return <EditableText
                     key={key}
                     status={1}
                     showCheckbox={showCheckbox}
                     onSetReturnDataInput={(e) => checkReturnData(e, label)}
                     type={type}
                     titleFile={titleFile}
                     editable={readOnly ? false : editable}
                     option={context.t(label)}
                     name={name}
                     value={(inputs[name] === 'undefined' || !inputs.hasOwnProperty(name)) ? '' : inputs[name]}
                     onChange={onChange}
                     onInputFileChange={onInputFileChange}
                     options={options}
                     accept={accept}
                     fileType={fileType}
                     handleImagesToZoom={handleImagesToZoom}
                  />
               }
            })}
         </div>
      </div >
   )
}

export default InformationSection