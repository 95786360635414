import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragHandleIcon from "../../../images/drag-icon.svg";
import DeleteIcon from "../../../images/delete-icon.svg";
import './dragAndDrop.css'
function DragAndDropContainer({ listComponents,accessToAction,Component,handleDeleteItem,handleAction }) {
  return (
    <DragDropContext
      onDragEnd={(param) => {
        if(!accessToAction) return 
        const srcI = param.source.index;
        const desI = param.destination
          ? param.destination.index
          : param.source.index;
        if (param.destination) {
          listComponents.splice(desI, 0, listComponents.splice(srcI, 1)[0]);
          handleAction()
        }
      }}
    >
      <div>
        <Droppable droppableId="droppable-1">
          {(provided, _) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {listComponents.map((item,i) => {
                const id = item.id
                return (
                  <Draggable
                    key={id}
                    draggableId={"draggable-" + id}
                    index={i}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} className="drag-item">
                        <div {...provided.dragHandleProps}>
                          {accessToAction && <img src={DragHandleIcon} alt="" />}
                        </div>
                        {Component(item,i)}
                        <div >
                          {accessToAction && <img src={DeleteIcon} alt="delete" width="16px" onClick={() => handleDeleteItem(item)} />}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default DragAndDropContainer;
