import UtilsHelper from "../../helpers/Utils.helper";

export const getAlerts = async (userId, filterConfig, filterSearch) => {
  const path = `/api/audit/get-all`;
  const params = {
    filterConfig,
    filterSearch,
  };
  const configRequest = {
    userId,
    method: "POST",
    path,
    data: params,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const getAlertById = async (userId, id) => {
  const path = `/api/audit/${id}`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const getAmlCodes = async (userId) => {
  const path = `/api/aml-codes`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const getUserByBanexcoinId = async (userId) => {
  const path = `/api/${userId}/users`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

// faltan
export const updateEventDetailByEventId = async (userId, id, data) => {
  const path = `/api/audit/${id}`;
  const configRequest = {
    userId,
    method: "PUT",
    path,
    data,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const updateUserOnboardingStatus = async (userId, id, data, action) => {
  const path = `/api/audit/${action}-onboarding/${id}`;
  const configRequest = {
    userId,
    method: "PUT",
    path,
    data,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
