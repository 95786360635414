import React, { useEffect, useState, useContext } from "react";
import isEmpty from "is-empty";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import close from "../../../images/new_icons/close-circle.svg";
import { useFilter } from "../../../hooks/useFilter";
import BanexcoinAccountsController from "../../FallControllers/BanexcoinAccountsController";
import AllBanexcoinAccountsData from "../../../components/FallComponents/BanexcoinAccountsPage/AllBanexcoinAccountsData";
import Modal from "../../../components/FallComponents/BanexcoinAccountsPage/Modal";
import DataFormatterHelper from "../../../helpers/DataFormatter.helper";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import BanexcoinAccountDetails from "../../../components/FallComponents/BanexcoinAccountsPage/BanexcoinAccountDetails";
import { useModal } from "../../../hooks/useModal";
import RoleAccessHelper from "../../../helpers/RoleAccess.helper";
import { Context as ContextUser } from '../../../providers/UserProvider'
function BanexcoinAccountsPage(props, context) {
  const { userInfo } = props;
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [action, setAction] = useState("ADD");
  const [selectedId, setSelectedId] = useState(null);
  const [accountsToShowByStatus, setAccountsToShowByStatus] = useState([]);
  const [withRightSection, setWithRightSection] = useState(false);
  const { showModal, handleToggleModal } = useModal();
  const [accessToAction,setAccessToAction] = useState(false)
  const [countries, setCountries] = useState([]);
  const { handleWritePermission, modulesByUserProfile } = useContext(ContextUser);

  const getAllCountries = async () => {
    const countriesResponse =
      await BanexcoinAccountsController.getListCountries({
        setCountries,
        userInfo,
      });
    let countriesOptions = [];
    if (!isEmpty(countriesResponse)) {
      countriesOptions = countriesResponse.map((country) => {
        return { value: country.countryCode, name: country.countryName };
      });
    }
    setCountries(countriesOptions);
  };

  const actions = {
    EDIT: {
      title: "Editar cuenta bancaria - Banexcoin",
      buttonProps: {
        text: "Guardar datos",
        className: "green",
      },
    },
    ADD: {
      title: "Añadir cuenta bancaria - Banexcoin",
      buttonProps: {
        text: "Añadir cuenta bancaria",
        className: "purple",
      },
    },
  };
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
  } = useFilter({ totalRows });

  const getAllBanexcoinAccounts = async () => {
    setLoading(true);
    await BanexcoinAccountsController.getAllBanexcoinAccounts({
      userId: userInfo.UserId,
      filterConfig,
      setAccounts,
      setTotalRows,
      setAccountsToShowByStatus,
    });
    setLoading(false);
  };

  const handleSelectRow = ({ row }) => {
    setSelectedId(row.id);
    setWithRightSection(true);
  };

  const handleAddBanexcoinAccount = () => {
    setWithRightSection(false);
    handleToggleModal();
    setSelectedId(null);
  };

  const handleToggleActiveBnxAccount = async (isChecked, id) => {
    let newStatus = 0;
    let updateWasSuccessfully = true;
    if (!isChecked) {
      newStatus = 1;
      updateWasSuccessfully =
        await BanexcoinAccountsController.changeStateBanexcoinAccount({
          userId: userInfo.UserId,
          banexcoinAccountId: id,
          state: "activate",
        });
    } else {
      newStatus = 0;
      updateWasSuccessfully =
        await BanexcoinAccountsController.changeStateBanexcoinAccount({
          userId: userInfo.UserId,
          banexcoinAccountId: id,
          state: "deactivate",
        });
    }
    if (!updateWasSuccessfully) return;
    const updatedAccounts = accounts.map((item) => {
      if (item.id === id) {
        return { ...item, status: newStatus };
      }
      return item;
    });
    setAccounts(updatedAccounts);
    const updatedAccountsToShow = accountsToShowByStatus.map((item) => {
      if (item.id === id) {
        return { ...item, status: newStatus };
      }
      return item;
    });
    setAccounts(updatedAccounts);
    setAccountsToShowByStatus(updatedAccountsToShow);
  };

  const downloadRows = async () => {
    let dataWithDynamicFields = accountsToShowByStatus.map((account) => {
      let newAccountItems = { ...account };
      newAccountItems["bankName"] = account.swiftBankBySwiftBankId.bankName;
      newAccountItems["countryCode"] =
        account.swiftBankBySwiftBankId.countryCode;
      delete newAccountItems["accountDetails"];
      delete newAccountItems["accountMessage"];
      delete newAccountItems["accountRulesInstructions"];
      delete newAccountItems["swiftBankBySwiftBankId"];
      if (
        !isEmpty(account.accountDetails) &&
        !isEmpty(account.accountDetails.es)
      ) {
        account.accountDetails.es.forEach((item) => {
          newAccountItems[item.title.replaceAll(" ", "")] = item.value;
        });
      }
      return newAccountItems;
    });
    const url = window.URL.createObjectURL(
      new Blob([
        DataFormatterHelper.convertToCSV({ data: dataWithDynamicFields }),
      ])
    );
    const currentdate = new Date();
    const datetime =
      currentdate.getDate() +
      "_" +
      (currentdate.getMonth() + 1) +
      "_" +
      currentdate.getFullYear() +
      "___" +
      currentdate.getHours() +
      "_" +
      currentdate.getMinutes() +
      "_" +
      currentdate.getSeconds();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `banexcoin_accounts_${datetime}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const handleAccountsToShowByStatus = (ev) => {
    if (ev.target.checked) {
      setAccountsToShowByStatus(
        accounts.filter((account) => account.status === 1)
      );
      return;
    }
    setAccountsToShowByStatus(accounts);
  };

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
        setSelectedId(null);
      },
    },
  ];
  useEffect(() => {
    if (!isEmpty(userInfo) && !isEmpty(modulesByUserProfile)) {
      getAllBanexcoinAccounts();
      const access = handleWritePermission("cuentas-banexcoin");
      setAccessToAction(access)
      getAllCountries()
      }
  }, [userInfo, modulesByUserProfile]);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem('tokenDB');
      if (token) {
        const access = RoleAccessHelper.hasAccesstoAction("active_update_bnx_account")
        setAccessToAction(access)
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      <div
        className={
          localStorage.getItem("isBeta") === "true"
            ? "beta-content-container"
            : "content-container"
        }
      >
        <ResizableColumns
          withRightSection={withRightSection}
          minWidth={"585px"}
          childrenOne={
            <AllBanexcoinAccountsData
              context={context}
              accessToAction={accessToAction}
              setFilterSearch={() => {}}
              resetFilters={() => {}}
              onChangeLimit={onChangeLimit}
              filterConfig={filterConfig}
              filterSearch={filterSearch}
              totalRows={totalRows}
              rows={accountsToShowByStatus}
              loading={loading}
              offset={offset}
              showingTo={showingTo}
              changePage={changePage}
              handleSelectRow={handleSelectRow}
              selectedId={selectedId}
              handleToggleActiveBnxAccount={handleToggleActiveBnxAccount}
              handleAddBanexcoinAccount={handleAddBanexcoinAccount}
              getAllBanexcoinAccounts={getAllBanexcoinAccounts}
              downloadRows={downloadRows}
              setWithRightSection={setWithRightSection}
              handleAccountsToShowByStatus={handleAccountsToShowByStatus}
            />
          }
          childrenTwo={
            <RightResizable
              headerOptions={headerOptions}
              loadingData={{
                title: "Actualizando información",
                isLoading: false,
              }}
            >
              <BanexcoinAccountDetails
                userInfo={userInfo}
                data={accounts}
                action={action}
                handleToggleModal={handleToggleModal}
                title={actions["EDIT"].title}
                buttonProps={actions["EDIT"].buttonProps}
                selectedId={selectedId}
                accessToAction={accessToAction}
                getAllBanexcoinAccounts={getAllBanexcoinAccounts}
                countries={countries}
              />
            </RightResizable>
          }
        />
      </div>
      {showModal && (
        <Modal
          data={accounts}
          action={action}
          showModal={showModal}
          handleToggleModal={handleToggleModal}
          title={actions["ADD"].title}
          buttonProps={actions["ADD"].buttonProps}
          selectedId={selectedId}
          getAllBanexcoinAccounts={getAllBanexcoinAccounts}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { user: { userInfo } } = state
  return { userInfo };
};

BanexcoinAccountsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BanexcoinAccountsPage);
