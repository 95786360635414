import React from 'react';
import { ModalLayout } from '../../../components/FallComponents/Modal/ModalLayout';
import Cropper from 'react-easy-crop';

export const CropModal = ({
  handleActionCloseCropModal,
  showModalToCropImage,
  faceImage,
  crop,
  zoom,
  setCrop,
  onCropComplete,
  setZoom
}) => {
  return (
    <ModalLayout
      actionClose={handleActionCloseCropModal}
      showModal={showModalToCropImage}
      classGeneral='custom-modal-crop'>
      <div className="crop-container">
        <Cropper
          style="width: 500px; height: 500px; align:center"
          image={faceImage}
          crop={crop}
          zoom={zoom}
          aspect={10 / 14}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </ModalLayout>
  );
};
