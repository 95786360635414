import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import DateHelper from "../../helpers/Date.helper";
import { getAllBalanceReports, getAllBalanceReportsByDate } from "../FallServices/BalancesReportServices";

class BalanceReportsController{
    static getAllBalanceReports = async ({userInfo, setRows, setLoading}) => {
        try {
            setLoading(true);
            const response = await getAllBalanceReports(userInfo.UserId);
            setRows(response.data)
            return true
        } catch (error) {
            ComponentsHelper.toast({
              type: toastTypes.warn,
              message: "La información no pudo ser accesada",
            });
            return false;
          } finally {
            setLoading(false);
          }
    }
    static getBalanceReportsByDate = async ({userInfo, setRows, setLoading, date}) => {
        try {
            setLoading(true);
            const response = await getAllBalanceReportsByDate(userInfo.UserId,date);
            const rowsWithDateFormatted = response.data.map(item => {
              const createdAt = DateHelper.formatDateToYear(new Date(Number(item.createdTimestamp)).toDateString())
              delete item.createdTimestamp
              return {createdAt,...item}
            })
            setRows(rowsWithDateFormatted)
            return true
        } catch (error) {
            ComponentsHelper.toast({
              type: toastTypes.warn,
              message: "La información no pudo ser accesada",
            });
            return false;
          } finally {
            setLoading(false);
          }
    }
}

export default BalanceReportsController