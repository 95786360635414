import UtilsHelper from "../../helpers/Utils.helper";

export const getAllProviders  = async (userId) => {
    const path= `/api/provider`
    return await UtilsHelper.sendRequest({
        userId,
        method: "GET",
        path,
      });
}
export const createNewProvider  = async (userId,data) => {
    const path= `/api/provider`
    return await UtilsHelper.sendRequest({
        userId,
        method: "POST",
        path,
        data
      });
}

export const updateProvider  = async (userId,data,id) => {
    const path= `/api/provider/${id}`
    return await UtilsHelper.sendRequest({
        userId,
        method: "PUT",
        path,
        data
      });
}
export const deleteProvider  = async (userId,id) => {
    const path= `/api/provider/${id}`
    return await UtilsHelper.sendRequest({
        userId,
        method: "DELETE",
        path
      });
}
export const getProviderByRuc  = async (userId,ruc) => {
  const path= `/api/provider/ruc/${ruc}`
  return await UtilsHelper.sendRequest({
      userId,
      method: "GET",
      path,
    });
}