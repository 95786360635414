export const OPTIONS_STATUS_BLOCK = {
  "OPERATIONS":[
    {
      name: 'Baja Voluntaria',
      value: 'Baja Voluntaria'
    },
    {
      name: 'Por Lavado de Activos',
      value: 'Por Lavado de Activos'
    },
    {
      name: 'Por Terrorismo',
      value: 'Por Terrorismo'
    },
    {
      name: 'Por noticias vinculadas a LA/FT',
      value: 'Por noticias vinculadas a LA/FT'
    },
    {
      name: 'FATCA (US person)',
      value: 'FATCA (US person)'
    },
    {
      name: 'Supresión de datos personales',
      value: 'Supresión de datos personales'
    },
    {
      name: 'Duplicidad de cuentas',
      value: 'Duplicidad de cuentas'
    },
    {
      name: 'Usuario de Prueba',
      value: 'Usuario de Prueba'
    },
    {
      name: 'Otros (detallar)',
      value: 'others'
    }
  ],
  "SUPERADMIN":[
    {
      name: 'Baja Voluntaria',
      value: 'Baja Voluntaria'
    },
    {
      name: 'Por Lavado de Activos',
      value: 'Por Lavado de Activos'
    },
    {
      name: 'Por Terrorismo',
      value: 'Por Terrorismo'
    },
    {
      name: 'Por noticias vinculadas a LA/FT',
      value: 'Por noticias vinculadas a LA/FT'
    },
    {
      name: 'País sancionado por OFAC',
      value: 'País sancionado por OFAC'
    },
    {
      name: 'FATCA (US person)',
      value: 'FATCA (US person)'
    },
    {
      name: 'Supresión de datos personales',
      value: 'Supresión de datos personales'
    },
    {
      name: 'Duplicidad de cuentas',
      value: 'Duplicidad de cuentas'
    },
    {
      name: 'Usuario de Prueba',
      value: 'Usuario de Prueba'
    },
    {
      name: 'Otros (detallar)',
      value: 'others'
    }
  ],
  "COMPLIANCE":[
    {
      name: 'Baja Voluntaria',
      value: 'Baja Voluntaria'
    },
    {
      name: 'Por Lavado de Activos',
      value: 'Por Lavado de Activos'
    },
    {
      name: 'Por Terrorismo',
      value: 'Por Terrorismo'
    },
    {
      name: 'Por noticias vinculadas a LA/FT',
      value: 'Por noticias vinculadas a LA/FT'
    },
    {
      name: 'País sancionado por OFAC',
      value: 'País sancionado por OFAC'
    },
    {
      name: 'FATCA (US person)',
      value: 'FATCA (US person)'
    },
    {
      name: 'Supresión de datos personales',
      value: 'Supresión de datos personales'
    }
  ]
};