import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccordionMultiple from '../../../Accordion/AccordionMultiple';
import isEmpty from 'is-empty';
import { useForm } from '../../../../../hooks/formHooks';
import { identificationNumberType, isShareholder, IS_PEP, nationality, nonProfit, typeOfLegalPerson } from '../../../../../constants';
import { states } from '../../../../../config/staticWorldContent';
import { province } from '../../../../../config/staticPeruProv';
import { districts } from '../../../../../config/staticPeruDist';
import DataFormatterHelper from '../../../../../helpers/DataFormatter.helper';
import { CustomButton } from '../../../CustomButton/CustomButton';
import IncorrectInformationModal from '../Components/IncorrectInformationModal';
import InformationSection from '../../../InformationSection/InformationSection';
import KycIncorrectModal from '../../../NaturalCustomersPage/Components/KycIncorrectModal';

const Shareholder = (props, context) => {
   const { shareholders, countries, handleReturnCorporateShareholderInfoFields, handleUpdateCorporateShareholderInfo, confirmSendToIDM, handleImagesToZoom, cropDataReniec } = props;
   const [loader, setLoader] = useState(true);
   const [newList, setNewList] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [shareholderId, setShareholderId] = useState('');

   const showModalKYC = () => setShowModal(!showModal);

   const buildFullname = (arrayName) => {
      const notNullNames = arrayName.filter(x => x !== null);
      return notNullNames.join(' ').replace(/  +/g, ' ');
   }

   const buildShareholderData = () => {
      if (!isEmpty(shareholders)) {
         let newLegalRepresentative = [];
         let newShareholder = [];
         let newCompany = [];
         shareholders.map((data) => {
            const { dataForm, shareholderType } = data;
            const newDataForm = JSON.parse(dataForm);
            const { first_name, middle_name, last_name, second_last_name, company_legal_name } = newDataForm;
            const newData = { ...data, dataForm: newDataForm }
            if (newDataForm.is_legal_representative === '1') {
               const fullName = buildFullname([first_name, middle_name, last_name, second_last_name]);
               const title = `Representante legal ${newLegalRepresentative.length + 1}`;
               const type = 'LEGAL_REPRESENTATIVE';
               newLegalRepresentative.push({ ...newData, fullName, title, type });
            }
            if (shareholderType === 'PERSON_SHAREHOLDER' && newDataForm.is_legal_representative !== '1') {
               const fullName = buildFullname([first_name, middle_name, last_name, second_last_name]);
               const title = `Accionista ${newShareholder.length + 1}`;
               const type = 'SHAREHOLDER';
               newShareholder.push({ ...newData, fullName, title, type });
            }
            if (shareholderType === 'COMPANY_SHAREHOLDER') {
               const fullName = buildFullname([company_legal_name]);
               const title = `Empresa accionista ${newCompany.length + 1}`;
               const type = 'COMPANY';
               newCompany.push({ ...newData, fullName, title, type });
            }
         })
         setNewList([
            ...newLegalRepresentative,
            ...newShareholder,
            ...newCompany
         ])
      };
      setLoader(false);
   };

   const handleReject = (id) => {
   }

   useEffect(() => {
      if (loader) buildShareholderData();
   }, []);

   return (
      <div className="content-tab">
         <div className="content-tab-container">
            <AccordionMultiple
               list={newList.map((data, key) => {
                  return {
                     title: data.title,
                     Component: <ShareholderComponent
                        key={key}
                        id={data.id}
                        countries={countries}
                        dataForm={data.dataForm}
                        fullName={data.fullName}
                        type={data.type}
                        handleReturnCorporateShareholderInfoFields={handleReturnCorporateShareholderInfoFields}
                        handleUpdateCorporateShareholderInfo={handleUpdateCorporateShareholderInfo}
                        confirmSendToIDM={confirmSendToIDM}
                        handleReject={handleReject}
                        handleImagesToZoom={handleImagesToZoom}
                        cropDataReniec={cropDataReniec}
                        context={context}
                     />
                  }
               })}
            />
         </div>
         <div />
         {showModal &&
            <KycIncorrectModal
               showModal={showModal}
               changeShowModal={showModalKYC}
               handleSubmit={() => { }}
               getUserDataByIdWithLogs={() => { }}
            />
         }
      </div>
   )
}

Shareholder.contextTypes = {
   t: PropTypes.func.isRequired
};

export default Shareholder;

const ShareholderComponent = ({ id, countries, dataForm, fullName, type, handleReturnCorporateShareholderInfoFields, handleUpdateCorporateShareholderInfo, confirmSendToIDM, handleReject, handleImagesToZoom, cropDataReniec, context }) => {

   const { inputs, onInputChangeV2, onInputFileChange, setInputs } = useForm(initialValue);

   const [returnData, setReturnData] = useState([]);
   const [showModal, setShowModal] = useState(false);

   const [loaderIncorrect, setLoaderIncorrect] = useState(false);
   const [loaderReniec, setLoaderReniec] = useState(false);
   const [loaderIdVerif, setLoaderIdVerif] = useState(false);

   const checkReturnData = (e, label) => {
      e.persist();
      const { name, checked } = e.target;
      let newReturnData = [];
      if (checked) {
         newReturnData = [
            ...returnData,
            {
               label_en: label,
               label_es: context.t(label),
               name: name
            }
         ]
      } else {
         newReturnData = returnData.filter(data => data.name !== name);
      }
      setReturnData(newReturnData);
   };

   const defineLanguage = () => {
      const country = type === 'COMPANY' ? dataForm.country_incorporation : dataForm.residence_country;
      let result = 'EN';
      if (!isEmpty(country) && nationality.includes(country)) {
         result = 'ES';
      }
      return result;
   };

   const handleCorrectInformation = (e, message) => {
      e.preventDefault();
      setLoaderIncorrect(true);
      setShowModal(false);
      handleReturnCorporateShareholderInfoFields(id, returnData, message, setLoaderIncorrect);
   };

   const resetValues = (array) => {
      let initialvalues = {};
      array.map(({ name }) => {
         initialvalues[name] = dataForm[name];
      });
      setInputs({
         ...inputs,
         ...initialvalues
      })
   };

   const submitData = () => {
      handleUpdateCorporateShareholderInfo({
         id,
         ...inputs
      });
   }

   const initialValuesLegalRepresentative = {
      select_percentage_number: '',
      percentage_number: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      second_last_name: '',
      birthday: '',
      nationality: '',
      birth_country: '',
      residence_country: '',
      address_main: '',
      building: '',
      apt_number: '',
      town: '',
      state: '',
      city: '',
      district: '',
      zip_code: '',
      occupation: '',
      work_center: '',
      phone: '',
      email: '',
      is_pep: '',
      pep_document: '',

      billing_address: '',
      billing_building: '',
      billing_apt_number: '',
      billing_town: '',
      billing_country: '',
      billing_state: '',
      billing_city: '',
      billing_district: '',
      billing_zip: '',

      document_country: '',
      document_type: '',
      document_number: '',
      document_data: '',
      back_document_data: '',
      faces_images: ''
   };

   const legalRepresentative = [
      {
         title: 'Datos personales',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Is a Shareholder?',
               name: 'select_percentage_number',
               type: 'select',
               options: isShareholder
            },
            {
               label: 'Percentage Number %',
               name: 'percentage_number',
               type: 'number',
               visible: inputs.select_percentage_number === '1'
            },
            {
               label: 'First Name',
               name: 'first_name',
               type: 'text'
            },
            {
               label: 'Middle name',
               name: 'middle_name',
               type: 'text'
            },
            {
               label: 'Last Name',
               name: 'last_name',
               type: 'text'
            },
            {
               label: 'Second Last Name',
               name: 'second_last_name',
               type: 'text'
            },
            {
               label: 'Date of Birth',
               name: 'birthday',
               type: 'date'
            },
            {
               label: 'Nationality',
               name: 'nationality',
               type: 'select',
               options: countries
            },
            {
               label: 'Birth Country',
               name: 'birth_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Country of Residence',
               name: 'residence_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Residence address',
               name: 'address_main',
               type: 'text'
            },
            {
               label: 'Building number or name',
               name: 'building',
               type: 'text'
            },
            {
               label: 'Interior # / Apartment # / Office #',
               name: 'apt_number',
               type: 'text'
            },
            {
               label: 'Zonification / Town',
               name: 'town',
               type: 'text'
            },
            {
               label: 'State',
               name: 'state',
               type: 'select',
               options: DataFormatterHelper.selectArrayData(states[inputs.residence_country] || [])
            },
            {
               label: 'City / Province',
               name: 'city',
               type: inputs.residence_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(province[inputs.state] || [])
            },
            {
               label: 'Municipality / District',
               name: 'district',
               type: inputs.residence_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(districts[inputs.city] || [])
            },
            {
               label: 'Zip Code',
               name: 'zip_code',
               type: 'text'
            },
            {
               label: 'Activity, occupation, trade or profession',
               name: 'occupation',
               type: 'text'
            },
            {
               label: 'Work Center',
               name: 'work_center',
               type: 'text'
            },
            {
               label: 'Phone',
               name: 'phone',
               type: 'number'
            },
            {
               label: 'Email',
               name: 'email',
               type: 'email'
            },
            {
               label: 'Is a PEP? (People Exposed Politically)',
               name: 'is_pep',
               type: 'select',
               options: IS_PEP
            },
            {
               label: 'Upload required PEP Document',
               name: 'pep_document',
               type: 'file',
               titleFile: 'Documento PEP',
               visible: inputs.is_pep === '1'
            },
         ]
      },
      {
         title: 'Datos de facturación',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Billing Address',
               name: 'billing_address',
               type: 'text'
            },
            {
               label: 'Billing Building number or name',
               name: 'billing_building',
               type: 'text'
            },
            {
               label: 'Billing Interior # / Apartment #',
               name: 'billing_apt_number',
               type: 'text'
            },
            {
               label: 'Zonification',
               name: 'billing_town',
               type: 'text'
            },
            {
               label: 'Billing Country',
               name: 'billing_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Billing State',
               name: 'billing_state',
               type: inputs.billing_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(states[inputs.billing_country] || [])
            },
            {
               label: 'Billing City / Province',
               name: 'billing_city',
               type: inputs.billing_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(province[inputs.billing_state] || [])
            },
            {
               label: 'Billing Municipality / District',
               name: 'billing_district',
               type: inputs.billing_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(districts[inputs.billing_city] || [])
            },
            {
               label: 'Billing Zip Code',
               name: 'billing_zip',
               type: 'text'
            }
         ]
      },
      {
         title: 'Subir documento y selfie',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Document Country',
               name: 'document_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Document Type',
               name: 'document_type',
               type: 'select',
               options: [{
                  name: inputs.nationality === 'PE' ? 'Documento Nacional de Identidad' : 'Pasaporte',
                  value: inputs.nationality === 'PE' ? 'ID' : 'PP'
               }]
            },
            {
               label: inputs.document_country === 'PE' ? ((inputs.document_type === 'ID' || inputs.document_type === 'PP') ? 'Document Number (8/12 digits)' : 'RUC Number (11 digits)') : 'Document Number',
               name: 'document_number',
               type: 'text'
            },
            {
               label: 'Document Data',
               name: 'document_data',
               type: 'file',
               fileType: 'image',
               accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png',
               titleFile: 'Documento anverso',
            },
            {
               label: inputs.document_type === 'PP' ? 'Identity Document of the Country where you reside' : 'Datos del documento',
               name: 'back_document_data',
               type: 'file',
               fileType: 'image',
               accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png',
               titleFile: 'Documento reverso',
            },
            {
               label: 'Selfie Photo',
               name: 'faces_images',
               type: 'file',
               fileType: 'image',
               accept: 'image/x-png,image/png,image/jpeg,image/heic,image/heif',
               titleFile: 'Foto selfie',
            },
         ]
      }
   ];

   const initialValuesShareholder = {
      percentage_number: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      second_last_name: '',
      birthday: '',
      nationality: '',
      birth_country: '',
      residence_country: '',
      address_main: '',
      building: '',
      apt_number: '',
      town: '',
      state: '',
      city: '',
      district: '',
      zip_code: '',
      occupation: '',
      work_center: '',
      phone: '',
      email: '',
      is_pep: '',
      pep_document: '',

      billing_address: '',
      billing_building: '',
      billing_apt_number: '',
      billing_town: '',
      billing_country: '',
      billing_state: '',
      billing_city: '',
      billing_district: '',
      billing_zip: '',

      document_country: '',
      document_type: '',
      document_number: '',
      document_data: '',
      back_document_data: '',
      faces_images: ''
   };

   const shareholder = [
      {
         title: 'Datos personales',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Percentage Number %',
               name: 'percentage_number',
               type: 'number'
            },
            {
               label: 'First Name',
               name: 'first_name',
               type: 'text'
            },
            {
               label: 'Middle name',
               name: 'middle_name',
               type: 'text'
            },
            {
               label: 'Last Name',
               name: 'last_name',
               type: 'text'
            },
            {
               label: 'Second Last Name',
               name: 'second_last_name',
               type: 'text'
            },
            {
               label: 'Date of Birth',
               name: 'birthday',
               type: 'date'
            },
            {
               label: 'Nationality',
               name: 'nationality',
               type: 'select',
               options: countries
            },
            {
               label: 'Birth Country',
               name: 'birth_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Country of Residence',
               name: 'residence_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Residence address',
               name: 'address_main',
               type: 'text'
            },
            {
               label: 'Building number or name',
               name: 'building',
               type: 'text'
            },
            {
               label: 'Interior # / Apartment # / Office #',
               name: 'apt_number',
               type: 'text'
            },
            {
               label: 'Zonification / Town',
               name: 'town',
               type: 'text'
            },
            {
               label: 'State',
               name: 'state',
               type: 'select',
               options: DataFormatterHelper.selectArrayData(states[inputs.residence_country] || [])
            },
            {
               label: 'City / Province',
               name: 'city',
               type: inputs.residence_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(province[inputs.state] || [])
            },
            {
               label: 'Municipality / District',
               name: 'district',
               type: inputs.residence_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(districts[inputs.city] || [])
            },
            {
               label: 'Zip Code',
               name: 'zip_code',
               type: 'text'
            },
            {
               label: 'Activity, occupation, trade or profession',
               name: 'occupation',
               type: 'text'
            },
            {
               label: 'Work Center',
               name: 'work_center',
               type: 'text'
            },
            {
               label: 'Phone',
               name: 'phone',
               type: 'number'
            },
            {
               label: 'Email',
               name: 'email',
               type: 'email'
            },
            {
               label: 'Is a PEP? (People Exposed Politically)',
               name: 'is_pep',
               type: 'select',
               options: IS_PEP
            },
            {
               label: 'Upload required PEP Document',
               name: 'pep_document',
               type: 'file',
               titleFile: 'Documento PEP',
               visible: inputs.is_pep === '1'
            },
         ]
      },
      {
         title: 'Datos de facturación',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Billing Address',
               name: 'billing_address',
               type: 'text'
            },
            {
               label: 'Billing Building number or name',
               name: 'billing_building',
               type: 'text'
            },
            {
               label: 'Billing Interior # / Apartment #',
               name: 'billing_apt_number',
               type: 'text'
            },
            {
               label: 'Zonification',
               name: 'billing_town',
               type: 'text'
            },
            {
               label: 'Billing Country',
               name: 'billing_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Billing State',
               name: 'billing_state',
               type: inputs.billing_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(states[inputs.billing_country] || [])
            },
            {
               label: 'Billing City / Province',
               name: 'billing_city',
               type: inputs.billing_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(province[inputs.billing_state] || [])
            },
            {
               label: 'Billing Municipality / District',
               name: 'billing_district',
               type: inputs.billing_country === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(districts[inputs.billing_city] || [])
            },
            {
               label: 'Billing Zip Code',
               name: 'billing_zip',
               type: 'text'
            }
         ]
      },
      {
         title: 'Subir documento y selfie',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Document Country',
               name: 'document_country',
               type: 'select',
               options: countries
            },
            {
               label: 'Document Type',
               name: 'document_type',
               type: 'select',
               options: [{
                  name: inputs.nationality === 'PE' ? 'Documento Nacional de Identidad' : 'Pasaporte',
                  value: inputs.nationality === 'PE' ? 'ID' : 'PP'
               }]
            },
            {
               label: inputs.document_country === 'PE' ? ((inputs.document_type === 'ID' || inputs.document_type === 'PP') ? 'Document Number (8/12 digits)' : 'RUC Number (11 digits)') : 'Document Number',
               name: 'document_number',
               type: 'text'
            },
            {
               label: 'Document Data',
               name: 'document_data',
               type: 'file',
               fileType: 'image',
               accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png',
               titleFile: 'Documento anverso',
            },
            {
               label: inputs.document_type === 'PP' ? 'Identity Document of the Country where you reside' : 'Datos del documento',
               name: 'back_document_data',
               type: 'file',
               fileType: 'image',
               accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png',
               titleFile: 'Documento reverso',
            },
            {
               label: 'Selfie Photo',
               name: 'faces_images',
               type: 'file',
               fileType: 'image',
               accept: 'image/x-png,image/png,image/jpeg,image/heic,image/heif',
               titleFile: 'Foto selfie',
            },
         ]
      }
   ];

   const initialValueCompany = {
      percentage_number: '',
      company_legal_name: '',
      legal_entity_type: '',
      non_profit: '',
      other_non_profit: '',
      other_legal_entity_type: '',
      taxpayer_identification_number_type: '',
      other_taxpayer_identification_number_type: '',
      taxpayer_identification_number: '',
      corporate_purpose: '',
      company_address: '',
      building: '',
      apt_number: '',
      town: '',
      country_incorporation: '',
      state: '',
      city: '',
      district: '',
      zip_code: '',
      registration_date: '',
      begin_date: '',
      linked_economic_group: '',
      company_phone: '',
      company_email: ''
   }

   const company = [
      {
         title: 'Datos corporativos',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Percentage Number %',
               name: 'percentage_number',
               type: 'number'
            },
            {
               label: 'Denomination or Full Legal Name of Company',
               name: 'company_legal_name',
               type: 'text'
            },
            {
               label: 'Type of Legal Entity',
               name: 'legal_entity_type',
               type: 'select',
               options: typeOfLegalPerson
            },
            {
               label: 'Non-Profit',
               name: 'non_profit',
               type: 'select',
               options: nonProfit,
               visible: inputs.legal_entity_type === 'Non-Profit'
            },
            {
               label: 'Other Non-Profit',
               name: 'other_non_profit',
               type: 'text',
               visible: (inputs.non_profit === 'Other' && inputs.legal_entity_type === 'Non-Profit')
            },
            {
               label: 'Other Type of Legal Entity',
               name: 'other_legal_entity_type',
               type: 'text',
               visible: inputs.legal_entity_type === 'Other'
            },
            {
               label: 'Type of Taxpayer Identification Number',
               name: 'taxpayer_identification_number_type',
               type: 'select',
               options: identificationNumberType
            },
            {
               label: 'Other Type of Taxpayer Identification Number',
               name: 'other_taxpayer_identification_number_type',
               type: 'text',
               visible: inputs.taxpayer_identification_number_type === 'Other'
            },
            {
               label: 'Taxpayer Identification Number',
               name: 'taxpayer_identification_number',
               type: 'text'
            },
            {
               label: 'Describe Corporate Purpose / Products or Services that the company provides',
               name: 'corporate_purpose',
               type: 'text'
            },
            {
               label: 'Enter Street / Avenue',
               name: 'company_address',
               type: 'text'
            },
            {
               label: 'Building number or name',
               name: 'building',
               type: 'text'
            },
            {
               label: 'Interior # / Apartment # / Office #',
               name: 'apt_number',
               type: 'text'
            },
            {
               label: 'Zonification / Town',
               name: 'town',
               type: 'text'
            },
            {
               label: 'Country',
               name: 'country_incorporation',
               type: 'select',
               options: countries
            },
            {
               label: 'State',
               name: 'state',
               type: 'select',
               options: DataFormatterHelper.selectArrayData(states[inputs.country_incorporation] || [])
            },
            {
               label: 'City / Province',
               name: 'city',
               type: inputs.country_incorporation === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(province[inputs.state] || [])
            },
            {
               label: 'Municipality / District',
               name: 'district',
               type: inputs.country_incorporation === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(districts[inputs.city] || [])
            },
            {
               label: 'Zip Code',
               name: 'zip_code',
               type: 'text'
            },
            {
               label: 'Registration Date (Constitution)',
               name: 'registration_date',
               type: 'date'
            },
            {
               label: 'Activity begin date',
               name: 'begin_date',
               type: 'date'
            },
            {
               label: 'Linked economic group (if applicable)',
               name: 'linked_economic_group',
               type: 'text'
            },
            {
               label: 'Company Phone',
               name: 'company_phone',
               type: 'number'
            },
            {
               label: 'Company Email',
               name: 'company_email',
               type: 'text'
            }
         ]
      }
   ];

   const initialValueType = {
      LEGAL_REPRESENTATIVE: initialValuesLegalRepresentative,
      SHAREHOLDER: initialValuesShareholder,
      COMPANY: initialValueCompany
   }

   const listDataType = {
      LEGAL_REPRESENTATIVE: legalRepresentative,
      SHAREHOLDER: shareholder,
      COMPANY: company
   }

   const initialValue = initialValueType[type];
   const listData = listDataType[type];

   useEffect(() => {
      setInputs({
         ...initialValue,
         ...dataForm
      })
   }, [])

   return (
      <div>
         {showModal && <IncorrectInformationModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleSubmit={handleCorrectInformation}
            listData={returnData}
            defineLanguage={defineLanguage} />}
         <h3 className='name-shareholder'>{fullName}</h3>
         <div className='shareholder-section'>
            {listData.map((data, key) =>
               <InformationSection
                  key={key}
                  data={data}
                  inputs={inputs}
                  onChange={onInputChangeV2}
                  checkReturnData={checkReturnData}
                  onInputFileChange={onInputFileChange}
                  handleImagesToZoom={handleImagesToZoom}
                  context={context}
               />)}
         </div>
         <div className='content-shareholder-btn'>
            <CustomButton
               text="Información Incorrecta"
               onClick={() => setShowModal(true)}
               className="red"
               disabled={loaderIncorrect ? loaderIncorrect : isEmpty(returnData)}
               showLoader={loaderIncorrect}
               customClassName='btn-corporate-incorrectInfo'
            />
            {
               type !== 'COMPANY' &&
               <React.Fragment>
                  {
                     (inputs.document_country === 'PE' && inputs.document_type === 'ID') &&
                     <CustomButton
                        text="Enviar a RENIEC"
                        onClick={() => cropDataReniec(inputs.faces_images, inputs.document_country, inputs.document_number)}
                        className="green"
                        disabled={loaderReniec}
                        customClassName='btn-corporate-reniec'
                     />
                  }
                  <CustomButton
                     text="Enviar a DIG_iD_VERIF"
                     onClick={() => confirmSendToIDM(id, setLoaderIdVerif)}
                     className="green"
                     disabled={loaderIdVerif}
                     customClassName='btn-corporate-idverif'
                  />
               </React.Fragment>
            }
         </div>
      </div>
   )
};