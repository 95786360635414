import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'is-empty';
import HeaderPage from '../../../components/FallComponents/HeaderPage/HeaderPage';
import SubHeaderPage from '../../../components/FallComponents/HeaderPage/SubHeaderPage';
import TableContainer from '../../../components/FallComponents/Table/TableContainer';
import DashboardController from '../../FallControllers/DashboardController';
import { LEVELS_STATUS } from '../../../constants';
import { Loader } from '../../../components/FallComponents/Loader/Loader';

const DashboardAdminPage = (props, context) => {

  const { userInfo } = props;
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState({
    allVCurrentYearQuantities: [],
    allVThisMonthSignupQuantities: [],
    allVTodayQuantities: []
  });

  const handleStatus = (id) => ((LEVELS_STATUS.find(element => element.id === id) || {}).name);

  const tableHeadersYear = [
    {
      tag: 'dates',
      title: 'Fecha (Mes/Año)'
    },
    {
      tag: '',
      title: 'Estado',
      cell: ({ data }) => handleStatus(data.status)
    },
    {
      tag: 'quantity',
      title: 'Cantidad'
    }
  ];

  const tableHeadersMonth = [
    {
      tag: 'dates',
      title: 'Hoy'
    },
    {
      tag: '',
      title: 'Estado',
      cell: ({ data }) => handleStatus(data.status)
    },
    {
      tag: 'quantity',
      title: 'Cantidad'
    }
  ];

  const tableHeadersToday = [
    {
      tag: 'dates',
      title: 'Hoy'
    },
    {
      tag: 'quantity',
      title: 'Cantidad'
    }
  ];

  const loadInitialData = () => {
    DashboardController.handleDashboard({
      userInfo,
      setLoading,
      setAllData
    })
  }

  useEffect(() => {
    if (!isEmpty(userInfo)) loadInitialData();
  }, [userInfo])

  return (
    <div className='dashboardPage container-page-data'>
      <HeaderPage
        title="Dashboard"
        subTitle="Dashboard / Dashboard"
      />
      {
        loading ? <Loader message="Cargando registros..." /> :
          <div className='dashboard-container-main'>
            <div className='space-data-table'>
              <SubHeaderPage
                title="Cantidad de KYC'S en este año (por estado)"
              />
              <TableContainer
                loading={loading}
                headers={tableHeadersYear}
                rows={allData.allVCurrentYearQuantities}
                enablePagination={false}
              />
            </div>
            <div>
              <div className='space-data-table'>
                <SubHeaderPage
                  title="Cantidad de KYC'S de hoy (por estado)"
                />
                <TableContainer
                  loading={loading}
                  headers={tableHeadersMonth}
                  rows={allData.allVTodayQuantities}
                  enablePagination={false}
                />
              </div>
              <div className='space-data-table'>
                <SubHeaderPage
                  title="Cantidad de usuarios registrados este mes"
                />
                <TableContainer
                  loading={loading}
                  headers={tableHeadersToday}
                  rows={allData.allVThisMonthSignupQuantities}
                  enablePagination={false}
                />
              </div>
            </div>
          </div>
      }

    </div >
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

DashboardAdminPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(DashboardAdminPage);
