export const currencies =  [
    {
      value: "PEN",
      name: "PEN",
    },
    {
      value: "PYG",
      name: "PYG",
    },
    {
      value: "COP",
      name: "COP",
    },
  ]