import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  addAutoApprovalProduct,
  deleteAutoApprovalProduct,
  getAllProducts,
  toggleAutoApprovalStatus,
  updateAutoApprovalData,
} from "../FallServices/AutoApprovalServices";

class AutoApprovalController {
  static getAllProducts = async ({
    userInfo,
    filterConfig,
    setRows,
    setTotalRows,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const response = await getAllProducts(userInfo.UserId, filterConfig);
      if (!isEmpty(response.data) && response.data.success) {
        setRows(response.data.data);
        setTotalRows(response.data.countData);
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static toggleAutoApprovalStatus = async ({ userInfo, data }) => {
    try {
      const response = await toggleAutoApprovalStatus(
        userInfo.UserId,
        data.type,
        data.id,
        data.isChecked
      );
      if (!isEmpty(response.data) && response.data.success) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Actualizado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    }
  };
  static updateAutoApprovalData = async ({ userInfo, id, data }) => {
    try {
      const response = await updateAutoApprovalData(userInfo.UserId, id, data);
      if (!isEmpty(response.data) && response.data.success) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Actualizado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no se pudo actualizar",
      });
      return false;
    }
  };
  static addAutoApprovalProduct = async ({ userInfo, product,setLoadingAddProduct }) => {
    try {
      setLoadingAddProduct(true)
      const data ={
        productSymbol:product,
        enableDepositApproval:false,
        enableWithdrawApproval:false
      }
      const response = await addAutoApprovalProduct(userInfo.UserId, data);
      if (!isEmpty(response.data) && response.data.success) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Añadido exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no se pudo actualizar",
      });
      return false;
    }finally{
      setLoadingAddProduct(false)
    }
  };
  static deleteAutoApprovalData = async ({ userInfo, id, setLoadingDelete }) => {
    try {
      setLoadingDelete(true)
      const response = await deleteAutoApprovalProduct(userInfo.UserId, id);
      if (!isEmpty(response.data) && response.data.success) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Eliminado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no se pudo eliminada",
      });
      return false;
    }finally{
      setLoadingDelete(false)
    }
  };
}

export default AutoApprovalController;
