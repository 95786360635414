import React, { useState } from 'react';
import HeaderPage from '../HeaderPage/HeaderPage';
import TableContainer from '../Table/TableContainer';
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import { GroupFilters } from '../Filters/Filters';
import { useForm } from '../../../hooks/formHooks';
import { OPTIONS_STATUS } from '../../../constants';
import './allWithdrawData.css';
import ComponentsHelper from '../../../helpers/Components.helper';
import DateHelper from '../../../helpers/Date.helper'
const AllWithdrawData = props => {
  const {
    withRightSection,
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    setFilterSearch,
    resetFilters,
    productList,
    handleSelectRow,
    context,
    selectedId,
    setWithRightSection
  } = props;

  const [showFilters, setShowFilters] = useState(false);

  const filterInitialValues = {
    TicketNumber: '',
    ReferenceId: '',
    AccountId: '',
    AccountName: '',
    AssetId: '',
    Amount: '',
    Status: '',
    StartTimestamp: '',
    EndTimestamp: ''
  }

  const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(
    filterInitialValues,
    handleSearchFilters
  );

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearchFilters = () => {
    Object.keys(inputsFilters).forEach(element => {
      inputsFilters[element] === '' && delete inputsFilters[element];
    });
    setFilterSearch(inputsFilters);
  };

  const handleResetFilters = () => {
    setInputs(filterInitialValues);
    setWithRightSection(false)
    resetFilters();
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? 'header-option-active' : ''
    },
    {
      icon: RotateRight,
      action: handleResetFilters
    }
  ];

  const inputs = [
    {
      values: {
        title: "Desde",
        name: "StartTimestamp",
        value: inputsFilters.StartTimestamp,
        type: "date",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Hasta",
        name: "EndTimestamp",
        value: inputsFilters.EndTimestamp,
        type: "date",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: 'Ticket ID',
        name: 'TicketNumber',
        value: inputsFilters.TicketNumber,
        type: 'number',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Account ID',
        name: 'AccountId',
        value: inputsFilters.AccountId,
        type: 'number',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Usuario',
        name: 'AccountName',
        value: inputsFilters.AccountName,
        type: 'text',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Moneda',
        name: 'AssetId',
        value: inputsFilters.AssetId,
        type: 'select',
        options: productList
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Monto',
        name: 'Amount',
        value: inputsFilters.Amount,
        type: 'number',
        step: 'any',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Estado',
        name: 'Status',
        value: inputsFilters.Status,
        type: 'select',
        options: OPTIONS_STATUS
      },
      actions: {
        onChange: onInputChangeV2
      }
    }
  ];

  const tableHeaders = [
    {
      tag: 'info',
      title: ' ',
      cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.RequestCode,"Request code"),
      className: 'minimizer no-Selection'
    },
    {
      tag: 'TicketNumber',
      title: 'Ticket ID',
      handleSelectRow,
      disable: true
    },
    {
      tag: 'AccountId',
      title: 'Account ID',
      className: 'minimizer',
      handleSelectRow
    },
    {
      tag: 'AccountName',
      title: 'Usuario',
      handleSelectRow
    },
    {
      tag: 'Amount',
      title: 'Monto',
      handleSelectRow
    },
    {
      tag: 'AssetName',
      title: 'Moneda',
      handleSelectRow
    },
    {
      tag: 'UpdatedByUserName',
      title: 'Actualizado por',
      handleSelectRow
    },
    {
      tag: '',
      title: 'Fecha de creación',
      handleSelectRow,
      cell: ({data}) => <p> {DateHelper.formatDate(data.CreatedTimestamp)}</p>
    },
    {
      tag: '',
      title: 'Fecha de actualización',
      handleSelectRow,
      cell: ({data}) => <p> {DateHelper.formatDate(data.LastUpdateTimestamp)}</p>
    },
    {
      tag: 'Status',
      title: 'Estado',
      handleSelectRow,
     
    },
  ];

  return (
    <div className="all-withdraw-data">
      <HeaderPage
        title={context.t("Retiros")}
        subTitle="Tickets / Retiros"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}>
        {showFilters && (
          <GroupFilters
            handleSearchFilters={handleSearchFilters}
            withRightSection={withRightSection}
            inputs={inputs}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default AllWithdrawData;
