import UtilsHelper from "../../helpers/Utils.helper";

export const postWithdrawsGroups = async (userId, params) => {
    const path = "/api/v1/reports/withdraws_groups";
    const configRequest = {
        userId,
        method: "POST",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const postWithdrawsDetails = async (userId, params) => {
    const path = "/api/v1/reports/withdraws_details";
    const configRequest = {
        userId,
        method: "POST",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const getSpreadData = async (userId, params) => {
    const path = "/api/v1/reports/spread/by_dates";
    const configRequest = {
        userId,
        method: "GET",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const getTradeData = async (userId, params) => {
    const path = "/api/v1/reports/trades/by_dates";
    const configRequest = {
        userId,
        method: "GET",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const getAffiliateData = async (userId, params) => {
    const path = "/api/v1/reports/affiliate/by_dates";
    const configRequest = {
        userId,
        method: "GET",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const getWithdrawData = async (userId, params) => {
    const path = "/api/v1/reports/withdraws/by_dates";
    const configRequest = {
        userId,
        method: "GET",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const getDepositData = async (userId, params) => {
    const path = "/api/v1/reports/deposits/by_dates";
    const configRequest = {
        userId,
        method: "GET",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};