
import isEmpty from 'is-empty';
import xlsx from 'json-as-xlsx';
import { toastTypes } from '../../constants';
import ComponentsHelper from '../../helpers/Components.helper';
import {
  editData,
  getLevelById,
  getCountries,
  generalActionLog,
  getLogsComplete,
  getRequestReniecData,
  getRequestReniecPhoto,
  getRequestReniecSign,
  reniecRequestToValidate,
  getLevelsBeta,
  incrementLevel,
  rejectedLevel,
  updateToIdentityMind,
  correctFields,
  savingRegiterFieldsHook,
  getUserInfoById,
  updatelockUserById,
  updateUnlockUserById,
  freezeAcountById,
  unfreezeAcountById,
  deleteKycAndInternalMessageById,
  downloadAlllevelsBasic,
  reniecLogRequest,
  getCoincidence,
  sendToMigrations,
  rejectKYC,
  postFaceComparison,
  getBiometricData,
  updateLevel,
  getUpdatableLevels
} from '../FallServices/LevelServices';
import { getUserInfoB89, patchUpdateActiveness, postCreateAccount, postCreateClient } from '../FallServices/BanexCardServices';

class NaturalCustomersController {
  static getUserDataById = async ({
    source,
    setLoadingUserInfo,
    userInfo,
    newUserInfo,
    setUserInfo,
    setIsCoink,
    setAccountUserInfo = () => { },
    setCoincidence,
    setLogs,
    finallyAction = true
  }) => {
    setLoadingUserInfo(true);
    setAccountUserInfo(null);
    try {
      const result = await getLevelById(userInfo.UserId, newUserInfo.userId, source);
      const isCoink = result.level.userLevel === 100 || result.level.userLevel === 101
      setIsCoink(isCoink);
      setUserInfo({
        ...newUserInfo,
        userData: result.level
      });
      if (result && result.level) await NaturalCustomersController.getAllLogs({ userInfo, id: newUserInfo.userId, lvlInfo: result.level, setLogs });
      await NaturalCustomersController.getCoincidence({ userInfo, newUserInfo, setCoincidence });
      return result || true;
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' });
      return false;
    } finally {
      finallyAction && setLoadingUserInfo(false);
    }
  };

  static getUserInfoB89ByAccountId = async ({
    userInfo,
    banexcoinUserAccountId,
    setConfigBanexCard
  }) => {
    try {
      const response = await getUserInfoB89(userInfo.UserId, banexcoinUserAccountId);
      const { b89AccountId, isActive } = response.data.data
      setConfigBanexCard({
        isUser: true,
        isActive,
        associatedCard: !isEmpty(b89AccountId)
      })
    } catch (error) { }
  }

  static postCreateUserB89ByAccountId = async ({
    userInfo,
    banexcoinUserAccountId
  }) => {
    try {
      await postCreateClient(userInfo.UserId, banexcoinUserAccountId);
      return true
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: error.response.data.message })
      return false
    }
  }

  static postAssociateCardByAccountId = async ({
    userInfo,
    banexcoinUserAccountId,
    setConfigBanexCard
  }) => {
    try {
      await postCreateAccount(userInfo.UserId, banexcoinUserAccountId);
      setConfigBanexCard({
        isUser: true,
        isActive: true,
        associatedCard: true
      })
      ComponentsHelper.toast({ type: toastTypes.success, message: 'Se asoció al usuario de manera exitosa.' })
      return true
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: error.response.data.message })
      return false
    }
  }

  static patchUpdateActivenessByAccountId = async ({
    userInfo,
    banexcoinUserAccountId,
    setConfigBanexCard
  }) => {
    try {
      const response = await patchUpdateActiveness(userInfo.UserId, banexcoinUserAccountId);
      const { isActive } = response.data.data
      setConfigBanexCard({
        isUser: true,
        isActive,
        associatedCard: true
      })
      ComponentsHelper.toast({ type: toastTypes.success, message: 'Se realizó el cambio de estado de manera exitosa.' })
      return true
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: error.response.data.message })
      return false
    }
  }

  static getAllLogs = async ({ userInfo, id, lvlInfo, setLogs }) => {
    try {
      if (isEmpty(lvlInfo)) return
      const result = await getLogsComplete(
        userInfo.UserId,
        id,
        lvlInfo.banexcoinUserId
      );
      const newLogs = result.nodes.map((log, i) => {
        log.pos = i + 1;
        return log;
      });
      setLogs(newLogs);
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' })
    }
  };
  static getCoincidence = async ({ userInfo, newUserInfo, setCoincidence }) => {
    try {
      const result = await getCoincidence(userInfo.UserId, newUserInfo.userId);
      setCoincidence(result);
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información de coincidencia y similaridad no pudo ser accesada.' })
    }
  };
  static loadLevels = async ({
    setLoading,
    userInfo,
    filterConfig,
    filterSearch,
    setRows,
    setTotalRows
  }) => {
    try {
      setLoading(true);
      const result = await getLevelsBeta(
        userInfo.UserId,
        filterConfig,
        filterSearch
      );
      const newRows = result.nodes.map(row => {
        row.fullDocument = `${row.documentType || '-'} / ${row.documentNumber || '-'}`;
        return row;
      });
      setRows(newRows);
      setTotalRows(result.totalCount);
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' })
    } finally {
      setLoading(false);
    }
  };

  static editFormData = async (userId, data, levelBasicInfo) => {
    const model = {
      id: data.id,
      banexcoin_user_id: data.banexcoin_user_id,
      first_name: data.first_name,
      last_name: data.last_name,
      middle_name: data.middle_name,
      second_last_name: data.second_last_name,
      address_main: data.address_main,
      building: data.building,
      city: data.city,
      town: data.town,
      state: data.state,
      phone: data.phone,
      apt: data.apt,
      district: data.district,
      zip_code: data.zip_code,
      phone: data.phone,
      is_pep: data.is_pep,
      pep_document: data.pep_document,
      billing_country: data.billing_country,
      billing_address: data.billing_address,
      billing_zip: data.billing_zip,
      billing_state: data.billing_state,
      billing_city: data.billing_city,
      billing_building: data.billing_building,
      billing_town: data.billing_town,
      billing_apt_number: data.billing_apt_number,
      billing_district: data.billing_district,
      document_type: data.document_type,
      document_country: data.document_country,
      document_number: data.document_number,
      nationality: data.nationality,
      birth_country: data.birth_country,
      birthday: data.birthday,
      status: 8,
      occupation: data.occupation,
      occupation_details: data.occupation_details,
      work_center: data.work_center,
      residence_country: data.residence_country,
      document_data: data.document_data,
      back_document_data: data.back_document_data,
      faces_images: data.faces_images,
      proof_of_residence: !isEmpty(data.proof_of_residence)
        ? data.proof_of_residence
        : '',
      master_affidavit_document: !isEmpty(data.master_affidavit_document)
        ? data.master_affidavit_document
        : '',
      master_origin_document: !isEmpty(data.master_origin_document)
        ? data.master_origin_document
        : '',
      master_origin_founds: !isEmpty(data.master_origin_founds)
        ? data.master_origin_founds
        : '',
      is_invoice_with_ruc: !isEmpty(data.is_invoice_with_ruc) ? data.is_invoice_with_ruc : '',
      personal_ruc: data.personal_ruc,
      master_worker_type: data.master_worker_type,
      return_all_rows: !isEmpty(data.return_all_rows)
        ? data.return_all_rows
        : false,
      gender: data.gender,
      document_expiration_date: `${data.document_expiration_date}T05:00:00+00:00`
    };

    if (isEmpty(data.document_expiration_date)) {
      delete model['document_expiration_date']
    }

    const modelFiles = new FormData();
    for (let key in model) {
      modelFiles.append(key, model[key]);
    }

    if (!isEmpty(data.document_data_new) && typeof data.document_data_new === "object") {
      modelFiles.append('document_data_new', data.document_data_new);
    }
    if (!isEmpty(data.back_document_data_new) && typeof data.back_document_data_new === "object") {
      modelFiles.append('back_document_data_new', data.back_document_data_new);
    }
    if (!isEmpty(data.pep_document_new)) {
      modelFiles.append('pep_document_new', data.pep_document_new);
    }
    if (!isEmpty(data.faces_images_new) && typeof data.faces_images_new === "object") {
      modelFiles.append('faces_images_new', data.faces_images_new);
    }
    if (!isEmpty(data.proof_of_residence_new)) {
      modelFiles.append('proof_of_residence_new', data.proof_of_residence_new);
    }

    if (!isEmpty(data.master_affidavit_document_new)) {
      modelFiles.append(
        'master_affidavit_document_new',
        data.master_affidavit_document_new
      );
    }

    if (!isEmpty(data.master_origin_document_new)) {
      modelFiles.append(
        'master_origin_document_new',
        data.master_origin_document_new
      );
    }

    if (!isEmpty(data.master_origin_founds_new)) {
      modelFiles.append(
        'master_origin_founds_new',
        data.master_origin_founds_new
      );
    }
    if (!isEmpty(data.other_occupation)) {
      modelFiles.append(
        'other_occupation',
        data.other_occupation
      );
    }

    try {
      ComponentsHelper.toast({ type: toastTypes.info, message: 'Editando...' })
      await editData(userId, modelFiles);
    } catch (err) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' })
    }
  };

  static getReniecUserData = async ({
    setReniecData,
    setLoadingReniecData,
    levelBasicInfo,
    userInfo
  }) => {
    try {
      setReniecData({});
      setLoadingReniecData(true);
      if (levelBasicInfo.documentCountry === 'PE' && levelBasicInfo.documentType === 'ID') {
        const documentNumber = levelBasicInfo.documentNumber.replace(/-.*/i, '');
        const dniData = await getRequestReniecData(
          userInfo.UserId,
          documentNumber
        );
        setReniecData(dniData.data.data);
      }
    } catch (err) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' })
    } finally {
      setLoadingReniecData(false);
    }
  };

  static getReniecPhoto = async ({
    setReniecImageData,
    setLoadingReniecData,
    levelBasicInfo,
    userInfo
  }) => {
    try {
      setReniecImageData({});
      setLoadingReniecData(true);
      if (levelBasicInfo.documentCountry === 'PE' && levelBasicInfo.documentType === 'ID') {
        const documentNumber = levelBasicInfo.documentNumber.replace(/-.*/i, '');
        const dniData = await getRequestReniecPhoto(
          userInfo.UserId,
          documentNumber
        );
        setReniecImageData(dniData.data.data);
      }
    } catch (err) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' })
    } finally {
      setLoadingReniecData(false);
    }
  };

  static getReniecSign = async ({
    setReniecImageData,
    setLoadingReniecData,
    levelBasicInfo,
    userInfo
  }) => {
    try {
      setReniecImageData({});
      setLoadingReniecData(true);
      if (levelBasicInfo.documentCountry === 'PE' && levelBasicInfo.documentType === 'ID') {
        const documentNumber = levelBasicInfo.documentNumber.replace(/-.*/i, '');
        const dniData = await getRequestReniecSign(
          userInfo.UserId,
          documentNumber
        );
        setReniecImageData(dniData.data.data);
      }
    } catch (err) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' })
    } finally {
      setLoadingReniecData(false);
    }
  };

  static requestReniecToValidateBiometricsData = async ({
    setLoadingSendRequestReniecToValidate,
    levelBasicInfo,
    setLoadingUserInfo,
    userInfo,
    newUserInfo,
    setUserInfo,
    setIsCoink,
    setLogs,
    cropData,
    setCoincidence
  }) => {
    try {
      let error_reniec = null;
      setLoadingSendRequestReniecToValidate(true);
      ComponentsHelper.toast({ type: toastTypes.info, message: "Enviando a RENIEC..." })
      const reniec_result = await reniecRequestToValidate(
        userInfo.UserId,
        levelBasicInfo,
        cropData
      ).catch(e => {
        if (e.hasOwnProperty('response') && !isEmpty(e.response.data)) {
          console.error(
            'Error from reniec service verify face: ',
            e.response.data
          );
          error_reniec = e.response.data.errors;
          ComponentsHelper.toast({ type: toastTypes.warn, message: error_reniec })
        } else {
          throw e.response.data;
        }
      });
      let log = {
        description: `USER::${levelBasicInfo.banexcoinUserId}`,
        aditional_info: {
          reniec_response: {
            validated: false,
            reason: !isEmpty(error_reniec)
              ? error_reniec
              : 'Imagen facial capturada no es válida',
            result: JSON.stringify(reniec_result)
          }
        },
        id: levelBasicInfo.id
      };

      if (!isEmpty(reniec_result) && !reniec_result.hasOwnProperty('error')) {
        log.aditional_info.reniec_response.reason = '';
        log.aditional_info.reniec_response.validated = true;
        ComponentsHelper.toast({ type: toastTypes.success, message: "Imagen facial capturada satisfactoriamente..." })
      }

      await reniecLogRequest(userInfo.UserId, log).catch(e => {
        throw e;
      });
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: "Ocurrió un error guardando en EL historial." })
      const data = {
        description: `USER::${levelBasicInfo.banexcoinUserId}`,
        aditional_info: {
          server_error: error
        },
        type: 'SERVER_ERROR',
        id: levelBasicInfo.id
      };
      await generalActionLog(userInfo.UserId, data).catch(e => {
        ComponentsHelper.toast({ type: toastTypes.warn, message: "Ocurrió un error guardando en EL historial." })
      });
    } finally {
      setLoadingSendRequestReniecToValidate(false);
    }
    const res = await NaturalCustomersController.getUserDataById({
      setLoadingUserInfo,
      userInfo,
      newUserInfo,
      setUserInfo,
      setIsCoink,
      setLogs,
      setCoincidence
    });
    await NaturalCustomersController.getAllLogs({
      id: newUserInfo.userId,
      lvlInfo: res.level,
      userInfo,
      setLogs
    });
  };

  static getListCountries = async ({ newListCountries, userInfo }) => {
    try {
      const result = await getCountries(userInfo.UserId, true);
      newListCountries(result.data.countries);
    } catch (error) {
      return false
    }
  };

  static incrementLevel = async ({ userInfo, userId, levelBasicInfo, setLoadingAccept }) => {
    try {
      setLoadingAccept(true);
      await incrementLevel(
        userInfo.UserId,
        userId,
        levelBasicInfo.banexcoinUserAccountId,
        levelBasicInfo.nextLevel
      );
      ComponentsHelper.toast({ type: toastTypes.success, message: 'Incremento de nivel exitoso' })
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Ha ocurrido un error. La información no se pudo enviar.' })
    }
    finally {
      setLoadingAccept(false);
    }
  };
  static rejectedIncrementLevel = async ({
    userInfo,
    userId,
    levelBasicInfo,
    setLoadingReject
  }) => {
    try {
      setLoadingReject(true)
      await rejectedLevel(
        userInfo.UserId,
        userId,
        levelBasicInfo.banexcoinUserAccountId,
        levelBasicInfo.nextLevel
      );
      ComponentsHelper.toast({ type: toastTypes.success, message: 'Solicitud enviada exitosamente' })
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Ha ocurrido un error. La información no se pudo enviar.' })
    } finally {
      setLoadingReject(false)
    }
  };

  static updateToIdentityMindById = async ({
    userInfo,
    newUserInfo,
    reload
  }) => {
    try {
      await updateToIdentityMind(userInfo.UserId, newUserInfo.userId);
      ComponentsHelper.toast({ type: toastTypes.success, message: 'Envio exitoso' })
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'La información no pudo ser accesada.' })
    }
  };

  static sendToCorrectFields = async ({
    getIncorrectFields,
    userInfo,
    newUserInfo,
    setInputsReturn,
    setLoadingIncorrect
  }) => {
    try {
      setLoadingIncorrect(true);
      const fields = getIncorrectFields();
      if (isEmpty(fields)) {
        ComponentsHelper.toast({ type: toastTypes.warn, message: 'No has seleccionado ningún campo a enviar.' })
        return;
      }
      const result = await correctFields(userInfo.UserId, newUserInfo.userId, fields);
      if (result.data.message === 'success') {
        setInputsReturn({});
        ComponentsHelper.toast({ type: toastTypes.success, message: 'Envío exitoso.' })
      }
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Error al enviar datos para corregir.' })
    }
    finally {
      setLoadingIncorrect(false);
    }
  };
  static updateRegisterData = async ({ userInfo, userId, inputs }) => {
    try {
      await savingRegiterFieldsHook(userInfo.UserId, userId, inputs);
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Error al intentar editar los datos.' })
    }
  };

  static getAccountUserInfoById = async ({
    userInfo,
    banexcoinUserid,
    banexcoinUserAccountId,
    setAccountUserInfo
  }) => {
    try {
      const res = await getUserInfoById(
        userInfo.UserId,
        banexcoinUserid,
        banexcoinUserAccountId
      );
      if (!isEmpty(res.data) && !isEmpty(res.data.account)) {
        setAccountUserInfo(res.data.account);
      } else {
        setAccountUserInfo({});
      }
    } catch (err) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: "La información no pudo ser accesada." })
    }
  };

  static sendLockUser = async ({
    userInfo,
    userId,
    inputs,
    getUserDataByIdWithLogs
  }) => {
    if (!isEmpty(inputs) && !isEmpty(inputs.userLockReason)) {
      try {
        if (
          inputs.userLockReason === 'others' &&
          isEmpty(inputs.userLockReasonOthers)
        ) {
          ComponentsHelper.toast({ type: toastTypes.warn, message: 'Debes seleccionar una razón de bloqueo.' })
        } else {
          const res = await updatelockUserById(userInfo.UserId, userId, inputs);
          if (inputs.userLockReason === 'Baja Voluntaria') {
            await deleteKycAndInternalMessageById(
              userInfo.UserId,
              inputs.kycUserId
            );
          }
          await getUserDataByIdWithLogs()
        }
      } catch (err) {
        ComponentsHelper.toast({ type: toastTypes.warn, message: 'No se pudo guardar la información.' })
      }
    } else {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Debes seleccionar una razón de bloqueo.' })
    }
  };

  static sendUnLockUser = async ({
    userInfo,
    userId,
    inputs,
    getUserDataByIdWithLogs
  }) => {
    try {
      const res = await updateUnlockUserById(userInfo.UserId, userId, inputs);
      if (res.data.message === 'success') {
        getUserDataByIdWithLogs();
      }
    } catch (err) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'No se pudo guardar la información.' })
    }
  };

  static freezeAccount = async ({ levelBasicInfo, userInfo }) => {
    try {
      const result = await freezeAcountById(userInfo.UserId, levelBasicInfo.id, levelBasicInfo.banexcoinUserAccountId);
      if (
        !isEmpty(result) &&
        result.hasOwnProperty('data') &&
        !isEmpty(result.data) &&
        result.data.message === 'success'
      ) {
        ComponentsHelper.toast({ type: toastTypes.success, message: 'Cuenta congelada exitosamente.' })
      }
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Error al congelar la cuenta.' })
    }
  };

  static unFreezeAccount = async ({ levelBasicInfo, userInfo }) => {
    try {
      let id = levelBasicInfo.id;
      let accountId = levelBasicInfo.banexcoinUserAccountId;

      let result = await unfreezeAcountById(userInfo.UserId, id, accountId);
      if (
        !isEmpty(result) &&
        result.hasOwnProperty('data') &&
        !isEmpty(result.data) &&
        result.data.message === 'success'
      ) {
        ComponentsHelper.toast({ type: toastTypes.success, message: 'Cuenta descongelada exitosamente.' })
      }
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Error al descongelar cuenta.' })
    }
  };

  static downloadBasicLevels = async ({ ids, Userid }) => {
    const buildColumns = (data) => {
      const getKeys = Object.keys(data[0]);
      const columns = getKeys.map((name) => {
        return {
          label: name,
          value: (row) => row[name],
        };
      });
      return columns;
    };
    try {
      ComponentsHelper.toast({ type: toastTypes.info, message: 'Descargando...' })
      const response = await downloadAlllevelsBasic({ userId: Userid, ids });
      const currentdate = new Date();
      const datetime = `${currentdate.getDate()}_${currentdate.getMonth() + 1}_${currentdate.getFullYear()}_${currentdate.getHours()}_${currentdate.getMinutes()}`;
      const columns = buildColumns(response.data.data);
      const data = [
        {
          sheet: "Datos",
          columns,
          content: response.data.data
        }
      ];
      const settings = {
        fileName: `levels_report_${datetime}`,
        extraLength: 10,
        writeOptions: {}
      };
      setTimeout(() => {
        xlsx(data, settings)
      }, 1);
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Información no pudo ser almacenada.' })
    }
  };

  static sendToMigrations = async ({ userInfo, levelId, data, setLoadingMigrations, source }) => {
    try {
      setLoadingMigrations(true)
      const response = await sendToMigrations(userInfo.UserId, levelId, data, source)
      if (!isEmpty(response.data) && response.data.message === "success") {
        ComponentsHelper.toast({ type: toastTypes.success, message: "Datos enviados exitosamente." })
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: 'Error al enviar datos a migraciones.' })
      return false
    } finally {
      setLoadingMigrations(false)
    }
  }

  static postRejectKYC = async ({
    userInfo,
    data,
    setLoader,
    context
  }) => {
    try {
      setLoader(true);
      const response = await rejectKYC(userInfo.UserId, data);
      if (response.data.result === 'success') {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Datos enviados exitosamente.",
        });
        return true;
      }
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Hubo un problema. Por favor, vuelva a intentarlo.",
      });
      return false;
    } catch (err) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: context.t(err.response.data.message),
      });
      return false;
    } finally {
      setLoader(false);
    }
  }
  static sendFaceComparison = async ({
    userInfo,
    image,
    userId
  }) => {
    try {
      const formData = new FormData();
      formData.append('documentFace', image, 'face_image.png');
      formData.append('kycId', userId);
      return await postFaceComparison(userInfo.UserId, formData);
    } catch (err) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Hubo un problema. Por favor, vuelva a intentarlo.",
      });
      return false;
    }
  }

  static findKycData = (data) => {
    const kycSuccess = data.find(item => item.status === "SUCCESS")
    if(kycSuccess) return kycSuccess
    const sortedKyc = data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    return sortedKyc[0]
  }

  static getBiometricData = async ({userInfo, accountId}) => {
    try {
      const response = await getBiometricData(userInfo, accountId);
      if(response.data.result !== 'success' || isEmpty(response.data.data)) return { status:"EMPTY" }
      const { data } = response.data
      const {status, updatedAt, ...kycData } = NaturalCustomersController.findKycData(data)
      const {test_face_recognition_ratio, test_global_authenticity_ratio, ...restParameters} = kycData 
      let failParameters = []
      let responseBiometricData = {
        status:"OK",
        failParameters
      }
      for (const key in restParameters) {
        if (restParameters[key] !== "OK") {
          failParameters.push(key)
        }
      }
      if(test_global_authenticity_ratio < 0.9){
        failParameters.push('test_global_authenticity_ratio')
      }
      if(typeof test_face_recognition_ratio === "string" && test_face_recognition_ratio !== "OK"){
        failParameters.push('test_face_recognition_ratio')
      }
      if(typeof test_face_recognition_ratio === "number"){
        if(test_face_recognition_ratio < 0.5 && (status === "CANCELED" || status === "ERROR")){
          responseBiometricData.status = "FAILED"
        } else if(test_face_recognition_ratio < 0.75){
          failParameters.push('test_face_recognition_ratio')
        }  
      }

      switch (userInfo.userLevel) {
        case 100:
          if (userInfo.residenceCountry !== 'CO')
            failParameters.push('document_types_candidate.no_co')

          if (kycData.document_types_candidate.icao_code !== 'COL')
            failParameters.push('document_types_candidate.icao_code_differs')

          if (!kycData.document_types_candidate.name.includes('Colombia - id Card'))
            failParameters.push('document_types_candidate.no_col_id')

          break
        case 10:
          if (userInfo.residenceCountry !== 'PE')
            failParameters.push('document_types_candidate.no_pe')

          if (kycData.document_types_candidate.icao_code !== 'PER')
            failParameters.push('document_types_candidate.icao_code_differs')

          if (!['Peru - Id Card', 'Peru - Resident permit'].includes(kycData.document_types_candidate.name))
            failParameters.push('document_types_candidate.no_pe_id')

          break
        case 0:
          if (userInfo.residenceCountry !== 'PE') {
            if (kycData.document_types_candidate.icao_code !== 'PER')
              failParameters.push('document_types_candidate.icao_code_differs')

            if (!kycData.document_types_candidate.name.includes('Passport'))
              failParameters.push('document_types_candidate.no_passport')
          }

          if (userInfo.residenceCountry === 'PE') {
            if (kycData.document_types_candidate.icao_code !== 'PER')
              failParameters.push('document_types_candidate.icao_code_differs')

            if (!['Peru - Id Card', 'Peru - Resident permit'].includes(kycData.document_types_candidate.name))
              failParameters.push('document_types_candidate.no_pe_id')
          }

          break
      }

      return responseBiometricData
    } catch (err) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Hubo un problema. Por favor, vuelva a intentarlo.",
      });
      return { status:"ERROR" };
    }
  }

  static updateLevel = async (userId, levelBasic, newLevel) => {
    try {
      return await updateLevel(userId, levelBasic, newLevel)
    } catch (e) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: 'Hubo un problema. Por favor, vuelva a intentarlo.'
      })

      return false
    }
  }

  static getPossibleLevels = async (userId, levelBasicId, setUpdatableLevels) => {
    try {
      const response = await getUpdatableLevels(userId, levelBasicId)
      setUpdatableLevels(response.data ? response.data.data : [])
    } catch (e) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: 'Hubo un problema. Por favor, vuelva a intentarlo.'
      })

      return false
    }
  }
}

export default NaturalCustomersController;
