import React from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'

function ModalRejectWithdraw({
    showModal,
    handleCloseModal,
    loading=false,
    handleChangeInputReasonToReject,
    handleRejectWithdrawByAdmin,
    toStatus,
    rejectReason
}) {
  return (
    <Modal
    show={showModal}
    onHide={handleCloseModal}
    backdrop="static"
    keyboard={false}
    className="modal-return reject-reason"
  >
    <div className={"loading-box-sp" + (loading ? " open" : "")}>
      <Spinner animation="grow" />
    </div>
    <Modal.Header closeButton />
    <Modal.Body>
      <div className="custom-ui">
        <h1>
          ¿Está seguro de que desea enviar el ticket a estado{" "}
          {toStatus.cancelStatus}?
        </h1>
        {toStatus.cancelStatus === "Submitted" && (
          <p>
            Recuerda que el ticket está en estado Enviado, si vas a
            cancelarlo verifica que no haya sido enviada a la red.
          </p>
        )}
        {toStatus.requestReason && (
          <p>
            <Form.Group>
              <Form.Control
                type="text"
                name="rejectReason"
                placeholder="Ingrese el motivo de rechazo"
                defaultValue={rejectReason.rejectReason}
                onChange={handleChangeInputReasonToReject}
                autoComplete={"off"}
              />
            </Form.Group>
          </p>
        )}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={handleRejectWithdrawByAdmin}
      >
        Aceptar
      </Button>
      <Button variant="secondary" onClick={handleCloseModal}>
        Cancelar
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default ModalRejectWithdraw