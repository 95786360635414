import React, { useState, useContext, useEffect } from 'react';
import { LevelsContainer } from '../Levels/LevelsContainer';
import firma from '../../../../images/new_icons/firma.svg';
import send from '../../../../images/new_icons/send.svg';
import profile2 from '../../../../images/new_icons/profile2.svg';
import dniIcon from '../../../../images/new_icons/dni.svg';
import edit from '../../../../images/new_icons/edit.svg';
import check from '../../../../images/new_icons/check.svg';
import cross from '../../../../images/new_icons/cross.svg';
import CopyIcon from "../../../../images/new_icons/info.svg";
import { EditableText } from '../../EditableText/EditableText';
import { Context } from '../../../../pages/FallPages/Customers/Natural/ProviderLevels';
import isEmpty from 'is-empty';
import Accordion from '../../Accordion/AccordionComponent';
import DateHelper from '../../../../helpers/Date.helper'
import { DOCUMENT_TYPE } from '../../../../helpers/constants';
import { Dropdown, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CustomButton } from '../../CustomButton/CustomButton';
import ComponentsHelper from '../../../../helpers/Components.helper';
import UrlHelper from '../../../../helpers/Url.helper';
import UtilsHelper from '../../../../helpers/Utils.helper';
import NaturalCustomersController from '../../../../pages/FallControllers/NaturalCustomersController';
import { CURRENT_LEVEL, genderType, validationParameters } from '../../../../constants'
import KycIncorrectModal from '../Components/KycIncorrectModal';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import { useModal } from '../../../../hooks/useModal';

const GeneralInfo = ({
  status,
  userInfo,
  inputs,
  onSetReturnDataInput,
  onInputChange,
  onInputFileChange,
  onSubmit,
  reset,
  editPermission,
  levelsUser,
  manualKYC
}) => {
  const {
    email,
    accountid,
    blacklist,
    creado,
    ip,
    proveedor
  } = userInfo;
  const { document_type, document_number, first_name, middle_name, last_name, second_last_name, birthday = '', nationality, birth_country, residence_country, gender, document_expiration_date = '', document_data_new, back_document_data_new } = inputs;
  const { handleImagesToZoom, listCountries, possibleLevels } = useContext(Context);
  const [editable, setEditable] = useState(false);
  const cancelInfoUser = () => {
    reset();
    setEditable(false);
  };

  const showCheckboxByUserLevel = level => levelsUser.userLevel === 0 || (levelsUser.userLevel === level && levelsUser.nextLevel === level + 1) ;

  return (
    <div className="general-info-container">
      <Form
        noValidate
        validated={false}
        encType="multipart/form-data"
        onSubmit={onSubmit}
        id="form-admin-levels">
        <div className="content-level-title with-img">
          <h2>Datos de cliente</h2>
          {editPermission &&
            <React.Fragment>
              <button
                type={!editable ? 'submit' : 'button'}
                onClick={() => setEditable(!editable)}>
                <img src={!editable ? edit : check} alt="edit image" />
              </button>
              {editable && (
                <button type="button" onClick={cancelInfoUser}>
                  <img src={cross} alt="cross" style={{ marginLeft: '10px' }} />
                </button>
              )}
            </React.Fragment>
          }
        </div>
        <div className="general-info">
          <div>
            <div className="information-container">
              <EditableText
                status={status}
                showCheckbox={showCheckboxByUserLevel(10)}
                onSetReturnDataInput={onSetReturnDataInput}
                type="text"
                editable={editable}
                option="Primer nombre"
                name="first_name"
                value={first_name}
                className='uppercase'
                onChange={onInputChange}
              />
              <EditableText
                status={status}
                showCheckbox={showCheckboxByUserLevel(10)}
                onSetReturnDataInput={onSetReturnDataInput}
                type="text"
                editable={editable}
                option="Segundo nombre"
                name="middle_name"
                value={middle_name}
                className='uppercase'
                onChange={onInputChange}
              />
              <EditableText
                status={status}
                showCheckbox={showCheckboxByUserLevel(10)}
                onSetReturnDataInput={onSetReturnDataInput}
                type="text"
                editable={editable}
                option="Primer apellido"
                name="last_name"
                value={last_name}
                className='uppercase'
                onChange={onInputChange}
              />
              <EditableText
                status={status}
                showCheckbox={showCheckboxByUserLevel(10)}
                onSetReturnDataInput={onSetReturnDataInput}
                type="text"
                editable={editable}
                option="Segundo apellido"
                name="second_last_name"
                value={second_last_name}
                className='uppercase'
                onChange={onInputChange}
              />
              <EditableText
                status={status}
                showCheckbox={showCheckboxByUserLevel(10)}
                onSetReturnDataInput={onSetReturnDataInput}
                type="date"
                editable={editable}
                option="Fecha de nacimiento"
                value={editable ? birthday : birthday.split('-').reverse().join('-')}
                name="birthday"
                onChange={onInputChange}
              />
              <EditableText
                status={status}
                onSetReturnDataInput={onSetReturnDataInput}
                type="select"
                editable={editable}
                option="Nacionalidad"
                name="nationality"
                value={nationality}
                onChange={onInputChange}
                options={listCountries}
              />
              <EditableText
                status={status}
                onSetReturnDataInput={onSetReturnDataInput}
                type="select"
                editable={editable}
                option="País de nacimiento"
                name="birth_country"
                value={birth_country}
                onChange={onInputChange}
                options={listCountries}
              />
              <EditableText
                status={status}
                onSetReturnDataInput={onSetReturnDataInput}
                type="select"
                editable={editable}
                option="País de residencia"
                name="residence_country"
                value={residence_country}
                onChange={onInputChange}
                options={listCountries}
              />
            </div>
            <div className="email-container">
              <p>Email:</p>
              <p>{email}</p>
            </div>
            <div className="information-container">
              <div>
                <p>Account ID:</p>
                <p>{accountid}</p>
              </div>
              <div>
                <p>BlackList:</p>
                <p>{blacklist}</p>
              </div>
              <EditableText
                status={status}
                onSetReturnDataInput={onSetReturnDataInput}
                type="select"
                editable={editable}
                option="Tipo de documento"
                value={document_type}
                name="document_type"
                onChange={onInputChange}
                options={DOCUMENT_TYPE}
              />
              <EditableText
                status={status}
                onSetReturnDataInput={onSetReturnDataInput}
                type="text"
                editable={editable}
                option="N° de documento"
                name="document_number"
                value={document_number}
                onChange={onInputChange}
              />
              <div>
                <p>Creado el:</p>
                <p>{creado}</p>
              </div>
            </div>
            <div className="ip-container">
              <p>IP:</p>
              <p>{ip}</p>
            </div>
            <div className="information-container">
              <div>
                <p>Proveedor:</p>
                <p>{proveedor}</p>
              </div>
              <EditableText
                status={status}
                onSetReturnDataInput={onSetReturnDataInput}
                type="select"
                editable={editable}
                option="Género"
                value={gender}
                name="gender"
                onChange={onInputChange}
                options={genderType}
              />
            </div>
            {!!possibleLevels.length && <div className='information-container'>
              <EditableText
                  status={status}
                  onSetReturnDataInput={onSetReturnDataInput}
                  type='select'
                  editable={editable}
                  option='Nivel'
                  value={(() => {
                    const prevLevelName = CURRENT_LEVEL.find(it => it.value === levelsUser.userLevel)
                    const nextLevelName = CURRENT_LEVEL.find(it => it.value === levelsUser.nextLevel)
                    const levelName = (() => {
                      if (!prevLevelName && !nextLevelName) return 'Sin nivel'

                      return status === 7 ? nextLevelName.title : prevLevelName.title
                    })()

                    return CURRENT_LEVEL.find(it => it.title === levelName).value
                  })()}
                  name='level'
                  onChange={onInputChange}
                  options={
                    CURRENT_LEVEL
                        .filter(it => possibleLevels.includes(it.value))
                        .map(it => ({ name: it.title, value: it.value }))
                  }
              />
            </div>}
          </div>
          <div className="images-container">
            <EditableText
              status={status}
              showCheckbox={manualKYC ? showCheckboxByUserLevel(10) : false}
              onSetReturnDataInput={onSetReturnDataInput}
              type="file"
              fileType="image"
              accept="image/x-png,image/jpeg,image/heic,image/heif,image/png"
              editable={editable}
              option="Anverso"
              name="document_data_new"
              nameCheck="document_data"
              value={document_data_new}
              onChange={onInputChange}
              onInputFileChange={onInputFileChange}
              handleImagesToZoom={handleImagesToZoom}
            />
            <EditableText
              status={status}
              showCheckbox={manualKYC ? showCheckboxByUserLevel(10) : false}
              onSetReturnDataInput={onSetReturnDataInput}
              type="file"
              fileType="image"
              accept="image/x-png,image/jpeg,image/heic,image/heif,image/png"
              editable={editable}
              option="Reverso"
              name="back_document_data_new"
              nameCheck="back_document_data"
              value={back_document_data_new}
              onChange={onInputChange}
              onInputFileChange={onInputFileChange}
              handleImagesToZoom={handleImagesToZoom}
            />
            <div>
              <EditableText
                status={status}
                onSetReturnDataInput={onSetReturnDataInput}
                type="date"
                editable={editable}
                option="Fecha de vencimiento"
                value={editable ? document_expiration_date : document_expiration_date.split('-').reverse().join('-')}
                name="document_expiration_date"
                onChange={onInputChange}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

const IdentityValidation = ({ userInfo, handleReniecRequests, handleShowModalAzure, editPermission, loadReniecFacial, biometricData, isCoink }) => {
  const { phoneNumber, state } = userInfo;
  const { dni, expDate, lastName, name, verify='' } = loadReniecFacial;
  const renderTooltip = (
    <div>
      <p><strong>DNI:</strong> {dni}</p>
      <p><strong>Nombres:</strong> {name}</p>
      <p><strong>Apellidos:</strong> {lastName}</p>
      <p><strong>Fecha de Expiración:</strong> {expDate}</p>
    </div>
  );

  const biometricStatus = () => {
    if(biometricData.status === "OK"){
      if(isEmpty(biometricData.failParameters)){
        return "Verificado"
      }else{
        return "Revisión manual"
      }
    }
    if(biometricData.status === "FAILED"){
      return "Información incorrecta"
    }
    return "No solicitado"
  }
   
  return (
    <div className="identity-validation">
      {
        !isCoink && (
          <>
                <div className="validation-reniec-biofacial">
                <h3>Validación de Reniec</h3>
                  <div className="validation-phone">
                    <p>Estado:</p>
                    <p className="color-status-Pendiente info-validation">{verify}</p>
                    {dni && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="button-tooltip">{renderTooltip}</Tooltip>}
                    >
                      <div>
                        <img width="16px" src={CopyIcon} />
                      </div>
                    </OverlayTrigger>
                  )}
                  </div>        
                </div>
                <div className="validation-reniec-container">
                  {editPermission &&
                    <button
                      onClick={() =>
                        userInfo.image1 === 'default' || userInfo.image2 === 'default'
                          ? {}
                          : handleReniecRequests.handleSendRequestReniec()
                      }>
                      <img src={send} alt="" />
                      <p>Enviar a Reniec</p>
                    </button>
                  }
                  <button onClick={() => handleReniecRequests.handleReniecInfo()}>
                    <img src={dniIcon} alt="" />
                    <p>Ver datos</p>
                  </button>
                  <button onClick={() => handleReniecRequests.handleReniecPhoto()}>
                    <img src={profile2} alt="" />
                    <p>Ver foto</p>
                  </button>
                  <button onClick={() => handleReniecRequests.handleReniecSign()}>
                    <img src={firma} alt="" />
                    <p>Ver firma</p>
                  </button>
                </div>
          </>
        ) 
      }
      <h3>Validación Biométrica</h3>
      <div className="validation-biometric-container">
        <div className="validation-phone biometric">
          <p>Motivo:</p>
          {(biometricData.status === "OK" || biometricData.status === "FAILED" )? (
            isEmpty(biometricData.failParameters) ? (
              <p>Todas las reglas verificadas con éxito</p>
            ) : (
              <ul>
                {biometricData.failParameters.map((item) => (
                  <li>{validationParameters[item]}</li>
                ))}
              </ul>
            )
          ) : (
            "-"
          )}
        </div>
        <div className="validation-phone">
          <p>Estado:</p>
          <p className={`color-status-${biometricStatus().replaceAll(" ", "_")}`}>
            {biometricStatus()}
          </p>
        </div>
      </div>
      <h3>Validación de teléfono</h3>
      <div className="validation-phone-container">
        <div className="validation-phone">
          <p>Teléfono:</p>
          <p>{phoneNumber}</p>
        </div>
        <div className="validation-phone">
          <p>Estado:</p>
          {state}
        </div>
      </div>
      <br />
      <br />
      <h3>Validación de Azure</h3>
      <div className="validation-reniec-container">
        {editPermission && (
          <button
            onClick={() =>
              !isEmpty(userInfo.image1) &&
              userInfo.image1 !== "default" &&
              handleShowModalAzure()
            }
          >
            <img src={send} alt="" />
            <p>Enviar a Azure</p>
          </button>
        )}
      </div>
    </div>
  );
};

const FirstTabOptions = ({
  onSubmit,
  inputs,
  onInputChange,
  onInputFileChange,
  reset,
  handleReniecRequests,
  handleShowModalAzure,
  userInfo,
  getUserDataByIdWithLogs,
  handlerValidations,
  handleRejectKYC,
  editPermission,
  handleLoadNaturalUsers,
  manualKYC
}) => {
  const {
    userInfo: newUserInfo,
    warningStatus,
    onSetReturnDataInput,
    inputsReturn,
    setInputsReturn,
    isCoink
  } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingIncorrect, setLoadingIncorrect] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [action, setAction] = useState({
    title:"",
    message:"",
    function: () => {}
  })
  const { showModal: showActionModal, handleToggleModal: handleToggleActionModal } = useModal();
   const [loadReniecFacial, setLoadReniecFacial] = useState({
    verify: "Pendiente",
  });
  const { logs } = useContext(Context);
  useEffect(() => {
    logs.map((obj) => {
      const aditionalInfo = JSON.parse(obj.aditionalInfo);
      if (
        !isEmpty(aditionalInfo) &&
        !isEmpty(aditionalInfo.reniec_response) &&
        aditionalInfo.reniec_response.validated
      ) {
        const data = JSON.parse(aditionalInfo.reniec_response.result);
        setLoadReniecFacial({
          name: data.name,
          lastName: data.lastName,
          dni: data.dni,
          expDate: data.expDate,
          verify: "Verificado",
        });
      }
    });
  }, [logs]);
  const [biometricData, setBiometricData] = useState({})

  const getBiometricData = async () => {
    const data = await NaturalCustomersController.getBiometricData({userInfo, accountId: newUserInfo.userData.banexcoinUserAccountId})
    setBiometricData(data)
  }

  useEffect(
    () => {
      if(!isEmpty(userInfo)){
        getBiometricData()
      }}
    , [userInfo]
  )


  const levelBasicInfo = newUserInfo.userData;

  const showModalKYC = () => setShowModal(!showModal);

  const statusPhoneVerify = bnxdata => {
    const states = [
      {
        title: 'Validado',
        className: 'Aprobado'
      },
      {
        title: 'Pendiente',
        className: 'Pendiente'
      },
      {
        title: 'No validado',
        className: 'Rechazado'
      }
    ];
    let currentStatus = states[1];
    if (isEmpty(bnxdata)) {
      currentStatus = states[2];
    } else if (bnxdata.phoneVerified) {
      currentStatus = states[0];
    }
    return (
      <p className={`color-status-${currentStatus.className}`}>
        {currentStatus.title}
      </p>
    );
  };

  const userInformation = {
    email: levelBasicInfo.banexcoinUserByBanexcoinUserId ? levelBasicInfo.banexcoinUserByBanexcoinUserId.email : "",
    accountid: levelBasicInfo.banexcoinUserAccountId,
    blacklist: UtilsHelper.getWarningStatus(warningStatus),
    tipodocumento: levelBasicInfo.documentType,
    nrodocumento: levelBasicInfo.documentNumber,
    creado: DateHelper.formatDate(levelBasicInfo.createdAt),
    ip: levelBasicInfo.ip ? levelBasicInfo.ip : '',
    image1: UrlHelper.validateImgUrl(levelBasicInfo.documentData),
    image2: UrlHelper.validateImgUrl(levelBasicInfo.backDocumentData),
    proveedor: ComponentsHelper.providerImage(levelBasicInfo.userLevel),
    phoneNumber: levelBasicInfo.banexcoinUserByBanexcoinUserId.phone,
    state: statusPhoneVerify(levelBasicInfo.banexcoinUserByBanexcoinUserId)
  };

  const executeIncrementLevel = async () => {
    await NaturalCustomersController.incrementLevel({
      userInfo,
      userId: newUserInfo.userId,
      levelBasicInfo,
      setLoadingAccept
    });
    getUserDataByIdWithLogs();
    handleLoadNaturalUsers()
  };

  const rejectIncrementLevel = async () => {
    await NaturalCustomersController.rejectedIncrementLevel({
      userInfo,
      userId: newUserInfo.userId,
      levelBasicInfo,
      setLoadingReject
    });
    getUserDataByIdWithLogs();
    handleLoadNaturalUsers()
  };

  const getIncorrectFields = () =>
    JSON.stringify(
      Object.values(inputsReturn).filter(el => !isEmpty(el) && el)
    );

  const validateIncorrectFields = () => {
    const validate = Object.values(inputsReturn).filter(el => !isEmpty(el) && el);
    return isEmpty(validate);
  }

  const sendToCorrectField = async () => {
    await NaturalCustomersController.sendToCorrectFields({
      getIncorrectFields,
      userInfo,
      newUserInfo,
      setInputsReturn,
      setLoadingIncorrect
    });
    getUserDataByIdWithLogs();
    handleLoadNaturalUsers()
  };

  const optionsAccordion = [
    {
      title: 'Información General',
      component: (
        <GeneralInfo
          status={newUserInfo.userData.status}
          onSetReturnDataInput={onSetReturnDataInput}
          userInfo={userInformation}
          inputs={inputs}
          onInputChange={onInputChange}
          onInputFileChange={onInputFileChange}
          onSubmit={onSubmit}
          reset={reset}
          editPermission={editPermission}
          levelsUser={{
            userLevel: newUserInfo.userData.userLevel,
            nextLevel: newUserInfo.userData.nextLevel
          }}
          manualKYC={manualKYC}
        />
      )
    },
    {
      title: 'Validación de Identidad',
      component: (
        <IdentityValidation
          userInfo={userInformation}
          handleReniecRequests={handleReniecRequests}
          handleShowModalAzure={handleShowModalAzure}
          editPermission={editPermission}
          loadReniecFacial={loadReniecFacial}
          isCoink={isCoink}
          biometricData={biometricData}
        />
      )
    }
  ];

  const handleAvailableButtons = (newUserInfo) => {
    const status = newUserInfo.userData.status
    const level = newUserInfo.userData.userLevel
    if (isEmpty(status) || status === 4) return;
    if (status === 7 || status === 5) {
      return <CustomButton
        text="Rechazar"
        onClick={() => {
          setAction({
            title:"Rechazar solicitud",
            message:"<b class='danger_text'>Esta acción inhabilitará al cliente permanentemente</b>¿Estás seguro que deseas rechazar esta cuenta en Banexcoin?",
            function: rejectIncrementLevel
          })
          handleToggleActionModal()
        }}
        className="black-v2"
      />
    } else {
      return (
        <React.Fragment>
          <CustomButton
            text="Aprobar"
            onClick={() => {
              setAction({
                title:"Aprobar solicitud",
                message:"¿Desea aprobar la solicitud de aumento de nivel para la cuenta seleccionada?",
                function: executeIncrementLevel
              })
              handleToggleActionModal()
            }}
            className="green"
          />
          {level < 100 &&
              <CustomButton
              text="Información Incorrecta"
              onClick={() => {
                setAction({
                  title:"Solicitar correccion de campos",
                  message:"¿Está seguro que desea solicitar la corrección de los campos seleccionados?",
                  function: sendToCorrectField
                })
                handleToggleActionModal()
              }}
              className="red"
              showLoader={loadingIncorrect}
              disabled={loadingIncorrect ? loadingIncorrect : validateIncorrectFields()}
            />
          }
          <CustomButton
            text="Rechazar"
            onClick={() => {
              setAction({
                title:"Rechazar solicitud",
                message:"<b class='danger_text'>Esta acción inhabilitará al cliente permanentemente</b>¿Estás seguro que deseas rechazar esta cuenta en Banexcoin?",
                function: rejectIncrementLevel
              })
              handleToggleActionModal()
            }}
            className="black-v2"
            disabled={loadingReject}
          />
        </React.Fragment>
      )
    }
  }

  const kycBtn = [0, 10, 100].includes(newUserInfo.userData.userLevel) && (newUserInfo.userData.status === 1 || newUserInfo.userData.status === 5);

  return (
    <>
      <div className="content-tab">
        <div className="content-tab-container">
          {optionsAccordion.map(({ title, component }, key) => (
            <Accordion title={title} Component={component} key={key} />
          ))}
          <h2 className="title-accordion">Información de KYC</h2>
          <LevelsContainer
            status={newUserInfo.userData.status}
            levelsUser={{
              userLevel: newUserInfo.userData.userLevel,
              nextLevel: newUserInfo.userData.nextLevel
            }}
            onSetReturnDataInput={onSetReturnDataInput}
            userInfo={userInfo}
            inputs={inputs}
            onInputChange={onInputChange}
            onInputFileChange={onInputFileChange}
            onSubmit={onSubmit}
            reset={reset}
            editPermission={editPermission}
          />
        </div>
        {editPermission &&
          <div className="buttons-container">
            <div className="buttons-left">
              {!isEmpty(newUserInfo.userData.status) &&
                <>
                  {handleAvailableButtons(newUserInfo)}
                  {kycBtn &&
                    <CustomButton
                      text="Rechazar biometría"
                      onClick={() => setShowModal(true)}
                      className="black-v2"
                      showLoader={false}
                    />
                  }
                </>
              }
            </div>
            <Dropdown>
              <Dropdown.Toggle className="ml-0 p-0">
                Validaciones
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handlerValidations}>
                  Enviar a Acuant
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      </div>
      {showModal &&
        <KycIncorrectModal
          showModal={showModal}
          changeShowModal={showModalKYC}
          handleSubmit={handleRejectKYC}
          getUserDataByIdWithLogs={getUserDataByIdWithLogs}
        />
      }
      <ConfirmModal
        showModal={showActionModal}
        handleToggleModal={() => {
          handleToggleActionModal();
        }}
        title={action.title}
        message={action.message}
        confirmButtonProps={{
          onClick: action.function,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </>
  );
};

export default FirstTabOptions;
