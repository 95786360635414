import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './user.css';
import Accordion from '../../../Accordion/AccordionComponent';
import isEmpty from 'is-empty';
import UtilsHelper from '../../../../../helpers/Utils.helper';
import UrlHelper from '../../../../../helpers/Url.helper'
import RoleAccessHelper from '../../../../../helpers/RoleAccess.helper';
import { CustomButton } from '../../../CustomButton/CustomButton';
import Table from '../../../Table/Table';
import { banksAccountBanexcoin } from '../../../../../constants';
import { Alert } from 'react-bootstrap';
import DateHelper from '../../../../../helpers/Date.helper';

const User = (props, context) => {
  const {
    ticketInfo,
    depositInfo,
    levelCorporate,
    confirmSubmit,
    rejectedSubmit,
    handleFindTransaction,
    transactions,
    eventFindTransaction,
    transactionsByCurrency,
    confirmBankTransactionSubmit,
    getCurrencyByBankAccount,
    handleImagesToZoom,
    setEventFindTransaction,
    editPermission
  } = props;

  const [transactionId, setTransactionId] = useState('');
  const typesOfCrypto = ['BTC', 'USDC', 'USD Coin'];
  const ticketStatus = ['FullyProcessed', 'Rejected', 'Accepted'];
  const isValid = !(ticketInfo.AssetName === 'BTC');

  const getBankByBankAccount = bankAccount => {
    const findAccount = banksAccountBanexcoin.find(
      b => b.bankAccount.replace(/-/g, '') === bankAccount
    );
    return !isEmpty(findAccount) ? findAccount.bank : '-';
  };

  let newAccountDestination = '';
  if (!isEmpty(depositInfo.accountDestination)) {
    newAccountDestination = getBankByBankAccount(
      depositInfo.accountDestination
    );
  }
  let txIdLink = '';
  if (typesOfCrypto.includes(ticketInfo.AssetName)) {
    txIdLink = UrlHelper.txidToLink(depositInfo.TXId, ticketInfo.AssetName);
  }

  const ClientDetails = () => {
    return (
      <div className="slot-details">
        <div className="option">
          <p>
            <b>{context.t('Request username')}:</b>
          </p>
          <p>{ticketInfo.AccountName || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Source of funds')}:</b>
          </p>
          <p>{depositInfo.sourceOfFunds || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Account ID')}:</b>
          </p>
          <p>{ticketInfo.AccountId || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Rejected reason')}:</b>
          </p>
          <p>{ticketInfo.RejectReason || '-'}</p>
        </div>
      </div>
    );
  };
  const TicketDetails = () => {
    return (
      <div className="slot-details">
        <div className="option">
          <p>
            <b>{context.t('Request Code')}:</b>
          </p>
          <p>{ticketInfo.RequestCode || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Ticket Number')}:</b>
          </p>
          <p>{ticketInfo.TicketNumber || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Notional Value')}:</b>
          </p>
          <p>{ticketInfo.NotionalValue}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Updated By')}:</b>
          </p>
          <p>{ticketInfo.UpdatedByUserName || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Amount')}:</b>
          </p>
          <p>{ticketInfo.Amount || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Asset Name')}:</b>
          </p>
          <p>{ticketInfo.AssetName || '-'}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Last Updated')}:</b>
          </p>
          <p>{DateHelper.formatDate(ticketInfo.LastUpdateTimeStamp)}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Created')}:</b>
          </p>
          <p>{DateHelper.formatDate(ticketInfo.CreatedTimestamp)}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t('Reference ID')}:</b>
          </p>
          <p>{ticketInfo.ReferenceId || '-'}</p>
        </div>
        {typesOfCrypto.includes(ticketInfo.AssetName) ? (
          <React.Fragment>
            <div className="option">
              <p>
                <b>{context.t('TXId')}:</b>
              </p>
              <p>
                {!isEmpty(txIdLink) ? (
                  <a
                    href={txIdLink}
                    target="_blank"
                    title="Consultar TXID en nueva pestaña">
                    {depositInfo.TXId}
                  </a>
                ) : (
                  depositInfo.TXId
                )}
              </p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('From Address')}:</b>
              </p>
              <p>{depositInfo.FromAddress || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('To Address')}:</b>
              </p>
              <p>{depositInfo.ToAddress || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Source of funds')}:</b>
              </p>
              <p>{depositInfo.sourceOfFunds || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Source of funds others')}:</b>
              </p>
              <p>{depositInfo.sourceOfFundsOthers || '-'}</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {depositInfo.depositProof && (
              <div className="option">
                <p>
                  <b>{context.t('Proof of deposit')}:</b>
                </p>
                <p>
                  {UtilsHelper.checkIsImage(depositInfo.depositProof) ? (
                    <img
                      src={UrlHelper.fixStorageLink(depositInfo.depositProof)}
                      width="100px"
                      onClick={() => handleImagesToZoom([{ src: UrlHelper.fixStorageLink(depositInfo.depositProof), alt: 'document front image' }])}
                    />
                  ) : (
                    <a
                      href={UrlHelper.fixStorageLink(depositInfo.depositProof)}
                      target="_blank"
                      className="btn btn-primary">
                      {`Descargar prueba de depósito `}
                      <b>{UtilsHelper.getFilename(depositInfo.depositProof)}</b>
                    </a>
                  )}
                </p>
              </div>
            )}
            {!isEmpty(levelCorporate) && (
              <React.Fragment>
                <div className="option">
                  <p>
                    <b>{context.t('Document Type')}:</b>
                  </p>
                  <p>
                    {levelCorporate.taxpayerIdentificationNumberType || '-'}
                  </p>
                </div>
                <div className="option">
                  <p>
                    <b>{context.t('Document Number')}:</b>
                  </p>
                  <p>{levelCorporate.taxpayerIdentificationNumber || '-'}</p>
                </div>
              </React.Fragment>
            )}
            <div className="option">
              <p>
                <b>{context.t('Transaction Number')}:</b>
              </p>
              <p>{depositInfo.transactionNumber || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Transaction Date')}:</b>
              </p>
              <p>{depositInfo.transactionDate || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Account Source')}:</b>
              </p>
              <p>{depositInfo.accountSource || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Bank Name Source')}:</b>
              </p>
              <p>{depositInfo.bankNameSource || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Bank Country Source')}:</b>
              </p>
              <p>{depositInfo.bankCountrySource || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Bank Name Destination')}:</b>
              </p>
              <p>{depositInfo.bankNameDestination || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Bank Country Destination')}:</b>
              </p>
              <p>{depositInfo.bankCountryDestination || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Account Destination')}:</b>
              </p>
              <p>{depositInfo.accountDestination || '-'}</p>
            </div>
            <div className="option">
              <p>
                <b>{context.t('Source of funds others')}:</b>
              </p>
              <p>{depositInfo.sourceOfFundsOthers || '-'}</p>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const validateChecked = id => transactionId === id;

  const handleClassname = (value1, value2) =>
    value1 === value2 ? 'dep-success' : 'dep-error';

  const optionsAccordion = [
    {
      title: 'Información del Cliente',
      component: <ClientDetails />
    },
    {
      title: 'Información del Ticket',
      component: <TicketDetails />
    }
  ];

  const headers = [
    {
      tag: '',
      title: '',
      className: 'rd-btn',
      cell: ({ data }) => (
        <input
          name="transactionGroup"
          type="radio"
          value={data.id}
          onChange={e => setTransactionId(e.target.value)}
          checked={validateChecked(data.id)}
        />
      )
    },
    {
      tag: '',
      title: 'Banco Origen (Cliente)',
      cell: ({ data }) => (
        <p className={handleClassname(newAccountDestination, data.bankCode)}>
          {' '}
          {newAccountDestination}
        </p>
      )
    },
    {
      tag: '',
      title: 'Banco Destino BNX',
      cell: ({ data }) => (
        <p className={handleClassname(newAccountDestination, data.bankCode)}>
          {data.bankCode}
        </p>
      )
    },
    {
      tag: 'sourceBankAccountNumber',
      title: 'Cuenta BNX'
    },
    {
      tag: '',
      title: 'Fecha Transacción',
      cell: ({ data }) => (
        <p
          className={handleClassname(
            depositInfo.transactionDate,
            data.transactionDate
          )}>
          {data.transactionDate}
        </p>
      )
    },
    {
      tag: 'transactionReference',
      title: 'Referencia'
    },
    {
      tag: '',
      title: 'Monto',
      cell: ({ data }) => (
        <p>{`${getCurrencyByBankAccount(data.sourceBankAccountNumber)} ${data.transactionAmount}`}</p>
      )
    },
    {
      tag: '',
      title: 'Detalle',
      cell: ({ data }) => (
        <p className="text-ellipsis">{JSON.parse(data.payload).detail}</p>
      )
    }
  ];

  const handleTextButton = () => {
    return ticketInfo.Status === 'New' ? context.t('Process transaction') : context.t('Claim transaction')
  }

  return (
    <div className="user-deposits-main">
      <div className="details-body-us">
        {!isEmpty(transactionsByCurrency) ? (
          <React.Fragment>
            <div className="container-tbl-dep">
              <Table
                rowHead={headers}
                dataBody={transactionsByCurrency}
              />
            </div>
            {!isEmpty(transactionId) && (
              <CustomButton
                text={handleTextButton()}
                onClick={() => confirmBankTransactionSubmit(transactionId)}
                className="orange"
              />
            )}
          </React.Fragment>
        ) : (
          eventFindTransaction && (
            <div className='message-alert'>
              <span onClick={() => setEventFindTransaction(false)}>x</span>
              <Alert variant={'warning'}>
                <h3>
                  {`Actualmente existen ${transactions.length} transaccion(es) por este monto en el banco receptor.`} <br />
                  Pero no corresponde a los datos de este Depósito, verificar Banco y Moneda del Ticket.
                </h3>
              </Alert>
            </div>
          )
        )}
        {optionsAccordion.map(({ title, component }, key) => (
          <Accordion title={title} Component={component} key={key} />
        ))}
      </div>
      {editPermission &&
        <div className="buttons-container">
          <div className="buttons-left">
          {!ticketStatus.includes(ticketInfo.Status) && (
            <React.Fragment>
              <CustomButton
                text="Aceptar"
                onClick={confirmSubmit}
                className="green"
              />
              <CustomButton
                text="Rechazar"
                onClick={rejectedSubmit}
                className="red"
              />
            </React.Fragment>
          )}
          {isValid &&
            !ticketInfo.Claim && (
              <CustomButton
                text="Buscar transacciones"
                onClick={handleFindTransaction}
                className="orange"
              />
            )}
          </div>
        </div>
      }
    </div>
  );
};

User.contextTypes = {
  t: PropTypes.func.isRequired
};

export default User;
