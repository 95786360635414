import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import LoginFormComponent from './LoginFormComponent';
import {
  login,
  AUTH_2FA_REQUIRED
} from 'apex-web/lib/redux/actions/authActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import path from '../../helpers/path';
import { withRouter } from 'react-router';
import config from '../../config';

var _config$global = config.global,
  gateway = _config$global.gateway,
  globalSiteName = _config$global.siteName,
  LoginForm = config.LoginForm,
  _config$SignupForm = config.SignupForm,
  useEmailAsUsername = _config$SignupForm.useEmailAsUsername,
  active = _config$SignupForm.active;

var siteName =
  LoginForm && LoginForm.siteName ? LoginForm.siteName : globalSiteName;

var mapStateToProps = function mapStateToProps(state) {
  return {
    errorMsg: state.auth.errorMsg,
    isConnected: state.wsConnection.isConnected,
    useEmailAsUsername: useEmailAsUsername,
    active: active,
    siteName: siteName,
    gateway: gateway
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    doSubmit: function doSubmit(payload, isConnected) {
      if (!isConnected || !navigator.onLine) {
        return dispatch(
          showSnack({
            id: 'loginNotConnectedError',
            // context.t('We were unable to login, check your internet connection and try refreshing the page.')
            text:
              'We were unable to login, check your internet connection and try refreshing the page.',
            type: 'warning'
          })
        );
      }

      return dispatch(login(payload)).then(function(action) {
        if (action.type === AUTH_2FA_REQUIRED) {
          ownProps.history.push(path('/twofactorauth'));
        }
      });
    }
  };
};

var LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);

var LoginFormContainerForm = reduxForm({
  form: 'login',
  onSubmit: function onSubmit() {}
})(LoginFormContainer);

export default withRouter(LoginFormContainerForm);
