import React, {useState, useContext, useEffect } from 'react';
import security from '../../../../images/new_icons/security.svg';
import InputElement from '../../InputElement/InputElement';
import { Context } from '../../../../pages/FallPages/Customers/Natural/ProviderLevels';
import { CustomButton } from '../../CustomButton/CustomButton';
import { useForm } from '../../../../hooks/formHooks';
import { Loader } from '../../Loader/Loader';
import isEmpty from 'is-empty';
import NaturalCustomersController from '../../../../pages/FallControllers/NaturalCustomersController';
import { OPTIONS_STATUS_BLOCK } from '../../../../constants';
import DateHelper from '../../../../helpers/Date.helper'
import UserPermitsController from '../../../../pages/FallControllers/UserPermitsController';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import { useModal } from '../../../../hooks/useModal';

export const UserBlocked = ({ userInfo, getUserDataByIdWithLogs, editPermission, handleLoadNaturalUsers }) => {
  const {
    userInfo: newUserInfo,
    accountUserInfo,
    setAccountUserInfo
  } = useContext(Context);
  const { userData } = newUserInfo;
  const [userLoggedData, setUserLoggedData] = useState(null)
  const {
    banexcoinUserAccountId,
    banexcoinUserByBanexcoinUserId: {
      email,
      banexcoinUserid,
      userLockReason,
      userLockReasonOthers,
      userLockStatus,
      id: userId
    },
    id
  } = userData;

  let { inputs, setInputs, onInputChangeV2 } = useForm({ userLockReason: '' });
  const [action, setAction] = useState({
    title:"",
    message:"",
    function: () => {}
  })
  const { showModal: showActionModal, handleToggleModal: handleToggleActionModal } = useModal();


  const getAccountUserInfoById = async () => {
    await NaturalCustomersController.getAccountUserInfoById({
      userInfo,
      banexcoinUserid,
      banexcoinUserAccountId,
      setAccountUserInfo
    });
  };
  const getUserLoggedProfile = async () => {
    UserPermitsController.getAllUsers({
      setTotalRows:() => {},
      filterConfig:{limit:100},
      setLoading:() => {},
      setRows:setUserLoggedData,
      filterSearch: { banexcoinUserid : userInfo.UserId },
      userInfo
    });
  };

  const filteredBlockedReason = () => {
    if(isEmpty(userLoggedData) || isEmpty(userLoggedData[0].profileByProfileId)) return []
    
    const profileName = userLoggedData[0].profileByProfileId.name.toLowerCase()

    if(profileName.includes("cumplimiento")) return OPTIONS_STATUS_BLOCK.COMPLIANCE
    if(profileName.includes("operaciones")) return OPTIONS_STATUS_BLOCK.OPERATIONS

    return OPTIONS_STATUS_BLOCK.SUPERADMIN
  }

  useEffect(() => {
    inputs = {
      ...inputs,
      email,
      iduser: banexcoinUserid,
      kycUserId: id,
      userLockReason,
      userLockReasonOthers
    };
    setInputs(inputs);
    getUserLoggedProfile()
    getAccountUserInfoById();
  }, []);

  const selectOptions = {
    values: {
      icon: security,
      title: 'Motivo de bloqueo',
      name: 'userLockReason',
      value: inputs.userLockReason,
      type: 'select',
      options: filteredBlockedReason(),
      placeholder: 'Seleccione un estado'
    },
    actions: {
      onChange: onInputChangeV2
    }
  };

  const inputBlockByOther = {
    values: {
      title: 'Otros',
      name: 'userLockReasonOthers',
      value: inputs.userLockReasonOthers || '',
      type: 'text'
    },
    actions: {
      onChange: onInputChangeV2
    }
  };

  const freezeAccount = async () => {
    await NaturalCustomersController.freezeAccount({
      levelBasicInfo: userData,
      userInfo
    });
    getUserDataByIdWithLogs();
  };

  const unFreezeAccount = async () => {
    await NaturalCustomersController.unFreezeAccount({
      levelBasicInfo: userData,
      userInfo
    });
    getUserDataByIdWithLogs();
  };

  const handleFreezeAction = () => {
    setAction({
      title: `${accountUserInfo.Frozen ? 'Descongelar' : 'Congelar'} usuario`,
      message: `¿Está seguro que desea ${
        accountUserInfo.Frozen ? 'descongelar' : 'congelar'
      } el usuario seleccionado?`,
      function : () => accountUserInfo.Frozen ? unFreezeAccount() : freezeAccount()
    })
    handleToggleActionModal()
  };

  const handleBlock = () => {
    setAction({
      title:`${userLockStatus === 1 ? 'Desbloquear' : 'Bloquear'} usuario`,
      message:`¿Está seguro de que desea ${
        userLockStatus === 1 ? 'desbloquear' : 'bloquear'
      } el usuario seleccionado?`,
      function: async () => userLockStatus === 1 ? await sendUnLockUser() : await sendLockUser()
    })
    handleToggleActionModal()
  }

  const rechargeData = () => {
    handleLoadNaturalUsers()
    getUserDataByIdWithLogs()
  }

  const sendLockUser = async () => {
    await NaturalCustomersController.sendLockUser({
      userInfo,
      userId,
      inputs,
      getUserDataByIdWithLogs: rechargeData
    });
  };

  const sendUnLockUser = async () => {
    await NaturalCustomersController.sendUnLockUser({
      userInfo,
      userId,
      inputs,
      getUserDataByIdWithLogs: rechargeData
    });
  };

  return (
    <div className="content-tab">
      {accountUserInfo === null ? (
        <div className="container-loading">
          <Loader message="Cargando.." />
        </div>
      ) : !isEmpty(accountUserInfo) ? (
        <React.Fragment>
          <div className="register-data-tab">
            <div className="item-content-info mb-4">
              <p>Estado del cliente</p>
              <p>{userLockStatus === 1 ? "Bloqueado" : "Desbloqueado"}</p>
            </div>
            <div className="item-content-info mb-4">
              <p>Email</p>
              <p>{userData.email || '-'}</p>
            </div>
            <div className="item-content-info mb-4">
              <p>Fecha de bloqueo</p>
              <p>
                {DateHelper.formatDate(
                  userData.banexcoinUserByBanexcoinUserId.userLockDate
                ) || '-'}
              </p>
            </div>
            {editPermission ?
              <InputElement
                values={selectOptions.values}
                actions={selectOptions.actions}
              /> :
              <div className="item-content-info mb-4">
                <p>Motivo de bloqueo</p>
                <p>{inputs.userLockReason || '-'}</p>
              </div>        
            }
            {inputs.userLockReason === 'others' && (
              <React.Fragment>
                <br />
                {editPermission ?
                  <InputElement
                    values={inputBlockByOther.values}
                    actions={inputBlockByOther.actions}
                  /> :
                  <div className="item-content-info mb-4">
                    <p>Otros</p>
                    <p>{inputs.userLockReasonOthers || ''}</p>
                  </div> 
                }
              </React.Fragment>
            )}
          </div>
          {editPermission &&
            <div className="buttons-container">
              <div className="buttons-left">
                <CustomButton
                  text={userLockStatus === 1 ? 'Desbloquear' : 'Bloquear'}
                  onClick={handleBlock}
                  className={userLockStatus === 1 ? 'green' : 'red'}
                />
                <CustomButton
                  text={accountUserInfo.Frozen ? 'Descongelar' : 'Congelar'}
                  onClick={handleFreezeAction}
                  className={accountUserInfo.Frozen ? 'black' : 'gray'}
                />
              </div>
              <CustomButton
                text="Más info"
                onClick={() => {}}
                className="purple"
              />
            </div>
          }
        </React.Fragment>
      ) : (
        <h4 style={{ padding: '10px' }}>No hay información de usuario</h4>
      )}
      <ConfirmModal
        showModal={showActionModal}
        handleToggleModal={() => {
          handleToggleActionModal();
        }}
        title={action.title}
        message={action.message}
        confirmButtonProps={{
          onClick: action.function,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};
