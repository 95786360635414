import React, { useState } from 'react';

export const Context = React.createContext();

const ProviderLevels = ({ children }) => {
  const [tabSelect, setTabSelect] = useState('Cliente');
  const [imagesZoom, setImagesZoom] = useState({ show: false, images: null });
  const [warningStatus, setWarningStatus] = useState('');
  const [logs, setLogs] = useState(null);
  const [isCoink, setIsCoink] = useState(false);
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useState({
    userId: null,
    userData: null
  });
  const [accountUserInfo, setAccountUserInfo] = useState(null);
  const [listCountries, seltListCountries] = useState([]);
  const [inputsReturn, setInputsReturn] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    second_last_name: '',
    birthday: '',
    nationality: '',
    birth_country: '',
    address_main: '',
    building: '',
    city: '',
    town: '',
    state: '',
    phone: '',
    apt: '',
    district: '',
    zip_code: '',
    is_pep: '',
    pep_document: '',
    billing_country: '',
    billing_address: '',
    billing_state: '',
    billing_city: '',
    billing_zip: '',
    billing_building: '',
    billing_town: '',
    billing_apt_number: '',
    billing_district: '',
    document_type: '',
    document_country: '',
    document_number: '',
    occupation: '',
    occupation_details: '',
    work_center: '',
    is_invoice_with_ruc: false,
    personal_ruc: ''
  });
  const [possibleLevels, setPossibleLevels] = useState([])

  const onSetReturnDataInput = e => {
    e.persist();
    let value = null;
    if (e.target.checked) {
      value = e.target.name;
    }
    let newInputReturn = {
      [e.target.name]: value
    }
    const checks = {
      is_pep: 'pep_document',
      is_invoice_with_ruc: 'personal_ruc'
    }
    if(Object.keys(checks).includes(e.target.name)){
      newInputReturn[checks[e.target.name]] = !value ? null : checks[e.target.name]
    }
    setInputsReturn(inputsReturn => ({
      ...inputsReturn,
      ...newInputReturn
    }));
  };

  const handleImagesToZoom = images => {
    if (!images || (images.length === 1 && images[0].src === 'default')) {
      setImagesZoom({ show: false, images: null });
      return;
    }
    setImagesZoom({ show: true, images });
  };
  const value = {
    imagesZoom,
    handleImagesToZoom,
    loadingUserInfo,
    setLoadingUserInfo,
    userInfo,
    setUserInfo,
    setWarningStatus,
    warningStatus,
    setLogs,
    logs,
    isCoink,
    setIsCoink,
    listCountries,
    seltListCountries,
    tabSelect,
    setTabSelect,
    accountUserInfo,
    setAccountUserInfo,
    inputsReturn,
    setInputsReturn,
    onSetReturnDataInput,
    possibleLevels,
    setPossibleLevels
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ProviderLevels;
