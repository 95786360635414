import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';
const queryString = require('query-string');

export const getMe = async userId => {
  let result = [];
  try {
    let path = '/api/me';
    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let response = await HandlerBackend.sendRequest(configRequest).catch(e => {
      throw e;
    });

    let currentTokenDB = localStorage.getItem('tokenDB');
    if (!empty(response.data)) {
      if (!empty(response.data.tokenDB) && empty(currentTokenDB)) {
        localStorage.setItem('tokenDB', response.data.tokenDB);
      }
    }

    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const getLevelById = async (userId, levelId) => {
  let result = {};
  try {
    let path = `/api/levels/${levelId}`;
    let configRequest = {
      userId,
      method: 'get',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getLevelByUsername = async (userId, username) => {
  let result = {};
  try {
    let path = `/api/levels/username/${username}`;
    let configRequest = {
      userId,
      method: 'get',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getLevelByAccountId = async (userId, accountId) => {
  let result = {};
  try {
    let path = `/api/levels/accountid/${accountId}`;
    let configRequest = {
      userId,
      method: 'get',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getBanexUserById = async (userId, updateById) => {
  let result = {};
  try {
    let path = `/api/banexcoin-user/${updateById}`;
    let configRequest = {
      userId,
      method: 'get',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getLevels = async (userId, page) => {
  let result = [];
  try {
    let path = '/api/levels';
    let params = {
      page: 1
    };

    if (!empty(page)) {
      params.page = page;
    }

    if (!empty(params)) {
      path += '?' + queryString.stringify(params);
    }

    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.levels : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const getLevelsBeta = async (userId, filterConfig, filterSearch) => {
  let result = [];
  try {
    let path = '/api/allbasiclevels';

    let params = {
      filterConfig,
      filterSearch
    };

    let configRequest = {
      userId,
      method: 'post',
      path,
      data: params
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.levels : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const downloadAlllevelsBasic = async userId => {
  let result = [];
  try {
    let path = '/api/download/all-basiclevel';
    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let currentdate = new Date();
    let datetime =
      currentdate.getDate() +
      '_' +
      (currentdate.getMonth() + 1) +
      '_' +
      currentdate.getFullYear() +
      '___' +
      currentdate.getHours() +
      '_' +
      currentdate.getMinutes() +
      '_' +
      currentdate.getSeconds();
    let result = await HandlerBackend.sendRequest(configRequest);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `levels_report_${datetime}.csv`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    throw error;
  }
  return result;
};

export const updateToIdentityMind = async (userId, levelId) => {
  let path = `/api/levels/identity-mind/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const incrementLevel = async (
  userId,
  levelId,
  banexcoin_user_account_id,
  user_level
) => {
  let path = `/api/levels/increment/${levelId}/${banexcoin_user_account_id}/${user_level}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const rejectedLevel = async (
  userId,
  levelId,
  banexcoin_user_account_id,
  user_level
) => {
  let path = `/api/levels/rejected/${levelId}/${banexcoin_user_account_id}/${user_level}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const infoIncorrect = async (
  userId,
  levelId,
  banexcoin_user_account_id
) => {
  let path = `/api/levels/info-incorrect/${levelId}/${banexcoin_user_account_id}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const correctFields = async (userId, levelId, fields) => {
  let headers = {
    // 'Content-Type': 'json/aplication'
  };
  let path = `/api/levels/return-fields/${levelId}`;

  let model = {
    fields: fields
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const deleteAddress = async (userId, levelId) => {
  let path = `/api/levels/delete-address/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deletePhone = async (userId, levelId) => {
  let path = `/api/levels/delete-phone/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deleteDocument = async (userId, levelId) => {
  let path = `/api/levels/delete-document-data/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deleteBackDocument = async (userId, levelId) => {
  let path = `/api/levels/delete-back-document-data/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const deleteFaceImage = async (userId, levelId) => {
  let path = `/api/levels/delete-face-image/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const modifyInfoByAdmin = async (userId, levelId) => {
  let path = `/api/levels/modify/${levelId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const checkboxInput = value => {
  let status = false;
  if (typeof value === 'boolean') {
    status = value;
  }
  if (typeof value === 'string') {
    status = value === 'on' ? true : false;
  }
  return status;
};

export const editFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };

  let path = '/api/levels/edit';
  let model = {
    id: data.id,
    banexcoin_user_id: data.banexcoin_user_id,
    first_name: data.first_name,
    last_name: data.last_name,
    middle_name: data.middle_name,
    second_last_name: data.second_last_name,
    address_main: data.address_main,
    building: data.building,
    city: data.city,
    town: data.town,
    state: data.state,
    phone: data.phone,
    apt: data.apt,
    district: data.district,
    zip_code: data.zip_code,
    phone: data.phone,
    is_pep: data.is_pep,
    pep_document: data.pep_document,
    billing_country: data.billing_country,
    billing_address: data.billing_address,
    billing_zip: data.billing_zip,
    billing_state: data.billing_state,
    billing_city: data.billing_city,
    billing_building: data.billing_building,
    billing_town: data.billing_town,
    billing_apt_number: data.billing_apt_number,
    billing_district: data.billing_district,
    document_type: data.document_type,
    document_country: data.document_country,
    document_number: data.document_number,
    nationality: data.nationality,
    birth_country: data.birth_country,
    birthday: data.birthday,
    status: 8,
    occupation: data.occupation,
    occupation_details: data.occupation_details,
    work_center: data.work_center,
    residence_country: data.residence_country,
    document_data: data.document_data,
    back_document_data: data.back_document_data,
    faces_images: data.faces_images,
    proof_of_residence: !empty(data.proof_of_residence)
      ? data.proof_of_residence
      : '',
    master_affidavit_document: !empty(data.master_affidavit_document)
      ? data.master_affidavit_document
      : '',
    master_origin_document: !empty(data.master_origin_document)
      ? data.master_origin_document
      : '',
    master_origin_founds: !empty(data.master_origin_founds)
      ? data.master_origin_founds
      : '',
    is_invoice_with_ruc: checkboxInput(data.is_invoice_with_ruc),
    personal_ruc: data.personal_ruc
  };

  let modelFiles = new FormData();
  for (let key in model) {
    modelFiles.append(key, model[key]);
  }

  if (!empty(data.document_data_new)) {
    modelFiles.append('document_data_new', data.document_data_new);
  }
  if (!empty(data.back_document_data_new)) {
    modelFiles.append('back_document_data_new', data.back_document_data_new);
  }
  if (!empty(data.pep_document_new)) {
    modelFiles.append('pep_document_new', data.pep_document_new);
  }
  if (!empty(data.faces_images_new)) {
    modelFiles.append('faces_images_new', data.faces_images_new);
  }
  if (!empty(data.proof_of_residence_new)) {
    modelFiles.append('proof_of_residence_new', data.proof_of_residence_new);
  }

  if (!empty(data.master_affidavit_document_new)) {
    modelFiles.append(
      'master_affidavit_document_new',
      data.master_affidavit_document_new
    );
  }

  if (!empty(data.master_origin_document_new)) {
    modelFiles.append(
      'master_origin_document_new',
      data.master_origin_document_new
    );
  }

  if (!empty(data.master_origin_founds_new)) {
    modelFiles.append(
      'master_origin_founds_new',
      data.master_origin_founds_new
    );
  }

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: modelFiles
  });
};

export const getBlacklistNews = async (userId, arrDocNum) => {
  let result = [];
  try {
    let path = '/api/blacklist-news';

    let configRequest = {
      userId,
      method: 'post',
      path,
      data: arrDocNum
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.blacklistList : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const getCountries = async userId => {
  let path = '/api/countries?restricted=true';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getLogs = async (userId, id) => {
  let result = [];
  let path = `/api/levels/getLogs/${id}`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });

  result = !empty(response.data) ? response.data.resultLogs : [];
  return result;
};

export const getLogsComplete = async (userId, id, kycUserId) => {
  let result = [];
  let path = `/api/levels/getLogs/${id}/${kycUserId}`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });

  result = !empty(response.data) ? response.data.resultLogs : [];
  return result;
};

export const sendToCorfid = async (userId, data) => {
  let path = `/api/services/corfid/processuser`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
};

/********************************************************SERVICES***********************************************/

export const reniecRequest = async (userId, data) => {
  let result = [];
  let path = `/api/services/identity/faceverify`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const reniecLogRequest = async (userId, data) => {
  let result = [];
  let path = `/api/levels/renieclogs/`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const getLogReniecById = async (userId, id) => {
  let result = [];
  let path = `/api/levels/renieclogs/${id}`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'GET',
    path
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};
export const getReniecStatusLog = async userId => {
  let path = `/api/levels/reniec/laststatuslog`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'GET',
    path
  });
};

export const requestReniecStatus = async (userId, data) => {
  let path = `/api/levels/reniec/laststatus`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
};

export const ocrRequest = async (userId, data, path) => {
  let result = [];
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const getLogOcrById = async (userId, id) => {
  let result = [];
  let path = `/api/levels/ocrlogs/${id}`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'GET',
    path
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const generalActionLog = async (userId, data) => {
  let result = [];
  let path = `/api/action-logs`;
  let response = await HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });

  result = !empty(response.data) ? response.data : [];
  return result;
};

export const rejectAu10tixPhotos = async (userId, body) => {
  let result = [];
  try {
    let path = '/api/levels/normal/reject/au10tix';

    let configRequest = {
      userId,
      method: 'post',
      path,
      data: body
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : {};
  } catch (error) {
    throw error;
  }
  return result;
};

export const savingRegiterFieldsHook = async (userId, levelId, fields) => {
  let headers = {};
  let path = `/api/levels/edit-register-fields/${levelId}`;

  let model = {
    fields: fields
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const updatelockUserById = async (
  userId,
  userIdToBlock,
  fields,
  LockEmail,
  kycUserId,
  iduser
) => {
  let headers = {};
  let path = `/api/levels/lock-user/${userIdToBlock}`;

  let model = {
    userLockReason: fields.userLockReason,
    userLockReasonOthers: fields.userLockReasonOthers,
    email: LockEmail,
    kycUserId: kycUserId,
    iduser: iduser
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const savingRegiterFieldsCEMigracionesHook = async (
  userId,
  levelId,
  fields
) => {
  let headers = {};
  let path = `/api/levels/fill-ce-register-fields/${levelId}`;

  let model = {
    fields: fields
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model,
    timeout: 80000
  });
};

export const updateUnlockUserById = async (
  userId,
  userIdToBlock,
  fields,
  LockEmail,
  kycUserId,
  iduser
) => {
  let headers = {};
  let path = `/api/levels/unlock-user/${userIdToBlock}`;

  let model = {
    email: LockEmail,
    kycUserId: kycUserId,
    iduser: iduser
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const deleteKycAndInternalMessageById = (userId, id) => {
  let headers = {};
  let path = `/api/levels/internal/message/delete/`;
  let model = { id: id };
  return HandlerBackend.sendRequest({
    method: 'post',
    path,
    headers,
    userId,
    data: model
  });
};

export const getCoincidence = async (userId, id) => {
  let result = [];
  try {
    let path = `/api/match/coincidence/${id}`;

    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.data : {};
  } catch (error) {
    throw error;
  }
  return result;
};

export const freezeAcountById = (userId, id, accountId) => {
  let headers = {};
  let path = `/api/freeze-account/${id}/${accountId}`;

  return HandlerBackend.sendRequest({
    method: 'post',
    path,
    userId,
    headers
  });
};

export const unfreezeAcountById = (userId, id, accountId) => {
  let headers = {};
  let path = `/api/unfreeze-account/${id}/${accountId}`;

  return HandlerBackend.sendRequest({
    method: 'post',
    path,
    userId,
    headers
  });
};

export const getUserInfoById = async (userId, id, accountid) => {
  let headers = {};
  let path = `/api/get-accountAP/info/${id}/${accountid}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    headers,
    path
  });
};

export const changeMasterType = async (userId, arrDocNum, id) => {
  let result = [];
  try {
    let path = `/api/levels/edit-master-data/${id}`;

    let configRequest = {
      userId,
      method: 'post',
      path,
      data: arrDocNum
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
  return result;
};
