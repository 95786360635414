import React from 'react';
import { CustomButton } from '../../CustomButton/CustomButton';
import { transferTypes, accountTypes } from '../../../../constants';
import './bankAccountDetails.css';
import isEmpty from 'is-empty';

const BankAccountDetails = ({ detailBankAccount: data, approvedButton, rejectedButton, handleApproveAccount, handleRejectedAccount, editPermission }) => {

  const showButton = {
    'success': [0, 2],
    'danger': [0, 1]
  };

  const accountStatus = {
    '0': {
      name: 'Pendiente',
      color: 'ticket-status'
    },
    '1': {
      name: 'Aprobado',
      color: 'ticket-status-FullyProcessed'
    },

    '2': {
      name: 'Desactivado o rechazado',
      color: 'ticket-status-Rejected'
    },
    '3': {
      name: 'Desactivado o rechazado',
      color: 'ticket-status-Rejected'
    }
  };

  const usableByTheUser = (data.availableAccount === 'YES' && data.status === 1) ? 'Si' : 'No';

  let transferType = {};
  if (!isEmpty(data.transferType)) {
    transferType = transferTypes.find(tt => tt.type === data.transferType);
  }

  let identificationDocument = `${data.documentType} - ${data.documentNumber}`;
  let fullName = `${data.firstName} ${data.middleName} ${data.lastName} ${data.secondLastName}`;
  if (!isEmpty(data.levelCorporate)) {
    const corporateDate = JSON.parse(data.levelCorporate);
    const { company_legal_name, legal_entity_type, taxpayer_identification_number_type, taxpayer_identification_number } = corporateDate;
    identificationDocument = `${taxpayer_identification_number_type} - ${taxpayer_identification_number}`;
    fullName = `${company_legal_name} ${legal_entity_type}`;
  }

  let accountType = {};
  if (!isEmpty(data.accountType)) {
    accountType = accountTypes.find(at => at.type === data.accountType);
  }

  const validateParam = (param) => {
    let value = '';
    const newParam = param.replaceAll(' ', '');
    if (!isEmpty(newParam)) {
      value = param;
    };
    return value;
  }

  const initialItems = [
    { title: '¿Utilizable por el usuario?', value: usableByTheUser },
    { title: 'Alias', value: data.alias },
    { title: 'Tipo de transferencia', value: transferType.name },
    { title: 'Moneda', value: data.currencyCode },
  ]

  const nationalItems = [
    { title: 'Documento de identidad', value: identificationDocument },
    { title: 'Nombre del cliente', value: validateParam(fullName) },
    { title: 'Nombre del banco', value: data.beneficiaryBankname },
    { title: 'Número de cuenta', value: data.accountNumber },
    { title: 'Número de cuenta interbancaria (CCI)', value: data.accountNumberCci },
    { title: 'Tipo de cuenta', value: accountType.name },
    { title: 'Plaza (Ciudad) del banco', value: data.bankCity },
  ];

  const domesticTransferUsItems = [
    { title: 'Nombre del banco', value: data.beneficiaryBankname },
    { title: 'Número de cuenta', value: data.accountNumber },
    { title: 'Dirección del banco', value: data.bankAddress },
    { title: 'Número de enrutamiento ABA', value: data.abaRoutingNumber },
    { title: 'Nombre del beneficiario final', value: data.fullName },
    { title: 'País del beneficiario', value: data.beneficiaryCountryName },
    { title: 'Ciudad del beneficiario', value: data.beneficiaryCity },
    { title: 'Estado/Provincia del beneficiario', value: data.beneficiaryState },
    { title: 'Código postal del beneficiario', value: data.beneficiaryZip },
    { title: 'Referencia del beneficiario', value: data.beneficiaryReference },
  ];

  const domesticTransferUsWithBankItems = [
    { title: 'Nombre del banco', value: data.beneficiaryBankname },
    { title: 'Número de cuenta', value: data.accountNumber },
    { title: 'Número de enrutamiento ABA', value: data.abaRoutingNumber },
    { title: 'Código BIC / Swift', value: data.swiftBicCode },
    { title: 'Nombre del beneficiario final', value: data.fullName },
    { title: 'Dirección del beneficiario', value: data.beneficiaryAddress },
    { title: 'País del beneficiario', value: data.beneficiaryCountryName },
    { title: 'Ciudad del beneficiario', value: data.beneficiaryCity },
    { title: 'Código postal del beneficiario', value: data.beneficiaryZip },
    { title: 'País del banco intermediario', value: data.intermediaryBankCountryName },
    { title: 'Nombre del banco intermediario', value: data.intermediaryBankname },
    { title: 'Dirección de banco intermediario', value: data.intermediaryBankAddress },
    { title: 'Ciudad de banco intermediario', value: data.intermediaryBankCity },
    { title: 'Región/Estado/Provincia de banco intermediario', value: data.intermediaryBankState },
  ];

  const otherItems = [
    { title: 'País del banco', value: data.countryName },
    { title: 'Nombre del banco', value: data.beneficiaryBankname },
    { title: 'Número de cuenta', value: data.accountNumber },
    { title: 'Dirección del banco', value: data.bankAddress },
    { title: 'Código BIC / Swift', value: data.swiftBicCode },
    { title: 'Nombre del beneficiario final', value: data.fullName },
    { title: 'Dirección del beneficiario', value: data.beneficiaryAddress },
    { title: 'País del beneficiario', value: data.beneficiaryCountryName },
    { title: 'Ciudad del beneficiario', value: data.beneficiaryCity },
    { title: 'Código postal del beneficiario', value: data.beneficiaryZip },
    { title: 'País del banco intermediario', value: data.intermediaryBankCountryName },
    { title: 'Nombre del banco intermediario', value: data.intermediaryBankname },
    { title: 'Dirección de banco intermediario', value: data.intermediaryBankAddress },
    { title: 'Ciudad de banco intermediario', value: data.intermediaryBankCity },
    { title: 'Región/Estado/Provincia de banco intermediario', value: data.intermediaryBankState },
  ]

  const OptionDetail = ({ title, value }) => {
    return (
      <div className="option">
        <p>
          <b>{title}</b>
        </p>
        <p>{value || '-'}</p>
      </div>
    )
  };

  const national = () => {
    return (
      <React.Fragment>
        {nationalItems.map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
      </React.Fragment>
    )
  };

  const domesticTransferUs = () => {
    return (
      <React.Fragment>
        {domesticTransferUsItems.slice(0, 4).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
        <h2>Información del beneficiario</h2>
        {domesticTransferUsItems.slice(4).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
      </React.Fragment>
    )
  };

  const domesticTransferUsWithBank = () => {
    return (
      <React.Fragment>
        {domesticTransferUsWithBankItems.slice(0, 4).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
        <h2>Información del beneficiario</h2>
        {domesticTransferUsWithBankItems.slice(4, 10).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
        <h2>Información del banco intermediario</h2>
        {domesticTransferUsWithBankItems.slice(10, 15).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
      </React.Fragment>
    )
  };

  const other = () => {
    return (
      <React.Fragment>
        {otherItems.slice(0, 5).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
        <h2>Información del beneficiario</h2>
        {otherItems.slice(5, 10).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
        <h2>Información del banco intermediario</h2>
        {otherItems.slice(10, 15).map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
      </React.Fragment>
    )
  }

  const defineTransferType = (type) => {
    switch (type) {
      case 'NATIONAL':
        return national();
      case 'DOMESTIC_TRANSFER_US':
        return domesticTransferUs();
      case 'DOMESTIC_TRANSFER_US_WITH_BANK':
        return domesticTransferUsWithBank();
      default:
        return other();
    }
  }

  return (
    <div className='bank-account-detail-main'>
      <div className='bank-account-body-container'>
        <div className='header-section'>
          <h2>Detalles de cuenta bancaria</h2>
          <p>
            Estado:
            <br />
            <strong className={accountStatus[data.status].color}>
              {accountStatus[data.status].name}
            </strong>
          </p>
        </div>
        <div className="slot-details body-section">
          {initialItems.map(({ title, value }, key) => <OptionDetail title={title} value={value} key={key} />)}
          {!isEmpty(data.transferType) && defineTransferType(data.transferType)}
        </div>
      </div>
      {editPermission &&
        <div className='buttons-container'>
          <div className='buttons-left'>
          {showButton.success.includes(data.status) &&
            <CustomButton
              text="Aceptar"
              onClick={() => handleApproveAccount(data.id)}
              className="green"
              disabled={approvedButton}
            />
          }
          {showButton.danger.includes(data.status) &&
            <CustomButton
              text="Rechazar"
              onClick={() => handleRejectedAccount(data.id)}
              className="red"
              disabled={rejectedButton}
            />
          }
          </div>
        </div>
      }
    </div>
  )
}

export default BankAccountDetails