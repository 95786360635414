import isEmpty from "is-empty";
import UtilsHelper from "../../helpers/Utils.helper";

export const createComment = async (userId, levelId, data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const path = "/api/levels/internal/message";

  let model = new FormData();
  model.append("level_id", levelId);
  model.append("message", data.message);
  model.append("type", data.type);
  if (!isEmpty(data.images)) {
    data.images.forEach((file) => {
      model.append("image", file);
    });
  }
  const configRequest = {
    userId,
    method: "POST",
    headers,
    path,
    data: model,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const deleteComment = async (userId, message_id) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const path = `/api/levels/internal/message/${message_id}`;

  let model = new FormData();
  model.append("deleted", true);

  const configRequest = {
    userId,
    method: "PUT",
    headers,
    path,
    data: model,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const editComment = async (userId, id, comment) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const path = `/api/levels/internal/message/${id}`;

  let model = new FormData();
  model.append("message", comment);
  const configRequest = {
    userId,
    method: "PUT",
    headers,
    path,
    data: model,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const getComments = async (userId, levelId) => {
  const headers = {};
  const path = `/api/levels/internal/message/${levelId}`;

  const configRequest = {
    userId,
    method: "GET",
    headers,
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
