import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../hooks/useFilter";
import isEmpty from "is-empty";
import CountriesController from "../../FallControllers/CountriesController";
import AllCountriesData from "../../../components/FallComponents/CountriesPage/AllCountriesData";

const CountriesPage = (props, context) => {
  const { userInfo } = props;

  const [withRightSection, setWithRightSection] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState({});
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
    setFilterSearch,
    resetFilters
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const updateCountryStatus = async ({ status, countryCode }) => {
    return await CountriesController.updateCountryStatus({
      userInfo,
      data: { status, countryCode },
    });
  };

  const getCountries = () => {
    CountriesController.getCountries({
      userInfo,
      setLoading,
      setRows,
      setTotalRows,
      filterConfig,
      filterSearch,
    });
  };
  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getCountries();
  }, [userInfo, filterConfig, filterSearch]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllCountriesData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            getCountries={getCountries}
            selectedCountry={selectedCountry}
            updateCountryStatus={updateCountryStatus}
            setFilterSearch={setFilterSearch}
            resetFilters={resetFilters}
          />
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

CountriesPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(CountriesPage);
