import isEmpty from 'is-empty'
import React from 'react'

export const NationalTemplate = ({ transferType, withdrawTemplate, accountType, context }) => {
    return (
        <div className='slot-details'>
            <div className='option'>
                <p>
                    <b>{context.t('Transfer Type')}:</b>
                </p>
                <p>
                    {!isEmpty(transferType) ? transferType.name : ''}
                </p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Account type')}:</b>
                </p>
                <p>{!isEmpty(accountType) ? accountType.name : ''}</p>
            </div>
            <div className='option'>
                <p>
                    <b>
                        {context.t('Destination Bank Account Number')}:
                    </b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.accountSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Account CCI')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.accountSourceCci}</p>
            </div>
            <div className='option'>
                <p>
                    <b>
                        {context.t('Destination Bank Account Name')}:
                    </b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankNameSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Country')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankCountrySource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank City')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankCity}</p>
            </div>
        </div>
    )
}
