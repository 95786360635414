import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import AddIcon from "../../../images/add-icon.svg";
import DateHelper from "../../../helpers/Date.helper";
import { Switch } from "../Switch/Switch";
import { useModal } from "../../../hooks/useModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import DataFormatterHelper from "../../../helpers/DataFormatter.helper";
import { CURRENT_LEVEL } from "../../../constants";

const AllDepositLimitsData = (props, context) => {
  const {
    onChangeLimit,
    totalRows,
    rows,
    loading,
    handleSelectRow,
    selectedId,
    getAllDepositLimits,
    handleUpdateLimit,
    handleAddDepositLimitModal
  } = props;
  const { showModal, handleToggleModal } = useModal();
  const [checkedData, setCheckedData] = useState({
    active: false,
    id: null,
  });

  const CheckboxStatus = ({ data }) => {
    const handleToggleLimitStatus = (ev) => {
      handleToggleModal();
      setCheckedData({ active: ev.target.checked, id: data.id });
    };
    return (
      <Switch
        defaultChecked={data.active}
        onChange={handleToggleLimitStatus}
        id={`switch-toggle-bnx-account-${data.id}`}
      />
    );
  };

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleAddDepositLimitModal,
    },
    {
      icon: RotateRight,
      action: getAllDepositLimits,
    },
  ];

  const handleConfirmActionModal = async () => {
    const response = await handleUpdateLimit({checkedData})
    if(response){
      getAllDepositLimits()
    }
  }

  const tableHeaders = [
    {
      tag: "createdAt",
      title: "Fecha de creación",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "updatedAt",
      title: "Fecha de actualización",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.updatedAt)}</p>,
    },
    {
      tag: "",
      title: "Nivel de usuario",
      handleSelectRow,
      cell:({data}) => <p>{`${(CURRENT_LEVEL.find(level => level.value == data.userLevel) || {}).title || ""} (Nivel ${data.userLevel})`}</p> 
    },
    {
      tag: "",
      title: "Umbral de transaccción única",
      handleSelectRow,
      cell:({data}) => <p>{DataFormatterHelper.currencyFormatter({currency:"USD",amount:data.dailyAmount})}</p>
    },
    {
      tag: "",
      title: "Umbral de transaccción múltiple",
      handleSelectRow,
      cell:({data}) => <p>{DataFormatterHelper.currencyFormatter({currency:"USD",amount:data.monthlyAmount})}</p>
    },
    {
      tag: "",
      title: "Activado",
      cell: ({ data }) => <CheckboxStatus data={data} />,
    },
  ];
  
  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Umbrales de depósito")}
        subTitle="Cumplimiento / Umbrales de depósito"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        headers={tableHeaders}
        rowIdSelected={selectedId}
        enablePagination={false}
      />
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={() => {
          handleToggleModal();
        }}
        title="Activar / Desactivar umbral"
        message={`¿Desea ${
          !checkedData.active ? "desactivar" : "activar"
        } umbral seleccionado?`}
        confirmButtonProps={{
          onClick: handleConfirmActionModal,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllDepositLimitsData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllDepositLimitsData);
