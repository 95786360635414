import isEmpty from 'is-empty'
import React, { useState } from 'react'
import ComponentsHelper from '../../../helpers/Components.helper'
import DataFormatterHelper from '../../../helpers/DataFormatter.helper'
import DateHelper from '../../../helpers/Date.helper'
import { useForm } from '../../../hooks/formHooks'
import { GroupFilters } from '../Filters/Filters'
import HeaderPage from '../HeaderPage/HeaderPage'
import TableContainer from '../Table/TableContainer'
import Setting from '../../../images/new_icons/setting.svg'
import SettingLight from '../../../images/new_icons/setting-light.svg'
import Download from '../../../images/new_icons/download.svg'
import RotateRight from '../../../images/new_icons/rotate-right.svg'

const AllTradeReportPage = (props) => {

    const { loading, setFilterSearch, onChangeLimit, filterConfig, totalRows, offset, showingTo, changePage, rows, withRightSection, initialConfig, handleSelectRow, tradeId, handleDownloadTradesReport } = props;

    const filterInitialValues = {
        apUsername: "",
        apMakertaker: "",
        apQuantity: "",
        binanceExecutedqty: "",
        binanceOrigqty: "",
        binanceSide: "",
        binanceSymbol: "",
        binanceType: ""
    };

    const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(filterInitialValues);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});

    const handleShowFilters = () => setShowFilters(!showFilters);

    const handleResetFilters = () => {
        setInputs(filterInitialValues);
        setFilters({})
        initialConfig();
    };

    const handleOnChange = (e) => {
        onInputChangeV2(e);
        setFilters({
            ...filters,
            [e.name]: !isEmpty(e.value) ? { includesInsensitive: String(e.value) } : ''
        });
    }

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: '',
            title: 'AP trade datetime',
            cell: ({ data }) => DateHelper.formatDate(data.apTradeDatetime),
            handleSelectRow
        },
        {
            tag: 'apAccountname',
            title: 'AP usuario',
            handleSelectRow
        },
        {
            tag: 'apInstrumentsymbol',
            title: 'AP símbolo',
            handleSelectRow
        },
        {
            tag: 'apMakertaker',
            title: 'AP maker/taker',
            handleSelectRow
        },
        {
            tag: 'apPrice',
            title: 'AP price',
            handleSelectRow
        },
        {
            tag: 'apQuantity',
            title: 'AP quantity',
            handleSelectRow
        },
        {
            tag: '',
            title: 'Diferencias/Precios',
            cell: ({ data }) => Number(data.apPrice) - Number(data.binancePrice),
            handleSelectRow
        },
        {
            tag: 'binanceExecutedqty',
            title: 'Provider executed quantity',
            handleSelectRow
        },
        {
            tag: 'binanceOrigqty',
            title: 'Provider orig quantity',
            handleSelectRow
        },
        {
            tag: 'binanceSide',
            title: 'Provider side',
            handleSelectRow
        },
        {
            tag: 'binanceSymbol',
            title: 'Provider symbol',
            handleSelectRow
        },
        {
            tag: 'binanceType',
            title: 'Provider type',
            handleSelectRow
        }
    ]

    const handleSearchFilters = () => {
        const newFilters = DataFormatterHelper.cleanJSONFilters(filters);
        Object.keys(newFilters).forEach(element => {
            newFilters[element] === '' && delete newFilters[element];
        });
        setFilterSearch(newFilters)
    };

    const apMakertaker = [
        { value: "Maker", name: "Maker" },
        { value: "Taker", name: "Taker" }
    ];

    const binanceSide = [
        { value: "SELL", name: "Sell" },
        { value: "BUY", name: "Buy" }
    ];

    const headerOptions = [
        {
            icon: showFilters ? SettingLight : Setting,
            action: handleShowFilters,
            className: showFilters ? 'header-option-active' : ''
        },
        {
            icon: Download,
            action: handleDownloadTradesReport
        },
        {
            icon: RotateRight,
            action: handleResetFilters
        }
    ];

    const inputs = [
        {
            values: {
                title: 'AP usuario',
                name: 'apUsername',
                value: inputsFilters.apUsername,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: "AP maker/taker",
                name: "apMakertaker",
                value: inputsFilters.apMakertaker,
                type: "select",
                options: apMakertaker,
                required: true,
            },
            actions: {
                onChange: handleOnChange,
            },
        },
        {
            values: {
                title: 'AP quantity',
                name: 'apQuantity',
                value: inputsFilters.apQuantity,
                type: 'number',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Provider executed quantity',
                name: 'binanceExecutedqty',
                value: inputsFilters.binanceExecutedqty,
                type: 'number',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Provider orig quantity',
                name: 'binanceOrigqty',
                value: inputsFilters.binanceOrigqty,
                type: 'number',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: "Provider side",
                name: "binanceSide",
                value: inputsFilters.binanceSide,
                type: "select",
                options: binanceSide,
                required: true,
            },
            actions: {
                onChange: handleOnChange,
            },
        },
        {
            values: {
                title: 'Provider symbol',
                name: 'binanceSymbol',
                value: inputsFilters.binanceSymbol,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Provider type',
                name: 'binanceType',
                value: inputsFilters.binanceType,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        }
    ];

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Reporte de Intercambios"
                subTitle="Reportes / Reporte de Intercambios"
                headerOptions={headerOptions}
            />
            <TableContainer
                onChangeLimit={onChangeLimit}
                filterConfig={filterConfig}
                totalRows={totalRows}
                rows={rows}
                loading={loading}
                offset={offset}
                showingTo={showingTo}
                changePage={changePage}
                headers={tableHeaders}
                rowIdSelected={tradeId}
            >
                {
                    showFilters &&
                    <GroupFilters
                        handleSearchFilters={handleSearchFilters}
                        withRightSection={withRightSection}
                        inputs={inputs}
                    />
                }
            </TableContainer>
        </div>
    )
}

export default AllTradeReportPage