import React from 'react'
import ComponentsHelper from '../../../helpers/Components.helper';
import HeaderPage from '../HeaderPage/HeaderPage'
import TableContainer from '../Table/TableContainer'
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import AddIcon from "../../../images/add-icon.svg";
import { useModal } from '../../../hooks/useModal';
import CreateCategoryModal from './Modals/Category/CreateCategoryModal';
import DateHelper from '../../../helpers/Date.helper';

const AllCategoriesAndModulesPage = (props) => {

    const { loading, categoryList, reloadData, handleCreateCategory, handleSelectRow, categoryId, context } = props;

    const { showModal, handleToggleModal } = useModal();

    const headerOptions = [
        {
            icon: AddIcon,
            action: handleToggleModal,
        },
        {
            icon: RotateRight,
            action: reloadData
        }
    ];

    const handleModulesName = (array) => array.map(element => element.name).toString().replaceAll(",", ", ") || '-';

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: '',
            title: 'Fecha de creación',
            cell: ({ data }) => DateHelper.formatDate(data.createdAt),
            handleSelectRow
        },
        {
            tag: 'name',
            title: 'Nombre de categoría',
            handleSelectRow
        },
        {
            tag: 'modulesName',
            title: 'Lista de módulos',
            cell: ({ data }) => handleModulesName(data.modulesList),
            handleSelectRow
        }
    ];

    return (
        <React.Fragment>
            <div className='all-page-data'>
                <HeaderPage
                    title="Categorías y módulos"
                    subTitle="Configuraciones / Categorías y módulos"
                    headerOptions={headerOptions}
                />
                <TableContainer
                    loading={loading}
                    headers={tableHeaders}
                    rows={categoryList}
                    enablePagination={false}
                    rowIdSelected={categoryId}
                />
            </div>
            <CreateCategoryModal
                showModal={showModal}
                handleToggleModal={handleToggleModal}
                handleCreateCategory={handleCreateCategory}
                context={context}
            />
        </React.Fragment>
    )
}

export default AllCategoriesAndModulesPage