import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import down from '../../../images/new_icons/arrow-down.svg';

const AccordionMultiple = ({ list = [] }) => {
   return (
      <Accordion>
         {list.map(({ title, Component }, key) =>
            <Card className="accordion-card" key={key}>
               <Card.Header>
                  <Accordion.Toggle variant="link" eventKey={key+1}>
                     {title} <img src={down} alt="arrow to down" />
                  </Accordion.Toggle>
               </Card.Header>
               <Accordion.Collapse eventKey={key+1}>
                  <Card.Body>{Component}</Card.Body>
               </Accordion.Collapse>
            </Card>
         )}
      </Accordion>
   );
};

export default AccordionMultiple;