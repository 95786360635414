import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import DateHelper from "../../../helpers/Date.helper";
import "./allTxHashData.css";
import { useForm } from "../../../hooks/formHooks";
import { GroupFilters } from "../Filters/Filters";

const AllTxHashData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    handleSelectRow,
    selectedId,
    withRightSection,
    setFilterSearch,
    resetFilters
  } = props;

  const [showFilters, setShowFilters] = useState(false);

  const filterInitialValues = {
    ticketNumber: '',
    ticketAccountName: ''
  };

  const { inputs: filterInputs, onInputChangeV2, setInputs } = useForm(
    filterInitialValues,
    handleSearchFilters
  );

  const handleSearchFilters = () => {
    Object.keys(filterInputs).forEach(element => {
      filterInputs[element] === '' && delete filterInputs[element];
    });
    setFilterSearch(filterInputs);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? 'header-option-active' : ''
    },
    {
      icon: RotateRight,
      action: () => {
        resetFilters()
        setInputs(filterInitialValues)
      },
    },
  ];

  const tableHeaders = [
    {
      tag: "",
      title: "Fecha de creación",
      handleSelectRow,
      cell:({data}) => <p>{DateHelper.formatDate(data.createdAt)}</p>
    },
    {
      tag: "",
      title: "Fecha de actualización",
      handleSelectRow,
      cell:({data}) => <p>{DateHelper.formatDate(data.updatedAt)}</p>
    },
    {
      tag: "",
      title: "Estado de ticket",
      handleSelectRow,
      cell: ({ data }) => <p>{data.status === 1 ? "Activo" : "Inactivo"}</p>,
    },
    {
      tag: "ticketNumber",
      title: "Número de ticket",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Tipo",
      handleSelectRow,
      cell: ({data}) => <p>{data.type}</p>
    },
    {
      tag: "txId",
      title: "ID de transacción",
      handleSelectRow,
    },
    {
      tag: "accountId",
      title: "ID de cuenta",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Usuario",
      handleSelectRow,
      cell: ({ data }) => (
        <p>{`${data.banexcoinUserByBanexcoinUserId.banexcoinUsername} (${data.banexcoinUserByBanexcoinUserId.email})`}</p>
      ),
    },
  ];

  const filters = [
    {
      values: {
        title: 'Número de ticket',
        name: 'ticketNumber',
        value: filterInputs.ticketNumber,
        type: 'number',
        required:true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Usuario',
        name: 'ticketAccountName',
        value: filterInputs.ticketAccountName,
        type: 'text',
        required:true
      },  
      actions: {
        onChange: onInputChangeV2
      }
    }
  ];

  return (
    <div className="all-withdraw-data">
      <HeaderPage
        title={context.t("Tx Hash")}
        subTitle="Tickets / Tx Hash"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}
      >
         {showFilters && (
          <GroupFilters
            handleSearchFilters={handleSearchFilters}
            withRightSection={withRightSection}
            inputs={filters}
          />
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllTxHashData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllTxHashData);
