export const invoicesTypes = {
  FACTURA_ACTIVA_SERIE_BOLETA: "Boleta",
  FACTURA_ACTIVA_SERIE_FACTURA: "Factura",
  FACTURA_ACTIVA_SERIE_NOTA_CREDITO_BOLETA: "Nota de Crédito (Boleta)",
  FACTURA_ACTIVA_SERIE_NOTA_CREDITO_FACTURA: "Nota de Crédito (Factura)",
};

export const invoicesTypesInput = [
  {
    value:"rec",
    name:"Receipt"
  },
  {
    value:"inv",
    name:"Invoice"
  },
  {
    value:"notcredrec",
    name:"Credit Note (Receipt)"
  },
  {
    value:"notcredinv",
    name:"Credit Note (Invoice)"
  },
]