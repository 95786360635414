import React, { useState } from "react";
import UserPermitsController from "../../../../pages/FallControllers/UserPermitsController";
import { TabsContainer } from "../../Tabs/TabsContainer";
import './usersDetails.css'
import {MainDetails,Permits} from "./Tabs";
import RolesAccessHelper from '../../../../helpers/RoleAccess.helper'
import { History } from "./Tabs/History";

function UsersDetails({ data, userInfo, resetUserDetails, context, profiles, lastLoginInfo,logs, mainIsEditable, setMainIsEditable }) {
  const [loading,setLoading] = useState(false)
  const [tabSelect,setTabSelect] = useState("Detalles")

  const handleFreeUserSms = async () => {
    const response = await UserPermitsController.freeUserSmsById({
      userInfo,
      setLoading,
      id: data.id,
    });
    if (response) {
      resetUserDetails();
    }
  };

  const handleUpdateUserType = async (newUserType, callback) => {
    const response = await UserPermitsController.updateUserType({
      userInfo,
      id: data.id,
      data: { usertype: newUserType },
    });
    if (!response) {
      callback();
      return
    }
    resetUserDetails()
  };

  const HeaderSection = () => {
    return (
      <div className="header-section">
        <h2>{data.banexcoinUsername}</h2>
        <div className="option">
          <p>Banexcoin user ID: </p>
          <p> {data.banexcoinUserid}</p>
        </div>
      </div>
    );
  };

  const tabsOptions = [
    {
      title: 'Detalles',
      component: (
        <MainDetails
          data={data}
          handleFreeUserSms={handleFreeUserSms}
          loading={loading}
          updateUserType={handleUpdateUserType}
          lastLoginInfo={lastLoginInfo}
          isEditable={mainIsEditable}
          setIsEditable={setMainIsEditable}
        />
      ),
      default: true,
    },
    {
      title: 'Permisos',
      component: (
        <Permits
          data={data}
          userInfo={userInfo}
          resetUserDetails={resetUserDetails}
          profiles={profiles}
          context={context}
        />
      ),
      default: false,
      disabled: !RolesAccessHelper.hasAccesstoAction("user_edit_role"),
    },
    {
      title: 'Historial',
      component: (
        <History
          logs={logs}
          context={context}
        />
      ),
      default: false,
      disabled: !RolesAccessHelper.hasAccesstoAction("user_edit_role"),
    },
  ];

  return (
    <div className="resizable-details-container">
      <HeaderSection />
      <div className="container-tabs">
        <TabsContainer
          tabsOptions={tabsOptions}
          tabSelect={tabSelect}
          setTabSelect={setTabSelect}
        />
      </div>
    </div>
  );
}

export default UsersDetails;
