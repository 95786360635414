import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AddForm from "./AddForm";
import "./modal.css";

const ModalAddBlackList = (props, context) => {
  const {
    showModal,
    handleToggleModal,
    title,
    userInfo,
    type,
    getAllResumeByType
  } = props;

  const buttonProps = {
    text: "Añadir registros",
    className: "purple",
    customClassName:"addblacklist-add-rows-button"
  }

  return (
    <Modal className="blacklist-add-modal" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {title}
      </ModalHeader>
      <ModalBody>
        <AddForm
          handleToggleModal={handleToggleModal}
          buttonProps={buttonProps}
          userInfo={userInfo}
          type={type}
          getAllResumeByType={getAllResumeByType}
        />
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
ModalAddBlackList.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(ModalAddBlackList);
