import UtilsHelper from '../../helpers/Utils.helper';

export const getBankMovements = async (data, userId) => {
   try {
      const path = '/api/v1/admin/tools/convert/salcedo';
      let model = new FormData();
      model.append('files', data.bankingMovementsFile);
      const configRequest = {
         userId,
         method: 'post',
         data: model,
         path
      };
      const response = await UtilsHelper.sendRequest(configRequest);
      return (response.data.hasOwnProperty('code')) ? response.data.data : [];
   } catch (error) {
      return false;
   }
};