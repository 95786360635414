export const optionsLevel = [
  {
    name: 'Nivel 0',
    value: 0
  },
  {
    name: 'Nivel 10',
    value: 10
  },
  {
    name: 'Nivel 11',
    value: 11
  },
  {
    name: 'Nivel 12',
    value: 12
  },
  {
    name: 'Nivel 13',
    value: 13
  },
  {
    name: 'Nivel 14',
    value: 14
  },
  {
    name: 'Nivel 100',
    value: 100
  },
  {
    name: 'Nivel 101',
    value: 101
  }
];
export const optionsLevel2 = [
  {
    name: 'Sin filtro',
    value: 'empty'
  },
  {
    name: 'Nivel 0',
    value: '0'
  },
  {
    name: 'Nivel 10',
    value: '10'
  },
  {
    name: 'Nivel 11',
    value: '11'
  },
  {
    name: 'Nivel 12',
    value: '12'
  },
  {
    name: 'Nivel 13',
    value: '13'
  },
  {
    name: 'Nivel 14',
    value: '14'
  },
  {
    name: 'Nivel 100',
    value: '100'
  },
  {
    name: 'Nivel 101',
    value: '101'
  }
];

export const legalLevelOptions = [
  {
    name: 'Sin filtro',
    value: 'empty'
  },
  {
    name: 'Nivel 17',
    value: '17'
  },
  {
    name: 'Nivel 18',
    value: '18'
  }
];