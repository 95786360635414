import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, Form, Modal, Button } from 'react-bootstrap';
import empty from 'is-empty';
import { Link } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class CropImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      image_name: '',
      crop: {
        unit: '%',
        width: 100,
        height: 100
      }
    };
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  onDropFile = () => {
    this.setState({ src: null });
    this.props.setBlobByName(this.props.name, null);
    let emptyObject = {
      target: {
        name: this.props.name,
        files: []
      }
    };
    this.props.onInputFileChange(emptyObject);
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      let timestamp = Date.now();
      let filename = this.props.name + timestamp + '.jpeg';
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        filename
      );

      let file = await fetch(croppedImageUrl)
        .then(r => r.blob())
        .then(
          blobFile => new File([blobFile], filename, { type: 'image/png' })
        );
      let croppedData = {
        persist: null,
        target: {
          name: this.props.name,
          files: [file]
        }
      };
      this.props.setBlobByName(this.props.name, croppedImageUrl);
      this.props.onInputFileChange(croppedData);
      this.setState({ croppedImageUrl });
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * 3;
    canvas.height = crop.height * 3;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * 3,
      crop.height * 3
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  setName(name) {
    this.setState({ image_name: name });
  }

  componentDidMount() {
    let name = this.props.name;
    let timestamp = Date.now();
    this.setName(name + timestamp);
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const {
      name,
      disabled,
      imagesBlob,
      accept,
      activePreview,
      required
    } = this.props;

    return (
      <React.Fragment>
        {src && !disabled ? (
          <button
            type="button"
            onClick={() => {
              this.onDropFile();
            }}
            className="drop_btn"
            title="Borrar Imagen">
            X
          </button>
        ) : (
          <Form.Control
            required={!empty(required) ? required : false}
            name={name}
            type="file"
            accept={accept}
            onChange={this.onSelectFile}
            disabled={disabled}
            className={!empty(this.props.className) ? this.props.className : ''}
          />
        )}

        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            className="middle-row"
          />
        )}
        {!empty(activePreview) && activePreview && croppedImageUrl ? (
          src && !disabled ? (
            <img
              alt="Crop"
              style={{ maxWidth: '100%' }}
              src={croppedImageUrl}
              id={name + '-image'}
              className="middle-row"
            />
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {/* {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
        )} */}
      </React.Fragment>
    );
  }
}

CropImage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CropImage;
