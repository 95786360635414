import React, { useState } from 'react';
import SubHeaderPage from '../../HeaderPage/SubHeaderPage';
import InputElement from '../../InputElement/InputElement';
import TableContainer from '../../Table/TableContainer';
import Setting from '../../../../images/new_icons/setting.svg';
import SettingLight from '../../../../images/new_icons/setting-light.svg';
import RotateRight from '../../../../images/new_icons/rotate-right.svg';
import AddIcon from "../../../../images/add-icon.svg";
import ComponentsHelper from '../../../../helpers/Components.helper';
import DateHelper from '../../../../helpers/Date.helper';
import { useModal } from '../../../../hooks/useModal';
import { Switch } from '../../Switch/Switch';
import isEmpty from 'is-empty';
import ConfirmAssetStatusChange from './Modals/ConfirmAssetStatusChange';
import CreateAssets from './Modals/CreateAssets';
import { assets } from '../../../../constants';
import ConfirmAssetRestrictedChange from './Modals/ConfirmAssetRestrictedChange';
import ConfirmAssetRestrictedExchangeChange from './Modals/ConfirmAssetRestrictedExchangeChange';

const   AssetsList = (props) => {
    const { handleLoadActives, loading, handleSelectRow, listActives, totalRows, selectedId, handleDisableActive, handleActiveAsset, onChangeLimit, filterConfig, offset, showingTo, changePage, handleCreateNewAsset } = props;

    const [showFilters, setShowFilters] = useState(false);
    const { showModal, handleToggleModal } = useModal();
    const { showModal: showModal2, handleToggleModal: handleToggleModal2 } = useModal();
    const { showModal: showModal3, handleToggleModal: handleToggleModal3 } = useModal();
    const { showModal: showModal4, handleToggleModal: handleToggleModal4 } = useModal();
    const [checkedData, setCheckedData] = useState({
        isChecked: 0,
        id: null,
    });
    const [checkedRestricted, setCheckedRestricted] = useState({
        isChecked: false,
        id: null,
    });

    const [checkedRestrictedExchange, setCheckedRestrictedExchange] = useState({
        isChecked: false,
        id: null,
    });

    const [activeType, setActiveType] = useState('');

    const newListActives = !isEmpty(activeType) ? listActives.filter(({ type }) => type === activeType) : listActives;

    const handleShowFilters = () => setShowFilters(!showFilters);

    const handleAssetType = (value) => (assets.find(data => data.value === value) || {}).name;

    const CheckboxStatus = ({ data }) => {
        const handleToggleActiveBnxAccount = () => {
            setCheckedData({ isChecked: data.status, id: data.id });
            handleToggleModal();
        };
        return (
            <Switch
                defaultChecked={data.status}
                onChange={handleToggleActiveBnxAccount}
                id={`switch-toggle-bnx-account-${data.id}`}
            />
        );
    };

    const CheckboxRestricted = ({ data }) => {
        const handleToggleActiveBnxAccount = () => {
            console.log("data::", data)
            setCheckedRestricted({ isChecked: data.restricted, id: data.id });
            handleToggleModal3();
        };
        return (
            <Switch
                defaultChecked={Number(data.restricted)}
                onChange={handleToggleActiveBnxAccount}
                id={`switch-toggle-bnx-account-${data.id}-restricted`}
            />
        );
    };

    const CheckboxRestrictedExchange = ({ data }) => {
        const handleToggleActiveBnxAccount = () => {
            setCheckedRestrictedExchange({ isChecked: data.restrictedExchange, id: data.id });
            handleToggleModal4();
        };
        return (
            <Switch
                defaultChecked={Number(data.restrictedExchange)}
                onChange={handleToggleActiveBnxAccount}
                id={`switch-toggle-bnx-account-${data.id}-restrictedExchange`}
            />
        );
    };

    const headerOptions = [
        {
            icon: AddIcon,
            action: handleToggleModal2,
        },
        {
            icon: showFilters ? SettingLight : Setting,
            action: handleShowFilters,
            className: showFilters ? 'header-option-active' : ''
        },
        {
            icon: RotateRight,
            action: handleLoadActives
        }
    ];

    const input = {
        values: {
            title: 'Tipo de activo',
            name: 'activeType',
            value: activeType,
            type: 'select',
            options: [
                {
                    name: 'Todos',
                    value: ''
                },
                {
                    name: 'Producto',
                    value: 'PRODUCT'
                },
                {
                    name: 'Instrumento',
                    value: 'INSTRUMENT'
                }
            ],
            placeholder: 'Seleccione un activo',
            required: true
        },
        actions: {
            onChange: (data) => setActiveType(data.value)
        }
    }

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: '',
            title: 'Fecha de creación',
            cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
            handleSelectRow
        },
        {
            tag: 'value',
            title: 'Nombre de activo',
            handleSelectRow
        },
        {
            tag: '',
            title: 'Tipo',
            cell: ({ data }) => handleAssetType(data.type),
            handleSelectRow
        },
        {
            tag: '',
            title: 'Habilitado',
            cell: ({ data }) => <CheckboxStatus data={data} />
        },
        {
            tag: '',
            title: 'Restringido en Compra/venta',
            cell: ({ data }) => <CheckboxRestricted data={data} />
        },
        {
            tag: '',
            title: 'Restringido en Exchange',
            cell: ({ data }) => {
                if(data.type === 'PRODUCT') {
                    return <React.Fragment/>
                }
                return <CheckboxRestrictedExchange data={data} />
            }
        },
        {
            tag: '',
            title: 'Deshabilitado para nivel',
            cell: ({ data }) => <div className='levels-batches-container'>{data.restrictedLevels.map((level) => <span>{level}</span>)}</div>,
            handleSelectRow
        }
    ];

    return (
        <React.Fragment>
            <div className='section-list-data'>
                <SubHeaderPage
                    title="Lista de Activos"
                    headerOptions={headerOptions}
                />
                <TableContainer
                    onChangeLimit={onChangeLimit}
                    filterConfig={filterConfig}
                    offset={offset}
                    showingTo={showingTo}
                    changePage={changePage}
                    loading={loading}
                    headers={tableHeaders}
                    rows={newListActives}
                    totalRows={totalRows}
                    rowIdSelected={selectedId}
                >
                    {
                        showFilters &&
                        <div style={{ width: '250px', marginBottom: '2rem' }}>
                            <InputElement
                                values={input.values}
                                actions={input.actions}
                                autoComplete={'Off'}
                            />
                        </div>
                    }
                </TableContainer>
            </div>
            <ConfirmAssetStatusChange
                showModal={showModal}
                handleToggleModal={handleToggleModal}
                checkedData={checkedData}
                handleDisableActive={handleDisableActive}
                handleActiveAsset={handleActiveAsset}
            />
            <ConfirmAssetRestrictedChange
                showModal={showModal3}
                handleToggleModal={handleToggleModal3}
                checkedData={checkedRestricted}
                handleActiveAsset={handleActiveAsset}
            />
            <ConfirmAssetRestrictedExchangeChange
                showModal={showModal4}
                handleToggleModal={handleToggleModal4}
                checkedData={checkedRestrictedExchange}
                handleActiveAsset={handleActiveAsset}
            />
            <CreateAssets
                showModal={showModal2}
                handleToggleModal={handleToggleModal2}
                handleCreateNewAsset={handleCreateNewAsset}
            />
        </React.Fragment>
    )
}

export default AssetsList