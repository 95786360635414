import React from 'react';
import { Magnifier } from 'react-image-magnifiers';
import close from '../../../images/new_icons/close-circle.svg';
import './imagesZoom.css'
function ImagesZoom({ images, onClose }) {
  return (
    <div className="images-zoom-container">
      <div className="icon-close">
        <img
          src={close}
          onClick={() => onClose()}
          width="40px"
          height="40px"
        />
      </div>
      <div className='images-list'>
        {images && images.length > 0 &&
          images.map(image => (
            <Magnifier
              imageSrc={image.src}
              imageAlt={image.alt}
              largeImageSrc={image.src}
            />
          ))}
      </div>
    </div>
  );
}

export default ImagesZoom;
