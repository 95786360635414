import React, { useEffect, useState } from "react";
import TableContainer from "../../../components/FallComponents/Table/TableContainer";
import DateHelper from "../../../helpers/Date.helper";
import HeaderPage from "../HeaderPage/HeaderPage";
import "./allBanexcoinAccountsData.css";
import AddIcon from "../../../images/add-icon.svg";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Download from "../../../images/new_icons/download.svg";
import { Switch } from "../Switch/Switch";
import { accountUtilityList } from "../../../constants/components/Settings/accountUtility";
import { useModal } from "../../../hooks/useModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
function AllBanexcoinAccountsData(props) {
  const {
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    handleSelectRow,
    selectedId,
    handleAddBanexcoinAccount,
    handleToggleActiveBnxAccount,
    getAllBanexcoinAccounts,
    downloadRows,
    handleAccountsToShowByStatus,
    accessToAction
  } = props;

  const [
    isCheckedInputToShowActiveAccounts,
    setIsCheckedInputToShowActiveAccounts,
  ] = useState(false);

  const { showModal, handleToggleModal } = useModal();
  const [checkedData, setCheckedData] = useState({
    isChecked: false,
    id: null,
  });

  const CheckboxStatus = ({ data }) => {
    const handleToggleActiveBnxAccount = () => {
      handleToggleModal();
      setCheckedData({ isChecked: data.status, id: data.id });
    };
    return (
      <Switch
        defaultChecked={data.status}
        onChange={handleToggleActiveBnxAccount}
        id={`switch-toggle-bnx-account-${data.id}`}
      />
    );
  };

  const handleAccountUtility = (accountUtility) => {
    if (accountUtility === 3) {
      return "Depósito y retiro";
    }
    return accountUtilityList[accountUtility - 1].name;
  };

  const tableHeaders = [
    {
      tag: "createdAt",
      title: "Fecha de creación",
      cell: ({ data }) => DateHelper.formatDate(data.createdAt),
      handleSelectRow,
    },
    {
      tag: "fullName",
      title: "Nombre de cuenta bancaria",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Entidad bancaria",
      cell: ({ data }) => (
        <div className="td-bank-entity">
          <p>{data.swiftBankBySwiftBankId.bankName}</p>
          <img
            src={data.accountLogo}
            width="100px"
            alt={data.swiftBankBySwiftBankId.bankName}
            title={data.swiftBankBySwiftBankId.bankName}
          />
        </div>
      ),
      className: "w-200",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Tipo de utilidad",
      cell: ({ data }) => <p>{handleAccountUtility(data.accountUtility)}</p>,
      handleSelectRow,
    },
    {
      tag: "currencyCode",
      title: "Moneda",
      handleSelectRow,
    },
    {
      tag: "updatedAt",
      title: "Fecha de actualización",
      cell: ({ data }) => DateHelper.formatDate(data.updatedAt),
      handleSelectRow,
    },
    {
      tag: "",
      title: "Cuenta activada",
      cell: ({ data }) => (
        <div>
          {accessToAction ? (
            <CheckboxStatus data={data} />
          ) : (
            <p style={{fontSize:"16px"}}>{data.status === 1 ? "Si" : "No"}</p>
          )}
        </div>
      ),
    },
  ];

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleAddBanexcoinAccount,
    },
    {
      icon: Download,
      action: downloadRows,
    },
    {
      icon: RotateRight,
      action: () => {
        setIsCheckedInputToShowActiveAccounts(false);
        getAllBanexcoinAccounts();
      },
    },
  ];

  const handleConfirmActionModal = () =>
    handleToggleActiveBnxAccount(
      checkedData.isChecked,
      checkedData.id,
    );

  return (
    <React.Fragment>
      <div className="all-accounts-data">
        <HeaderPage
          title="Cuentas bancarias - Banexcoin"
          subTitle="Configuraciones / Cuentas bancarias - Banexcoin"
          headerOptions={headerOptions}
        />
        <div className="buttons-section">
          <label htmlFor="show-active-accounts">
            <input
              type="checkbox"
              id="show-active-accounts"
              onChange={(ev) => {
                handleAccountsToShowByStatus(ev);
                setIsCheckedInputToShowActiveAccounts(
                  !isCheckedInputToShowActiveAccounts
                );
              }}
              checked={isCheckedInputToShowActiveAccounts}
            />
            <span>Mostrar solo cuentas activas</span>
          </label>
        </div>
        <TableContainer
          onChangeLimit={() => {}}
          filterConfig={filterConfig}
          totalRows={totalRows}
          rows={rows}
          loading={loading}
          offset={offset}
          showingTo={showingTo}
          changePage={changePage}
          headers={tableHeaders}
          rowIdSelected={selectedId}
          enablePagination={false}
        />
      </div>
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={() => {
          handleToggleModal();
        }}
        title="Activar / Desactivar cuenta"
        message={`¿Desea ${
          checkedData.isChecked === 1 ? "desactivar" : "activar"
        } la cuenta bancaria seleccionada?`}
        confirmButtonProps={{
          onClick: handleConfirmActionModal,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </React.Fragment>
  );
}

export default AllBanexcoinAccountsData;
