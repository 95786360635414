import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import AccordionComponent from '../../../Accordion/AccordionComponent';
import { CustomButton } from '../../../CustomButton/CustomButton';
import isEmpty from 'is-empty';
import ComponentsHelper from '../../../../../helpers/Components.helper';
import { identificationNumberType, nationality, nonProfit, typeOfLegalPerson } from '../../../../../constants';
import { useForm } from '../../../../../hooks/formHooks';
import { states } from '../../../../../config/staticWorldContent';
import { province } from '../../../../../config/staticPeruProv';
import { districts } from '../../../../../config/staticPeruDist';
import DataFormatterHelper from '../../../../../helpers/DataFormatter.helper';
import IncorrectInformationModal from '../Components/IncorrectInformationModal';
import InformationSection from '../../../InformationSection/InformationSection';

const UserLegal = (props, context) => {

   const { levelBasicInfo, countries, handleIncrementLegalUserById, handleRejecLegalUserById, handleReturnCorporateInfoFields, handleUpdateCorporateInfo } = props;

   const { reviewStatus, dataForm, status, id, banexcoinUserid } = levelBasicInfo;
   const newDataForm = JSON.parse(dataForm);

   const [returnData, setReturnData] = useState([]);
   const [showModal, setShowModal] = useState(false);

   const [loaderIncorrect, setLoaderIncorrect] = useState(false);
   const [loaderReject, setLoaderReject] = useState(false);
   const [loaderAccept, setLoaderAccept] = useState(false);

   const initialValues = {
      company_legal_name: '',
      legal_entity_type: '',
      non_profit: '',
      other_non_profit: '',
      other_legal_entity_type: '',
      taxpayer_identification_number_type: '',
      other_taxpayer_identification_number_type: '',
      taxpayer_identification_number: '',
      corporate_purpose: '',
      ip: '',
      company_address: '',
      corporate_building: '',
      corporate_apt_number: '',
      corporate_town: '',
      country_incorporation: '',
      state: '',
      city: '',
      corporate_district: '',
      zip_code: '',
      ubigeo: '',
      registration_date: '',
      begin_date: '',
      linked_economic_group: '',
      company_phone: '',

      full_name: '',
      job_position: '',
      phone: '',

      company_constitution_act: '',
      incorporation_document: '',
      receipt_public_service: '',
      last_income_tax_return: '',
      affidavit_origins_funds: '',
      ruc_file: ''
   };

   const { inputs, onInputChangeV2, onInputFileChange, setInputs } = useForm(initialValues);

   const availableButtons = () => {
      if (reviewStatus === 'ACCEPTED') return null;
      return (
         <React.Fragment>
            {status !== 7 &&
               <CustomButton
                  text="Desaprobar"
                  onClick={rejectUserRequest}
                  className="red"
                  disabled={loaderReject}
                  customClassName='btn-corporate-reject'
               />
            }
            <CustomButton
               text="Aprobar"
               onClick={confirmUserRequest}
               className="green"
               disabled={loaderAccept}
               customClassName='btn-corporate-accept'
            />
         </React.Fragment>
      );
   };

   const checkReturnData = (e, label) => {
      e.persist();
      const { name, checked } = e.target;
      let newReturnData = [];
      if (checked) {
         newReturnData = [
            ...returnData,
            {
               label_en: label,
               label_es: context.t(label),
               name: name
            }
         ]
      } else {
         newReturnData = returnData.filter(data => data.name !== name);
      }
      setReturnData(newReturnData);
   };

   const rejectUserRequest = () => {
      ComponentsHelper.confirmAlert({
         title: 'Rechazar solicitud',
         message:
            '¿Está seguro de que desea RECHAZAR la solicitud? Al rechazar la solicitud el cliente no tendra accesos a los servicios de Banexcoin',
         buttons: [
            {
               label: 'Aceptar',
               onClick: () => handleRejecLegalUserById(setLoaderReject)
            },
            {
               label: 'Cancelar',
               onClick: () => { }
            }
         ]
      });
   };

   const confirmUserRequest = () => {
      ComponentsHelper.confirmAlert({
         title: 'Aprobar solicitud',
         message:
            '¿Está seguro de que desea APROBAR la solicitud? Al aprobar la solicitud el cliente será promovido al nivel de verificación solicitado',
         buttons: [
            {
               label: 'Aceptar',
               onClick: () => handleIncrementLegalUserById(setLoaderAccept)
            },
            {
               label: 'Cancelar',
               onClick: () => { }
            }
         ]
      });
   };

   const handleCorrectInformation = (e, message) => {
      e.preventDefault();
      setLoaderIncorrect(true);
      setShowModal(false)
      handleReturnCorporateInfoFields(returnData, message, setLoaderIncorrect);
   };

   const defineLanguage = () => {
      const countryIncorporation = newDataForm.country_incorporation;
      let result = 'EN';
      if (!isEmpty(countryIncorporation) && nationality.includes(countryIncorporation)) {
         result = 'ES';
      }
      return result;
   };

   const resetValues = (array) => {
      let initialvalues = {};
      array.map(({ name }) => {
         initialvalues[name] = newDataForm[name];
      });
      setInputs({
         ...inputs,
         ...initialvalues
      })
   };

   const submitData = () => {
      handleUpdateCorporateInfo({
         id,
         banexcoin_user_id: banexcoinUserid,
         ...inputs
      });
   }

   const listGeneralInfo = [
      {
         title: 'Datos corporativos',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Denomination or Full Legal Name of Company',
               name: 'company_legal_name',
               type: 'text'
            },
            {
               label: 'Type of Legal Entity',
               name: 'legal_entity_type',
               type: 'select',
               options: typeOfLegalPerson
            },
            {
               label: 'Non-Profit',
               name: 'non_profit',
               type: 'select',
               options: nonProfit,
               visible: inputs.legal_entity_type === 'Non-Profit'
            },
            {
               label: 'Other Non-Profit',
               name: 'other_non_profit',
               type: 'text',
               visible: (inputs.non_profit === 'Other' && inputs.legal_entity_type === 'Non-Profit')
            },
            {
               label: 'Other Type of Legal Entity',
               name: 'other_legal_entity_type',
               type: 'text',
               visible: inputs.legal_entity_type === 'Other'
            },
            {
               label: 'Type of Taxpayer Identification Number',
               name: 'taxpayer_identification_number_type',
               type: 'select',
               options: identificationNumberType
            },
            {
               label: 'Other Type of Taxpayer Identification Number',
               name: 'other_taxpayer_identification_number_type',
               type: 'text',
               visible: inputs.taxpayer_identification_number_type === 'Other'
            },
            {
               label: 'Taxpayer Identification Number',
               name: 'taxpayer_identification_number',
               type: 'text'
            },
            {
               label: 'Describe Corporate Purpose / Products or Services that the company provides',
               name: 'corporate_purpose',
               type: 'text'
            },
            {
               label: 'IP',
               name: 'ip',
               type: 'text',
               readOnly: true
            },
            {
               label: 'Enter Street / Avenue',
               name: 'company_address',
               type: 'text'
            },
            {
               label: 'Building number or name',
               name: 'corporate_building',
               type: 'text'
            },
            {
               label: 'Interior # / Apartment # / Office #',
               name: 'corporate_apt_number',
               type: 'text'
            },
            {
               label: 'Zonification / Town',
               name: 'corporate_town',
               type: 'text'
            },
            {
               label: 'Country',
               name: 'country_incorporation',
               type: 'select',
               options: countries
            },
            {
               label: 'State',
               name: 'state',
               type: 'select',
               options: DataFormatterHelper.selectArrayData(states[inputs.country_incorporation] || [])
            },
            {
               label: 'City / Province',
               name: 'city',
               type: inputs.country_incorporation === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(province[inputs.state] || [])
            },
            {
               label: 'Municipality / District',
               name: 'corporate_district',
               type: inputs.country_incorporation === 'PE' ? 'select' : 'text',
               options: DataFormatterHelper.selectArrayData(districts[inputs.city] || [])
            },
            {
               label: 'Zip Code',
               name: 'zip_code',
               type: 'text'
            },
            {
               label: 'Ubigeo',
               name: 'ubigeo',
               type: 'text'
            },
            {
               label: 'Registration Date (Constitution)',
               name: 'registration_date',
               type: 'date'
            },
            {
               label: 'Activity begin date',
               name: 'begin_date',
               type: 'date'
            },
            {
               label: 'Linked economic group (if applicable)',
               name: 'linked_economic_group',
               type: 'text'
            },
            {
               label: 'Company Phone',
               name: 'company_phone',
               type: 'text'
            }
         ]
      },
      {
         title: 'Persona de contacto',
         resetValues: resetValues,
         submitData: submitData,
         fields: [
            {
               label: 'Full name',
               name: 'full_name',
               type: 'text'
            },
            {
               label: 'Job Position',
               name: 'job_position',
               type: 'text'
            },
            {
               label: 'Phone',
               name: 'phone',
               type: 'text'
            }
         ]
      }
   ];

   const corporateDocuments = {
      title: 'Documentos corporativos',
      resetValues: resetValues,
      submitData: submitData,
      fields: [
         {
            label: 'Company Constitution Act',
            name: 'company_constitution_act',
            type: 'file',
            titleFile: 'Acta de constitución'
         },
         {
            label: 'Incorporation Document / Validity of Power of Attorney no later than 3 months',
            name: 'incorporation_document',
            type: 'file',
            titleFile: 'Documento de incorporación'
         },
         {
            label: 'Receipt of Public Service (Electricity, Telephone, Water, etc. not more than 3 months)',
            name: 'receipt_public_service',
            type: 'file',
            titleFile: 'Recibo de servicio'
         },
         {
            label: 'Last income tax return',
            name: 'last_income_tax_return',
            type: 'file',
            titleFile: 'Declaración de inpuesto'
         },
         {
            label: 'Affidavit of Origins of funds',
            name: 'affidavit_origins_funds',
            type: 'file',
            titleFile: 'Declaración jurada'
         },
         {
            label: 'RUC file',
            name: 'ruc_file',
            type: 'file',
            titleFile: 'Ficha RUC'
         }
      ]
   };

   useEffect(() => {
      setInputs({
         ...newDataForm
      })
   }, [])

   return (
      <div className="content-tab">
         <div className="content-tab-container">
            {showModal && <IncorrectInformationModal
               showModal={showModal}
               setShowModal={setShowModal}
               handleSubmit={handleCorrectInformation}
               listData={returnData}
               defineLanguage={defineLanguage} />}
            <AccordionComponent
               title={'Información General'}
               Component={
                  <div className="general-info-container">
                     {listGeneralInfo.map((data, key) =>
                        <InformationSection
                           key={key}
                           data={data}
                           inputs={inputs}
                           onChange={onInputChangeV2}
                           checkReturnData={checkReturnData}
                           onInputFileChange={onInputFileChange}
                           context={context}
                        />
                     )}
                  </div>
               }
            />
            <div className='card-body'>
               <InformationSection
                  data={corporateDocuments}
                  inputs={inputs}
                  onChange={onInputChangeV2}
                  checkReturnData={checkReturnData}
                  onInputFileChange={onInputFileChange}
                  context={context}
               />
            </div>
         </div>
         <div className="buttons-container">
            <div className="buttons-left">
               {availableButtons()}
               <CustomButton
                  text="Información Incorrecta"
                  onClick={() => setShowModal(true)}
                  className="red"
                  disabled={loaderIncorrect ? loaderIncorrect : isEmpty(returnData)}
                  showLoader={loaderIncorrect}
                  customClassName='btn-corporate-incorrectInfo'
               />
            </div>
            <CustomButton text="Más info" onClick={() => { }} className="purple" />
         </div>
      </div>
   )
}

UserLegal.contextTypes = {
   t: PropTypes.func.isRequired
};

export default UserLegal;