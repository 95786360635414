import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import { getLevelsBeta, downloadAlllevelsBasic } from './AdminLevelsHooks';
import empty from 'is-empty';
import queryString from 'query-string';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Button, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import './AdminLevelsCorporate.css';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import TableContainer from './TableContent/TableContainer';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const AdminLevelsCorporatePageBeta = (props, context) => {
  const { userInfo, accounts } = props;

  if (empty(userInfo) || empty(accounts)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    limit: 10,
    page: 1
  });
  const [filterSearch, setFilterSearch] = useState({});

  async function loadLevels(config, search) {
    setLoading(1);

    try {
      let result = await getLevelsBeta(
        userInfo.UserId,
        !empty(config) ? config : filterConfig,
        !empty(search) ? search : filterSearch
      );
      setFetching(false);
      setRows(result.nodes);
      setFilterConfig({
        ...filterConfig,
        totalCount: result.totalCount
      });
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setLoading(0);
  }

  async function downloadBasicLevels() {
    try {
      setLoading(1);
      let result = await downloadAlllevelsBasic(userInfo.UserId);
      toast.success(context.t('Downloading...'), {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  useEffect(() => {
    if (!empty(userInfo)) {
      loadLevels();
    }
  }, []);

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className="content-container">
        <TableContainer
          fetching={fetching}
          rows={rows}
          filterConfig={filterConfig}
          setFilterConfig={setFilterConfig}
          filterSearch={filterSearch}
          setFilterSearch={setFilterSearch}
          loadLevels={loadLevels}
          downloadBasicLevels={downloadBasicLevels}
          {...props}
        />
      </div>
    </React.Fragment>
  );
};

AdminLevelsCorporatePageBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AdminLevelsCorporatePageBeta);
