import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getCountries, updateCountryByCode } from "../FallServices/CountriesServices";

class CountriesController {
  static getCountries = async ({
    userInfo,
    filterConfig,
    setLoading,
    filterSearch,
    setRows,
    setTotalRows,
  }) => {
    try {
      setLoading(true);
      const response = await getCountries(
        userInfo.UserId,
        filterConfig,
        filterSearch
      );
      if(!isEmpty(response.data) && response.status === 200){
        setRows(response.data.nodes)
        setTotalRows(response.data.totalCount)
        return true
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  static updateCountryStatus = async ({userInfo,data}) => {
    try {
        const response = await updateCountryByCode(userInfo.UserId,data.countryCode, data.status)
        if(!isEmpty(response.data) && !isEmpty(response.data.nodeId)) return true
        return false 
    }catch (error) {
        return false;
      }
  }
}

export default CountriesController;
