import React, { useState } from 'react';
import HeaderPage from '../HeaderPage/HeaderPage';
import TableContainer from '../Table/TableContainer';
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import { GroupFilters } from '../Filters/Filters';
import { useForm } from '../../../hooks/formHooks';
import { userRolesById, userTypes } from '../../../constants';
import DateHelper from '../../../helpers/Date.helper'
const AllUsersData = props => {
  const {
    withRightSection,
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    setFilterSearch,
    resetFilters,
    handleSelectRow,
    context,
    selectedId,
    setWithRightSection
  } = props;

  const [showFilters, setShowFilters] = useState(false);

  const filterInitialValues = {
    banexcoinUserid: '',
    banexcoinUsername: '',
    email: '',
  };

  const { inputs: filterInputs, onInputChangeV2, setInputs } = useForm(
    filterInitialValues,
    handleSearchFilters
  );

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearchFilters = () => {
    Object.keys(filterInputs).forEach(element => {
      filterInputs[element] === '' && delete filterInputs[element];
    });
    if(filterInputs.hasOwnProperty('banexcoinUserid')){
      filterInputs["banexcoinUserid"] = Number(filterInputs["banexcoinUserid"])
    }
    setFilterSearch(filterInputs);
  };

  const handleResetFilters = () => {
    setInputs(filterInitialValues);
    setWithRightSection(false)
    resetFilters();
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? 'header-option-active' : ''
    },
    {
      icon: RotateRight,
      action: handleResetFilters
    }
  ];

  const filters = [
    {
      values: {
        title: 'Banexcoin user ID',
        name: 'banexcoinUserid',
        value: filterInputs.banexcoinUserid,
        type: 'number',
        required:true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Nombre de usuario',
        name: 'banexcoinUsername',
        value: filterInputs.banexcoinUsername,
        type: 'text',
        required:true
      },  
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Correo electrónico',
        name: 'email',
        value: filterInputs.email,
        type: 'text',
        required:true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Tipo de usuario',
        name: 'usertype',
        value: filterInputs.usertype,
        type: 'select',
        required:true,
        options: userTypes,
        isClearable: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
  ];

  const tableHeaders = [
    {
      tag: 'banexcoinUserid',
      title: 'Banexcoin user ID',
      handleSelectRow,
    },
    {
      tag: '',
      title: 'Fecha de creación',
      handleSelectRow,
      cell:({data}) => <p>{DateHelper.formatDate(data.createdAt)}</p>
    },
    {
      tag: '',
      title: 'Fecha de actualización',
      handleSelectRow,
      cell:({data}) => <p>{DateHelper.formatDate(data.updatedAt)}</p>
    },
    {
      tag: 'banexcoinUsername',
      title: 'Nombre de usuario',
      handleSelectRow,
    },
    {
      tag: 'email',
      title: 'Correo electrónico',
      handleSelectRow
    },
    {
      tag: '',
      title: 'Teléfono',
      handleSelectRow,
      cell:({data}) => <p>{data.phone || "-"}</p>
    },
    {
      tag: '',
      title: 'Rol',
      handleSelectRow,
      cell:({data}) => <p>{userRolesById[data.roleId]}</p>
    },
    {
      tag: '',
      title: 'Tipo de usuario',
      handleSelectRow,
      cell:({data}) => <p>{(userTypes.find(item => item.value === data.usertype) || {}).name || "-"}</p>
    },
  ];

  return (
    <div className="all-container-data">
      <HeaderPage
        title={context.t("Usuarios")}
        subTitle="Permisos / Usuarios"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}>
        {showFilters && (
          <GroupFilters
            handleSearchFilters={handleSearchFilters}
            withRightSection={withRightSection}
            inputs={filters}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default AllUsersData;
