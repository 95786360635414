import UtilsHelper from '../../helpers/Utils.helper';

export const getCountries = async (userId, filterConfig, filterSearch) => {
    const path = `/api/countries?restricted=true`;
    const params = {
      filterConfig,
      filterSearch
    };
    const configRequest = {
      userId,
      method: 'POST',
      path,
      data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const updateCountryByCode = async (userId, code, status) => {
    const path = `/api/countries/${code}`;
    const configRequest = {
      userId,
      method: 'PUT',
      path,
      data: { status }
    };
    return await UtilsHelper.sendRequest(configRequest);
};
