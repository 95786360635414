import { connect } from 'react-redux';
import DepositTemplateFormComponent from './DepositTemplateFormComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';

var mapStateToProps = function mapStateToProps(state, _ref) {
  var submitting = _ref.submitting,
    baseClasses = _ref.baseClasses;
  return {
    template: state.deposit.template,
    product: depositProductSelector(state),
    submitting: submitting,
    baseClasses: baseClasses
  };
};

export default connect(mapStateToProps)(DepositTemplateFormComponent);
