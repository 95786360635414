export const acuantLogsTypes = [
  {
    value: "PROCESSED",
    name: "Processed",
  },
  {
    value: "FAIL-PROVIDER",
    name: "Failed",
  },
];
