import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CustomButton } from "../CustomButton/CustomButton";
import isEmpty from "is-empty";
import moment from "moment";
import xlsx from "json-as-xlsx";

function SelectRowsForm(props, context) {
  const { handleToggleModal, rows } = props;
  const buildColumns = (data) => {
    const getKeys = Object.keys(data[0]);
    const columns = getKeys.map((name) => {
        return {
            label: name,
            value: (row) => row[name],
        };
    });
    return columns;
  };
  const downloadRows = () => {
    let slots = []
    const arrayCheckbox = document.getElementsByName('select-row-item');
    for (let item of arrayCheckbox) {
      if (!item.checked) {
        slots.push(item.value);
      }
    }
    const customRows = JSON.parse(JSON.stringify(rows))
    const headers = Object.keys(rows[0])
    const customRowsToDownload = customRows.map(row => {
      headers.forEach(item => {
        if(slots.includes(item)){
          delete row[item]
        }else{
         if(typeof row[item] === "object"){
          for (let key in row[item]) {
            if(key.toLowerCase().includes("name")){
              row[item] = row[item][key]
            }
          }
         } 
        }
      });
      return row
    })

    const columns = buildColumns(customRowsToDownload);
    const data = [
        {
            sheet: "Datos",
            columns,
            content: customRowsToDownload
        }
    ];
    const settings = {
        fileName: `internal-users_${moment().format("YYYY-MM-DD")}`,
        extraLength: 10,
        writeOptions: {}
    };
    setTimeout(() => {
        xlsx(data, settings)
    }, 1);
  };

  return (
    <div>
      <div>
        {isEmpty(rows)
          ? "No hay registros para descargar"
          : Object.keys(rows[0]).map((item) => {
              return (
                <div className="select-row-modal-item">
                  <label>
                    <input type="checkbox" value={item} defaultChecked name="select-row-item"/>
                    <span>{item}</span>
                  </label>
                </div>
              );
            })}
      </div>
      <div className="container-submit-cancel-buttons">
        <CustomButton
          onClick={handleToggleModal}
          className="red"
          text="Cancelar"
          disabled={false}
          showLoader={false}
        />
        <CustomButton
          text="Descargar"
          className="green"
          onClick={downloadRows}
          disabled={false}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};
SelectRowsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(SelectRowsForm);
