import isEmpty from "is-empty";
import xlsx from "json-as-xlsx";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import DataFormatterHelper from "../../helpers/DataFormatter.helper";
import DateHelper from "../../helpers/Date.helper";
import { 
    postWithdrawsDetails,
    postWithdrawsGroups,
    getSpreadData,
    getTradeData,
    getAffiliateData,
    getWithdrawData,
    getDepositData
    } from "../FallServices/ReportTypeServices";

class ReportTypeController { 
    static formatNumber = (amount, decimal = 2, currency = null) => (DataFormatterHelper.fixNumberWithoutRound(Number(amount), decimal, currency));
    static foundCountry = (countries, countryCode) => !countryCode ? countryCode : countries.find(country => country.countryCode === countryCode).countryName; 
    
    static AllConsolidatedReport = async ({
        userInfo,
        decimalPlaces,
        params,
        setLoading,
        setRows,
        setTableFooters
    }) => {
        try {
            setLoading(true);
            setRows([]);
            const response = await postWithdrawsGroups(userInfo.UserId, params);
            if (!isEmpty(response.data.data.results)) {
                const data = response.data.data.results;
                const calculateTotalNumber = (value) => (data.reduce((previousValue, currentValue) => previousValue + Number(currentValue[value]), 0))
                const newResponse = data.map((element) => {
                    return {
                        accountId: element.accountId.toString(),
                        accountName: element.accountName,
                        accountCreatedAt: DateHelper.formatDate(element.accountCreatedAt),
                        countWithdraw: element.countWithdraw,
                        acumBtcAmount: `BTC ${ReportTypeController.formatNumber(element.acumBtcAmount, decimalPlaces["BTC"])}`,
                        acumUsdcAmount: `USDC ${ReportTypeController.formatNumber(element.acumUsdcAmount, decimalPlaces["USDC"])}`,
                        acumPenAmount: ReportTypeController.formatNumber(element.acumPenAmount, decimalPlaces["PEN"], "PEN"),
                        acumUsdAmount: ReportTypeController.formatNumber(element.acumUsdAmount, decimalPlaces["USD"], "USD"),
                        acumAmountWithdrawUsd: ReportTypeController.formatNumber(element.acumAmountWithdrawUsd, decimalPlaces["USD"], "USD"),
                        acumFeeAmountUsdWithIgv: ReportTypeController.formatNumber(element.acumFeeAmountUsdWithIgv, decimalPlaces["USD"], "USD"),
                        acumFeeAmountUsdWithoutIgv: ReportTypeController.formatNumber(element.acumFeeAmountUsdWithoutIgv, decimalPlaces["USD"], "USD"),
                        acumFeeAmountUsdIgv: ReportTypeController.formatNumber(element.acumFeeAmountUsdIgv, decimalPlaces["USD"], "USD"),
                        walletsCount: element.walletsCount,
                        bankCount: element.bankCount
                    }
                })
                setRows(newResponse);
                setTableFooters([
                    {
                        id: 'accountId',
                        title: ""
                    },
                    {
                        id: 'accountName',
                        title: "TOTAL",
                    },
                    {
                        id: 'accountCreatedAt',
                        title: data.reduce((previousValue) => previousValue + 1, 0).toString()
                    },
                    {
                        id: 'countWithdraw',
                        title: data.reduce((previousValue, currentValue) => previousValue + Number(currentValue.countWithdraw), 0).toString()
                    },
                    {
                        id: 'acumBtcAmount',
                        title: `BTC ${ReportTypeController.formatNumber(calculateTotalNumber('acumBtcAmount'), decimalPlaces["BTC"])}`
                    },
                    {
                        id: 'acumUsdcAmount',
                        title: `USDC ${ReportTypeController.formatNumber(calculateTotalNumber('acumUsdcAmount'), decimalPlaces["USDC"])}`
                    },
                    {
                        id: 'acumPenAmount',
                        title: ReportTypeController.formatNumber(calculateTotalNumber('acumPenAmount'), decimalPlaces["PEN"], "PEN")
                    },
                    {
                        id: 'acumUsdAmount',
                        title: ReportTypeController.formatNumber(calculateTotalNumber('acumUsdAmount'), decimalPlaces["USD"], "USD")
                    },
                    {
                        id: 'acumAmountWithdrawUsd',
                        title: ReportTypeController.formatNumber(calculateTotalNumber('acumAmountWithdrawUsd'), decimalPlaces["USD"], "USD")
                    },
                    {
                        id: 'acumFeeAmountUsdWithIgv',
                        title: ReportTypeController.formatNumber(calculateTotalNumber('acumFeeAmountUsdWithIgv'), decimalPlaces["USD"], "USD")
                    },
                    {
                        id: 'acumFeeAmountUsdWithoutIgv',
                        title: ReportTypeController.formatNumber(calculateTotalNumber('acumFeeAmountUsdWithoutIgv'), decimalPlaces["USD"], "USD")
                    },
                    {
                        id: 'acumFeeAmountUsdIgv',
                        title: ReportTypeController.formatNumber(calculateTotalNumber('acumFeeAmountUsdIgv'), decimalPlaces["USD"], "USD")
                    },
                    {
                        id: 'walletsCount',
                        title: data.reduce((previousValue, currentValue) => previousValue + Number(currentValue.walletsCount), 0).toString()
                    },
                    {
                        id: 'bankCount',
                        title: data.reduce((previousValue, currentValue) => previousValue + Number(currentValue.bankCount), 0).toString()
                    },
                ])
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static AllSpreadDataReport = async ({
        userInfo,
        params,
        setLoading,
        setRows
    }) => {
        try {
            setLoading(true);
            setRows([]);
            const response = await getSpreadData(userInfo.UserId, params);
            if (!isEmpty(response.data.data.results)) {
                const data = response.data.data.results;
                const decimalProduct = 4;
                const newResponse = data.map((element) => {
                    return {
                        horaUtc: DateHelper.formatDate(element.horaUtc),
                        ...element,
                        fee: ReportTypeController.formatNumber(element.fee, decimalProduct),
                        bnxPrice: ReportTypeController.formatNumber(element.bnxPrice, decimalProduct),
                        notionalValue: ReportTypeController.formatNumber(element.notionalValue, decimalProduct),
                        bnxNotionalValue: ReportTypeController.formatNumber(element.bnxNotionalValue, decimalProduct),
                        bnxQuantity: ReportTypeController.formatNumber(element.bnxQuantity, decimalProduct),
                        formattedBnxQuantity: ReportTypeController.formatNumber(element.formattedBnxQuantity, decimalProduct),
                        productAmount: ReportTypeController.formatNumber(element.productAmount, decimalProduct),
                        newUtcTime: DateHelper.formatDate(element.newUtcTime),
                        newUtcTimeTrunc: DateHelper.formatDate(element.newUtcTimeTrunc),
                        banexcoinPriceQuantity: ReportTypeController.formatNumber(element.banexcoinPriceQuantity, decimalProduct),
                        spread: ReportTypeController.formatNumber(element.spread, decimalProduct),
                        percent: ReportTypeController.formatNumber(element.percent, decimalProduct),
                    }
                })
                setRows(newResponse);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static AllTradeDataReport = async ({
        userInfo,
        params,
        setLoading,
        setRows,
        countries
    }) => {
        try {
            setLoading(true);
            setRows([]);
            const response = await getTradeData(userInfo.UserId, params);
            if (!isEmpty(response.data.data.results)) {
                const data = response.data.data.results;
                const decimalProduct = 4;
                const newResponse = data.map((element) => {
                    return {
                        ...element,
                        fechaPeru:DateHelper.utcFormatter24h(element.fechaPeru),
                        idUsuario: ReportTypeController.formatNumber(element.idUsuario),
                        idCuenta: ReportTypeController.formatNumber(element.idCuenta),
                        diaCreacionPeru: ReportTypeController.formatNumber(element.diaCreacionPeru),
                        mesCreacionPeru: DateHelper.convertNumberToMonth(element.mesCreacionPeru),
                        anioCreacionPeru: ReportTypeController.formatNumber(element.anioCreacionPeru),
                        edad:ReportTypeController.formatNumber(element.edad),
                        idAfiliado:ReportTypeController.formatNumber(element.idAfiliado),
                        idReferido:ReportTypeController.formatNumber(element.idReferido),
                        fechaTrade: DateHelper.ticksToTimestamp(element.fechaTrade),
                        diaTradePeru: ReportTypeController.formatNumber(element.diaTradePeru),
                        mesTradePeru: DateHelper.convertNumberToMonth(element.mesTradePeru),
                        anioTradePeru: ReportTypeController.formatNumber(element.anioTradePeru),
                        cantidad: ReportTypeController.formatNumber(element.cantidad, 6),
                        precio: ReportTypeController.formatNumber(element.precio, decimalProduct),
                        tipoCambio: ReportTypeController.formatNumber(element.tipoCambio, decimalProduct),
                        montoIntercambio: ReportTypeController.formatNumber(element.montoIntercambio, decimalProduct),
                        montoIntercambioUsd: ReportTypeController.formatNumber(element.montoIntercambioUsd, decimalProduct),
                        porcentajeComision: ReportTypeController.formatNumber(element.porcentajeComision, decimalProduct),
                        montoComision: ReportTypeController.formatNumber(element.montoComision, decimalProduct),
                        comisionExpresadoUsd: ReportTypeController.formatNumber(element.comisionExpresadoUsd, decimalProduct),
                        comisionExpresadoUsdSinIgv2: ReportTypeController.formatNumber(element.comisionExpresadoUsdSinIgv2, decimalProduct),  
                        comisionExpresadoPen: ReportTypeController.formatNumber(element.comisionExpresadoPen, decimalProduct),
                        comisionExpresadoPenSinIgv: ReportTypeController.formatNumber(element.comisionExpresadoPenSinIgv, decimalProduct),
                        transactionId: ReportTypeController.formatNumber(element.transactionId),
                        orderId: ReportTypeController.formatNumber(element.orderId),
                        executionId: ReportTypeController.formatNumber(element.executionId),
                        paisResidencia : ReportTypeController.foundCountry(countries,element.paisResidencia)
                    }
                })
                setRows(newResponse);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static AllAffiliateDataReport = async ({
        userInfo,
        params,
        setLoading,
        setRows
    }) => {
        try {
            setLoading(true);
            setRows([]);
            const response = await getAffiliateData(userInfo.UserId, params);
            if (!isEmpty(response.data.data.results)) {
                const data = response.data.data.results;
                const newResponse = data.map((element) => {
                    return {
                        ...element,
                        datetimeUtc: DateHelper.formatDate(element.datetimeUtc),
                        datetimeAmericaLima: DateHelper.formatDate(element.datetimeAmericaLima),
                        timeStamp: DateHelper.ticksToTimestamp(element.timeStamp),
                    }
                });
                setRows(newResponse);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static AllWithdrawDataReport = async ({
        userInfo,
        params,
        setLoading,
        setRows,
        countries
    }) => {
        try {
            setLoading(true);
            setRows([]);
            const response = await getWithdrawData(userInfo.UserId, params);
            if (!isEmpty(response.data.data.results)) {
                const data = response.data.data.results;
                const decimalProduct = 4;
                const newResponse = data.map((element) => {
                    return {
                        ...element,
                        idUsuario: ReportTypeController.formatNumber(element.idUsuario),
                        idCuenta: ReportTypeController.formatNumber(element.idCuenta),
                        fechaCreacionPeru:DateHelper.formatDateTimezone(element.fechaCreacionPeru),
                        mesRetiroPeru:DateHelper.convertNumberToMonth(element.mesRetiroPeru),
                        idAfiliado:ReportTypeController.formatNumber(element.idAfiliado),
                        idReferido:ReportTypeController.formatNumber(element.idReferido),
                        timestampUtc:DateHelper.utcFormatter24h(element.timestampUtc),
                        fechaCreacionAmericaLimaPeru:DateHelper.formatDateTimezone(element.fechaCreacionAmericaLimaPeru),
                        monto: ReportTypeController.formatNumber(element.monto, decimalProduct),
                        montoEnDolares: ReportTypeController.formatNumber(element.montoEnDolares, decimalProduct),
                        precioCripto: ReportTypeController.formatNumber(element.precioCripto, decimalProduct),
                        paisResidencia : ReportTypeController.foundCountry(countries,element.paisResidencia)
                    }
                })
                setRows(newResponse);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static AllDepositDataReport = async ({
        userInfo,
        params,
        setLoading,
        setRows,
        countries
    }) => {
        try {
            setLoading(true);
            setRows([]);
            const response = await getDepositData(userInfo.UserId, params);
            if (!isEmpty(response.data.data.results)) {
                const data = response.data.data.results;
                const decimalProduct = 4;
                const newResponse = data.map((element) => {
                    return {
                        ...element,
                        idUsuario: ReportTypeController.formatNumber(element.idUsuario),
                        idCuenta: ReportTypeController.formatNumber(element.idCuenta),
                        fechaCreacionPeru:DateHelper.formatDateTimezone(element.fechaCreacionPeru),
                        mesDepositoPeru:DateHelper.convertNumberToMonth(element.mesDepositoPeru),
                        idAfiliado:ReportTypeController.formatNumber(element.idAfiliado),
                        idReferido:ReportTypeController.formatNumber(element.idReferido),
                        timestampUtc:DateHelper.utcFormatter24h(element.timestampUtc),
                        fechaCreacionAmericaLimaPeru:DateHelper.formatDateTimezone(element.fechaCreacionAmericaLimaPeru),
                        monto: ReportTypeController.formatNumber(element.monto, decimalProduct),
                        montoEnDolares: ReportTypeController.formatNumber(element.montoEnDolares, decimalProduct),
                        precioCripto: ReportTypeController.formatNumber(element.precioCripto, decimalProduct),
                        paisResidencia : ReportTypeController.foundCountry(countries,element.paisResidencia)                       
                    }
                })
                setRows(newResponse);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }
 
    static DownloadAllConsolidated = async ({
        rows,
        tableFooters
    }) => {
        const titleColumn = {
            accountId: "ID de cuenta",
            accountName: "Usuario",
            accountCreatedAt: "Fecha de creación de la cuenta",
            countWithdraw: "Número de Retiros",
            acumBtcAmount: "Bitcoin Retiradas",
            acumUsdcAmount: "USDC Retiradas",
            acumPenAmount: "Soles Retirados",
            acumUsdAmount: "Dólares Retirados",
            acumAmountWithdrawUsd: "Montos Totales de Retiro Expresados en $",
            acumFeeAmountUsdWithIgv: "Total Comisiones por Retiro (Con IGV)",
            acumFeeAmountUsdWithoutIgv: "Total Comisiones por Retiro (Sin IGV)",
            acumFeeAmountUsdIgv: "IGV (Comisiones de Retiro)",
            walletsCount: "Billeteras Externas",
            bankCount: "A cuentas Bancarias"
        }
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: titleColumn[name],
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        let footerData = {};
        tableFooters.forEach((element) => (footerData[element.id] = element.title));
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: [
                        ...rows,
                        footerData
                    ]
                }
            ];
            const settings = {
                fileName: "Reporte de Retiro consolidado",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

    static DownloadAllSpread = async ({
        rows,
        tableFooters
    }) => {
        const titleColumn = {
            horaUtc: "horaUtc",
            accountId: "accountId",
            accountName: "accountName",
            referenceId: "referenceId",
            referenceType: "referenceType",
            productId: "productId",
            product: "product",
            productFullName: "productFullName",
            productType: "productType",
            transactionType: "transactionType",
            instrumentId: "instrumentId",
            instrumentSymbol: "instrumentSymbol",
            fee: "fee",
            bnxPrice: "bnxPrice",
            notionalValue: "notionalValue",
            bnxNotionalValue: "bnxNotionalValue",
            tradeTime: "tradeTime",
            bnxQuantity: "bnxQuantity",
            formattedBnxQuantity: "formattedBnxQuantity",
            productAmount: "productAmount",
            side: "side",
            newUtcTime: "newUtcTime",
            newUtcTimeTrunc: "newUtcTimeTrunc",
            banexcoinPriceQuantity: "banexcoinPriceQuantity",
            mediaPrice: "mediaPrice",
            mediaPriceSeek: "mediaPriceSeek",
            mediaPriceQuantity: "mediaPriceQuantity",
            spread: "spread",
            percent: "percent"
        }
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: titleColumn[name],
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        let footerData = {};
        tableFooters.forEach((element) => (footerData[element.id] = element.title));
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: [
                        ...rows,
                        footerData
                    ]
                }
            ];
            const settings = {
                fileName: "Reporte cálculo del Spread",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

    static DownloadAllTrade = async ({
        rows,
        tableFooters
    }) => {
        const titleColumn = {
            idUsuario: 'IDUsuario',
            idCuenta: 'IDCuenta',
            nombreCuenta: 'NombreCuenta',
            fechaPeru: 'FechaRegistro',
            fechaNacimiento: 'FechaNacimiento',
            edad: 'Edad',
            rangoEdad: 'RangoEdad',
            generoTexto: 'Genero',
            idAfiliado: 'IDAfiliado',
            idReferido: 'IDReferido',
            person: 'TipoPersona',
            tipoCliente: 'TipoCliente',
            paisResidencia: 'PaisResidencia',
            residente: 'Residente',
            nivelKyc: 'NivelKYC',
            fechaTrade: 'FechaTrade',
            diaTradePeru: 'DiaTradePeru',
            mesTradePeru: 'MesTradePeru',
            anioTradePeru: 'AnioTradePeru',
            diaSemanaTradePeru: 'DiaSemanaTradePeru',
            intercambioTipo: 'TipoTrade',
            cantidad: 'Cantidad',
            activoIntercambio: 'ActivoTradeado',
            precio: 'Precio',
            tipoCambio: 'TipoCambio',
            parIntercambio: 'ParTradeado',
            montoIntercambio: 'MontoTradeado',
            activoRecibeCliente: 'ActivoRecibido',
            montoIntercambioUsd: 'MontoTradeadoUSD',
            porcentajeComision: 'PorcentajeComision',
            montoComision: 'MontoComision',
            activoRecibeComision: 'ActivoComision',
            comisionExpresadoUsd: 'ComisionEnUSD',
            comisionExpresadoUsdSinIgv2: 'ComisionEnUSDSinIGV',
            comisionExpresadoPen: 'ComisionEnPEN',
            comisionExpresadoPenSinIgv: 'ComisionEnPENSinIGV',
            rango: 'Rango',
            transactionId: 'TransactionID',
            orderId: 'OrderID',
            executionId: 'ExecutionID'
        };
        
        const buildColumns = (data) =>
            Object.keys(titleColumn)
                .filter((name) => Object.keys(data[0]).includes(name))
            .map((name) => ({ label: titleColumn[name], value: (row) => row[name] }));
        const footerData = Object.fromEntries(tableFooters.map(element => [element.id, element.title]));

        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: [
                        ...rows,
                        footerData
                    ]
                }
            ];
            const settings = {
                fileName: "Reporte de trade",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

    static DownloadAllWithdraw = async ({
        rows,
        tableFooters
    }) => {
        const titleColumn = {
            idUsuario: 'IDUsuario',
            idCuenta: 'IDCuenta',
            nombreCuenta: 'NombreCuenta',
            fechaCreacionPeru: 'FechaRegistro',
            fechaNacimiento: 'FechaNacimiento',
            edad: 'Edad',
            rangoEdad: 'RangoEdad',
            generoTexto: 'Genero',
            verficiacionCuentaText: 'VerficiacionCuenta',
            idAfiliado: 'IdAfiliado',
            idReferido: 'IdReferido',
            persona: 'TipoPersona',
            tipoCliente: 'TipoCliente',
            paisResidencia: 'PaisResidencia',
            residente: 'Residente',
            nivelKyc: 'NivelKYC',
            timestampUtc: 'FechaUtc',
            fechaCreacionAmericaLimaPeru: 'FechaPeru',
            diaRetiroPeru: 'DiaRetiroPeru',
            mesRetiroPeru: 'MesRetiroPeru',
            anioRetiroPeru: 'AnioRetiroPeru',
            diaSemanaRetiroPeru: 'DiaSemanaRetiroPeru',
            monto: 'Monto',
            idActivoSimbolo: 'Activo',
            montoEnDolares: 'MontoEnDolares',
            precioCripto: 'PrecioCripto',
            nroTicket: 'NroTicket',
            estatusTicket: 'EstatusTicket',
            bancoTipoTransferencia: 'BancoTipoTransferencia',
            bancoCuentaCliente:"BancoCuentaCliente",
            bancoCuentaClienteCci:"BancoCuentaClienteCci",
            bancoNombreCliente:"BancoNombreCliente",
            bancoPaisCliente:"BancoPaisCliente",
            rango:"Rango"
        };
        
        const buildColumns = (data) =>
        Object.keys(data[0])
            .filter((name) => titleColumn[name])
            .map((name) => ({ label: titleColumn[name], value: (row) => row[name] }));
        const footerData = Object.fromEntries(tableFooters.map(element => [element.id, element.title]));

        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: [
                        ...rows,
                        footerData
                    ]
                }
            ];
            const settings = {
                fileName: "Reporte de retiro",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

    static DownloadAllDeposit = async ({
        rows,
        tableFooters
    }) => {
        const titleColumn = {
            idUsuario: 'IDUsuario',
            idCuenta: 'IDCuenta',
            nombreCuenta: 'NombreCuenta',
            fechaCreacionPeru: 'FechaRegistro',
            fechaNacimiento: 'FechaNacimiento',
            edad: 'Edad',
            rangoEdad: 'RangoEdad',
            generoTexto: 'Genero',
            verficiacionCuentaText: 'VerficiacionCuenta',
            idAfiliado: 'IdAfiliado',
            idReferido: 'IdReferido',
            persona: 'TipoPersona',
            tipoCliente: 'TipoCliente',
            paisResidencia: 'PaisResidencia',
            residente: 'Residente',
            nivelKyc: 'NivelKYC',
            timestampUtc: 'FechaUtc',
            fechaCreacionAmericaLimaPeru: 'FechaPeru',
            diaDepositoPeru: 'DiaDepositoPeru',
            mesDepositoPeru: 'MesDepositoPeru',
            anioDepositoPeru: 'AnioDepositoPeru',
            diaSemanaDepositoPeru: 'DiaSemanaDepositoPeru',
            monto: 'Monto',
            idActivoSimbolo: 'Activo',
            montoEnDolares: 'MontoEnDolares',
            precioCripto: 'PrecioCripto',
            nroTicket: 'NroTicket',
            estatusTicket: 'EstatusTicket',
            bancoTrx: 'BancoTrx',
            bancoTipoTransferencia: 'BancoTipoTransferencia',
            bancoCuentaCliente: "BancoCuentaCliente",
            bancoCuentaClienteCci: "BancoCuentaClienteCci",
            bancoNombreCliente: "BancoNombreCliente",
            bancoPaisCliente: "BancoPaisCliente",
            bancoBxn: "BancoBxn",
            bancoPaisBxn: "BancoPaisBxn",
            bancoCuentaBxn: "BancoCuentaBxn",
            rango: "Rango"
        };
        
        const buildColumns = (data) =>
        Object.keys(data[0])
            .filter((name) => titleColumn[name])
            .map((name) => ({ label: titleColumn[name], value: (row) => row[name] }));
        const footerData = Object.fromEntries(tableFooters.map(element => [element.id, element.title]));

        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: [
                        ...rows,
                        footerData
                    ]
                }
            ];
            const settings = {
                fileName: "Reporte de depósito",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

    static DownloadAllAffiliate = async ({
        rows,
        tableFooters
    }) => {
        const titleColumn = {
            dateUtc: 'DateUtc',
            datetimeUtc: 'DatetimeUtc',
            dateAmericaLima: 'DateAmericaLima',
            datetimeAmericaLima: 'DatetimeAmericaLima',
            transactionId: 'TransactionId',
            accountId: 'AccountId',
            omsId: 'OmsId',
            balance: 'Balance',
            counterPartyAccountId: 'CounterPartyAccountId',
            creditAmount: 'CreditAmount',
            debitAmount: 'DebitAmount',
            productId: 'ProductId',
            enteredOnTicks: 'EnteredOnTicks',
            transactionReferenceType: 'TransactionReferenceType',
            transactionReferenceId: 'TransactionReferenceId',
            transactionTypeOrg: 'TransactionTypeOrg',
            timeStamp: 'TimeStamp',
            referenceType: 'ReferenceType',
            transactionType: 'TransactionType'
        };
        
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: titleColumn[name],
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        const footerData = Object.fromEntries(tableFooters.map(element => [element.id, element.title]))
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: [
                        ...rows,
                        footerData
                    ]
                }
            ];
            const settings = {
                fileName: "Reporte de referidos",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

    static AllDetailsReport = async ({
        userInfo,
        decimalPlaces,
        params,
        setLoading,
        setRows,
    }) => {
        try {
            setLoading(true);
            setRows([]);
            const response = await postWithdrawsDetails(userInfo.UserId, params);
            if (!isEmpty(response.data.data.results)) {
                const data = response.data.data.results;
                const newResponse = data.map((element) => {
                    return {
                        accountId: `${element.accountId}`,
                        accountName: element.accountName,
                        isClient: element.isClient ? 'Si' : 'No',
                        userLocked: element.userLocked ? 'No cliente' : 'Cliente',
                        person: element.person,
                        verificationLevel: `${element.verificationLevel || '-'}`,
                        verificationLevelText: element.verificationLevelText,
                        symbol: element.symbol,
                        acumBtcAmount: `BTC ${element.symbol === 'BTC' ? ReportTypeController.formatNumber(element.amount, decimalPlaces["BTC"]) : 0}`,
                        acumUsdcAmount: `USDC ${element.symbol === 'USDC' ? ReportTypeController.formatNumber(element.amount, decimalPlaces["USDC"]) : 0}`,
                        acumPenAmount: ReportTypeController.formatNumber(element.symbol === 'PEN' ? element.amount : 0, decimalPlaces["PEN"], "PEN"),
                        acumUsdAmount: ReportTypeController.formatNumber(element.symbol === 'USD' ? element.amount : 0, decimalPlaces["USD"], "USD"),
                        price: ReportTypeController.formatNumber(element.price, decimalPlaces["USD"], "USD"),
                        notionalValue: ReportTypeController.formatNumber(element.notionalValue, decimalPlaces["USD"], "USD"),
                        feeAmount: `${(element.symbol === 'BTC' || element.symbol === 'USDC') ? element.symbol : ''} ${ReportTypeController.formatNumber(element.feeAmount, decimalPlaces[element.symbol], element.symbol)}`,
                        feeAmountUsd: ReportTypeController.formatNumber(element.feeAmountUsd, decimalPlaces["USD"], "USD"),
                        bankNameSource: element.bankNameSource,
                        bankCountrySource: element.bankCountrySource,
                        statusText: element.statusText,
                        ticketNumber: element.ticketNumber,
                        timestampUtc: DateHelper.utcFormatter(element.timestampUtc),
                        timestampAmericaLimaPeru: DateHelper.formatDate(element.timestampAmericaLimaPeru),
                        dayAmericaLimaPeru: `${element.dayAmericaLimaPeru}`,
                        monthAmericaLimaPeru: `${element.monthAmericaLimaPeru}`,
                        yearAmericaLimaPeru: `${element.yearAmericaLimaPeru}`
                    }
                })
                setRows(newResponse);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static DownloadAllDetails = async ({
        rows
    }) => {
        const titleColumn = {
            accountId: "ID de cuenta",
            accountName: "Nombre de la cuenta",
            isClient: "En admin",
            userLocked: "Tipo usuario",
            person: "Persona",
            verificationLevel: "Nivel de verificación",
            verificationLevelText: "Nivel",
            symbol: "Nombre del activo",
            acumBtcAmount: "Bitcoin Retiradas",
            acumUsdcAmount: "USDC Retiradas",
            acumPenAmount: "Soles Retirados",
            acumUsdAmount: "Dólares Retirados",
            notionalValue: "Valor nocional",
            price: "T/C",
            feeAmount: "Monto de comisión",
            feeAmountUsd: "Monto comisión en USD",
            bankNameSource: "Banco",
            bankCountrySource: "País del Banco",
            statusText: "Estatus",
            ticketNumber: "Número de ticket",
            timestampUtc: "UTC",
            timestampAmericaLimaPeru: "Perú",
            dayAmericaLimaPeru: "Día",
            monthAmericaLimaPeru: "Mes",
            yearAmericaLimaPeru: "Año"
        }
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: titleColumn[name],
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: rows
                }
            ];
            const settings = {
                fileName: "Reporte de Retiro detallado",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

}
export default ReportTypeController;