export const nonProfit = [
    {
        name: 'Asociación',
        value: 'Association'
    },
    {
        name: 'Fundación',
        value: 'Foundation'
    },
    {
        name: 'Comité',
        value: 'Committee'
    },
    {
        name: 'Otro (especifique)',
        value: 'Other'
    }
];