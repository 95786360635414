export const DOCUMENT_TYPE = [
    {
      name: 'Pasaporte',
      value: 'PP'
    },
    {
      name: 'Documento Nacional de Identidad',
      value: 'ID'
    },
    {
      name: 'Residence Permit (CE)',
      value: 'RP'
    }
  ];