import isEmpty from 'is-empty';
import React, { useState } from 'react'
import ComponentsHelper from '../../../../../helpers/Components.helper';
import DataFormatterHelper from '../../../../../helpers/DataFormatter.helper';
import DateHelper from '../../../../../helpers/Date.helper';
import { useForm } from '../../../../../hooks/formHooks';
import { GroupFilters } from '../../../../../components/FallComponents/Filters/Filters'
import HeaderPage from '../../../../../components/FallComponents/HeaderPage/HeaderPage'
import TableContainer from '../../../../../components/FallComponents/Table/TableContainer'
import Setting from '../../../../../images/new_icons/setting.svg';
import SettingLight from '../../../../../images/new_icons/setting-light.svg';
import Download from '../../../../../images/new_icons/download.svg';
import { formatAmountCoink  } from '../../../../../helpers/numberFormatter';

const AllCoinkDepositPage = (props) => {

    const { loading, setFilterSearch, onChangeLimit, filterConfig, totalRows, offset, showingTo, changePage, rows, withRightSection, handleDownload } = props;

    const filterInitialValues = {
        correlative: "",
        faStatus: "",
        serie: "",
        type: ""
    };
    const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(filterInitialValues);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});

    const handleShowFilters = () => setShowFilters(!showFilters);

     const defineFilter = (e) => {
        if (e.name === 'correlative') return { in: Number(e.value) }
        return { includesInsensitive: String(e.value) }
    }

    const handleOnChange = (e) => {
        onInputChangeV2(e);
        setFilters({
            ...filters,
            [e.name]: !isEmpty(e.value) ? defineFilter(e) : ''
        });
    }

     const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: 'id',
            title: 'Id',
        },
        {
            tag: 'banexcoinUserProviderClientId',
            title: 'Id de Proveedor'
        },
        {
            tag: 'requestId',
            title: 'Id de solicitud'
        },
        {
            tag: '',
            title: 'Hora y fecha de operación',
            cell: ({ data }) => DateHelper.formatDate(data.createdAt)
        },
        {
            tag: '',
            title: 'Hora y fecha de actualización',
            cell: ({ data }) => DateHelper.formatDate(data.updatedAt)
        },
        {
            tag: 'depositMode',
            title: 'Tipo de depósito'
        },
        {
            tag: 'ticketNumber',
            title: 'Número de Ticket'
        },
        {
            tag: 'amount',
            title: 'Monto',
            cell: ({ data }) => formatAmountCoink(data.amount)
        },
        {
            tag: 'status',
            title: 'Estado'
        }
    ]

    const handleSearchFilters = () => {
        const newFilters = DataFormatterHelper.cleanJSONFilters(filters);
        Object.keys(newFilters).forEach(element => {
            newFilters[element] === '' && delete newFilters[element];
        });
        setFilterSearch(newFilters)
    };

    const headerOptions = [
        {
            icon: showFilters ? SettingLight : Setting,
            action: handleShowFilters,
            className: showFilters ? 'header-option-active' : ''
        },
        {
            icon: Download,
            action: handleDownload
        }
    ];

    const voucherType = [
        { value: "AUTO", name: "Auto" },
        { value: "MANUAL", name: "Manual" }
    ]

    const inputs = [
        {
            values: {
                title: "Tipo de depósito",
                name: "type",
                value: inputsFilters.type,
                type: "select",
                options: voucherType,
                required: true,
            },
            actions: {
                onChange: handleOnChange,
            },
        }
    ]

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Depósito Coink"
                subTitle="Coink / Transacciones coink / Depósito Coink"
                headerOptions={headerOptions}
            />
            <TableContainer
                onChangeLimit={onChangeLimit}
                filterConfig={filterConfig}
                totalRows={totalRows}
                rows={rows}
                loading={loading}
                offset={offset}
                showingTo={showingTo}
                changePage={changePage}
                headers={tableHeaders}
                enablePagination={true}
                rowIdSelected={''}
            >
                {
                    showFilters &&
                    <GroupFilters
                        handleSearchFilters={handleSearchFilters}
                        withRightSection={withRightSection}
                        inputs={inputs}
                    />
                }
            </TableContainer>
        </div>
    )
}

export default AllCoinkDepositPage