import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import DateHelper from "../../../helpers/Date.helper";
import { countriesStatusTypes } from "../../../constants";
import { Switch } from "../Switch/Switch";
import { useModal } from "../../../hooks/useModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import { GroupFilters } from "../Filters/Filters";
import isEmpty from "is-empty";

const AllCountriesData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    selectedCountry,
    getCountries,
    updateCountryStatus,
    withRightSection,
    resetFilters,
    setFilterSearch
  } = props;
  const { showModal, handleToggleModal } = useModal();
  const [checkedData, setCheckedData] = useState({
    active: false,
    id: null,
  });
  const [filterInputs, setFilterInputs] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const handleSearchFilters = () => {
    const filtersToSend = {...filterInputs}
    Object.keys(filterInputs).forEach(item => {
      if(isEmpty(filterInputs[item])){
        delete filtersToSend[item]
      }else{
        filtersToSend[item] = `"${filterInputs[item]}"`
      }
    })
    setFilterSearch({...filtersToSend})
  }
  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: RotateRight,
      action: () => {
        resetFilters()
        setFilterInputs({
          countryCode:"",
          countryName:""
        })
      },
    },
  ];

  const handleConfirmActionModal = async () => {
    const country = rows.find(item => item.countryCode === checkedData.id) || {}
    const response = await updateCountryStatus({status:country.status,countryCode:checkedData.id})
    if(response){
      getCountries()
    }
  }

  const CheckboxStatus = ({ data }) => {
    const handleToggleLimitStatus = (ev) => {
      handleToggleModal();
      setCheckedData({ active: ev.target.checked, id: data.countryCode });
    };
    return (
      <Switch
        defaultChecked={data.status === 1}
        onChange={handleToggleLimitStatus}
        id={`switch-toggle-bnx-account-${data.countryCode}`}
      />
    );
  };

  const tableHeaders = [
    {
      tag: "countryCode",
      title: "Código de país",
    },
    {
      tag: "countryName",
      title: "País",
    },
    {
      tag: "",
      title: "Fecha de creación",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "",
      title: "Fecha de actualización",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.updatedAt)}</p>,
    },
    {
      tag: "",
      title: "Estado",
      cell: ({ data }) => <p>{countriesStatusTypes[data.status] || "-"}</p>,
    },
    {
      tag: "",
      title: "Activado",
      cell: ({ data }) => <CheckboxStatus data={data} />,
    },
  ];

  const filters = [
    {
      values: {
        title: "Código de país",
        name: "countryCode",
        value: filterInputs.countryCode,
        type: "text",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "País",
        name: "countryName",
        value: filterInputs.countryName,
        type: "text",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    }
  ];

  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Países")}
        subTitle="Cumplimiento / Países"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedCountry}
      >
         {showFilters && (
          <GroupFilters
            inputs={filters}
            withRightSection={withRightSection}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </TableContainer>
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={() => {
          handleToggleModal();
        }}
        title="Activar / Desactivar país"
        message={`¿Desea ${
          !checkedData.active ? "desactivar" : "activar"
        } pais?`}
        confirmButtonProps={{
          onClick: handleConfirmActionModal,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllCountriesData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllCountriesData);
