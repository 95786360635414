export const tableHeaders = [
    {
      tag: "vouOrigen",
      title: "Vou.Origen",
    },
    {
      tag: "vouNumero",
      title: "Vou.Numero",
    },
    {
      tag: "vouFecha",
      title: "Vou.Fecha",
    },
    {
      tag: "doc",
      title: "Doc",
    },
    {
      tag: "numero",
      title: "Numero",
    },
    {
      tag: "fecDoc",
      title: "Fec.Doc",
    },
    {
      tag: "fecVenc",
      title: "Fec.Venc.",
    },
    {
      tag: "codigo",
      title: "Codigo",
    },
    {
      tag: "valorExp",
      title: "Valor Exp.",
    },
    {
      tag: "bImponiple",
      title: "B.Imponible",
    },
    {
      tag: "inafecto",
      title: "Inafecto",
    },
    {
      tag: "exonerado",
      title: "Exonerado",
    },
    {
      tag: "isc",
      title: "I.S.C.",
    },
    {
      tag: "igv",
      title: "IGV",
    },
    {
      tag: "otrosTrib",
      title: "OTRS TRIB.",
    },
    {
      tag: "impBolsa",
      title: "IMP.BOLSA",
    },
    {
      tag: "moneda",
      title: "Moneda",
    },
    {
      tag: "tc",
      title: "TC",
    },
    {
      tag: "glosa",
      title: "Glosa",
    },
    {
      tag: "ctaIngreso",
      title: "Cta Ingreso",
    },
    {
      tag: "ctaIgv",
      title: "Cta IGV",
    },
    {
      tag: "ctaOtrib",
      title: "Cta O. Trib.",
    },
    {
      tag: "ctaXcobrar",
      title: "Cta x cobrar",
    },
    {
      tag: "cCosto",
      title: "C.Costo",
    },
    {
      tag: "presupuesto",
      title: "Presupuesto",
    },
    {
      tag: "rDoc",
      title: "R.Doc",
    },
    {
      tag: "rNumero",
      title: "R.numero",
    },
    {
      tag: "rFecha",
      title: "R.Fecha",
    },
    {
      tag: "ruc",
      title: "RUC",
    },
    {
      tag: "rSocial",
      title: "R.Social",
    },
    {
      tag: "tipo",
      title: "Tipo",
    },
    {
      tag: "tipDocIden",
      title: "Tip.Doc.Ide",
    },
    {
      tag: "medioDePago",
      title: "Medio de pago",
    },
    {
      tag: "apellido1",
      title: "Apellido 1",
    },
    {
      tag: "apellido2",
      title: "Apellido 2",
    },
    {
      tag: "nombre",
      title: "Nombre",
    },
    {
      tag: "pOrigen",
      title: "P.origen",
    },
    {
      tag: "pVou",
      title: "P.vou",
    },
    {
      tag: "pFecha",
      title: "P.fecha",
    },
    {
      tag: "pFechaD",
      title: "P.fecha D.",
    },
    {
      tag: "pFechaV",
      title: "P.fecha V.",
    },
    {
      tag: "pCtaCob",
      title: "P.cta cob",
    },
    {
      tag: "pMpago",
      title: "P.m.pago",
    },
    {
      tag: "pDoc",
      title: "P.doc",
    },
    {
      tag: "pNumDoc",
      title: "P.num doc",
    },
    {
      tag: "pMoneda",
      title: "P.moneda",
    },
    {
      tag: "pTc",
      title: "P.tc",
    },
    {
      tag: "pMonto",
      title: "P.monto",
    },
    {
      tag: "pGlosa",
      title: "P.glosa",
    },
    {
      tag: "pFe",
      title: "P.fe",
    },
    {
      tag: "retencion0/1",
      title: "Retencion 0/1",
    },
    {
      tag: "pdbNdes",
      title: "PDB ndes",
    },
    {
      tag: "codTasa",
      title: "CodTasa",
    },
    {
      tag: "indRet",
      title: "Ind.Ret",
    },
    {
      tag: "bImp",
      title: "B.Imp",
    },
    {
      tag: "empty",
      title: "igv",
    }
    
  ];