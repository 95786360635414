import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import withAuthentication from "apex-web/lib/hocs/withAuthentication";
import DashboardPage from "./FallPages/Dashboard/DashboardPage";
import NaturalCustomerPage from "./FallPages/Customers/Natural/NaturalPage";
import WithdrawPage from "./FallPages/Tickets/Withdraws/WithdrawPage";
import ErrorPage from "./FallPages/ErrorPage/ErrorPage";
import GlobalSideMenuContainer from "../components/FallComponents/GlobalSideMenu/GlobalSideMenuContainer";
import ProviderLevels from "./FallPages/Customers/Natural/ProviderLevels";
import DepositPage from "./FallPages/Tickets/Deposits/DepositPage";
import BankAccountPage from "./FallPages/Customers/BankAccounts/BankAccountPage";
import BanexcoinAccountsPage from "./FallPages/Settings/BanexcoinAccountsPage";
import TxHashPage from "./FallPages/Tickets/TxHash/TxHashPage";
import BankingMovementsPage from "./FallPages/Finances/BankingMovementsPage";
import LegalCustomerPage from './FallPages/Customers/Legal/LegalPage';
import UsersPage from "./FallPages/Customers/UsersPage";
import InternalUsersPage from "./FallPages/Security/InternalUsersPage";
import KpiReportsPage from "./FallPages/Reports/Kpis/KpiReportsPage";
import AmlAlertsPage from "./FallPages/Compliance/AmlAlertsPage";
import DepositLimitsSettingsPage from "./FallPages/Compliance/DepositLimitsSettingsPage";
import DepositAlertsPage from "./FallPages/Compliance/DepositAlertsPage";
import AlphapointAccountsPage from "./FallPages/Customers/AlphapointAccounts/AlphapointAccountsPage";
import GlobalConfigPage from "./FallPages/Settings/GlobalConfig/GlobalConfigPage";
import BlackListPage from "./FallPages/Compliance/BlackList/BlackListPage";
import BlackListByTypePage from "./FallPages/Compliance/BlackList/BlackListByTypePage";
import BlackListFilesQueuePage from "./FallPages/Compliance/BlackList/BlackListFilesQueuePage";
import CountriesPage from "./FallPages/Compliance/CountriesPage";
import CategoriesAndModulesPage from "./FallPages/Settings/CategoriesAndModules/CategoriesAndModulesPage";
import AcuantPage from "./FallPages/Compliance/AcuantPage";
import AutoApprovalPage from "./FallPages/Settings/GlobalConfig/AutoApprovalPage";
import AcuantLogsPage from "./FallPages/Compliance/AcuantLogsPage";
import ProfilesPage from "./FallPages/Settings/ProfilesPage";
import ProofPaymentPage from "./FallPages/Finances/ProofPaymentPage";
import ReniecPage from "./FallPages/Compliance/ReniecPage";
import BankTransactionsPage from "./FallPages/Finances/BankTransactionsPage";
import TradeReportPage from "./FallPages/Reports/Trades/TradeReportPage";
import TicketsAndInvoicesPage from "./FallPages/Billing/TicketsAndInvoicesPage";
import FxRatePage from "./FallPages/Rates/FxRatesPage";
import SerieCorrelativePage from "./FallPages/Billing/SerieCorrelativePage";
import BalancesReportsPage from "./FallPages/Finances/BalancesReportsPage";
import NotificationsPage from "./FallPages/Marketing/NotificationsPage";
import ProvidersPage from "./FallPages/Finances/ProvidersPage";
import { Loader } from "../components/FallComponents/Loader/Loader";
import UserProvider from "../providers/UserProvider";
import AutoTradePage from "./FallPages/Settings/AutoTradePage";
import ReportPage from "./FallPages/Reports/ReportPage/ReportPage";
import ReportTypePage from "./FallPages/Reports/ReportPage/ReportTypePage";
import RestrictedDomainsPage from "./FallPages/Security/RestrictedDomainsPage";
import GeneralConfigPage from "./FallPages/Card/GeneralConfig/GeneralConfigPage";
import CommissionsPage from "./FallPages/Finances/CommissionsPage";
import ConsolidatedReport from "../components/FallComponents/ReportPage/ConsolidatedReport";
import ReportSpreadUsdcPage from "./FallPages/Reports/ReportSpreadUsdcPage";
import CoinkTransactionsPage from "./FallPages/Coink/CoinkTransactions/CoinkTransactionsPage";
import CoinkTransactionsTypePage from "./FallPages/Coink/CoinkTransactions/CoinkTransactionsTypePage";

const InteriorPageBeta = (props) => {

  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  const allPages = [
    {
      path: 'tablero',
      component: <DashboardPage />
    },
    {
      path: 'clientes-naturales',
      component: <ProviderLevels>
        <NaturalCustomerPage />
      </ProviderLevels>
    },
    {
      path: 'clientes-naturales/:id',
      component: <ProviderLevels>
        <NaturalCustomerPage />
      </ProviderLevels>
    },
    {
      path: 'clientes-juridicos',
      component: <LegalCustomerPage />
    },
    {
      path: "cuentas-bancarias",
      component: <BankAccountPage />
    },
    {
      path: "cuentas-alphapoint",
      component: <AlphapointAccountsPage />
    },
    {
      path: "depositos",
      component: <DepositPage />
    },
    {
      path: "depositos/:id",
      component: <DepositPage />
    },
    {
      path: "retiros",
      component: <WithdrawPage />
    },
    {
      path: "retiros/:id",
      component: <WithdrawPage />
    },
    {
      path: "tx-hash",
      component: <TxHashPage />
    },
    {
      path: "cuentas-banexcoin",
      component: <BanexcoinAccountsPage />
    },
    {
      path: "configuraciones-globales",
      component: <GlobalConfigPage />
    },
    {
      path: "categorias-y-modulos",
      component: <CategoriesAndModulesPage />
    },
    {
      path: 'reniec',
      component: <ReniecPage />
    },
    {
      path: "movimientos-bancarios",
      component: <BankingMovementsPage />
    },
    {
      path: "usuarios",
      component: <UsersPage />
    },
    {
      path: "reportes-de-kpi",
      component: <KpiReportsPage />
    },
    {
      path: "reportes-de-intercambio",
      component: <TradeReportPage />
    },
    {
      path: "alertas-aml",
      component: <AmlAlertsPage />
    },
    {
      path: "umbrales-de-deposito",
      component: <DepositLimitsSettingsPage />
    },
    {
      path: "alertas-de-deposito",
      component: <DepositAlertsPage />
    },
    {
      path: "lista-negra",
      component: <BlackListPage />
    },
    {
      path: "lista-negra/files",
      component: <BlackListFilesQueuePage />
    },
    {
      path: "lista-negra/:type",
      component: <BlackListByTypePage />
    },
    {
      path: "paises",
      component: <CountriesPage />
    },
    {
      path: "acuant",
      component: <AcuantPage />
    },
    {
      path: "autoaprobador-de-tickets",
      component: <AutoApprovalPage />
    },
    {
      path: "acuant-logs",
      component: <AcuantLogsPage />
    },
    {
      path: "perfiles",
      component: <ProfilesPage />
    },
    {
      path: "transacciones-bancarias",
      component: <BankTransactionsPage />
    },
    {
      path: "comprobantes-de-pago",
      component: <ProofPaymentPage />
    },
    {
      path: "series-y-correlativos",
      component: <SerieCorrelativePage />
    },
    {
      path: "tipos-de-cambio",
      component: <FxRatePage />
    },
    {
      path: "boletas-y-facturas",
      component: <TicketsAndInvoicesPage />
    },
    {
      path: "usuarios-internos",
      component: <InternalUsersPage />
    },
    {
      path: "reporte-de-balances",
      component: <BalancesReportsPage />
    },
    {
      path: "notificaciones",
      component: <NotificationsPage />
    },
    {
      path: "auto-trades",
      component: <AutoTradePage />
    },
    {
      path: "proveedores",
      component: <ProvidersPage />
    },
    {
      path: "reporte-general",
      component: <ReportPage />
    },
    {
      path: "reporte-general/:cardId/:itemId",
      component: <ReportTypePage />
    },
    {
      path: "dominios-restringidos",
      component: <RestrictedDomainsPage />
    },
    {
      path: "configuraciones-generales",
      component: <GeneralConfigPage />
    },
    {
      path:"comisiones",
      component: <CommissionsPage />
    },
    {
      path:"reporte-de-spread-usdc",
      component: <ReportSpreadUsdcPage />
    },
    {
      path : "coink-transactions",
      component: <CoinkTransactionsPage />
    },
    {
      path: "coink-transactions/:cardId/:itemId",
      component: <CoinkTransactionsTypePage />
    },
  ];

  const allowedModules = permissions.map(({ category: { modules } }) => (modules.map(({ name }) => (name.toLowerCase().replaceAll(' ', '-'))))).flatMap(element => element);
  const newPages = allPages.filter(element => allowedModules.some((item) => element.path.includes(item)));

  return (
    <UserProvider>
      <GlobalSideMenuContainer {...props} setPermissions={setPermissions} setLoading={setLoading}>
        {!loading ?
          <Switch>
            {newPages.map(({ path, component }, key) =>
              <Route exact path={`/${path}`} key={key}>
                {component}
              </Route>
            )}
            <Route>
              <ErrorPage />
            </Route>
          </Switch>
          :
          <Loader message="Verificando permisos" className="loader-initial-v2" />
        }
      </GlobalSideMenuContainer>
    </UserProvider>
  );
};

export default withAuthentication(InteriorPageBeta);
