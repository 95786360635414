export const reviewStatus = [
    {
        id: 'empty',
        name: 'All',
        value: 'empty',
    },
    {
        id: 'ACCEPTED',
        name: 'Aceptada',
        value: 'ACCEPTED',
    },
    {
        id: 'REJECTED',
        name: 'Rechazada',
        value: 'REJECTED',
    },
    {
        id: 'INPROCCESS',
        name: 'Revisión en proceso',
        value: 'INPROCCESS',
    },
    {
        id: 'NEW_RECORD',
        name: 'Nuevo registro pendiente',
        value: 'NEW_RECORD',
    },
    {
        id: 'INCORRECT_INFORMATION',
        name: 'Información incorrecta',
        value: 'INCORRECT_INFORMATION',
    },
    {
        id: 'REVIEW_REQUESTED',
        name: 'Revisión solicitada',
        value: 'REVIEW_REQUESTED',
    }
]