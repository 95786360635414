import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import DateHelper from "../../../helpers/Date.helper";
import AddIcon from "../../../images/add-icon.svg";
import { Switch } from "../Switch/Switch";
import { useModal } from "../../../hooks/useModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";

const AllAutoApprovalData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    handleSelectRow,
    selectedId,
    getAllProducts,
    handleToggleStatusAutoApproval,
    handleToggleAddModal
  } = props;
  const { showModal, handleToggleModal } = useModal();
  const [checkedData, setCheckedData] = useState({});

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleToggleAddModal,
    },
    {
      icon: RotateRight,
      action: getAllProducts,
    },
  ];

  const CheckboxStatus = ({ status, type, data }) => {
    const handleToggleSwitch = () => {
      handleToggleModal();
      setCheckedData({
        isChecked: status,
        type,
        productSymbol: data.productSymbol,
        id: data.id,
      });
    };
    return (
      <Switch
        defaultChecked={status}
        onChange={handleToggleSwitch}
        id={`switch-toggle-${type}-${data.id}`}
      />
    );
  };

  const handleConfirmActionModal = () =>
    handleToggleStatusAutoApproval(checkedData);

  const modalMessage = `¿Desea ${
    checkedData.isChecked ? "deshabilitar" : "habilitar"
  } autoaprobador de ticket de ${
    checkedData.type === "withdraw" ? "retiro" : "depósito"
  } para ${checkedData.productSymbol}?`;

  const tableHeaders = [
    {
      tag: "",
      title: "Fecha de creación",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "",
      title: "Fecha de actualización",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.updatedAt)}</p>,
    },
    {
      tag: "",
      title: "Creado por",
      handleSelectRow,
      cell: ({ data }) => <p>{data.banexcoinUserByCreatedBy.banexcoinUsername}</p>,
    },
    {
      tag: "productSymbol",
      title: "Producto",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Activado para retiros",
      cell: ({ data }) => (
        <CheckboxStatus
          data={data}
          type="withdraw"
          status={data.enableWithdrawApproval}
        />
      ),
    },
    {
      tag: "",
      title: "Activado para depositos",
      cell: ({ data }) => (
        <CheckboxStatus
          data={data}
          type="deposit"
          status={data.enableDepositApproval}
        />
      ),
    },
  ];

  return (
    <div className="all-withdraw-data">
      <HeaderPage
        title={context.t("Autoaprobador de tickets")}
        subTitle="Configuraciones / Autoaprobador de tickets"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}
        enablePagination={false}
      />
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={() => {
          handleToggleModal();
        }}
        title="Habilitar / Deshabilitar autoaprobador de tickets"
        message={modalMessage}
        confirmButtonProps={{
          onClick: handleConfirmActionModal,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllAutoApprovalData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllAutoApprovalData);
