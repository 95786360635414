export const platformType = [
    {
        name: 'Sin filtro',
        value: ''
    },
    {
        name: 'App',
        value: 'APP'
    },
    {
        name: 'Web',
        value: 'WEB'
    },
];