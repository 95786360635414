import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AllReniecPage from '../../../components/FallComponents/ReniecPage/AllReniecPage'
import ResizableColumns from '../../../components/FallComponents/ResizableColumns/ResizableColumns'
import RightResizable from '../../../components/FallComponents/RightResizable/RightResizable'
import '../../../components/FallComponents/ReniecPage/allReniecPage.css'
import ReniecController from '../../FallControllers/ReniecController';
import closeIcon from '../../../images/new_icons/close-circle.svg';
import ReniecDetails from '../../../components/FallComponents/ReniecPage/ReniecDetails/ReniecDetails';
import ImagesZoom from '../../../components/FallComponents/ImagesZoom/ImagesZoom';
import isEmpty from 'is-empty';

const ReniecPage = (props, context) => {

    const { userInfo } = props;

    const [imagesZoom, setImagesZoom] = useState({ show: false, images: null });
    const [loading, setLoading] = useState(false);
    const [loadingRight, setLoadingRight] = useState(true);
    const [withRightSection, setWithRightSection] = useState(false);
    const [reniecUserList, setReniecUserList] = useState([]);
    const [reniecUserId, setReniecUserId] = useState('');
    const [reniecUserInfo, setReniecUserInfo] = useState({});
    const [searchWithDni, setSearchWithDni] = useState(false);

    const handleImagesToZoom = images => {
        if (!images || (images.length === 1 && images[0].src === 'default')) {
            setImagesZoom({ show: false, images: null });
            return;
        }
        setImagesZoom({ show: true, images });
    };

    const handleSelectRow = ({ row }) => {
        setWithRightSection(true);
        if (row.state) setReniecUserId(row.id);
    };

    const handleSearchByNames = (first_surname, second_surname, names) => {
        ReniecController.searchByNames({
            userInfo,
            setLoading,
            first_surname,
            second_surname,
            names,
            setReniecUserList
        });
    };

    const handleSearchByDni = (dni, state) => {
        ReniecController.searchByDni({
            userInfo,
            dni,
            setLoadingRight,
            setLoading,
            setReniecUserInfo,
            setWithRightSection,
            state,
            setReniecUserList,
            setReniecUserId
        });
    };

    const headerOptions = [
        {
            image: closeIcon,
            alt: 'Close option',
            action: () => { setWithRightSection(false); }
        }
    ];

    useEffect(() => {
        if (!isEmpty(reniecUserId) && !searchWithDni) handleSearchByDni(reniecUserId, false);
    }, [reniecUserId])

    return (
        <div className="beta-content-container">
            <ResizableColumns
                withRightSection={withRightSection}
                minWidth={imagesZoom.show ? '950px' : '550px'}
                childrenOne={
                    <AllReniecPage
                        loading={loading}
                        handleSearchByNames={handleSearchByNames}
                        handleSearchByDni={handleSearchByDni}
                        reniecUserList={reniecUserList}
                        handleSelectRow={handleSelectRow}
                        reniecUserId={reniecUserId}
                        setWithRightSection={setWithRightSection}
                        setSearchWithDni={setSearchWithDni}
                    />
                }
                childrenTwo={
                    <RightResizable
                        headerOptions={headerOptions}
                        loadingData={{
                            title: 'Cargando información',
                            isLoading: loadingRight
                        }}
                        leftSection={imagesZoom.show ? <ImagesZoom images={imagesZoom.images} onClose={handleImagesToZoom} /> : null}
                    >
                        <ReniecDetails
                            reniecUserInfo={reniecUserInfo}
                            handleImagesToZoom={handleImagesToZoom}
                        />
                    </RightResizable>
                }
            />
        </div>
    )
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

ReniecPage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(ReniecPage)