import React from 'react';
import { Modal } from 'react-bootstrap';
import './modal.css';
import close from '../../../images/new_icons/close-circle.svg';
export const ModalLayout = ({ showModal, actionClose, className = '', classGeneral='', children }) => {
  return (
    <div className="modal-custom-validation">
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        className={`modal-custom-validation ${classGeneral}`}>
        <Modal.Body>
          <div>
            <button onClick={actionClose}>
              <img src={close} width="30px" />
            </button>
          </div>
          <section className={className}>{children}</section>
        </Modal.Body>
      </Modal>
    </div>
  );
};
