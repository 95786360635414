import React, { useEffect, useState } from 'react';
import { assets, coinkUserLevels, legalUserLevels, levelsNames, naturalUserLevels, statusList } from '../../../../constants';
import { useForm } from '../../../../hooks/formHooks';
import InformationSection from '../../InformationSection/InformationSection';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './globalConfigDetails.css';
import DateHelper from '../../../../helpers/Date.helper';
import { Switch } from '../../Switch/Switch';
import { useModal } from '../../../../hooks/useModal';
import SubHeaderPage from '../../HeaderPage/SubHeaderPage';
import AddIcon from "../../../../images/add-icon.svg";
import CreateAuthorizedAsset from '../GlobalConfigSections/Modals/CreateAuthorizedAsset';
import ConfirmAuthorizedAsset from '../GlobalConfigSections/Modals/ConfirmAuthorizedAsset';
import TableContainer from '../../Table/TableContainer';
import ConfirmAuthorizedModal from '../GlobalConfigSections/Modals/ConfirmAuthorizedModal';
import edit from '../../../../images/new_icons/edit.svg';
import check from '../../../../images/new_icons/check.svg';
import cross from '../../../../images/new_icons/cross.svg';
import { Loader } from '../../Loader/Loader';
import isEmpty from 'is-empty';

const GlobalConfigDetails = (props, context) => {

    const {
        assetInfoById,
        handleEditAssetById,
        listAssetAuthorizedById,
        handleDisableAssetAuthorizedById,
        handleCreateAuthorizedAssetById,
        handleUpdateAuthorizedAssetById,
        handleUpdateAuthorizedModal,
        loading,
        filterConfig,
        offset,
        showingTo,
        changePage,
        onChangeLimit,
        totalRows2,
        changeAllAssetsById
		} = props;

    const initialValues = {
        value: '',
        type: ''
    };

    const { inputs, onInputChangeV2, setInputs } = useForm(initialValues);
    const [checkedData, setCheckedData] = useState({
        isChecked: 0,
        id: null,
    });
    const [checkedAuthorized, setCheckedAuthorized] = useState({
        isChecked: false,
        id: null,
    });
    const [titleModal, setTitleModal] = useState('');
    const [messageModal, setMessageModal] = useState('');
    const [name, setName] = useState('');
    const [editableAuthorizedLevels, setEditableAuthorizedLevels] = useState('');
    const { showModal, handleToggleModal } = useModal();
    const { showModal: showModal2, handleToggleModal: handleToggleModal2 } = useModal();
    const { showModal: showModal3, handleToggleModal: handleToggleModal3 } = useModal();
    const [loadingGetAuthorizedLevels, setLoadingAuthorizedLevels] = useState(false);
    const headerOptions = [
        {
            icon: AddIcon,
            action: handleToggleModal2,
        }
    ];

    const toggleEditableAuthorizedLevels = () => setEditableAuthorizedLevels(editable => !editable);

    const handleSubmitEditRestrictedLevels = async () => {
        let unauthorizedLevels = [];
        document.getElementsByName('unauthorizedLevels').forEach((item) => {
            if (!item.checked) {
                unauthorizedLevels.push(Number(item.value));
            }
        })
        const assetData = {
            type: assetInfoById.type,
            value: assetInfoById.value,
            status: assetInfoById.status,
            restricted: assetInfoById.restricted
        }
        const { status: responseStatus } = await handleEditAssetById({
            ...assetData,
            restrictedLevels: JSON.stringify(unauthorizedLevels).replaceAll("[", "").replaceAll("]", "")
        })
        if (responseStatus) {
            changeAllAssetsById(assetInfoById.id, { ...assetData, restrictedLevels: unauthorizedLevels.map(item => String(item)) })
            setLoadingAuthorizedLevels(true)
            setTimeout(() => setLoadingAuthorizedLevels(false), 1000)
        }
        toggleEditableAuthorizedLevels();
    };

    const handleResetValuesAuthorizedLevels = () => {
        toggleEditableAuthorizedLevels();
    }
    const resetValues = (array) => {
        let initialValues = {};
        array.map(({ name }) => {
            initialValues[name] = assetInfoById[name];
        });
        setInputs({
            ...inputs,
            ...initialValues
        })
    };

    const submitData = async () => {
        if (inputs.value !== assetInfoById.value || inputs.type !== assetInfoById.type) {
            const newInputs = { ...inputs, value: inputs.value.toUpperCase(), restrictedLevels: assetInfoById.restrictedLevels }
            const { status, data } = await handleEditAssetById(newInputs);
            if (status) {
                changeAllAssetsById(assetInfoById.id, newInputs)
            } else {
                const newData = Object.keys(data).map((key) => ({ name: key }));
                resetValues(newData);
            }
        }
    };

    const assetInformation = {
        title: 'Información de Activo',
        resetValues: resetValues,
        submitData: submitData,
        fields: [
            {
                label: 'Nombre',
                name: 'value',
                type: 'text',
                showCheckbox: false
            },
            {
                label: 'Tipo de activo',
                name: 'type',
                type: 'select',
                options: assets,
                showCheckbox: false
            }
        ]
    };

    const CheckboxStatus = ({ data }) => {
        const handleToggleActiveBnxAccount = () => {
            setCheckedData({ isChecked: data.status, id: data.id });
            handleToggleModal();
        };
        return (
            <Switch
                defaultChecked={Number(data.status)}
                onChange={handleToggleActiveBnxAccount}
                id={`switch-toggle-bnx-account-${data.id}`}
            />
        );
    };

    const CheckboxAuthorized = ({ name, status, id }) => {
        const handleToggleActiveBnxAccount = () => {
            setCheckedAuthorized({ isChecked: status, id });
            const type = name === 'authorizedBuysell' ? 'compra y venta' : 'exchange';
            setTitleModal(`Activar / Desactivar ${type}`)
            setMessageModal(`¿Desea ${status ? 'desactivar' : 'activar'} ${type} para el usuario?`)
            setName(name)
            handleToggleModal3();
        };
        return (
            <Switch
                defaultChecked={status}
                onChange={handleToggleActiveBnxAccount}
                id={`switch-toggle-bnx-account-${name}-${id}`}
            />
        );
    };

    const tableHeaders = [
        {
            tag: '',
            title: 'Fecha de creación',
            cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
        },
        {
            tag: 'accountId',
            title: 'Id de cuenta'
        },
        {
            tag: '',
            title: 'Habilitado',
            cell: ({ data }) => <CheckboxStatus data={data} />
        },
        {
            tag: '',
            title: 'Habilitado para compra/venta',
            cell: ({ data }) => <CheckboxAuthorized name='authorizedBuysell' status={data.authorizedBuysell} id={data.id} />
        },
        {
            tag: '',
            title: 'Habilitado para Exchange',
            cell: ({ data }) => <CheckboxAuthorized name='authorizedExchange' status={data.authorizedExchange} id={data.id} />
        }
    ]
    const notIncludeElementOnArray = (val) => {
        const element = assetInfoById.restrictedLevels.find(item => String(item) === String(val))
        return isEmpty(element ? String(element) : null);
    }

    useEffect(() => {
        setInputs({
            value: assetInfoById.value,
            type: assetInfoById.type
        })
    }, [assetInfoById])

    return (
        <React.Fragment>
            <div className="layout-details banexcoin-accounts-page">
                <HeaderSection data={assetInfoById} />
                <div className='body-section-global-config'>
                    <div className='card-body'>
                        <InformationSection
                            data={assetInformation}
                            inputs={inputs}
                            onChange={onInputChangeV2}
                            context={context}
                        />
                    </div>
                    <br />
                    <div className="content-level-title with-img pl-4">
                        <h2>Niveles autorizados</h2>
                        <button type='button' onClick={() => !editableAuthorizedLevels ? toggleEditableAuthorizedLevels() : handleSubmitEditRestrictedLevels()}>
                            <img src={!editableAuthorizedLevels ? edit : check} alt="edit image" />
                        </button>
                        {editableAuthorizedLevels && (
                            <button type="button" onClick={handleResetValuesAuthorizedLevels}>
                                <img src={cross} alt="cross" style={{ marginLeft: '10px' }} />
                            </button>
                        )}
                    </div>
										{loadingGetAuthorizedLevels ?
											<div style={{ height: "150px", position: "relative" }}>
												<Loader message="Actualizando datos" />
											</div>
											: <div className='authorized-level-details-container'>
												{[...naturalUserLevels, ...legalUserLevels, ...coinkUserLevels].map((value) => {
													const isEnabled = notIncludeElementOnArray(value)
													return (
														<div className="authorized-level-details-item">
															{editableAuthorizedLevels && (
																<input
																	defaultChecked={Number(isEnabled)}
																	type="checkbox"
																	name="unauthorizedLevels"
																	value={value}
																/>
															)}
															<span title={levelsNames[String(value)]} className={`${editableAuthorizedLevels ? "editable" : isEnabled ? "enabled" : ""}`}>{value}</span>
														</div>
													);
												})}  </div>
										}
                    <div className='card-body'>
                        <SubHeaderPage
                            title="Usuarios autorizados"
                            headerOptions={headerOptions}
                        />
                    </div>
                    <div className='authorized-users-table-container'>
                        <TableContainer
                            onChangeLimit={onChangeLimit}
                            filterConfig={filterConfig}
                            offset={offset}
                            showingTo={showingTo}
                            changePage={changePage}
                            loading={loading}
                            headers={tableHeaders}
                            rows={listAssetAuthorizedById}
                            totalRows={totalRows2}
                            rowIdSelected={''}
                        />
                    </div>
                </div>
            </div>
            <ConfirmAuthorizedAsset
                showModal={showModal}
                handleToggleModal={handleToggleModal}
                checkedData={checkedData}
                handleDisableAssetAuthorizedById={handleDisableAssetAuthorizedById}
                handleUpdateAuthorizedAssetById={handleUpdateAuthorizedAssetById}
            />
            <CreateAuthorizedAsset
                showModal={showModal2}
                handleToggleModal={handleToggleModal2}
                handleCreateNewAsset={handleCreateAuthorizedAssetById}
            />
            <ConfirmAuthorizedModal
                showModal={showModal3}
                handleToggleModal={handleToggleModal3}
                titleModal={titleModal}
                messageModal={messageModal}
                handleUpdate={() => handleUpdateAuthorizedModal(checkedAuthorized.id, name, !checkedAuthorized.isChecked)}
            />
        </React.Fragment>

    );
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

GlobalConfigDetails.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(GlobalConfigDetails);


const HeaderSection = ({ data }) => {

    const status = data.status === 1 ? statusList[0].name : statusList[1].name;
    const handleAssetType = (value) => (assets.find(data => data.value === value) || {}).name;

    return (
        <div className="header-section-global-config">
            <h2>{data.value}</h2>
            <div className="info">
                <div>
                    <div className="option">
                        <p>Tipo:</p>
                        <p>{handleAssetType(data.type)}</p>
                    </div>
                </div>
                <div>
                    <div className="option">
                        <p>Estado:</p>
                        <p className={`color-status-${status}`}>{status}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};