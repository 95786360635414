import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getCards, postCard } from "../FallServices/GeneralConfigServices";

class GeneralConfigController {

    static getAllCards = async ({
        userInfo,
        setAllInitialSetting,
        setLoaderInitialCard
    }) => {
        try {
            throw new Error('Error');
            setAllInitialSetting({
                enabled: "false"
            });
            return true;
            // }
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally {
            setLoaderInitialCard(false)
        }
    };

    static updateB89Card = async ({
        userInfo,
        data
    }) => {
        try {
            throw new Error('Error');
            ComponentsHelper.toast({
                type: toastTypes.success,
                message: 'Se actualizó la configuración de la tarjeta de manera exitosa.'
            });
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

}
export default GeneralConfigController;