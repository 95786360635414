/* eslint-disable import/first */
import React, { Component, useState } from 'react';
import packageJson from '../package.json';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { initApex, setGateway } from 'apex-web/lib/apex';
import {
  connectionOpened,
  connectionClosed
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { getQueryParam } from './helpers/queryParamsHelper';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import StandAloneLayout from './layouts/StandAloneLayout/StandAloneLayout';
import './styles/theme/dark.css';
import './styles/theme/beta.css';
import SignupPage from './pages/SignupPage';
import ProblemLoggingInPage from './pages/ProblemLoggingInPage';
import DepositConfirmation from 'apex-web/lib/components/DepositConfirmation/DepositConfirmationModalContainer';
import Snackbar from 'apex-web/lib/components/APSnackbar/APSnackbarContainer';
import TwoFactor from 'apex-web/lib/components/TwoFactorAuthForm/TwoFactorAuthFormContainer';
import SidePane from './components/common/SidePane/SidePaneContainer';
import { emailLinkRoutes } from 'apex-web/lib/helpers/emailLinksHelper';
import EmailLinks from 'apex-web/lib/layouts/EmailLinksLayout.js';
import InteriorPage from './pages/InteriorPage';
import LoginPage from './pages/LoginPage';
import { getGateway } from 'apex-web/lib/helpers/wsHelper';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import SimpleExchangePage from './pages/Exchange/SimpleExchangePage';
import config from './config';
import Require2FA from 'apex-web/lib/components/2FA/TwoFactorAuthContainer';
import StepTitle from './pages/StepsTitle';
import { ToastContainer } from 'react-toastify';
import empty from 'is-empty';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMinusCircle,
  faInfoCircle,
  faShareSquare,
  faAngleLeft,
  faFileDownload,
  faEye,
  faWindowClose,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import LoginPage2 from './pages/FallPages/Login/LoginPage';
library.add(
  faMinusCircle,
  faInfoCircle,
  faShareSquare,
  faAngleLeft,
  faFileDownload,
  faEye,
  faWindowClose,
  faCheck
);
import { validateIp } from './helpers/lib';
import { toast } from 'react-toastify';
import { getMe } from './pages/AdminLevels/AdminLevelsHooks';
import Loading from './components/Loading';
import InteriorPageBeta from './pages/InteriorPageBeta';
import isEmpty from 'is-empty';

class App extends Component {
  constructor(props) {
    super(props);
    this.setBeta(true)
    setGateway(getGateway());
    initApex(this.props.connectionOpened, this.props.connectionClosed);
    const AffiliateTag = getQueryParam('aff');
    if (AffiliateTag) {
      window.localStorage.setItem('affiliateTag', AffiliateTag);
    }
    this.state = {
      firtCharge: true,
      isBeta: true,
      openMenu: true,
      run: true,
      activate: false,
      disableBeacon: true,
      updateMenu: null,
      loading: 0,
      steps: [
        {
          content: (
            <StepTitle
              title="Welcome to Banexcoin"
              content="Welcome to Banexcoin, a new platform to exchange crypto assets in the easiest way. Next, we will show you each element of the platform:"
            />
          ),
          placement: 'center',
          target: 'body'
        },
        {
          target: '.second-step',
          content: (
            <StepTitle
              title="Verification Levels"
              content="Verification levels are important within Banexcoin, depending on the level you have, there will be benefits for your account, such as: Best rates, wide deposit or withdrawal limits."
            />
          )
        }
      ]
    };
  }

  setOpenMenu = status => {
    this.setState({
      openMenu: status
    });
  };

  setBeta = status => {
    this.setState({
      isBeta: status
    });
    if (status == true) document.body.classList.add('admin-v2');
    else document.body.classList.remove('admin-v2');
    localStorage.setItem('isBeta', status);
  };

  showJoy = () => {
    this.setState({
      run: true,
      activate: true
    });
  };
  myLogHandler = () => {
    this.setState({
      run: true,
      activate: true
    });
  };
  syncTokenGBC(props) {
    (async () => {
      try {
        this.setState({
          loading: 1
        });

        localStorage.removeItem('tokenDB');
        await getMe(props.UserId).catch(e => {
          console.error('Error login in::: ', e);
          // @ts-ignore
          window.location = '/';
          localStorage.clear();
          toast.warn('Invalid User', {
            position: toast.POSITION.TOP_CENTER
          });
        });

        this.setState({
          updateMenu: new Date().getTime(),
          loading: 0
        });
      } catch (error) {
        console.log('syncTokenGBC:::', error.response);
        this.setState({
          loading: 0
        });
      }
    })();
  }
  componentWillMount = () => {
    let localBeta = localStorage.getItem('isBeta');
    if (localBeta == 'true') {
      document.body.classList.add('admin-v2');
    } else {
      document.body.classList.remove('admin-v2');
    }
    this.setBeta(localBeta == 'true' ? true : false);
  };

  componentDidMount = () => {
    (async () => {
      if (
        this.props.location.pathname != '/login' &&
        !empty(this.props.UserId)
      ) {
        let validIp = await validateIp(this.props.UserId);
        if (!validIp) {
          localStorage.clear();
          // @ts-ignore
          window.location = '/login';
          toast.warn('Invalid Access to this Ip Address', {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } else {
        localStorage.removeItem('tokenDB');
      }
    })();
  };

  componentDidUpdate({ UserId }) {
    if (!isEmpty(UserId)) {
      if (this.state.firtCharge) {
        this.onRouteChanged(UserId)
        this.setState({
          firtCharge: false
        });
      }
    }
  }

  onRouteChanged(UserId) {
    (async () => {
      if (this.props.location.pathname != '/login') {
        let validIp = await validateIp(UserId);
        if (!validIp) {
          toast.warn('Invalid Access to this Ip Address', {
            position: toast.POSITION.TOP_CENTER
          });
          localStorage.clear();
          // @ts-ignore
          window.location = '/login';
        }
      } else {
        localStorage.removeItem('tokenDB');
      }
    })();
  }

  render() {
    const { pathname } = this.props.location;
    const { run, steps, updateMenu, loading } = this.state;
    if (
      !empty(this.props.selectedAccountId) &&
      empty(updateMenu) &&
      loading == 0
    ) {
      this.syncTokenGBC(this.props);
    }

    return (
      <React.Fragment>
        <div
          className={
            'App fluid container' +
            (this.props.isAuthenticated ? ' logged' : '') +
            (this.state.openMenu ? ' open' : '')
          }>
          <Switch>
            <Route
              path="/version"
              render={() => (
                <React.Fragment>
                  <Loading loading={loading} />
                  <div>
                    <p>v3implementation-template: {packageJson.version}</p>
                    <p>apex-web: {packageJson.dependencies['apex-web']}</p>
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              exact
              path={'/'}
              component={redirectIfAuthorized(
                this.state.isBeta ? LoginPage2 : LoginPage,
                process.env.REACT_APP_DEFAULT_PATH
              )}
            />
            {emailLinkRoutes(pathname, EmailLinks)}
            <Route
              path={'/login'}
              component={redirectIfAuthorized(
                this.state.isBeta ? LoginPage2 : LoginPage,
                process.env.REACT_APP_DEFAULT_PATH
              )}
            />
            <Route
              path={'/v2/login'}
              component={redirectIfAuthorized(
                LoginPage2,
                process.env.REACT_APP_DEFAULT_PATH
              )}
            />
            <Route path={'/signup'} component={SignupPage} />
            <Route
              path={'/problem-logging-in'}
              component={ProblemLoggingInPage}
            />
            <Route path={'/twofactorauth'}>
              <React.Fragment>
                <Loading loading={loading} />
                <StandAloneLayout className="v2container-box">
                  <TwoFactor />
                </StandAloneLayout>
              </React.Fragment>
            </Route>
            {config.SimpleExchange.active && (
              <Route
                path={config.SimpleExchange.route}
                component={
                  <React.Fragment>
                    <Loading loading={loading} />
                    <SimpleExchangePage />
                  </React.Fragment>
                }
              />
            )}
            {/* passing props.location prevents Blocked Re-renders: https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking */}
            {this.state.isBeta ? (
              <InteriorPageBeta
                setBeta={this.setBeta}
                isBeta={this.state.isBeta}
                setOpenMenu={this.setOpenMenu}
                openMenu={this.state.openMenu}
              />
            ) : (
              <React.Fragment>
                <Loading loading={loading} />
                <InteriorPage
                  location={this.props.location}
                  updateMenu={this.state.updateMenu}
                  setOpenMenu={this.setOpenMenu}
                  openMenu={this.state.openMenu}
                  setBeta={this.setBeta}
                  isBeta={this.state.isBeta}
                />
              </React.Fragment>
            )}
          </Switch>
        </div>
        <Snackbar />
        <SidePane />
        <DepositConfirmation />
        <Require2FA />
        <ToastContainer />
      </React.Fragment>
    );
  }
}

var mapStateToProps = function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    UserId: state.user.userInfo.UserId,
    UserConfig: state.user.userConfig,
    Permissions: state.user.permissions,
    UserInfo: state.user.userInfo,
    selectedAccountId: state.user.selectedAccountId
  };
};
const mapDispatchToProps = {
  onInit,
  connectionOpened,
  connectionClosed
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);