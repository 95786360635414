import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getQueues = async (userId, filterConfig, filterSearch) => {
  try {
    const path = `/api/v2/acuant/logs`;
    const params = {
      filterConfig,
      filterSearch
    };

    const configRequest = {
      userId,
      method: 'GET',
      path,
      data: params
    };

    const response = await HandlerBackend.sendRequest(configRequest).catch(e => {
      throw e;
    });
    return !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
};

export const getQueuesV3 = async (userId, filterConfig, filterSearch) => {
  try {
    const path = `/api/v3/acuant/logs`;
    const params = {
      filterConfig,
      filterSearch
    };

    const configRequest = {
      userId,
      method: 'GET',
      path,
      data: params
    };

    const response = await HandlerBackend.sendRequest(configRequest).catch(e => {
      throw e;
    });
    return !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
};

export const getQueuesV3CSV = async (userId, filterConfig, filterSearch) => {
  try {
    const path = `/api/v3/acuant/logs/export`;
    const params = {
      filterConfig,
      filterSearch
    };

    const configRequest = {
      userId,
      method: 'GET',
      path,
      data: params
    };

    const response = await HandlerBackend.sendRequest(configRequest).catch(e => {
      throw e;
    });
    return !empty(response.data) ? response.data : "";
  } catch (error) {
    throw error;
  }
};