import React from 'react'
import DateHelper from '../../../../helpers/Date.helper';
import '../allTradeReportPage.css'

const TradeReportDetails = ({ tradeInfo }) => {

   const diferencePrice = Number(tradeInfo.apPrice) - Number(tradeInfo.binancePrice);

   const dateTime = (time) => (DateHelper.formatDate(time));

   return (
      <div className='right-resizable-user-info'>
         <div className="header-section">
            <h2 className='title-detail'>Detalles de Trade</h2>
         </div>
         <div className='container-trade-info'>
            <div className='separator-info'>
               <div className="option">
                  <p>AP trade datetime:</p>
                  <p>{dateTime(tradeInfo.apTradeDatetime) || '-'}</p>
               </div>
               <div className="option">
                  <p>AP account Id:</p>
                  <p>{tradeInfo.apAccountid || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>AP usuario:</p>
                  <p>{tradeInfo.apUsername || '-'}</p>
               </div>
               <div className="option">
                  <p>AP símbolo:</p>
                  <p>{tradeInfo.apInstrumentsymbol || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>AP maker/taker:</p>
                  <p>{tradeInfo.apMakertaker || '-'}</p>
               </div>
               <div className="option">
                  <p>AP order Id:</p>
                  <p>{tradeInfo.apOrderid || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>AP price:</p>
                  <p>{tradeInfo.apPrice || '-'}</p>
               </div>
               <div className="option">
                  <p>AP quantity:</p>
                  <p>{tradeInfo.apQuantity || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>AP trade Id:</p>
                  <p>{tradeInfo.apTradeid || '-'}</p>
               </div>
               <div className="option">
                  <p>AP side:</p>
                  <p>{tradeInfo.apSide || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>Diferencias/Precios:</p>
                  <p>{diferencePrice}</p>
               </div>
               <div className="option">
                  <p>Binance executed quantity:</p>
                  <p>{tradeInfo.binanceExecutedqty || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>Binance orig quantity:</p>
                  <p>{tradeInfo.binanceOrigqty || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>Binance price:</p>
                  <p>{tradeInfo.binancePrice || '-'}</p>
               </div>
               <div className="option">
                  <p>Binance side:</p>
                  <p>{tradeInfo.binanceSide || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>Binance status:</p>
                  <p>{tradeInfo.binanceStatus || '-'}</p>
               </div>
               <div className="option">
                  <p>Binance symbol:</p>
                  <p>{tradeInfo.binanceSymbol || '-'}</p>
               </div>
            </div>
            <div className='separator-info'>
               <div className="option">
                  <p>Binance type:</p>
                  <p>{tradeInfo.binanceType || '-'}</p>
               </div>
               <div className="option">
                  <p>Binance datetime:</p>
                  <p>{dateTime(Number(tradeInfo.binanceTime)) || '-'}</p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default TradeReportDetails