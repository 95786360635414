import empty from 'is-empty';
import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';
const queryString = require('query-string');

export const getDeposits = async (params, userId) => {
  let result = [];
  try {
    let path = '/api/depositTickets';

    if (!empty(params)) {
      path += '?' + queryString.stringify(params);
    }
    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data.allTickets : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const getDepositTicket = async (accountId, requestCode, userId) => {
  let result = {};
  try {
    let path = `/api/depositTickets/${accountId}/${requestCode}`;
    let configRequest = {
      userId,
      method: 'get',
      path
    };
    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
  return result;
};

export const submitComment = async (userId, data) => {
  let path = '/api/depositTickets/comment';
  let model = {
    comment: data.comment,
    ticketCode: data.ticketCode,
    ticketId: data.ticketId
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: model
  });
};

export const acceptDeposit = async (userId, data) => {
  let path = '/api/depositTickets/accept';
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data
  });
};

export const rejectDeposit = async (userId, data) => {
  let path = '/api/depositTickets/reject';
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data
  });
};

// SERVICES HOOKS

export const bankAccounts = async userId => {
  let path = '/api/services/bank/accounts';
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    data: { path: '/api/v1/bank/accounts' },
    path
  }).catch(e => {
    throw e;
  });
};

export const bankAccountBalance = async (userId, data) => {
  let path = '/api/utils/bank/accounts/balance';
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    data,
    path
  }).catch(e => {
    throw e;
  });
};

export const bankTransactions = async (userId, data) => {
  let path = '/api/services/bank/transactions';
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    data,
    path
  }).catch(e => {
    throw e;
  });
};

export const synBankTransactions = async (userId, data) => {
  let path = '/api/services/bank/transactions/sync';
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    data,
    path
  }).catch(e => {
    throw e;
  });
};

export const freezeBankTransactions = async (userId, data) => {
  let path = '/api/services/bank/freezedeposit';
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    data,
    path
  }).catch(e => {
    throw e;
  });
};

export const deleteBankTransaction = async (userId, data) => {
  let path = '/api/services/bank/transaction/delete';
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    data,
    path
  }).catch(e => {
    throw e;
  });
};
