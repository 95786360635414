import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import Download from "../../../images/new_icons/download.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import DateHelper from "../../../helpers/Date.helper";
import { GroupFilters } from "../Filters/Filters";
import {
  acuantLogsTypes,
} from "../../../constants";
import ComponentsHelper from "../../../helpers/Components.helper";

const AllAcuantLogsData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    selectedLog,
    withRightSection,
    resetFilters,
    setFilterSearch,
    downloadRows,
    handleSelectRow
  } = props;

  const [filterInputs, setFilterInputs] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const handleSearchFilters = () => {
    let filters = {}
    Object.keys(filterInputs).forEach(item => {
        filters[item] = {includesInsensitive: filterInputs[item]}
    })
    setFilterSearch(filters)
  };
  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon:Download,
      action: downloadRows
    },
    {
      icon: RotateRight,
      action: () => {
        resetFilters();
        setFilterInputs({
          userId: "",
          accountId: "",
          banexcoinUsername: "",
        });
      },
    },
  ];

  const tableHeaders = [
    {
      tag: "",
      title: "ID",
      cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "ID"),
      className: "minimizer no-Selection",
    },
    {
      tag: "createdAt",
      title: "Fecha de creación",
      handleSelectRow,
      cell: ({ data }) => DateHelper.formatDate(data.createdAt),
    },
    {
      tag: "updatedAt",
      title: "Fecha de actualización",
      handleSelectRow,
      cell: ({ data }) => DateHelper.formatDate(data.updatedAt),
    },
    {
      tag: "userId",
      title: "User ID",
      handleSelectRow,
    },
    {
      tag: "accountId",
      title: "Account ID",
      handleSelectRow,
    },
    {
      tag: "banexcoinUsername",
      title: "Nombre de usuario",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Estado",
      cell: ({ data }) => (
        <p>
          {
            (acuantLogsTypes.find((item) => item.value === data.status) || {})
            .name
          }
        </p>
      ),
      handleSelectRow,
    },
  ];

  const filters = [
    {
      values: {
        title: "User ID",
        name: "userId",
        value: filterInputs.userId,
        type: "text",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "User account ID",
        name: "accountId",
        value: filterInputs.accountId,
        type: "text",
        required: true,
        rootClassname: "min-width-filter-input-acuant-accountid",
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Nombres de usuario analizado",
        name: "banexcoinUsername",
        value: filterInputs.banexcoinUsername,
        type: "text",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Estado",
        name: "status",
        value: filterInputs.status,
        type: "select",
        options: acuantLogsTypes,
        isClearable: true,
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
  ];

  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Acuant Logs")}
        subTitle="Cumplimiento / Acuant Logs"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedLog.id}
      >
        {showFilters && (
          <GroupFilters
            inputs={filters}
            withRightSection={withRightSection}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllAcuantLogsData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllAcuantLogsData);
