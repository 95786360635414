import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./amlAlertsDetails.css";
import { CustomButton } from "../../CustomButton/CustomButton";
import InputElement from "../../InputElement/InputElement";
import {
  alertCalificationTypes,
  alertStatus,
  userLevels,
} from "../../../../constants";
import DateHelper from "../../../../helpers/Date.helper";
import isEmpty from "is-empty";

const AmlAlertsDetails = (props, context) => {
  const {
    data,
    handleUpdateUserOnboardingtatus,
    handleModifyEvent,
    loadingUpdate,
  } = props;
  const [inputs, setInputs] = useState({});
  const [actionType, setActionType] = useState("");

  const handleOnChange = (target) => {
    setInputs({
      ...inputs,
      [target.name]: target.value,
    });
  };

  const validationToShowApproveRejectButton =
    data.updatedBy === "ONBOARDING" &&
    (inputs.status === "RESOLVED" ||
      data.userEventByUserEventId.status === "RESOLVED");

  const validationToShowAlertCalificationInput =
    inputs.status === "RESOLVED" ||
    data.userEventByUserEventId.status === "RESOLVED";

  const handleOnClickModifyEvent = () => {
    setActionType("MODIFY");
    const inputsToSend = { ...inputs };
    if (inputs.status !== "RESOLVED") {
      delete inputsToSend["alertCondition"];
    }
    handleModifyEvent(inputsToSend);
  };

  const constructFullNameAmlUser = () => {
    if (isEmpty(data.userEventByUserEventId.blacklistInfoByBlacklistInfoId))
      return "-";
    const { firstName, secondName, lastName, paternoRazonSocial } =
      data.userEventByUserEventId.blacklistInfoByBlacklistInfoId;
    return `${firstName} ${secondName} ${lastName} ${paternoRazonSocial}`;
  };

  const constructDocumentNumberAmlUser = () => {
    if (isEmpty(data.userEventByUserEventId.blacklistInfoByBlacklistInfoId))
      return "-";
    const { typeDocument, numDocument } =
      data.userEventByUserEventId.blacklistInfoByBlacklistInfoId;
    return `${typeDocument} - ${numDocument}`;
  };

  const constructUsername = () => {
    if (
      isEmpty(
        data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId.nodes
      )
    )
      return "-";
    const { firstName, middleName, lastName, secondLastName } =
      data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId
        .nodes[0];
    return `${firstName} ${middleName} ${lastName} ${secondLastName}`;
  };

  const constructUserDocumentNumber = () => {
    if (
      isEmpty(data.userEventByUserEventId.blacklistInfoByBlacklistInfoId.nodes)
    )
      return "-";
    const { documentType, documentNumber } =
      data.userEventByUserEventId.blacklistInfoByBlacklistInfoId;
    return `${documentType} - ${documentNumber}`;
  };

  const getBanexcoinUserSlot = (slot) => {
    if (
      isEmpty(
        data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId.nodes
      )
    )
      return "-";
    return (
      data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId
        .nodes[0][slot] || "-"
    );
  };
  const getUserLevel = () => {
    if (
      isEmpty(
        data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId.nodes
      )
    )
      return "-";
    return userLevels[
      data.banexcoinUserByBanexcoinUserId.levelsBasicsByBanexcoinUserId
        .nodes[0]["userLevel"]
    ];
  };

  const eventStatusInputProps = {
    values: {
      title: "Estado de evento",
      name: "status",
      value: data.userEventByUserEventId.status || inputs.status,
      type: "select",
      options: alertStatus,
      placeholder: "Seleccione una opción",
      required: true,
      rootClassname: "input-element-aml-alert",
    },
    actions: {
      onChange: handleOnChange,
    },
  };
  const eventCalificationInputProps = {
    values: {
      title: "Calificación de alerta",
      name: "event_calification",
      value: inputs.event_calification || "",
      type: "select",
      options: alertCalificationTypes,
      placeholder: "Seleccione una opción",
      required: true,
      rootClassname: "input-element-aml-alert",
    },
    actions: {
      onChange: handleOnChange,
    },
  };
  const commentInputProps = {
    values: {
      name: "comments",
      value: inputs.comments || "",
      type: "textarea",
      placeholder: "Añade un comentario...",
      required: true,
      rootClassname: "input-element-aml-alert",
    },
    actions: {
      onChange: (ev) => handleOnChange(ev.target),
    },
  };
  const modifyEventButtonProps = {
    onClick: handleOnClickModifyEvent,
    text: "Modificar evento",
    className: "orange",
    disabled: loadingUpdate || isEmpty(inputs),
    showLoader: loadingUpdate && actionType === "MODIFY",
    customClassName: "onboarding-buttons-approve-reject",
  };
  const approveButtonProps = {
    onClick: () => {
      handleUpdateUserOnboardingtatus("approve");
      setActionType("APPROVE");
    },
    text: "Aprobar onboarding",
    className: "green",
    disabled:
      loadingUpdate || data.banexcoinUserByBanexcoinUserId.userStatus === 2,
    showLoader: loadingUpdate && actionType === "APPROVE",
    customClassName: "onboarding-buttons-approve-reject",
  };
  const rejectButtonProps = {
    onClick: () => {
      handleUpdateUserOnboardingtatus("reject");
      setActionType("REJECT");
    },
    text: "Rechazar onboarding",
    className: "red",
    disabled:
      loadingUpdate || data.banexcoinUserByBanexcoinUserId.userStatus === 2,
    showLoader: loadingUpdate && actionType === "REJECT",
    customClassName: "onboarding-buttons-approve-reject",
  };

  const getStatus =
    alertStatus.find(
      (alert) =>
        alert.value.toLowerCase() ===
        data.userEventByUserEventId.status.toLowerCase()
    ) || {};

  const HeaderSection = () => {
    return (
      <div className="header-section">
        <div>
          <h2>{data.userEventByUserEventId.idAmlAlert}</h2>
        </div>
        <div className="badge-container-status">
          <p
            className={`color-status-${data.userEventByUserEventId.status.toLowerCase()}`}
          >
            {getStatus.name}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="aml-alerts-details">
      <HeaderSection />
      <div>
        <div className="aml-alerts-actions-content">
          <h2 className="subtitle">Detalle de alerta</h2>
          <div className="slot-details">
            <div className="option">
              <p>Fecha de generación</p>
              <p>
                {DateHelper.formatDate(
                  data.userEventByUserEventId.blacklistInfoByBlacklistInfoId
                    .createdAt
                )}
              </p>
            </div>
            <div className="option">
              <p>Aml checked</p>
              <p>
                {data.userEventByUserEventId.blacklistInfoByBlacklistInfoId
                  .isChecked
                  ? "Si"
                  : "No"}
              </p>
            </div>
            <div className="option">
              <p>Usuario Aml</p>
              <p>{constructFullNameAmlUser()}</p>
            </div>
            <div className="option">
              <p>Documento de identidad</p>
              <p>{constructDocumentNumberAmlUser()}</p>
            </div>
            <div className="option">
              <p>ID de alerta</p>
              <p>{data.userEventByUserEventId.idAmlAlert}</p>
            </div>
            <div className="option">
              <p>Estado</p>
              <p>{data.userEventByUserEventId.status || "-"}</p>
            </div>
            <div className="option">
              <p>Tipo de evento</p>
              <p>{data.userEventByUserEventId.eventType || "-"}</p>
            </div>
            <div className="option">
              <p>Calificación de señal de alerta</p>
              <p>{data.alertCalification || "-"}</p>
            </div>
            <div className="option">
              <p>Actualizado por</p>
              <p>{data.updatedBy}</p>
            </div>
            <div className="option">
              <p>Fuente</p>
              <p>
                {data.userEventByUserEventId.blacklistInfoByBlacklistInfoId
                  .blacklistSource || "N/A"}
              </p>
            </div>
          </div>
          <h2 className="subtitle">Detalle de usuario</h2>
          <div className="slot-details">
            <div className="option">
              <p>Nombre completo</p>
              <p>{constructUsername()}</p>
            </div>
            <div className="option">
              <p>Documento de identidad</p>
              <p>{constructUserDocumentNumber()}</p>
            </div>
            <div className="option">
              <p>Id de usuario Banexcoin</p>
              <p>{data.banexcoinUserByBanexcoinUserId.banexcoinUserid}</p>
            </div>
            <div className="option">
              <p>Usuario Banexcoin</p>
              <p>{data.banexcoinUserByBanexcoinUserId.banexcoinUsername}</p>
            </div>
            <div className="option">
              <p>Correo electrónico</p>
              <p>{data.banexcoinUserByBanexcoinUserId.email}</p>
            </div>
            <div className="option">
              <p>Nivel actual</p>
              <p>{getUserLevel()}</p>
            </div>
            <div className="option">
              <p>País de residencia</p>
              <p>{getBanexcoinUserSlot("residenceCountry")}</p>
            </div>
            <div className="option">
              <p>Teléfono</p>
              <p>{getBanexcoinUserSlot("phone")}</p>
            </div>
            <div className="option">
              <p>Detalles de ocupación</p>
              <p>{getBanexcoinUserSlot("occupation")}</p>
            </div>
            <div className="option">
              <p>Ocupación</p>
              <p>{getBanexcoinUserSlot("occupationDetails")}</p>
            </div>
            <div className="option">
              <p>Origen de fondos</p>
              <p>{getBanexcoinUserSlot("originOfFunds")}</p>
            </div>
          </div>
          <h2 className="subtitle">Comentarios</h2>
          {!isEmpty(data.comments) ? (
            <ul>
              {JSON.parse(data.comments).map((comment) => (
                <li>{comment}</li>
              ))}
            </ul>
          ) : (
            ""
          )}
          <h2 className="subtitle">Actualizar estado de alerta</h2>
          <InputElement {...eventStatusInputProps} />
          {validationToShowAlertCalificationInput && (
            <InputElement {...eventCalificationInputProps} />
          )}
          <InputElement {...commentInputProps} />
        </div>
        <div className="aml-alerts-actions-button">
          <CustomButton {...modifyEventButtonProps} />
          {validationToShowApproveRejectButton && (
            <React.Fragment>
              <CustomButton {...approveButtonProps} />
              <CustomButton {...rejectButtonProps} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (global) => {
  const {
    user: { userInfo },
    product: { products },
  } = global;
  return { userInfo, products };
};

AmlAlertsDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AmlAlertsDetails);
