/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ScriptLoader from 'apex-web/lib/helpers/scriptLoader';

var baseClasses = getBEMClasses('deposit-form');

var TrustpayDepositWorkflowComponent = function TrustpayDepositWorkflowComponent(
  _ref
) {
  var url = _ref.url;
  return React.createElement(
    'div',
    { className: baseClasses('trustpay-form') },
    React.createElement('form', {
      action: location.href,
      className: 'paymentWidgets',
      'data-brands': 'VISA MASTER AMEX'
    }),
    React.createElement(ScriptLoader, { url: url })
  );
};

TrustpayDepositWorkflowComponent.defaultProps = {
  url: '',
  title: ''
};

TrustpayDepositWorkflowComponent.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
};

export default TrustpayDepositWorkflowComponent;
