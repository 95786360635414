import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CustomButton } from "../FallComponents/CustomButton/CustomButton";
import './confirmModal.css'
const ConfirmModal = (props, context) => {
  const {
    showModal,
    handleToggleModal,
    title,
    message,
    confirmButtonProps,
    cancelButtonProps,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleConfirmAction = async () => {
    setLoading(true);
    confirmButtonProps.onClick && await confirmButtonProps.onClick();
    setLoading(false);
    handleToggleModal();
  };
  const handleCancelAction = () => {
    cancelButtonProps.onClick && cancelButtonProps.onClick();
    handleToggleModal();
  };

  return (
    <Modal className="custom-modal-confirm-container" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {title}
      </ModalHeader>
      <ModalBody>
        <p dangerouslySetInnerHTML={{__html: message}} />
        <div className="buttons-confirm-container">
        <CustomButton
          {...confirmButtonProps}
          disabled={loading}
          text={confirmButtonProps.text}
          onClick={handleConfirmAction}
          className="green"
          />
        <CustomButton
          {...cancelButtonProps}
          disabled={loading}
          showLoader={false}
          text={cancelButtonProps.text}
          onClick={handleCancelAction}
          className="outline"
        />
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

ConfirmModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ConfirmModal);
