import empty from 'is-empty';

function getKeyByValue(object, value) {
  if (!empty(object[value])) {
    return object[value];
  } else {
    return value;
  }
}

export const translationFunction = (text, cust) => {
  let lang = localStorage.getItem('language') || 'en';
  const traducir = (texto, custom = null) => {
    if (!empty(custom)) {
      let arrayNames = [];
      let arrayReplace = [];
      let textoTraducido = getKeyByValue(window.APEXTranslations[lang], texto);
      for (let i in custom) {
        arrayNames.push(i);
        arrayReplace.push(custom[i]);
      }
      if (!empty(arrayNames) && !empty(arrayReplace)) {
        for (var i = 0; i < arrayNames.length; i++) {
          textoTraducido = textoTraducido.replace(
            new RegExp('{' + arrayNames[i] + '}', 'gi'),
            arrayReplace[i]
          );
        }
      }
      return textoTraducido;
    }
    return getKeyByValue(window.APEXTranslations[lang], texto);
  };
  if (!empty(cust)) {
    return traducir(text, cust);
  }
  return traducir(text);
};
