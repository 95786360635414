export const ACCOUNTS_CURRENCY = [
  {
    name: 'All',
    value: 'empty',
  },
  {
    name: 'USD',
    value: 'USD',
  },
  {
    name: 'PEN',
    value: 'PEN',
  }
]