import React, { useRef, useState } from 'react'
import isEmpty from "is-empty";
import { Button, Form, InputGroup } from "react-bootstrap";
import { CustomButton } from '../../CustomButton/CustomButton';

export const CommentsTemplate = ({ context, comments, handleSubmitNewComment, editPermission }) => {
  const [loading, setLoading] = useState(false)
  const newCommentRef = useRef(null);
  const executeSubmit = () => {
    handleSubmitNewComment(newCommentRef.current.value, setLoading)
  }
  return (
    <div className="comments content-tab">
        <div className='content-tab-container '>
        <div>
          <h2>{context.t("Comments")}</h2>
        </div>
        <ul>
          {(!isEmpty(comments) && comments.length > 0) &&
            comments.map((c) => (
              <li key={c.id}>
                <strong>{c.date} -</strong> {c.comment}
              </li>
            ))}
        </ul>
        {editPermission &&
          <Form.Control
            as="textarea"
            rows="3"
            name="comment"
            ref={newCommentRef}
          />
        }
      </div>
      {editPermission &&
        <div className='buttons-container align-right'>
          <CustomButton disabled={loading} onClick={executeSubmit} type="submit" text="Añadir comentario" className="purple" />
        </div>
      }
    </div>
  );
};