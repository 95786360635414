import {
  getAllAccounts,
  getBankList,
  getCountries,
  saveBanexcoinAccount,
  toggleStateBanexcoinAccount,
} from "../FallServices/BanexcoinAccountsService";
import ComponentHelper from "../../helpers/Components.helper";
class BanexcoinAccountsController {
  static getAllBanexcoinAccounts = async ({
    userId,
    filterConfig,
    setAccounts,
    setTotalRows,
    setAccountsToShowByStatus,
  }) => {
    try {
      const response = await getAllAccounts(userId, filterConfig);
      if (response.success) {
        setAccounts(response.data);
        setAccountsToShowByStatus(response.data);
        setTotalRows(response.totalOfRows);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  static sendBanexcoinAccount = async ({
    userId,
    data,
    setErrorsValidation,
  }) => {
    try {
      const response = await saveBanexcoinAccount(userId, data, "POST");
      if (response.data.success) {
        ComponentHelper.toast({
          type: "success",
          message: "La cuenta bancaria fue agregada correctamente.",
        });
        return true;
      }
      ComponentHelper.toast({
        type: "warn",
        message: "Hubo un problema. Por favor, vuelva a intentarlo.",
      });
      return false;
    } catch (error) {
      return BanexcoinAccountsController.handlerErrors(
        error.response.data.data,
        setErrorsValidation
      );
    }
  };
  static updateBanexcoinAccount = async ({
    userId,
    data,
    setErrorsValidation,
  }) => {
    try {
      const response = await saveBanexcoinAccount(userId, data, "PUT");
      if (response.data.success) {
        ComponentHelper.toast({
          type: "success",
          message: "La cuenta bancaria fue actualizada correctamente.",
        });
        return true;
      }
      ComponentHelper.toast({
        type: "warn",
        message: "Hubo un problema. Por favor, vuelva a intentarlo.",
      });
      return false;
    } catch (error) {
      return BanexcoinAccountsController.handlerErrors(
        error.response.data.data,
        setErrorsValidation
      );
    }
  };
  static changeStateBanexcoinAccount = async ({
    userId,
    banexcoinAccountId,
    state,
  }) => {
    try {
      const response = await toggleStateBanexcoinAccount(
        userId,
        banexcoinAccountId,
        state
      );
      const message = `La cuenta bancaria fue ${
        state === "activate" ? "activada" : "desactivada"
      } exitosamente`;
      if (response.success) {
        ComponentHelper.toast({
          type: "success",
          message: message,
        });
        return true;
      }
      ComponentHelper.toast({
        type: "warn",
        message: "Hubo un problema. Por favor, vuelva a intentarlo.",
      });
      return false;
    } catch (error) {
      ComponentHelper.toast({
        type: "warn",
        message: "Hubo un problema. Por favor, vuelva a intentarlo.",
      });
      return false;
    }
  };

  static getListCountries = async ({ userInfo }) => {
    try {
      const result = await getCountries(userInfo.UserId);
      return result.data.countries;
    } catch (error) {
      return false;
    }
  };

  static getAllBankList = async ({ userInfo, countryCode }) => {
    try {
      const result = await getBankList(
        userInfo.UserId,
        { page: 0, limit: 0 },
        countryCode
      );

      return result.data;
    } catch (error) {
      return false;
    }
  };

  static handlerErrors = (errors, setErrorsValidation) => {
    let dynamicInputsErrors = {
      accountRulesInstructions: [],
      accountDetails: [],
    };
    Object.keys(errors).forEach((key) => {
      const element = key.split("/");
      const slot = element[0];
      const pos = element[2];
      dynamicInputsErrors[slot] = { ...dynamicInputsErrors[slot], [pos]: true };
    });
    setErrorsValidation({ ...errors, dynamicInputsErrors });
    ComponentHelper.toast({
      type: "warn",
      message: "Hubo un problema. Por favor, vuelva a intentarlo.",
    });
    return false;
  };
}

export default BanexcoinAccountsController;
