import isEmpty from 'is-empty';
import React, { useState } from 'react'
import ComponentsHelper from '../../../helpers/Components.helper';
import { useForm } from '../../../hooks/formHooks';
import { GroupFilters } from '../Filters/Filters';
import HeaderPage from '../HeaderPage/HeaderPage'
import InputElement from '../InputElement/InputElement';
import TableContainer from '../Table/TableContainer';

const AllReniecPage = (props) => {

    const { loading, handleSearchByNames, handleSearchByDni, reniecUserList, handleSelectRow, reniecUserId, setWithRightSection,
        setSearchWithDni } = props;

    const [searchType, setSearchType] = useState('');

    const filterInitialValuesNames = {
        first_surname: '',
        second_surname: '',
        names: ''
    };

    const filterInitialValuesDni = {
        dni: ''
    };

    const { inputs, onInputChangeV2 } = useForm(filterInitialValuesNames);
    const { first_surname, second_surname, names } = inputs;

    const { inputs: inputsN2, onInputChangeV2: onInputChangeV2N2 } = useForm(filterInitialValuesDni);
    const { dni } = inputsN2;

    const handleSearch = () => {
        if (searchType === 'names') {
            setSearchWithDni(false);
            const validateNames = (first_surname.trim() || second_surname.trim() || names.trim());
            validateNames && handleSearchByNames(first_surname, second_surname, names);
        } else {
            setSearchWithDni(true);
            setWithRightSection(false);
            const validateDni = dni.trim();
            validateDni && handleSearchByDni(dni, true);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') handleSearch();
    }

    const inputType = {
        values: {
            title: 'Tipo de búsqueda',
            name: 'searchType',
            value: searchType,
            type: 'select',
            options: [
                {
                    name: 'Búsqueda por DNI',
                    value: 'dni'
                },
                {
                    name: 'Búsqueda por nombres',
                    value: 'names'
                }
            ],
            placeholder: 'Seleccione un tipo de búsqueda',
            required: true
        },
        actions: {
            onChange: (data) => { setSearchType(data.value) }
        }
    }

    const inputFiltersName = [
        {
            values: {
                title: 'Apellido paterno',
                name: 'first_surname',
                value: first_surname,
                type: 'text',
                required: true
            },
            actions: {
                onChange: onInputChangeV2,
                onKeyDown: handleKeyDown
            }
        },
        {
            values: {
                title: 'Apellido materno',
                name: 'second_surname',
                value: second_surname,
                type: 'text',
                required: true
            },
            actions: {
                onChange: onInputChangeV2,
                onKeyDown: handleKeyDown
            }
        },
        {
            values: {
                title: 'Nombres',
                name: 'names',
                value: names,
                type: 'text',
                required: true
            },
            actions: {
                onChange: onInputChangeV2,
                onKeyDown: handleKeyDown
            }
        },
    ]

    const inputFiltersDni = [
        {
            values: {
                title: 'Número de DNI',
                name: 'dni',
                value: dni,
                type: 'number',
                required: true
            },
            actions: {
                onChange: onInputChangeV2N2,
                onKeyDown: handleKeyDown
            }
        },
    ]

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Dni: "),
            className: 'minimizer no-Selection'
        },
        {
            tag: 'id',
            title: 'Número de DNI',
            handleSelectRow
        },
        {
            tag: 'first_surname',
            title: 'Apellido paterno',
            handleSelectRow
        },
        {
            tag: 'second_surname',
            title: 'Apellido materno',
            handleSelectRow
        },
        {
            tag: 'names',
            title: 'Nombres',
            handleSelectRow
        }
    ]

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Reniec"
                subTitle="Cumplimiento / Reniec"
            />
            <div className="global-config-data">
                <div>
                    <div className='reniec-type'>
                        <InputElement {...inputType} />
                    </div>
                    {
                        !isEmpty(searchType) && (
                            searchType === 'dni' ?
                                <TypeSearchOption
                                    handleSearch={handleSearch}
                                    inputFilters={inputFiltersDni}
                                /> :
                                <TypeSearchOption
                                    handleSearch={handleSearch}
                                    inputFilters={inputFiltersName}
                                />
                        )}
                </div>
                <TableContainer
                    loading={loading}
                    headers={tableHeaders}
                    rows={reniecUserList}
                    enablePagination={false}
                    rowIdSelected={reniecUserId}
                />
            </div>
        </div>
    )
}

export default AllReniecPage

const TypeSearchOption = (props) => {
    const { handleSearch, inputFilters } = props;
    return (
        <GroupFilters
            handleSearchFilters={handleSearch}
            withRightSection={false}
            inputs={inputFilters}
        />
    )
}