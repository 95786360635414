import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { cleanReniec, getPhotoByDni, getSearchByDni, getSignByDni, postSearchByNames } from "../FallServices/ReniecServices";

class ReniecController {
    static searchByDni = async ({
        userInfo,
        dni,
        setLoadingRight,
        setLoading,
        setReniecUserInfo,
        setWithRightSection,
        state,
        setReniecUserList,
        setReniecUserId
    }) => {
        const errorMessage = () => {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            setWithRightSection(false);
            return false;
        };
        try {
            setLoadingRight(true);
            if (state) { setLoading(true); }
            else { setWithRightSection(true); }
            const clean = await cleanReniec(userInfo.UserId);
            if (clean.status !== 200) return errorMessage();
            const info = await getSearchByDni(userInfo.UserId, dni);
            if (info.data.data.error_code !== '0000') {
                setWithRightSection(false);
                ComponentsHelper.toast({
                    type: toastTypes.warn,
                    message: "Usuario no encontrado.",
                });
                return false;
            }
            const allGets = [getPhotoByDni(userInfo.UserId, dni), getSignByDni(userInfo.UserId, dni)];
            let photoAndSign = [];
            await Promise.all(allGets).then(response => {
                photoAndSign = response.map(element => element.data.data)
            })
            const newInfo = info.data.data;
            const newData = {
                ...newInfo,
                photo: photoAndSign[0],
                sign: photoAndSign[1]
            }
            setReniecUserInfo(newData);
            if (state) {
                setReniecUserList([{
                    id: newInfo.dni_number,
                    first_surname: newInfo.last_name_father,
                    second_surname: newInfo.last_name_mother,
                    names: newInfo.names,
                    state: false
                }]);
                setReniecUserId(newInfo.dni_number);
            }
            return true;
        } catch (err) {
            return errorMessage();
        } finally {
            setLoadingRight(false);
            setLoading(false);
        }
    }

    static searchByNames = async ({
        userInfo,
        setLoading,
        first_surname,
        second_surname,
        names,
        setReniecUserList
    }) => {
        const errorMessage = () => {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false
        };
        try {
            setLoading(true);
            const clean = await cleanReniec(userInfo.UserId);
            if (clean.status !== 200) return errorMessage();
            const data = { first_surname, second_surname, names }
            const response = await postSearchByNames(userInfo.UserId, data);
            if (response.status !== 200) return errorMessage();
            if (isEmpty(response.data.data.data)) {
                ComponentsHelper.toast({
                    type: toastTypes.info,
                    message: "No se encontraron usuarios.",
                });
                return false
            }
            const newData = response.data.data.data.map(element => {
                return {
                    id: element.dni.value,
                    first_surname: element.first_surname.value,
                    second_surname: element.second_surname.value,
                    names: element.names.value,
                    state: true
                };
            });
            setReniecUserList(newData);
            return true;
        } catch (err) {
            return errorMessage();
        } finally {
            setLoading(false);
        }
    }
}

export default ReniecController;