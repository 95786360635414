import React from 'react';
import { toast } from 'react-toastify';
import isEmpty from 'is-empty';
import apex from 'apex-web/lib/apex';
import moment from 'moment';
import {
  acceptDeposit,
  bankAccounts,
  bankTransactions,
  freezeBankTransactions,
  getDeposits,
  getDepositTicketByID,
  getLevelByAccountId,
  rejectDeposit,
  submitComment
} from '../FallServices/DepositServices';
import DataFormatterHelper from '../../helpers/DataFormatter.helper';
import DateHelper from '../../helpers/Date.helper';
class DepositController {
  static loadDeposits = async ({
    setLoading,
    userInfo,
    setRows,
    setTotalRows,
    totalRows,
    filterConfig,
    filterSearch,
    context
  }) => {
    try {
      setLoading(true);
      const param = {
        ...filterSearch,
        page: filterConfig.page
      };
      let result = await getDeposits(param, userInfo.UserId);
      Object.keys(filterSearch).forEach(element => {
        if (!isEmpty(filterSearch[element])) {
          result = result.filter(r =>
            r[element]
              .toString()
              .toLowerCase()
              .includes(filterSearch[element].toString().toLowerCase())
          );
        }
      });
      const newResult = result.map(data => {
        const depositInfo = DataFormatterHelper.jsonClean(data.DepositInfo);
        return {
          ...data,
          bankNameDestination: depositInfo.bankNameDestination,
          id: data.RequestCode
        };
      });
      setRows(newResult);
      if(totalRows === 0 && !isEmpty(newResult)){
        setTotalRows(newResult[0].TicketNumber);
      }
      setLoading(false);
      return newResult
    } catch (error) {
      setLoading(false);
      toast.warn(context.t("La información no es accesible."), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  static loadDepositByID = async ({
    userInfo,
    selectedDeposit,
    setLoadingInfoByID,
    setLimits,
    setTicketInfo,
    setDepositInfo,
    setCommentInfo,
    setLevelInfo,
    setLevelCorporate,
    resetState,
    context
  }) => {
    setLoadingInfoByID(true);
    try {
      resetState();
      const result = await getDepositTicketByID(
        selectedDeposit.AccountId,
        selectedDeposit.RequestCode,
        userInfo.UserId
      );
      const levelResult = await getLevelByAccountId(
        userInfo.UserId,
        selectedDeposit.AccountId
      );
      if (!isEmpty(result.limits)) {
        setLimits(result.limits);
      }
      if (!isEmpty(result.ticket)) {
        setTicketInfo(result.ticket);
        const depositInfoData = DataFormatterHelper.jsonClean(result.ticket.DepositInfo);
        setDepositInfo(depositInfoData);
        const commentInfo = result.ticket.Comments.map((data, key) => (
          <li key={key}>
            <b>
              {DateHelper.formatDate(data.EnteredDateTime)}
              {` - `}
            </b>
            {data.Comment}
          </li>
        ));
        setCommentInfo(commentInfo);
      }

      if (!isEmpty(levelResult.level)) {
        setLevelInfo(levelResult.level);
      }
      if (!isEmpty(levelResult.levelCorporate)) {
        setLevelCorporate(levelResult.levelCorporate);
      }
    } catch (error) {
      toast.warn(context.t("La información no es accesible."), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoadingInfoByID(false);
  };

  static getProducts = async ({ setProductList, context }) => {
    try {
      const res = await apex.connection.GetProducts({
        OMSId: 1
      });
      if (!isEmpty(res)) {
        const newRes = res.map(data => {
          return {
            name: data.ProductFullName,
            value: data.ProductId
          };
        });
        setProductList(newRes);
      }
    } catch (error) {
      toast.warn(context.t('failed get products'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  static findTransaction = async ({
    userInfo,
    ticketInfo,
    setEventFindTransaction,
    setTransactions,
    context
  }) => {
    try {
      const accounts = await bankAccounts(userInfo.UserId);

      if (isEmpty(accounts)) {
        throw new Error('No Bank Accounts Set');
      }

      const data = accounts.find(i =>i.accountNumber ===parseInt(JSON.parse(ticketInfo.DepositInfo).accountDestination));

      if (isEmpty(data)) {
        throw new Error(
          'No bank account found for this ticket Account Destination'
        );
      }

      const depositInfo = JSON.parse(ticketInfo.DepositInfo);
      const body = {
        from: moment(new Date(depositInfo.transactionDate.slice(0, 24)))
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        transactionType: 'CREDIT',
        amount: ticketInfo.Amount,
        status: 1,
        path: '/api/v1/bank/transactions'
      };

      const result = await bankTransactions(userInfo.UserId, body);
      if (result.total === 0) {
        toast.warn(context.t("No se encontraron transacciones."), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setEventFindTransaction(true);
      setTransactions(result.data);
    } catch (error) {
      toast.warn(context.t(`${error}`), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  static acceptDepositByAdmin = async ({
    userInfo,
    ticketInfo,
    getTicketByIdInit,
    context
  }) => {
    try {
      await acceptDeposit(userInfo.UserId, {
        accountId: ticketInfo.AccountId,
        requestCode: ticketInfo.RequestCode
      });
      toast.success(context.t('Depósito aprobado exitosamente.'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getTicketByIdInit();
    } catch (error) {
      toast.warn(context.t('Error al procesar la información.'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  static rejectDepositByAdmin = async ({
    userInfo,
    ticketInfo,
    getTicketByIdInit,
    context
  }) => {
    try {
      await rejectDeposit(userInfo.UserId, {
        accountId: ticketInfo.AccountId,
        requestCode: ticketInfo.RequestCode
      });
      toast.success(context.t('Depoósito rechazado exitosamente.'), {
        position: toast.POSITION.TOP_CENTER
      });
      await getTicketByIdInit();
    } catch (error) {
      toast.warn(context.t('Error al procesar la información.'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  static processTransaction = async ({
    ticketInfo,
    userInfo,
    transactionId,
    getTicketByIdInit,
    setEventFindTransaction,
    setTransactions,
    context
  }) => {
    try {
      const claimDetails = {
        deposit: ticketInfo,
        user: userInfo
      };

      const body = {
        id: transactionId,
        reference: ticketInfo.TicketNumber,
        details: new Buffer.from(JSON.stringify(claimDetails)).toString(
          'base64'
        ),
        path: '/api/v1/bank/freezedeposit'
      };

      if (ticketInfo.Status === 'New') {
        await acceptDeposit(userInfo.UserId, {
          accountId: ticketInfo.AccountId,
          requestCode: ticketInfo.RequestCode
        });
        toast.success(context.t('Transacción procesada exitosamente.'), {
          position: toast.POSITION.TOP_CENTER
        });
      }

      await freezeBankTransactions(userInfo.UserId, body);

      setEventFindTransaction(false);
      setTransactions([]);

      toast.success(context.t('Ticket Processed'), {
        position: toast.POSITION.TOP_CENTER
      });

      await getTicketByIdInit();
    } catch (error) {
      toast.warn(context.t('Error al procesar la información.'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  static sendComment = async ({
    userInfo,
    comment,
    ticketInfo,
    getTicketByIdInit,
    context
  }) => {
    try {
      const data = {
        comment: comment,
        ticketCode: ticketInfo.RequestCode,
        ticketId: ticketInfo.TicketNumber
      };
      await submitComment(userInfo.UserId, data);

      toast.success(context.t('Comment sent'), {
        position: toast.POSITION.TOP_CENTER
      });

      await getTicketByIdInit();
    } catch (error) {
      toast.warn(context.t("La información no pudo ser guardada."), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };
}

export default DepositController;
