import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  addLimit,
  getLimits,
  getLogs,
  getUser,
  updateLimit,
} from "../FallServices/DepositLimitsServices";

class DepositLimitsController {
  static getAllLimits = async ({ userInfo, setRows, setLoading }) => {
    try {
      setLoading(true);
      const response = await getLimits(userInfo.UserId);
      if (!isEmpty(response) && response.status === 200) {
        const logsSortByUserLevel = !isEmpty(response.data.success)
          ? response.data.success.sort((a, b) => a.userLevel - b.userLevel)
          : [];
        setRows(logsSortByUserLevel);
        return logsSortByUserLevel;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  static updateLevelLimits = async ({
    userInfo,
    levelLimitId,
    newAmounts,
    checkedData,
    adminUserId,
    oldData,
  }) => {
    try {
      let oldValues = [];
      let newValues = [];
      let columns = [];
      const newData = {active:checkedData.active, ...newAmounts}
      if(!isEmpty(oldData)){
        Object.keys(newData).map((item) => {
          if (oldData[item] !== newData[item]) {
            oldValues.push(oldData[item]);
            newValues.push(newData[item]);
            columns.push(item);
          }
        });
      }else{
        oldValues.push(!checkedData.active);
        newValues.push(checkedData.active);
        columns.push("active");
      }
      const response = await updateLimit(
        userInfo.UserId,
        levelLimitId,
        newAmounts,
        checkedData,
        adminUserId,
        {
          oldValues,
          newValues,
          columns,
        }
      );
      if (!isEmpty(response) && response.status === 200) {
        ComponentsHelper.toast({
          type: "success",
          message: "Umbral actualizado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser actualizada",
      });
      return false;
    }
  };

  static addLevelLimit = async ({
    userInfo,
    newAmounts,
    userLevel,
    setLoadingAddLimit,
  }) => {
    try {
      setLoadingAddLimit(true);
      const response = await addLimit(userInfo.UserId, userLevel, newAmounts);
      if (!isEmpty(response) && response.status === 200) {
        ComponentsHelper.toast({
          type: "success",
          message: "El umbral fue añadido exitosamente",
        });

        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser añadida",
      });
      return false;
    } finally {
      setLoadingAddLimit(false);
    }
  };
  static getUser = async ({ userInfo, id, setUserId }) => {
    try {
      const response = await getUser(userInfo.UserId, id);
      if (!isEmpty(response) && response.status === 200) {
        setUserId(response.data.id);
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  };
  static getLogs = async ({userInfo,id,setLogs}) => {
    try {
      const response = await getLogs(userInfo.UserId,id)
      if (!isEmpty(response.data)){
        setLogs(response.data.result)
      }
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  }
}

export default DepositLimitsController;
