import React, { useState } from 'react'
import ComponentsHelper from '../../../../helpers/Components.helper';
import SubHeaderPage from '../../HeaderPage/SubHeaderPage';
import AddIcon from "../../../../images/add-icon.svg";
import TableContainer from '../../Table/TableContainer';
import CreateModuleModal from '../Modals/Module/CreateModuleModal';
import { useModal } from '../../../../hooks/useModal';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import UpdateModuleModal from '../Modals/Module/UpdateModuleModal';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateHelper from '../../../../helpers/Date.helper';

const ModuleDetails = (props) => {

    const { categoryInfo, handleCreateModuleByCategoryId, handleUpdateModuleByCategoryId, handleDeleteModuleById, categoryOptions, context } = props;
    
    const [moduleInfo, setModuleInfo] = useState({})
    const { showModal, handleToggleModal } = useModal();
    const { showModal: showModal2, handleToggleModal: handleToggleModal2 } = useModal();
    const { showModal: showModal3, handleToggleModal: handleToggleModal3 } = useModal();

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: '',
            title: 'Fecha de creación',
            cell: ({ data }) => DateHelper.formatDate(data.createdAt),
        },
        {
            tag: 'name',
            title: 'Nombre de módulo'
        },
        {
            tag: '',
            title: 'Acciones',
            cell: ({ data }) => <div className='content-actions'>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 200, hide: 200 }}
                    overlay={<Tooltip id={`${data.id}-edit`}>Editar</Tooltip>}>
                    <FontAwesomeIcon icon={faPen} className="" onClick={() => openModal(handleToggleModal3, data)} />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 200, hide: 200 }}
                    overlay={<Tooltip id={`${data.id}-delete`}>Eliminar</Tooltip>}>
                    <FontAwesomeIcon icon={faTrash} className="" onClick={() => openModal(handleToggleModal2, data)} />
                </OverlayTrigger>
            </div>
        }
    ]

    const headerOptions = [
        {
            icon: AddIcon,
            action: handleToggleModal
        }
    ];

    const openModal = (toggleModal, data) => {
        toggleModal();
        setModuleInfo(data);
    };

    return (
        <React.Fragment>
            <div>
                <SubHeaderPage
                    title="Lista de módulos"
                    headerOptions={headerOptions}
                />
                <TableContainer
                    headers={tableHeaders}
                    rows={categoryInfo.modulesList}
                    enablePagination={false}
                    rowIdSelected={''}
                />
            </div>
            <CreateModuleModal
                showModal={showModal}
                handleToggleModal={handleToggleModal}
                handleCreateModuleByCategoryId={handleCreateModuleByCategoryId}
                context={context}
            />
            <UpdateModuleModal
                showModal={showModal3}
                handleToggleModal={handleToggleModal3}
                handleUpdateModuleByCategoryId={handleUpdateModuleByCategoryId}
                moduleInfo={moduleInfo}
                categoryOptions={categoryOptions}
                id={categoryInfo.id}
                context={context}
            />
            <ConfirmModal
                showModal={showModal2}
                handleToggleModal={handleToggleModal2}
                title='Eliminar módulo'
                message='¿Está seguro que desea eliminar este módulo?'
                confirmButtonProps={{
                    text: "Si",
                    onClick: () => handleDeleteModuleById(moduleInfo.id)
                }}
                cancelButtonProps={{
                    text: "No"
                }}
            />
        </React.Fragment>
    )
}

export default ModuleDetails