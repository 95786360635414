import React, { useState } from 'react'
import Table from '../../Table/Table';
import { TabsContainer } from '../../Tabs/TabsContainer';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

const AlphapointAccountDetails = ({ accountDataById, decimalPlaces }) => {
    const { accountInfo = {}, positions = [], volume = [] } = accountDataById;
    
    const [tabSelect, setTabSelect] = useState('Balance');

    const tableHeadersBalances = [
        {
            tag: 'ProductSymbol',
            title: 'Producto',
        },
        {
            tag: '',
            title: 'Monto',
            cell: ({ data }) => formatNumberToLocale(data.Amount, decimalPlaces[data.ProductSymbol])
        },
        {
            tag: '',
            title: 'Monto anterior',
            cell: ({ data }) => formatNumberToLocale(data.Hold, decimalPlaces[data.ProductSymbol])
        },
        {
            tag: '',
            title: 'Depositos pendientes',
            cell: ({ data }) => formatNumberToLocale(data.PendingDeposits, decimalPlaces[data.ProductSymbol])
        },
        {
            tag: '',
            title: 'Retiros pendientes',
            cell: ({ data }) => formatNumberToLocale(data.PendingWithdraws, decimalPlaces[data.ProductSymbol])
        }
    ]

    const tableHeadersTrading = [
        {
            tag: 'InstrumentSymbol',
            title: 'Instrumento',
        },
        {
            tag: '',
            title: 'Volumen diario',
            cell: ({ data }) =>  formatNumberToLocale(data.DailyNotionalTradeVolume, 2)
        },
        {
            tag: '',
            title: 'Volumen mensual',
            cell: ({ data }) =>  formatNumberToLocale(data.MonthlyNotionalTradeVolume, 2)
        },
        {
            tag: '',
            title: 'Volumen anual',
            cell: ({ data }) =>  formatNumberToLocale(data.YearlyNotionalTradeVolume, 2)
        },
        {
            tag: '',
            title: 'Volumen comercial continuo',
            cell: ({ data }) =>  formatNumberToLocale(data.RollingMonthlyStartDate, 2)
        }
    ]

    const tabsOptions = [
        {
            title: 'Balance',
            component: <TabSection tableHeaders={tableHeadersBalances} dataList={positions} />,
            default: true
        },
        {
            title: 'Volumen de trade',
            component: <TabSection tableHeaders={tableHeadersTrading} dataList={volume} />,
            default: false
        },
    ];
    return (
        <div className='right-resizable-user-info'>
            <HeaderSection accountInfo={accountInfo} />
            <div className="container-tabs">
                <TabsContainer
                    tabsOptions={tabsOptions}
                    tabSelect={tabSelect}
                    setTabSelect={setTabSelect}
                />
            </div>
        </div>
    )
}

export default AlphapointAccountDetails

const HeaderSection = ({ accountInfo }) => {
    const { AccountName, AccountId, AccountType, RiskType, VerificationLevel } = accountInfo;
    return (
        <div className='header-section-legal'>
            <h2>{AccountName}</h2>
            <div className="info">
                <div>
                    <div className="option">
                        <p>ID de cuenta:</p>
                        <p>{AccountId}</p>
                    </div>
                    <div className="option">
                        <p>Tipo de cuenta:</p>
                        <p>{AccountType}</p>
                    </div>
                </div>
                <div>
                    <div className="option">
                        <p>Tipo de riesgo:</p>
                        <p>{RiskType}</p>
                    </div>
                    <div className="option">
                        <p>Nivel de verificación:</p>
                        <p>{VerificationLevel}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TabSection = ({ tableHeaders, dataList }) => {
    return (
        <div style={{ position: 'relative' }}>
            <div className="table-wrapper">
                <Table rowHead={tableHeaders} dataBody={dataList} rowIdSelected='' />
            </div>
        </div>
    )
}