import isEmpty from 'is-empty'
import React from 'react'

export const InternationalWithoutIntermediaryBankTemplate = ({ transferType, withdrawTemplate, context }) => {
    return (
        <div className='slot-details'>
            <div className='option'>
                <p>
                    <b>{context.t('Transfer Type')}:</b>
                </p>
                <p>
                    {!isEmpty(transferType) ? transferType.name : ''}
                </p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Country Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankCountrySource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Name Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankNameSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Account Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.accountSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Address Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankAddressSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Swift / BIC Code')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.swiftBicCode}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryName}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Address')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryAddress}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Country Name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryCountryName}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary City')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryCity}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Zip')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryZip}</p>
            </div>
        </div>
    )
}
