import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import AddIcon from "../../../images/add-icon.svg";
import { Loader } from "../Loader/Loader";
import isEmpty from "is-empty";
import "./allProfileData.css";

const AllProfilesData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    selectedId,
    reloadData,
    handleToggleAddModal,
    tableHeaders,
  } = props;

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleToggleAddModal,
    },
    {
      icon: RotateRight,
      action: reloadData,
    },
  ];

  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Perfiles")}
        subTitle="Configuraciones / Perfiles"
        headerOptions={headerOptions}
      />
      {!loading ? (
        !isEmpty(tableHeaders) ? (
          <TableContainer
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            headers={tableHeaders}
            rowIdSelected={selectedId}
            enablePagination={false}
            customClassname="fixed-first-column"
          />
        ) : (
          <p className="empty-text">No se encontraron módulos :(</p>
        )
      ) : (
        <div style={{ position: "relative" }}>
          <Loader message="Cargando permisos" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllProfilesData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllProfilesData);
