import isEmpty from "is-empty";
import moment from "moment";
import DateHelper from "../../../helpers/Date.helper";
import UtilsHelper from "../../../helpers/Utils.helper";

export const generateRowsCsv = (rows) => {
    const documentOperation = ["01 FACTURA", "02 RECIBO POR HONORARIOS", "03 BOLETA DE VENTA", "07 NOTA DE CREDITO", "12 TICKET", "13 DOCUMENTO BANCO", "14 RECIBO SERV PUBLICOS", "TR TRANSFERNCIA"];
    const documentType = ['7 PASAPORTE', '6 RUC', '4 CE', '1 DNI'];
    return !isEmpty(rows) ? rows.map(r => {
        if (!isEmpty(r.id) && !isEmpty(r.payload) && r.transactionAmount != 0) {
          if (!isEmpty(r.transactionDate)) {
            let detailsJson = '';
            let conceptoRow = '';
            let beneficiarioRow = '';
            let numeroDocumentoRow = '';
            let cargoRow = 0;
            let abonoRow = 0;
            let fechaDocumento='';
            let fechaVencimiento='';
            let numeroComprobante='';
            let nombreApellido = '';
            let numeroDocumento = '';
            let tipoDocumentoIdentidad = '';
            let tipoDocumentoOperacion = '';
            let extraData = '';
            
            if (!isEmpty(r.accountDetails)) {
              extraData = `- ${r.accountDetails.lastName} ${
                r.accountDetails.secondLastName
              }, ${r.accountDetails.firstName} ${
                r.accountDetails.middleName
              }`.toUpperCase();
            }
    
            if (!isEmpty(r.claimDetails)) {
              let detailsBase64 = r.claimDetails;
              let detailsJsonString = new Buffer.from(
                detailsBase64,
                'base64'
              ).toString('ascii');
              detailsJsonString = detailsJsonString.replace(/[^\x20-\x7E]+/g, '');
    
              try {
                detailsJson = JSON.parse(detailsJsonString);
              } catch (error) {
              }
            }
    
            if (!isEmpty(detailsJson) && !isEmpty(detailsJson.deposit)) {
              conceptoRow = ``;
              beneficiarioRow = ``;
              numeroDocumentoRow = `Deposit Ticket ${
                detailsJson.deposit.TicketNumber
              }`;
              nombreApellido = detailsJson.deposit.name || '';
              numeroDocumento = detailsJson.deposit.identification_document || '';
              tipoDocumentoIdentidad = documentType.includes(detailsJson.deposit.identification_document_type || '') ? detailsJson.deposit.identification_document_type.split(' ')[0] : '';
              tipoDocumentoOperacion =  documentOperation.includes(detailsJson.deposit.document_operation || '') ? detailsJson.deposit.document_operation.split(' ')[0] : '';
              extraData = '';
            }
    
            if (!isEmpty(detailsJson) && !isEmpty(detailsJson.withdrawal)) {
              conceptoRow = ``;
              beneficiarioRow = ``;
              numeroDocumentoRow = `Withdrawal Ticket ${
                detailsJson.withdrawal.TicketNumber
              }`;
              nombreApellido = detailsJson.withdrawal.name || '';
              numeroDocumento = detailsJson.withdrawal.identification_document || '';
              tipoDocumentoIdentidad = documentType.includes(detailsJson.withdrawal.identification_document_type || '') ? detailsJson.withdrawal.identification_document_type.split(' ')[0] : '';
              tipoDocumentoOperacion = documentOperation.includes(detailsJson.withdrawal.document_operation || '') ? detailsJson.withdrawal.document_operation.split(' ')[0] : '';
              extraData = '';
            }
    
            if (!isEmpty(detailsJson) && !isEmpty(detailsJson.admin)) {
              if (
                detailsJson.admin.type == 'FEE' ||
                detailsJson.admin.type == 'IMPUESTO'
              ) {
                conceptoRow = `${r.payload}`;
                beneficiarioRow = ``;
                numeroDocumentoRow = ``;
              }
    
              if (detailsJson.admin.type == 'ADMIN') {
                const category = detailsJson.admin.category;
                if (category === 'DEPOSIT' || category === 'WITHDRAW') {
                  conceptoRow = '';
                  beneficiarioRow = '';
                  numeroDocumentoRow = `${UtilsHelper.firtsLetterInUpperCase(category)} Ticket ${
                    detailsJson.admin.payment_number
                  }`;
                } else {
                  conceptoRow = `${detailsJson.admin.category} - ${r.payload}`;
                  beneficiarioRow = `${detailsJson.admin.message}`;
                  numeroDocumentoRow = '';
                  numeroComprobante = detailsJson.admin.payment_number || '';
                }
              }
              fechaDocumento = detailsJson.admin.document_date || '';
              fechaVencimiento = detailsJson.admin.due_date || '';
              nombreApellido = detailsJson.admin.name || '';
              numeroDocumento = detailsJson.admin.identification_document || '';
              tipoDocumentoIdentidad = documentType.includes(detailsJson.admin.identification_document_type || '') ? detailsJson.admin.identification_document_type.split(' ')[0] : '';
              tipoDocumentoOperacion = documentOperation.includes(detailsJson.admin.document_operation || '') ? detailsJson.admin.document_operation.split(' ')[0] : '';
            }
    
            if (r.transactionType == 'DEBIT') {
              cargoRow =
                r.transactionAmount < 0
                  ? r.transactionAmount * -1
                  : r.transactionAmount;
            }
    
            if (r.transactionType == 'CREDIT') {
              abonoRow =
                r.transactionAmount < 0
                  ? r.transactionAmount * -1
                  : r.transactionAmount;
            }
    
            if (!isEmpty(beneficiarioRow) && !isEmpty(conceptoRow)) beneficiarioRow = `- ${beneficiarioRow}`;
            if (!isEmpty(numeroDocumentoRow) && (!isEmpty(conceptoRow) || !isEmpty(beneficiarioRow)))
              numeroDocumentoRow = `- ${numeroDocumentoRow}`;
    
            return {
              fechaOperacion: moment(r.transactionDate).format('DD/MM'),
              numeroOperacion: r.transactionReference,
              concepto: `${conceptoRow} ${beneficiarioRow} ${numeroDocumentoRow} ${extraData}`,
              cargo: cargoRow,
              abono: abonoRow,
              fechaDocumento: DateHelper.formatDateToYear(!isEmpty(fechaDocumento) ? fechaDocumento : r.transactionDate),
              fechaVencimiento: DateHelper.formatDateToYear(!isEmpty(fechaVencimiento) ? fechaVencimiento : r.transactionDate),
              numeroComprobante,
              numeroDocumento,
              nombreApellido,
              tipoDocumentoOperacion,
              tipoDocumentoIdentidad
            }
          }
        }
      }).reverse() : [];
}