import React from "react";
import DateHelper from "../../../helpers/Date.helper";

function AcuantLogsDetails({ data }) {
  return (
    <div className="resizable-details-container deposit-limits-details">
      <div className="header-section">
        <h2>Detalles de log del usuario {data.banexcoinUsername}</h2>
        <div>
          <p
            className={`color-status-${
              data.status === "PROCESSED" ? "resolved" : "failed"
            }`}
          >
            {data.status === "PROCESSED" ? "Procesado" : "Fallido"}
          </p>
        </div>
      </div>
      <div className="p-4">
        <div className="details-option">
          <p>Fecha de creación</p>
          <p> {DateHelper.formatDate(data.createdAt)}</p>
        </div>
        <br />
        <div className="details-option">
          <p>Fecha de actualización</p>
          <p>{DateHelper.formatDate(data.updatedAt)}</p>
        </div>
        <div className="details-option">
          <p>Creado por</p>
          <p>{data.executeByName}</p>
        </div>
        <br/>
        <h3>Detalle de usuario analizado</h3>
        <div className="details-option">
          <p>Account ID</p>
          <p>{data.accountId}</p>
        </div>
        <div className="details-option">
          <p>Nombre de usuario</p>
          <p>{data.banexcoinUsername}</p>
        </div>
        <br />
        <div className="details-option">
          <p>Fecha de actualización</p>
          <p>{data.email}</p>
        </div>
        <br/>
        <h3>Detalle de proveedor</h3>
        <div className="details-option">
          <p>Acuant ID</p>
          <p>{data.acuantId}</p>
        </div>
        <div className="details-option">
          <p>Ejecutado por</p>
          <p>{data.acuantLogExecuteByName}</p>
        </div>
      </div>
    </div>
  );
}

export default AcuantLogsDetails;
