import React, { useEffect, useState } from 'react';
import empty from 'is-empty';
import PropTypes from 'prop-types';
import { createBannerPopup, updateBannerPopup } from './NewsBannerHooks';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import EditorConvertToHTML from './DraftWysiwyg';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Col,
  Row,
  Badge,
  CustomInput,
  Form,
  FormFeedback
} from 'reactstrap';
import { CustomButton } from '../../components/FallComponents/CustomButton/CustomButton';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { useModal } from '../../hooks/useModal';
import { eventTypes } from '../../constants';

const ModalForm = (props, context) => {
  let {
    showModal,
    modalType,
    closeModal,
    userInfo,
    getAllIncomingMessages,
    setLoading,
    loading,
    setInputs,
    inputs,
    selectedNotification,
    handleDeleteNotification
  } = props;
  const { showModal:showModalConfirmDelete, handleToggleModal:handleToggleModalDelete } = useModal();
  const [errors, setErrors] = useState({});
  const [fetchError,setFetchError] = useState(false)
  const [imagePreview,setImagePreview] = useState({
    es:null,
    en:null
  })

  const onInputsChange = e => {
    e.persist();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const onTextareaChange = (val, name) => {
    setInputs({
      ...inputs,
      [name]: val
    });
  };

  useEffect(
    () => {
      if (inputs.type === 'carousel') {
        inputs.eventType = 'private';
      }
    },
    [inputs]
  );

  const onSwitchChange = e => {
    e.persist();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.checked
    });
  };

  const onInputFileChange = e => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.files[0]
    });
  };
  const customValidations = inputs => {
    let inputsErrors = {};
    if (empty(inputs.startAt)) {
      inputsErrors.startAt = 'Debes llenar este campo';
    }
    if (empty(inputs.endAt)) {
      inputsErrors.endAt = 'Debes llenar este campo';
    }
    if (empty(inputs.type)) {
      inputsErrors.type = 'Debes llenar este campo';
    }
    if (empty(inputs.eventType)) {
      inputsErrors.eventType = 'Debes llenar este campo';
    }
    if (empty(inputs.eventTitleES)) {
      inputsErrors.eventTitleES = 'Debes llenar este campo';
    }
    if (empty(inputs.eventTitleEN)) {
      inputsErrors.eventTitleEN = 'Debes llenar este campo';
    }
    if (
      inputs.type === 'headband' &&
      modalType == 'Crear' &&
      empty(inputs.eventTextES)
    ) {
      inputsErrors.eventTextES = 'Debes llenar este campo';
    }
    if (
      inputs.type === 'headband' &&
      modalType == 'Crear' &&
      empty(inputs.eventTextEN)
    ) {
      inputsErrors.eventTextEN = 'Debes llenar este campo';
    }
    if (
      inputs.type === 'headband_app' &&
      modalType == 'Crear' &&
      empty(inputs.eventTextOnlyES)
    ) {
      inputsErrors.eventTextOnlyES = 'Debes llenar este campo';
    }
    if (
      inputs.type === 'headband_app' &&
      modalType == 'Crear' &&
      empty(inputs.eventTextOnlyEN)
    ) {
      inputsErrors.eventTextOnlyEN = 'Debes llenar este campo';
    }
    if (
      inputs.type === 'popup' &&
      modalType == 'Crear' &&
      empty(inputs.eventImagesES)
    ) {
      inputsErrors.eventImagesES = 'Debes llenar este campo';
    }
    if (
      inputs.type === 'popup' &&
      modalType == 'Crear' &&
      empty(inputs.eventImagesEN)
    ) {
      inputsErrors.eventImagesEN = 'Debes llenar este campo';
    }
    setErrors(inputsErrors);
    return inputsErrors;
  };
  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    let inputsTemp = {
      ...inputs,
      validated: true
    };
    setInputs(inputsTemp);
    let errorsCustom = customValidations(inputsTemp);
    if (empty(errorsCustom)) {
      callBackSubmit(inputsTemp);
    }
  };
  const callBackSubmit = async inputs => {
    setLoading(true);
    try {
      if (modalType == 'edit') {
        let updateEvent = await updateBannerPopup({
          ...inputs,
          type: eventTypes[inputs.type] || inputs.type
        }, userInfo.UserId);
        if (updateEvent.data.messages === 'success') {
          setImagePreview({es:null, en:null})
          setFetchError(false)
          setLoading(true);
          closeModal();
          toast.success('Información actualizada exitosamente', {
            position: toast.POSITION.TOP_CENTER
          });
          getAllIncomingMessages();
        }
      } else {
        let createEvent = await createBannerPopup(inputs, userInfo.UserId);
        if (createEvent.data.messages === 'success') {
          setImagePreview({es:null, en:null})
          setFetchError(false)
          setLoading(true);
          closeModal();
          toast.success('Información  creada exitosamente', {
            position: toast.POSITION.TOP_CENTER
          });
          getAllIncomingMessages();
        }
      }
    } catch (error) {
      setFetchError(true)
    }
    setLoading(false);
  };

  const handlePreviewImage = (e, language) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
      setImagePreview({...imagePreview,[language]:reader.result})
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleDeletePreview = (language,name) => {
    setImagePreview({...imagePreview,[language]:null})
    setInputs({...inputs,[name]:(selectedNotification && selectedNotification.eventImages && selectedNotification.eventImages[language] || null)})
  }

  const handleDelete = async () => {
    const response = await handleDeleteNotification()
    if(response){
      closeModal()
      getAllIncomingMessages()
    }
  }
  return (
    <Modal isOpen={showModal} size="lg" modalClassName="modal-large" style={{maxWidth:"1000px"}}>
      <Form noValidate validated={inputs.validated} onSubmit={onSubmit}>
        <div className="btn-close-right">
          <button
            type="button"
            className="close-btn"
            aria-label="Close"
            disabled={loading}
            onClick={() => closeModal()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16">
              <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
            </svg>
          </button>
        </div>

        <ModalHeader>
          <div>
            <h3>{modalType == 'edit' ? 'Editar' : 'Crear'} Evento</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <label>Inicio:</label>
            <Input
              className="form-control"
              name="startAt"
              type="datetime-local"
              onChange={onInputsChange}
              defaultValue={
                modalType == 'edit'
                  ? moment(inputs.startAt)
                      .tz('America/Lima')
                      .format('YYYY-MM-DDTHH:mm')
                  : inputs.startAt
              }
              invalid={
                !empty(errors.startAt) && inputs.validated ? true : false
              }
            />
            <FormFeedback tooltip>{errors.startAt}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label>Fin:</label>
            <Input
              className="form-control"
              name="endAt"
              type="datetime-local"
              onChange={onInputsChange}
              defaultValue={
                modalType == 'edit'
                  ? moment(inputs.endAt)
                      .tz('America/Lima')
                      .format('YYYY-MM-DDTHH:mm')
                  : inputs.endAt
              }
              invalid={!empty(errors.endAt) && inputs.validated ? true : false}
            />
            <FormFeedback tooltip>{errors.endAt}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label>Tipo Evento:</label>
            <Input
              className="form-control"
              name="type"
              type="select"
              defaultValue={eventTypes[inputs.type]}
              onChange={onInputsChange}
              invalid={!empty(errors.type) && inputs.validated ? true : false}>
              <option value="">Seleccionar</option>
              <option value="carousel">CARRUSEL</option>
              <option value="popup">BANNER</option>
              <option value="headband">CINTILLO</option>
              <option value="popup_app">BANNER APP</option>
              <option value="headband_app">CINTILLO APP</option>
            </Input>
            <FormFeedback tooltip>{errors.type}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label>Mostrar Como:</label>
            <Input
              className="form-control"
              name="eventType"
              type="select"
              defaultValue={inputs.eventType}
              onChange={onInputsChange}
              invalid={
                !empty(errors.eventType) && inputs.validated ? true : false
              }>
              <option value="">Seleccionar</option>
              <option
                value="public"
                disabled={inputs.type === 'carousel' ? true : false}>
                PUBLICO
              </option>
              <option
                value="private"
                selected={inputs.type === 'carousel' ? 'selected' : ''}>
                PRIVADO
              </option>
              <option
                value="both"
                disabled={inputs.type === 'carousel' ? true : false}>
                AMBOS
              </option>
            </Input>
            <FormFeedback tooltip>{errors.eventType}</FormFeedback>
          </FormGroup>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <label>Titulo</label>
                <br />
                <Badge color="secondary">Español</Badge>
                <Input
                  className="form-control"
                  name="eventTitleES"
                  type="text"
                  defaultValue={inputs.eventTitleES}
                  onChange={onInputsChange}
                  invalid={
                    !empty(errors.eventTitleES) && inputs.validated
                      ? true
                      : false
                  }
                />
                <FormFeedback tooltip>{errors.eventTitleES}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <br />
              <FormGroup>
                <Badge color="danger">Inglés</Badge>
                <Input
                  className="form-control"
                  name="eventTitleEN"
                  type="text"
                  defaultValue={inputs.eventTitleEN}
                  onChange={onInputsChange}
                  invalid={
                    !empty(errors.eventTitleEN) && inputs.validated
                      ? true
                      : false
                  }
                />
                <FormFeedback tooltip>{errors.eventTitleEN}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {inputs.type === 'headband' || (inputs.type && inputs.type.includes("CINTILLO")) ? (
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <label>Descripción</label>
                  <br />
                  <Badge color="secondary">Español</Badge>
                  <EditorConvertToHTML
                    name="eventTextES"
                    onChange={val => {
                      onTextareaChange(val, 'eventTextES');
                    }}
                    defaultValue={inputs.eventTextES || ''}
                    invalid={
                      !empty(errors.eventTextES) && inputs.validated
                        ? true
                        : false
                    }
                  />
                  <FormFeedback
                    tooltip
                    style={{
                      display:
                        !empty(errors) &&
                        !empty(errors.eventTextES) &&
                        inputs.validated
                          ? 'block'
                          : 'none'
                    }}>
                    {errors.eventTextES}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <br />
                <FormGroup>
                  <Badge color="danger">Inglés</Badge>
                  <EditorConvertToHTML
                    name="eventTextEN"
                    onChange={val => {
                      onTextareaChange(val, 'eventTextEN');
                    }}
                    defaultValue={inputs.eventTextEN || ''}
                    invalid={
                      !empty(errors.eventTextEN) && inputs.validated
                        ? true
                        : false
                    }
                  />
                  <FormFeedback
                    tooltip
                    style={{
                      display:
                        !empty(errors) &&
                        !empty(errors.eventTextEN) &&
                        inputs.validated
                          ? 'block'
                          : 'none'
                    }}>
                    {errors.eventTextEN}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          ) : (inputs.type === 'headband_app' || (inputs.type && inputs.type.includes("CINTILLO_APP"))) ? (
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <label>Descripción</label>
                  <br />
                  <Badge color="secondary">Español</Badge>
                  <Input
                    className="form-control"
                    name="eventTextOnlyES"
                    type="textarea"
                    maxLength={200}
                    defaultValue={inputs.eventTextOnlyES || ''}
                    onChange={onInputsChange}
                    invalid={
                      !empty(errors.eventTextOnlyES) && inputs.validated
                        ? true
                        : false
                    }
                  />
                  <p style={{"marginTop":"1rem"}}>Longitud máxima: 200 caracteres</p>
                  <FormFeedback tooltip>{errors.eventTextOnlyES}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <br />
                <FormGroup>
                  <Badge color="danger">Inglés</Badge>
                  <Input
                    className="form-control"
                    name="eventTextOnlyEN"
                    type="textarea"
                    maxLength={200}
                    defaultValue={inputs.eventTextOnlyEN || ''}
                    onChange={onInputsChange}
                    invalid={!empty(errors.eventTextOnlyEN) && inputs.validated}
                  />
                  <p style={{"marginTop":"1rem"}}>Longitud máxima: 200 caracteres</p>
                  <FormFeedback tooltip>{errors.eventTextOnlyEN}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ''
            )}
          {inputs.type == 'popup_app' && (
            <div>
              <Alert variant={'warning'}>
                <h3>{'Recuerde subir una imágen adaptable para moviles'}</h3>
              </Alert>
            </div>
          )}
          {inputs.type === 'popup' ||
          inputs.type === 'popup_app' ||
          inputs.type === 'carousel' ? (
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <label>Imagen</label>
                  <br />
                  <Badge color="secondary">Español</Badge>
                  <CustomInput
                    type="file"
                    name="eventImagesES"
                    label="Adjunta una imágen en Español"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      onInputFileChange(e)
                      handlePreviewImage(e,"es")
                    }}
                    invalid={!empty(errors.eventImagesES) && inputs.validated}
                    />
                  <FormFeedback tooltip>{errors.eventImagesES}</FormFeedback>
                  <div className='flex items-center' style={{margin:"1rem"}}>
                    <img src={inputs &&  inputs.eventImages && inputs.eventImages.es} alt="" width="150px" />
                    {imagePreview.es && 
                    <div style={{margin:"1rem"}}>
                      <p>Nueva imagen</p>
                      <img src={imagePreview.es} alt="" width="150px" style={{margin:"1rem"}}/>
                      <p className='delete-image-modal-form' onClick={() => handleDeletePreview("es","eventImagesES")}>Eliminar imagen</p>
                    </div>
                    }
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <br />
                <FormGroup>
                  <Badge color="danger">Inglés</Badge>
                  <CustomInput
                    type="file"
                    name="eventImagesEN"
                    label="Adjunta una imágen en Inglés"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      onInputFileChange(e)
                      handlePreviewImage(e,"en")
                    }}
                    invalid={!empty(errors.eventImagesEN) && inputs.validated}
                  />
                  <FormFeedback tooltip>{errors.eventImagesEN}</FormFeedback>
                  <div className='flex items-center' style={{margin:"1rem"}}>
                    <img src={inputs &&  inputs.eventImages && inputs.eventImages.en} alt="" width="150px" style={{margin:"1rem"}}/>
                  {imagePreview.en && 
                  <div style={{margin:"1rem"}}>
                    <p>Nueva imagen</p>
                    <img src={imagePreview.en} alt="" width="150px" style={{margin:"1rem"}}/>
                    <p className='delete-image-modal-form' onClick={() => handleDeletePreview("en","eventImagesEN")}>Eliminar imagen</p>
                    </div>}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {inputs.type === 'carousel' ? (
          <Row form>
            <FormGroup>
            <div>
              <label>Agregar Enlace?</label>
              <div className="switch-field">
                <CustomInput
                  className="switch-large"
                  type="switch"
                  id="statusLink"
                  name="statusLink"
                  checked={inputs.statusLink}
                  label={inputs.statusLink ? 'Si' : 'No'}
                  onChange={onSwitchChange}
                />
              </div>
            </div>
          </FormGroup>
          </Row>
          ): ''
          }
          {inputs.statusLink ? (
          <Row form>
              <Col md={6}>
                <FormGroup>
                  <label>Link Externo</label>
                  <br />
                  <Badge color="secondary">Español</Badge>
                  <Input
                    className="form-control"
                    name="eventExternalLinkES"
                    type="text"
                    defaultValue={inputs.eventExternalLinkES}
                    onChange={onInputsChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <br />
                <FormGroup>
                  <Badge color="danger">Inglés</Badge>
                  <Input
                    className="form-control"
                    name="eventExternalLinkEN"
                    type="text"
                    defaultValue={inputs.eventExternalLinkEN}
                    onChange={onInputsChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          ): ''
          } 
          <FormGroup>
            <div>
              <label>Activar Noticia?</label>
              <div className="switch-field">
                <CustomInput
                  className="switch-large"
                  type="switch"
                  id="status"
                  name="status"
                  checked={inputs.status}
                  label={inputs.status === true ? 'Activo' : 'Inactivo'}
                  onChange={onSwitchChange}
                />
              </div>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {fetchError && <p className='error-message'>Ocurrio un error al intentar guardar. Revise los campos ingresados.</p>}
          <div className='flex items-center w-100' style={{justifyContent:"space-between"}}>
          {localStorage.getItem("isBeta") === "true" || modalType !== "add" && <CustomButton type="button" className="red" text="Borrar notificación" onClick={handleToggleModalDelete}/>}
            <div className='flex items-center' style={{gap:"1rem"}}>
              <CustomButton type="submit" disabled={loading} className="green" text={modalType === 'edit' ? 'Editar' : 'Crear'}/>
              <CustomButton type="button" onClick={closeModal} disabled={loading} showLoader={false} className="red" text='Cancelar'/>
            </div>
          </div>
        </ModalFooter>
      </Form>
      <ConfirmModal
        showModal={showModalConfirmDelete}
        handleToggleModal={() => {
          handleToggleModalDelete();
        }}
        title="Eliminar notificación"
        message={`¿Desea eliminar esta notificación?`}
        confirmButtonProps={{
          onClick: handleDelete,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(
  mapStateToProps,
  null
)(ModalForm);
