import React, { useEffect, useState } from "react";
import DateHelper from "../../../helpers/Date.helper";
import { EditableText } from "../EditableText/EditableText";
import editIcon from "../../../images/new_icons/edit.svg";
import checkIcon from "../../../images/new_icons/check.svg";
import crossIcon from "../../../images/new_icons/cross.svg";
import "./autoApprovalDetails.css";
import { CustomButton } from "../CustomButton/CustomButton";
import { products } from "../../../constants";

function AutoApprovalDetails({
  data,
  isEditable,
  updateDetails,
  handleIsEditable,
  loadingDelete,
  deleteAutoApprovalData
}) {
  const [inputs, setInputs] = useState({
    productSymbol: data.productSymbol,
  });

  useEffect(() => {
    setInputs({ productSymbol: data.productSymbol });
  }, [data]);

  const handleChangeDetails = (target) => {
    setInputs({
      ...inputs,
      [target.name]: target.value,
    });
  };

  const handleResetValues = () => {
    setInputs({
      productSymbol: data.productSymbol,
    });
    handleIsEditable();
  };

  const handleCloseEdition = () => {
    handleIsEditable();
    if (isEditable) {
      updateDetails(data.id, inputs, () =>
        setInputs({
          productSymbol: data.productSymbol,
        })
      );
    }
  };

  const productSymbolInputProps = {
    editable: isEditable,
    type: "select",
    option: "Producto",
    name: "productSymbol",
    value: inputs.productSymbol,
    onChange: handleChangeDetails,
    rootClassname: "input-element-aml-alert",
    options: products.slice(-3),
  };

  const enableWithdrawText = data.enableWithdrawApproval
    ? "Activo"
    : "Desactivado";
    
  const enableDepositText = data.enableDepositApproval
    ? "Activo"
    : "Desactivado";

  return (
    <div className="ticket-detail-main auto-approval-details">
     <div>
     <div className="header-section auto-approval-details-header">
        <h2>Autoaprobador para {data.productSymbol}</h2>
        <p>
          Retiro:{" "}
          <span className={`color-status-${enableWithdrawText}`}>
            {enableWithdrawText}
          </span>
        </p>
        <p>
          Depósito:{" "}
          <span className={`color-status-${enableDepositText}`}>
            {enableDepositText}
          </span>
        </p>
      </div>
      <div className="p-4">
        <div className="flex items-center gap-1">
          <h3>Detalle de autoaprobador</h3>
          <img
            onClick={handleCloseEdition}
            src={isEditable ? checkIcon : editIcon}
          />
          {isEditable && <img onClick={handleResetValues} src={crossIcon} />}
        </div>
        <br />
        <div className="details-option">
          <p>Fecha de creación:</p>
          <p> {DateHelper.formatDate(data.createdAt)}</p>
        </div>
        <br />
        <div className="details-option">
          <p>Fecha de actualización:</p>
          <p>{DateHelper.formatDate(data.updatedAt)}</p>
        </div>
        <EditableText {...productSymbolInputProps} />
      </div>
     </div>
      <div className="buttons-container">
        <div className="buttons-left"></div>
        <CustomButton text="Eliminar" className="red" onClick={() => deleteAutoApprovalData(data.id)} disabled={loadingDelete}/>
      </div>
    </div>
  );
}

export default AutoApprovalDetails;
