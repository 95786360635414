export const OcupationsDetails = {
  Housewife: [{ id: 'Housewife', name: 'Housewife' }],
  Unemployed: [
    { id: 'Attorney', name: 'Attorney' },
    { id: 'Actor', name: 'Actor' },
    { id: 'Actress', name: 'Actress' },
    { id: 'Artist', name: 'Artist' },
    { id: 'Show director', name: 'Show director' },
    { id: 'Choreographer', name: 'Choreographer' },
    { id: 'Model', name: 'Model' },
    { id: 'Musician', name: 'Musician' },
    { id: 'Actuary', name: 'Actuary' },
    { id: 'Administrator', name: 'Administrator' },
    { id: 'Customs officer', name: 'Customs officer' },
    { id: 'Customs agent', name: 'Customs agent' },
    { id: 'Border inspectors', name: 'Border inspectors' },
    { id: 'Flight attendant', name: 'Flight attendant' },
    { id: 'Stewardess', name: 'Stewardess' },
    { id: 'Real estate Agent', name: 'Real estate Agent' },
    { id: 'Real estate Intermediary', name: 'Real estate Intermediary' },
    { id: 'Real estate broker', name: 'Real estate broker' },
    { id: 'Broker', name: 'Broker' },
    { id: 'Immigration agent', name: 'Immigration agent' },
    { id: 'Migration', name: 'Migration' },
    { id: 'Tourism agent', name: 'Tourism agent' },
    { id: 'Travel agent', name: 'Travel agent' },
    { id: 'Insurance intermediary', name: 'Insurance intermediary' },
    { id: 'Insurance agent', name: 'Insurance agent' },
    { id: 'Farmer', name: 'Farmer' },
    { id: 'Agronomist', name: 'Agronomist' },
    { id: 'Agrologist', name: 'Agrologist' },
    { id: 'Aggrimesor', name: 'Aggrimesor' },
    { id: 'Arboriculturist', name: 'Arboriculturist' },
    { id: 'Surveyor', name: 'Surveyor' },
    { id: 'Geographer', name: 'Geographer' },
    { id: 'Mason', name: 'Mason' },
    { id: 'Construction worker', name: 'Construction worker' },
    {
      id: 'System and Computer Analysts',
      name: 'System and Computer Analysts'
    },
    { id: 'Anthropologist', name: 'Anthropologist' },
    {
      id: 'Archaeologist and Ethnologist',
      name: 'Archaeologist and Ethnologist'
    },
    { id: 'Archivist', name: 'Archivist' },
    { id: 'Boat builder', name: 'Boat builder' },
    { id: 'Architect', name: 'Architect' },
    { id: 'Craftsman', name: 'Craftsman' },
    { id: 'Social assistant', name: 'Social assistant' },
    { id: 'Literary author', name: 'Literary author' },
    { id: 'Writer and Critical', name: 'Writer and Critical' },
    { id: 'Bacteriologist', name: 'Bacteriologist' },
    { id: 'Pharmacologist', name: 'Pharmacologist' },
    { id: 'Biologist', name: 'Biologist' },
    { id: 'Scientist', name: 'Scientist' },
    { id: 'Poultryman', name: 'Poultryman' },
    { id: 'Waste man', name: 'Waste man' },
    { id: 'Sweeper', name: 'Sweeper' },
    { id: 'Bank Cashier', name: 'Bank Cashier' },
    { id: 'Waiter', name: 'Waiter' },
    { id: 'Barman', name: 'Barman' },
    { id: 'Kitchener', name: 'Kitchener' },
    { id: 'Chef', name: 'Chef' },
    {
      id: 'Exchanger, Purchase / Sale of currency',
      name: 'Exchanger, Purchase / Sale of currency'
    },
    { id: 'Peasant', name: 'Peasant' },
    { id: 'Foreman', name: 'Foreman' },
    { id: 'Handler', name: 'Handler' },
    { id: 'Carpenter', name: 'Carpenter' },
    { id: 'Postman', name: 'Postman' },
    { id: 'Locksmith', name: 'Locksmith' },
    { id: 'Debt collector', name: 'Debt collector' },
    { id: 'Conductor', name: 'Conductor' },
    { id: 'Driver', name: 'Driver' },
    { id: 'Taxi driver', name: 'Taxi driver' },
    { id: 'Concierge', name: 'Concierge' },
    { id: 'Porter', name: 'Porter' },
    { id: 'Guardian', name: 'Guardian' },
    { id: 'Vigilant', name: 'Vigilant' },
    { id: 'Builder', name: 'Builder' },
    { id: 'Counter', name: 'Counter' },
    { id: 'Contractor', name: 'Contractor' },
    {
      id: 'Taylor / Garment manufacturer',
      name: 'Taylor / Garment manufacturer'
    },
    { id: 'Cosmetologist', name: 'Cosmetologist' },
    { id: 'Hairdresser and Barber', name: 'Hairdresser and Barber' },
    { id: 'Decorator', name: 'Decorator' },
    { id: 'Designer', name: 'Designer' },
    { id: 'Publicist', name: 'Publicist' },
    { id: 'Advertising designer', name: 'Advertising designer' },
    { id: 'Dentist', name: 'Dentist' },
    { id: 'Dentistry', name: 'Dentistry' },
    { id: 'Professional athlete', name: 'Professional athlete' },
    { id: 'Athlete', name: 'Athlete' },
    { id: 'Referee', name: 'Referee' },
    { id: 'Sports coach', name: 'Sports coach' },
    { id: 'Distributor', name: 'Distributor' },
    { id: 'Teacher', name: 'Teacher' },
    { id: 'Economist', name: 'Economist' },
    { id: 'Electrical technician', name: 'Electrical technician' },
    { id: 'Exporting businessman', name: 'Exporting businessman' },
    { id: 'Importing businessman', name: 'Importing businessman' },
    { id: 'Nurse', name: 'Nurse' },
    { id: 'Assembler', name: 'Assembler' },
    { id: 'Sculptor', name: 'Sculptor' },
    { id: 'Photographer', name: 'Photographer' },
    { id: 'Operators camera', name: 'Operators camera' },
    { id: 'Film and tv', name: 'Film and tv' },
    { id: 'Radio and tv announcer', name: 'Radio and tv announcer' },
    { id: 'Scriptwriter', name: 'Scriptwriter' },
    { id: 'Rancher', name: 'Rancher' },
    { id: 'Plumber', name: 'Plumber' },
    { id: 'Historian', name: 'Historian' },
    { id: 'Engineer', name: 'Engineer' },
    { id: 'Interpreter, Translator', name: 'Interpreter, Translator' },
    { id: 'Gardener', name: 'Gardener' },
    { id: 'Jockey', name: 'Jockey' },
    { id: 'Jewerler / Silver', name: 'Jewerler / Silver' },
    {
      id: 'Laboratory (technician)',
      name: 'Laboratory (technician)'
    },
    { id: 'Liquidator', name: 'Liquidator' },
    { id: 'Claims', name: 'Claims' },
    { id: 'Insurance', name: 'Insurance' },
    { id: 'Machiner', name: 'Machiner' },
    { id: 'Machinery operator', name: 'Machinery operator' },
    { id: 'Hammer', name: 'Hammer' },
    { id: 'Auctioneer', name: 'Auctioneer' },
    { id: 'Wholesale', name: 'Wholesale' },
    { id: 'Mechanic', name: 'Mechanic' },
    { id: 'Surgeon', name: 'Surgeon' },
    { id: 'Metallurgist', name: 'Metallurgist' },
    { id: 'Nutritionist', name: 'Nutritionist' },
    { id: 'Laborer Operator', name: 'Laborer Operator' },
    { id: 'Obstetrician', name: 'Obstetrician' },
    { id: 'Event planner', name: 'Event planner' },
    { id: 'Baker', name: 'Baker' },
    { id: 'Pastry', name: 'Pastry' },
    { id: 'Paramedic', name: 'Paramedic' },
    { id: 'Journalist', name: 'Journalist' },
    { id: 'Proficient', name: 'Proficient' },
    { id: 'Fisherman', name: 'Fisherman' },
    { id: 'Pilot', name: 'Pilot' },
    { id: 'Painter', name: 'Painter' },
    { id: 'Municipal police', name: 'Municipal police' },
    { id: 'State police', name: 'State police' },
    { id: 'Film Producer', name: 'Film Producer' },
    { id: 'Radio', name: 'Radio' },
    { id: 'TV', name: 'TV' },
    { id: 'Theater', name: 'Theater' },
    { id: 'Producer, extensive crops', name: 'Producer, extensive crops' },
    { id: 'Programmer', name: 'Programmer' },
    { id: 'Psychologist', name: 'Psychologist' },
    { id: 'Therapist', name: 'Therapist' },
    { id: 'Chiropractor', name: 'Chiropractor' },
    {
      id: 'Kinesiterapist (Kinesiologists)',
      name: 'Kinesiterapist (Kinesiologists)'
    },
    {
      id: 'Public and Industrial Relacionist',
      name: 'Public and Industrial Relacionist'
    },
    { id: 'Watchmaker', name: 'Watchmaker' },
    { id: 'Car repair', name: 'Car repair' },
    { id: 'Retoucher painter', name: 'Retoucher painter' },
    { id: 'Appliances repairer', name: 'Appliances repairer' },
    { id: 'Delivery man', name: 'Delivery man' },
    { id: 'Secretary', name: 'Secretary' },
    { id: 'Receptionist', name: 'Receptionist' },
    { id: 'Telephonist', name: 'Telephonist' },
    { id: 'Body guard', name: 'Body guard' },
    { id: 'Security guard', name: 'Security guard' },
    { id: 'Storage / Warehouse service', name: 'Storage / Warehouse service' },
    { id: 'Vehicle rental service', name: 'Vehicle rental service' },
    { id: 'Sociologist', name: 'Sociologist' },
    { id: 'Appraiser', name: 'Appraiser' },
    { id: 'Technical', name: 'Technical' },
    { id: 'Bullfighter', name: 'Bullfighter' },
    { id: 'Processor', name: 'Processor' },
    {
      id: 'Cargo transportation and / or moving',
      name: 'Cargo transportation and / or moving'
    },
    { id: 'Carrier', name: 'Carrier' },
    { id: 'Peddler', name: 'Peddler' },
    { id: 'Veterinarian', name: 'Veterinarian' },
    { id: 'Zoologist', name: 'Zoologist' },
    { id: 'Zootechnical', name: 'Zootechnical' },
    { id: 'Medical visitor', name: 'Medical visitor' },
    { id: 'Shoemaker', name: 'Shoemaker' }
  ],
  Employee: [
    { id: 'Attorney', name: 'Attorney' },
    { id: 'Actor', name: 'Actor' },
    { id: 'Actress', name: 'Actress' },
    { id: 'Artist', name: 'Artist' },
    { id: 'Show director', name: 'Show director' },
    { id: 'Choreographer', name: 'Choreographer' },
    { id: 'Model', name: 'Model' },
    { id: 'Musician', name: 'Musician' },
    { id: 'Actuary', name: 'Actuary' },
    { id: 'Administrator', name: 'Administrator' },
    { id: 'Customs officer', name: 'Customs officer' },
    { id: 'Customs agent', name: 'Customs agent' },
    { id: 'Border inspectors', name: 'Border inspectors' },
    { id: 'Flight attendant', name: 'Flight attendant' },
    { id: 'Stewardess', name: 'Stewardess' },
    { id: 'Real estate Agent', name: 'Real estate Agent' },
    { id: 'Real estate Intermediary', name: 'Real estate Intermediary' },
    { id: 'Real estate broker', name: 'Real estate broker' },
    { id: 'Broker', name: 'Broker' },
    { id: 'Immigration agent', name: 'Immigration agent' },
    { id: 'Migration', name: 'Migration' },
    { id: 'Tourism agent', name: 'Tourism agent' },
    { id: 'Travel agent', name: 'Travel agent' },
    { id: 'Insurance intermediary', name: 'Insurance intermediary' },
    { id: 'Insurance agent', name: 'Insurance agent' },
    { id: 'Farmer', name: 'Farmer' },
    { id: 'Agronomist', name: 'Agronomist' },
    { id: 'Agrologist', name: 'Agrologist' },
    { id: 'Aggrimesor', name: 'Aggrimesor' },
    { id: 'Arboriculturist', name: 'Arboriculturist' },
    { id: 'Surveyor', name: 'Surveyor' },
    { id: 'Geographer', name: 'Geographer' },
    {
      id: 'System and Computer Analysts',
      name: 'System and Computer Analysts'
    },
    { id: 'Anthropologist', name: 'Anthropologist' },
    {
      id: 'Archaeologist and Ethnologist',
      name: 'Archaeologist and Ethnologist'
    },
    { id: 'Archivist', name: 'Archivist' },
    { id: 'Architect', name: 'Architect' },
    { id: 'Social assistant', name: 'Social assistant' },
    { id: 'Literary author', name: 'Literary author' },
    { id: 'Writer and Critical', name: 'Writer and Critical' },
    { id: 'Bacteriologist', name: 'Bacteriologist' },
    { id: 'Pharmacologist', name: 'Pharmacologist' },
    { id: 'Biologist', name: 'Biologist' },
    { id: 'Scientist', name: 'Scientist' },
    { id: 'Bank Cashier', name: 'Bank Cashier' },
    { id: 'Waiter', name: 'Waiter' },
    { id: 'Barman', name: 'Barman' },
    { id: 'Kitchener', name: 'Kitchener' },
    { id: 'Chef', name: 'Chef' },
    { id: 'Builder', name: 'Builder' },
    { id: 'Counter', name: 'Counter' },
    { id: 'Contractor', name: 'Contractor' },
    {
      id: 'Taylor / Garment manufacturer',
      name: 'Taylor / Garment manufacturer'
    },
    { id: 'Decorator', name: 'Decorator' },
    { id: 'Designer', name: 'Designer' },
    { id: 'Publicist', name: 'Publicist' },
    { id: 'Advertising designer', name: 'Advertising designer' },
    { id: 'Dentist', name: 'Dentist' },
    { id: 'Dentistry', name: 'Dentistry' },
    { id: 'Professional athlete', name: 'Professional athlete' },
    { id: 'Athlete', name: 'Athlete' },
    { id: 'Referee', name: 'Referee' },
    { id: 'Sports coach', name: 'Sports coach' },
    { id: 'Teacher', name: 'Teacher' },
    { id: 'Economist', name: 'Economist' },
    { id: 'Exporting businessman', name: 'Exporting businessman' },
    { id: 'Importing businessman', name: 'Importing businessman' },
    { id: 'Nurse', name: 'Nurse' },
    { id: 'Sculptor', name: 'Sculptor' },
    { id: 'Photographer', name: 'Photographer' },
    { id: 'Operators camera', name: 'Operators camera' },
    { id: 'Film and tv', name: 'Film and tv' },
    { id: 'Radio and tv announcer', name: 'Radio and tv announcer' },
    { id: 'Scriptwriter', name: 'Scriptwriter' },
    { id: 'Historian', name: 'Historian' },
    { id: 'Engineer', name: 'Engineer' },
    { id: 'Interpreter, Translator', name: 'Interpreter, Translator' },
    { id: 'Jockey', name: 'Jockey' },
    { id: 'Laboratory (technician)', name: 'Laboratory (technician)' },
    { id: 'Liquidator', name: 'Liquidator' },
    { id: 'Claims', name: 'Claims' },
    { id: 'Insurance', name: 'Insurance' },
    { id: 'Machiner', name: 'Machiner' },
    { id: 'Machinery operator', name: 'Machinery operator' },
    { id: 'Hammer', name: 'Hammer' },
    { id: 'Auctioneer', name: 'Auctioneer' },
    { id: 'Doctor', name: 'Doctor' },
    { id: 'Surgeon', name: 'Surgeon' },
    { id: 'Metallurgist', name: 'Metallurgist' },
    { id: 'Nutritionist', name: 'Nutritionist' },
    { id: 'Obstetrician', name: 'Obstetrician' },
    { id: 'Event planner', name: 'Event planner' },
    { id: 'Paramedic', name: 'Paramedic' },
    { id: 'Journalist', name: 'Journalist' },
    { id: 'Proficient', name: 'Proficient' },
    { id: 'Pilot', name: 'Pilot' },
    { id: 'Film Producer', name: 'Film Producer' },
    { id: 'Radio', name: 'Radio' },
    { id: 'TV', name: 'TV' },
    { id: 'Theater', name: 'Theater' },
    { id: 'Producer, extensive crops', name: 'Producer, extensive crops' },
    { id: 'Programmer', name: 'Programmer' },
    { id: 'Psychologist', name: 'Psychologist' },
    { id: 'Therapist', name: 'Therapist' },
    { id: 'Chiropractor', name: 'Chiropractor' },
    {
      id: 'Kinesiterapist (Kinesiologists)',
      name: 'Kinesiterapist (Kinesiologists)'
    },
    {
      id: 'Public and Industrial Relacionist',
      name: 'Public and Industrial Relacionist'
    },
    { id: 'Secretary', name: 'Secretary' },
    { id: 'Receptionist', name: 'Receptionist' },
    { id: 'Telephonist', name: 'Telephonist' },
    { id: 'Sociologist', name: 'Sociologist' },
    { id: 'Appraiser', name: 'Appraiser' },
    { id: 'Veterinarian', name: 'Veterinarian' },
    { id: 'Zoologist', name: 'Zoologist' },
    { id: 'Zootechnical', name: 'Zootechnical' },
    { id: 'Medical visitor', name: 'Medical visitor' }
  ],
  Employer: [
    { id: 'Employer', name: 'Employer' },
    { id: 'Other', name: 'Other' }
  ],
  Student: [{ id: 'Student', name: 'Student' }],
  Retired: [
    { id: 'Attorney', name: 'Attorney' },
    { id: 'Actor', name: 'Actor' },
    { id: 'Actress', name: 'Actress' },
    { id: 'Artist', name: 'Artist' },
    { id: 'Show director', name: 'Show director' },
    { id: 'Choreographer', name: 'Choreographer' },
    { id: 'Model', name: 'Model' },
    { id: 'Musician', name: 'Musician' },
    { id: 'Actuary', name: 'Actuary' },
    { id: 'Administrator', name: 'Administrator' },
    { id: 'Customs officer', name: 'Customs officer' },
    { id: 'Customs agent', name: 'Customs agent' },
    { id: 'Border inspectors', name: 'Border inspectors' },
    { id: 'Flight attendant', name: 'Flight attendant' },
    { id: 'Stewardess', name: 'Stewardess' },
    { id: 'Real estate Agent', name: 'Real estate Agent' },
    { id: 'Real estate Intermediary', name: 'Real estate Intermediary' },
    { id: 'Real estate broker', name: 'Real estate broker' },
    { id: 'Broker', name: 'Broker' },
    { id: 'Immigration agent', name: 'Immigration agent' },
    { id: 'Migration', name: 'Migration' },
    { id: 'Tourism agent', name: 'Tourism agent' },
    { id: 'Travel agent', name: 'Travel agent' },
    { id: 'Insurance intermediary', name: 'Insurance intermediary' },
    { id: 'Insurance agent', name: 'Insurance agent' },
    { id: 'Farmer', name: 'Farmer' },
    { id: 'Agronomist', name: 'Agronomist' },
    { id: 'Agrologist', name: 'Agrologist' },
    { id: 'Aggrimesor', name: 'Aggrimesor' },
    { id: 'Arboriculturist', name: 'Arboriculturist' },
    { id: 'Surveyor', name: 'Surveyor' },
    { id: 'Geographer', name: 'Geographer' },
    { id: 'Mason', name: 'Mason' },
    { id: 'Construction worker', name: 'Construction worker' },
    {
      id: 'System and Computer Analysts',
      name: 'System and Computer Analysts'
    },
    { id: 'Anthropologist', name: 'Anthropologist' },
    {
      id: 'Archaeologist and Ethnologist',
      name: 'Archaeologist and Ethnologist'
    },
    { id: 'Archivist', name: 'Archivist' },
    { id: 'Boat builder', name: 'Boat builder' },
    { id: 'Architect', name: 'Architect' },
    { id: 'Craftsman', name: 'Craftsman' },
    { id: 'Social assistant', name: 'Social assistant' },
    { id: 'Literary author', name: 'Literary author' },
    { id: 'Writer and Critical', name: 'Writer and Critical' },
    { id: 'Bacteriologist', name: 'Bacteriologist' },
    { id: 'Pharmacologist', name: 'Pharmacologist' },
    { id: 'Biologist', name: 'Biologist' },
    { id: 'Scientist', name: 'Scientist' },
    { id: 'Poultryman', name: 'Poultryman' },
    { id: 'Waste man', name: 'Waste man' },
    { id: 'Sweeper', name: 'Sweeper' },
    { id: 'Bank Cashier', name: 'Bank Cashier' },
    { id: 'Waiter', name: 'Waiter' },
    { id: 'Barman', name: 'Barman' },
    { id: 'Kitchener', name: 'Kitchener' },
    { id: 'Chef', name: 'Chef' },
    {
      id: 'Exchanger, Purchase / Sale of currency',
      name: 'Exchanger, Purchase / Sale of currency'
    },
    { id: 'Peasant', name: 'Peasant' },
    { id: 'Foreman', name: 'Foreman' },
    { id: 'Handler', name: 'Handler' },
    { id: 'Carpenter', name: 'Carpenter' },
    { id: 'Postman', name: 'Postman' },
    { id: 'Locksmith', name: 'Locksmith' },
    { id: 'Debt collector', name: 'Debt collector' },
    { id: 'Conductor', name: 'Conductor' },
    { id: 'Driver', name: 'Driver' },
    { id: 'Taxi driver', name: 'Taxi driver' },
    { id: 'Concierge', name: 'Concierge' },
    { id: 'Porter', name: 'Porter' },
    { id: 'Guardian', name: 'Guardian' },
    { id: 'Vigilant', name: 'Vigilant' },
    { id: 'Builder', name: 'Builder' },
    { id: 'Counter', name: 'Counter' },
    { id: 'Contractor', name: 'Contractor' },
    {
      id: 'Taylor / Garment manufacturer',
      name: 'Taylor / Garment manufacturer'
    },
    { id: 'Cosmetologist', name: 'Cosmetologist' },
    { id: 'Hairdresser and Barber', name: 'Hairdresser and Barber' },
    { id: 'Decorator', name: 'Decorator' },
    { id: 'Designer', name: 'Designer' },
    { id: 'Publicist', name: 'Publicist' },
    { id: 'Advertising designer', name: 'Advertising designer' },
    { id: 'Dentist', name: 'Dentist' },
    { id: 'Dentistry', name: 'Dentistry' },
    { id: 'Professional athlete', name: 'Professional athlete' },
    { id: 'Athlete', name: 'Athlete' },
    { id: 'Referee', name: 'Referee' },
    { id: 'Sports coach', name: 'Sports coach' },
    { id: 'Distributor', name: 'Distributor' },
    { id: 'Teacher', name: 'Teacher' },
    { id: 'Economist', name: 'Economist' },
    { id: 'Electrical technician', name: 'Electrical technician' },
    { id: 'Exporting businessman', name: 'Exporting businessman' },
    { id: 'Importing businessman', name: 'Importing businessman' },
    { id: 'Nurse', name: 'Nurse' },
    { id: 'Assembler', name: 'Assembler' },
    { id: 'Sculptor', name: 'Sculptor' },
    { id: 'Photographer', name: 'Photographer' },
    { id: 'Operators camera', name: 'Operators camera' },
    { id: 'Film and tv', name: 'Film and tv' },
    { id: 'Radio and tv announcer', name: 'Radio and tv announcer' },
    { id: 'Scriptwriter', name: 'Scriptwriter' },
    { id: 'Rancher', name: 'Rancher' },
    { id: 'Plumber', name: 'Plumber' },
    { id: 'Historian', name: 'Historian' },
    { id: 'Engineer', name: 'Engineer' },
    { id: 'Interpreter, Translator', name: 'Interpreter, Translator' },
    { id: 'Gardener', name: 'Gardener' },
    { id: 'Jockey', name: 'Jockey' },
    { id: 'Jewerler / Silver', name: 'Jewerler / Silver' },
    {
      id: 'Laboratory (technician)',
      name: 'Laboratory (technician)'
    },
    { id: 'Liquidator', name: 'Liquidator' },
    { id: 'Claims', name: 'Claims' },
    { id: 'Insurance', name: 'Insurance' },
    { id: 'Machiner', name: 'Machiner' },
    { id: 'Machinery operator', name: 'Machinery operator' },
    { id: 'Hammer', name: 'Hammer' },
    { id: 'Auctioneer', name: 'Auctioneer' },
    { id: 'Wholesale', name: 'Wholesale' },
    { id: 'Mechanic', name: 'Mechanic' },
    { id: 'Surgeon', name: 'Surgeon' },
    { id: 'Metallurgist', name: 'Metallurgist' },
    { id: 'Nutritionist', name: 'Nutritionist' },
    { id: 'Laborer Operator', name: 'Laborer Operator' },
    { id: 'Obstetrician', name: 'Obstetrician' },
    { id: 'Event planner', name: 'Event planner' },
    { id: 'Baker', name: 'Baker' },
    { id: 'Pastry', name: 'Pastry' },
    { id: 'Paramedic', name: 'Paramedic' },
    { id: 'Journalist', name: 'Journalist' },
    { id: 'Proficient', name: 'Proficient' },
    { id: 'Fisherman', name: 'Fisherman' },
    { id: 'Pilot', name: 'Pilot' },
    { id: 'Painter', name: 'Painter' },
    { id: 'Municipal police', name: 'Municipal police' },
    { id: 'State police', name: 'State police' },
    { id: 'Film Producer', name: 'Film Producer' },
    { id: 'Radio', name: 'Radio' },
    { id: 'TV', name: 'TV' },
    { id: 'Theater', name: 'Theater' },
    { id: 'Producer, extensive crops', name: 'Producer, extensive crops' },
    { id: 'Programmer', name: 'Programmer' },
    { id: 'Psychologist', name: 'Psychologist' },
    { id: 'Therapist', name: 'Therapist' },
    { id: 'Chiropractor', name: 'Chiropractor' },
    {
      id: 'Kinesiterapist (Kinesiologists)',
      name: 'Kinesiterapist (Kinesiologists)'
    },
    {
      id: 'Public and Industrial Relacionist',
      name: 'Public and Industrial Relacionist'
    },
    { id: 'Watchmaker', name: 'Watchmaker' },
    { id: 'Car repair', name: 'Car repair' },
    { id: 'Retoucher painter', name: 'Retoucher painter' },
    { id: 'Appliances repairer', name: 'Appliances repairer' },
    { id: 'Delivery man', name: 'Delivery man' },
    { id: 'Secretary', name: 'Secretary' },
    { id: 'Receptionist', name: 'Receptionist' },
    { id: 'Telephonist', name: 'Telephonist' },
    { id: 'Body guard', name: 'Body guard' },
    { id: 'Security guard', name: 'Security guard' },
    { id: 'Storage / Warehouse service', name: 'Storage / Warehouse service' },
    { id: 'Vehicle rental service', name: 'Vehicle rental service' },
    { id: 'Sociologist', name: 'Sociologist' },
    { id: 'Appraiser', name: 'Appraiser' },
    { id: 'Technical', name: 'Technical' },
    { id: 'Bullfighter', name: 'Bullfighter' },
    { id: 'Processor', name: 'Processor' },
    {
      id: 'Cargo transportation and / or moving',
      name: 'Cargo transportation and / or moving'
    },
    { id: 'Carrier', name: 'Carrier' },
    { id: 'Peddler', name: 'Peddler' },
    { id: 'Veterinarian', name: 'Veterinarian' },
    { id: 'Zoologist', name: 'Zoologist' },
    { id: 'Zootechnical', name: 'Zootechnical' },
    { id: 'Medical visitor', name: 'Medical visitor' },
    { id: 'Shoemaker', name: 'Shoemaker' }
  ],
  'Member of the Armed Forces': [
    { id: 'Member of the Armed Forces', name: 'Member of the Armed Forces' },
    { id: 'Municipal police', name: 'Municipal police' },
    { id: 'State police', name: 'State police' }
  ],
  'Clergy member': [{ id: 'Priest / Nun', name: 'Priest / Nun' }],
  Laborer: [
    { id: 'Mason', name: 'Mason' },
    { id: 'Construction worker', name: 'Construction worker' },
    { id: 'Boat builder', name: 'Boat builder' },
    { id: 'Craftsman', name: 'Craftsman' },
    { id: 'Poultryman', name: 'Poultryman' },
    { id: 'Waste man', name: 'Waste man' },
    { id: 'Sweeper', name: 'Sweeper' },
    {
      id: 'Exchanger, Purchase / Sale of currency',
      name: 'Exchanger, Purchase / Sale of currency'
    },
    { id: 'Peasant', name: 'Peasant' },
    { id: 'Foreman', name: 'Foreman' },
    { id: 'Handler', name: 'Handler' },
    { id: 'Carpenter', name: 'Carpenter' },
    { id: 'Postman', name: 'Postman' },
    { id: 'Locksmith', name: 'Locksmith' },
    { id: 'Debt collector', name: 'Debt collector' },
    { id: 'Conductor', name: 'Conductor' },
    { id: 'Driver', name: 'Driver' },
    { id: 'Taxi driver', name: 'Taxi driver' },
    { id: 'Concierge', name: 'Concierge' },
    { id: 'Porter', name: 'Porter' },
    { id: 'Guardian', name: 'Guardian' },
    { id: 'Vigilant', name: 'Vigilant' },
    { id: 'Cosmetologist', name: 'Cosmetologist' },
    { id: 'Hairdresser and Barber', name: 'Hairdresser and Barber' },
    { id: 'Distributor', name: 'Distributor' },
    { id: 'Electrical technician', name: 'Electrical technician' },
    { id: 'Assembler', name: 'Assembler' },
    { id: 'Rancher', name: 'Rancher' },
    { id: 'Plumber', name: 'Plumber' },
    { id: 'Gardener', name: 'Gardener' },
    { id: 'Jewerler / Silver', name: 'Jewerler / Silver' },
    { id: 'Mechanic', name: 'Mechanic' },
    { id: 'Laborer Operator', name: 'Laborer Operator' },
    { id: 'Baker', name: 'Baker' },
    { id: 'Pastry', name: 'Pastry' },
    { id: 'Fisherman', name: 'Fisherman' },
    { id: 'Painter', name: 'Painter' },
    { id: 'Watchmaker', name: 'Watchmaker' },
    { id: 'Car repair', name: 'Car repair' },
    { id: 'Retoucher painter', name: 'Retoucher painter' },
    { id: 'Appliances repairer', name: 'Appliances repairer' },
    { id: 'Delivery man', name: 'Delivery man' },
    { id: 'Security', name: 'Security' },
    { id: 'Body guard', name: 'Body guard' },
    { id: 'Security guard', name: 'Security guard' },
    { id: 'Storage', name: 'Storage' },
    { id: 'Warehouse service', name: 'Warehouse service' },
    { id: 'Technical', name: 'Technical' },
    { id: 'Bullfighter', name: 'Bullfighter' },
    { id: 'Processor', name: 'Processor' },
    {
      id: 'Cargo transportation and / or moving',
      name: 'Cargo transportation and / or moving'
    },
    { id: 'Carrier', name: 'Carrier' },
    { id: 'Shoemaker', name: 'Shoemaker' }
  ],
  'Domestic Worker': [
    { id: 'Household employee', name: 'Household employee' },
    { id: 'Nana', name: 'Nana' }
  ],
  'Independent Worker': [
    { id: 'Merchant / Seller', name: 'Merchant / Seller' },
    { id: 'Wholesale', name: 'Wholesale' },
    { id: 'Vehicle rental service', name: 'Vehicle rental service' },
    {
      id: 'Video rental service, Sound equipment',
      name: 'Video rental service, Sound equipment'
    },
    { id: 'Peddler', name: 'Peddler' },
    { id: 'Other', name: 'Other' }
  ]
};
