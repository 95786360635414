import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button, Table, Container, FormGroup } from 'reactstrap';
import {
  syncAllUsers,
  getContactList,
  deleteListById,
  createListByName
} from './SendContactsSendridHooks';
import './SendContactsSendrid.css';
import Loading from '../../components/Loading';

const SendContactsSendrid = (props, context) => {
  let { userInfo } = props;
  const [listado, setListado] = useState([]);
  const [loading, setLoading] = useState(0);

  const syncEmailsListadoContactosById = async id => {
    setLoading(1);
    try {
      let sync = await syncAllUsers(userInfo.UserId, id);
      let job = sync.data.job_id;
      toast.success(context.t('Synced to Sendgrid \n Job:') + job, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const deleteContactosFromList = async (id, name) => {
    setLoading(1);
    let listname = name;
    try {
      let deletelist = await deleteListById(userInfo.UserId, id);
      let job = deletelist.data.job_id;
      let createlist = await createListByName(userInfo.UserId, name);
      setLoading(0);
      getListadoContactosById();
      if (!empty(deletelist) && !empty(createlist)) {
        toast.success(context.t('Contactos ELiminados Satisfactoriamente'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const construirListado = lista => {
    let fila = [];
    for (let e of lista) {
      fila.push(
        <tr key={e.id}>
          <td>{e.id}</td>
          <td>{e.name}</td>
          <td>{e.contact_count}</td>
          <td>
            <div className="btn-table">
              <Button
                color="primary"
                onClick={() => {
                  syncEmailsListadoContactosById(e.id);
                }}>
                {context.t('Sync')}
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  deleteContactosFromList(e.id, e.name);
                }}>
                {context.t('Delete')}
              </Button>
            </div>
          </td>
        </tr>
      );
    }
    return fila;
  };

  const getListadoContactosById = async () => {
    setLoading(1);
    try {
      let sync = await getContactList(userInfo.UserId);

      setListado(sync.data);
    } catch (error) {}
    setLoading(0);
  };

  useEffect(
    () => {
      if (!empty(userInfo)) {
        getListadoContactosById();
      }
    },
    [userInfo]
  );

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className="content-sendgrid">
        <Container className="row-sendgrid">
          <h3>{context.t('Contact Lists SendGrid')}</h3>
          <div className="btn-right">
            <Button
              color="primary"
              onClick={() => {
                getListadoContactosById();
              }}>
              {context.t('Refresh')}
            </Button>
          </div>
          <Table className="list-sengrid">
            <thead>
              <tr>
                <th>Id</th>
                <th>List</th>
                <th>Contacts</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>{construirListado(listado)}</tbody>
          </Table>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

SendContactsSendrid.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(SendContactsSendrid);
