import isEmpty from 'is-empty';
import React from 'react'
import DataFormatterHelper from '../../../../helpers/DataFormatter.helper';
import AccordionMultiple from '../../Accordion/AccordionMultiple';
import { CustomButton } from '../../CustomButton/CustomButton';

const Coincidence = ({ coincidence: { coincidences = [], similarities = [] } }) => {

    const buildFullName = (firstName, middleName, lastName, secondLastName) => {
        const name = `${firstName} ${middleName} ${lastName} ${secondLastName}`
        const newName = name.trim();
        return !isEmpty(newName) ? newName : '--';
    }

    const downloadRows = (array, title) => {
        const dataWithDynamicFields = array.map(({ firstName, middleName, lastName, secondLastName, banexcoinUserAccountId }) => {
            const name = buildFullName(firstName, middleName, lastName, secondLastName);
            return {
                'Nombre Completo': name === '--' ? null : name,
                'AccountId': banexcoinUserAccountId
            }
        });
        const url = window.URL.createObjectURL(new Blob([DataFormatterHelper.convertToCSV({ data: dataWithDynamicFields })], { type: 'text/csv;charset=utf-8;' }));
        const currentdate = new Date();
        const datetime = currentdate.getDate() + "_" + (currentdate.getMonth() + 1) + "_" + currentdate.getFullYear() + "___" + currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds();
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `banexcoin_${title}_${datetime}.csv`);
        document.body.appendChild(link);
        link.click();
    };

    const handleOpenNewUser = (id) =>  window.open(`${window.location.origin}/clientes-naturales/${id}`, "_blank");

    const CoincidenceSection = (array, type) => {
        const textInfo = {
            coincidencia: 'No se han encontrado coincidencias del documento',
            similaridad: 'No se han encontrado coincidencias en el nombre y apellido'
        }
        if (isEmpty(array)) return <p>{textInfo[type]}</p>;
        return (
            <div>
                {array.map(({ firstName, middleName, lastName, secondLastName, banexcoinUserAccountId, id }, key) =>
                    <div key={key} className='item-coincidence'>
                        <div className='item-information'>
                            <p><span>Nombre Completo: </span><span>{buildFullName(firstName, middleName, lastName, secondLastName)}</span></p>
                            <p><span>AccountId: </span><span>{banexcoinUserAccountId}</span></p>
                        </div>
                        <CustomButton text="Ver" onClick={() => handleOpenNewUser(id)} className="black" />
                    </div>
                )}
            </div>
        )
    }

    const listItems = [
        {
            title: 'Coincidencia',
            Component: CoincidenceSection(coincidences, 'coincidencia')
        },
        {
            title: 'Similaridad',
            Component: CoincidenceSection(similarities, 'similaridad')
        }
    ]

    return (
        <div className="content-tab">
            <div className="content-tab-container">
                <AccordionMultiple list={listItems} />
            </div>
            <div className="buttons-container">
                <div className="buttons-left" />
                <div className='buttons-right-content'>
                    <CustomButton text="Descargar coincidencia" onClick={() => downloadRows(coincidences, 'coincidences')} className="purple" disabled={isEmpty(coincidences)} showLoader={false} />
                    <CustomButton text="Descargar similaridad" onClick={() => downloadRows(similarities, 'similarities')} className="purple" disabled={isEmpty(similarities)} showLoader={false} />
                </div>
            </div>
        </div>
    )
}

export default Coincidence