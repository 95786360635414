import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import DataFormatterHelper from "../../helpers/DataFormatter.helper";
import { getDownloadTradesReport, postAllTradesReport } from "../FallServices/TradeReportServices";
import xlsx from "json-as-xlsx";

class TradeReportController {
    static handleAllTradesReport = async ({
        userInfo,
        filterConfig,
        filterSearch,
        setLoading,
        setRows,
        setTotalRows
    }) => {
        try {
            setLoading(true);
            const params = { filterConfig, filterSearch }
            const response = await postAllTradesReport(userInfo.UserId, params);
            if (!isEmpty(response.data)) {
                setRows(response.data.levels.nodes);
                setTotalRows(response.data.levels.totalCount);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static handleDownloadAllTradesReport = async ({
        userInfo
    }) => {
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: name.replace('binance','provider'),
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const response = await getDownloadTradesReport(userInfo.UserId);
            const reportInfo = DataFormatterHelper.csvToJSON(response.data);
            const columns = buildColumns(reportInfo);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: reportInfo
                }
            ];
            const settings = {
                fileName: "Reporte de Intercambio",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }
}
export default TradeReportController;