import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  getAlertById,
  getAlerts,
  getAmlCodes,
  getUserByBanexcoinId,
  updateEventDetailByEventId,
  updateUserOnboardingStatus,
} from "../FallServices/AmlAlertsServices";

class AmlAlertsController {
  static getAllAlerts = async ({
    userInfo,
    filterConfig,
    filterSearch,
    setRows,
    setTotalRows,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const response = await getAlerts(
        userInfo.UserId,
        filterConfig,
        filterSearch
      );
      if (!isEmpty(response) && !isEmpty(response.data)) {
        setRows(response.data.nodes);
        setTotalRows(response.data.totalCount);
      }
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static getAlertById = async ({
    userInfo,
    id,
    setLoadingAlertDataById,
    setSelectedAlertData,
  }) => {
    try {
      setLoadingAlertDataById(true);
      const response = await getAlertById(userInfo.UserId, id);
      if (!isEmpty(response) && !isEmpty(response.data)) {
        setSelectedAlertData(response.data[0]);
        return response.data[0];
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoadingAlertDataById(false);
    }
  };
  static getAllAmlCodes = async ({ userInfo, setAmlCodes }) => {
    try {
      const response = await getAmlCodes(userInfo.UserId);
      if (!isEmpty(response) && !isEmpty(response.data)) {
        setAmlCodes(response.data);
        return true;
      }
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  };
  static getUserByBanexcoinId = async ({ userInfo, setBanexcoinUserData }) => {
    try {
      const response = await getUserByBanexcoinId(userInfo.UserId);
      if (!isEmpty(response) && !isEmpty(response.data)) {
        setBanexcoinUserData(response.data);
        return response.data;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  };
  static updateUserOnboardingStatus = async ({
    userInfo,
    setLoadingUpdate,
    banexcoinUserId,
    data,
    action,
  }) => {
    try {
      setLoadingUpdate(true);
      const response = await updateUserOnboardingStatus(
        userInfo.UserId,
        banexcoinUserId,
        data,
        action
        );
        if (
          !isEmpty(response) &&
        !isEmpty(response.data) &&
        response.data.message === "success"
      ) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "La información fue actualizada exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser actualizada",
      });
      return false;
    } finally {
      setLoadingUpdate(false);
    }
  };
  static modifyEventDetails = async ({userInfo,id,data,setLoadingUpdate}) => {
    try {
      setLoadingUpdate(true)
      const response = await updateEventDetailByEventId(userInfo.UserId,id,data)
      if (!isEmpty(response) && response.status === 200) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "La información fue actualizada exitosamente",
        });
        return true;
      }
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }finally{
      setLoadingUpdate(false)
    }
  }
}

export default AmlAlertsController;
