export const actionTypes = [
    {
      id: 'LOCK_USER',
      name: 'Usuario Bloqueado'
    },
    {
      id: 'UNLOCK_USER',
      name: 'Usuario Desbloqueado'
    },
    {
      id: 'FREEZE_ACCOUNT',
      name: 'Cuenta Congelada'
    },
    {
      id: 'UNFREEZE_ACCOUNT',
      name: 'Cuenta Descongelada'
    },
    {
      id: 'EDIT_FIELDS',
      name: 'Campos editados'
    },
    {
      id: 'RENIEC_VALIDATION',
      name: 'Validacion a Reniec'
    },
    {
      id: 'RETURNED_FIELDS',
      name: 'Campos devueltos'
    },
    {
      id: 'EDIT_FIELDS_CORPORATE_BASIC_INFO',
      name: 'Campos devueltos (Datos basicos corporativo)'
    },
    {
      id: 'RETURNED_FIELDS_CORPORATE_SHAREHOLDER_INFO',
      name: 'Campos devueltos (Datos de accionista corporativo)'
    },
    {
      id: 'EDIT_FIELDS_CORPORATE_BASIC_CHANGED_INFO',
      name: 'Campos editados (Datos de corporativo)'
    },
    {
      id: 'EDIT_FIELDS_CORPORATE_SHAREHOLDER_INFO',
      name: 'Campos editados (Datos de accionista corporativo)'
    },
    {
      id: 'USER_APROVED',
      name: 'Usuario aprobado'
    },
    {
      id: 'USER_REJECTED',
      name: 'Usuario rechazado'
    },
    {
      id: 'SENT_TO_IDM',
      name: 'Enviado a ACUANT'
    },
    {
      id: 'IDM_SENT',
      name: 'Enviado a ACUANT'
    },
    {
      id: 'RENIEC_VALIDATION',
      name: 'Validacion con Reniec'
    },
    {
      id: 'CORFID_RESPONSE',
      name: 'Respuesta de TRUST_PE'
    },
    {
      id: 'OCR_VALIDATION',
      name: 'Respuesta de OCR'
    },
    {
      id: 'TRUST_PE',
      name: 'Respuesta de TRUST_PE'
    },
    {
      id: 'SERVER_ERROR',
      name: 'Error del server'
    },
    {
      id: 'WORKER_TYPE_CHANGED',
      name: 'Tipo de trabajador cambiado'
    }
  ];