import React from 'react';
import PropTypes from 'prop-types';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import LoginFormContainer from './LoginFormContainer';

export const LoginFormComponent = (props, context) => {
  return <LoginFormContainer />;
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  LoginFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
