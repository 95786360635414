import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import empty from 'is-empty';
import PropTypes from 'prop-types';
import { useForm } from '../../../hooks/formHooks';
import HeaderPage from '../HeaderPage/HeaderPage'
import InformationSection from '../InformationSection/InformationSection';
import isEmpty from 'is-empty';
import { LoaderButton } from '../Loader/Loader';

const AllAutoTradeData = (props, context) => {

    const { loading, trades, handleEditSettingByKey, rates = [], level1 = {}, instruments = [] } = props;

    const baseInstrument = 'USDCUSD';
    const currentRate = rates.find(r => r.currency === 'PEN')
    const [initialValues, setInitialValues] = useState({
        id: '',
        type: '',
        value: ''
    })
    const [initialValues2, setInitialValues2] = useState({
        id: '',
        type: '',
        value: ''
    })
    const [initialValues3, setInitialValues3] = useState({
        id: '',
        ACTIVE: '',
        accountUsername: ''
    })

    const { inputs, onInputChangeV2, setInputs } = useForm(initialValues);
    const { inputs: inputs2, onInputChangeV2: onInputChangeV22, setInputs: setInputs2 } = useForm(initialValues2);
    const { inputs: inputs3, onInputChangeV2: onInputChangeV23, setInputs: setInputs3 } = useForm(initialValues3);

    const resetValues = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = initialValues[name];
        });
        setInputs({
            ...inputs,
            ...newInitialvalues
        })
    };
    const resetValues2 = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = initialValues2[name];
        });
        setInputs2({
            ...inputs2,
            ...newInitialvalues
        })
    };
    const resetValues3 = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = initialValues3[name];
        });
        setInputs3({
            ...inputs3,
            ...newInitialvalues
        })
    };

    const handleEdit = (id, data) => {
        let newData = {}
        Object.keys(data).forEach((element) => {
            if (element === 'value') {
                newData[element] = Number(data[element])
            } else {
                newData[element] = data[element]
            }
        })
        handleEditSettingByKey(id, newData)
    }


    const USDCPEN_BUY_CONFIG = {
        title: 'Configuración de compra USDCPEN',
        resetValues: resetValues,
        submitData: () => handleEdit(initialValues.id, inputs),
        fields: [
            {
                label: 'Tipo',
                name: 'type',
                type: 'select',
                showCheckbox: false,
                options: [
                    {
                        name: 'Porcentaje',
                        value: 'percentage'
                    },
                    {
                        name: 'Fijo',
                        value: 'fixed'
                    }
                ]

            },
            {
                label: 'Valor',
                name: 'value',
                type: 'number',
                showCheckbox: false
            }
        ]
    }

    const USDCPEN_SELL_CONFIG = {
        title: 'Configuración de venta USDCPEN',
        resetValues: resetValues2,
        submitData: () => handleEdit(initialValues2.id, inputs2),
        fields: [
            {
                label: 'Tipo',
                name: 'type',
                type: 'select',
                showCheckbox: false,
                options: [
                    {
                        name: 'Porcentaje',
                        value: 'percentage'
                    },
                    {
                        name: 'Fijo',
                        value: 'fixed'
                    }
                ]

            },
            {
                label: 'Valor',
                name: 'value',
                type: 'number',
                showCheckbox: false
            }
        ]
    }

    const USDCPEN_CONFIG = {
        title: 'Configuración general USDCPEN',
        resetValues: resetValues3,
        submitData: () => handleEdit(initialValues3.id, inputs3),
        fields: [
            {
                label: 'Activo',
                name: 'ACTIVE',
                type: 'select',
                showCheckbox: false,
                options: [
                    {
                        name: 'Si',
                        value: true
                    },
                    {
                        name: 'No',
                        value: false
                    }
                ]

            },
            {
                label: 'Nombre de usuario de la cuenta',
                name: 'accountUsername',
                type: 'text',
                showCheckbox: false
            }
        ]
    }

    useEffect(() => {
        if (!isEmpty(trades)) {
            const initial1 = trades.find(element => element.settingsKey === "USDCPEN_BUY_CONFIG");
            const initial2 = trades.find(element => element.settingsKey === "USDCPEN_SELL_CONFIG");
            const initial3 = trades.find(element => element.settingsKey === "USDCPEN_CONFIG");
            setInputs(initial1.settingsValue)
            setInitialValues({ ...initial1.settingsValue, id: initial1.id })
            setInputs2(initial2.settingsValue)
            setInitialValues2({ ...initial2.settingsValue, id: initial2.id })
            setInputs3(initial3.settingsValue)
            setInitialValues3({ ...initial3.settingsValue, id: initial3.id })
        }
    }, [trades])

    const BuySellInformationChange = () => {
        Number.prototype.toFixedDown = function(digits) {
            var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
                m = this.toString().match(re);
            return m ? parseFloat(m[1]) : this.valueOf();
        };
        if(empty(currentRate)) {
            return <React.Fragment><b>Cargando precios...</b></React.Fragment>;
        }

        const instrumentBaseData = instruments.find(i => i.Symbol === baseInstrument)
        const price = level1[instrumentBaseData.InstrumentId]

        const originalPrice = currentRate.rate;
        const variableConfigBuy = (inputs.type === 'percentage') ? originalPrice * (Number(inputs.value)/100) : Number(inputs.value);

        const buyPrice = Number((originalPrice + variableConfigBuy) * price.BestBid).toFixedDown(3);

        const variableConfigSell = (inputs2.type === 'percentage') ? originalPrice * (Number(inputs2.value)/100) : Number(inputs2.value)
        const sellPrice = Number((originalPrice + variableConfigSell) * price.BestOffer).toFixedDown(3);
        
        return <React.Fragment>
            <div className='title-section-left'><h2>Último tipo de cambio USDPEN</h2></div>
            <div className='body-sector'>
                <ul className='prices-sector'>
                    <li>Precio original: <b>{currentRate.rate}</b></li>
                    <li>Precio <b>compra</b> modificado: <b>{buyPrice}</b> <i>[(Precio original + {inputs.type === 'percentage' ? '%' : 'Monto fijo'} adicional) * BestBid en USDCUSD]</i></li>
                    <li>Precio <b>venta</b> modificado: <b>{sellPrice}</b> <i>[(Precio original + {inputs2.type === 'percentage' ? '%' : 'Monto fijo'} adicional) * BestOffer en USDCUSD]</i></li>
                </ul>
            </div>
        </React.Fragment>
    }

    if(empty(instruments) || empty(level1)) {
        return <React.Fragment />
    }

    return (
        <div className='all-page-data hscroll'>
            <HeaderPage
                title="Auto trades"
                subTitle="Configuraciones / Auto trades"
            />
            <div>
                {
                    loading ?
                        <div className='section-loader'>
                            <LoaderButton color='orange' />
                            <p>Cargando información...</p>
                        </div>
                        :
                        <React.Fragment>
                            <BuySellInformationChange />
                            <div className='row-data-sector'>
                                <div className='device-section' >
                                    <InformationSection
                                        data={USDCPEN_BUY_CONFIG}
                                        inputs={inputs}
                                        onChange={onInputChangeV2}
                                        context={context}
                                    />
                                </div>
                                <div className='device-section' >
                                    <InformationSection
                                        data={USDCPEN_SELL_CONFIG}
                                        inputs={inputs2}
                                        onChange={onInputChangeV22}
                                        context={context}
                                    />
                                </div>
                                <div className='device-section' >
                                    <InformationSection
                                        data={USDCPEN_CONFIG}
                                        inputs={inputs3}
                                        onChange={onInputChangeV23}
                                        context={context}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                }
            </div>
        </div >
    )
}

AllAutoTradeData.contextTypes = {
    t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
    const { level1, instrument: { instruments } } = state;
    return {
        level1,
        instruments
    };
  };

export default connect(mapStateToProps, null)(AllAutoTradeData)