import isEmpty from 'is-empty'
import UtilsHelper from '../../helpers/Utils.helper'

export const getLevelById = async (userId, levelId, source) => {
  try {
    const path = `/api/levels/${levelId}`;
    const configRequest = {
      userId,
      method: 'get',
      path,
      source: !isEmpty(source) ? source : null
    };

    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data : {};
  } catch (error) {
    throw error;
  }
};

export const getLevelsBeta = async (userId, filterConfig, filterSearch) => {
  try {
    const path = '/api/allbasiclevels';

    const params = {
      filterConfig,
      filterSearch
    };

    const configRequest = {
      userId,
      method: 'post',
      path,
      data: params
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data.levels : [];
  } catch (error) {
    throw error;
  }
};

export const getLogsComplete = async (userId, id, kycUserId) => {
  try {
    const path = `/api/levels/getLogs/${id}/${kycUserId}`;
    const configRequest = {
      userId,
      method: 'post',
      path
    };

    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data.resultLogs : [];
  } catch (error) {
    throw error;
  }
};

export const editData = async (userId, modelFiles) => {
  try {
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    const path = '/api/v2/levels/edit';

    return UtilsHelper.sendRequest({
      userId,
      method: 'post',
      headers,
      path,
      data: modelFiles
    });
  } catch (err) {
    throw err
  }
};

export const getCountries = async (userId, restricted = false) => {
  const path = `/api/countries?restricted=${restricted}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getRequestReniecData = async (userId, documentNumber) => {
  const path = `/api/v2/reniec/data/${documentNumber}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getRequestReniecPhoto = async (userId, documentNumber) => {
  const path = `/api/v2/reniec/photo/${documentNumber}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getRequestReniecSign = async (userId, documentNumber) => {
  const path = `/api/v2/reniec/sign/${documentNumber}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const reniecRequestToValidate = async (
  userId,
  levelBasicInfo,
  cropData
) => {
  const path = `/api/services/identity/faceverify`;
  const data = {
    path: '/api/v1/identity/biometrics/faceverify',
    provider: 'RENIECPROD',
    country: levelBasicInfo.documentCountry,
    dni: levelBasicInfo.documentNumber,
    ipCliente: '192.168.5.0',
    macCliente: 'D4:C9:EF:D6:4C:0C',
    facialCapture: levelBasicInfo.facesImages,
    cropData: cropData
  };
  const response = await UtilsHelper.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
  return !isEmpty(response.data) ? response.data : [];
};

export const reniecLogRequest = async (userId, data) => {
  const path = `/api/levels/renieclogs/`;
  const response = await UtilsHelper.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
  return !isEmpty(response.data) ? response.data : [];
};

export const generalActionLog = async (userId, data) => {
  const path = `/api/action-logs`;
  const response = await UtilsHelper.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
 return !isEmpty(response.data) ? response.data : [];
};

export const savingRegiterFieldsHook = async (userId, levelId, fields) => {
  const headers = {};
  const path = `/api/levels/edit-register-fields/${levelId}`;

  const model = {
    fields: fields
  };
  return UtilsHelper.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const incrementLevel = async (
  userId,
  levelId,
  banexcoin_user_account_id,
  user_level
) => {
  const path = `/api/levels/increment/${levelId}/${banexcoin_user_account_id}/${user_level}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const correctFields = async (userId, levelId, fields) => {
  const headers = {};
  const path = `/api/levels/return-fields/${levelId}`;

  const model = {
    fields
  };
  return UtilsHelper.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
export const getUserInfoById = async (userId, id, accountid) => {
  const headers = {};
  const path = `/api/get-accountAP/info/${id}/${accountid}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    headers,
    path
  });
};

export const rejectedLevel = async (
  userId,
  levelId,
  banexcoin_user_account_id,
  user_level
) => {
  const path = `/api/levels/rejected/${levelId}/${banexcoin_user_account_id}/${user_level}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const updateToIdentityMind = async (userId, levelId) => {
  const path = `/api/levels/identity-mind/${levelId}`;
  return UtilsHelper.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const updatelockUserById = async (userId, id, fields) => {
  const headers = {};
  const path = `/api/levels/lock-user/${id}`;
  const model = {
    userLockReason: fields.userLockReason,
    userLockReasonOthers: fields.userLockReasonOthers,
    email: fields.email,
    kycUserId: fields.kycUserId,
    iduser: fields.iduser
  };
  return UtilsHelper.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const updateUnlockUserById = async (userId, id, fields) => {
  const headers = {};
  const path = `/api/levels/unlock-user/${id}`;
  const model = {
    email: fields.email,
    kycUserId: fields.kycUserId,
    iduser: fields.iduser
  };
  return UtilsHelper.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const freezeAcountById = (userId, id, accountId) => {
  const headers = {};
  const path = `/api/freeze-account/${id}/${accountId}`;
  return UtilsHelper.sendRequest({
    method: 'post',
    path,
    userId,
    headers
  });
};

export const unfreezeAcountById = (userId, id, accountId) => {
  const headers = {};
  const path = `/api/unfreeze-account/${id}/${accountId}`;
  return UtilsHelper.sendRequest({
    method: 'post',
    path,
    userId,
    headers
  });
};

export const deleteKycAndInternalMessageById = (userId, id) => {
  const headers = {};
  const path = `/api/levels/internal/message/delete/`;
  const model = { id: id };
  return UtilsHelper.sendRequest({
    method: 'post',
    path,
    headers,
    userId,
    data: model
  });
};

export const downloadAlllevelsBasic = async ({ userId, ids }) => {
  try {
    const path = '/api/download/all-basiclevel-by-ids';
    const configRequest = {
      userId,
      method: 'post',
      path,
      data: { ids }
    };
    const result = await UtilsHelper.sendRequest(configRequest);
    return result || ''
  } catch (error) {
    throw error;
  }
};

export const getCoincidence = async (userId, id) => {
  try {
    const path = `/api/match/coincidence/${id}`;
    const configRequest = {
      userId,
      method: 'GET',
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data.data : {};
  } catch (error) {
    throw error;
  }
};
export const sendToMigrations = async (userId, levelId, fields,source) => {
  const path = `/api/levels/fill-ce-register-fields/${levelId}`;
  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data: { fields },
    timeout: 80000,
    source
  });
};

export const rejectKYC = async (userId, data) => {
  const path = '/api/levels/normal/reject/lleida';
  const configRequest = {
    userId,
    method: 'PATCH',
    path,
    data
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const postFaceComparison = async (userId, data) => {
  const path = '/api/v2/face-comparison';
  const configRequest = {
    userId,
    method: 'POST',
    path,
    data
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const getBiometricData = async (userInfo, accountId) => {
  const path = `/api/levels/normal/kyc/lleida/${accountId}`;
  const configRequest = {
    userId: userInfo.UserId,
    method: 'GET',
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const updateLevel = async (userId, levelBasic, newLevel) => {
  return await UtilsHelper.sendRequest({
    userId,
    method: 'PATCH',
    data: { verificationLevel: newLevel },
    path: `/api/levels/${levelBasic.id}/update-level/${levelBasic.banexcoinUserAccountId}`
  })
}

export const getUpdatableLevels = async (userId, levelBasicId) => {
  return await UtilsHelper.sendRequest({
    userId,
    method: 'GET',
    path: `/api/levels/${levelBasicId}/update-level/possible-levels`
  })
}