import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APIcon from '../components/common/APIcon';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import path from '../helpers/path';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import StandAloneLayout from '../layouts/StandAloneLayout/StandAloneLayout';

import SignupFormContainer from 'apex-web/lib/components/SimpleSignupForm';

import '../styles/components/common/StandaloneForm.css';
import './SignupPage.css';

const baseClasses = getBEMClasses('standalone-form');
const signupPageClasses = getBEMClasses('signup-page');

export const SignupFormComponent = (props, context) => {
  return (
    <StandAloneLayout>
      <div id="bg-nmy">
        <div className="left-img">
          <div>
            <div className="img1" />
          </div>
        </div>
        <div className="right-block">
          <div className="oval" />
          <div className="boxin" />
        </div>
      </div>
      <div
        className={`${baseClasses('container')} ${signupPageClasses(
          'container'
        )}`}>
        <div
          className={`${baseClasses('header')} ${signupPageClasses('header')}`}>
          <div
            className={`${baseClasses('header-text')} ${signupPageClasses(
              'header-text'
            )}`}>
            {context.t('{n} Sign Up', {
              n: process.env.REACT_APP_SITE_NAME
            })}
          </div>
          <Link to={path('/')} className={baseClasses('close-icon')}>
            <APIcon name="close" classModifiers="big" />
          </Link>
        </div>

        <SignupFormContainer />

        <div
          className={`${baseClasses('footer')} ${signupPageClasses('footer')}`}>
          <Link
            to={path('/login')}
            className={signupPageClasses('link')}
            disabled>
            {context.t('Login')}
          </Link>
        </div>
      </div>
    </StandAloneLayout>
  );
};

SignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  SignupFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
