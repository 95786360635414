import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { CustomButton } from "../CustomButton/CustomButton";
import InputElement from "../InputElement/InputElement";
import { fiatCurrencyList, invoicesTypesInput } from "../../../constants";
import SeriesCorrelativesController from "../../../pages/FallControllers/SeriesCorrelativeController";

const status = [
  {
    value: "1",
    name: "Si",
  },
  {
    value: "0",
    name: "No",
  },
]
function AddRatesForm(props, context) {
  const { handleToggleModal,userInfo,getAllSeriesCorrelative } = props;
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  
  const handleInputChange = (target) => {
    setInputs({ ...inputs, [target.name]: target.value });
    delete errors[target.name];
  };

  const customValidations = () => {
    let errors = {};
    if (isEmpty(inputs.currency)) {
      errors.currency = "Campo requerido";
    }
    if (isEmpty(inputs.status)) {
      errors.status = "Campo requerido";
    }
    if (isEmpty(inputs.serial)) {
      errors.serial = "Campo requerido";
    }
    if (isEmpty(inputs.correlative)) {
      errors.correlative = "Campo requerido";
    }
    if (isEmpty(inputs.list_type)) {
      errors.list_type = "Campo requerido";
    }
    setErrors(errors);
    return errors;
  };

  const handleAddSerieCorrelative = async () => {
    const errors = customValidations();
    if (!isEmpty(errors)) return;
    const response = await SeriesCorrelativesController.addSerieCorrelative({userInfo,data:inputs,setLoading})
    if(response){
      handleToggleModal()
      getAllSeriesCorrelative()
    }
  };

  const docTypeInputProps = {
    values: {
      title: "Tipo de documento",
      value: inputs.list_type,
      name: "list_type",
      type: "select",
      required: true,
      options:invoicesTypesInput,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.list_type,
  };
  const activeInputProps = {
    values: {
      title: "Estado",
      value: inputs.status,
      name: "status",
      type: "select",
      required: true,
      options: status,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.status,
  };
  const serieInputProps = {
    values: {
      title: "Ingresa un número de serie",
      value: inputs.serial,
      name: "serial",
      type: "text",
      required: true,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.serial,
  };
  const correlativeInputProps = {
    values: {
      title: "Correlativo",
      value: inputs.correlative,
      name: "correlative",
      type: "text",
      required: true,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.correlative,
  };
  const currencyInputProps = {
    values: {
      title: "Tipo de moneda",
      value: inputs.currency,
      name: "currency",
      type: "select",
      required: true,
      options:fiatCurrencyList,
    },
    actions: {
      onChange: handleInputChange,
    },
    error: errors.currency,
  };

  return (
    <div>
      <div className="add-modal-inputs">
        <InputElement {...docTypeInputProps} />
        <br />
        <InputElement {...serieInputProps} />
        <br />
        <InputElement {...correlativeInputProps} />
        <br />
        <InputElement {...currencyInputProps} />
        <br />
        <InputElement {...activeInputProps} />
      </div>

      <div className="container-submit-cancel-buttons">
        <CustomButton
          onClick={handleToggleModal}
          className="red"
          text="Cancelar"
          disabled={loading}
          showLoader={false}
        />
        <CustomButton
          text="Guardar"
          className="green"
          onClick={handleAddSerieCorrelative}
          disabled={loading}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};
AddRatesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(AddRatesForm);
