import React from 'react'
import { confirmationCodesStatus } from '../../../../../constants';
import DateHelper from '../../../../../helpers/Date.helper';
import Table  from "../../../Table/Table";

export const VerificationCodesSMS = ({data}) => {

    const tableHeaders = [
        {
          tag: 'confirmationCode',
          title: 'Código',
          className: 'minimizer1'
        },
        {
          tag: 'status',
          title: 'Estado',
          cell: ({data}) => <p>{confirmationCodesStatus[`${data.status}`]}</p>,
          className: 'minimizer1'
        },
        {
          tag: 'createdAt',
          title: 'Fecha de creación',
          cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>
        },
        {
          tag: 'expirationDate',
          title: 'Fecha de expiración',
          cell: ({ data }) => <p>{DateHelper.formatDate(data.expirationDate)}</p>
        },
       
      ];
    
    return (
      <div style={{ position: 'relative' }}>
      <div className="table-wrapper">
        <Table showTooltipToCopy={false} rowHead={tableHeaders} dataBody={data.usersConfirmationCodesByUserId ? data.usersConfirmationCodesByUserId.nodes : []} />
      </div>
    </div>
    )
  }
