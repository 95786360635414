import UtilsHelper from "../../helpers/Utils.helper";

export const getLimits = async (userId) => {
  const path = `/api/v1/levels_limit`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const updateLimit = async (
  userId,
  levelLimitId,
  newAmounts,
  checkedData,
  adminUserId,
  data
) => {
  const path = `/api/v1/levels_limit`;
  const configRequest = {
    userId,
    method: "PUT",
    path,
    data: {
      id: levelLimitId,
      daily_amount: newAmounts.dailyAmount,
      monthly_amount: newAmounts.monthlyAmount,
      active: checkedData.active,
      user_id_execute_action: adminUserId,
      updated_new_values: data.newValues,
      updated_old_values: data.oldValues,
      updated_columns: data.columns,
    },
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const addLimit = async (userId, userLevel, newAmounts) => {
  const path = `/api/v1/levels_limit`;
  const configRequest = {
    userId,
    method: "POST",
    path,
    data: {
      user_level: userLevel,
      daily_amount: newAmounts.dailyAmount,
      monthly_amount: newAmounts.monthlyAmount,
    },
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const getUser = async (userId, id) => {
  const path = `/api/${id}/users`;
  const configRequest = {
    userId,
    method: "GET",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const getLogs = async (userId,id) => {
  const path = `/api/v1/levels_limit/update_logs/${id}`;
  const configRequest = {
    userId,
    method: "GET",
    path
    };
  return await UtilsHelper.sendRequest(configRequest);
};