import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import BlackListController from "../../../FallControllers/BlackListController";
import AllBlackListData from "../../../../components/FallComponents/BlackListPage/AllBlackListData";
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns";


function BlackListPage(props, context) {
  const { userInfo } = props;
  const [loadingAllResume, setLoadingAllResume] = useState(false);
  const [resumeData, setResumenData] = useState({});

  const getAllResume = () => {
    BlackListController.getAllResume({
      userInfo,
      setLoadingAllResume,
      setResumenData,
    });
  }

  const handleDownloadList = (type) => {
    BlackListController.getBlacklistRecords({userInfo,type})
  }

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllResume()
  }, [userInfo]);


  return (
    <div className="beta-content-container">
      <ResizableColumns
        childrenOne={
          <AllBlackListData
            resumeData={resumeData}
            getAllResume={getAllResume}
            loadingAllResume={loadingAllResume}
            handleDownloadList={handleDownloadList}
          />
        }
      />
    </div>
  );
}

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

BlackListPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BlackListPage);
