import isEmpty from 'is-empty';
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CustomButton } from '../../../CustomButton/CustomButton';
import InputElement from '../../../InputElement/InputElement';

const UpdateModuleModal = ({ showModal, handleToggleModal, handleUpdateModuleByCategoryId, moduleInfo, categoryOptions, id, context }) => {

    const [name, setName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');

    const nameElement = {
        values: {
            title: 'Nombre del módulo',
            name: 'name',
            value: name,
            type: 'text',
            required: true
        },
        actions: {
            onChange: (data) => setName(data.value)
        },
        error: errors.name
    };

    const categoryElement = {
        values: {
            title: "Nombre de la categoría",
            value: categoryId,
            options: categoryOptions,
            type: "select",
            required: true
        },
        actions: {
            onChange: (data) => setCategoryId(data.value)
        },
        error: errors.categoryId
    };

    const customError = () => {
        let errors = {};
        if (isEmpty(name) || !name.trim()) errors.name = 'Campo requerido';
        if (isEmpty(categoryId) || !categoryId.trim()) errors.categoryId = 'Campo requerido';
        setErrors(errors);
        return errors;
    }

    const closeModal = () => {
        loadData();
        setErrors({});
        setMessage('');
        handleToggleModal();
    }

    const submitCreateModule = async () => {
        setMessage('');
        const errors = customError();
        if (!isEmpty(errors)) return;
        if (name !== moduleInfo.name || categoryId !== id) {
            setLoading(true);
            const status = categoryId === id;
            const response = await handleUpdateModuleByCategoryId(name, moduleInfo.id, categoryId, setMessage, status, id);
            setLoading(false);
            if (response) closeModal();
        }
    }

    const loadData = () => {
        setName(moduleInfo.name);
        setCategoryId(id);
    }

    useEffect(() => {
        loadData();
    }, [moduleInfo])

    return <Modal className='modal-general-v2' isOpen={showModal}>
        <ModalHeader charCode="X" toggle={closeModal}>
            Actualización de módulo
        </ModalHeader>
        <ModalBody>
            <div className='body-content-modal'>
                <InputElement {...nameElement} />
                <InputElement {...categoryElement} />
                {!isEmpty(message) && <p className='text-red'>{context.t(message)}</p>}
                <div className=''>
                    <CustomButton
                        onClick={closeModal}
                        className="red"
                        text="Cancelar"
                        showLoader={false}
                        disabled={loading}
                    />
                    <CustomButton
                        onClick={submitCreateModule}
                        className="purple"
                        text="Actualizar módulo"
                        disabled={loading}
                    />
                </div>
            </div>
        </ModalBody>
    </Modal>
}

export default UpdateModuleModal