import isEmpty from "is-empty";
import empty from "is-empty";
import jwt_decode from "jwt-decode";
import { MenuItemsAccessV2, roleActionAccess } from "../constants";
import ProfileTick from '../images/new_icons/profile-tick2.svg';


export default class RoleAccessHelper {
  static hasAccesstoAction = (action) => {
    let result = false;
    const rolesAccess = RoleAccessHelper.getCurrentRoleAccess();
    const currentAction = roleActionAccess.find(
      (role) => role.action === action
    );
    if (!empty(currentAction)) {
      const hasAccess = currentAction.rolesAccess.find((role) =>
        rolesAccess.includes(role)
      );
      if (!empty(hasAccess)) {
        result = true;
      }
    }
    return result;
  };

  static getCurrentRoleAccess = () => {
    let roleAccess = ["norole_r"];
    const userToken = RoleAccessHelper.getCurrentUser();
    if (!empty(userToken) && !empty(userToken.role_admin_access)) {
      roleAccess = JSON.parse(userToken.role_admin_access);
    }
    return roleAccess;
  };

  static getCurrentUser = () => {
    let userToken = localStorage.getItem("tokenDB");
    let result = {};
    window.addEventListener("storage", () => {
      const token = localStorage.getItem("tokenDB");
      if (!empty(token)) {
        userToken = token;
      }
    });
    if (!empty(userToken)) {
      const decoded = jwt_decode(userToken);
      result = {
        ...decoded,
      };
    }
    return result;
  };

  static constructMenu = (menuItems,context) => {
    return menuItems.map(({category}) => {
      const getIcon = (MenuItemsAccessV2.find(item => item.name === category.name || item.nameEs === category.name) || {}).icon || ProfileTick
      return {
        id: category.id,
        name: context.t(category.name),
        icon: getIcon,
        iconMain: "SecurityIcon",
        childrens: isEmpty(category.modules)
          ? []
          : category.modules.map((module) => {
            return {
              menu: {
                text: context.t(module.name.charAt(0).toUpperCase() + module.name.slice(1)),
                path: `/${module.name.replaceAll(" ","-").toLowerCase()}`,
              },
            }
          }).sort((a,b) => (a.menu.text).localeCompare(b.menu.text)),
      }
    }).sort((a,b) => (a.name).localeCompare(b.name));
  };
}
