import isEmpty from 'is-empty'
import React from 'react'

export const InternationalTemplate = ({ transferType, withdrawTemplate, context }) => {
    return (
        <div className='slot-details'>
            <div className='option'>
                <p>
                    <b>{context.t('Transfer Type')}:</b>
                </p>
                <p>
                    {!isEmpty(transferType) ? transferType.name : ''}
                </p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Country Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankCountrySource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Name Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankNameSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Account Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.accountSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Address Source')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankAddressSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Swift / BIC Code')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.swiftBicCode}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryName}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Address')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryAddress}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Country Name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryCountryName}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary City')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryCity}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Zip')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryZip}</p>
            </div>
            <div className='option'>
                <p>
                    <b>
                        {context.t('Intermediary Bank Country Name')}:
                    </b>
                </p>
                <p>
                    {!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryBankCountryName}
                </p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Intermediary Bank name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryBankname}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Intermediary Bank Address')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryBankAddress}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Intermediary Bank City')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryBankCity}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Intermediary Bank State')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryBankState}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Intermediary Bank Zip')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryBankZip}</p>
            </div>
            <div className='option'>
                <p>
                    <b>
                        {context.t('Intermediary Swift / BIC Code')}:
                    </b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.intermediarySwiftBicCode}</p>
            </div>
            <div className='option'>
                <p>
                    <b>
                        {context.t('Intermediary ABA / Routing Number')}:
                    </b>
                </p>
                <p>
                    {!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryAbaRoutingNumber}
                </p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Intermediary Reference')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.intermediaryReference}</p>
            </div>
        </div>
    )
}
