import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import empty from 'is-empty';

class FiatAccounts extends Component {
  loadOptions(fiatAccounts) {
    let options = [];

    //console.log('FiatAccounts::::::::loadOptions',this.props.FiatAccounts)
    options.push(
      <option key="0" value="">
        Seleccionar Cuenta
      </option>
    );
    fiatAccounts.map(fiataccount =>
      options.push(
        <option key={fiataccount.id} value={fiataccount.id}>
          {fiataccount.swiftBankBySwiftBankId.bankName} -{' '}
          {fiataccount.accountNumber}
        </option>
      )
    );

    return options;
  }

  render() {
    //console.log('FiatAccounts::::::::Component',this.props.FiatAccounts);
    let fiatAccounts = this.props.fiatAccounts;

    return !empty(fiatAccounts) ? (
      <Form.Control
        as="select"
        required
        name="account_source"
        onChange={this.props.onChange}>
        {this.loadOptions(fiatAccounts)}
      </Form.Control>
    ) : (
      <p>Cargando Cuentas</p>
    );
  }
}

export default FiatAccounts;
