import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import {
  getLevelById,
  getBanexUserById,
  updateToIdentityMind,
  incrementLevel,
  rejectedLevel,
  deleteAddress,
  deletePhone,
  deleteDocument,
  deleteBackDocument,
  deleteFaceImage,
  infoIncorrect,
  modifyInfoByAdmin,
  editFormData,
  correctFields
} from './AdminLevelsHooks';
import empty from 'is-empty';
import queryString from 'query-string';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSecureGetParams } from '../../helpers/lib';
import { LEVELS_STATUS } from '../../helpers/constants';
import { preparePaginationOptions } from '../../helpers/pagination';
import { submitFormData } from '../Level/IntermediateLevelPageHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faMinusCircle,
  faQuestionCircle,
  faPaperPlane,
  faPen,
  faUserLock,
  faUserEdit,
  faSave
} from '@fortawesome/free-solid-svg-icons';
import { DatePickerInput } from 'rc-datepicker';
import PhoneInput from 'react-phone-input-2';
import { useForm } from '../../hooks/formHooks';
import Countries from '../../components/Countries';
import CropImage from './CropImage';
import UploaderBnx from './UploaderBnx';
import 'react-phone-input-2/lib/style.css';
import 'moment/locale/es.js';
var DatePicker = require('reactstrap-date-picker');

const GetFilename = url => {
  if (url) {
    return decodeURI(url.split('/').pop());
  }
  return '';
};

const layoutClasses = getBEMClasses('admin-levels-page');

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const InputText = (props, context) => {
  let { label, name, size, type, inputs, newInputs, onInputChange } = props;
  let value =
    (newInputs[name] !== 'undefined'
      ? !empty(newInputs[name])
        ? newInputs[name]
        : ''
      : '') || '';
  return (
    <Col xl={size || '6'}>
      <Form.Group controlId={name}>
        <Form.Label>{context.t(label)}</Form.Label>
        <Form.Control
          name={name}
          type={type}
          value={value}
          onChange={onInputChange}
          placeholder={context.t(label)}
        />
        <Form.Control.Feedback type="invalid">
          {context.t('You must put {name}', { name: label })}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
};

const InputSelect = (props, context) => {
  let {
    label,
    name,
    size,
    type,
    options,
    inputs,
    newInputs,
    onInputChange
  } = props;
  let value =
    newInputs[name] === '' ||
    newInputs[name] === null ||
    newInputs[name] === undefined
      ? null
      : newInputs[name].toString();
  return (
    <Col xl={size || '6'}>
      <Form.Group controlId={name}>
        <Form.Label>{context.t(label)}</Form.Label>
        <Form.Control
          as="select"
          name={name}
          value={value || ''}
          onChange={onInputChange}>
          {!empty(options) ? (
            <React.Fragment>
              <option value="">{context.t('Select an option')}</option>
              {options.map((option, index) => {
                var keys = Object.keys(option);
                return (
                  <option key={index} value={option[keys[0]]}>
                    {context.t(option[keys[1]])}
                  </option>
                );
              })}
            </React.Fragment>
          ) : (
            <option value="">{context.t('No value yet')}</option>
          )}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {context.t('You must put {name}', { name: label })}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
};

const InputDate = (props, context) => {
  let {
    label,
    name,
    size,
    type,
    options,
    inputs,
    newInputs,
    onInputChange,
    onInputChangeByName
  } = props;
  let value = !empty(newInputs[name]) ? newInputs[name] : null;
  return (
    <Col xl={size || '6'}>
      <Form.Group controlId={name}>
        <Form.Label>{context.t(label)}</Form.Label>
        <DatePicker
          name={name}
          value={value}
          onChange={(v, f) => {
            let value;
            if (!empty(v)) {
              value = moment(v).format('YYYY-MM-DD');
            } else {
              value = moment()
                .subtract(18, 'years')
                .format('YYYY-MM-DD');
            }
            onInputChangeByName(name, value);
          }}
        />
        <Form.Control.Feedback type="invalid">
          {context.t('You must put {name}', { name: label })}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
};

const InputFile = (props, context) => {
  let {
    label,
    name,
    size,
    filetype,
    type,
    options,
    inputs,
    newInputs,
    onInputChange,
    onInputChangeByName,
    onInputFileChange,
    setBlobByName,
    imagesBlob
  } = props;
  let propper = props.props[0];
  return (
    <Col xl={size || '6'}>
      <Form.Group controlId={name}>
        <Form.Label>{context.t(label)}</Form.Label>
        {filetype === 'image' ? (
          <React.Fragment>
            {!empty(inputs[name]) ? (
              <img
                src={!empty(imagesBlob[name]) ? imagesBlob[name] : inputs[name]}
              />
            ) : (
              ''
            )}
            <CropImage
              name={name}
              onInputFileChange={onInputFileChange}
              setBlobByName={setBlobByName}
              imagesBlob={imagesBlob}
              {...propper}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!empty(inputs[name]) ? (
              <a
                href={inputs[name]}
                target="_blank"
                className="btn btn-info download">
                {`Descargar `}
                <b>{GetFilename(inputs[name])}</b>
              </a>
            ) : (
              ''
            )}
            <UploaderBnx
              name={name}
              type={type}
              {...propper}
              onInputFileChange={onInputFileChange}
            />
          </React.Fragment>
        )}
        <Form.Control.Feedback type="invalid">
          {context.t('You must put {name}', { name: label })}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
};

const InputTel = (props, context) => {
  let {
    label,
    name,
    size,
    filetype,
    type,
    options,
    inputs,
    newInputs,
    onInputChange,
    onInputChangeByName
  } = props;
  let propper = props.props[0];
  return (
    <Col xl={size || '6'}>
      <Form.Group controlId={name}>
        <Form.Label>{context.t(label)}</Form.Label>
        <PhoneInput
          inputClass={'form-pay-phone-custom'}
          onChange={value => {
            props.onInputChangeByName(name, value);
          }}
          value={newInputs[name] || ''}
          isValid={(value, country) => {
            if (value.length < 9) {
              return false;
            } else {
              return true;
            }
          }}
          inputProps={{
            id: name,
            name: name,
            requiredmessage: context.t('You must enter the phone number')
          }}
        />
        <Form.Control.Feedback type="invalid">
          {context.t('You must put {name}', { name: label })}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
};

const GenGroupGroup = (props, context) => {
  let {
    schemeForm,
    formInfo,
    newInputs,
    onInputChange,
    onInputChangeByName,
    onInputFileChange,
    setBlobByName,
    imagesBlob
  } = props;
  const getFieldFunction = (type, data, index) => {
    switch (type) {
      case 'text':
        // Obtenemos el campo de tipo texto
        return (
          <InputText
            key={index}
            {...data}
            inputs={formInfo}
            newInputs={newInputs}
            onInputChange={onInputChange}
            onInputChangeByName={onInputChangeByName}
          />
        );
      case 'number':
        // Obtenemos el campo de tipo numerico
        return (
          <InputText
            key={index}
            {...data}
            inputs={formInfo}
            newInputs={newInputs}
            onInputChange={onInputChange}
            onInputChangeByName={onInputChangeByName}
          />
        );
      case 'email':
        // Obtenemos el campo de tipo texto en email
        return (
          <InputText
            key={index}
            {...data}
            inputs={formInfo}
            newInputs={newInputs}
            onInputChange={onInputChange}
            onInputChangeByName={onInputChangeByName}
          />
        );
      case 'select':
        // Obtenemos el campo de tipo select
        return (
          <InputSelect
            key={index}
            {...data}
            inputs={formInfo}
            newInputs={newInputs}
            onInputChange={onInputChange}
            onInputChangeByName={onInputChangeByName}
          />
        );
      case 'date':
        // Obtenemos el campo de tipo fecha
        return (
          <InputDate
            key={index}
            {...data}
            inputs={formInfo}
            newInputs={newInputs}
            onInputChange={onInputChange}
            onInputChangeByName={onInputChangeByName}
          />
        );
      case 'file':
        // Obtenemos el campo de tipo fecha
        return (
          <InputFile
            key={index}
            {...data}
            inputs={formInfo}
            newInputs={newInputs}
            onInputChange={onInputChange}
            onInputChangeByName={onInputChangeByName}
            onInputFileChange={onInputFileChange}
            setBlobByName={setBlobByName}
            imagesBlob={imagesBlob}
          />
        );
      case 'tel':
        // Obtenemos el campo de tipo fecha
        return (
          <InputTel
            key={index}
            {...data}
            inputs={formInfo}
            newInputs={newInputs}
            onInputChange={onInputChange}
            onInputChangeByName={onInputChangeByName}
          />
        );
      default:
        return <React.Fragment key={index} />;
    }
  };

  let Groups = [];
  for (let i in schemeForm) {
    let g = schemeForm[i];
    let Fields = [];
    for (let f in g.fields) {
      let field = g.fields[f];
      let display = false;
      let coincidenceD = 0;
      if (!empty(field.displayOn)) {
        for (let i in field.displayOn) {
          let d = field.displayOn[i];
          if (newInputs[d.parent] === d.conditionValue) {
            coincidenceD++;
          }
        }
        if (field.displayOn.length === coincidenceD) {
          display = true;
        }
      } else {
        display = true;
      }
      let Campo = '';
      if (empty(field.displayOn) || display) {
        Campo = getFieldFunction(field.type, field, f);
      }
      if (!empty(Campo)) {
        Fields.push(Campo);
      }
    }
    Groups.push(
      <Card className="group-form" key={i}>
        <Card.Header>{g.title}</Card.Header>
        <Card.Body>
          <Alert variant={'primary'}>{g.hint.map((v, i) => v.p)}</Alert>
          <Row>{Fields}</Row>
        </Card.Body>
      </Card>
    );
  }

  return <React.Fragment>{Groups}</React.Fragment>;
};

const SimpleAutoForm = (props, context) => {
  const {
    onInputChange,
    onInputChangeByName,
    onInputFileChange,
    setBlobByName,
    imagesBlob,
    onSubmit,
    formInfo,
    newFormInfo,
    schemeForm
  } = props;
  if (empty(schemeForm) || empty(formInfo)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);

  return (
    <React.Fragment>
      <GenGroupGroup
        schemeForm={schemeForm}
        formInfo={formInfo}
        newInputs={newFormInfo}
        onInputChange={onInputChange}
        onInputChangeByName={onInputChangeByName}
        onInputFileChange={onInputFileChange}
        setBlobByName={setBlobByName}
        imagesBlob={imagesBlob}
      />
    </React.Fragment>
  );
};

SimpleAutoForm.contextTypes = {
  t: PropTypes.func.isRequired
};

GenGroupGroup.contextTypes = {
  t: PropTypes.func.isRequired
};

InputText.contextTypes = {
  t: PropTypes.func.isRequired
};

InputSelect.contextTypes = {
  t: PropTypes.func.isRequired
};

InputDate.contextTypes = {
  t: PropTypes.func.isRequired
};

InputFile.contextTypes = {
  t: PropTypes.func.isRequired
};

InputTel.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(SimpleAutoForm);
