import isEmpty from 'is-empty'
import React, { useEffect, useState } from 'react'
import BoxLimits from './BoxLimits'
import './boxLimits.css'

function BoxLimitsContainer({ type, limits }) {
    const [daily, setDaily] = useState(0)
    const [monthly, setMonthly] = useState(0)
    const [yearly, setYearly] = useState(0)
    useEffect(() => {
        if(isEmpty(limits)) return
        const dailyCurrentPer = (100 / limits[type].daily.OriginalLimit) * limits[type].daily.OriginalCurrent;
        setDaily(dailyCurrentPer)
        const monthlyCurrentPer = (100 / limits[type].monthly.OriginalLimit) * limits[type].monthly.OriginalCurrent;
        setMonthly(monthlyCurrentPer)
        const yearlyCurrentPer = (100 / limits[type].yearly.OriginalLimit) * limits[type].yearly.OriginalCurrent;
        setYearly(yearlyCurrentPer)
    }, [])

    if(isEmpty(limits)) return <></>
    return (
        <div className='box-limits-container'>
            <h2>Límites</h2>
            <div className='content-limits'>
                <BoxLimits NotionalCurrent={limits[type].daily.NotionalCurrent}
                    NotionalLimit={limits[type].daily.NotionalLimit}
                    OriginalCurrent={limits[type].daily.OriginalCurrent}
                    OriginalLimit={limits[type].daily.OriginalLimit}
                    notional_product={limits.notional_product}
                    original_product={limits.original_product}
                    amount={daily}
                    title="Diario"
                />
                <BoxLimits NotionalCurrent={limits[type].monthly.NotionalCurrent}
                    NotionalLimit={limits[type].monthly.NotionalLimit}
                    OriginalCurrent={limits[type].monthly.OriginalCurrent}
                    OriginalLimit={limits[type].monthly.OriginalLimit}
                    notional_product={limits.notional_product}
                    original_product={limits.original_product}
                    amount={monthly}
                    title="Mensual"
                />
                <BoxLimits NotionalCurrent={limits[type].yearly.NotionalCurrent}
                    NotionalLimit={limits[type].yearly.NotionalLimit}
                    OriginalCurrent={limits[type].yearly.OriginalCurrent}
                    OriginalLimit={limits[type].yearly.OriginalLimit}
                    notional_product={limits.notional_product}
                    original_product={limits.original_product}
                    amount={yearly}
                    title="Anual"
                />
            </div>
        </div>
    )
}

export default BoxLimitsContainer