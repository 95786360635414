import UtilsHelper from '../../helpers/Utils.helper';

export const getAllAvailableKpiReports = async (userId) => {
    const path = `/api/v1/kpi`;
    const configRequest = {
      userId,
      method: 'GET',
      path
    };
    return await UtilsHelper.sendRequest(configRequest);
};
