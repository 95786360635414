import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import AddIcon from "../../../images/add-icon.svg";
import DeleteIcon from "../../../images/delete-icon.svg";
import isEmpty from "is-empty";
import ComponentsHelper from "../../../helpers/Components.helper";
import { Switch } from "../Switch/Switch";
import { useModal } from "../../../hooks/useModal";
import SeriesCorrelativesController from "../../../pages/FallControllers/SeriesCorrelativeController";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import { invoicesTypes } from "../../../constants";

const AllSeriesCorrelativeData = (props, context) => {
  const {
    userInfo,
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    selectedId,
    setRows,
    getAllSeriesCorrelative,
    handleAddToggleModal
  } = props;
  const { showModal, handleToggleModal } = useModal();
  const [checkedData, setCheckedData] = useState({
    isChecked:"",
    id: "",
    message: {
      title: "",
      subtitle: ""
    },
    action: () => {},
  });


  const headerOptions = [
    {
      icon: AddIcon,
      action: handleAddToggleModal,
    },
    {
      icon: RotateRight,
      action: () => {
        getAllSeriesCorrelative();
      },
    },
  ];

  const getInfoFromJson = (data, slot) => {
    try {
      if (isEmpty(data)) return "";
      const detail = JSON.parse(data.settingsValue);
      return detail[slot];
    } catch (error) {
      return "";
    }
  };

  const CheckboxStatus = ({ data }) => {
    const toggleSwitch = () => {
      handleToggleModal();
      setCheckedData({
        isChecked: data.status,
        id: data.id,
        message: {
          title: "Activar / Desactivar comprobante",
          subtitle: `¿Desea ${
            data.status === 1 ? "desactivar" : "activar"
          } el item seleccionado?`,
        },
        action: () => handleToggleStatus(data),
      });
    };
    return (
      <Switch
        defaultChecked={data.status}
        onChange={toggleSwitch}
        id={`switch-toggle-bnx-account-${data.id}`}
      />
    );
  };

  const handleDeleteInvoice = async (id) => {
    const response = await SeriesCorrelativesController.deleteInvoice({
      userInfo,
      id
    });
    if(!response) return
    setRows(rows => rows.filter(item => item.id !== id))
  };

  const handleToggleStatus = async (data) => {
    const response = await SeriesCorrelativesController.changeStatus({userInfo,data})
    if(response){
        getAllSeriesCorrelative()
    }
  }

  const tableHeaders = [
    {
      tag: "info",
      title: " ",
      cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
      className: "minimizer no-Selection",
    },
    {
      tag: "",
      title: "Tipo",
      cell: ({ data }) => (
        <p>{invoicesTypes[data.settingsKey] || "DESCONOCIDO"}</p>
      ),
    },
    {
      tag: "",
      title: "Serie",
      cell: ({ data }) => <p>{getInfoFromJson(data, "SERIE")}</p>,
    },
    {
      tag: "",
      title: "Correlativo",
      cell: ({ data }) => <p>{getInfoFromJson(data, "CORRELATIVE")}</p>,
    },
    {
      tag: "",
      title: "Moneda",
      cell: ({ data }) => <p>{getInfoFromJson(data, "CURRENCY")}</p>,
    },
    {
      tag: "",
      title: "Activo",
      cell: ({ data }) =>  <CheckboxStatus data={data} />,
    },
    {
      tag: "",
      title: "Acciones",
      cell: ({ data }) => (
          <button
            onClick={() => {
                setCheckedData({ isChecked: data.status, id: data.id, message: {
                    title: "Borrar comprobante",
                    subtitle: `¿Desea borrar el item seleccionado?`,
                },
                action: () => handleDeleteInvoice(data.id), });
                handleToggleModal();
            }}
          >
            <img src={DeleteIcon} alt="delete icon" style={{width:"15px"}}/>
          </button>
      ),
    },
  ];

  return (
    <div className="all-withdraw-data">
      <HeaderPage
        title={context.t("Series y correlativos")}
        subTitle="Facturación / Series y correlativos"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        headers={tableHeaders}
        rowIdSelected={selectedId}
      />
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={() => {
          handleToggleModal();
        }}
        title={checkedData.message.title}
        message={checkedData.message.subtitle}
        confirmButtonProps={{
          onClick: checkedData.action,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllSeriesCorrelativeData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllSeriesCorrelativeData);
