import isEmpty from "is-empty";
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "../../../../hooks/formHooks";
import ReportTypeController from "../../../FallControllers/ReportTypeController";
import { GroupFilters } from "../../../../components/FallComponents/Filters/Filters";
import HeaderPage from "../../../../components/FallComponents/HeaderPage/HeaderPage";
import TableContainer from "../../../../components/FallComponents/Table/TableContainer";
import Download from "../../../../images/new_icons/download.svg";
import moment from "moment";

const AffiliateReport = (props, context) => {
  const { userInfo, decimalPlaces } = props;
  const filterInitialValues = {
    initDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  const { inputs: filterInputs, onInputChangeV2 } =
    useForm(filterInitialValues);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableFooters, setTableFooters] = useState([]);

  const headerOptions = [
    {
      icon: Download,
      action: () => handleDownload(),
    },
  ];

  const handleSearchFilters = () => {
    if (isEmpty(filterInputs.initDate) || isEmpty(filterInputs.endDate)) 
    return;
    ReportTypeController.AllAffiliateDataReport({
      userInfo,
      params: filterInputs,
      setLoading,
      setRows,
    });
  };

  const handleDownload = () => {
    if (isEmpty(rows)) return;
    ReportTypeController.DownloadAllAffiliate({
      rows,
      tableFooters,
    });
  };

  const tableHeaders = [
    {
      tag: "dateUtc",
      title: "DateUtc",
    },
    {
      tag: "datetimeUtc",
      title: "DatetimeUtc",
    },
    {
      tag: "dateAmericaLima",
      title: "DateAmericaLima",
      className: "table-title-v2",
    },
    {
      tag: "datetimeAmericaLima",
      title: "DatetimeAmericaLima",
    },
    {
      tag: "transactionId",
      title: "TransactionId",
    },
    {
      tag: "accountId",
      title: "AccountId",
    },
    {
        tag: "omsId",
        title: "OmsId",
    },
    {
        tag: "balance",
        title: "Balance",
    },
    {
      tag: "counterPartyAccountId",
      title: "CounterPartyAccountId",
    },
    {
        tag: "creditAmount",
        title: "CreditAmount",
    },
    {
      tag: "debitAmount",
      title: "DebitAmount",
    },
    {
      tag: "productId",
      title: "ProductId",
    },
    {
      tag: "enteredOnTicks",
      title: "EnteredOnTicks",
    },
    {
      tag: "transactionReferenceType",
      title: "TransactionReferenceType",
    },
    {
      tag: "transactionReferenceId",
      title: "TransactionReferenceId",
    },
    {
      tag: "transactionTypeOrg",
      title: "TransactionTypeOrg",
    },
    {
      tag: "timeStamp",
      title: "TimeStamp",
    },
    {
       tag: "referenceType",
       title: "ReferenceType",
    },
    {
        tag: "transactionType",
        title: "TransactionType",
    }
  ];

  const filters = [
    {
      values: {
        title: "Desde",
        name: "initDate",
        value: filterInputs.initDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Hasta",
        name: "endDate",
        value: filterInputs.endDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="beta-content-container">
            <div className="all-page-data">
              <HeaderPage
                title="Comisión por Referidos"
                subTitle="Reportes / Reporte general / Comisión por referidos"
                headerOptions={headerOptions}
              />
              <TableContainer
                rows={rows}
                loading={loading}
                headers={tableHeaders}
                footers={!isEmpty(rows) && !loading ? tableFooters : null}
                rowIdSelected={""}
                enablePagination={false}
              >
                <GroupFilters
                  handleSearchFilters={handleSearchFilters}
                  withRightSection={false}
                  inputs={filters}
                />
              </TableContainer>
            </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user:{userInfo},product:{ decimalPlaces } }) => {
    return { userInfo,decimalPlaces };
  };
  

AffiliateReport.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AffiliateReport);
