import React, { useEffect } from "react";
import isEmpty from "is-empty";
import { useState } from "react";
import DataFormatterHelper from "../../../helpers/DataFormatter.helper";
import { CustomButton } from "../CustomButton/CustomButton";
import InputElement from "../InputElement/InputElement";
import { getLevelsBeta } from "../../../pages/FallServices/LevelServices";
import ComponentsHelper from "../../../helpers/Components.helper";
import { toastTypes } from "../../../constants";

const ClaimTransactionForm = ({
  transactionData = {},
  handleClose,
  processTransaction,
  userInfo,
  extraData,
  action,
  provider
}) => {

  const [searchButton, setSearchButton] = useState(false)
  const [documentType, setDocumentType] = useState('')
  const [errors, setErrors] = useState({});

  const documentOperation = [
    { value: "01 FACTURA", name: "01 FACTURA" },
    { value: "02 RECIBO POR HONORARIOS", name: "02 RECIBO POR HONORARIOS" },
    { value: "03 BOLETA DE VENTA", name: "03 BOLETA DE VENTA" },
    { value: "07 NOTA DE CREDITO", name: "07 NOTA DE CREDITO" },
    { value: "12 TICKET", name: "12 TICKET" },
    { value: "13 DOCUMENTO BANCO", name: "13 DOCUMENTO BANCO" },
    { value: "14 RECIBO SERV PUBLICOS", name: "14 RECIBO SERV PUBLICOS" },
    { value: "TR TRANSFERNCIA", name: "TR TRANSFERNCIA" },
    { value: "OTRO", name: "OTRO" },
  ]

  const defineDocumentOperation = () => {
    try {
      return documentOperation.find((element) => element.value === transactionData.admin.document_operation).value;
    } catch (error) {
      return 'OTRO';
    }
  }

  const [transactionAdminDetails, setTransactionAdminDetails] = useState({
    category: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.category)) ? transactionData.admin.category : "",
    message: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.message)) ? transactionData.admin.message : "",
    document_date: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.document_date)) ? transactionData.admin.document_date : "",
    due_date: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.due_date)) ? transactionData.admin.due_date : "",
    payment_number: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.payment_number)) ? transactionData.admin.payment_number : "",
    document_operation: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.document_operation)) ? defineDocumentOperation() : "",
    identification_document_type: '6 RUC',
    ruc_document_type: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.identification_document_type) && transactionData.admin.identification_document_type === '6 RUC') ? `${transactionData.admin.identification_document} ${transactionData.admin.name}` : '',
    identification_document: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.identification_document)) ? transactionData.admin.identification_document : "",
    name: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.name)) ? transactionData.admin.name : "",
  });

  const allProviders = provider.map((element) => {
    return { value: `${element.ruc} ${element.businessName.toUpperCase()}`, name: element.businessName.toUpperCase() }
  })

  const searchByDocumentNumber = async () => {
    if (isEmpty(transactionAdminDetails.identification_document)) return;
    const filterConfig = { limit: 1, page: 1 };
    const filterSearch = { documentNumber: { in: transactionAdminDetails.identification_document } }
    try {
      setSearchButton(true)
      const response = await getLevelsBeta(userInfo.UserId, filterConfig, filterSearch)
      if (isEmpty(response.nodes)) {
        return ComponentsHelper.toast({
          type: toastTypes.warn,
          message: "No se encontró ningun registro.",
        });
      }
      const data = response.nodes[0];
      setTransactionAdminDetails({
        ...transactionAdminDetails,
        identification_document: data.documentNumber,
        name: `${data.firstName} ${data.secondLastName}`
      });
      const documentType = {
        'PP': '7 PASAPORTE',
        'RP': '4 CE',
        'ID': '1 DNI'
      }
      setDocumentType(documentType[data.documentType])
      delete errors["identification_document"]
      delete errors["name"]
    } catch (error) {
      return ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Error al buscar el número de documento.",
      });
    } finally {
      setSearchButton(false)
    }
  }

  const categoryInputProps = {
    values: {
      title: "Categoría *",
      value: transactionAdminDetails.category || "",
      name: "category",
      type: "text",
      required: true,
    },
    actions: {
      onChange: (target) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          category: target.value.toUpperCase(),
        });
        delete errors["category"]
      },
    },
    error: errors.category,
  };
  const messageInputProps = {
    values: {
      title: "Mensaje *",
      value: transactionAdminDetails.message || "",
      name: "message",
      type: "textarea",
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          message: ev.target.value.toUpperCase(),
        });
        delete errors["message"]
      },
    },
    error: errors.message,
  };

  const documentDateInputProps = {
    values: {
      title: "Fecha de documento *",
      value: transactionAdminDetails.document_date || "",
      name: "document_date",
      type: "date",
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          document_date: ev.value
        });
        delete errors["document_date"]
      },
    },
    error: errors.document_date,
  };
  const dueDateInputProps = {
    values: {
      title: "Fecha de vencimiento *",
      value: transactionAdminDetails.due_date || "",
      name: "due_date",
      type: "date",
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          due_date: ev.value
        });
        delete errors["due_date"]
      },
    },
    error: errors.due_date,
  };
  const paymentNumberInputProps = {
    values: {
      title: "Número de comprobante de pago *",
      value: transactionAdminDetails.payment_number || "",
      name: "payment_number",
      type: "text",
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          payment_number: ev.value
        });
        delete errors["payment_number"]
      },
    },
    error: errors.payment_number,
  };

  const documentOperationInputProps = {
    values: {
      title: "Tipo de documento de la operación *",
      value: transactionAdminDetails.document_operation || "",
      name: "document_operation",
      type: "select",
      options: documentOperation,
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          document_operation: ev.value
        });
        delete errors["document_operation"]
      },
    },
    error: errors.document_operation,
  };
  const otherDocumentOperationInputProps = {
    values: {
      title: "Documento de la operación *",
      value: transactionAdminDetails.other_document_operation || "",
      name: "other_document_operation",
      type: "text",
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          other_document_operation: ev.value.toUpperCase()
        });
        delete errors["other_document_operation"]
      },
    },
    error: errors.other_document_operation,
  };
  const identificationDocumentTypeInputProps = {
    values: {
      title: "Tipo de documento de identidad *",
      value: transactionAdminDetails.identification_document_type || "",
      name: "identification_document_type",
      type: "select",
      options: [
        { value: "OTRO", name: "DOCUMENTO DE IDENTIDAD (1 DNI - 4 CE - 7 PASAPORTE)" },
        { value: "6 RUC", name: "6 RUC" },
      ],
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          identification_document_type: ev.value
        });
        delete errors["identification_document_type"]
      },
    },
    error: errors.identification_document_type,
  };
  const rucDocumentTypeInputProps = {
    values: {
      title: "Proveedor *",
      value: transactionAdminDetails.ruc_document_type || "",
      name: "ruc_document_type",
      type: "select",
      options: allProviders,
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          ruc_document_type: ev.value
        });
        delete errors["ruc_document_type"]
      },
    },
    error: errors.ruc_document_type,
  };
  const identificationDocumentInputProps = {
    values: {
      title: "Número de documento *",
      value: transactionAdminDetails.identification_document || "",
      name: "identification_document",
      type: "number",
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          identification_document: ev.value
        });
        delete errors["identification_document"]
      },
    },
    error: errors.identification_document,
  };
  const nameInputProps = {
    values: {
      title: "Nombre *",
      value: transactionAdminDetails.name || "",
      name: "name",
      type: "text",
      required: true,
    },
    actions: {
      onChange: (ev) => {
        setTransactionAdminDetails({
          ...transactionAdminDetails,
          name: ev.value.toUpperCase()
        });
        delete errors["name"]
      },
    },
    error: errors.name,
  };

  const customErrors = () => {
    let errors = {};
    if (isEmpty(transactionAdminDetails.category)) {
      errors.category = "Campo requerido";
    }
    if (isEmpty(transactionAdminDetails.message)) {
      errors.message = "Campo requerido";
    }
    if(transactionAdminDetails.document_operation !== 'OTRO'){
      if (isEmpty(transactionAdminDetails.document_date)) {
        errors.document_date = "Campo requerido";
      }
      if (isEmpty(transactionAdminDetails.due_date)) {
        errors.due_date = "Campo requerido";
      }
      if (isEmpty(transactionAdminDetails.payment_number)) {
        errors.payment_number = "Campo requerido";
      }
      if (isEmpty(transactionAdminDetails.document_operation)) {
        errors.document_operation = "Campo requerido";
      }
    }
    if (isEmpty(transactionAdminDetails.identification_document_type)) {
      errors.identification_document_type = "Campo requerido";
    }
    if (!isEmpty(transactionAdminDetails.identification_document_type)) {
      if (transactionAdminDetails.identification_document_type === '6 RUC' && isEmpty(transactionAdminDetails.ruc_document_type)) {
        errors.ruc_document_type = "Campo requerido";
      }
      if (transactionAdminDetails.identification_document_type === 'OTRO') {
        if (isEmpty(transactionAdminDetails.identification_document)) {
          errors.identification_document = "Campo requerido";
        }
        if (isEmpty(transactionAdminDetails.name)) {
          errors.name = "Campo requerido";
        }
      }
    }
    setErrors(errors);
    return errors;
  };

  const defineValuesByRuc = (type) => {
    return type === 'id' ? transactionAdminDetails.ruc_document_type.split(' ')[0] : transactionAdminDetails.ruc_document_type.split(' ').splice(1).toString().replaceAll(',', ' ');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const errors = customErrors();
    if (!isEmpty(errors)) return;
    let paramsProcess = {
      id: extraData.id,
      details: {
        admin: {
          type: "ADMIN",
          category: transactionAdminDetails.category,
          message: transactionAdminDetails.message,
          document_date: transactionAdminDetails.document_date,
          due_date: transactionAdminDetails.due_date,
          payment_number: transactionAdminDetails.payment_number,
          identification_document: transactionAdminDetails.identification_document_type === '6 RUC' ? defineValuesByRuc('id') : transactionAdminDetails.identification_document,
          name: transactionAdminDetails.identification_document_type === '6 RUC' ? defineValuesByRuc('name') : transactionAdminDetails.name,
          document_operation: transactionAdminDetails.document_operation === 'OTRO' ? '' : transactionAdminDetails.document_operation,
          identification_document_type: transactionAdminDetails.identification_document_type === '6 RUC' ? transactionAdminDetails.identification_document_type : documentType,
          user: userInfo,
        },
      },
    };
    paramsProcess.details = new Buffer.from(
      JSON.stringify(paramsProcess.details)
    ).toString("base64");
    await processTransaction(action,paramsProcess || {});
    handleClose()
  };

  useEffect(() => {
    if (transactionAdminDetails.identification_document_type === 'OTRO') {
      setDocumentType(transactionData.admin.identification_document_type);
    }
    if (transactionAdminDetails.document_operation === 'OTRO') {
      setTransactionAdminDetails({
        ...transactionAdminDetails,
        other_document_operation: transactionData.admin.document_operation
      })
    }
  }, [])

  return (
    <div className="custom-ui">
      <h4 style={{textAlign:"center"}}>
        {extraData.payload} -{" "}
        {DataFormatterHelper.currencyFormatter({
          currency: extraData.currency,
          amount: extraData.transactionAmount,
        })}
      </h4>
      <div className="body-form">
        <InputElement {...categoryInputProps} />
        <InputElement {...messageInputProps} />
        <InputElement {...documentOperationInputProps} />
        {transactionAdminDetails.document_operation !== 'OTRO' &&
          <>
            <InputElement {...documentDateInputProps} />
            <InputElement {...dueDateInputProps} />
            <InputElement {...paymentNumberInputProps} />
          </>
        }
        {!isEmpty(transactionAdminDetails.identification_document_type) && (
          transactionAdminDetails.identification_document_type === '6 RUC' ?
            <>
              <InputElement {...rucDocumentTypeInputProps} />
            </> :
            <>
              <div className="space-form-search">
                <InputElement {...identificationDocumentInputProps} />
                <CustomButton
                  disabled={searchButton}
                  className="black"
                  onClick={searchByDocumentNumber}
                  text="Buscar"
                />
              </div>
              <InputElement {...nameInputProps} />
            </>
        )}
        <div className="container-submit-cancel-buttons">
          <CustomButton className="red" onClick={handleClose} text="Cancelar" />
          <CustomButton
            className="green"
            onClick={handleSubmit}
            text="Guardar"
          />
        </div>
      </div>
    </div>
  );
};

export default ClaimTransactionForm;
