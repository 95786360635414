import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CustomButton } from "../CustomButton/CustomButton";
import statusComponent from "../StatusComponent/StatusComponent";
import { transactionsStatus } from "../../../constants";
import DateHelper from "../../../helpers/Date.helper";
import Modal from "./BankTransactionModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import { useModal } from "../../../hooks/useModal";
import isEmpty from "is-empty";
import "./bankTransactionDetails.css";
import { useEffect } from "react";

const BankTransactionsDetails = (props, context) => {
  const {
    data,
    actions,
    userInfo,
    provider,
    editPermission
  } = props;
  const [claimTransactionDetails, setClaimTransactionDetails] = useState({});
  const [detailsJson, setDetailsJson] = useState("");
  const { showModal, handleToggleModal } = useModal();
  const { showModal: showModal2, handleToggleModal: handleToggleModal2 } =
    useModal();
  const [action, setAction] = useState({
    title: "",
    message: "",
    function: () => {},
  });

  useEffect(() => {
    let detailsJson = "";
    setDetailsJson({})
    if (isEmpty(data.claimDetails)) return;

    const detailsBase64 = data.claimDetails;
    let detailsJsonString = new Buffer.from(detailsBase64, "base64").toString(
      "ascii"
    );
    detailsJsonString = detailsJsonString.replace(/[^\x20-\x7E]+/g, "");
    try {
      detailsJson = JSON.parse(detailsJsonString);
    } catch (error) {
    }
    setDetailsJson(detailsJson);
  }, [data]);
  const HeaderSection = () => {
    return (
      <div className="header-section">
        <div>
          <h2>{data.payload}</h2>
        </div>
        <div className="badge-container-status">
          {statusComponent(data.status, true, transactionsStatus)}
        </div>
      </div>
    );
  };

  const ActionTag = ({ text, action }) => {
    return (
      <div onClick={action} className="bank-transaction-action-button">
        <p>{text}</p>
      </div>
    );
  };

  const redirectTicketsConstructor = () => {
    let resultExtra = [];
    if (!isEmpty(detailsJson) && !isEmpty(detailsJson.deposit)) {
      resultExtra.push(
        <ActionTag
          text="Ver detalles"
          action={() => actions.goToDepositTicket(detailsJson)}
        />
      );
    }

    if (!isEmpty(detailsJson) && !isEmpty(detailsJson.withdrawal)) {
      resultExtra.push(
        <ActionTag
          text="Ver detalles"
          action={() => actions.goToWithdrawalTicket(detailsJson)}
        />
      );
    }
    return resultExtra;
  };
  const buttonsContructor = () => {
    let resultExtra = [];
    if (!isEmpty(data.payload) && data.status == 1) {
      resultExtra.push(
        <CustomButton
          className="green"
          text="Asignar detalles"
          onClick={() => {
            setClaimTransactionDetails(detailsJson)
            handleToggleModal2();
            setAction({
              title: "Asignar detalles",
              message:
                "Estás seguro que quieres asignar detalles a esta transacción?",
              function: (action, data) => actions.processClaimTransactionAdmin(action,data),
              edit: false
            });
          }}
        />
      );
    }

    const validationToClaimTransactionFee =
      (!isEmpty(data.payload) &&
        data.transactionType === "DEBIT" &&
        data.status == 1) ||
      (!isEmpty(data.payload) &&
        data.transactionAmount == 0 &&
        data.status == 1);

    if (validationToClaimTransactionFee) {
      resultExtra.push(
        <CustomButton
          className="purple"
          text="Asignar comisión"
          onClick={() => {
            handleToggleModal();
            setAction({
              title: "Asignar comisión",
              message:
                "Estás seguro que quieres asignar esta transacción a comisiones?",
              function: () => actions.processClaimTransactionFee(data),
            });
          }}
        />
      );
    }

    resultExtra.push(
      <div style={{display:"flex",gap:"1rem"}}>
        <CustomButton
          className="orange"
          text="Editar transacción"
          onClick={() => {
            setAction({
              title: "Editar transacción",
              message: "Estás seguro que quieres editar esta transacción?",
              row:data,
              detailsJson,
              function:handleEditTransaction,
              edit: true
            });
            setClaimTransactionDetails(detailsJson);
            handleToggleModal2();
          }}
        />
        <CustomButton
          className="red"
          text="Borrar"
          onClick={() => {
            handleToggleModal();
            setAction({
              title: "Borrar transacción",
              message: "Estás seguro que quieres borrar esta transacción?",
              function: () => actions.processDeleteTransaction(data),
            });
          }}
        />
      </div>
    )
    return resultExtra;
  };

  const handleEditTransaction = async (action,details) => {
    await actions.processEditTransactions(action, details);
  };

  return (
    <div className="bank-transactions-details">
      <HeaderSection />
      <div className="content-tab">
        <div className="bank-transactions-actions-content">
          <h2 className="subtitle">Detalle de transacción</h2>
          <div className="slot-details">
            <div className="option">
              <p>Account ID</p>
              <p>{data.accountId}</p>
            </div>
            <div className="option">
              <p>Fecha de transacción</p>
              <p>{data.transactionDate}</p>
            </div>
            <div className="option">
              <p>Fecha de reclamación</p>
              <p>{DateHelper.formatDate(data.claimDate)}</p>
            </div>
            <div className="option">
              <p>Referencia de transacción</p>
              <p>{data.transactionReference}</p>
            </div>
            <div className="option">
              <p>Banco</p>
              <p>{data.bankName}</p>
            </div>
            <div className="option">
              <p>Cuenta bancaria</p>
              <p>{data.sourceBankAccountNumber}</p>
            </div>
            <div className="option">
              <p>Moneda</p>
              <p>{data.currency}</p>
            </div>
            <div className="option">
              <p>Monto</p>
              <p>{data.transactionAmount}</p>
            </div>
          </div>
          <h2 className="subtitle">Detalle de {!isEmpty(detailsJson.deposit) ? "depósito" : "retiro"}</h2>
          {redirectTicketsConstructor()}
          
        </div>
        {editPermission && 
          <div className="buttons-container">
            <div className="buttons-left">
              {buttonsContructor().map(item => item)}
            </div>
          </div>
        }
      </div>
      {showModal2 && (
        <Modal
          userInfo={userInfo}
          showModal={showModal2}
          handleToggleModal={() => {
            setAction({});
            handleToggleModal2();
          }}
          processTransaction={action.function}
          edit={action.edit}
          action={action}
          extraData={data}
          transactionData={claimTransactionDetails}
          provider={provider}
        />
      )}
      {showModal && (
        <ConfirmModal
          showModal={showModal}
          handleToggleModal={() => {
            handleToggleModal();
          }}
          title={action.title}
          message={action.message}
          confirmButtonProps={{
            onClick: action.function,
            text: "Si",
          }}
          cancelButtonProps={{
            text: "No",
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (global) => {
  const {
    user: { userInfo },
    product: { products },
  } = global;
  return { userInfo, products };
};

BankTransactionsDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BankTransactionsDetails);
