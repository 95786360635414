import isEmpty from "is-empty";
import xlsx from "json-as-xlsx";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getAllTransactionsDeposit, getAllTransactionsWithdrawals } from "../FallServices/CoinkTransactionsServices"
class CoinkTransactionsController {
    static handleAllCoinkTransactionsDeposit = async ({
        userInfo,
        filterConfig,
        filterSearch,
        setLoading,
        setRows,
        setTotalRows
    }) => {
        try {
            setLoading(true);
            const { limit , page } = filterConfig
            const { type } = filterSearch;
            const params = { limit, page }
            if (filterSearch && type) {
                params["depositMode"] = type.includesInsensitive;
              }
            const response = await getAllTransactionsDeposit(userInfo.UserId, params);

            if (!isEmpty(response.data)) {
                const newData = response.data.data.results.map((element) => {
                    return {
                        id:  element.id,
                        banexcoinUserProviderClientId: element.banexcoinUserProviderClientId,
                        requestId: element.requestId,
                        createdAt: element.createdAt,
                        updatedAt: element.updatedAt,
                        depositMode: element.depositMode,
                        ticketNumber: element.ticketNumber,
                        amount: element.amount,
                        status: element.status
                    }
                })
                setRows(newData);
                setTotalRows(response.data.data.info.totalCount);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static handleAllCoinkTransactionsWithdrawals= async ({
        userInfo,
        filterConfig,
        filterSearch,
        setLoading,
        setRows,
        setTotalRows
    }) => {
        try {
            setLoading(true);
            const{ limit , page } = filterConfig
            const params = { limit, page }
            const response = await getAllTransactionsWithdrawals(userInfo.UserId, params);

            if (!isEmpty(response.data)) {
                const newData = response.data.data.result.map((element) => {
                    return {
                        id:  element.id,
                        banexcoinUserProviderClientId: element.banexcoinUserProviderClientId,
                        requestId: element.requestId,
                        accountId: element.accountId,
                        createdAt: element.createdAt,
                        updatedAt: element.updatedAt,
                        ticketNumber: element.ticketNumber,
                        amount: element.amount,
                        status: element.status
                    }
                })
                setRows(newData);
                setTotalRows(response.data.data.info.totalCount);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }


    static handleDownloadAllCoinkTransactionsDeposit  = async ({
        rows
    }) => {
        const titleColumn = {
            id: "Id",
            banexcoinUserProviderClientId: "Id de Proveedor",
            requestId: "Id de solicitud",
            createdAt: "Hora y fecha de operación",
            updatedAt: "Hora y fecha de actualización",
            depositMode: "Tipo de depósito",
            ticketNumber: "Número de Ticket",
            amount: "Monto",
            status: "Estado"
        }
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: titleColumn[name],
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: rows
                }
            ];
            const settings = {
                fileName: "Reporte de Depósito Coink",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                 xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }

    static handleDownloadAllCoinkTransactionsWithdrawals  = async ({
        rows
    }) => {
        const titleColumn = {
            id: "Id",
            banexcoinUserProviderClientId: "Id de Proveedor",
            requestId: "Id de solicitud",
            accountId: "Id de cuenta",
            createdAt: "Hora y fecha de operación",
            updatedAt: "Hora y fecha de actualización",
            ticketNumber: "Número de Ticket",
            amount: "Monto",
            status: "Estado"
        }
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: titleColumn[name],
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: [
                        ...rows,
                    ]
                }
            ];
            const settings = {
                fileName: "Reporte de Retiro",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }
}
export default CoinkTransactionsController;