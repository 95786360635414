import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modal.css";
import isEmpty from "is-empty";
import { Loader } from "../Loader/Loader";
import { CustomButton } from "../CustomButton/CustomButton";

const LogsModal = (props, context) => {
  const {
    showModal,
    handleToggleModal,
    handleDownloadLogs,
    title,
    logs,
    logsLoading,
  } = props;

  return (
    <Modal className="blacklist-add-modal" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {title}
      </ModalHeader>
      <ModalBody>
        <React.Fragment>
          {logsLoading ? (
            <Loader message="Cargando logs" />
          ) : !isEmpty(logs) ? (
            <React.Fragment>
              {logs.map((log) => (
                <p>{JSON.parse(log.data).error_message}</p>
              ))}
              <br />
              <CustomButton
                text="Descargar"
                onClick={handleDownloadLogs}
                className="purple"
              />
            </React.Fragment>
          ) : (
            <p>No hay registros para mostrar</p>
          )}
        </React.Fragment>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
LogsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(LogsModal);
