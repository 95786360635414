import UtilsHelper from "../../helpers/Utils.helper";

export const getProfiles = async (userId) => {
  const path = "/api/profile";
  return await UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
};
export const getModules = async (userId) => {
  const path = "/api/module";
  return await UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
};
export const createProfile = async (userId, name) => {
  const path = "/api/profile";
  return await UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data: {
      name,
    },
  });
};
export const deleteProfile = async (userId, id) => {
  const path = `/api/profile/${id}`;
  return await UtilsHelper.sendRequest({
    userId,
    method: "DELETE",
    path
  });
};
export const updateProfile = async (userId, id,name) => {
  const path = `/api/profile/${id}`;
  return await UtilsHelper.sendRequest({
    userId,
    method: "PUT",
    path,
    data:{
      name
    }
  });
};
export const updatePermits = async (userId, data) => {
  const path = "/api/assignedModule";
  return await UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data
  });
}
export const getModulesByProfile = async (userId) => {
  const path = `/api/modulesByUser/${userId}`;
  return await UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
};
