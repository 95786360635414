import { useEffect, useState } from "react";

export const useFilter = ({
  totalRows=0,
  filterConfigUrl = {
    limit: 100,
    page: 1,
  },
}) => {
  const [filterSearch, setFilterSearch] = useState({});
  const [filterConfig, setFilterConfig] = useState(filterConfigUrl);
  const [offset, setOffset] = useState(0);
  const [showingTo, setShowingTo] = useState(1);
  const onChangeLimit = (limit) => {
    setFilterConfig({ limit: Number(limit), page: 1 });
  };

  const changePage = (num) => setFilterConfig({ ...filterConfig, page: num });

  const resetFilters = (limit = 100, search = {}) => {
    setFilterConfig({
      limit: limit,
      page: 1,
    });
    setFilterSearch(search);
  };

  useEffect(() => {
    setOffset((filterConfig.page - 1) * filterConfig.limit + 1);
  }, [filterConfig]);

  useEffect(() => {
    setShowingTo(
      filterConfig.page * filterConfig.limit > totalRows
        ? totalRows
        : filterConfig.page * filterConfig.limit
    );
  }, [filterConfig, totalRows]);
  return {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    resetFilters,
    onChangeLimit,
    setShowingTo,
    setFilterSearch,
    setFilterConfig,
  };
};
