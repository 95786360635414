import React, {useState, useEffect } from 'react';
import security from '../../../../../images/new_icons/security.svg';
import InputElement from '../../../InputElement/InputElement';
import { CustomButton } from '../../../CustomButton/CustomButton';
import { useForm } from '../../../../../hooks/formHooks';
import { Loader } from '../../../Loader/Loader';
import isEmpty from 'is-empty';
import NaturalCustomersController from '../../../../../pages/FallControllers/NaturalCustomersController';
import LegalController from '../../../../../pages/FallControllers/LegalController';
import UserPermitsController from '../../../../../pages/FallControllers/UserPermitsController';
import ComponentsHelper from '../../../../../helpers/Components.helper';
import DateHelper from '../../../../../helpers/Date.helper'
import { OPTIONS_STATUS_BLOCK } from '../../../../../constants';

export const UserBlocked = ({ userInfo, row,handleLoadLegals, levelBasicInfo, getUserDataByIdWithLogs, editPermission }) => {
  const [userLoggedData, setUserLoggedData] = useState({})
  const [accountUserInfo, setAccountUserInfo] = useState({})
  const {
      banexcoinUserid,
      banexcoinUsername,
      userLockDate,
      userLockReason,
      userLockReasonOthers,
      userLockStatus,
      id
  } = levelBasicInfo;

  let { inputs, setInputs, onInputChangeV2 } = useForm({ userLockReason: '' });

  const getAccountUserInfoById = async () => {
    await NaturalCustomersController.getAccountUserInfoById({
      userInfo,
      banexcoinUserid,
      banexcoinUserAccountId: levelBasicInfo.banexcoinUserAccountId,
      setAccountUserInfo
    });
  };
  const getUserLoggedProfile = async () => {
    UserPermitsController.getAllUsers({
      setTotalRows: () => {},
      filterConfig: { limit:1 },
      setLoading: () => {},
      setRows: setUserLoggedData,
      filterSearch: { banexcoinUserid : userInfo.UserId },
      userInfo
    });
  };


  useEffect(() => {
    inputs = {
      ...inputs,
      userLockReason,
      userLockReasonOthers
    };
    setInputs(inputs);
    getUserLoggedProfile()
    getAccountUserInfoById();
  }, []);

  const filteredBlockedReason = () => {
    if(isEmpty(userLoggedData) || isEmpty(userLoggedData[0].profileByProfileId)) return []
    
    const profileName = userLoggedData[0].profileByProfileId.name.toLowerCase()

    if(profileName.includes("cumplimiento")) return OPTIONS_STATUS_BLOCK.COMPLIANCE
    if(profileName.includes("operaciones")) return OPTIONS_STATUS_BLOCK.OPERATIONS

    return OPTIONS_STATUS_BLOCK.SUPERADMIN
  }

  const selectOptions = {
    values: {
      icon: security,
      title: 'Motivo de bloqueo',
      name: 'userLockReason',
      value: inputs.userLockReason,
      type: 'select',
      options: filteredBlockedReason(),
      placeholder: 'Seleccione un estado',
      rootClassname:"max-w-250"
    },
    actions: {
      onChange: onInputChangeV2
    }
  };

  const inputBlockByOther = {
    values: {
      title: 'Otros',
      name: 'userLockReasonOthers',
      value: inputs.userLockReasonOthers || '',
      type: 'text',
      rootClassname:"max-w-250"
    },
    actions: {
      onChange: onInputChangeV2
    }
  };

  const freezeAccount = async () => {
    await NaturalCustomersController.freezeAccount({
      levelBasicInfo,
      userInfo
    });
    getUserDataByIdWithLogs();
  };
  const unFreezeAccount = async () => {
    await NaturalCustomersController.unFreezeAccount({
      levelBasicInfo,
      userInfo
    });
    getUserDataByIdWithLogs();
  };

  const handleFreezeAlert = frozen => {
    ComponentsHelper.confirmAlert({
      title: `${frozen ? 'Descongelar' : 'Congelar'} usuario`,
      message: `¿Está seguro que desea ${
        frozen ? 'descongelar' : 'congelar'
      } este usuario? `,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => (frozen ? unFreezeAccount() : freezeAccount())
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  };
  const handleFreezeAction = () => handleFreezeAlert(accountUserInfo.Frozen);

  const handleBlock = () => {
    if(isEmpty(inputs.userLockReason)) return false 
    ComponentsHelper.confirmAlert({
      title: `${userLockStatus === 1 ? 'Desbloquear' : 'Bloquear'} usuario`,
      message: `¿Está seguro de que desea ${
        userLockStatus === 1 ? 'Desbloquear' : 'Bloquear'
      } usuario?`,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () =>
            userLockStatus === 1 ? sendUnLockUser() : sendLockUser()
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }

  const reloadData = () => {
    setAccountUserInfo(null)
    getUserDataByIdWithLogs()
    getAccountUserInfoById();
    handleLoadLegals()
  }

  const sendLockUser = async () => {
    const response = await LegalController.updatedUserBlockStatus({
      userInfo,
      type:"lock",
      data:{
        banexcoinUsername,
        kycUserId:id,
        userLockStatus:1,
        userLockReason:inputs.userLockReason,
        userLockReasonOthers:inputs.userLockReasonOthers,
        iduser:banexcoinUserid
      },
      email:row.bnxEmail,
      inputs
    });
    if(response){
      reloadData()
    }
  };
  const sendUnLockUser = async () => {
    const response = await LegalController.updatedUserBlockStatus({
      userInfo,
      type:"unlock",
      data:{
        banexcoinUsername,
        kycUserId:id,
        userLockStatus:0,
        userLockReason:"",
        userLockReasonOthers:"",
        iduser:banexcoinUserid
      },
    });
    if(response){
      reloadData()
    }
  };

  return (
    <div className="content-tab">
      {!accountUserInfo ? (
        <div className="container-loading">
          <Loader message="Cargando.." />
        </div>
      ) : !isEmpty(accountUserInfo) ? (
        <React.Fragment>
          <div className="register-data-tab">
            <div className="item-content-info mb-4">
              <p>Estado del cliente</p>
              <p>{userLockStatus === 1 ? "Bloqueado" : "Desbloqueado"}</p>
            </div>
            <br/>
            <div className="item-content-info mb-4">
              <p>Fecha de bloqueo</p>
              <p>
                { DateHelper.utcFormatter(userLockDate) || '-' }
              </p>
            </div> 
            <br/>
            {editPermission && userLockStatus !== 1 ?
              <InputElement
                values={selectOptions.values}
                actions={selectOptions.actions}
              /> :
              <div className="item-content-info mb-4">
                <p>Motivo de bloqueo</p>
                <p>{inputs.userLockReason || '-'}</p>
              </div>        
            }
            {inputs.userLockReason === 'others' && (
              <React.Fragment>
                <br />
                {editPermission ?
                  <InputElement
                    values={inputBlockByOther.values}
                    actions={inputBlockByOther.actions}
                  /> :
                  <div className="item-content-info mb-4">
                    <p>Otros</p>
                    <p>{inputs.userLockReasonOthers || ''}</p>
                  </div> 
                }
              </React.Fragment>
            )}
          </div>
          {editPermission &&
            <div className="buttons-container">
              <div className="buttons-left">
                <CustomButton
                  text={userLockStatus === 1 ? 'Desbloquear' : 'Bloquear'}
                  onClick={handleBlock}
                  className={userLockStatus === 1 ? 'green' : 'red'}
                />
                <CustomButton
                  text={accountUserInfo.Frozen ? 'Descongelar' : 'Congelar'}
                  onClick={handleFreezeAction}
                  className={accountUserInfo.Frozen ? 'black' : 'gray'}
                />
              </div>
            </div>
          }
        </React.Fragment>
      ) : (
        <h4 style={{ padding: '10px' }}>No hay información de usuario</h4>
      )}
    </div>
  );
};
