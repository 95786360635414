import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import AllCommissionsData from "../../../components/FallComponents/CommissionsPage/AllCommissionsData";
import CommissionsController from "../../FallControllers/CommissionsController";

const CommissionsPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});

  const getCommissionsByDate = async () => {
    await CommissionsController.getCommissionsByDate({
      userInfo,
      filterSearch:inputs,
      setRows,
      setLoading,
    });
  };

  const resetData = () => {
    setRows([])
    setInputs({})
  }

  useEffect(() => {
    if (isEmpty(userInfo) || isEmpty(inputs)) return;
    getCommissionsByDate();
  }, [userInfo, inputs]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllCommissionsData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            rows={rows}
            loading={loading}
            setFilterSearch={setInputs}
            resetData={resetData}
          />
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

CommissionsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(CommissionsPage);
