import React from "react";

export const Default = ({
  type,
  name,
  value,
  onChange,
  required,
  disabled,
  accept,
  title,
  className,
  ...restValues
}) => {
  return (
    <label className={`label-text ${(type === "date" || type === "datetime-local") ? "input-date" : ""}`}>
      <input
        className={`${(type === "date" || type === "datetime-local") ? "" : "input-not-date"} ${className}`}
        type={type || "text"}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target)}
        required={required}
        disabled={disabled}
        accept={accept}
        {...restValues}
      />
      <p style={{marginTop:"0", marginBottom:"0"}} className={`${type === "date" ? "date-text" : ""}`}>{title}</p>
    </label>
  );
};
