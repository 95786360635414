import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "./uploadData.json";
import PropTypes from "prop-types";

const UncontrolledLottie =(props, context) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (
      <div className="lottie-animated" >
        <Lottie options={defaultOptions} height={200} width={200}  />
        <h1 className="lottie-title">{context.t('Syncing contacts....')}</h1>
      </div>
    );

}

UncontrolledLottie.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default (UncontrolledLottie);