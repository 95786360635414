import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import TableContainer from './TableContent/TableContainer';
import { getQueuesV3CSV, getQueuesV3 } from './AcuantLogsHooks';
import * as FileSaver from 'file-saver';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const AcuantLogsPage = (props, context) => {
  const { userInfo, accounts } = props;

  if (empty(userInfo) || empty(accounts)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);
  const [rows, setRows] = useState([]);
  const [filterConfig, setFilterConfig] = useState({ limit: 100, page: 1, totalCount: 0 });
  const [filterSearch, setFilterSearch] = useState({});

  const loadRows = async (config, search) => {
    setLoading(1);
    try {
      const result = await getQueuesV3(
        userInfo.UserId,
        !empty(config) ? config : filterConfig,
        !empty(search) ? search : filterSearch
      );

      setRows(result.data.nodes);
      setFilterConfig({
        ...filterConfig,
        totalCount: result.data.totalCount
      });
    } catch (error) {
      toast.warn(context.t('Information is not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    } finally {
      setLoading(0);
    }
  }

  const downloadRows = async (config, search) => {
    setLoading(1);
    try {
      const result = await getQueuesV3CSV(
        userInfo.UserId,
        !empty(config) ? config : filterConfig,
        !empty(search) ? search : filterSearch
      );
      const csvData = new Blob([result], { type: 'text/csv;charset=utf-8;' });

      FileSaver.saveAs(csvData, 'acuant_logs.csv');
      return true;
    } catch (error) {
      toast.warn(context.t('Information is not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    } finally {
      setLoading(0);
    }
  }

  const changePage = num => {
    filterConfig.page = num;
    loadRows(filterConfig, filterSearch);
  };

  const onChangeLimit = e => {
    e.persist();
    let name = e.target.name;
    let value = Number(e.target.value);
    filterConfig.limit = value;
    filterConfig.page = 1;
    loadRows(filterConfig, filterSearch);
  };

  const searchData = () => {
    filterConfig.page = 1;
    setFilterConfig(filterConfig)
    loadRows(filterConfig, filterSearch);
  }

  const onChangeField = e => {
    e.persist();
    const type = e.target.type;
    const name = e.target.name;
    let value = { includesInsensitive: e.target.value }

    if(type === 'number' || type === 'select-one') {
      if(!isNaN(e.target.value)) {
        // @ts-ignore
        value = { in: Number(e.target.value) }
      } else {
        // @ts-ignore
        value = { in: e.target.value };
      }
    }
      
    if (!empty(e.target.value)) {
      filterSearch[name] = value;
    } else {
      if (filterSearch.hasOwnProperty(name)) {
        delete filterSearch[name];
      }
    }
    setFilterSearch(filterSearch);
  };

  function FiltersComponent() {
    return null;
  }

  useEffect(() => {
    if (!empty(userInfo)) {
      loadRows();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="content-container">
        <Loading loading={loading} />
        <Col xs="12" lg="12">
          <h1 style={{ textAlign: 'left' }}>{context.t('Acuant Logs')}</h1>
        </Col>
        {FiltersComponent()}
        <TableContainer
          setLoading={setLoading}
          loading={loading}
          filterConfig={filterConfig}
          filterSearch={filterSearch}
          rows={rows}
          loadRows={loadRows}
          downloadRows={downloadRows}
          changePage={changePage}
          onChangeLimit={onChangeLimit}
          searchData={searchData}
          onChangeField={onChangeField}
          {...props}
        />
      </div>
    </React.Fragment>
  );
};

AcuantLogsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AcuantLogsPage);
