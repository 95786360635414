import UtilsHelper from '../../helpers/Utils.helper';

export const getAcuantLogs = async (userId, filterConfig, filterSearch) => {
    const path = `/api/v3/acuant/logs`;
    const params = {
      filterConfig,
      filterSearch
    };
    const configRequest = {
      userId,
      method: 'GET',
      path,
      data: params
    };
    return await UtilsHelper.sendRequest(configRequest)
};
export const exportCSV = async (userId, filterConfig, filterSearch) => {
    const path = `/api/v3/acuant/logs/export`;
    const params = {
      filterConfig,
      filterSearch
    };
    const configRequest = {
      userId,
      method: 'GET',
      path,
      data: params
    };
    return await UtilsHelper.sendRequest(configRequest)
};