export const OPTIONS_STATUS = [
    {
      name: 'New',
      value: 'New'
    },
    {
      name: 'Accepted',
      value: 'Accepted'
    },
    {
      name: 'Rejected',
      value: 'Rejected'
    },
    {
      name: 'SystemProcessing',
      value: 'SystemProcessing'
    },
    {
      name: 'FullyProcessed',
      value: 'FullyProcessed'
    },
    {
      name: 'Failed',
      value: 'Failed'
    },
    {
      name: 'AmlProcessing',
      value: 'AmlProcessing'
    },
    {
      name: 'AmlAccepted',
      value: 'AmlAccepted'
    },
    {
      name: 'AmlRejected',
      value: 'AmlRejected'
    },
    {
      name: 'Aml Failed',
      value: 'Aml Failed'
    },
    {
      name: 'LimitsAccepted',
      value: 'LimitsAccepted'
    },
    {
      name: 'LimitsRejected',
      value: 'LimitsRejected'
    },
    {
      name: 'AdminProcessing',
      value: 'AdminProcessing'
    },
    {
      name: 'Pending',
      value: 'Pending'
    },
    {
      name: 'Limits Accepted',
      value: 'Limits Accepted'
    },
    {
      name: 'Limits Rejected',
      value: 'Limits Rejected'
    }
  ];
  