import React, { useState } from 'react';
import empty from 'is-empty';
import queryString from 'query-string';

export const PAGINATION_LIMIT = 100;

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Results
  </span>
);

export function preparePaginationOptions(page, totalSize) {
  let options = {
    page: parseInt(page),
    sizePerPage: PAGINATION_LIMIT,
    totalSize,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    alwaysShowAllBtns: true,
    showTotal: true,
    hideSizePerPage: true,
    paginationTotalRenderer: customTotal
  };
  return options;
}
