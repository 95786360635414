import React from 'react'
import ConfirmModal from '../../../../ConfirmModal/ConfirmModal'

const ConfirmAuthorizedAsset = ({ showModal, handleToggleModal, checkedData, handleDisableAssetAuthorizedById, handleUpdateAuthorizedAssetById }) => {
    const actions = {
        0: {
            text: 'activar',
            onClick: () => handleUpdateAuthorizedAssetById(checkedData.id)
        },
        1: {
            text: 'desactivar',
            onClick: () => handleDisableAssetAuthorizedById(checkedData.id)
        }
    }
    return (
        <ConfirmModal
            showModal={showModal}
            handleToggleModal={handleToggleModal}
            title="Activar / Desactivar activo para el usuario"
            message={`¿Desea ${actions[checkedData.isChecked].text} el activo del usuario?`}
            confirmButtonProps={{
                onClick: () => actions[checkedData.isChecked].onClick(),
                text: "Si"
            }}
            cancelButtonProps={{
                text: "No",
            }}
        />
    )
}

export default ConfirmAuthorizedAsset