export const alphapointAccountTypes = [
    {
        name: 'All',
        value: 'All',
    },
    {
        name: 'Asset',
        value: 'Asset',
    },
    {
        name: 'Liability',
        value: 'Liability',
    },
    {
        name: 'Receivable',
        value: 'Receivable',
    },
    {
        name: 'MarginAsset',
        value: 'MarginAsset',
    }
]