export const products = [
    {
        name:"USD",
        value:"USD"
    },
    {
        name:"PEN",
        value:"PEN"
    },
    {
        name:"BTC",
        value:"BTC"
    },
    {
        name:"ETH",
        value:"ETH"
    },
    {
        name:"USDC",
        value:"USDC"
    },
]