import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import DateHelper from "../../../helpers/Date.helper";
import { providerTypes } from "../../../constants";

const AlProvidersData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    handleSelectRow,
    selectedId,
    headerOptions
  } = props;

  const tableHeaders = [
    {
      tag: "",
      title: "Fecha de creación",
      handleSelectRow,
      cell:({data}) => <p>{DateHelper.formatDate(data.createdAt)}</p>
    },
    {
      tag: "",
      title: "Fecha de actualización",
      handleSelectRow,
      cell:({data}) => <p>{DateHelper.formatDate(data.updatedAt)}</p>
    },
    {
      tag: "ruc",
      title: "RUC",
      handleSelectRow,
    },
    {
      tag: "businessName",
      title: "Razón social",
      handleSelectRow,
    },
    {
        tag: "",
        title: "Tipo",
        handleSelectRow,
        cell:({data}) => <p>{providerTypes[data.type]}</p>
    },
  
  ];

  return (
    <div  className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Proveedores")}
        subTitle="Finanzas / Proveedores"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AlProvidersData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AlProvidersData);
