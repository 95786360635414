import { connect } from 'react-redux';
import FiatDepositModalComponent from './FiatDepositModalComponent';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import {
  MODAL_TYPES,
  openModal
} from 'apex-web/lib/redux/actions/modalActions';
import { createDepositTicket } from 'apex-web/lib/redux/actions/depositActions';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var bankInfo = {};
  if (ownProps.payload && config.Deposit[ownProps.payload.ProductSymbol]) {
    var depositBankInfo = config.Deposit[ownProps.payload.ProductSymbol];
    bankInfo = {
      name: depositBankInfo.bankName,
      wireNumber: depositBankInfo.bankWireNumber,
      accountNumber: depositBankInfo.bankAccountNumber
    };
  }
  return {
    bankInfo: bankInfo,
    showDepositIdModal: config.Deposit.showDepositIdModal
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    submitDeposit: function submitDeposit(payload) {
      return dispatch(createDepositTicket(payload));
    },
    openReferenceModal: function openReferenceModal() {
      return dispatch(
        openModal(MODAL_TYPES.DEPOSIT_REFERENCE_ID, {
          ProductSymbol: ownProps.payload.ProductSymbol
        })
      );
    }
  };
};

var container = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiatDepositModalComponent);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_FIAT_DEPOSIT
})(container);
