import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { CustomButton } from "../CustomButton/CustomButton";
import InputElement from "../InputElement/InputElement";
import { providerTypes } from "../../../constants";
import ProvidersController from "../../../pages/FallControllers/ProvidersController";
import SearchIcon from "../../../images/new_icons/search.svg";
import { LoaderButton } from "../Loader/Loader";
const rucPattern = /^(10|15|20|17)([0-9]{9})$/;

function AddProvidersForm(props) {
  const { handleToggleModal, userInfo, getAllProviders } = props;
  const [inputs, setInputs] = useState({
    type: "",
    businessName: "",
    ruc: "",
  });
  const [errors, setErrors] = useState({});
  const [fetchError, setFetchError] = useState({ error: "" });
  const [loading, setLoading] = useState(false);
  const [loadingEnterpriseByRuc, setLoadingEnterpriseByRuc] = useState(false);

  const handleInputChange = (target) => {
    setInputs({ ...inputs, [target.name]: target.value });
    delete errors[target.name];
  };

  const addProvider = async () => {
    const response = await ProvidersController.addNewProvider({
      userInfo,
      data: inputs,
      setLoading,
      setFetchError,
    });
    if (response) {
      handleToggleModal();
      getAllProviders();
    }
  };

  const customValidations = () => {
    let errors = {};
    if (isEmpty(inputs.type)) {
      errors.type = "Campo requerido";
    }
    if (isEmpty(inputs.businessName)) {
      errors.businessName = "Campo requerido";
    }
    if (inputs.type === "LOCAL" && isEmpty(inputs.ruc)) {
      errors.ruc = "Campo requerido";
    }
    if (!isEmpty(inputs.ruc) && !rucPattern.test(inputs.ruc)) {
      errors.ruc = "Formato de RUC inválido";
    }
    setErrors(errors);
    return errors;
  };

  const handleAddNewProvider = () => {
    setFetchError({ error: "" });
    const errors = customValidations();
    if (!isEmpty(errors)) return;
    addProvider();
  };

  const handleSearchEnterpriseByRuc = () => {
    setFetchError({ error: "" })
    setInputs(inputs => ({...inputs,businessName:""}))
    ProvidersController.getProviderByRuc({
      userInfo,
      setInputs,
      setLoadingEnterpriseByRuc,
      ruc: inputs.ruc,
      setFetchError
    });
  };

  const businessNameInputProps = {
    values: {
      title: "Razón social",
      value: inputs.businessName,
      name: "businessName",
      required: true,
    },
    actions: {
      onChange: (target) => {
        handleInputChange(target)
        setFetchError({ error: "" });
      },
    },
    error: errors.businessName,
  };
  const typeInputProps = {
    values: {
      title: "Tipo de proveedor",
      value: inputs.type,
      name: "type",
      type: "select",
      required: true,
      options: Object.keys(providerTypes).map((item) => ({
        name: providerTypes[item],
        value: item,
      })),
    },
    actions: {
      onChange: (target) => {
        if (target.value === "NO_DOMICILED") {
          delete inputs.ruc;
        }
        handleInputChange(target);
        setFetchError({ error: "" });
      },
    },
    error: errors.type,
  };
  const rucInputProps = {
    values: {
      title: "RUC",
      value: inputs.ruc,
      name: "ruc",
      required: true,
      rootClassname: "w-100",
    },
    actions: {
      onChange: (target) => {
        handleInputChange(target)
      }
    },
    error: errors.ruc,
  };

  return (
    <div>
      <div className="add-modal-inputs">
        <InputElement {...typeInputProps} />
        {inputs.type === "LOCAL" && (
          <>
            <br />
            <div className="flex items-center justify-between gap-1">
              <InputElement {...rucInputProps} />
              <button
                disabled={loadingEnterpriseByRuc || isEmpty(inputs.ruc)}
                className={`button-search ${loadingEnterpriseByRuc ? "disabled-button" : ""}`}
                onClick={handleSearchEnterpriseByRuc}
              >
                {loadingEnterpriseByRuc ? <LoaderButton/> :  <img src={SearchIcon} alt="" />}
              </button>
            </div>
          </>
        )}
        <br />
        <InputElement {...businessNameInputProps} />
      </div>
      {!isEmpty(fetchError.error) && (
        <div>
          <p
            className="add-rate-alert-message"
            style={{ color: "var(--red-color)" }}
          >
            {fetchError.error}
          </p>
        </div>
      )}
      <div className="container-submit-cancel-buttons">
        <CustomButton
          onClick={handleToggleModal}
          className="red"
          text="Cancelar"
          disabled={loading}
          showLoader={false}
        />
        <CustomButton
          text="Guardar"
          className="green"
          onClick={handleAddNewProvider}
          disabled={loading}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};
AddProvidersForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(AddProvidersForm);
