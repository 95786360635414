import React, { useContext, useEffect, useState } from 'react'
import isEmpty from 'is-empty';
import { connect } from 'react-redux';
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns'
import AllGeneralConfigPage from '../../../../components/FallComponents/GeneralConfigPage/AllGeneralConfigPage'
import GeneralConfigController from '../../../FallControllers/GeneralConfigController';
import { Context as ContextUser } from '../../../../providers/UserProvider'

const GeneralConfigPage = (props, context) => {

    const { userInfo } = props;

    const {
        modulesByUserProfile,
        handleWritePermission
    } = useContext(ContextUser);

    const [loaderInitialCard, setLoaderInitialCard] = useState(true);
    const [allInitialSetting, setAllInitialSetting] = useState({});
    const [editPermission, setEditPermission] = useState(false);

    const handleLoadCards = () => {
        GeneralConfigController.getAllCards({ userInfo, setAllInitialSetting, setLoaderInitialCard })
    }

    const handleSubmitB89Card = (data) => {
        GeneralConfigController.updateB89Card({ userInfo, data })
    }

    useEffect(() => {
        if (!isEmpty(userInfo) && !isEmpty(modulesByUserProfile)) {
            setEditPermission(handleWritePermission('configuraciones-generales'))
            handleLoadCards();
        }
    }, [userInfo, modulesByUserProfile])


    return (
        <div className="beta-content-container">
            <ResizableColumns
                withRightSection={false}
                minWidth='500px'
                childrenOne={
                    <AllGeneralConfigPage
                        editPermission={editPermission}
                        loaderInitialCard={loaderInitialCard}
                        allInitialSetting={allInitialSetting}
                        handleSubmitB89Card={handleSubmitB89Card}
                    />
                }
            />
        </div>
    )
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

export default connect(mapStateToProps)(GeneralConfigPage)