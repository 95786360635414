import UtilsHelper from "../../helpers/Utils.helper";

export const postAllTicketsAndInvoices = async (userId, params) => {
    const path = '/api/allticketsinvoices';
    const configRequest = {
        userId,
        method: "POST",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const postCreateCreditNoteSubmit = async (userId, params) => {
    const path = '/api/facturactiva/create-credit-note';
    const configRequest = {
        userId,
        method: "POST",
        path,
        data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getDownloadAllTicketsInvoices = async (userId) => {
    const path = '/api/download/allticketsinvoices';
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}