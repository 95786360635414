import isEmpty from 'is-empty';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import closeIcon from '../../../../images/new_icons/close-circle.svg';
import AllCategoriesAndModulesPage from '../../../../components/FallComponents/CategoriesAndModulesPage/AllCategoriesAndModulesPage';
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns';
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable';
import CategoriesController from '../../../FallControllers/CategoriesController';
import CategoryDetails from '../../../../components/FallComponents/CategoriesAndModulesPage/CategoryDetails/CategoryDetails';
import ModulesController from '../../../FallControllers/ModulesController';
import PropTypes from "prop-types";

const CategoriesAndModulesPage = (props, context) => {

    const { userInfo } = props;

    const [loading, setLoading] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [withRightSection, setWithRightSection] = useState(false);
    const [categoryId, setCategoryId] = useState('');

    const handleGetAllCategories = () => {
        CategoriesController.getAllCategories({
            userInfo,
            setLoading,
            setCategoryList
        });
    };

    const handleCreateCategory = async (name, setMessage) => {
        return await CategoriesController.createCategory({
            userInfo,
            name,
            setCategoryList,
            setMessage
        });
    };

    const handleUpdateCategory = async (name) => {
        return await CategoriesController.updateCategory({
            userInfo,
            categoryId,
            name,
            setCategoryList,
            context
        });
    };

    const handleDeleteCategory = async () => {
        return await CategoriesController.deleteCategory({
            userInfo,
            categoryId,
            setCategoryList,
            setWithRightSection,
            context
        });
    };

    const handleCreateModuleByCategoryId = async (name, setMessage) => {
        return await ModulesController.createModulesByCategoryId({
            userInfo,
            name,
            categoryId,
            setCategoryList,
            setMessage
        });
    };

    const handleUpdateModuleByCategoryId = async (name, moduleId, categoryId, setMessage, status, oldCategoryId) => {
        return await ModulesController.updateModuleByCategoryId({
            userInfo,
            name,
            moduleId,
            categoryId,
            setMessage,
            status,
            setCategoryList,
            oldCategoryId
        });
    };

    const handleDeleteModuleById = async (moduleId) => {
        return await ModulesController.deleteModuleById({
            userInfo,
            moduleId,
            setCategoryList,
            categoryId
        });
    };

    const handleSelectRow = ({ row: { id } }) => {
        setCategoryId(id);
        setWithRightSection(true);
    };

    const headerOptions = [
        {
            image: closeIcon,
            alt: 'Close option',
            action: () => { setWithRightSection(false) }
        }
    ];

    const categoryInfo = categoryList.find((data) => data.id === categoryId) || {};

    const categoryOptions = categoryList.map(data => { return { name: data.name, value: data.id } }) || [];

    useEffect(() => {
        if (!isEmpty(userInfo)) handleGetAllCategories();
    }, [userInfo]);

    return (
        <div className="beta-content-container">
            <ResizableColumns
                withRightSection={withRightSection}
                minWidth='500px'
                childrenOne={
                    <AllCategoriesAndModulesPage
                        loading={loading}
                        categoryList={categoryList}
                        reloadData={handleGetAllCategories}
                        handleCreateCategory={handleCreateCategory}
                        handleSelectRow={handleSelectRow}
                        categoryId={categoryId}
                        context={context}
                    />
                }
                childrenTwo={
                    <RightResizable
                        headerOptions={headerOptions}
                        loadingData={{
                            title: 'Cargando información',
                            isLoading: false
                        }}
                    >
                        <CategoryDetails
                            categoryInfo={categoryInfo}
                            handleUpdateCategory={handleUpdateCategory}
                            handleDeleteCategory={handleDeleteCategory}
                            handleCreateModuleByCategoryId={handleCreateModuleByCategoryId}
                            handleUpdateModuleByCategoryId={handleUpdateModuleByCategoryId}
                            handleDeleteModuleById={handleDeleteModuleById}
                            categoryOptions={categoryOptions}
                        />
                    </RightResizable>
                }
            />
        </div>
    )
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

CategoriesAndModulesPage.contextTypes = {
    t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(CategoriesAndModulesPage);