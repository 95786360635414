import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modal.css";
import AddProviderForm from "./AddProviderForm";

const AddRatesModal = (props) => {
  const {
    showModal,
    handleToggleModal,
    title,
    data,
    getAllProviders
  } = props;
  return (
    <Modal className="custom-modal-container add-rates" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {title}
      </ModalHeader>
      <ModalBody>
       <AddProviderForm data={data} handleToggleModal={handleToggleModal} getAllProviders={getAllProviders}/>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AddRatesModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AddRatesModal);
