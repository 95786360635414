import React, { useState } from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Button, Row, Col, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';

const TableContainerBeta = (props, context) => {
  const { rows, filterConfig, sendInfo, changePage, onChangeLimit } = props;

  let limit = filterConfig.limit;
  let totalItems = filterConfig.totalCount;
  let currentPage = filterConfig.page;
  let offset = (currentPage - 1) * limit + 1;
  let showingTo =
    currentPage * limit > totalItems ? totalItems : currentPage * limit;

  const [ids, setIds] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  if (empty(context)) {
    return null;
  }

  const handleToggle = ({ target }) => {
    let userId = JSON.parse(target.name).banexcoinUserByBanexcoinUserId
      .banexcoinUserid;
    const isIdPresent = ids.find(element => element === userId);
    let resultIds = [];

    if (empty(isIdPresent) && target.checked === true) {
      resultIds = ids.concat(userId);
    } else if (!empty(isIdPresent) && target.checked === false) {
      let index = ids.indexOf(userId);
      let localIds = ids;
      localIds.splice(index, 1);
      resultIds = localIds;
    }

    setIds(resultIds);
    if (resultIds.length === rows.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };

  const handleToggleAll = ({ target }) => {
    if (target.checked === true) {
      setIds(
        rows.map(row => row.banexcoinUserByBanexcoinUserId.banexcoinUserid)
      );
      setCheckedAll(true);
    } else {
      setIds([]);
      setCheckedAll(false);
    }
  };

  const PaginationField = () => {
    return (
      <div className="row react-bootstrap-table-pagination">
        <div className="col-md-3 col-xs-3 col-sm-3 col-lg-3">
          <span className="react-bootstrap-table-pagination-total">
            {context.t(
              'Showing {offset} to {showingTo} of {totalCount} Results',
              {
                offset: offset,
                showingTo: showingTo,
                totalCount: filterConfig.totalCount
              }
            )}
          </span>
        </div>
        <div className="col-md-3 col-xs-3 col-sm-3 col-lg-3">
          <span className="react-bootstrap-table-pagination-limit">
            <b>Filtrar registros por pagina</b>
            <select onChange={onChangeLimit} defaultValue={limit}>
              <option value="500">500</option>
              <option value="600">600</option>
              <option value="700">700</option>
              <option value="800">800</option>
            </select>
          </span>
        </div>
        <div className="react-bootstrap-table-pagination-list col-md-4 col-xs-4 col-sm-4 col-lg-4">
          <Pagination
            innerClass="pagination react-bootstrap-table-page-btns-ul"
            itemClass="page-item"
            linkClass="page-link"
            activePage={currentPage}
            itemsCountPerPage={limit}
            totalItemsCount={totalItems || 0}
            pageRangeDisplayed={5}
            onChange={changePage.bind(this)}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="table-banexcoin">
        <Col xl="12">
          <div
            style={{
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              paddingBottom: '20px'
            }}>
            <Button
              variant="warning"
              disabled={!empty(ids) ? false : true}
              onClick={() => {
                sendInfo(ids);
              }}>
              {context.t('Enviar data')}
            </Button>
          </div>
        </Col>
        <Table striped hover responsive>
          <thead>
            <tr>
              <th className="roleName">#</th>
              <th className="roleName">Banexcoin User Id</th>
              <th className="roleName">Banexcoin Username</th>
              <th className="roleName">Banexcoin User Account Id</th>
              <th className="roleName">Level</th>
              <th className="roleName">Email</th>
              <th className="roleName">{context.t('Created Date')}</th>
              <th className="roleName">{context.t('Person Type')}</th>
              <th>
                <input
                  style={{ boxShadow: 'none' }}
                  type="checkbox"
                  checked={checkedAll}
                  onChange={handleToggleAll}
                  key={'all'}
                  name={'all'}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {!empty(rows) ? (
              rows.map((row, i) => {
                const existCheckedFind = ids.find(
                  id =>
                    id === row.banexcoinUserByBanexcoinUserId.banexcoinUserid
                );
                const existChecked = !empty(existCheckedFind) ? true : false;
                return (
                  <tr key={i} className={'rowstatus' + row.status}>
                    <td>{i + 1 + limit * (currentPage - 1)}</td>
                    <td>
                      {row.hasOwnProperty('banexcoinUserByBanexcoinUserId')
                        ? row.banexcoinUserByBanexcoinUserId.banexcoinUserid
                        : row.banexcoinUserByBanexcoinUserId.banexcoinUserid}
                    </td>
                    <td>{row.banexcoinUsername}</td>
                    <td>{row.banexcoinUserAccountId}</td>
                    <td>{row.userLevel}</td>
                    <td>{row.hasOwnProperty('email') ? row.email : 'N/A'}</td>
                    <td>{row.createdAt.split('T')[0]}</td>
                    <td>
                      {row.hasOwnProperty('dataForm') ? 'Jurídico' : 'Natural'}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={handleToggle}
                        defaultChecked={existChecked}
                        key={Math.random()}
                        name={JSON.stringify(row)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <React.Fragment>
                <tr className="emptydata">
                  <td colSpan="12">No existen datos</td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </Table>
        <PaginationField />
      </div>
    </React.Fragment>
  );
};

TableContainerBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TableContainerBeta;
