import isEmpty from 'is-empty';
import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { assets } from '../../../../../constants';
import { CustomButton } from '../../../CustomButton/CustomButton';
import InputElement from '../../../InputElement/InputElement'
import { Switch } from '../../../Switch/Switch';
import './modal.css';

const CreateAuthorizedAsset = ({ showModal, handleToggleModal, handleCreateNewAsset }) => {

    const [accountId, setAccountId] = useState('');
    const [status, setStatus] = useState(false);
    const [authorizedBuysell, setAuthorizedBuysell] = useState(false);
    const [authorizedExchange, setAuthorizedExchange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})

    const assetAccountIdElement = {
        values: {
            title: 'Id de cuenta',
            name: 'accountId',
            value: accountId,
            type: 'number',
            required: true
        },
        actions: {
            onChange: (data) => setAccountId(data.value)
        },
        error: errors.accountId
    };

    const resetData = () => {
        setAccountId('');
        setStatus(false);
        setAuthorizedBuysell(false);
        setAuthorizedExchange(false);
        setErrors({});
    }

    const customError = () => {
        let errors = {};
        if (isEmpty(accountId)) errors.accountId = 'Campo requerido';
        setErrors(errors);
        return errors;
    }

    const submitCreateAssets = async () => {
        const errors = customError();
        if (!isEmpty(errors)) return;
        const newAccountId = Number(accountId);
        setLoading(true);
        await handleCreateNewAsset({
            accountId: newAccountId,
            status: status ? 1 : 0,
            authorizedBuysell,
            authorizedExchange
        });
        setLoading(false);
        resetData();
        handleToggleModal();
    }

    return <Modal className="modal-create-assets" isOpen={showModal}>
        <ModalHeader charCode="X" toggle={handleToggleModal}>
            Nuevo usuario autorizado
        </ModalHeader>
        <ModalBody>
            <div className='content-assets-inputs'>
                <InputElement {...assetAccountIdElement} />
                <div className='content-switch-assets'>
                    <p>Habilitar estado</p>
                    <div>
                        <Switch
                            defaultChecked={status}
                            onChange={() => setStatus(!status)}
                            id={`switch-toggle-bnx-account-assetStatus`}
                        />
                    </div>
                </div>
                <div className='content-switch-assets'>
                    <p>Habilitar en Compra/venta</p>
                    <div>
                        <Switch
                            defaultChecked={authorizedBuysell}
                            onChange={() => setAuthorizedBuysell(!authorizedBuysell)}
                            id={`switch-toggle-bnx-account-assetAuthorizedBuysell`}
                        />
                    </div>
                </div>
                <div className='content-switch-assets'>
                    <p>Habilitar en Exchange</p>
                    <div>
                        <Switch
                            defaultChecked={authorizedExchange}
                            onChange={() => setAuthorizedExchange(!authorizedExchange)}
                            id={`switch-toggle-bnx-account-assetAuthorizedExchange`}
                        />
                    </div>
                </div>
                <div className='container-submit-cancel-buttons'>
                    <CustomButton
                        onClick={handleToggleModal}
                        className="red"
                        text="Cancelar"
                        showLoader={false}
                        disabled={loading}
                    />
                    <CustomButton
                        onClick={submitCreateAssets}
                        className="purple"
                        text="Añadir activo"
                        disabled={loading}
                    />
                </div>
            </div>
        </ModalBody>
    </Modal>

}

export default CreateAuthorizedAsset