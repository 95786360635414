import React from 'react';
import PropTypes from 'prop-types';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import APIcon from 'apex-web/lib/components/common/APIcon';

import './CryptoWalletDepositWorkflowComponent.css';

var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
      for (
        var _i = arr[Symbol.iterator](), _s;
        !(_n = (_s = _i.next()).done);
        _n = true
      ) {
        _arr.push(_s.value);
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }
    return _arr;
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError(
        'Invalid attempt to destructure non-iterable instance'
      );
    }
  };
})();

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClasses = getBEMClasses('cryptowallet-deposit');

var CryptoWalletDepositWorkflowComponent = (function(_React$Component) {
  _inherits(CryptoWalletDepositWorkflowComponent, _React$Component);

  function CryptoWalletDepositWorkflowComponent() {
    _classCallCheck(this, CryptoWalletDepositWorkflowComponent);

    return _possibleConstructorReturn(
      this,
      (
        CryptoWalletDepositWorkflowComponent.__proto__ ||
        Object.getPrototypeOf(CryptoWalletDepositWorkflowComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(CryptoWalletDepositWorkflowComponent, [
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var context = this.context,
          _props = this.props,
          addressList = _props.addressList,
          product = _props.product,
          showSnack = _props.showSnack;
        // Protocol prefix for the QR Code, lowercase name with no spaces.

        var depositType = product.ProductFullName.toLowerCase().replace(
          /\W/g,
          ''
        );
        var selectedAddress = addressList.length
          ? addressList[addressList.length - 1]
          : '';

        var _destinationTagHelper = destinationTagHelper(selectedAddress),
          _destinationTagHelper2 = _slicedToArray(_destinationTagHelper, 2),
          address = _destinationTagHelper2[0],
          destinationTag = _destinationTagHelper2[1];

        return React.createElement(
          'div',
          { className: baseClasses('wrapper') },
          React.createElement(
            'div',
            { className: baseClasses('title') },
            context.t('Please read the instructions below')
          ),
          React.createElement(
            'div',
            { className: baseClasses('description') },
            context.t(
              'Depositing cryptocurrency into the exchange is safe and easy. The address below can always be used to deposit cryptocurrency into your account.'
            )
          ),
          React.createElement(
            'div',
            { className: baseClasses('frame') },
            React.createElement(
              'div',
              { className: baseClasses('qr-code') },
              React.createElement(APQRCode, {
                value: depositType + ':' + selectedAddress,
                className: baseClasses('qrcode')
              })
            ),
            React.createElement(
              'div',
              null,
              React.createElement(
                'div',
                { className: baseClasses('title') },
                context.t('Address'),
                ':'
              ),
              React.createElement(
                'div',
                {
                  'data-test': 'Address',
                  onClick: function onClick() {
                    showSnack(
                      _this2.context.t(
                        'The address has been copied to the clipboard.'
                      )
                    );
                    copyToClipboard(address);
                  },
                  className: baseClasses('address')
                },
                address,
                React.createElement(APIcon, {
                  name: 'copy',
                  customClass: baseClasses('copy-icon')
                })
              ),
              destinationTag &&
                React.createElement(
                  React.Fragment,
                  null,
                  React.createElement(
                    'div',
                    { className: baseClasses('title') },
                    this.context.t('Be sure to include this destination tag:')
                  ),
                  React.createElement(
                    'div',
                    {
                      'data-test': 'Destination tag',
                      className: baseClasses('address'),
                      onClick: function onClick() {
                        showSnack(
                          _this2.context.t(
                            'The destination tag has been copied to the clipboard.'
                          )
                        );
                        copyToClipboard(destinationTag);
                      }
                    },
                    destinationTag,
                    React.createElement(APIcon, {
                      name: 'copy',
                      customClass: baseClasses('copy-icon')
                    })
                  )
                ),
              React.createElement(
                'div',
                { className: baseClasses('description') },
                context.t(
                  'Your account will automatically update after the cryptocurrency network confirms your transaction.'
                )
              ),
              React.createElement(
                'div',
                { className: baseClasses('description') },
                context.t('The confirmation may take up to 1 hour.')
              ),
              React.createElement(
                'div',
                { className: baseClasses('description') },
                React.createElement(
                  'div',
                  null,
                  context.t('1) Send cryptocurrency to this address')
                ),
                React.createElement(
                  'div',
                  null,
                  context.t('2) Your deposit will automatically be processed')
                )
              )
            )
          )
        );
      }
    }
  ]);

  return CryptoWalletDepositWorkflowComponent;
})(React.Component);

CryptoWalletDepositWorkflowComponent.propTypes = {
  addressList: PropTypes.arrayOf(PropTypes.string),
  showSnack: PropTypes.func,
  product: PropTypes.shape({
    Product: PropTypes.string,
    ProductId: PropTypes.number,
    ProductFullName: PropTypes.string,
    ProductType: PropTypes.string
  })
};
CryptoWalletDepositWorkflowComponent.defaultProps = {
  addressList: [],
  product: { Product: '', ProductId: 0, ProductFullName: '', ProductType: '' }
};
CryptoWalletDepositWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CryptoWalletDepositWorkflowComponent;
