import UtilsHelper from "../../helpers/Utils.helper";

export const getAllAccounts = async (userId, config) => {
  try {
    const path = `/api/v1/banexcoin/accounts/${config.page}/${config.limit}`;
    const configRequest = {
      userId,
      method: "GET",
      path,
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveBanexcoinAccount = async (userId, data,typeMethod) => {
    const path = `/api/v1/banexcoin/accounts`;
    const configRequest = {
      userId,
      method: typeMethod,
      path,
      data,
      headers:{
        'Content-Type': 'multipart/form-data'
      }
    };
    return await UtilsHelper.sendRequest(configRequest);
};

export const toggleStateBanexcoinAccount = async (userId,banexcoinAccountId,state) => {
  try {
    const path = `/api/v1/banexcoin/accounts/${state}/${banexcoinAccountId}`;
    const configRequest = {
      userId,
      method: "PUT",
      path,
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCountries = async (userId) => {
  try {
    const path = "/api/countries?restricted=true";
    return UtilsHelper.sendRequest({
      userId,
      method: "GET",
      path,
    });
  } catch (error) {
    return error;
  }
};

export const getBankList = async (userId, config,countryCode) => {
  const path = `/api/v1/banexcoin/banks/${config.page}/${config.limit}?countryCode=${countryCode}`;
  const configRequest = {
    userId,
    method: "GET",
    path
  };
  return await UtilsHelper.sendRequest(configRequest);
};
