import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TableContainer from "../Table/TableContainer";
import DateHelper from "../../../helpers/Date.helper";
import SubHeaderPage from "../HeaderPage/SubHeaderPage";
import isEmpty from "is-empty";

const AllBalancesReportHistoryData = (props, context) => {
  const {
    totalRows,
    rows,
    loading
  } = props;

   const tableHeaders = [
    {
      tag: "",
      title: "Fecha de creación",
      cell:({data}) => <p>{DateHelper.formatDate(data.createdAt)}</p>
    },
    {
      tag: "",
      title: "Fecha de actualización",
      cell:({data}) => <p>{DateHelper.formatDate(data.updatedAt)}</p>
    },
    {
      tag: "",
      title: "Creado por",
      cell:({data}) => <p>{!isEmpty(data.banexcoinUserByCreatedBy) ? data.banexcoinUserByCreatedBy.banexcoinUsername : "-"}</p>
    },
    {
      tag: "",
      title: "Fecha seleccionada del reporte",
      cell:({data}) => <p>{DateHelper.formatDate(data.inputDate)}</p>
    },
    {
      tag: "status",
      title: "Estado",
    },
  ]

  return (
    <div className="space-data-table">
      <SubHeaderPage title="Historial de reportes de balance" />
      <TableContainer
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        headers={tableHeaders}
        rowIdSelected={" "}
        enablePagination={false}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllBalancesReportHistoryData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllBalancesReportHistoryData);
