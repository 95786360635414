import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getProofPaymentsByDate } from "../FallServices/ProofPaymentServices";

class ProofPaymentController {
  static getProofPaymentsByDate = async ({
    userInfo,
    setRows,
    setLoading,
    filterSearch,
  }) => {
    try {
      setLoading(true);
      const response = await getProofPaymentsByDate(
        userInfo.UserId,
        filterSearch
      );
      if (response.status !== 200) return false
      const proofOfPayments = !isEmpty(response.data) ? response.data : []
      setRows(proofOfPayments.map((item) => ({ ...item, empty: "" })));
      if(isEmpty(proofOfPayments)){
        ComponentsHelper.toast({
          type: toastTypes.warn,
          message: "No se encontraron comprobantes",
        });
      }
      return true
    } catch (error) {
      setRows([])
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
}
export default ProofPaymentController;
