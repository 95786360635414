import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import { editCorporateInfo } from './AdminLevelsHooks';
import empty from 'is-empty';
import queryString from 'query-string';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSecureGetParams } from '../../helpers/lib';
import { LEVELS_STATUS } from '../../helpers/constants';
import { preparePaginationOptions } from '../../helpers/pagination';
import { submitFormData } from '../Level/IntermediateLevelPageHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faMinusCircle,
  faQuestionCircle,
  faPaperPlane,
  faPen,
  faUserLock,
  faUserEdit,
  faSave
} from '@fortawesome/free-solid-svg-icons';
import { DatePickerInput } from 'rc-datepicker';
import PhoneInput from 'react-phone-input-2';
import { useForm } from '../../hooks/formHooks';
import Countries from '../../components/Countries';
import SimpleAutoForm from './SimpleAutoForm';
import { states } from '../../config/staticWorldContent';
import { province } from '../../config/staticPeruProv';
import { districts } from '../../config/staticPeruDist';

const GetFilename = url => {
  if (url) {
    return decodeURI(url.split('/').pop());
  }
  return '';
};

const layoutClasses = getBEMClasses('admin-levels-page');

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const AdminLevelsViewPageForm = (props, context) => {
  const {
    levelBasicInfo,
    userInfo,
    accounts,
    countries,
    setLoading,
    loading,
    getUserLevelById,
    closeSidebarForm
  } = props;

  const [originalInputs, setOriginalInputs] = useState({});
  const [imagesBlob, setImagesBlob] = useState({});

  const setBlobByName = (name, blob) => {
    setImagesBlob(imagesBlob => ({
      ...imagesBlob,
      [name]: blob
    }));
  };
  async function setValidate(val) {
    setInputs(inputs => ({
      ...inputs,
      validated: val
    }));
  }

  const onSubmitCallback = async inputs => {
    try {
      setLoading(1);
      let datos = {
        id: levelBasicInfo.id,
        banexcoin_user_id: levelBasicInfo.banexcoinUserid,
        ...inputs
      };
      let result = await editCorporateInfo(userInfo.UserId, datos);
      if (result.data.message === 'success') {
        closeSidebarForm();
        toast.success(context.t('Registro editado exitosamente'), {
          position: toast.POSITION.TOP_CENTER
        });
        window.location.reload(false);
      }
    } catch (error) {
      console.log('error', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const customValidations = inputs => {
    let errors = {};
    return errors;
  };

  let initialState = {
    validated: false
  };

  const onInputFileChange2 = e => {
    e.persist();
    if (!empty(e.target.files[0])) {
      setInputs(inputs => ({
        ...inputs,
        [e.target.name]: e.target.files[0]
      }));
    } else {
      setInputs(inputs => ({
        ...inputs,
        [e.target.name]: originalInputs[e.target.name]
      }));
    }
  };

  let {
    inputs,
    setInputs,
    onInputChange,
    onSubmit,
    onInputChangeByName,
    onInputFileChange
  } = useForm(initialState, onSubmitCallback, customValidations);

  async function setDataForms(data) {
    try {
      let form = JSON.parse(data.dataForm);
      if (!empty(form)) {
        setOriginalInputs(form);
        setInputs(form);
      }
    } catch (error) {
      console.log('error setDataForms', error);
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  useEffect(
    () => {
      if (!empty(levelBasicInfo)) {
        setDataForms(levelBasicInfo);
      }
    },
    [levelBasicInfo]
  );

  if (empty(userInfo) || empty(accounts) || empty(levelBasicInfo)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  let scheme = [
    {
      title: 'Corporate Data',
      hint: [
        {
          p: ''
        },
        {
          p:
            'To increase the level of Client to the Corporate Level, you must supply the Detailed Information of the Company, this registration must be carried out only using a Banexcoin account duly created by its current Legal Representative.',
          links: [
            {
              title: 'See limits',
              href:
                'https://soporte.banexcoin.com/support/solutions/articles/60000609834'
            }
          ]
        }
      ],
      fields: [
        {
          label: 'Denomination or Full Legal Name of Company',
          name: 'company_legal_name',
          type: 'text',
          props: [
            {
              required: false,
              autoComplete: 'off',
              placeholder: 'Enter Denomination or Full Legal Name of Company'
            }
          ]
        },
        {
          label: 'Type of Legal Entity',
          name: 'legal_entity_type',
          type: 'select',
          options: [
            { value: 'Ltd', name: 'Ltd' },
            { value: 'SE', name: 'SE' },
            { value: 'SPE', name: 'SPE' },
            { value: 'EEIG', name: 'EEIG' },
            { value: 'PLC', name: 'PLC' },
            { value: 'Corp', name: 'Corp' },

            { value: 'SA', name: 'SA' },
            { value: 'SAA', name: 'SAA' },
            { value: 'SAC', name: 'SAC' },
            { value: 'SRL', name: 'SRL' },
            { value: 'EIRL', name: 'EIRL' },
            { value: 'CA.', name: 'CA.' },

            { value: 'Non-Profit', name: 'Non-Profit (specify)' },
            { value: 'Other', name: 'Other (specify)' }
          ],
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Non-Profit',
          name: 'non_profit',
          type: 'select',
          options: [
            { value: 'Association', name: 'Association' },
            { value: 'Foundation', name: 'Foundation' },
            { value: 'Committee', name: 'Committee' },
            { value: 'Other', name: 'Other (specify)' }
          ],
          displayOn: [
            {
              parent: 'legal_entity_type',
              conditionValue: 'Non-Profit'
            }
          ],
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Other Non-Profit',
          name: 'other_non_profit',
          type: 'text',
          displayOn: [
            {
              parent: 'legal_entity_type',
              conditionValue: 'Non-Profit'
            },
            {
              parent: 'non_profit',
              conditionValue: 'Other'
            }
          ],
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Other Type of Legal Entity',
          name: 'other_legal_entity_type',
          type: 'text',
          displayOn: [
            {
              parent: 'legal_entity_type',
              conditionValue: 'Other'
            }
          ],
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Type of Taxpayer Identification Number',
          name: 'taxpayer_identification_number_type',
          type: 'select',
          options: [
            { value: 'TIN', name: 'TIN' },
            { value: 'EIN', name: 'EIN' },
            { value: 'VAT', name: 'VAT' },
            { value: 'NINO', name: 'NINO' },

            { value: 'RUC', name: 'RUC' },
            { value: 'RUT', name: 'RUT' },
            { value: 'NIF', name: 'NIF' },
            { value: 'RIF', name: 'RIF' },

            { value: 'Other', name: 'Other (specify)' }
          ],
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Other Type of Taxpayer Identification Number',
          name: 'other_taxpayer_identification_number_type',
          type: 'text',
          displayOn: [
            {
              parent: 'taxpayer_identification_number_type',
              conditionValue: 'Other'
            }
          ],
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Taxpayer Identification Number',
          name: 'taxpayer_identification_number',
          type: 'text',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label:
            'Describe Corporate Purpose / Products or Services that the company provides',
          name: 'corporate_purpose',
          type: 'text',
          size: 12,
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Enter Street / Avenue',
          name: 'company_address',
          type: 'text',
          size: '6',
          props: [
            {
              required: false,
              placeholder: 'Enter Street / Avenue'
            }
          ]
        },
        {
          label: 'Building number or name',
          name: 'corporate_building',
          type: 'text',
          size: '6',
          props: [
            {
              required: false,
              placeholder: 'Building number or name',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Interior # / Apartment # / Office #',
          name: 'corporate_apt_number',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off',
              required: false
            }
          ]
        },
        {
          label: 'Zonification / Town',
          name: 'corporate_town',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off',
              required: false
            }
          ]
        },
        {
          label: 'Country',
          name: 'country_incorporation',
          type: 'select',
          options: countries,
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'State',
          name: 'state',
          type: 'select',
          options: states[inputs.country_incorporation],
          props: [
            {
              required: false
            }
          ]
        },
        inputs.country_incorporation === 'PE'
          ? {
              label: 'City / Province',
              name: 'city',
              type: 'select',
              options: province[inputs.state],
              props: [
                {
                  required: false
                }
              ]
            }
          : {
              label: 'City / Province',
              name: 'city',
              type: 'text',
              props: [
                {
                  required: false
                }
              ]
            },
        inputs.country_incorporation === 'PE'
          ? {
              label: 'Municipality / District',
              name: 'corporate_district',
              type: 'select',
              options: districts[inputs.city],
              props: [
                {
                  required: false
                }
              ]
            }
          : {
              label: 'Municipality / District',
              name: 'corporate_district',
              type: 'text',
              props: [
                {
                  required: false
                }
              ]
            },
        {
          label: 'Zip Code',
          name: 'zip_code',
          type: 'text',
          size: '6',
          props: [
            {
              required: false,
              placeholder: 'Zip Code',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Ubigeo',
          name: 'ubigeo',
          type: 'text',
          size: '6',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Registration Date (Constitution)',
          name: 'registration_date',
          type: 'date',
          props: [
            {
              required: false,
              isNormal: true
            }
          ]
        },
        {
          label: 'Activity begin date',
          name: 'begin_date',
          type: 'date',
          props: [
            {
              required: false,
              isNormal: true,
              defaultMinDate: !empty(inputs.registration_date)
                ? inputs.registration_date
                : false
            }
          ]
        },
        {
          label: 'Linked economic group (if applicable)',
          name: 'linked_economic_group',
          type: 'text',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Company Phone',
          name: 'company_phone',
          type: 'tel',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Incomes',
          name: 'incomes',
          type: 'number',
          size: '6',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Patrimony',
          name: 'patrimony',
          type: 'number',
          size: '6',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Audited financial statements',
          name: 'audited_financial_statements',
          type: 'select',
          options: [{ value: 'Si', name: 'Yes' }, { value: 'No', name: 'No' }],
          size: '6',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Compliance officer',
          name: 'compliance_officer',
          type: 'select',
          options: [{ value: 'Si', name: 'Yes' }, { value: 'No', name: 'No' }],
          size: '6',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Institutional investor',
          name: 'institutional_investor',
          type: 'select',
          options: [{ value: 'Si', name: 'Yes' }, { value: 'No', name: 'No' }],
          size: '6',
          props: [
            {
              required: false
            }
          ]
        }
      ]
    },
    {
      title: 'Contact person information',
      hint: [
        {
          p: 'Enter information for a contact person in your company or entity.'
        }
      ],
      fields: [
        {
          label: 'Full name',
          name: 'full_name',
          type: 'text',
          size: '12',
          props: [
            {
              required: false
            }
          ]
        },
        {
          label: 'Job Position',
          name: 'job_position',
          type: 'text',
          props: [
            {
              required: false,
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Phone',
          name: 'phone',
          type: 'tel',
          props: [
            {
              required: false
            }
          ]
        }
      ]
    },
    {
      title: 'Upload Corporate documents',
      hint: [
        {
          p: 'Crop the images before uploading if you wish'
        }
      ],
      fields: [
        {
          label: 'Company Constitution Act',
          name: 'company_constitution_act',
          type: 'file',
          maxSize: 10,
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label:
                '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
            }
          ],
          props: [
            {
              className: 'btn_constitution_act',
              required: false,
              accept: '.pdf,.doc,.docx'
            }
          ]
        },
        {
          label:
            'Incorporation Document / Validity of Power of Attorney no later than 3 months',
          name: 'incorporation_document',
          type: 'file',
          maxSize: 10,
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label:
                '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
            }
          ],
          props: [
            {
              className: 'btn_constitution_act',
              required: false,
              accept: '.pdf,.doc,.docx'
            }
          ]
        },
        {
          label:
            'Receipt of Public Service (Electricity, Telephone, Water, etc. not more than 3 months)',
          name: 'receipt_public_service',
          type: 'file',
          maxSize: 10,
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label:
                '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
            }
          ],
          props: [
            {
              className: 'btn_constitution_act',
              required: false,
              accept: '.pdf,.doc,.docx'
            }
          ]
        },
        {
          label: 'Last income tax return',
          name: 'last_income_tax_return',
          type: 'file',
          maxSize: 10,
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label:
                '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
            }
          ],
          props: [
            {
              className: 'btn_constitution_act',
              required: false,
              accept: '.pdf,.doc,.docx'
            }
          ]
        },
        {
          label: 'Affidavit of Origins of funds',
          name: 'affidavit_origins_funds',
          type: 'file',
          maxSize: 10,
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label:
                '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
            }
          ],
          props: [
            {
              className: 'btn_constitution_act',
              required: false,
              accept: '.pdf,.doc,.docx'
            }
          ]
        },
        {
          label: 'RUC file',
          name: 'ruc_file',
          type: 'file',
          maxSize: 10,
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label:
                '(Supported formats: .PDF and .DOC/.DOCX, maximum size 10 MB)'
            }
          ],
          props: [
            {
              className: 'btn_constitution_act',
              required: false,
              accept: '.pdf,.doc,.docx'
            }
          ]
        },
      ]
    }
  ];

  return (
    <React.Fragment>
      <Card className="form-sidebar-beta corporate">
        <Card.Body>
          <div className="title-beta-box">
            <h3>
              Editar datos de <b>{levelBasicInfo.banexcoinUsername}</b>
            </h3>
          </div>
          <Form
            noValidate
            validated={inputs.validated}
            encType="multipart/form-data"
            onSubmit={onSubmit}
            className="form-beta corporate">
            <SimpleAutoForm
              formInfo={originalInputs}
              newFormInfo={inputs}
              schemeForm={scheme}
              onInputChange={onInputChange}
              onInputChangeByName={onInputChangeByName}
              onInputFileChange={onInputFileChange2}
              setBlobByName={setBlobByName}
              imagesBlob={imagesBlob}
            />
            <Button
              variant="success"
              type="submit"
              style={{ padding: '1em' }}
              className="submitbeta">
              <FontAwesomeIcon icon={faSave} className="spc" />
              <span>{context.t('Actualizar')}</span>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

AdminLevelsViewPageForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AdminLevelsViewPageForm);
