import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../../hooks/useFilter";
import RightResizable from "../../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../../images/new_icons/close-circle.svg";
import TxHashController from "../../../FallControllers/TxHashController";
import AllTxHashData from "../../../../components/FallComponents/TxHashPage/AllTxHashData";
import TxHashDetails from "../../../../components/FallComponents/TxHashPage/TxHashDetails/TxHashDetails";
import isEmpty from "is-empty";
import ComponentsHelper from "../../../../helpers/Components.helper";
import { Context as ContextUser } from '../../../../providers/UserProvider'

const TxHashPage = (props, context) => {
  const { userInfo } = props;

  const { 
    modulesByUserProfile,
    handleWritePermission
 } = useContext(ContextUser);

  const [withRightSection, setWithRightSection] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTicketByNumber, setLoadingTicketByNumber] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [loadingUpdateTxHash, setLoadingUpdateTxHash] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
    setFilterConfig,
    setFilterSearch,
    resetFilters
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 10,
      page: 1,
    },
  });

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const handleSetTxHash = async (newTxid, callback) => {
    let response = false;
    let type = "save";
    if (isEmpty(selectedTicket.txId)) {
      response = await TxHashController.saveTicketByNumber({
        userInfo,
        setLoadingUpdateTxHash,
        selectedTicket,
        ticketData,
        newTxid,
      });
    } else {
      type = "update";
      response = await TxHashController.updateTicketByNumber({
        userInfo,
        setLoadingUpdateTxHash,
        setTicketData,
        ticketData,
        selectedTicket,
        newTxid,
      });
    }
    if (!response) {
      ComponentsHelper.toast({
        type: "warn",
        message: "Ocurrió un error. Por favor, vuelva a intentarlo.",
      });
      return;
    }
    ComponentsHelper.toast({
      type: "success",
      message: `ID de transacción fue ${
        type === "save" ? "guardada" : "actualizada"
      } exitosamente.`,
    });
    getAllTxIds();
    callback();
  };

  const handleGetTicketByNumber = async () => {
    TxHashController.getTicketByNumber({
      userInfo,
      setLoadingTicketByNumber,
      setTicketData,
      selectedTicket,
    });
  };

  const handleSelectRow = ({ row }) => {
    setWithRightSection(true);
    setSelectedTicket(row);
  };

  const getAllTxIds = () => {
    TxHashController.getAllTxIds({
      userInfo,
      setLoading,
      setRows,
      setTotalRows,
      filterConfig,
      filterSearch
    });
  };
  useEffect(() => {
    if (isEmpty(userInfo) || isEmpty(modulesByUserProfile)) return;
    setEditPermission(handleWritePermission('tx-hash'))
    getAllTxIds();
  }, [modulesByUserProfile, userInfo, filterConfig,filterSearch]);

  useEffect(() => {
    if (isEmpty(userInfo) || isEmpty(selectedTicket)) return;
    handleGetTicketByNumber();
  }, [selectedTicket]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllTxHashData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            handleSelectRow={handleSelectRow}
            getAllTxIds={getAllTxIds}
            setFilterSearch={setFilterSearch}
            setFilterConfig={setFilterConfig}
            resetFilters={resetFilters}
          />
        }
        childrenTwo={
          <RightResizable
            loadingData={{
              title: "Cargando ticket",
              isLoading: loadingTicketByNumber,
            }}
            headerOptions={headerOptions}
          >
            <TxHashDetails
              data={ticketData}
              context={context}
              handleSetTxHash={handleSetTxHash}
              loading={loadingUpdateTxHash}
              editPermission={editPermission}
            />
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

TxHashPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(TxHashPage);
