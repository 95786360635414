import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const statusComponent = (status, withText = true, allStatuses = []) => {
  const badgeClassName = !withText ? 'badge-tooltip' : ''
  const foundRecord = allStatuses.find(l => l.id == status) || {}
  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="button-tooltip-status">{foundRecord.name}</Tooltip>
      }>
      <span
        className={`badge status-${foundRecord.name ? foundRecord.name.replaceAll(' ', '_') : ""} ${badgeClassName}`}>
        {withText && foundRecord.name}
      </span>
    </OverlayTrigger>
  );
};
export default statusComponent;
