import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import { Default, File, Month, Select, AsyncSelect, Textarea } from "./types";
import "./inputSelect.css";
import "./inputElement.css";

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};
const InputElement = (props, context) => {
  const { values, actions = {}, error } = props;
  const {
    title,
    icon,
    name,
    value,
    type,
    options,
    placeholder = "Seleccione una opción",
    rightIcon,
    helperText = "",
    required = false,
    disabled = false,
    rootClassname = "",
    id = "",
    accept = "*",
    className,
    ...restValues
  } = values;
  const { onChange = () => {}, onChangeSecondary, ...restActions } = actions;

  const renderLeftIcon = () => {
    return (
      <div className="input-element-icon">
        <img src={icon} alt="icon input" />
      </div>
    );
  };

  const renderRightIcon = () => {
    return (
      <div className="input-element-icon">
        <img
          src={rightIcon}
          alt="icon to show password"
          onClick={onChangeSecondary}
        />
      </div>
    );
  };

  const renderHelperMessages = () => {
    return !isEmpty(error) ? (
      typeof error === "object" ? (
        error.map((item) => (
          <p style={{ color: "var(--red-color)" }}>{context.t(item)}</p>
        ))
      ) : (
        <p style={{ color: "var(--red-color)" }}>{context.t(error)}</p>
      )
    ) : (
      !isEmpty(helperText) && helperText.map((item) => <p>{context.t(item)}</p>)
    );
  };

  const inputElementClassName = `input-element ${icon ? "first" : "second"} ${
    !isEmpty(error) ? "error-input-style" : ""
  } ${type === "textarea" ? "input-element-textarea" : ""} `

  const renderInputByType = () => {
    switch (type) {
      case "textarea": {
        return (
          <Textarea
            error={error}
            context={context}
            title={title}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
          />
        );
      }
      case "select": {
        return (
          <Select
            title={title}
            type={type}
            disabled={disabled}
            options={options}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            {...restValues}
          />
        );
      }
      case "asyncSelect": {
        return (
          <AsyncSelect
            title={title}
            type={type}
            disabled={disabled}
            options={options}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            {...restValues}
          />
        );
      }
      case "file": {
        return (
          <File
            id={id}
            name={name}
            title={title}
            onChange={onChange}
            accept={accept}
          />
        );
      }
      case "month": {
        return (
          <Month
            name={name}
            title={title}
            onChange={onChange}
            required={required}
            value={value}
          />
        );
      }
      default: {
        return (
          <Default
            title={title}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            type={type}
            accept={accept}
            disabled={disabled}
            className={className}
            {...restValues}
            {...restActions}
          />
        );
      }
    }
  };
  return (
    <div className={rootClassname}>
      <div
        className={inputElementClassName}
      >
        {icon && renderLeftIcon()}
        {renderInputByType()}
        {rightIcon && renderRightIcon()}
      </div>
      {renderHelperMessages()}
    </div>
  );
};

InputElement.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(InputElement);
