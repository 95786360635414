import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../images/new_icons/close-circle.svg";
import AllDepositLimitsData from "../../../components/FallComponents/DepositLimitsPage/AllDepositLimitsData";
import DepositLimitsController from "../../FallControllers/DepositLimitsController";
import DepositLimitsDetails from "../../../components/FallComponents/DepositLimitsPage/DepositLimitsDetails/DepositLimitsDetails";
import { useModal } from "../../../hooks/useModal";
import Modal from "../../../components/FallComponents/DepositLimitsPage/AddModal";

const DepositLimitsSettingsPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAddLimit, setLoadingAddLimit] = useState(false);
  const [selectedLimit, setSelectedLimit] = useState({});
  const { showModal, handleToggleModal } = useModal();
  const [adminUserId, setAdminUserId] = useState(null);
  const [logs, setLogs] = useState([]);

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const getAllDepositLimits = async () => {
    const response = await DepositLimitsController.getAllLimits({
      userInfo,
      setRows,
      setLoading,
    });
    if (!response) return;
    setWithRightSection(false);
  };

  const handleSelectRow = async ({ row }) => {
    setWithRightSection(true);
    setSelectedLimit(row);
    await DepositLimitsController.getLogs({userInfo,id:row.id,setLogs})
  };
  const handleUpdateLimit = async ({ data, newAmounts = {}, checkedData }) => {
    let amountsToSend = newAmounts;
    let checkedDataToSend = checkedData;

    if (isEmpty(amountsToSend)) {
      const limitById = rows.find((row) => row.id === checkedData.id);
      amountsToSend = {
        dailyAmount: limitById.dailyAmount,
        monthlyAmount: limitById.monthlyAmount,
      };
    }

    if (isEmpty(checkedDataToSend)) {
      checkedDataToSend = { active: data.active };
    }

    return await DepositLimitsController.updateLevelLimits({
      userInfo,
      levelLimitId: selectedLimit.id || checkedData.id,
      newAmounts: amountsToSend,
      checkedData: checkedDataToSend,
      adminUserId,
      oldData:data
    });
  };

  const handleAddNewLimits = async (inputs) => {
    const newAmounts = {
      dailyAmount: Number(inputs.daily),
      monthlyAmount: Number(inputs.monthly),
    };
    await DepositLimitsController.addLevelLimit({
      userInfo,
      newAmounts,
      userLevel: inputs.level,
      setLoadingAddLimit,
      adminUserId,
      oldData:selectedLimit
    });
    handleToggleModal()
  };

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllDepositLimits();
    DepositLimitsController.getUser({userInfo,id:userInfo.UserId,setUserId:setAdminUserId})
  }, [userInfo]);

  return (
    <React.Fragment>
      <div className="beta-content-container">
        <ResizableColumns
          withRightSection={withRightSection}
          childrenOne={
            <AllDepositLimitsData
              setWithRightSection={setWithRightSection}
              withRightSection={withRightSection}
              rows={rows}
              loading={loading}
              handleSelectRow={handleSelectRow}
              getAllDepositLimits={getAllDepositLimits}
              selectedId={selectedLimit.id}
              handleUpdateLimit={handleUpdateLimit}
              handleAddDepositLimitModal={handleToggleModal}
            />
          }
          childrenTwo={
            <RightResizable
              loadingData={{
                title: "Cargando información",
                isLoading: false,
              }}
              headerOptions={headerOptions}
            >
              <DepositLimitsDetails
                data={selectedLimit}
                context={context}
                handleUpdateLimit={handleUpdateLimit}
                getAllDepositLimits={getAllDepositLimits}
                logs={logs}
              />
            </RightResizable>
          }
        />
      </div>
      {showModal && (
        <Modal
          showModal={showModal}
          handleAddDepositLimitModal={handleToggleModal}
          handleSubmitInputs={handleAddNewLimits}
          loading={loadingAddLimit}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

DepositLimitsSettingsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DepositLimitsSettingsPage);
