import isEmpty from 'is-empty';
import apex from 'apex-web/lib/apex';
import {
  acceptWithdraw,
  bankTransactions,
  freezeBankTransactions,
  getLevelByAccountId,
  getWithdraws,
  getWithdrawTicket,
  rejectWithdraw,
  submitComment,
  updatetWithdraw,
  getBankListByCurrencyCode
} from '../FallServices/WithdrawServices';
import DataFormatterHelper from '../../helpers/DataFormatter.helper';
import moment from 'moment';
import { currencyAssetNames } from '../../helpers/lib';
import ComponentsHelper from '../../helpers/Components.helper';
import { types } from '../../helpers/constants';

class WithdrawController {
  static getWithdraws = async ({
    setLoading,
    userInfo,
    setRows,
    setTotalRows,
    totalRows,
    filterConfig,
    filterSearch,
    context
  }) => {
    setLoading(true);
    try {

      if((filterSearch.hasOwnProperty("StartTimestamp") && !filterSearch.hasOwnProperty("EndTimestamp")) || 
        (!filterSearch.hasOwnProperty("StartTimestamp") && filterSearch.hasOwnProperty("EndTimestamp"))
        ) {
        return ComponentsHelper.toast({ type: types.warn, message: "Seleccione un rango de fecha adecuado."})
      }

      const filter = {...filterSearch}

      if(filterSearch.hasOwnProperty("StartTimestamp")){
        filter.StartTimestamp = moment(filterSearch.StartTimestamp).toISOString()
        filter.EndTimestamp = moment(filterSearch.EndTimestamp).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        }).toISOString()
      }
      const params = {
        ...filter,
        page: filterConfig.page
      };
      let result = await getWithdraws(userInfo.UserId, params);
      
      if (isEmpty(result)){
        setRows(newResult);
        return []
      } 
      if(!isEmpty(filterSearch)){
        Object.keys(filterSearch).forEach(element => {
          if (!isEmpty(filterSearch[element])) {
            result = result.filter(r => r[element] ? String(r[element]).toLowerCase() === String(filterSearch[element]).toLowerCase() : r);
          }
        });
      }
      const newResult = result.map(data => {
        const withdrawInfo = DataFormatterHelper.jsonClean(data.WithdrawTransactionDetails);
        return {
          ...data,
          bankNameDestination: withdrawInfo.bankNameDestination,
          id: data.RequestCode
        };
      });
      setRows(newResult);
      if (totalRows === 0 && !isEmpty(newResult)) {
        setTotalRows(newResult[0].TicketNumber);
      }
    } catch (error) {
      ComponentsHelper.toast({ type: types.warn, message: context.t('Information could not accesible') })
    } finally {
      setLoading(false);
    }

  };
  static getWithdrawById = async ({
    userInfo,
    withdrawInfo,
    setLoadingInfoById,
    context,
    setWithdrawTicketData,
    setLevelData
  }) => {
    setLoadingInfoById(true);
    try {
      const result = await getWithdrawTicket(
        userInfo.UserId,
        withdrawInfo.AccountId,
        withdrawInfo.RequestCode,
      );
      setWithdrawTicketData(result)
      const levelResult = await getLevelByAccountId(
        userInfo.UserId,
        withdrawInfo.AccountId,
      );
      const levelData = (!isEmpty(levelResult) && !isEmpty(levelResult.levelCorporate)) ? levelResult.levelCorporate : levelResult.level
      setLevelData(levelData)
    } catch (error) {
      ComponentsHelper.toast({ type: types.warn, message: context.t('Information could not accesible') })
    } finally {
      setLoadingInfoById(false);
    }
  };
  static getProducts = async ({ setProductList, context }) => {
    try {
      const res = await apex.connection.GetProducts({
        OMSId: 1
      });
      if (!isEmpty(res)) {
        const newRes = res.map(data => {
          return {
            name: data.ProductFullName,
            value: data.ProductId
          };
        });
        setProductList(newRes);
      }
    } catch (error) {
      ComponentsHelper.toast({ type: types.warn, message: context.t('Failed get products') })
    }
  };
  static findTransaction = async ({
    setLoading,
    ticket,
    userInfo,
    setTransactions,
    getCurrencyByBankAccount,
    setTransactionsByCurrency,
    context
  }) => {
    try {
      setLoading(true);
      const body = {
        transactionType: 'DEBIT',
        amount: ticket.Amount,
        status: 1,
        path: '/api/v1/bank/transactions'
      };

      const result = await bankTransactions(userInfo.UserId, body)

      if (result.data.total === 0) {
        ComponentsHelper.toast({ type: types.warn, message: context.t("There are not transactions") })
        return false
      }
      const currency = currencyAssetNames.find(a => a.assetName === ticket.AssetName);
      const withdrawalCurrency = !isEmpty(currency) ? currency.currency : ''
      let  transactionsByCurrency = []
      if(!isEmpty(result.data.data)){
         transactionsByCurrency = result.data.data.filter(t => withdrawalCurrency === getCurrencyByBankAccount(t.sourceBankAccountNumber));
      }
      setTransactionsByCurrency(transactionsByCurrency)
      setTransactions(result.data.data);
      return transactionsByCurrency.length > 0 ? true : false
    } catch (e) {
      ComponentsHelper.toast({ type: types.warn, message: context.t(`${e}`) })
      return false
    } finally {
      setLoading(false);
    }

  }
  static processTransaction = async ({
    ticket,
    userInfo,
    transactionId,
    setLoading,
    setTransactions,
    context
  }) => {
    try {
      const claimDetails = {
        withdrawal: ticket,
        user: userInfo
      };

      const body = {
        id: transactionId,
        reference: `${ticket.TicketNumber}`,
        details: new Buffer.from(JSON.stringify(claimDetails)).toString('base64'),
        path: '/api/v1/bank/freezedeposit'
      };
      setLoading(true);
      await freezeBankTransactions(userInfo.UserId, body)
      ComponentsHelper.toast({ type: types.success, message: context.t('Ticket updated') })
      setTransactions([]);
      return true
    } catch (e) {
      ComponentsHelper.toast({ type: types.warn, message: context.t(`Error processing withdrawal ticket`) })
      return false
    } finally {
      setLoading(false);
    }
  }
  static acceptWithdrawByAdmin = async ({
    ticket,
    userInfo,
    aditionalInfo,
    context,
    setLoading
  }) => {
    
    try {
      setLoading(true)
      const {file,ipr_img,ipr_img2,...restaditionalInfo  } = aditionalInfo
       
       const data ={
        accountId: ticket.AccountId,
        requestCode: ticket.RequestCode,
        status: 'Accepted',
        aditional_info: JSON.stringify(restaditionalInfo),
       }
      
       const formData = new FormData();
      Object.keys(data).forEach((item) => {
        formData.append(item, data[item]);
      });
      
      if(!isEmpty(aditionalInfo.ipr_img)){
        formData.append("ipr_img", ipr_img);
      }
      if(!isEmpty(aditionalInfo.ipr_img2)){
        formData.append("ipr_img2", ipr_img2);
      }

      let status = true
      const result = await acceptWithdraw(userInfo.UserId, formData);
      const messageType = result.data.message
      if (messageType === "error") {
        status = false
      }
      const returnMessage = () => {
        let result = "Can not update the ticket status"
        if (messageType === "success") {
          result = "Succesfully updated"
        } else if (messageType !== "error") {
          result = 'Ticket updated on Alphapoint but invoice not updated'
        }
        return result
      }
      ComponentsHelper.toast({ type: messageType, message: returnMessage() })
      return status
    } catch (error) {
      ComponentsHelper.toast({ type: types.warn, message: context.t("Information could not be saved") })
      return false;
    } finally {
      setLoading(false)
    }
  }
  static rejectWithdrawByAdmin = async ({
    toStatusName,
    setLoading,
    ticket,
    userInfo,
    handleCloseModal,
    context,
    rejectReason,
    aditionalInfo
  }) => {
    try {
      setLoading(true);
      const {file,ipr_img,ipr_img2,...restaditionalInfo  } = aditionalInfo
      let data = {
        accountId: ticket.AccountId,
        requestCode: ticket.RequestCode,
        status: toStatusName,
        rejectReason,
      };
      if (aditionalInfo) {
        data = { ...data, aditional_info: JSON.stringify(restaditionalInfo) }
      }
      const formData = new FormData();
      Object.keys(data).forEach((item) => {
        formData.append(item, data[item]);
      });
      
      if(!isEmpty(aditionalInfo.ipr_img)){
        formData.append("ipr_img", ipr_img);
      }

      if(!isEmpty(aditionalInfo.ipr_img2)){
        formData.append("ipr_img2", ipr_img2);
      }
      let status = true

      const result = await rejectWithdraw(userInfo.UserId, formData);
      const messageType = result.data.message
      ComponentsHelper.toast({ type: messageType, message: context.t('Successful ticket rejection') })
      if (messageType === "success") {
        handleCloseModal();
      }
      return true
    } catch (error) {
      ComponentsHelper.toast({ type: types.warn, message: context.t('Information could not be saved') })
      return false
    } finally {
      setLoading(false);
    }
  };
  static sendNewComment = async ({
    setLoadingComments,
    userInfo,
    comment,
    context
  }) => {
    try {
      setLoadingComments(true);
      await submitComment(userInfo.UserId, comment);
      ComponentsHelper.toast({ type: types.success, message: context.t('The comment was send successfully') })
      return true
    } catch (error) {
      ComponentsHelper.toast({ type: types.success, message: context.t('The comment was send successfully') })
      return false
    } finally {
      setLoadingComments(false);
    }
  }
  static updateWithdrawByAdmin = async ({
    setLoading,
    userInfo,
    ticket,
    processInputs,
    context
  }) => {
    try {
      setLoading(true);
      const {file,ipr_img,ipr_img2,...restaditionalInfo  } = processInputs
      const data ={
                    accountId: ticket.AccountId,
                    requestCode: ticket.RequestCode,
                    status: 'Accepted',
                    aditional_info: JSON.stringify(restaditionalInfo),
                  }

      const formData = new FormData();
      Object.keys(data).forEach((item) => {
        formData.append(item, data[item]);
      });
      
      if(!isEmpty(processInputs.ipr_img)){
        formData.append("ipr_img", ipr_img);
      }

      if(!isEmpty(processInputs.ipr_img2)){
        formData.append("ipr_img2", ipr_img2);
      }

      let status = true
      const result = await updatetWithdraw(
        userInfo.UserId,
        ticket.RequestCode,
        formData,
      );
      if (!isEmpty(result.data.actionLog)) {
        ComponentsHelper.toast({ type: types.success, message: context.t('Succesfully updated') })
      } else {
        ComponentsHelper.toast({ type: types.error, message: context.t('Can not update the ticket status') })
      }
    } catch (error) {
      ComponentsHelper.toast({ type: types.error, message: context.t('Information could not be saved') })
    } finally {
      setLoading(false);
    }
  }

  static getListBanks = async ({
    userInfo,
    currencyCode,
    setLoading,
    context
  }) => {
    try {
      setLoading(true);
      const result = await getBankListByCurrencyCode(
        userInfo.UserId,
        currencyCode
      );
      if (!isEmpty(result.data.data)) {
       return result;
      } 
    } catch (error) {
      ComponentsHelper.toast({ type: types.error, message: context.t('Failed get Banks') })
    } finally {
      setLoading(false);
    }
  }     
}

export default WithdrawController;
