import React from "react";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import BankingMovementsToolPage from "../../BankingMovementsTool/BankingMovementsToolPage";

function BankingMovementsPage() {
  return (
    <div className="beta-content-container">
      <ResizableColumns
        childrenOne={<BankingMovementsToolPage />}
      />
    </div>
  );
}

export default BankingMovementsPage;
