import React from 'react'
import { Alert } from 'react-bootstrap'
export const BankDetails = ({ ticket, validationToShowWithdrawTemplate, withdrawTemplatesTypes, withdrawTemplate, context }) => {
    return (
        <React.Fragment>
            {ticket.Status !== "Pending2Fa" && validationToShowWithdrawTemplate() ?
                withdrawTemplatesTypes[withdrawTemplate.accountTransferType] :
                <Alert variant={"warning"}>
                    <h3>
                        {context.t("To view the data of this withdrawal, the status of the ticket must be approved by the client")}
                    </h3>
                </Alert>}
        </React.Fragment>
    )
}