import React, { useState } from 'react'
import DataFormatterHelper from '../../../helpers/DataFormatter.helper'
import isEmpty from 'is-empty';
import InputElement from '../InputElement/InputElement';
import { CustomButton } from '../CustomButton/CustomButton';

const TicketTransacionForm = ({
    transactionData = {},
    extraData,
    handleClose,
    userInfo,
    processTransaction,
    action
}) => {

    const [transactionAdminDetails, setTransactionAdminDetails] = useState({
        category: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.category)) ? transactionData.admin.category : "",
        payment_number: (!isEmpty(transactionData.admin) && !isEmpty(transactionData.admin.payment_number)) ? transactionData.admin.payment_number : "",
        document_operation: '12 TICKET'
    });
    const [errors, setErrors] = useState({});

    const ticketType = [
        { value: "DEPOSIT", name: "Depósito" },
        { value: "WITHDRAW", name: "Retiro" },
    ]

    const documentOperationInputProps = {
        values: {
            title: "Tipo de ticket *",
            value: transactionAdminDetails.category || "",
            name: "category",
            type: "select",
            options: ticketType,
            required: true,
        },
        actions: {
            onChange: (ev) => {
                setTransactionAdminDetails({
                    ...transactionAdminDetails,
                    category: ev.value
                });
                delete errors["category"]
            },
        },
        error: errors.category,
    };

    const paymentNumberInputProps = {
        values: {
            title: "Número de ticket *",
            value: transactionAdminDetails.payment_number || "",
            name: "payment_number",
            type: "text",
            required: true,
        },
        actions: {
            onChange: (ev) => {
                setTransactionAdminDetails({
                    ...transactionAdminDetails,
                    payment_number: ev.value
                });
                delete errors["payment_number"]
            },
        },
        error: errors.payment_number,
    };

    const customErrors = () => {
        let errors = {};
        if (isEmpty(transactionAdminDetails.category)) {
            errors.category = "Campo requerido";
        }
        if (isEmpty(transactionAdminDetails.payment_number)) {
            errors.payment_number = "Campo requerido";
        }
        setErrors(errors);
        return errors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const errors = customErrors();
        if (!isEmpty(errors)) return;
        let paramsProcess = {
            id: extraData.id,
            details: {
                admin: {
                    type: "ADMIN",
                    category: transactionAdminDetails.category,
                    payment_number: transactionAdminDetails.payment_number,
                    document_operation: transactionAdminDetails.document_operation,
                    user: userInfo,
                },
            },
        };
        paramsProcess.details = new Buffer.from(
            JSON.stringify(paramsProcess.details)
        ).toString("base64");
        await processTransaction(action, paramsProcess || {});
        handleClose()
    }

    return (
        <div className="custom-ui">
            <h4 style={{ textAlign: "center" }}>
                {extraData.payload} -{" "}
                {DataFormatterHelper.currencyFormatter({
                    currency: extraData.currency,
                    amount: extraData.transactionAmount,
                })}
            </h4>
            <div className="body-form">
                <InputElement {...documentOperationInputProps} />
                <InputElement {...paymentNumberInputProps} />
                <div className="container-submit-cancel-buttons">
                    <CustomButton className="red" onClick={handleClose} text="Cancelar" />
                    <CustomButton
                        className="green"
                        onClick={handleSubmit}
                        text="Guardar"
                    />
                </div>
            </div>
        </div>
    )
}

export default TicketTransacionForm