import React from "react";
import DateHelper from "../../../../../helpers/Date.helper";
import Table from "../../../Table/Table";

export const History = ({ logs }) => {

  const descriptionConstructor = (description) => {
    try {
      const descriptionParse = description.
      slice(1,-1).
      split(",")
      return <><strong>Actual: </strong>{descriptionParse[1]!== "null" ? descriptionParse[1] : "-"} <br/> <strong>Anterior: </strong>{descriptionParse[0]!== "null" ? descriptionParse[0] : "-"}</>
    } catch (error) {
      return ""
    }
  }
  const headers = [
    {
      tag: "type",
      title: "Acción",
    },
    {
      tag: "executeByName",
      title: "Ejecutado por",
    },
    {
      tag: "",
      title: "Descripción",
      cell:({data}) => <p>{descriptionConstructor(data.description)}</p>
    },
    {
      tag: "",
      title: "Fecha",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className="table-wrapper">
        <Table
          showTooltipToCopy={false}
          rowIdSelected=""
          rowHead={headers}
          dataBody={logs}
        />
      </div>
    </div>
  );
};
