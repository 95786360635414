import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  getDepositAlertsByDate,
  getDepositAlertsByAccountId,
  updateAlertStatus,
  getLogs,
  getUser,
} from "../FallServices/DepositAlertsServices";

class DepositAlertsController {
  static getDepositAlertsByDate = async ({
    userInfo,
    date,
    setRows,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const response = await getDepositAlertsByDate(userInfo.UserId, date);
      if (!isEmpty(response.data)) {
        const alertsSortByUpdatedAt = !isEmpty(response.data.result)
          ? response.data.result.sort(
              (a, b) =>
                new Date(b.updatedAt) -
                new Date(a.updatedAt)
            )
          : [];
        setRows(alertsSortByUpdatedAt);
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static getDepositAlertsByAccountId = async ({
    userInfo,
    date,
    setLoadingDepositsByAccount,
    setDepositsByAccount,
    accountId,
  }) => {
    try {
      setLoadingDepositsByAccount(true);
      const response = await getDepositAlertsByAccountId(
        userInfo.UserId,
        date,
        accountId
      );
      if (!isEmpty(response.data)) {
        setDepositsByAccount(response.data.result);
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoadingDepositsByAccount(false);
    }
  };
  static updateAlertStatus = async ({userInfo,data,amlAlertCode,newStatus,adminId,newValues,oldValues,updatedColumns}) => {
    try {
      let oldValues = [];
      let newValues = [];
      let columns = [];
      const newDataToLog = {alertStatus:newStatus, amlAlertCode}
        Object.keys(newDataToLog).map((item) => {
          if (data[item] !== newDataToLog[item]) {
            oldValues.push(data[item]);
            newValues.push(newDataToLog[item]);
            columns.push(item);
          }
        });
      const newData = {
        user_id: data.userId,
        user_name: data.userName,
        user_account_id: data.userAccountId,
        user_level: data.userLevel,
        person_type: data.personType,
        aml_alert_code: amlAlertCode,
        event_type:  data.eventType,
        alert_status: newStatus,
        user_id_execute_action:adminId,
        updated_new_values:newValues || [],
        updated_old_values:oldValues || [],
        updated_columns:columns || [],
        alert_month:Number(data.createdAt.split("-")[1]),
        alert_year:Number(data.createdAt.split("-")[0]),


    }
      const response = await updateAlertStatus(userInfo.UserId, newData)
      if(!isEmpty(response.data) && response.data.result){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Estado actualizado exitosamente",
        });
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  }
  static getLogs = async ({userInfo,id,setLogs}) => {
    try {
      const response = await getLogs(userInfo.UserId,id)
      if (!isEmpty(response.data)){
        setLogs(response.data.result)
      }
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  }

  static getUser = async ({userInfo,id,setUserId}) => {
    try {
      const response = await getUser(userInfo.UserId,id)
      if (!isEmpty(response) && response.status === 200) {
        setUserId(response.data.id)
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  }
}

export default DepositAlertsController;
