import isEmpty from 'is-empty';
import React, { useState } from 'react'
import { useForm } from '../../../hooks/formHooks';
import ReportTypeController from '../../../pages/FallControllers/ReportTypeController';
import { GroupFilters } from '../Filters/Filters'
import HeaderPage from '../HeaderPage/HeaderPage'
import TableContainer from '../Table/TableContainer'
import Download from '../../../images/new_icons/download.svg';
import moment from 'moment';

export const ConsolidatedReport = ({ userInfo, decimalPlaces }) => {

    const filterInitialValues = {
        init_time: moment().format("YYYY-MM-DD"),
        end_time: moment().format("YYYY-MM-DD")
    };

    const { inputs: filterInputs, onInputChangeV2 } = useForm(filterInitialValues);

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [tableFooters, setTableFooters] = useState([]);

    const headerOptions = [
        {
            icon: Download,
            action: () => handleDownload()
        },
    ];


    const handleSearchFilters = () => {
        if (isEmpty(filterInputs.init_time) || isEmpty(filterInputs.end_time)) return;
        ReportTypeController.AllConsolidatedReport({
            userInfo,
            decimalPlaces,
            params: filterInputs,
            setLoading,
            setRows,
            setTableFooters
        })
    };

    const handleDownload = () => {
        if (isEmpty(rows)) return;
        ReportTypeController.DownloadAllConsolidated({
            rows,
            tableFooters
        })
    }

    const tableHeaders = [
        {
            tag: "accountId",
            title: "ID de cuenta"
        },
        {
            tag: "accountName",
            title: "Usuario"
        },
        {
            tag: "accountCreatedAt",
            title: "Fecha de creación de la cuenta",
            className: "table-title-v2"
        },
        {
            tag: "countWithdraw",
            title: "Número de Retiros"
        },
        {
            tag: "acumBtcAmount",
            title: "Bitcoin Retiradas"
        },
        {
            tag: "acumUsdcAmount",
            title: "USDC Retiradas"
        },
        {
            tag: "acumPenAmount",
            title: "Soles Retirados"
        },
        {
            tag: "acumUsdAmount",
            title: "Dólares Retirados"
        },
        {
            tag: "acumAmountWithdrawUsd",
            title: "Montos Totales de Retiro Expresados en $",
            className: "table-title-v2"
        },
        {
            tag: "acumFeeAmountUsdWithIgv",
            title: "Total Comisiones por Retiro (Con IGV)",
            className: "table-title-v2"
        },
        {
            tag: "acumFeeAmountUsdWithoutIgv",
            title: "Total Comisiones por Retiro (Sin IGV)",
            className: "table-title-v2"
        },
        {
            tag: "acumFeeAmountUsdIgv",
            title: "IGV (Comisiones de Retiro)",
            className: "table-title-v2"
        },
        {
            tag: "walletsCount",
            title: "Billeteras Externas"
        },
        {
            tag: "bankCount",
            title: "A cuentas Bancarias"
        },
    ];

    const filters = [
        {
            values: {
                title: "Desde",
                name: "init_time",
                value: filterInputs.init_time,
                type: "date",
                required: true,
                max: moment().format("YYYY-MM-DD")
            },
            actions: {
                onChange: onInputChangeV2,
            },
        },
        {
            values: {
                title: "Hasta",
                name: "end_time",
                value: filterInputs.end_time,
                type: "date",
                required: true,
                max: moment().format("YYYY-MM-DD")
            },
            actions: {
                onChange: onInputChangeV2,
            },
        },
    ];

    return (
        <div className="all-page-data">
            <HeaderPage
                title='Retiro - Reporte consolidado'
                subTitle="Reportes / Reporte general / Retiro - Reporte consolidado"
                headerOptions={headerOptions}
            />
            <TableContainer
                rows={rows}
                loading={loading}
                headers={tableHeaders}
                footers={(!isEmpty(rows) && !loading) ? tableFooters : null}
                rowIdSelected={''}
                enablePagination={false}
            >
                <GroupFilters
                    handleSearchFilters={handleSearchFilters}
                    withRightSection={false}
                    inputs={filters}
                />
            </TableContainer>
        </div>
    )
}