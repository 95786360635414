import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFilter } from "../../../hooks/useFilter";
import isEmpty from "is-empty";
import close from "../../../images/new_icons/close-circle.svg";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import { useModal } from "../../../hooks/useModal";
import AddProvidersModal from "../../../components/FallComponents/ProvidersPage/Modal";
import AddIcon from "../../../images/add-icon.svg";
import Download from "../../../images/new_icons/download.svg";
import AllProvidersData from "../../../components/FallComponents/ProvidersPage/AllProvidersData";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import ProvidersController from "../../FallControllers/ProvidersController";
import ProviderDetails from "../../../components/FallComponents/ProvidersPage/ProviderDetails";
import ComponentsHelper from "../../../helpers/Components.helper";
import { toastTypes } from "../../../constants";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import xlsx from "json-as-xlsx";
import moment from "moment";

const XLSXheaders = {
  id:"id",
  ruc:"RUC",
  businessName:"Razón social",
  type:"Tipo",
  createdAt:"Fecha de creación",
  updatedAt:"Fecha de actualización"
}

const ProvidersPage = (props) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const { showModal, handleToggleModal } = useModal();
  const { showModal:showModalConfirmDelete, handleToggleModal:handleToggleModalConfirmDelete } = useModal();
  const [selectedProvider,setSelectedProvider] = useState({})
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const handleSelectRow = ({row}) => {
    setWithRightSection(true)
    setSelectedProvider(row)

  }

  const getAllProviders = () => {
    ProvidersController.getAllProviders({
      userInfo,
      setRows,
      setLoading,
      setTotalRows
    });
  };
  
  const updateProviderData = async (data,id,callback) => {
    if(data.type === "LOCAL" && data.ruc === "00000000000"){
      callback()
      return ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Ingrese un RUC válido",
      });
    }
    const response = await ProvidersController.updateProvider({
      userInfo,
      data,
      id
    });

    if(!response) return callback()
    
    setWithRightSection(false)
    getAllProviders()
    
  }
  const deleteProvider = async () => handleToggleModalConfirmDelete()

  const handleConfirmActionModalToDelete = async () => {
    const response = await ProvidersController.deleteProvider({
      userInfo,
      id:selectedProvider.id
    });
    if(response){
      setWithRightSection(false)
      getAllProviders()
    }
  }

  const buildColumns = (data) => {
    const getKeys = Object.keys(data[0]);
    const columns = getKeys.map((name) => {
        return {
            label: XLSXheaders[name],
            value: (row) => row[name],
        };
    });
    return columns;
  };

  const downloadProvidersList = () => {
    const columns = buildColumns(rows);
    const data = [
        {
            sheet: "Proveedores",
            columns,
            content: rows
        }
    ];
    const settings = {
        fileName: `proveedores_${moment().format("YYYY-MM-DD")}`,
        extraLength: 10,
        writeOptions: {}
    };
    setTimeout(() => {
        xlsx(data, settings)
    }, 1);
  }

  const rezisableOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleToggleModal,
    },
    {
      icon: Download,
      action: downloadProvidersList
    },
    {
      icon: RotateRight,
      action: () => {
        getAllProviders()
      },
    },
  ];

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllProviders()
  }, [userInfo, filterConfig]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
            <AllProvidersData
              setWithRightSection={setWithRightSection}
              withRightSection={withRightSection}
              onChangeLimit={onChangeLimit}
              filterConfig={filterConfig}
              filterSearch={filterSearch}
              rows={rows}
              offset={offset}
              showingTo={showingTo}
              changePage={changePage}
              loading={loading}
              headerOptions={headerOptions}
              handleSelectRow={handleSelectRow}
              totalRows={totalRows}
            />
        }
        childrenTwo={
          <RightResizable
            headerOptions={rezisableOptions}
          >
            <ProviderDetails data={selectedProvider} updateProviderData={updateProviderData} deleteProvider={deleteProvider}/>
          </RightResizable>
        }
      />
      {showModal && (
        <AddProvidersModal
          showModal={showModal}
          handleToggleModal={handleToggleModal}
          title="Agregar proveedor"
          getAllProviders={getAllProviders}
        />
      )}
      <ConfirmModal
        showModal={showModalConfirmDelete}
        handleToggleModal={() => {
          handleToggleModalConfirmDelete();
        }}
        title="Eliminar proveedor"
        message="¿Desea eliminar este proveedor?"
        confirmButtonProps={{
          onClick: handleConfirmActionModalToDelete,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

ProvidersPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ProvidersPage);
