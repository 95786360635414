import UtilsHelper from "../../helpers/Utils.helper";

export const getUserInfoB89 = async (userId, banexcoinAccountId) => {
    const path = `/api/b89/${banexcoinAccountId}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const postCreateClient = async (userId, banexcoinAccountId) => {
    const path = `/api/b89/create-client/${banexcoinAccountId}`;
    const configRequest = {
        userId,
        method: "POST",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const postCreateAccount = async (userId, banexcoinAccountId) => {
    const path = `/api/b89/create-account/${banexcoinAccountId}`;
    const configRequest = {
        userId,
        method: "POST",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const patchUpdateActiveness = async (userId, banexcoinAccountId) => {
    const path = `/api/b89/switch-activeness/${banexcoinAccountId}`;
    const configRequest = {
        userId,
        method: "PATCH",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}