import React from 'react'
import ConfirmModal from '../../../../ConfirmModal/ConfirmModal'

const ConfirmAssetRestrictedExchangeChange = ({ showModal, handleToggleModal, checkedData, handleActiveAsset }) => {
    const actions = {
        [false]: {
            text: 'restringir en el exchange el',
            onClick: () => handleActiveAsset(checkedData.id, { restrictedExchange: true }, 'El activo fue restringido en exitosamente')
        },
        [true]: {
            text: 'quitar la restricción en el exchange del',
            onClick: () => handleActiveAsset(checkedData.id, { restrictedExchange: false }, 'Se quito la restricción del activo de manera exitosa')
        }
    }
    return <ConfirmModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
        title="Restringir en Exchange activo"
        message={`¿Deseas ${actions[checkedData.isChecked].text} activo?`}
        confirmButtonProps={{
            onClick: () => actions[checkedData.isChecked].onClick(),
            text: "Si"
        }}
        cancelButtonProps={{
            text: "No",
        }}
    />
}

export default ConfirmAssetRestrictedExchangeChange