import { connect } from 'react-redux';
import ManualDepositWorkflowComponent from './ManualDepositWorkflowComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';

var mapStateToProps = function mapStateToProps(state, _ref) {
  var onSubmit = _ref.onSubmit,
    submitting = _ref.submitting;
  return {
    product: depositProductSelector(state),
    onSubmit: onSubmit,
    submitting: submitting
  };
};

export default connect(mapStateToProps)(ManualDepositWorkflowComponent);
