import React, { useState } from 'react';
import {
  EditorState,
  convertToRaw,
  ContentState
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const textToEditor = string => {
  const blocksFromHTML = htmlToDraft(string);
  if(blocksFromHTML){
    const content = ContentState.createFromBlockArray(blocksFromHTML);
    return EditorState.createWithContent(content);
  }
  return EditorState.createEmpty();
};

const EditorConvertToHTML = (props, context) => {
  let { onChange, defaultValue, name, invalid, className } = props;
  const [editorState, setEditorState] = useState(textToEditor(defaultValue));
  const onEditorStateChange = editorState => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  return (
    <div>
      <Editor
        handlePastedText={() => false}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor form-control textarea-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['link', 'inline', 'emoji']
        }}
      />
      <textarea
      style={{display:"none"}}
        disabled
        maxLength={200}
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      />
      <p style={{"marginTop":"1rem"}}>Longitud máxima: 200 caracteres</p>
    </div>
  );
};

export default EditorConvertToHTML;
