import isEmpty from 'is-empty'
import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { CustomButton } from '../CustomButton/CustomButton'
import { ModalLayout } from './ModalLayout'

const AzureModal = ({ showModal, handleShowModal, sendAzureImage, faceImage }) => {

    const [src, setUrl] = useState(null);
    const [imageRef, setImageRef] = useState();
    const [croppedImageUrl, setCroppedImageUrl] = useState();
    const [fileUrl, setFileUrl] = useState();
    const [crop, setCrop] = useState({
        unit: "%",
        width: 30
    });
    const [loader, setLoader] = useState(false);
    const [confidence, setConfidence] = useState('');

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setUrl(reader.result)
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoaded = (image) => {
        setImageRef(image)
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop)
    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl2 = await getCroppedImg(
                imageRef,
                crop,
                "newFile.jpeg"
            );
            setCroppedImageUrl(croppedImageUrl2)
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const pixelRatio = 3;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(fileUrl);
                    const urlBlob = window.URL.createObjectURL(blob);
                    setFileUrl(urlBlob);
                    resolve(urlBlob);
                },
                "image/jpeg",
                1
            );
        });
    }

    const sendImage = async () => {
        const img = document.getElementById('myImage');
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(async function (blob) {
            setLoader(true)
            const response = await sendAzureImage(blob)
            if (response.status === 200) {
                setConfidence(response.data.result.confidence)
            }
            setLoader(false)
        }, 'image/jpeg');
    }

    return (
        <ModalLayout showModal={showModal} actionClose={handleShowModal}>
            <div className="azure_container">
                <div>
                    {isEmpty(confidence) &&
                        <label className='azure_label'>
                            Seleccione una foto frontal del documento de identidad:
                            <input type="file" accept="image/*" onChange={onSelectFile} />
                        </label>
                    }
                </div>
                <div>
                    <div>
                        {(src && isEmpty(confidence)) && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={onImageLoaded}
                                onComplete={onCropComplete}
                                onChange={onCropChange}
                            />
                        )}
                        {croppedImageUrl && (
                            <img id='myImage' alt="Crop" style={{ display: "none" }} src={croppedImageUrl} />
                        )}
                    </div>
                    <div className='kyc_container_image'>
                        {!isEmpty(confidence) && <img alt="face" src={faceImage} />}
                        {croppedImageUrl && (
                            <img alt="Crop" src={croppedImageUrl} />
                        )}
                    </div>
                </div>
                <div>
                    {(!isEmpty(croppedImageUrl) && isEmpty(confidence)) &&
                        <CustomButton
                            text="Enviar a Azure"
                            onClick={sendImage}
                            className="orange"
                            disabled={loader}
                        />}
                    {!isEmpty(confidence) &&
                        <div className='azure_message'>
                            <p>Coincidencia: <span>{confidence}</span></p>
                        </div>
                    }
                </div>
            </div>
        </ModalLayout>
    );
}

export default AzureModal