import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../hooks/useFilter";
import AllAcuantLogsData from "../../../components/FallComponents/AcuantLogsPage/AllAcuantLogsData";
import AcuantLogsController from "../../FallControllers/AcuantLogsController";
import Close from "../../../images/new_icons/close-circle.svg";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import AcuantLogsDetails from "../../../components/FallComponents/AcuantLogsPage/AcuantLogsDetails";

const AcuantPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedLog, setSelectedLog] = useState({});
  const [loading, setLoading] = useState(true);
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
    setFilterSearch,
    resetFilters,
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const headerOptions = [
    {
      image: Close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const getAllAcuantLogs = () => {
    AcuantLogsController.getAllAcuantLogs({
      userInfo,
      setLoading,
      setRows,
      setTotalRows,
      filterConfig,
      filterSearch,
    });
  };

  const downloadRows = () => {
    AcuantLogsController.downloadRows({ userInfo, filterConfig, filterSearch });
  };

  const handleSelectRow = ({ row }) => {
    setSelectedLog(row);
    setWithRightSection(true);
  };

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllAcuantLogs();
  }, [userInfo, filterConfig, filterSearch]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllAcuantLogsData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            setFilterSearch={setFilterSearch}
            resetFilters={resetFilters}
            downloadRows={downloadRows}
            handleSelectRow={handleSelectRow}
            setSelectedLog={setSelectedLog}
            selectedLog={selectedLog}
          />
        }
        childrenTwo={
          <RightResizable
            loadingData={{
              title: "",
              isLoading: false,
            }}
            headerOptions={headerOptions}
          >
            <AcuantLogsDetails data={selectedLog}/>
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AcuantPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AcuantPage);
