import React, { useState } from 'react';
import '../NaturalCustomersDetails/naturalCustomersDetails.css'
import edit from '../../../../images/new_icons/edit.svg';
import check from '../../../../images/new_icons/check.svg';
import cross from '../../../../images/new_icons/cross.svg';
import { EditableText } from '../../EditableText/EditableText';
import isEmpty from 'is-empty';
import { Form } from 'react-bootstrap';

export const ContentLevel = ({
  title,
  content,
  onInputChange,
  onInputFileChange,
  onSubmit,
  reset = () => {},
  onSetReturnDataInput,
  showCheckbox = false,
  status,
  editPermission
}) => {
  const [editable, setEditable] = useState(false);

  const cancelInfoUser = () => {
    reset();
    setEditable(false);
  };
  return (
    <div className="content-level">
      <Form
        noValidate
        validated={false}
        encType="multipart/form-data"
        onSubmit={onSubmit}
        id="form-admin-levels">
        <div className="content-level-title with-img">
          <h2>Datos de {title}</h2>
          {editPermission &&
            <React.Fragment>
              {!isEmpty(content) &&
                !isEmpty(content.options) && (
                  <button
                    type={!editable ? 'submit' : 'button'}
                    onClick={() => setEditable(!editable)}>
                    <img src={!editable ? edit : check} alt="edit image" />
                  </button>
                )
              }
              {!isEmpty(content) &&
                !isEmpty(content.options) &&
                editable && (
                  <button type="button" onClick={cancelInfoUser}>
                    <img
                      src={cross}
                      alt="cross"
                      style={{ marginLeft: '10px' }}
                      onClick={() => setEditable(false)}
                    />
                  </button>
                )
              }
            </React.Fragment>
          }
        </div>
        {!isEmpty(content.options) && (
          <div className="content-level-grid">
            {content.options.map((option, key) => {
              const { visible = true } = option;
              if (visible) return (
                <EditableText
                  status={status}
                  showCheckbox={showCheckbox}
                  editable={editable}
                  value={option.value}
                  name={option.name}
                  nameCheck={option.nameCheck}
                  check={option.check}
                  onChange={onInputChange}
                  onInputFileChange={onInputFileChange}
                  option={option.title}
                  titleFile={option.titleFile}
                  type={option.type}
                  options={option.options || []}
                  key={key}
                  onSetReturnDataInput={onSetReturnDataInput}
                  accept={option.accept}
                />
              )})}
          </div>
        )}
      </Form>
    </div>
  );
};
