import isEmpty from 'is-empty';
import React from 'react';
import DateHelper from '../../../../../helpers/Date.helper';
import Table from '../../../Table/Table'

export const History = ({logs,context}) => {

  const formattedTitles = {
    alertStatus:"Estado de alerta",
    amlAlertCode:"Código de alerta"
  }
  const handleDetailsLog = (log) => {
    const details = JSON.parse(log.aditionalInfo)
    if(isEmpty(details.updated_columns)) return 
    const data = details.updated_columns.map((item,i) => {
      return {
        slot:item,
        oldValue:details.updated_old_values[i],
        newValue:details.updated_new_values[i],
      }
    })
    return (
      <div>
        {data.map(item => {
          return <React.Fragment>
            <p>{`${formattedTitles[item.slot]} / valor anterior: ${item.oldValue} / valor actual: ${item.newValue}`}</p>
            <br/>
          </React.Fragment>
        })}
      </div>
    )
  }
    const headers = [
    {
      tag: '',
      title: 'Acción',
      cell: ({data}) => <p>{context.t(JSON.parse(data.aditionalInfo).action)}</p>
    },
    {
      tag: '',
      title: 'Detalles',
      cell: ({data}) => <p>{handleDetailsLog(data)}</p>
    },
    {
      tag: 'executeByName',
      title: 'Ejecutado por'
    },
    {
      tag: '',
      title: 'Fecha',
      cell:({data}) => <p>{DateHelper.formatDate(data.createdAt)}</p>
    }
  ];

  return (
    <div style={{ position: 'relative' }}>
      <div className="table-wrapper">
        <Table showTooltipToCopy={false} rowIdSelected="" rowHead={headers} dataBody={logs} />
      </div>
    </div>
  );
};
