import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../../hooks/useFilter";
import RightResizable from "../../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../../images/new_icons/close-circle.svg";
import isEmpty from "is-empty";
import AutoApprovalController from "../../../FallControllers/AutoApprovalController";
import AllAutoApprovalData from "../../../../components/FallComponents/AutoApprovalPage/AllAutoApprovalData";
import AutoApprovalDetails from "../../../../components/FallComponents/AutoApprovalPage/AutoApprovalDetails";
import { useModal } from "../../../../hooks/useModal";
import AddModal from "../../../../components/FallComponents/AutoApprovalPage/AddModal";

const AutoApprovalPage = (props, context) => {
  const { userInfo } = props;

  const [withRightSection, setWithRightSection] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [rows, setRows] = useState([]);
  const { showModal, handleToggleModal:handleToggleAddModal } = useModal();
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingAddProduct, setLoadingAddProduct] = useState(false);
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });
  const handleIsEditable = () => setIsEditable(!isEditable);

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const handleSelectRow = ({ row }) => {
    setWithRightSection(true);
    setSelectedRow(row);
  };

  const getAllProducts = () => {
    AutoApprovalController.getAllProducts({
      userInfo,
      setLoading,
      setRows,
      setTotalRows,
      filterConfig,
    });
  };

  const handleToggleStatusAutoApproval = async (data) => {
    const response = await AutoApprovalController.toggleAutoApprovalStatus({
      userInfo,
      data,
    });
    if (response) {
      getAllProducts();
    }
  };

  const updateDetails = async (id, inputs, callback) => {
    const response = await AutoApprovalController.updateAutoApprovalData({
      userInfo,
      id,
      data: inputs,
    });
    if (!response) return callback();
    setWithRightSection(false);
    getAllProducts();
  };

  const deleteAutoApprovalData = async (id) => {
    const response = await AutoApprovalController.deleteAutoApprovalData({
      userInfo,
      id,
      setLoadingDelete
    });
    if (response){
      setWithRightSection(false);
      getAllProducts();
    }
  }
  
  const addProduct = async (product) => {
    const response = await AutoApprovalController.addAutoApprovalProduct({userInfo,product,setLoadingAddProduct})
    if(response){
      handleToggleAddModal(false)
      getAllProducts();
    }
  }

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllProducts();
  }, [userInfo, filterConfig]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllAutoApprovalData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            handleSelectRow={handleSelectRow}
            getAllProducts={getAllProducts}
            handleToggleStatusAutoApproval={handleToggleStatusAutoApproval}
            handleToggleAddModal={handleToggleAddModal}
          />
        }
        childrenTwo={
          <RightResizable loadingData={{}} headerOptions={headerOptions}>
            <AutoApprovalDetails
              data={selectedRow}
              isEditable={isEditable}
              updateDetails={updateDetails}
              handleIsEditable={handleIsEditable}
              loadingDelete={loadingDelete}
              deleteAutoApprovalData={deleteAutoApprovalData}
            />
          </RightResizable>
        }
      />
      {showModal && (
        <AddModal
          showModal={showModal}   
          handleToggleAddModal={handleToggleAddModal}     
          addProduct={addProduct} 
          loading={loadingAddProduct}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AutoApprovalPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AutoApprovalPage);
