import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  addCurrency,
  getHistoricalRates,
  getLastRates,
} from "../FallServices/FxRatesServices";

class FxRatesController {
  static getLastRates = async ({ userInfo, setLastRatesRows, setLoading }) => {
    try {
      setLoading(true);
      const response = await getLastRates(userInfo.UserId);
      const orderedLastRates = !isEmpty(response.data) ? response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) : []
      setLastRatesRows(orderedLastRates);
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static getHistoricalRates = async ({
    userInfo,
    setHistoricalRatesRows,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const params = {
        filterConfig: {
          limit: 20,
          page: 1
        }
      };
      const response = await getHistoricalRates(userInfo.UserId,params);
      setHistoricalRatesRows(response.data);
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static addNewRate = async ({
    userInfo,
    data,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const dataForm = {
        base_currency: data.base_currency,
        base_rate: parseFloat(data.base_rate),
        currency: data.currency,
        rate: parseFloat(data.rate),
        is_fixed: data.is_fixed
      };
      const response = await addCurrency(userInfo.UserId,dataForm);
      if(response.data.id){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Tipo de cambio añadido correctamente.",
        });
        return true
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser guardada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
}

export default FxRatesController;
