import React from 'react'
import { DOCUMENT_TYPE } from '../../../../constants'

export const ClientDetails = ({context,level,ticket}) => {
  const customerType = level && [17, 18].includes(level.userLevel) ? 'Jurídico' : 'Natural'
  let documentType = level ? DOCUMENT_TYPE.find(e => e.value === level.documentType ) : {}
  documentType = documentType ? context.t(documentType.name) : '-'
  let documentNumber = level && level.documentNumber ? level.documentNumber : '-'
  if(customerType === 'Jurídico'){
    documentType = level && level.taxpayerIdentificationNumberType ? level.taxpayerIdentificationNumberType : '-'
    documentNumber = level && level.taxpayerIdentificationNumber ? level.taxpayerIdentificationNumber : '-'
  }
    return (
      <div className="slot-details">
        <div className="option">
          <p>
            <b>Tipo de Cliente:</b>
          </p>
          <p>{customerType}</p>
        </div>
        <div className="option">
          <p>
            <b>Tipo de Documento:</b>
          </p>
          <p>{documentType}</p>
        </div>
        <div className="option">
          <p>
            <b>Número de documento:</b>
          </p>
          <p>{documentNumber}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Username")}:</b>
          </p>
          <p>{level && level.banexcoinUsername || "-"}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Reject reason")}:</b>
          </p>
          <p>{ticket.RejectReason || "-"}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Account ID")}:</b>
          </p>
          <p>{level && level.banexcoinUserAccountId || "-"}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Source of founds")}:</b>
          </p>
          <p>{level && level.sourceOfFunds || "-"}</p>
        </div>
      </div>
    )
  }