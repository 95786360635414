import React, { useEffect, useState } from 'react'
import close from "../../../../images/new_icons/close-circle.svg";
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns'
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable'
import { useHistory, useParams } from 'react-router-dom';
import isEmpty from 'is-empty';
import { connect } from 'react-redux';
import CoinkDepositPage from './CoinkDeposit/CoinkDepositPage';
import CoinkWithdrawalsPage from './CoinkWithdrawals/CoinkWithdrawalsPage';


const CoinkTransactionsTypePage = (props) => {

    const { userInfo, decimalPlaces } = props;

    const params = useParams();
    const history = useHistory()

    const [withRightSection, setWithRightSection] = useState(false)
    const [report, setRepost] = useState({
        typeId: '',
        leftComponent: <></>,
        rightComponent: <></>
    })

    const headerOptions = [
        {
            image: close,
            alt: "close option",
            action: () => { setWithRightSection(false); }
        },
    ];

    const reportList = [
        {
            id: 'reporte',
            types: [
                {
                    typeId: 'deposito-coink',
                    leftComponent: <CoinkDepositPage />,
                    rightComponent: <></>
                },
                {
                    typeId: 'retiro-coink',
                    leftComponent: <CoinkWithdrawalsPage />,
                    rightComponent: <></>
                }
             ]
        }
    ]

    useEffect(() => {
        try {
            const report = reportList.find(element => element.id === params.cardId)
            const components = report.types.find(element => element.typeId === params.itemId)
            if (isEmpty(components)) throw "";
            setRepost(components)
        } catch (error) {
            return history.push("/coink-transactions")
        }
    }, [])

    return (
        <div className="beta-content-container">
            <ResizableColumns
                withRightSection={withRightSection}
                minWidth='650px'
                childrenOne={report.leftComponent}
                childrenTwo={
                    <RightResizable
                        loadingData={{ isLoading: false }}
                        headerOptions={headerOptions}
                    >
                        {report.rightComponent}
                    </RightResizable >
                }
            />
        </div >
    )
}

const mapStateToProps = ({ user: { userInfo }, product: { decimalPlaces } }) => {
    return { userInfo, decimalPlaces };
};

export default connect(mapStateToProps)(CoinkTransactionsTypePage);