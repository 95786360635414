import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import arrowRight from '../../../images/icons/arrow-right.svg';
import arrowLeft from '../../../images/icons/arrow-left.svg';
import Table from './Table';
import './tableContainer.css';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const TableContainer = (props, context) => {
  const {
    children,
    filterConfig= {},
    totalRows = 0,
    rows,
    loading = false,
    offset= 0,
    showingTo = 0,
    changePage = () => {},
    onChangeLimit = () => {},
    headers,
    footers = null,
    getUserID = () => {},
    rowIdSelected,
    enablePagination=true,
    identifierSlot="id",
    customClassname = ""
  } = props;

  const PaginationField = () => {
    const debounceRef = useRef();
    const [limit, setLimit] = useState(filterConfig.limit);

    const changeLimit = e => {
      const value = Number(e.target.value);
      setLimit(value);
      if (value) {
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
          onChangeLimit(value);
        }, 1000);
      }
    };

    return (
      <div className="row react-bootstrap-table-pagination">
        <div className="col-md-4 col-xs-4 col-sm-4 col-lg-4 paginator-v2">
          <span className="react-bootstrap-table-pagination-total">
            {context.t('{offset} - {showingTo} de {totalRows}', {
              offset,
              showingTo,
              totalRows
            })}
          </span>
        </div>
        <div className="react-bootstrap-table-pagination-list col-md-4 col-xs-4 col-sm-4 col-lg-4 paginator-v2">
          <Pagination
            innerClass="pagination react-bootstrap-table-page-btns-ul items"
            itemClass="page-item"
            linkClass="page-link page-link-v2"
            activePage={filterConfig.page}
            firstPageText="Inicio"
            lastPageText="Final"
            prevPageText={<img src={arrowLeft} />}
            nextPageText={<img src={arrowRight} />}
            itemsCountPerPage={limit}
            totalItemsCount={totalRows}
            pageRangeDisplayed={5}
            onChange={changePage.bind(this)}
          />
        </div>
        <div className="col-md-4 col-xs-4 col-sm-4 col-lg-4 paginator-v2">
          <span className="react-bootstrap-table-pagination-limit">
            <b>Filtrar registros por página</b>
            <input
              onChange={changeLimit}
              type="number"
              value={limit}
              className="filter-items"
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={`table-container-v2 ${customClassname}`}>
        <div className="table-v2">
          <div className="table-wrapper">
            {children}
            <Table
              loading={loading}
              rowHead={headers}
              dataBody={rows}
              rowFoot={footers}
              handleClickOnRow={getUserID}
              rowIdSelected={rowIdSelected}
              identifierSlot={identifierSlot}
            />
          </div>
        </div>
        {
          enablePagination && <div className="pv2-bott">
          <PaginationField />
        </div>
        }
      </div>
    </React.Fragment>
  );
};

TableContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(TableContainer);
