import UtilsHelper from "../../helpers/Utils.helper";

export const cleanReniec = async (userId) => {
    const path = '/api/v2/reniec/clean';
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getSearchByDni = async (userId, dni) => {
    const path = `/api/v2/reniec/data/${dni}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getPhotoByDni = async (userId, dni) => {
    const path = `/api/v2/reniec/photo/${dni}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getSignByDni = async (userId, dni) => {
    const path = `/api/v2/reniec/sign/${dni}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const postSearchByNames = async (userId, data) => {
    const path = '/api/v2/reniec/names';
    const configRequest = {
        userId,
        method: "POST",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}