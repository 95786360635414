import React, { useState } from "react";
import UserPermitsController from "../../../../pages/FallControllers/UserPermitsController";
import { TabsContainer } from "../../Tabs/TabsContainer";
import './usersDetails.css'
import { VerificationCodesSMS,MainDetails,Permits, History} from "./Tabs";
import RolesAccessHelper from '../../../../helpers/RoleAccess.helper'
import ComponentsHelper from "../../../../helpers/Components.helper";
import { toastTypes } from "../../../../constants";

function UsersDetails({ data,userInfo,resetUserDetails,resetOnlyDetails,context,logs }) {
  const [loading,setLoading] = useState(false)
  const [tabSelect,setTabSelect] = useState("Detalles")

  const handleFreeUserSms = async () => {
    const response = await UserPermitsController.freeUserSmsById({
      userInfo,
      setLoading,
      id: data.id,
    });
    if (response) {
      resetUserDetails();
    }
  };

  const handleUpdateUserType = async (newUserType, callback) => {
    const response = await UserPermitsController.updateUserType({
      userInfo,
      id: data.id,
      data: { usertype: newUserType },
    });
    if (!response) {
      callback();
      return
    }
    resetUserDetails()
  };

  const handleUpdateUser = async (fieldsEdit, callback) => {
    ComponentsHelper.toast({
      type: toastTypes.info,
      message: "Enviando...",
    });
    const response = await UserPermitsController.updateUserInfo({
      userInfo,
      id: data.id,
      fieldsEdit
    });
    if(response){
      resetUserDetails()
      ComponentsHelper.toast({
        type: toastTypes.success,
        message: "Los datos fueron actualizados correctamente",
      });
    } else {
      callback()
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser editada",
      });
    }
  }

  const HeaderSection = () => {
    return (
      <div className="header-section">
        <h2>{data.banexcoinUsername}</h2>
        <div className="option">
          <p>Banexcoin user ID: </p>
          <p> {data.banexcoinUserid}</p>
        </div>
      </div>
    );
  };

  const tabsOptions = [
    {
      title: 'Detalles',
      component: (
        <MainDetails
          data={data}
          handleFreeUserSms={handleFreeUserSms}
          loading={loading}
          updateUser = {handleUpdateUser}
          updateUserType={handleUpdateUserType}
        />
      ),
      default: true,
    },
    {
      title: 'Códigos de verificación SMS',
      component: <VerificationCodesSMS data={data} />,
      default: false,
    },
    {
      title: 'Permisos',
      component: (
        <Permits
          data={data}
          userInfo={userInfo}
          resetUserDetails={resetUserDetails}
          context={context}
        />
      ),
      default: false,
    },
    {
      title: 'Historial',
      component: (
        <History
          logs={logs}
          context={context}
        />
      ),
      default: false,
    },
  ];

  return (
    <div className="resizable-details-container">
      <HeaderSection />
      <div className="container-tabs">
        <TabsContainer
          tabsOptions={tabsOptions}
          tabSelect={tabSelect}
          setTabSelect={setTabSelect}
        />
      </div>
    </div>
  );
}

export default UsersDetails;
