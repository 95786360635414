import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'is-empty';
import closeIcon from '../../../../images/new_icons/close-circle.svg';
import AllGlobalConfigPage from '../../../../components/FallComponents/GlobalConfigPage/AllGlobalConfigPage';
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns';
import GlobalConfigController from '../../../FallControllers/GlobalConfigController';
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable';
import GlobalConfigDetails from '../../../../components/FallComponents/GlobalConfigPage/GlobalConfigDetails/GlobalConfigDetails';
import { useFilter } from '../../../../hooks/useFilter';

const GlobalConfigPage = (props, context) => {

    const { userInfo } = props;
    const [changeLimit, setChangeLimit] = useState(false);
    const [changeLimit2, setChangeLimit2] = useState(false);
    const [loading, setLoading] = useState(true);
    const [withRightSection, setWithRightSection] = useState(false);
    const [listActives, setListActives] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [totalRows2, setTotalRows2] = useState(0);
    const [rightLoading, setRightLoading] = useState(true);
    const [activeId, setActiveId] = useState('');
    const [listAssetAuthorizedById, setListAssetAuthorizedById] = useState([]);
    const [assetInfoById, setAssetInfoById] = useState({});
    const [allInitialSetting, setAllInitialSetting] = useState({});
    const [loaderInitialSetting, setLoaderInitialSetting] = useState(true);

    const {
        filterConfig,
        offset,
        showingTo,
        changePage,
        onChangeLimit,
        resetFilters
    } = useFilter({ totalRows });

    const {
        filterConfig: filterConfig2,
        offset: offset2,
        showingTo: showingTo2,
        changePage: changePage2,
        onChangeLimit: onChangeLimit2,
        resetFilters: resetFilters2
    } = useFilter({ totalRows2 });

    const changeAssetsById = (id, name, value) => {
        const newAssetList = listActives.map((data) => {
            if (data.id === id) {
                return {
                    ...data,
                    [name]: value
                };
            };
            return data;
        })
        setListActives(newAssetList);
        setAssetInfoById({
            ...assetInfoById,
            [name]: value
        });
    }

    const changeAllAssetsById = (id, newData) => {
        const newAssetList = listActives.map((data) => {
            if (data.id === id) {
                return {
                    ...data,
                    ...newData
                };
            };
            return data;
        })
        setListActives(newAssetList);
        setAssetInfoById({
            ...assetInfoById,
            ...newData
        });
    }

    const changeAssetAuthorizedById = (id, name, value) => {
        const newlistAssetAuthorizedById = listAssetAuthorizedById.map((data) => {
            if (data.id === id) {
                return {
                    ...data,
                    [name]: value
                };
            };
            return data;
        });
        setListAssetAuthorizedById(newlistAssetAuthorizedById);
    }

    const handleSelectRow = ({ row }) => {
        setActiveId(row.id);
        setAssetInfoById(row)
        setWithRightSection(true);
    };

    const handleLoadActives = () => {
        GlobalConfigController.getAllAssets({
            userInfo,
            setLoading,
            setListActives,
            setTotalRows,
            filterConfig
        });
    }

    const handleCreateNewAsset = async (data) => {
        await GlobalConfigController.postNewAsset({
            userInfo,
            data,
            handleLoadActives
        })
    }

    const handleActiveAsset = async (assetId, data, message) => {
        await GlobalConfigController.activeAsset({
            userInfo,
            assetId,
            data,
            message,
            changeAssetsById
        })
    }

    const handleDisableActive = async (assetId) => {
        await GlobalConfigController.deleteAsset({
            userInfo,
            assetId,
            changeAssetsById
        });
    }

    const handleLoadActiveById = () => {
        GlobalConfigController.getAllAssetById({
            userInfo,
            activeId,
            setListAssetAuthorizedById,
            setRightLoading,
            setTotalRows2,
            filterConfig2
        })
    }

    const handleEditAssetById = async (data, assetId = activeId) => {
        const response = await GlobalConfigController.editAssetById({
            userInfo,
            assetId,
            data,
            changeAssetsById
        })
        return { status: response, data }
    }

    const handleCreateAuthorizedAssetById = async (data) => {
        await GlobalConfigController.createAuthorizedAssetById({
            userInfo,
            activeId,
            data,
            handleLoadActiveById
        });
    }

    const handleUpdateAuthorizedAssetById = async (assetId) => {
        await GlobalConfigController.updateAuthorizedAssetById({
            userInfo,
            assetId,
            changeAssetAuthorizedById
        });
    }

    const handleUpdateAuthorizedModal = async (assetId, name, status) => {
        await GlobalConfigController.updateAuthorizedModal({
            userInfo,
            assetId,
            name,
            status,
            changeAssetAuthorizedById
        });
    }

    const handleDisableAssetAuthorizedById = async (assetId) => {
        await GlobalConfigController.deleteAssetAuthorizedById({
            userInfo,
            assetId,
            changeAssetAuthorizedById
        });
    }

    const handleEditSettingByKey = async (type, data) => {
        return await GlobalConfigController.editSettingByKey({
            userInfo,
            type,
            data
        })
    }

    const headerOptions = [
        {
            image: closeIcon,
            alt: 'Close option',
            action: () => {
                setWithRightSection(false);
            }
        }
    ];

    const initialConfig = () => {
        resetFilters(20)
        setChangeLimit(true);
    }

    const initialConfig2 = () => {
        resetFilters2(20)
        setChangeLimit2(true);
    }

    useEffect(() => {
        if (!changeLimit) initialConfig();
        if (!isEmpty(userInfo) && changeLimit) handleLoadActives();
    }, [userInfo, filterConfig])

    useEffect(() => {
        if (!isEmpty(userInfo)) {
            GlobalConfigController.getAllInitialSetting({ userInfo, setAllInitialSetting, setLoaderInitialSetting })
        }
    }, [userInfo])

    useEffect(() => {
        if (!changeLimit2) initialConfig2();
        if (!isEmpty(activeId) && changeLimit2) handleLoadActiveById();
    }, [activeId, filterConfig2])


    return (
        <React.Fragment>
            <div className="beta-content-container">
                <ResizableColumns
                    withRightSection={withRightSection}
                    minWidth='500px'
                    childrenOne={
                        <AllGlobalConfigPage
                            handleSelectRow={handleSelectRow}
                            loading={loading}
                            listActives={listActives}
                            totalRows={totalRows}
                            handleLoadActives={handleLoadActives}
                            handleDisableActive={handleDisableActive}
                            handleActiveAsset={handleActiveAsset}
                            selectedId={activeId}
                            allInitialSetting={allInitialSetting}
                            loaderInitialSetting={loaderInitialSetting}
                            handleEditSettingByKey={handleEditSettingByKey}
                            onChangeLimit={onChangeLimit}
                            filterConfig={filterConfig}
                            offset={offset}
                            showingTo={showingTo}
                            changePage={changePage}
                            handleCreateNewAsset={handleCreateNewAsset}
                            withRightSection={withRightSection}
                        />
                    }
                    childrenTwo={
                        <RightResizable
                            headerOptions={headerOptions}
                            loadingData={{
                                title: 'Cargando información',
                                isLoading: false
                            }}
                        >
                            <GlobalConfigDetails
                                assetInfoById={assetInfoById}
                                handleEditAssetById={handleEditAssetById}
                                listAssetAuthorizedById={listAssetAuthorizedById}
                                handleDisableAssetAuthorizedById={handleDisableAssetAuthorizedById}
                                handleCreateAuthorizedAssetById={handleCreateAuthorizedAssetById}
                                handleUpdateAuthorizedAssetById={handleUpdateAuthorizedAssetById}
                                handleUpdateAuthorizedModal={handleUpdateAuthorizedModal}
                                loading={rightLoading}
                                filterConfig={filterConfig2}
                                offset={offset2}
                                showingTo={showingTo2}
                                changePage={changePage2}
                                onChangeLimit={onChangeLimit2}
                                totalRows2={totalRows2}
                                changeAllAssetsById={changeAllAssetsById}
                            />
                        </RightResizable>
                    }
                />
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

GlobalConfigPage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(GlobalConfigPage)