import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import {
  getUserById,
  updateUserById,
  getUserByBanexcoinId,
  freeSmsUserById
} from './UsersHooks';
import empty from 'is-empty';
import Table from 'react-bootstrap/Table';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Button, Col, Card, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { getSecureGetParams } from '../../helpers/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faSms } from '@fortawesome/free-solid-svg-icons';
import {
  userRoleIdInput,
  userStatusInput,
  userRoles
} from '../../helpers/roleAccessHelper';
import ConfirmationCodes from './TableContent/ConfirmationCodes';
import { hasAccesstoAction } from '../../helpers/roleAccessHelper';

const layoutClasses = getBEMClasses('admin-userss-page');

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

const UsersViewPage = (props, context) => {
  const { userInfo } = props;

  if (empty(userInfo)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);
  const [id, setId] = useState('');
  const [banexcoinUserInfo, setBanexcoinUserInfo] = useState({});
  const [inputs, setInputs] = useState({});
  const [userLogged, setUserLogged] = useState({});
  const [adminRoleInitialState, setAdminRoleInitialState] = useState();
  const [adminStatusInitialState, setAdminStatusInitialState] = useState();
  const [confirmationCodes, setConfirmationCodes] = useState([]);

  const userStatus = ['ACTIVE', 'BLOCKED', 'DELETED', 'INACTIVE'];

  useEffect(
    () => {
      async function getBanexcoinUserById() {
        setLoading(1);
        try {
          let result = {},
            resultUserLogged = {};
          if (
            !empty(props) &&
            !empty(props.match) &&
            !empty(props.match.params) &&
            !empty(props.match.params.param)
          ) {
            const params = getSecureGetParams(props.match.params.param);
            if (!empty(params) && !empty(params.id)) {
              setId(params.id);

              result = await getUserById(userInfo.UserId, params.id);

              resultUserLogged = await getUserByBanexcoinId(
                userInfo.UserId,
                userInfo.UserId
              ).catch(e => {
                throw e;
              });

              if (!empty(result)) {
                setConfirmationCodes(
                  result.usersConfirmationCodesByUserId.nodes
                );
                setBanexcoinUserInfo(result);
                setAdminRoleInitialState(
                  userRoleIdInput.findIndex(function(x) {
                    return x.value === result.roleId;
                  })
                );
                setAdminStatusInitialState(
                  userStatusInput.findIndex(function(x) {
                    return x.value === `${result.userStatus}`;
                  })
                );
              }

              if (!empty(resultUserLogged)) {
                setUserLogged(resultUserLogged);
              }
            }
          }
        } catch (error) {
          console.log('Errors: ', error);
          toast.warn(context.t('Information is not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
        setLoading(0);
      }
      if (!empty(userInfo)) {
        getBanexcoinUserById();
      }
    },
    [userInfo]
  );

  async function getBanexcoinUserById() {
    setLoading(1);
    try {
      let result = {},
        resultUserLogged = {};
      if (
        !empty(props) &&
        !empty(props.match) &&
        !empty(props.match.params) &&
        !empty(props.match.params.param)
      ) {
        const params = getSecureGetParams(props.match.params.param);
        if (!empty(params) && !empty(params.id)) {
          setId(params.id);

          result = await getUserById(userInfo.UserId, params.id).catch(e => {
            throw e;
          });

          resultUserLogged = await getUserByBanexcoinId(
            userInfo.UserId,
            userInfo.UserId
          ).catch(e => {
            throw e;
          });

          if (!empty(result)) {
            setConfirmationCodes(result.usersConfirmationCodesByUserId.nodes);
            setBanexcoinUserInfo(result);
            setAdminRoleInitialState(
              userRoleIdInput.findIndex(function(x) {
                return x.value === result.roleId;
              })
            );
            setAdminStatusInitialState(
              userStatusInput.findIndex(function(x) {
                return x.value === `${result.userStatus}`;
              })
            );
          }

          if (!empty(resultUserLogged)) {
            setUserLogged(resultUserLogged);
          }
        }
      }
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information is not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  async function freeSmsById() {
    setLoading(1);
    try {
      let result = {};
      if (
        !empty(props) &&
        !empty(props.match) &&
        !empty(props.match.params) &&
        !empty(props.match.params.param)
      ) {
        const params = getSecureGetParams(props.match.params.param);
        if (!empty(params) && !empty(params.id)) {
          setId(params.id);

          result = await freeSmsUserById(userInfo.UserId, params.id).catch(
            e => {
              throw e;
            }
          );

          if (
            !empty(result) &&
            result.hasOwnProperty('id') &&
            !empty(result.id)
          ) {
            toast.success(context.t('SMS Liberado'), {
              position: toast.POSITION.TOP_CENTER
            });
            await getBanexcoinUserById();
          }
        }
      }
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information is not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  const handleAdminInputChange = selectedOption => {
    let roleAdminAccess = selectedOption.map(s => {
      return s.value;
    });

    setInputs(inputs => ({
      ...inputs,
      role_admin_access: roleAdminAccess
    }));
  };

  const handleRoleInputChange = selectedOption => {
    setInputs(inputs => ({
      ...inputs,
      role_id: selectedOption.value
    }));
  };

  const handleStatusInputChange = selectedOption => {
    setInputs(inputs => ({
      ...inputs,
      user_status: selectedOption.value
    }));
  };

  async function modifyInfo() {
    try {
      setLoading(1);
      const params = getSecureGetParams(props.match.params.param);
      setId(params.id);
      let newInputs = {
        ...inputs
      };
      if (newInputs.hasOwnProperty('user_status')) {
        newInputs.user_status = Number(newInputs.user_status);
      }
      await updateUserById(userInfo.UserId, params.id, newInputs);
      await getBanexcoinUserById();

      toast.success(context.t('Your data is being sent'), {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('Errors: ', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setLoading(0);
  }

  function confirmSubmit() {
    confirmAlert({
      title: 'Aprobar solicitud',
      message: '¿Está seguro que desea editar éste usuario?',
      buttons: [
        {
          label: 'Aceptar',
          onClick: modifyInfo
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  }
  let adminRolesInitialState = [],
    adminRolesInitialStatusObject = [];

  if (!empty(banexcoinUserInfo)) {
    for (let admin in JSON.parse(banexcoinUserInfo.roleAdminAccess)) {
      if (admin > 0) {
        adminRolesInitialState[admin] =
          ', ' +
          userRoles.filter(
            i =>
              i.value === JSON.parse(banexcoinUserInfo.roleAdminAccess)[admin]
          )[0].label;
      } else {
        adminRolesInitialState[admin] = userRoles.filter(
          i => i.value === JSON.parse(banexcoinUserInfo.roleAdminAccess)[admin]
        )[0].label;
      }
      //Creating default object to selector multi
      adminRolesInitialStatusObject[admin] = {
        value: JSON.parse(banexcoinUserInfo.roleAdminAccess)[admin],
        label: userRoles.filter(
          i => i.value === JSON.parse(banexcoinUserInfo.roleAdminAccess)[admin]
        )[0].label
      };
    }
  }

  let defaultStatusValue = userStatusInput[adminStatusInitialState];
  let userStatusProps = {};
  if (
    typeof inputs.user_status !== 'undefined' &&
    inputs.user_status !== '' &&
    !empty(userStatusInput[inputs.user_status])
  ) {
    userStatusProps.defaultValue = inputs.user_status;
  } else if (!empty(defaultStatusValue)) {
    userStatusProps.value = defaultStatusValue;
  }
  return (
    <React.Fragment>
      <div className="content-container">
        <div className="container">
          <Row>
            <Col lg={7}>
              <Card
                className="admin-users-page actions-cloud"
                style={{ marginTop: 20 }}>
                <Card.Body>
                  <Loading loading={loading} />
                  <div className="title-beta-box">
                    <h3>Detalle del usuario</h3>
                  </div>
                  {empty(banexcoinUserInfo) ? (
                    <Row className="detail-row">
                      <Col lg={12}>
                        <span className="value-detail">Cargando</span>
                      </Col>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <Row className="detail-row">
                        <Col lg={4}>
                          <b>{context.t('Banexcoin User Id')}</b>
                          <span className="value-detail">
                            {banexcoinUserInfo.banexcoinUserid}
                          </span>
                        </Col>
                        <Col lg={4}>
                          <b>{context.t('Banexcoin Username')}</b>
                          <span className="value-detail">
                            {banexcoinUserInfo.banexcoinUsername}
                          </span>
                        </Col>
                        <Col lg={4}>
                          <b>{context.t('Email')}</b>
                          <span className="value-detail">
                            {banexcoinUserInfo.email}
                          </span>
                        </Col>
                      </Row>
                      <Row className="detail-row">
                        <Col lg={4}>
                          <b>{context.t('User Status')}</b>
                          <span className="value-detail">
                            {userStatus[banexcoinUserInfo.userStatus]}
                          </span>
                        </Col>
                        <Col lg={4}>
                          <b>{context.t('Role')}</b>
                          <span className="value-detail">
                            {
                              userRoleIdInput.filter(
                                i => i.value === banexcoinUserInfo.roleId
                              )[0].label
                            }
                          </span>
                        </Col>
                        <Col lg={4}>
                          <b>{context.t('Admin Access Role')}</b>
                          <span className="value-detail">
                            {empty(banexcoinUserInfo.roleAdminAccess)
                              ? 'N/A'
                              : !empty(adminRolesInitialState)
                                ? adminRolesInitialState
                                : ''}
                          </span>
                        </Col>
                      </Row>
                      <Row className="detail-row">
                        <Col lg={12}>
                          <b>Telefono</b>
                          <Row
                            className={
                              banexcoinUserInfo.phoneVerified
                                ? 'subrow affirmative'
                                : 'subrow negative'
                            }>
                            <Col lg={4}>
                              <b>{context.t('Telefono')}</b>
                              <span className="value-detail">
                                {banexcoinUserInfo.phone}
                              </span>
                            </Col>
                            <Col lg={4}>
                              <b>{context.t('Telefono Aux')}</b>
                              <span className="value-detail">
                                {banexcoinUserInfo.phoneAux}
                              </span>
                            </Col>
                            <Col lg={4}>
                              <b>{context.t('¿Telefono Verificado?')}</b>
                              <span className="value-detail">
                                {banexcoinUserInfo.phoneVerified ? 'SI' : 'NO'}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Card.Body>
                <Card.Footer className="footer-card">
                  <Button
                    onClick={() => {
                      freeSmsById();
                    }}
                    variant="info"
                    type="button">
                    <FontAwesomeIcon icon={faSms} className="spc" />
                    <span>{' ' + context.t('Liberar SMS')}</span>
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg={5}>
              {hasAccesstoAction('user_edit_role') ? (
                <Card style={{ marginTop: 20 }} className="admin-users-page">
                  <Card.Body>
                    <div className="title-beta-box">
                      <h3>Editar datos</h3>
                    </div>
                    <Form
                      noValidate
                      encType="application/json"
                      id="form-admin-users"
                      className="form-beta">
                      <Row className="detail-row">
                        <Col xl="6">
                          <Form.Group controlId="user_status">
                            <Form.Label>
                              <b>{context.t('User Status')}</b>
                            </Form.Label>
                            <Select
                              options={userStatusInput}
                              onChange={handleStatusInputChange}
                              {...userStatusProps}
                            />
                          </Form.Group>
                        </Col>

                        {!empty(userRoleIdInput[adminRoleInitialState]) ? (
                          <Col xl="6">
                            <Form.Group controlId="role_id">
                              <Form.Label>
                                <b>{context.t('Role')}</b>
                              </Form.Label>
                              <Select
                                options={userRoleIdInput}
                                defaultValue={[
                                  userRoleIdInput[adminRoleInitialState]
                                ]}
                                onChange={handleRoleInputChange}
                              />
                            </Form.Group>
                          </Col>
                        ) : null}

                        {!empty(banexcoinUserInfo) ? (
                          <Col xl="6">
                            <Form.Group controlId="role_admin_access">
                              <Form.Label>
                                <b>{context.t('Role Admin Access')}</b>
                              </Form.Label>
                              <Select
                                isMulti
                                defaultValue={adminRolesInitialStatusObject}
                                options={userRoles}
                                onChange={handleAdminInputChange}
                              />
                            </Form.Group>
                          </Col>
                        ) : null}
                      </Row>
                    </Form>
                  </Card.Body>
                  <Card.Footer className="footer-card">
                    {!empty(banexcoinUserInfo) ? (
                      <Button
                        onClick={() => confirmSubmit()}
                        variant="info"
                        type="button"
                        style={{
                          padding: '1em',
                          width: 'auto'
                        }}>
                        <FontAwesomeIcon icon={faUserEdit} className="spc" />
                        <span>{context.t(' Modify User')}</span>
                      </Button>
                    ) : null}
                  </Card.Footer>
                </Card>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={7}>
              <ConfirmationCodes rows={confirmationCodes} />
            </Col>
            <Col lg={5} />
          </Row>
          <div className={layoutClasses('footer')}>
            <PageFooterLayout />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

UsersViewPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(UsersViewPage);
