import React, { useState,useEffect } from "react";
import isEmpty from "is-empty";
import { Dropdown } from "react-bootstrap";
import CommentsController from "../../pages/FallControllers/CommentsController";
import points from "../../images/new_icons/more.svg";
import minusIcon from "../../images/new_icons/minus.svg";
import AddIcon from "../../images/add-icon.svg";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useModal } from "../../hooks/useModal";
import { EditableText } from "./EditableText/EditableText";
import InputElement from "./InputElement/InputElement";
import { CustomButton } from "./CustomButton/CustomButton";
import DateHelper from '../../helpers/Date.helper'
import { Loader } from "./Loader/Loader";

const Comments = ({ context, userInfo, levelId, type = null, sendMessage = true, formatDataCallback, filterDataCallback }) => {
  const [loadingAddComment, setLoadingAddComment] = useState(false);
  const [loadingEditComment, setLoadingEditComment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const { showModal, handleToggleModal } = useModal();
  const [commentError, setCommentError] = useState(null);
  const [commentValue,setCommentValue] = useState("");
  const [fileInputs, setFileInputs] = useState([]);

  const addFileInput = () => {
    setFileInputs([...fileInputs, { id: fileInputs.length + 1, data: "" }]);
  };

  const removeFileInput = (id) => {
    const filteredFileInputs = fileInputs.filter(
      (fileInput) => fileInput.id !== id
    );
    setFileInputs(filteredFileInputs);
  };

  const handleAddNewFile = (ev) => {
    if (isEmpty(ev.target.files)) return;
    const fileData = ev.target.files[0];
    const dataFile = {
      data: fileData,
      name: fileData.name,
    };
    const newFiles = fileInputs.map((file) => {
      if (file.id === Number(ev.target.name.split("-")[1])) {
        return { ...file, ...dataFile };
      }
      return file;
    });
    setFileInputs(newFiles);
  };

  const handleGetAllComments = async () => {
    setLoading(true);
    await CommentsController.getAllComments({ userInfo, levelId, setComments,type, filterDataCallback });
    setLoading(false);
    }

  const executeSubmit = async () => {
    if (isEmpty(commentValue)) {
        setCommentError("Campo requerido");
      return;
    }
    let data = {
      message: commentValue,
      type,
      images: fileInputs.map((file) => file.data),
    };
    if(formatDataCallback){
      data = {...data, message: formatDataCallback(commentValue)}
    }
    setLoadingAddComment(true);
    const response = await CommentsController.createNewComment({
      userInfo,
      levelId,
      data,
    });
    setLoadingAddComment(false);
    if (response) {
      setCommentValue("");
      setFileInputs([])
      handleGetAllComments();
    }
  };

  const handleDeleteComment = async () => {
    const response = await CommentsController.deleteCommentById({
      userInfo,
      commentId: selectedComment.commentId,
    });
    if (response) {
      handleGetAllComments();
    }
  };
  const handleConfirmationToDeleteComment = async (commentId) => {
    setSelectedComment({ commentId });
    handleToggleModal();
  };
  const handleEditComment = async () => {
    setLoadingEditComment(true);
    let data = {
      userInfo,
      commentId: selectedComment.id,
      comment: selectedComment.comment,
    }
    if(formatDataCallback){
      data = {...data, comment: formatDataCallback(selectedComment.comment)}
    }
    const response = await CommentsController.editCommentById(data);
    setLoadingEditComment(false);
    if (response) {
      setIsEditable(false);
      handleGetAllComments();
    }
  };
  const handleEditableComment = async ({ comment, id }) => {
    setSelectedComment({ comment, id });
    setIsEditable(true);
  };
  const handleCancelEditable = () => {
    setIsEditable(false);
    setSelectedComment(null);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const idsAreEquals = (id) => selectedComment && selectedComment.id === id;

  const handleChangeModifyComment = (ev) => {
    setSelectedComment({
      ...selectedComment,
      comment: ev.target.value,
    });
  };

  const isMyComment = (userId) => userId === userInfo.UserId

  const DropdownOptions = ({ id, comment }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle className="ml-0 p-0" as={CustomToggle}>
          <img src={points} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleEditableComment({ comment, id })}>
            Editar
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleConfirmationToDeleteComment(id)}>
            Eliminar
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const newCommentInputProps = {
    values: {
      name: "comment",
      placeholder: "Agrega un nuevo comentario...",
      type: "textarea",
      value:commentValue
    },
    error: commentError,
    actions: { onChange: (ev) => {
      setCommentError(null)
      setCommentValue(ev.target.value)
    } },
  };

  const renderFileInputsList = () => {
    return fileInputs.map((fileInput) => {
      return (
        <div className="flex comment-input-file-dynamic" key={fileInput.id} title={fileInput.name}>
          <InputElement
            values={{
              id: fileInput.id,
              title: !isEmpty(fileInput.name)
                ? fileInput.name
                : "Seleccione un archivo",
              type: "file",
              name: `files-${fileInput.id}`,
            }}
            actions={{ onChange: handleAddNewFile }}
          />
          <img src={minusIcon} onClick={() => removeFileInput(fileInput.id)} />
        </div>
      );
    });
  };

  useEffect(() => {
    if (!isEmpty(userInfo) && !isEmpty(levelId)) {
      handleGetAllComments();
    }
  }, [userInfo,levelId]);

  return (
    <div className="comments content-tab comments-customers">
      <div className="content-tab-container comments-container">
        {sendMessage &&
          <React.Fragment>
            <h2>Añade un nuevo comentario</h2>
            <InputElement {...newCommentInputProps} />
            <div className="flex upload-files-container" onClick={addFileInput}>
              <p>Adjuntar archivo</p>
              <img src={AddIcon} />
            </div>
            {renderFileInputsList()}
            <div className="button-align-right">
              <CustomButton
                disabled={loadingAddComment}
                onClick={executeSubmit}
                text="Añadir comentario"
                className="purple"
                customClassName="add-comment-button"
                />
            </div>
          </React.Fragment>
        }
        {loading ? <Loader/> :
          (!isEmpty(comments)) ?
            <React.Fragment>
              <h2>{context.t("Comments")}</h2>
              <ul>
                {!isEmpty(comments) &&
                  comments.map((comment) => {
                    if (comment.deleted) return;
                    const files = JSON.parse(comment.files);
                    return (
                      <li key={comment.id}>
                        <div className="comment-info">
                          <p>
                            {comment.banexcoinUserByCreatedBy.banexcoinUsername}{" "}
                            <small>{DateHelper.formatDate(comment.updatedAt)}</small>
                            {comment.createdAt !== comment.updatedAt && (
                              <span>Editado</span>
                            )}
                          </p>
                          {isMyComment(comment.banexcoinUserByCreatedBy.banexcoinUserid) && (
                            <DropdownOptions
                            id={comment.id}
                            comment={comment.message}
                          />
                          )}
                        </div>

                        <EditableText
                          editable={isEditable && idsAreEquals(comment.id)}
                          name="comment"
                          type="textarea"
                          placeholder="Ingresa un nuevo comentario..."
                          value={
                            idsAreEquals(comment.id)
                              ? selectedComment.comment
                              : comment.message
                          }
                          onChange={handleChangeModifyComment}
                        />
                        {isEditable && idsAreEquals(comment.id) && (
                          <div className="flex buttons-comment-container">
                            <CustomButton
                              text="Guardar"
                              onClick={handleEditComment}
                              className="green"
                              disabled={loadingEditComment}
                              customClassName="edit-comment-button"
                            />
                            <CustomButton
                              text="Descartar cambios"
                              onClick={handleCancelEditable}
                              className="red"
                              disabled={loadingEditComment}
                              showLoader={false}
                            />
                          </div>
                        )}
                        <details className="comment-files-list">
                          <summary>
                            Adjuntos ({!isEmpty(files) ? files.length : 0})
                          </summary>
                          <ul>
                            {" "}
                            {!isEmpty(files) &&
                              files.map((file) => (
                                <a key={file.location} className="comment-item-file" href={file.location}>
                                  {file.name}
                                </a>
                              ))}
                          </ul>
                        </details>
                      </li>
                    );
                  })}
              </ul>
            </React.Fragment> : 
            <h2>Sin comentarios</h2>
        }
      </div>
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
        title=""
        message="¿Deseas eliminar el comentario seleccionado?"
        confirmButtonProps={{
          onClick: handleDeleteComment,
          text: "Si",
          customClassName:"delete-comment-button"
        }}
        cancelButtonProps={{
          text: "No",
          customClassName:"delete-comment-button"
        }}
      />
    </div>
  );
};

export default Comments