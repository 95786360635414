export const roleActionAccess = [
    {
      action: 'deposit_find_transaction',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'deposit_accept',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'deposit_reject',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'bank_buttons_show',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'process_info_withdraw',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'withdraw_accept',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'withdraw_update',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'withdraw_reject',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'withdraw_find_transaction',
      rolesAccess: ['admin_rw', 'apr_rw', 'con_rw']
    },
    {
      action: 'user_edit_role',
      rolesAccess: ['admin_rw']
    },
    {
      action: 'rates_add_update',
      rolesAccess: ['admin_rw', 'ope_rw']
    },
    {
      action: 'lock_and_delete_uers',
      rolesAccess: ['admin_rw', 'ope_rw']
    },
    {
      action:'active_update_bnx_account',
      rolesAccess:['admin_rw', 'ope_rw']
    }
  ];