import React from 'react'

export const Month = ({name,value,onChange,required,title}) => {
    return(
        <label className="label-text input-date">
            <input
              type="month"
              name={name}
              value={value}
              onChange={(e) => onChange(e.target)}
              required={required}
            />
            <p className="date-text">{title}</p>
          </label>
    )
}