import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AddSerieCorrelativeForm from "./AddSerieCorrelativeForm";
import "./modal.css";

const AddSerieCorrelativeModal = (props, context) => {
  const {
    showModal,
    handleToggleModal,
    title,
    getAllSeriesCorrelative
  } = props;
  return (
    <Modal className="custom-modal-container add-serie-correlative" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {title}
      </ModalHeader>
      <ModalBody>
       <AddSerieCorrelativeForm handleToggleModal={handleToggleModal} getAllSeriesCorrelative={getAllSeriesCorrelative}/>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AddSerieCorrelativeModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AddSerieCorrelativeModal);
