import React from 'react';
import empty from 'is-empty';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const GlobalSideMenuSubItem = (
  { childrens, foundCoincidence, closeSide },
  context
) => {
  return childrens.map((sItem, index) => {
    return (
      <Link
        to={sItem.menu.path}
        className={
          'nav-link' +
          (!empty(foundCoincidence) &&
          foundCoincidence.menu.path === sItem.menu.path
            ? ' active'
            : '')
        }
        key={'sitem' + index}
        onClick={() => closeSide()}>
        {context.t(sItem.menu.text)}
      </Link>
    );
  });
};

GlobalSideMenuSubItem.contextTypes = {
  t: PropTypes.func.isRequired
};

export default GlobalSideMenuSubItem;
