import React from 'react';
import isEmpty from 'is-empty';
import { Loader } from '../Loader/Loader';
import './modal.css';
import { ModalLayout } from './ModalLayout';

export const ModalImage = ({
  reniecImageData,
  loadingReniecData,
  handleShowModal,
  showModal
}) => {
  return (
    <ModalLayout showModal={showModal} actionClose={handleShowModal}>
      <React.Fragment>
        {loadingReniecData ? (
          <Loader />
        ) : (
          <img src={`data:image/jpg;base64, ${reniecImageData.data}`} />
        )}
      </React.Fragment>
    </ModalLayout>
  );
};

export const ModalInfo = ({
  reniecInfo,
  loadingReniecData,
  handleShowModal,
  showModal
}) => {
  return (
    <ModalLayout showModal={showModal} actionClose={handleShowModal}>
      <div className="content-data-modal">
        {loadingReniecData ? (
          <Loader />
        ) : (
          <div className="content-data-modal-info">
            {!isEmpty(reniecInfo) &&
              Object.keys(reniecInfo).map(item => (
                <div className="itemmodal" key={item}>
                  <h3 className="nameitem">{item}: </h3>
                  <p>{reniecInfo[item]}</p>
                </div>
              ))}
          </div>
        )}
      </div>
    </ModalLayout>
  );
};
