import React from "react";
import HeaderPage from "../HeaderPage/HeaderPage";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import DowloadIcon from "../../../images/new_icons/download.svg";
import ArrowRightIcon from "../../../images/icons/arrow-right.svg";
import { Loader } from "../Loader/Loader";
import "./allBlackListData.css";
import { CustomButton } from "../CustomButton/CustomButton";
import { Link, useHistory } from "react-router-dom";

const Card = ({
  identifier,
  quantity,
  title,
  handleDownloadList,
  navigate,
}) => {
  return (
    <div className="blacklist-card-resume">
      <div>
        <h2>{title}</h2>
        <img
          onClick={() => handleDownloadList(identifier)}
          src={DowloadIcon}
          alt=""
        />
      </div>
      <p>{quantity} personas reportadas</p>
      <div className="blacklist-card-buttons">
        <CustomButton
          text="Ver detalles"
          className="purple"
          onClick={() => navigate({url:`/lista-negra/${identifier}`})}
        />
        <CustomButton
          text="Agregar"
          className="green"
          onClick={() =>
            navigate({ url: `/lista-negra/${identifier}`, state: { isOpenModalToAdd:true } })
          }
        />
      </div>
    </div>
  );
};

function AllBlackListData({
  resumeData,
  getAllResume,
  loadingAllResume,
  handleDownloadList,
}) {
  const history = useHistory();
  const headerOptions = [
    {
      icon: RotateRight,
      action: getAllResume,
    },
  ];

  const navigate = ({ url, state = {} }) => {
    history.push({pathname:url,state});
  };

  const CardsList = () => {
    return Object.keys(resumeData).map((item) => (
      <Card
        key={item}
        identifier={item}
        title={resumeData[item].title}
        quantity={resumeData[item].quantity}
        handleDownloadList={handleDownloadList}
        navigate={navigate}
      />
    ));
  };

  return (
    <div className="all-container-data">
      <HeaderPage
        title="Lista negra"
        subTitle="Cumplimiento / Lista negra"
        headerOptions={headerOptions}
      />
      <div className="blacklist-content-resume">
        <div className="flex items-center link-goto-files-container">
          <Link to="/lista-negra/files">Ver cola de archivos</Link>
          <img src={ArrowRightIcon} alt="" width="16"/>
        </div>
        <div>
          {loadingAllResume ? <Loader /> : <CardsList />} 
        </div>
      </div>
    </div>
  );
}

export default AllBlackListData;
