import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import GlobalSideMenuContainer from '../components/FallComponents/GlobalSideMenu/GlobalSideMenuContainer';

import AdminLevelsCorporatePageBeta from '../pages/AdminLevelsCorporate/AdminLevelsCorporatePageBeta';
import AdminLevelsCorporateViewPageBeta from '../pages/AdminLevelsCorporate/AdminLevelsCorporateViewPageBeta';
import BankTransactionsPage from './BankTransactions/BankTransactionsPage';
import SendContactsSendrid from './Marketing/SendContactsSendrid';
import AppMarketing from './AppMarketing/AppMarketing';
import UsersViewPage from './Users/UsersViewPage';
import AcuantPage from './Acuant/AcuantPage';
import AcuantLogsPage from './AcuantLogs/AcuantLogsPage';
import ErrorPage from './FallPages/ErrorPage/ErrorPage';

{/* Deprecated modules - 18/11/2022
-- Modules were migrated to new version
  import NewsBanner from './News/NewsBanner';
  import AdminFeesPage from './AdminFees/AdminFeesPage';
  import AdminPaymentAttemptListPage from './AdminTDC/AdminPaymentAttemptListPage';
  import SerialCorrelative from './SerialCorrelative/SerialCorrelativePage';
  import FacturaActiva from './FacturaActiva/FacturaActivaPage';
  import AdminInvoicesTicketsPage from './AdminInvoicesTickets/AdminInvoicesTicketsPage';
  import ReceiptInvoice from './ReceiptInvoice/ReceiptInvoicePage';
  import BankingMovementsToolPage from './BankingMovementsTool/BankingMovementsToolPage';
  import SettingsPage from './SettingsPage';
  import BlacklistFilePageV2 from '../pages/Blacklist/BlacklistFilePageV2';
  import BlacklistFileDetailsPage from './Blacklist/BlacklistFileDetailsPage';
  import SanctionedCountriesPage from './SanctionedCountries/SanctionedCountriesPage';
  import AuditPage from './AuditTransactions/AuditPage';
  import AuditViewPage from './AuditTransactions/AuditViewPage';
  import BlacklistFileAddPage from './Blacklist/BlacklistFileAddPage';
  import BlacklistFileQueuesPage from './Blacklist/BlacklistFileQueuesPage';
  import ReniecSearchPage from './Reniec/ReniecSearchPage';
*/}

{/* Deprecated modules - 18/11/2022
-- Modules were migrated to new version
import AdminKpis from './AdminKpis/AdminKpis';
import AdminTradesPageBeta from './AdminTrades/AdminTradesPageBeta';
-- Modules were removed from the current version.
import AdminConsolidated from './AdminConsolidated/AdminConsolidated';
*/}

{/* Deprecated modules - 10/11/2022
-- Modules were migrated to new version

import AdminLevelsPageBeta from '../pages/AdminLevels/AdminLevelsPageBeta';
import AdminLevelsViewPageBeta from '../pages/AdminLevels/AdminLevelsViewPageBeta';
import FiatAccountsPage from '../pages/FiatAccounts/FiatAccountsPage';
*/}

{/* Deprecated modules - 14/11/2022
-- Modules were migrated to new version

import WithdrawsPage from './Withdraws/WithdrawsPage'
import AdminTxIds from './AdminTxIds/AdminTxIds';
import AdminTxIdsAdd from './AdminTxIds/AdminTxIdsAdd';
import WithdrawsViewPage from './Withdraws/WithdrawsViewPage';
import DepositsViewPage from './Deposits/DepositsViewPage';
import DepositsPage from './Deposits/DepositsPage';
*/}

{/* Deprecated modules - 17/11/2022
-- Modules were migrated to new version

import DashboardPage from './DashboardPage';
import UsersPage from './Users/UsersPage';
import CurrencyRates from './Rates/CurrencyRates';
*/}




const InteriorPage = props => (
  <React.Fragment>
    <GlobalSideMenuContainer {...props}>
      <Switch>
        {/* <Route exact path={'/dashboard'} component={DashboardPage} /> */}
        {/* <Route exact path={'/admin-users'} component={UsersPage} /> */}
        <Route exact path={'/admin-users/view/:param'} component={UsersViewPage} />
        {/* <Route exact path={'/settings'} component={SettingsPage} /> */}
        {/* <Route exact path={'/invoice-settings'} component={FacturaActiva} /> */}
        {/* <Route exact path={'/receipt-invoice'} component={ReceiptInvoice} /> */}
        {/* <Route exact path={'/serial-correlative'} component={SerialCorrelative} /> */}
        {/* <Route exact path={'/countries'} component={SanctionedCountriesPage} />
        <Route exact path={'/audit'} component={AuditPage} />
        <Route exact path={'/reniec-search'} component={ReniecSearchPage} />
        <Route exact path={'/audit/view/:param'} component={AuditViewPage} /> */}
        <Route exact path={'/acuant'} component={AcuantPage} />
        <Route exact path={'/acuant/logs'} component={AcuantLogsPage} />
        {/* <Route
          exact
          path={'/admin-reports/kpis'}
          component={AdminKpis}
        /> */}
        {/* <Route
          exact
          path={'/admin-reports/kpis/:pathy'}
          component={AdminKpis}
        /> */}
        {/* <Route
          exact
          path={'/admin-reports/trades'}
          component={AdminTradesPageBeta}
        /> */}
        {/* <Route
          exact
          path={'/admin-reports/consolidated'}
          component={AdminConsolidated}
        /> */}
        
        {/* <Route
          exact
          path={'/admin-levels-corporate'}
          component={AdminLevelsCorporatePage}
        />
        <Route
          exact
          path={'/admin-levels-corporate/view/:params'}
          component={AdminLevelsCorporateViewPage}
        /> */}
        
        <Route exact path={'/bank-transactions'} component={BankTransactionsPage} />
        <Route
          exact
          path={'/admin-levels/corporate'}
          component={AdminLevelsCorporatePageBeta}
        />
        <Route
          exact
          path={'/admin-levels/corporate/view/:params'}
          component={AdminLevelsCorporateViewPageBeta}
        />
        {/* <Route exact path={'/withdraws'} component={WithdrawsPage} /> */}
        {/* <Route
          exact
          path={'/admin-txids/list/:limit/:page'}
          component={AdminTxIds}
        />
        <Route exact path={'/admin-txids/add'} component={AdminTxIdsAdd} /> */}
        {/* <Route
          exact
          path={'/withdraw-ticket/view/:params'}
          component={WithdrawsViewPage}
        />

        <Route exact path={'/admin-levels'} component={AdminLevelsPageBeta} /> */}
        {/* <Route
          exact
          path={'/admin-levels/view/:params'}
          component={AdminLevelsViewPageBeta}
        /> */}
        {/* <Route exact path={'/fiat-accounts'} component={FiatAccountsPage} /> */}

        {/* <Route exact path={'/deposits'} component={DepositsPage} /> */}
        {/*  <Route
          exact
          path={'/deposit-ticket/view/:params'}
          component={DepositsViewPage}
        /> */}
        {/* <Route exact path={'/blacklist'} component={BlacklistFilePageV2} />
        <Route
          exact
          path={'/blacklist/:listname/:limit/:page'}
          component={BlacklistFileDetailsPage}
        />
        <Route
          exact
          path={'/blacklist/add/:listname'}
          component={BlacklistFileAddPage}
        />
        <Route
          exact
          path={'/blacklist/get/queues/:limit/:page'}
          component={BlacklistFileQueuesPage}
        /> */}
        {/* <Route
          exact
          path={'/payment-attempts-tdc/:limit/:page'}
          component={AdminPaymentAttemptListPage}
        /> */}
        <Route exact path={'/marketing'} component={SendContactsSendrid} />
        {/* <Route exact path={'/rates'} component={CurrencyRates} /> */}
        {/* Fees */}
        {/* <Route exact path={'/admin-fees'} component={AdminFeesPage} /> */}
        {/* <Route exact path={'/banking-movements'} component={BankingMovementsToolPage} /> */}
        {/* <Route
          exact
          path={'/admin-invoices-tickets'}
          component={AdminInvoicesTicketsPage}
        /> */}
        {/* News */}
        {/* <Route exact path={'/notificaciones'} component={NewsBanner} /> */}

        {/* Listmonk */}
        <Route exact path={'/appmarketing'} component={AppMarketing} />
        <Route>
          <ErrorPage />
        </Route>
      </Switch>
    </GlobalSideMenuContainer>
  </React.Fragment>
);

export default withAuthentication(InteriorPage);
