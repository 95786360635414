import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  freeUserSmsById,
  getLastLogin,
  getLogs,
  getUserById,
  getUsers,
  updateProfile,
  updateUserById,
  updateUserType,
  updateUserInfo,
  getAllLogsUsers
} from "../FallServices/UserPermitsService";

class UserPermitsController {
  static getAllUsers = async ({
    userInfo,
    setRows,
    setLoading,
    filterConfig,
    setTotalRows,
    filterSearch,
  }) => {
    try {
      setLoading(true);
      const response = await getUsers(
        userInfo.UserId,
        filterConfig,
        filterSearch
      );
      setRows(response.data.nodes);
      setTotalRows(response.data.totalCount);
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static getUserById = async ({
    userInfo,
    setLoadingDetails,
    id,
    setUserDataById,
  }) => {
    try {
      setLoadingDetails(true);
      const response = await getUserById(userInfo.UserId, id);
      setUserDataById(response.data);
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    } finally {
      setLoadingDetails(false);
    }
  };
  static freeUserSmsById = async ({ userInfo, setLoading, id }) => {
    try {
      setLoading(true);
      const result = await freeUserSmsById(userInfo.UserId, id);
      if (result.data.id) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "SMS liberado satisfactoriamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static updateUserPermits = async ({ userInfo, id, data }) => {
    if (isEmpty(data)) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "No existe información para editar",
      });
      return;
    }
    try {
      const response = await updateUserById(userInfo.UserId, id, data);
      if (response.data.id) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Información actualizada correctamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser editada",
      });
      return false;
    }
  };
  static updateProfile = async ({ userInfo, userUuid, profileId }) => {
    try {
      const data = {
        "profile": {
          "id": profileId
        }
      }
      const response = await updateProfile(userInfo.UserId, userUuid,data);
      if (!isEmpty(response.data)) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Perfil de usuario actualizado satisfactoriamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser editada",
      });
      return false;
    }
  };
  static updateUserType = async ({ userInfo, id, data }) => {
    try {
      const response = await updateUserType(userInfo.UserId, id, data);
      if (!isEmpty(response.data) && response.data.message === "Success") {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Tipo de usuario actualizado satisfactoriamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser editada",
      });
      return false;
    }
  };
  static getLastLogin = async ({userInfo,userId,setLastLoginInfo}) => {
    try {
      const response = await getLastLogin(userInfo.UserId,userId)
      setLastLoginInfo(response.data)
      return true
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Último inicio de sesión no pudo ser accedido.",
      });
      return false;
    }
  };
  static getLogs = async ({ userInfo, setLogs, selectedUserId }) => {
    try {
      const response = await Promise.all([
        getLogs(userInfo.UserId, "Cambio de perfil", selectedUserId),
        getLogs(userInfo.UserId, "Cambio de tipo de usuario", selectedUserId)
      ]);
      const data = [...response[0].data,...response[1].data]
      const sortedLogsByCreatedAt = !isEmpty(data)
          ? data.sort(
              (a, b) =>
                new Date(b.createdAt) -
                new Date(a.createdAt)
            )
          : [];
      setLogs(sortedLogsByCreatedAt)
      return true
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    }
  }
  static updateUserInfo = async ({ userInfo, id, fieldsEdit }) => {
    try {
      const response = await updateUserInfo(userInfo.UserId, id, fieldsEdit);
      return !isEmpty(response.status) && response.data.message === "success";
    } catch (error) {
      return false;
    }
  };
  static getLogsUsers = async ({ userInfo, setLogs, selectedUserId }) => {
    try {
      const res = await getAllLogsUsers(userInfo.UserId, selectedUserId);
      const sortedLogsByCreatedAt = !isEmpty(res.data)
          ? res.data.sort(
              (a, b) =>
                new Date(b.createdAt) -
                new Date(a.createdAt)
            )
          : [];

      setLogs(sortedLogsByCreatedAt)
      return true
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    }
  }
}

export default UserPermitsController;
