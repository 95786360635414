import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RotateRight from "../../../../images/new_icons/rotate-right.svg";
import Setting from "../../../../images/new_icons/setting.svg";
import AddIcon from "../../../../images/add-icon.svg";
import SettingLight from "../../../../images/new_icons/setting-light.svg";
import { useLocation, useParams } from "react-router-dom";
import { useFilter } from "../../../../hooks/useFilter";
import HeaderPage from "../../../../components/FallComponents/HeaderPage/HeaderPage";
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns";
import isEmpty from "is-empty";
import BlackListController from "../../../FallControllers/BlackListController";
import TableContainer from "../../../../components/FallComponents/Table/TableContainer";
import DateHelper from "../../../../helpers/Date.helper";
import { GroupFilters } from "../../../../components/FallComponents/Filters/Filters";
import { useForm } from "../../../../hooks/formHooks";
import { useModal } from "../../../../hooks/useModal";
import RightResizable from "../../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../../images/new_icons/close-circle.svg";
import BlackListDetails from "../../../../components/FallComponents/BlackListPage/BlackListDetails/BlackListDetails";
import Modal from "../../../../components/FallComponents/BlackListPage/Modal";

const BlackListByTypePage = (props, context) => {
  const { userInfo } = props;
  const [blackListDataByType, setBlackListDataByType] = useState({});
  const [loadingBlackListData, setLoadingBlackListData] = useState(false);
  const [loadingDeleteBlackListById, setLoadingDeleteBlackListById] =
    useState(false);
  const [withRightSection, setWithRightSection] = useState(false);
  const [selectedDataById, setSelectedDataById] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const { showModal, handleToggleModal } = useModal();

  const params = useParams();
  const location = useLocation();

  const {
    offset,
    showingTo,
    filterConfig,
    onChangeLimit,
    changePage,
    filterSearch,
    resetFilters,
    setFilterSearch,
  } = useFilter({ totalRows: blackListDataByType.totalCount });

  const filterInitialValues = {
    paternoRazonSocial: "",
    lastName: "",
    firstName: "",
    secondName: "",
    typeDocument: "",
    numDocument: "",
  };

  const {
    inputs: inputsFilters,
    onInputChangeV2,
    setInputs: setInputsFilters,
  } = useForm(filterInitialValues, handleSearchFilters);

  const handleSearchFilters = () => {
    Object.keys(inputsFilters).forEach((element) => {
      if (inputsFilters[element] === "" || isEmpty(inputsFilters[element])) {
        delete inputsFilters[element];
      }
    });
    setFilterSearch(inputsFilters);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const getAllResumeByType = () => {
    const urlConfig = {
      type: params.type,
      ...filterConfig,
    };
    BlackListController.getBlackListById({
      userInfo,
      urlConfig,
      filter: filterSearch,
      setBlackListDataByType,
      setLoadingBlackListData,
    });
  };

  const updateBlackListById = async (fields, callback) => {
    const response = await BlackListController.updateBlackListById({
      userInfo,
      fields: { ...selectedDataById, ...fields },
      type: params.type,
    });
    if (response) {
      getAllResumeByType();
      setWithRightSection(false);
      return;
    }
    callback();
  };

  const deleteBlackListById = async () => {
    const response = await BlackListController.deleteBlackListById({
      setLoadingDeleteBlackListById,
      userInfo,
      id: selectedDataById.id,
      type: params.type,
    });
    if (response) {
      const filteredData = blackListDataByType.data.filter(
        (item) => item.id !== selectedDataById.id
      );
      setBlackListDataByType({ ...blackListDataByType, data: filteredData });
      setWithRightSection(false);
      return;
    }
  };

  const headerOptionsDetails = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleToggleModal,
    },
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: RotateRight,
      action: () => {
        resetFilters();
        setInputsFilters(filterInitialValues);
      },
    },
  ];

  useEffect(() => {
    if (isEmpty(userInfo) || isEmpty(params.type)) return;
    getAllResumeByType();
  }, [userInfo, filterConfig, filterSearch]);

  useEffect(() => {
    if (!isEmpty(location.state) && location.state.isOpenModalToAdd) {
      handleToggleModal();
    }
  }, []);

  const handleSelectRow = ({ row }) => {
    setWithRightSection(true);
    setSelectedDataById(row);
  };
  const tableHeaders = [
    {
      tag: "paternoRazonSocial",
      title: "Apellido paterno o razón social",
      handleSelectRow,
    },
    {
      tag: "lastName",
      title: "Apellido materno",
      handleSelectRow,
    },
    {
      tag: "firstName",
      title: "Primer nombre",
      handleSelectRow,
    },
    {
      tag: "secondName",
      title: "Segundo nombre",
      handleSelectRow,
    },
    {
      tag: "typeDocument",
      title: "Tipo de documento",
      handleSelectRow,
    },
    {
      tag: "numDocument",
      title: "Número de documento",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Fecha de creación",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "",
      title: "Fecha de actualización",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.updatedAt)}</p>,
    },
  ];

  const documentTypes = [
    {
      name: "Pasaporte",
      value: "PP",
    },
    {
      name: "Documento Nacional de Identidad",
      value: "DNI",
    },
    {
      name: "Residence Permit (CE)",
      value: "RP",
    },
    {
      name: "RUC",
      value: "RUC",
    },
  ];

  const filters = [
    {
      values: {
        title: "Apellido paterno o razón social",
        name: "paternoRazonSocial",
        value: inputsFilters.paternoRazonSocial,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Apellido materno",
        name: "lastName",
        value: inputsFilters.lastName,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Primer nombre",
        name: "firstName",
        value: inputsFilters.firstName,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Segundo nombre",
        name: "secondName",
        value: inputsFilters.secondName,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Tipo de documento",
        name: "typeDocument",
        value: inputsFilters.typeDocument,
        type: "select",
        options: documentTypes,
        required: true,
        isClearable: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Número de documento",
        name: "numDocument",
        value: inputsFilters.numDocument,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="beta-content-container">
        <ResizableColumns
          withRightSection={withRightSection}
          childrenOne={
            <div className="all-container-data">
              <HeaderPage
                title="Lista negra"
                subTitle={`Cumplimiento / Lista negra / ${
                  blackListDataByType.title || ""
                }`}
                headerOptions={headerOptions}
              />
              <TableContainer
                totalRows={blackListDataByType.totalRows}
                filterConfig={filterConfig}
                rows={blackListDataByType.data}
                onChangeLimit={onChangeLimit}
                changePage={changePage}
                loading={loadingBlackListData}
                offset={offset}
                showingTo={showingTo}
                headers={tableHeaders}
                rowIdSelected={selectedDataById.id}
              >
                {showFilters && (
                  <GroupFilters
                    handleSearchFilters={handleSearchFilters}
                    withRightSection={withRightSection}
                    inputs={filters}
                  />
                )}
              </TableContainer>
            </div>
          }
          childrenTwo={
            <RightResizable
              loadingData={false}
              headerOptions={headerOptionsDetails}
            >
              <BlackListDetails
                data={selectedDataById}
                documentTypes={documentTypes}
                updateBlackListById={updateBlackListById}
                deleteBlackListById={deleteBlackListById}
                loadingDeleteBlackListById={loadingDeleteBlackListById}
              />
            </RightResizable>
          }
        />
      </div>
      {showModal && (
        <Modal
          showModal={showModal}
          title="Agregar registros"
          handleToggleModal={handleToggleModal}
          type={params.type}
          getAllResumeByType={getAllResumeByType}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

BlackListByTypePage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BlackListByTypePage);
