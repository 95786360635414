import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import down from '../../../images/new_icons/arrow-down.svg';

const AccordionComponent = ({ title, Component }) => {
  return (
    <Accordion>
      <Card className="accordion-card">
        <Card.Header>
          <Accordion.Toggle variant="link" eventKey={1}>
            {title} <img src={down} alt="arrow to down" />
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={1}>
          <Card.Body>{Component}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AccordionComponent;
