import UtilsHelper from "../../helpers/Utils.helper";

export const getTxIds = async (userId, filterConfig, filterSearch) => {
  const path = "/api/all-txid";
  const params = {
    filterConfig,
    filterSearch,
  };
  const configRequest = {
    userId,
    method: "POST",
    path,
    data: params,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const getTicketByNumber = async (userId, ticket) => {
  const path = "/api/get-ticket";
  const data = {
    ticketNumber: ticket.ticketNumber,
    type: ticket.type,
  };
  const configRequest = {
    userId,
    method: "POST",
    path,
    data,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const saveTicketByNumber = async (userId, ticket,newTxId) => {
  const path = "/api/save-ticket";
  const data = {
    ticketNumber: ticket.ticketNumber,
    type: ticket.type,
    txId: newTxId,
  };
  const configRequest = {
    userId,
    method: "POST",
    path,
    data,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const updateTicketByNumber = async (userId, ticket,newTxid) => {
  const path = "/api/update-ticket";
  const data = {
    ticketNumber: ticket.ticketNumber,
    type: ticket.type,
    txId:newTxid,
    id: ticket.id,
  };
  const configRequest = {
    userId,
    method: "POST",
    path,
    data,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
