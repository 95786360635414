import moment from 'moment';
import apex from 'apex-web/lib/apex';
import HandlerBackend from '../../../../helpers/HandlerBackend';

export const getFiatAccounts = (currencyCode, userId) => {
  let path = `/api/deposit-fiat-accounts/?currencyCode=${currencyCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const getBanexcoinAccounts = (currencyCode, userId) => {
  let path = `/api/banexcoin-accounts/?currencyCode=${currencyCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const submitFormData = async data => {
  let payload = {
    assetManagerId: 1,
    accountId: data.AccountId,
    assetId: data.assetId,
    assetName: data.assetName,
    amount: data.amount,
    omsId: 1,
    requestCode: null,
    requestIP: null,
    requestUser: data.banexcoin_user_id,
    requestUserName: data.banexcoin_user_name,
    operatorId: 1,
    Status: 0,
    feeAmt: 0.0,
    updatedByUser: 0,
    updatedByUserName: null,
    ticketNumber: 0,
    depositInfo:
      '{"transactionNumber":"' +
      data.transaction_number +
      '","transactionDate":"' +
      data.transaction_date +
      '","amount":"' +
      data.amount +
      '","accountSource":"' +
      data.account_source +
      '","bankNameSource":"' +
      data.bank_name_source +
      '","bankCountrySource":"' +
      data.bank_country_source +
      '","bankNameDestination":"' +
      data.bank_name_destination +
      '","bankCountryDestination":"' +
      data.bank_country_destination +
      '","accountDestination":' +
      data.account_destination +
      '}',
    createdTimestamp: '0001-01-01T00:00:00',
    lastUpdateTimeStamp: '0001-01-01T00:00:00',
    comments: null,
    attachments: null
  };

  return await apex.connection.CreateDepositTicket(payload);
};
