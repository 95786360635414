export const IS_PEP = [
    {
      name: 'Si',
      value: '1'
    },
    {
      name: 'No',
      value: '0'
    }
  ];
  
  export const is_pep_numbers = [
    {
      name: 'Si',
      value: "1"
    },
    {
      name: 'No',
      value: "0"
    }
  ]