const headersArray = ['paterno_razon_social','materno','nombre1','nombre2','tipo_doi','numero_doi','fuente']
export  const processFileData = (dataString,setRows) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    let res = true
    headersArray.forEach(element => {
      if(!headers.includes(element)){
        res = false
      }
    });
    if(!res){
      setRows([])
      return res
    }
    const lengthMax = dataStringLines.length > 10 ? 11 : dataStringLines.length;
    const list = [];
    for (let i = 1; i < lengthMax; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setRows(list);
  };