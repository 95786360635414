import isEmpty from "is-empty";
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "../../../../hooks/formHooks";
import ReportTypeController from "../../../../pages/FallControllers/ReportTypeController";
import { GroupFilters } from "../../../../components/FallComponents/Filters/Filters";
import HeaderPage from "../../../../components/FallComponents/HeaderPage/HeaderPage";
import TableContainer from "../../../../components/FallComponents/Table/TableContainer";
import Download from "../../../../images/new_icons/download.svg";
import moment from "moment";

const SpreadReport = (props, context) => {
  const { userInfo, decimalPlaces } = props;
  const filterInitialValues = {
    initDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  const { inputs: filterInputs, onInputChangeV2 } =
    useForm(filterInitialValues);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableFooters, setTableFooters] = useState([]);

  const headerOptions = [
    {
      icon: Download,
      action: () => handleDownload(),
    },
  ];

  const handleSearchFilters = () => {
    if (isEmpty(filterInputs.initDate) || isEmpty(filterInputs.endDate)) 
    return;
    ReportTypeController.AllSpreadDataReport({
      userInfo,
      params: filterInputs,
      setLoading,
      setRows,
    });
  };

  const handleDownload = () => {
    if (isEmpty(rows)) return;
    ReportTypeController.DownloadAllSpread({
      rows,
      tableFooters,
    });
  };

  const tableHeaders = [
    {
      tag: "horaUtc",
      title: "horaUtc",
    },
    {
      tag: "accountId",
      title: "accountId",
    },
    {
      tag: "accountName",
      title: "accountName",
      className: "table-title-v2",
    },
    {
      tag: "referenceId",
      title: "referenceId",
    },
    {
      tag: "referenceType",
      title: "referenceType",
    },
    {
      tag: "productId",
      title: "productId",
    },
    {
        tag: "product",
        title: "product",
    },
    {
        tag: "productFullName",
        title: "productFullName",
    },
    {
      tag: "productType",
      title: "productType",
    },
    {
        tag: "transactionType",
        title: "transactionType",
    },
    {
      tag: "instrumentId",
      title: "instrumentId",
    },
    {
      tag: "instrumentSymbol",
      title: "instrumentSymbol",
    },
    {
      tag: "fee",
      title: "fee",
    },
    {
      tag: "bnxPrice",
      title: "bnxPrice",
    },
    {
      tag: "notionalValue",
      title: "notionalValue",
    },
    {
      tag: "tradeTime",
      title: "tradeTime",
    },
    {
      tag: "bnxQuantity",
      title: "bnxQuantity",
    },
    {
       tag: "formattedBnxQuantity",
       title: "formattedBnxQuantity",
    },
    {
        tag: "productAmount",
        title: "productAmount",
    },
    {
        tag: "side",
        title: "side",
    },
    {
        tag: "newUtcTime",
        title: "newUtcTime",
    },
    {
        tag: "newUtcTimeTrunc",
        title: "newUtcTimeTrunc",
    },
    {
        tag: "banexcoinPriceQuantity",
        title: "banexcoinPriceQuantity",
    },
    {
        tag: "mediaPrice",
        title: "mediaPrice",
    },
    {
        tag: "mediaPriceSeek",
        title: "mediaPriceSeek",
    },
    {
        tag: "mediaPriceQuantity",
        title: "mediaPriceQuantity",
    },
    {
        tag: "spread",
        title: "spread",
    },
    {
        tag: "percent",
        title: "percent",
    }
  ];

  const filters = [
    {
      values: {
        title: "Desde",
        name: "initDate",
        value: filterInputs.initDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Hasta",
        name: "endDate",
        value: filterInputs.endDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="beta-content-container">
            <div className="all-page-data">
              <HeaderPage
                title="Spread - Cálculo del Spread"
                subTitle="Reportes / Reporte general / Spread - Cálculo del Spread"
                headerOptions={headerOptions}
              />
              <TableContainer
                rows={rows}
                loading={loading}
                headers={tableHeaders}
                footers={!isEmpty(rows) && !loading ? tableFooters : null}
                rowIdSelected={""}
                enablePagination={false}
              >
                <GroupFilters
                  handleSearchFilters={handleSearchFilters}
                  withRightSection={false}
                  inputs={filters}
                />
              </TableContainer>
            </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user:{userInfo},product:{ decimalPlaces } }) => {
    return { userInfo,decimalPlaces };
  };
  

SpreadReport.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(SpreadReport);
