import { useState } from 'react';
import empty from 'is-empty';

export const useForm = (initialState = {}, callback, customValidations) => {
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState({});

  const onInputChange = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      [e.target.name]: e.target.value
    }));
  };

  const onInputChangeV2 = e => {
    const { value, name } = e;
    setInputs(inputs => ({
      ...inputs,
      [name]: value
    }));
  };

  const onInputCheckChange = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      [e.target.name]: e.target.checked
    }));
  };

  const onInputChangeByName = (name, value) => {
    setInputs(inputs => ({
      ...inputs,
      [name]: value
    }));
  };

  const onInputFileChange = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      [e.target.name]: e.target.files[0]
    }));
  };

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    setInputs(inputs => ({
      ...inputs,
      validated: true
    }));

    let errorsCustom = customValidations(inputs);
    setErrors(errorsCustom);
    if (form.checkValidity() && empty(errorsCustom)) {
      callback(inputs);

      if (!empty(form.id) && form.id != 'form-admin-levels') {
        form.reset();
      }
    }
  };

  return {
    onInputChange,
    onInputChangeV2,
    onInputChangeByName,
    onInputCheckChange,
    onInputFileChange,
    onSubmit,
    setInputs,
    setErrors,
    errors,
    inputs
  };
};
