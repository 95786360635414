export const spreadHeaders = [
    {
       tag: "fechaUtc",
       title: "fechaUtc",
    },
    {
       tag: "accountId",
       title: "accountId",
    },
    {
       tag: "accountName",
       title: "accountName",
    },
    {
       tag: "referenceId",
       title: "referenceId",
    },
    {
       tag: "referenceType",
       title: "referenceType",
    },
    {
       tag: "side",
       title: "side",
    },
    {
       tag: "productId",
       title: "productId",
    },
    {
       tag: "product",
       title: "product",
    },
    {
       tag: "productFullName",
       title: "productFullName",
    },
    {
       tag: "creditAmount",
       title: "creditAmount",
    },
    {
       tag: "debitAmount",
       title: "debitAmount",
    },
    {
       tag: "spread",
       title: "spread",
    },
    {
       tag: "transactionType",
       title: "transactionType",
    },
    {
       tag: "instrumentId",
       title: "instrumentId",
    },
    {
       tag: "instrumentSymbol",
       title: "instrumentSymbol",
    },
    {
       tag: "fee",
       title: "fee",
    },
    {
       tag: "bnxPrice",
       title: "bnxPrice",
    },
    {
       tag: "bnxNotionalValue",
       title: "bnxNotionalValue",
    },
    {
       tag: "tradeTime",
       title: "tradeTime",
    },
    {
       tag: "bnxQuantity",
       title: "bnxQuantity",
    },
 ]