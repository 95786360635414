import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import { editCorporateShareholderInfo } from './AdminLevelsHooks';
import empty from 'is-empty';
import queryString from 'query-string';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSecureGetParams } from '../../helpers/lib';
import { LEVELS_STATUS } from '../../helpers/constants';
import { preparePaginationOptions } from '../../helpers/pagination';
import { submitFormData } from '../Level/IntermediateLevelPageHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faMinusCircle,
  faQuestionCircle,
  faPaperPlane,
  faPen,
  faUserLock,
  faUserEdit,
  faSave
} from '@fortawesome/free-solid-svg-icons';
import { DatePickerInput } from 'rc-datepicker';
import PhoneInput from 'react-phone-input-2';
import { useForm } from '../../hooks/formHooks';
import Countries from '../../components/Countries';
import SimpleAutoForm from './SimpleAutoForm';
import { states } from '../../config/staticWorldContent';
import { province } from '../../config/staticPeruProv';
import { districts } from '../../config/staticPeruDist';

const GetFilename = url => {
  if (url) {
    return decodeURI(url.split('/').pop());
  }
  return '';
};

const layoutClasses = getBEMClasses('admin-levels-page');

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const AdminLevelsViewPageForm = (props, context) => {
  const {
    levelBasicInfo,
    userInfo,
    accounts,
    countries,
    schemaFull,
    loading,
    setLoading,
    getUserLevelById,
    closeSidebarFormShareholder,
    userId
  } = props;

  const [originalInputs, setOriginalInputs] = useState({});
  const [imagesBlob, setImagesBlob] = useState({});

  const setBlobByName = (name, blob) => {
    setImagesBlob(imagesBlob => ({
      ...imagesBlob,
      [name]: blob
    }));
  };
  async function setValidate(val) {
    setInputs(inputs => ({
      ...inputs,
      validated: val
    }));
  }

  const onSubmitCallback = async inputs => {
    try {
      setLoading(1);
      let datos = {
        id: levelBasicInfo.id,
        userId: userId,
        ...inputs
      };
      let result = await editCorporateShareholderInfo(userInfo.UserId, datos);
      if (result.data.message === 'success') {
        getUserLevelById();
        closeSidebarFormShareholder();
        toast.success(context.t('Registro editado exitosamente'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.log('error', error);
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const customValidations = inputs => {
    let errors = {};
    return errors;
  };

  let initialState = {
    validated: false
  };

  const onInputFileChange2 = e => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    if (!empty(e.target.files[0])) {
      setInputs(inputs => ({
        ...inputs,
        [e.target.name]: e.target.files[0]
      }));
    } else {
      setInputs(inputs => ({
        ...inputs,
        [e.target.name]: originalInputs[e.target.name]
      }));
    }
  };

  let {
    inputs,
    setInputs,
    onInputChange,
    onSubmit,
    onInputChangeByName,
    onInputFileChange
  } = useForm(initialState, onSubmitCallback, customValidations);

  async function setDataForms(data) {
    try {
      let form = JSON.parse(data.dataForm);
      if (!empty(form)) {
        setOriginalInputs(form);
        setInputs(form);
      }
    } catch (error) {
      console.log('error setDataForms', error);
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  useEffect(
    () => {
      if (!empty(levelBasicInfo)) {
        setDataForms(levelBasicInfo);
      }
    },
    [levelBasicInfo]
  );

  if (empty(userInfo) || empty(accounts) || empty(levelBasicInfo)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const getTitle = data => {
    let h = [];
    let form = JSON.parse(data.dataForm);
    if (data.hasOwnProperty('shareholderType')) {
      if (data.shareholderType === 'COMPANY_SHAREHOLDER') {
        h.push(
          <h3 key={1}>
            Editar datos del accionista corporativo{' '}
            <b>{form.company_legal_name}</b>
          </h3>
        );
      } else {
        h.push(
          <h3 key={2}>
            Editar datos del accionista{' '}
            <b>{`${form.first_name} ${form.last_name}`}</b>
          </h3>
        );
      }
    } else {
      h.push(
        <h3 key={3}>
          Editar datos del usuario corporativo{' '}
          <b>{levelBasicInfo.banexcoinUsername}</b>
        </h3>
      );
    }
    return h;
  };

  let buttonStatus = true;
  if (!empty(inputs.document_number)) {
    if (inputs.document_country === 'PE') {
      if (inputs.document_type === 'ID') {
        if (
          inputs.document_number.length < 8 ||
          inputs.document_number.length > 12
        ) {
          buttonStatus = true;
        } else {
          buttonStatus = false;
        }
      } else {
        if (inputs.document_type === 'PP') {
          if (inputs.document_number.length <= 12) {
            buttonStatus = false;
          } else {
            buttonStatus = true;
          }
        }
      }
    } else {
      buttonStatus = false;
    }
  }

  return (
    <React.Fragment>
      <Card className="form-sidebar-beta corporate">
        <Card.Body>
          <div className="title-beta-box">{getTitle(levelBasicInfo)}</div>
          <Form
            noValidate
            validated={inputs.validated}
            encType="multipart/form-data"
            onSubmit={onSubmit}
            className="form-beta corporate">
            <SimpleAutoForm
              formInfo={originalInputs}
              newFormInfo={inputs}
              schemeForm={schemaFull}
              onInputChange={onInputChange}
              onInputChangeByName={onInputChangeByName}
              onInputFileChange={onInputFileChange2}
              setBlobByName={setBlobByName}
              imagesBlob={imagesBlob}
            />
            <Button
              variant="success"
              type="submit"
              disabled={buttonStatus}
              style={{ padding: '1em' }}
              className="submitbeta">
              <FontAwesomeIcon icon={faSave} className="spc" />
              <span>{context.t('Actualizar')}</span>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

AdminLevelsViewPageForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AdminLevelsViewPageForm);
