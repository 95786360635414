export const commissionsHeaders = [
  {
    tag:"origen",
    title:"Origen",
  },
  {
    tag: "numVoucher",
    title: "Num.Voucher",
  },
  {
    tag: "fecha",
    title: "Fecha",
  },
  {
    tag: "cuenta",
    title: "Cuenta",
  },
  {
    tag: "montoDebe",
    title: "Monto Debe",
  },
  {
    tag: "montoHaber",
    title: "Monto Haber",
  },
  {
    tag: "monedaSd",
    title: "Moneda S/D",
  },
  {
    tag: "tCambio",
    title: "T.Cambio",
  },
  {
    tag: "doc",
    title: "Doc",
  },
  {
    tag: "numDoc",
    title: "Num.Doc",
  },
  {
    tag: "fecDoc",
    title: "Fec.Doc",
  },
  {
    tag: "fecVen",
    title: "Fec.Ven",
  },
  {
    tag: "codProvClie",
    title: "Cod.Prov.Clie",
  },
  {
    tag: "cCosto",
    title: "C.Costo",
  },
  {
    tag: "presupuesto",
    title: "Presupuesto",
  },
  {
    tag: "fEfectivo",
    title: "F.Efectivo",
  },
  {
    tag: "glosa",
    title: "Glosa",
  },
  {
    tag: "libroCVR",
    title: "Libro C/V/R",
  },
  {
    tag: "mtoNeto1",
    title: "Mto.Neto 1",
  },
  {
    tag: "mtoNeto2",
    title: "Mto.Neto 2",
  },
  {
    tag: "mtoNeto3",
    title: "Mto.Neto 3",
  },
  {
    tag: "mtoNeto4",
    title: "Mto.Neto 4",
  },
  {
    tag: "mtoNeto5",
    title: "Mto.Neto 5",
  },
  {
    tag: "mtoNeto6",
    title: "Mto.Neto 6",
  },
  {
    tag: "mtoNeto7",
    title: "Mto.Neto 7",
  },
  {
    tag: "mtoNeto8",
    title: "Mto.Neto 8",
  },
  {
    tag: "mtoNeto9",
    title: "Mto.Neto 9",
  },
  {
    tag: "mtoIgv",
    title: "Mto.IGV",
  },
  {
    tag: "refDoc",
    title: "Ref.Doc",
  },
  {
    tag: "refNumDoc",
    title: "Ref.Num.Doc",
  },
  {
    tag: "refFecha",
    title: "Ref.Fecha",
  },
  {
    tag: "detNum",
    title: "Det.Num",
  },
  {
    tag: "detFecha",
    title: "Det.Fecha",
  },
  {
    tag: "ruc",
    title: "RUC",
  },
  {
    tag: "rSocial",
    title: "R.Social",
  },
  {
    tag: "tipo",
    title: "Tipo",
  },
  {
    tag: "tipDocIden",
    title: "Tip.Doc.Iden",
  },
  {
    tag: "medioDePago",
    title: "Medio de Pago",
  },
  {
    tag: "apellido1",
    title: "Apellido 1",
  },
  {
    tag: "apellido2",
    title: "Apellido 2",
  },
  {
    tag: "nombre",
    title: "Nombre",
  },
  {
    tag: "tBien",
    title: "T.Bien",
  }
];