import isEmpty from "is-empty";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AllDepositData from "../../../../components/FallComponents/DepositsPage/AllDepositData";
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../../hooks/useFilter";
import DepositController from "../../../FallControllers/DepositController";
import RightResizable from "../../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../../images/new_icons/close-circle.svg";
import DepositDetails from "../../../../components/FallComponents/DepositsPage/DepositDetails/DepositDetails";
import ImagesZoom from "../../../../components/FallComponents/ImagesZoom/ImagesZoom";
import { getSecureGetParams } from "../../../../helpers/lib";
import BankTransactionsController from "../../../FallControllers/BankTransactionsController";
import { Context as ContextUser } from '../../../../providers/UserProvider'

const DepositsPage = (props, context) => {
  const { userInfo } = props;

  const { 
    modulesByUserProfile,
    handleWritePermission
 } = useContext(ContextUser);

  const [changeLimit, setChangeLimit] = useState(false);
  const [withRightSection, setWithRightSection] = useState(false);
  const [balance, setBalance] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limits, setLimits] = useState({});
  const [ticketInfo, setTicketInfo] = useState({});
  const [depositInfo, setDepositInfo] = useState({});
  const [commentInfo, setCommentInfo] = useState({});
  const [editPermission, setEditPermission] = useState(false);

  const [imagesZoom, setImagesZoom] = useState({ show: false, images: null });

  const [selectedDeposit, setSelectedDeposit] = useState({
    RequestCode: null,
    AccountId: null,
  });
  const [loadingInfoByID, setLoadingInfoByID] = useState(true);

  const [levelInfo, setLevelInfo] = useState({});
  const [levelCorporate, setLevelCorporate] = useState({});

  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    resetFilters,
    onChangeLimit,
    setFilterSearch,
  } = useFilter({ totalRows });

  const handleImagesToZoom = (images) => {
    if (!isEmpty(images)) {
      setImagesZoom({ show: true, images });
    }
  };

  const handleClose = () => {
    setImagesZoom({ show: false, images: null });
  };

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const handleSelectRow = ({ row }) => {
    setWithRightSection(true);
    if (row.RequestCode !== selectedDeposit.RequestCode) {
      setSelectedDeposit({
        RequestCode: row.RequestCode,
        AccountId: row.AccountId,
      });
    }
  };

  const handleSyncBankTransactions = (data) => {
    BankTransactionsController.syncBankTransactions({
      userInfo,
      data,
      setLoading,
      setBalance,
    });
  };

  const resetState = () => {
    setLimits({});
    setTicketInfo({});
    setDepositInfo({});
    setCommentInfo({});
    setLevelInfo({});
    setLevelCorporate({});
  };

  const getTicketByIdInit = () => {
    DepositController.loadDepositByID({
      userInfo,
      selectedDeposit,
      setLoadingInfoByID,
      setLimits,
      setTicketInfo,
      setDepositInfo,
      setCommentInfo,
      setLevelInfo,
      setLevelCorporate,
      resetState,
      context,
    });
  };

  useEffect(() => {
    onChangeLimit(300);
    setChangeLimit(true);
    DepositController.getProducts({
      setProductList,
      context,
    });
  }, []);

  useEffect(() => {
    if (
      !isEmpty(userInfo) &&
      selectedDeposit.RequestCode &&
      selectedDeposit.AccountId
    ) {
      handleImagesToZoom();
      getTicketByIdInit();
    }
  }, [selectedDeposit]);

  const getAllDeposits = async () => {
    const result = await DepositController.loadDeposits({
      setLoading,
      userInfo,
      totalRows,
      setRows,
      setTotalRows,
      filterConfig,
      filterSearch,
      context,
    });
    const lastParam = window.location.pathname.split("/").reverse()[0];
    if (lastParam === "depositos") return;
    const deposit = result.find((deposit) => deposit.TicketNumber == lastParam);
    if (!isEmpty(deposit)) {
      setSelectedDeposit({
        RequestCode: deposit.RequestCode,
        AccountId: deposit.AccountId,
      });
      setWithRightSection(true);
      return;
    }
    const paramDecoded = getSecureGetParams(lastParam);
    if (!isEmpty(paramDecoded)) {
      setSelectedDeposit({
        RequestCode: paramDecoded.requestCode,
        AccountId: paramDecoded.accountId,
      });
      setWithRightSection(true);
      return;
    }
  };

  useEffect(() => {
    if (isEmpty(userInfo) || !changeLimit || isEmpty(modulesByUserProfile)) return;
    setEditPermission(handleWritePermission('depositos'))
    getAllDeposits();
    if(isEmpty(balance)){
      BankTransactionsController.getBankAccountBalance({ userInfo, setBalance });
    }
  }, [modulesByUserProfile, userInfo, filterConfig, filterSearch]);

  const minWidth = imagesZoom.show ? "1000px" : "585px";
  const leftSection = imagesZoom.show ? (
    <ImagesZoom images={imagesZoom.images} onClose={handleClose} />
  ) : null;

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        minWidth={minWidth}
        childrenOne={
          <AllDepositData
            setFilterSearch={setFilterSearch}
            resetFilters={resetFilters}
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            productList={productList}
            handleSelectRow={handleSelectRow}
            selectedId={selectedDeposit.RequestCode}
            syncBankTransactions={handleSyncBankTransactions}
            balance={balance}
            editPermission={editPermission}
          />
        }
        childrenTwo={
          <RightResizable
            headerOptions={headerOptions}
            loadingData={{
              title: "Cargando ticket",
              isLoading: loadingInfoByID,
            }}
            leftSection={leftSection}
          >
            <DepositDetails
              ticketInfo={ticketInfo}
              levelInfo={levelInfo}
              levelCorporate={levelCorporate}
              depositInfo={depositInfo}
              getTicketByIdInit={getTicketByIdInit}
              limits={limits}
              commentInfo={commentInfo}
              handleImagesToZoom={handleImagesToZoom}
              editPermission={editPermission}
              getAllDeposits={getAllDeposits}
            />
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

DepositsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DepositsPage);
