import React, { useState } from 'react';
import HeaderPage from '../HeaderPage/HeaderPage';
import TableContainer from '../Table/TableContainer';
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import { GroupFilters } from '../Filters/Filters';
import { useForm } from '../../../hooks/formHooks';
import { OPTIONS_STATUS } from '../../../constants';
import isEmpty from 'is-empty';
import { Badge } from 'react-bootstrap';
import ComponentsHelper from '../../../helpers/Components.helper';
import DateHelper from '../../../helpers/Date.helper';
import './allDepositData.css';
import BankButtons from '../BankTransactionsPage/BankButtons';
const AllDepositData = props => {
  const {
    withRightSection,
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    setFilterSearch,
    resetFilters,
    productList,
    handleSelectRow,
    selectedId = '',
    syncBankTransactions,
    balance,
    editPermission
  } = props;

  const [showFilters, setShowFilters] = useState(false);

  const filterInitialValues = {
    TicketNumber: '',
    ReferenceId: '',
    AccountId: '',
    AccountName: '',
    AssetId: '',
    Amount: '',
    RequestUserName: '',
    Status: ''
  };

  const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(
    filterInitialValues,
    handleSearchFilters
  );

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearchFilters = () => {
    Object.keys(inputsFilters).forEach(element => {
      inputsFilters[element] === '' && delete inputsFilters[element];
    });
    setFilterSearch(inputsFilters);
  };

  const handleResetFilters = () => {
    setInputs(filterInitialValues);
    resetFilters();
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? 'header-option-active' : ''
    },
    {
      icon: RotateRight,
      action: handleResetFilters
    }
  ];

  const inputs = [
    {
      values: {
        title: 'Ticket ID',
        name: 'TicketNumber',
        value: inputsFilters.TicketNumber,
        type: 'number',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Reference ID',
        name: 'ReferenceId',
        value: inputsFilters.ReferenceId,
        type: 'text',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Account ID',
        name: 'AccountId',
        value: inputsFilters.AccountId,
        type: 'number',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Nombre de usuario',
        name: 'AccountName',
        value: inputsFilters.AccountName,
        type: 'text',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Moneda',
        name: 'AssetId',
        value: inputsFilters.AssetId,
        type: 'select',
        options: productList,
        isClearable: true,
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Monto',
        name: 'Amount',
        value: inputsFilters.Amount,
        type: 'number',
        step: 'any',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Solicitado por',
        name: 'RequestUserName',
        value: inputsFilters.RequestUserName,
        type: 'text',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Estado',
        name: 'Status',
        value: inputsFilters.Status,
        type: 'select',
        options: OPTIONS_STATUS,
        isClearable: true,
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    }
  ];

  const showStatusClaim = row => {
    const cryptoAssets = ['BTC', 'USDC', 'USD Coin'];
    if (
      cryptoAssets.includes(row.AssetName) ||
      row.Status === 'Rejected' ||
      isEmpty(row.bankNameDestination)
    ) {
      return null;
    }
    let status = '';
    if (!isEmpty(row.Claim)) {
      status = row.Claim ? 'success' : 'warning';
    }
    return <Badge bg={status} className={`badge-circle-${status}`} />;
  };

  const tableHeaders = [
    {
      tag: 'info',
      title: ' ',
      cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.RequestCode,"Request code"),
      className: 'minimizer no-Selection'
    },
    {
      tag: 'TicketNumber',
      title: 'Ticket ID',
      handleSelectRow
    },
    {
      tag: 'ReferenceId',
      title: 'Reference ID',
      handleSelectRow
    },
    {
      tag: 'AccountId',
      title: 'Account ID',
      handleSelectRow
    },
    {
      tag: 'AccountName',
      title: 'Nombre de usuario',
      handleSelectRow
    },
    {
      tag: 'bankNameDestination',
      title: 'Banco',
      cell: ({ data }) => (
        <p className="text-ellipsis">
          {data.bankNameDestination ? data.bankNameDestination : '-'}
        </p>
      ),
      handleSelectRow
    },
    {
      tag: 'AssetName',
      title: 'Moneda',
      handleSelectRow
    },
    {
      tag: 'Amount',
      title: 'Monto',
      handleSelectRow
    },
    {
      tag: 'RequestUserName',
      title: 'Solicitado por',
      handleSelectRow
    },
    {
      tag: 'Status',
      title: 'Estado',
      handleSelectRow
    },
    {
      tag: 'UpdatedByUserName',
      title: 'Actualizado por',
      handleSelectRow
    },
    {
      tag: 'Claim',
      title: 'Reclamado',
      cell: ({ data }) => showStatusClaim(data),
      className: 'minimizer',
      handleSelectRow
    },
    {
      tag: 'LastUpdateTimeStamp',
      title: 'Fecha de actualización',
      cell: ({ data }) => <p>{DateHelper.formatDate(data.LastUpdateTimeStamp)}</p>,
      handleSelectRow
    }
  ];

  return (
    <div className="all-deposit-data">
      <HeaderPage
        title="Depósitos"
        subTitle="Tickets / Depósitos"
        headerOptions={headerOptions}
      />
      <BankButtons
        balance={balance}
        syncBankTransactions={syncBankTransactions}
        editPermission={editPermission}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}>
        {showFilters && (
          <GroupFilters
            handleSearchFilters={handleSearchFilters}
            withRightSection={withRightSection}
            inputs={inputs}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default AllDepositData;
