export const banksAccountBanexcoin = [
    {
      bank: 'INTERBANK-PE',
      currency: 'USD',
      bankAccount: '108-3001943080'
    },
    {
      bank: 'INTERBANK-PE',
      currency: 'PEN',
      bankAccount: '108-3001943073'
    },
    {
      bank: 'SCOTIA-PE',
      currency: 'USD',
      bankAccount: '0004969054'
    },
    {
      bank: 'SCOTIA-PE',
      currency: 'PEN',
      bankAccount: '0007962010'
    },
    {
      bank: 'BBVA-PE',
      currency: 'PEN',
      bankAccount: '001101830100128469'
    },
    {
      bank: 'BBVA-PE',
      currency: 'USD',
      bankAccount: '001101830100128477'
    },
    {
      bank: 'COMERCIO-PE',
      currency: 'PEN',
      bankAccount: '410201298409'
    },
    {
      bank: 'COMERCIO-PE',
      currency: 'USD',
      bankAccount: '410201298393'
    },
    {
      bank: 'ALFIN-PE',
      currency: 'PEN',
      bankAccount: '01770884700001'
    },
    {
      bank: 'ALFIN-PE',
      currency: 'USD',
      bankAccount: '01770884700002'
    },
    {
      bank: 'BCP-PE',
      currency: 'PEN',
      bankAccount: '1933252040088'
    },
    {
      bank: 'BCP-PE',
      currency: 'USD',
      bankAccount: '1932153983112'
    }
  ];