import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getCountries = async userId => {
  let path = '/api/countries?restricted=true';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getSwiftBanks = async (countryCode, userId) => {
  let path = `/api/swiftbanks/?country_code=${countryCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getFiatAccounts = userId => {
  let path = `/api/fiat-accounts/`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const enableFiatAccount = async (id, userId) => {
  let path = `/api/enable-fiat-accounts/?id=${id}`;
  console.log('PATH', path);
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let path = '/api/save-fiat-accounts';
  // let model = new FormData();

  // model.append('full_name', data.full_name);
  // model.append('country_code', data.country_code);
  // model.append('swift_bank_id', data.swift_bank_id);
  // model.append('currency_code', data.currency_code);
  // model.append('account_number', data.account_number);
  // model.append('account_utility', data.account_utility);
  // model.append('special_instructions', data.special_instructions);

  // console.log('ACCOUNT DATA MODEL',model);
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};
