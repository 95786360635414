import React from "react";
import "./switch.css";
export const Switch = ({
  defaultChecked,
  onChange,
  id,
  disabled=false
}) => {
  return (
    <>
      <input
        defaultChecked={defaultChecked}
        type="checkbox"
        onChange={onChange}
        id={id}
        disabled={disabled}
        hidden
        className="switch-toggle-bnx-account-input"
      />
      <label className="switch-toggle-bnx-account-label" htmlFor={id}>
        <p>Si</p>
        <p>No</p>
      </label>
    </>
  );
};
