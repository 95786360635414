import React, { useState, useEffect, useContext } from 'react';
import empty from 'is-empty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import LogoBnx from './../../../images/logo-banexcoin-white-middleorange.svg';
import MenuIcon from './../../../images/linear-menu.svg';
import {
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
  Form
} from 'react-bootstrap';
import RoleAccessHelper from '../../../helpers/RoleAccess.helper';
import './globalSideMenu.css';
import GlobalSideMenuSubItem from './GlobalSideMenuSubItem';

import CloseSesion from '../../../images/new_icons/close-sesion.svg';
import Setting from '../../../images/new_icons/settingN.svg';

import ArrowDownLight from '../../../images/new_icons/arrow-down-light.svg';
import ProfilesController from '../../../pages/FallControllers/ProfilesController';
import { Context as ContextUser } from '../../../providers/UserProvider';

const GlobalSideMenu = (props, context) => {
  const {
    userInfo,
    selectedAccountId,
    isBeta,
    setBeta,
    location,
    setOpenMenu,
    logout,
    children,
    setPermissions,
    setLoading
  } = props;

  const userProvider = useContext(ContextUser);
  const [navItems, setNavItems] = useState([]);
  const [opened, setOpened] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const getMenuItems = async () => {
    const items  = await  ProfilesController.getModulesByProfile({userInfo, setLoading})
    if(!items) return
    setPermissions && setPermissions(items);
    userProvider && userProvider.setModulesByUserProfile(items);
    const menu = RoleAccessHelper.constructMenu(items, context);
    setNavItems(menu);
  }
  useEffect(() => {
      if (!empty(userInfo) && !empty(selectedAccountId)) {
        getMenuItems() 
      }
    },[userInfo, selectedAccountId]);

  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey,() => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <button
        type="button"
        className={'btn btn-link' + (isCurrentEventKey ? ' opened' : '')}
        onClick={decoratedOnClick}>
        {children}
      </button>
    );
  };
  const toggleMenuSide = () => {
    setOpened(!opened);
    setOpenMenu(!opened);
  };

  const adminVerion = JSON.parse(localStorage.getItem('isBeta')) ? "V2" : "";

  return (
    <React.Fragment>
      <div className={'v-beta sidebar-v2' + (opened ? ' opened' : ' closed')}>
        <img
          src={MenuIcon}
          alt="menu"
          className="logo-menu"
          onClick={toggleMenuSide}
        />
        <div className="logo-v2-container">
          <img src={LogoBnx} />
          <h3>Admin {adminVerion}</h3>
        </div>
        <Accordion>
          {navItems.map((item, index) => {
            if(empty(item.childrens)) return;
            let foundCoincidence = item.childrens.find(
              c => !empty(c.menu) && c.menu.path === location.pathname
            );
            return (
              <Card className='beta-item-v2' key={index}>
                <Card.Header
                  className={!empty(foundCoincidence) ? ' active' : ''}>
                  <ContextAwareToggle eventKey={'men-' + index}>
                    <img src={item.icon} alt="arrow down light" />
                    {context.t(item.name)}
                    <img src={ArrowDownLight} alt="arrow down light" />
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={'men-' + index}>
                  <Card.Body>
                    <GlobalSideMenuSubItem
                      childrens={item.childrens}
                      foundCoincidence={foundCoincidence}
                      closeSide={toggleMenuSide}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
        <div className="user-info">
          <div onClick={() => setShowOptions(!showOptions)}>
            <div className="cont-opt-menu">
              <div className={`chill-cont ${showOptions ? 'active' : ''}`}>
                <div className="fchill">
                  <div className="item-menu">
                    <img src={Setting} alt="Setting" />
                    <p>{context.t('Configuración')}</p>
                  </div>
                  <div className="item-menu" onClick={logout}>
                    <img src={CloseSesion} alt="icon to close session" />
                    <p>{context.t('Cerrar sesión')}</p>
                  </div>
                </div>
              </div>
              <span className="avatar" />
            </div>
            {userInfo.UserName}
          </div>
          <div className="switch-version">
            <Form.Check
              type="switch"
              id="custom-switch"
              onChange={e => {
                e.persist();
                setBeta(!isBeta);
              }}
              checked={isBeta}
            />
          </div>
        </div>
      </div>
      <div className={`container-admin-v2 ${!isBeta ? 'old-admin': '' }`}>
        <img
          src={MenuIcon}
          alt="icon menu"
          onClick={toggleMenuSide}
          className="icon-menu-hamburger"
        />
        {children}
      </div>
    </React.Fragment>
  );
};

GlobalSideMenu.contextTypes = {
  t: PropTypes.func.isRequired
};
GlobalSideMenu.propTypes = {
  logout: PropTypes.func.isRequired
};
const mapStateToProps = ({ user: { userInfo, selectedAccountId } }) => {
  return { userInfo, selectedAccountId };
};

export default connect(mapStateToProps)(GlobalSideMenu);
