import moment from "moment";
import UtilsHelper from "../../helpers/Utils.helper";

export const getAllResume = async (userId) => {
  const path = `/api/blacklist/get/all/resume`;
  return UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
};

export const getBlackListById = async (userId, urlConfig, filter) => {
  const path = `/api/blacklist/get/${urlConfig.type}/${urlConfig.limit}/${urlConfig.page}`;
  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data: filter,
  });
};

export const updateRecord = async (userId, type, editingFields) => {
  const id = editingFields.id;
  delete editingFields.id;
  const path = `/api/blacklist/update/${type}/${id}`;
  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data: editingFields,
  });
};

export const getBlacklistRecords = async (userId, type) => {
  const path = `/api/download/blacklist-records/${type}`;
  const result = await UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
  UtilsHelper.dowloadFile({
    data: result.data,
    name: `blacklist_records_${type}_${moment().format("YYYY-MM-DD")}.csv`,
  });
};



export const uploadFiles = async (data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const path = `/api/upload`;
  const model = new FormData();
  model.append("blacklist_file", data.blacklist_file);
  model.append("list_type", data.list_type);
  return UtilsHelper.sendRequest({
    method: "POST",
    path,
    headers,
    data: model,
  });
};

export const getSpecificListResume = async (userId, name) => {
  const path = `/api/blacklist/get/${name}/resume`;
  return UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
};

export const getQueuesLists = async (userId, urlConfig, filter) => {
  const path = `/api/blacklist/queues/get/${urlConfig.limit}/${urlConfig.page}`;
  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data: filter,
  });
};

export const deleteRecord = async (userId, type, id) => {
  const path = `/api/blacklist/delete/${type}/${id}`;
  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
  });
};

export const addRecordsBlacklist = async (userId, type, editingFields) => {
  const path = `/api/blacklist/add-multi/${type}`;
  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    data: editingFields,
    timeout: 800000,
  });
};

export const uploadRecordsBlacklist = async (userId, name, data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const path = `/api/blacklist/add-csv/${name}`;
  const model = new FormData();
  model.append("upload-blacklist", data);
  model.append("list_type", name);

  return UtilsHelper.sendRequest({
    userId,
    method: "POST",
    path,
    headers,
    data: model,
    timeout: 800000,
  });
};

export const getBlacklistErrorLog = async (userId, id) => {
  const path = `/api/blacklist/logs/${id}`;
  return UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
};



export const getBlacklistErrorLogDownload = async (userId, id) => {
  const path = `/api/download/blacklist-errors-log/${id}`;
  const result = await UtilsHelper.sendRequest({
    userId,
    method: "GET",
    path,
  });
  UtilsHelper.dowloadFile({
    data: result.data,
    name: `blacklist_errors_log_${moment().format("YYYY-MM-DD")}.csv`,
  });
};
