import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  createProfile,
  deleteProfile,
  getModules,
  getModulesByProfile,
  getProfiles,
  updatePermits,
  updateProfile,
} from "../FallServices/ProfilesServices";

class ProfilesController {
  static getAllProfiles = async ({
    userInfo,
    setRows,
    setTotalRows= () => {},
    setLoading = () => {}
  }) => {
    try {
      setLoading(true);
      const response = await getProfiles(userInfo.UserId);
      if (isEmpty(response.data)) {
        setRows([]);
        setTotalRows(0);
        return false;
      }
      const profiles = response.data.map((item) => {
        let profile = {
          name: item.name,
          profileId: item.id,
          permissions: item.assignedModulesByProfileId,
        };
        if (isEmpty(item.assignedModulesByProfileId.nodes)) return profile;
        item.assignedModulesByProfileId.nodes.forEach((role) => {
          const permitsArray = role.permissions.toString();
          profile[role.moduleByModuleId.name] = permitsArray;
        });
        return profile;
      });
      setRows(profiles);
      setTotalRows(profiles.length);
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static getAllModules = async ({
    userInfo,
    setTableHeaders,
  }) => {
    try {
      const response = await getModules(userInfo.UserId);
      if (isEmpty(response.data)) return setTableHeaders([])
      let sortedHeaders = {};
      let headers = [];
      response.data.forEach((item) => {
        if (!sortedHeaders.hasOwnProperty(item.categoryByCategoryId.name)) {
          sortedHeaders[item.categoryByCategoryId.name] = {
            items: [],
          };
        }
        sortedHeaders[item.categoryByCategoryId.name].items.push({
          title: item.name,
          tag: item.name,
          completeTitle: `${item.name} - ${item.categoryByCategoryId.name}`,
          id: item.id,
        });
      });
      Object.keys(sortedHeaders).forEach((item) => {
        headers = [...headers, ...sortedHeaders[item].items];
      });
      setTableHeaders([
        {
          tag: "name",
          title: "Perfiles",
        },
        ...headers,
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  static createProfile = async ({ userInfo, name, setLoadingAction }) => {
    try {
      setLoadingAction(true);
      const response = await createProfile(userInfo.UserId, name);
      if (!isEmpty(response.data) && !isEmpty(response.data.id)) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Perfil añadido exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser guardada",
      });
      return false;
    } finally {
      setLoadingAction(false);
    }
  };
  static deleteProfile = async ({ userInfo, id, setLoadingAction }) => {
    try {
      setLoadingAction(true);
      const response = await deleteProfile(userInfo.UserId, id);
      if (!isEmpty(response.data) && !isEmpty(response.data.id)) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Perfil eliminado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser eliminada",
      });
      return false;
    } finally {
      setLoadingAction(false);
    }
  };
  static updateProfile = async ({ userInfo, id, name }) => {
    try {
      const response = await updateProfile(userInfo.UserId, id, name);
      if (!isEmpty(response.data) && !isEmpty(response.data.id)) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Perfil actualizado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser actualizada",
      });
      return false;
    }
  };
  static updatePermits = async ({ userInfo, data, id, defaultData }) => {
    try {
      ComponentsHelper.toast({
        type: toastTypes.info,
        message: "Actualizando permisos",
      });
      let assignedModules = {};
      data.forEach((item) => {
        const [id, permitValue] = item.split("/");
        if (!assignedModules.hasOwnProperty(id)) {
          assignedModules[id] = [];
        }
        assignedModules[id].push(permitValue);
      });
      let finalAssignedModules = defaultData.permissions.nodes.map(
        (item) => {
          const moduleId = item.moduleByModuleId.id;
          const permits = assignedModules[moduleId];
          return {
            module: {
              id: item.moduleByModuleId.id,
            },
            permissions: permits || [],
          };
        }
      );
      Object.keys(assignedModules).forEach((item) => {
        const itemById = finalAssignedModules.find(
          (el) => el.module.id === item
        );
        if (itemById) return;
        finalAssignedModules.push({
          module: {
            id: item,
          },
          permissions: assignedModules[item],
        });
      });
      const permits = {
        profile: { id },
        assignedModules: finalAssignedModules,
      };
      const response = await updatePermits(userInfo.UserId, permits);
      if (!isEmpty(response.data)) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Permisos actualizados exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser actualizados",
      });
      return false;
    }
  };
static getModulesByProfile = async ({
  userInfo,
  setLoading
}) => {
  try {
    setLoading && setLoading(true);
    const response = await getModulesByProfile(userInfo.UserId);
    return !isEmpty(response.data) ? response.data : []
  } catch (error) {
    ComponentsHelper.toast({
      type: toastTypes.warn,
      message: "La informacion no es accesible",
    });
    return false;
  } finally {
    setLoading && setLoading(false);
  }
};
}

export default ProfilesController;
