import UtilsHelper from "../../helpers/Utils.helper";

export const getAllCategories = async (userId) => {
    const path = '/api/category';
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const createCategory = async (userId, data) => {
    const path = '/api/category';
    const configRequest = {
        userId,
        method: "POST",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const updateCategory = async (userId, categoryId, data) => {
    const path = `/api/category/${categoryId}`;
    const configRequest = {
        userId,
        method: "PUT",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const deleteCategory = async (userId, categoryId) => {
    const path = `/api/category/${categoryId}`;
    const configRequest = {
        userId,
        method: "DELETE",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}