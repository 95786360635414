import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../NaturalCustomersDetails/naturalCustomersDetails.css'
import { TabsContainer } from '../../Tabs/TabsContainer';
import { Context } from '../../../../pages/FallPages/Customers/Natural/ProviderLevels';
import statusComponent from '../../StatusComponent/StatusComponent';
import { CURRENT_LEVEL, LEVELS_STATUS, toastTypes } from '../../../../constants';
import { useForm } from '../../../../hooks/formHooks';
import { RegisterData } from '../../NaturalCustomersPage/Tabs/RegisterData';
import { History } from '../../NaturalCustomersPage/Tabs/History';
import KycData from '../../NaturalCustomersPage/Tabs/KycData';
import { UserBlocked } from '../../NaturalCustomersPage/Tabs/UserBlocked';
import UrlHelper from '../../../../helpers/Url.helper';
import isEmpty from 'is-empty';
import moment from 'moment-timezone';
import NaturalCustomersController from '../../../../pages/FallControllers/NaturalCustomersController';
import DateHelper from '../../../../helpers/Date.helper';
import ComponentsHelper from '../../../../helpers/Components.helper';
import { Comments } from '../Tabs/Comments';
import Coincidence from '../Tabs/Coincidence';
import edit from '../../../../images/new_icons/edit.svg';
import check from '../../../../images/new_icons/check.svg';
import cross from '../../../../images/new_icons/cross.svg';
import { Form } from 'react-bootstrap';
import Cards from '../Tabs/Cards';

const NaturalCustomerDetails = (props,context) => {
  const { handleReniecRequests, handleShowModalAzure, userInfo, sendIdentityMindSubmit, coincidence, setCoincidence, handleRejectKYC, editPermission,handleLoadNaturalUsers, configBanexCard, handleSubmitB89Card } = props
  const {
    handleImagesToZoom,
    userInfo: newUserInfo,
    setLoadingUserInfo,
    setUserInfo,
    setLogs,
    setIsCoink,
    tabSelect,
    setTabSelect,
    setAccountUserInfo,
    setPossibleLevels
  } = useContext(Context);
  const { userData: levelBasicInfo } = newUserInfo;
  const manualKYC = false; 
  const [initialValues, setInitialValues] = useState({});

  const currentLevel = (userLevel, nextLevel, kycStatus) => {
    const prevLevelName = CURRENT_LEVEL.find(l => l.value == userLevel);
    const nextLevelName = CURRENT_LEVEL.find(l => l.value == nextLevel);
    if (!prevLevelName && !nextLevelName) return 'Sin nivel';
    return kycStatus === 7 ? nextLevelName.title : prevLevelName.title;
  };

  const user = {
    fullname: `${levelBasicInfo.firstName} ${levelBasicInfo.middleName} ${levelBasicInfo.lastName} ${levelBasicInfo.secondLastName}`,
    userid: levelBasicInfo.banexcoinUserByBanexcoinUserId.banexcoinUserid,
    username: levelBasicInfo.banexcoinUserByBanexcoinUserId.banexcoinUsername,
    url: UrlHelper.validateImgUrl(levelBasicInfo.facesImages),
    level: currentLevel(levelBasicInfo.userLevel, levelBasicInfo.nextLevel, levelBasicInfo.status),
    status: statusComponent(levelBasicInfo.status, true, LEVELS_STATUS)
  };

  useEffect(() => {
    inputs = {
      ...inputs,
      id: levelBasicInfo.id,
      banexcoin_user_id: !isEmpty(levelBasicInfo.banexcoinUserByBanexcoinUserId)
        ? levelBasicInfo.banexcoinUserByBanexcoinUserId.banexcoinUserid
        : '',
      first_name: levelBasicInfo.firstName,
      middle_name: levelBasicInfo.middleName,
      last_name: levelBasicInfo.lastName,
      second_last_name: levelBasicInfo.secondLastName,
      birthday:  levelBasicInfo.birthday,
      nationality: levelBasicInfo.nationality,
      birth_country: levelBasicInfo.birthCountry,
      address_main: levelBasicInfo.addressMain,
      building: levelBasicInfo.building,
      city: levelBasicInfo.city,
      town: levelBasicInfo.town,
      district: levelBasicInfo.district,
      apt: levelBasicInfo.apt,
      state: levelBasicInfo.state,
      phone: levelBasicInfo.phone,
      zip_code: levelBasicInfo.zipCode,
      is_pep: (levelBasicInfo.isPep || '').toString(),
      pep_document: levelBasicInfo.pepDocument,
      billing_country: levelBasicInfo.billingCountry,
      billing_address: levelBasicInfo.billingAddress,
      billing_state: levelBasicInfo.billingState,
      billing_city: levelBasicInfo.billingCity,
      billing_zip: levelBasicInfo.billingZip,
      billing_building: levelBasicInfo.billingBuilding,
      billing_town: levelBasicInfo.billingTown,
      billing_apt_number: levelBasicInfo.billingAptNumber,
      billing_district: levelBasicInfo.billingDistrict,
      document_type: levelBasicInfo.documentType,
      document_country: levelBasicInfo.documentCountry,
      document_number: levelBasicInfo.documentNumber,
      occupation: levelBasicInfo.occupation,
      occupation_details: levelBasicInfo.occupationDetails,
      work_center: levelBasicInfo.workCenter,
      residence_country: levelBasicInfo.residenceCountry,
      document_data: levelBasicInfo.documentData,
      document_data_new: levelBasicInfo.documentData,
      back_document_data: levelBasicInfo.backDocumentData,
      back_document_data_new: levelBasicInfo.backDocumentData,
      faces_images: levelBasicInfo.facesImages,
      faces_images_new: levelBasicInfo.facesImages,
      proof_of_residence: levelBasicInfo.proofOfResidence,
      personal_ruc: levelBasicInfo.personalRuc || '',
      is_invoice_with_ruc: (levelBasicInfo.isInvoiceWithRuc || '').toString(),
      master_affidavit_document: levelBasicInfo.masterAffidavitDocument,
      master_origin_document: levelBasicInfo.masterOriginDocument,
      master_origin_founds: levelBasicInfo.masterOriginFounds,
      master_worker_type: levelBasicInfo.masterWorkerType,
      ip: levelBasicInfo.ip,
      gender: !isEmpty(levelBasicInfo.gender) ? levelBasicInfo.gender : '',
      document_expiration_date: !isEmpty(levelBasicInfo.documentExpirationDate) ? levelBasicInfo.documentExpirationDate.split("T")[0] : '',
      other_occupation: levelBasicInfo.otherOccupation
    };
    setInputs(inputs);
    setInitialValues(inputs);
    getPossibleLevels()
  }, []);

  const customValidations = inputs => {
    let errors = {};
    if (isEmpty(inputs.birthday)) {
      errors.birthday = true;
    } else {
      const birthdayDate = DateHelper.formatDateToYear(inputs.birthday)
      if (isEmpty(birthdayDate)) {
        errors.birthday = true;
      }
    }
    return errors;
  };

  const getUserDataByIdWithLogs = async () => {
    await NaturalCustomersController.getUserDataById({
      setLoadingUserInfo,
      userInfo,
      newUserInfo,
      setUserInfo,
      setIsCoink,
      setAccountUserInfo,
      setCoincidence,
      setLogs
    });
  };

  const getPossibleLevels = async () => {
    await NaturalCustomersController.getPossibleLevels(
        userInfo.UserId,
        levelBasicInfo.id,
        setPossibleLevels
    )
  }

  const onSubmitCallback = async inputs => {
    try {
      await Promise.all([
        NaturalCustomersController.editFormData(userInfo.UserId, inputs, levelBasicInfo),
        inputs.level ? NaturalCustomersController.updateLevel(userInfo.UserId, levelBasicInfo, inputs.level) : null
      ])
      await Promise.all([getUserDataByIdWithLogs(), handleLoadNaturalUsers()])
    } catch (error) {
      ComponentsHelper.toast({ type: toastTypes.warn, message: error })
    }
  };

  const newSendIdentityMindSubmit = () => sendIdentityMindSubmit(getUserDataByIdWithLogs);

  const resetInitialInputs = val => {
    if (!isEmpty(val)) {
      setInputs({
        ...inputs,
        ...initialValues,
        [val]: initialValues[val]
      });
    } else {
      setInputs(initialValues);
    }
  };

  let {
    inputs,
    setInputs,
    onInputChangeV2,
    onInputFileChange,
    onSubmit
  } = useForm({ validated: false }, onSubmitCallback, customValidations);

  const tabsOptions = [
    {
      title: 'Cliente',
      component: (
        <KycData
          userInfo={userInfo}
          handleReniecRequests={handleReniecRequests}
          handleShowModalAzure={handleShowModalAzure}
          onSubmit={onSubmit}
          inputs={inputs}
          onInputChange={onInputChangeV2}
          onInputFileChange={onInputFileChange}
          reset={resetInitialInputs}
          getUserDataByIdWithLogs={getUserDataByIdWithLogs}
          handlerValidations={newSendIdentityMindSubmit}
          handleRejectKYC={handleRejectKYC}
          editPermission={editPermission}
          handleLoadNaturalUsers={handleLoadNaturalUsers}
          manualKYC={manualKYC}
        />
      ),
      default: true
    },
    {
      title: 'Datos de registro',
      component: (
        <RegisterData
          userInfo={userInfo}
          getUserDataByIdWithLogs={getUserDataByIdWithLogs}
          editPermission={editPermission}
        />
      ),
      default: false
    },
    {
      title: 'Bloqueo de cliente',
      component: (
        <UserBlocked
          userInfo={userInfo}
          getUserDataByIdWithLogs={getUserDataByIdWithLogs}
          editPermission={editPermission}
          handleLoadNaturalUsers={handleLoadNaturalUsers}
        />
      ),
      default: false
    },
    {
      title: 'Comentarios',
      component: <Comments context={context} userInfo={userInfo} levelId={levelBasicInfo.id} sendMessage={editPermission}/>,
      default: false
    },
    {
      title: 'Historial',
      component: <History />,
      default: false
    },
    {
      title: 'Cumplimiento',
      component: <Coincidence coincidence={coincidence} />,
      default: false
    },
    {
      title: 'Tarjetas',
      component: <Cards allInitialSetting={configBanexCard} editPermission={editPermission} handleSubmitB89Card={handleSubmitB89Card} context={context}/>,
      default: false
    }
  ];

  return (
    <div className="right-resizable-user-info">
      <HeaderSection userInfo={user} handleZoomImages={handleImagesToZoom} photo={inputs.faces_images_new} onInputFileChange={onInputFileChange} reset={resetInitialInputs} onSubmit={onSubmit} editPermission={editPermission} manualKYC={manualKYC}/>
      <div className="container-tabs">
        <TabsContainer
          tabsOptions={tabsOptions}
          tabSelect={tabSelect}
          setTabSelect={setTabSelect}
        />
      </div>
    </div>
  );
};


const mapStateToProps = global => {
  const {
    user: { userInfo },
    product: { products }
  } = global;
  return { userInfo, products };
};

NaturalCustomerDetails.contextTypes = {
  t: PropTypes.func.isRequired
};


export default connect(mapStateToProps)(NaturalCustomerDetails);

const HeaderSection = ({ userInfo, handleZoomImages, photo, onInputFileChange, reset, onSubmit, editPermission, manualKYC }) => {
  const { fullname, userid, username, status, level } = userInfo;
  const { onSetReturnDataInput, userInfo: newUserInfo,} = useContext(Context);
  const [editable, setEditable] = useState(false);
  const reference = useRef('faces_images_new');
  const levelsUser = {
    userLevel: newUserInfo.userData.userLevel,
    nextLevel: newUserInfo.userData.nextLevel
  }

  const newPhoto = !isEmpty(photo) ? (typeof photo === 'object' ? URL.createObjectURL(photo) : photo) : '';

  const changeEdit = () => {
    if(!editable){
      reference.current.click()
    }
    setEditable(editable => !editable)
  };

  const handleSubmit = (e) => {
    changeEdit();
    if(typeof photo === 'object'){
      onSubmit(e);
    }
  };

  const handleResetValues = () => {
    changeEdit();
    reset();
  }

  const showCheckboxByUserLevel = level =>
    levelsUser.userLevel === level && levelsUser.nextLevel === level + 1;

  return (
    <div className="header-section">
      <div className='content-image'>
        {manualKYC && (showCheckboxByUserLevel(10) && newUserInfo.userData.status === 1) &&
          <input
            type="checkbox"
            name='faces_images'
            onChange={onSetReturnDataInput}
            id=""
            style={{ marginRight: '10px' }}
          />
        }
        <div className='options-form'>
          <Form.Control
            ref={reference}
            name='faces_images_new'
            type="file"
            accept='image/x-png,image/jpeg,image/heic,image/heif,image/png'
            onChange={onInputFileChange}
          />
        </div>
        {editPermission && 
          <div className='options-edit'>
            <button type='button' onClick={(e) => !editable ? changeEdit() : handleSubmit(e)}>
              <img src={!editable ? edit : check} alt="edit image" />
            </button>
            {editable && (
              <button type="button" onClick={handleResetValues}>
                <img src={cross} alt="cross" style={{ marginLeft: '10px' }} />
              </button>
            )}
          </div>
        }
        <img
          className="profile-image"
          src={newPhoto}
          alt="profile image"
          onError={ev => UrlHelper.addDefaultSrcImage(ev)}
          onClick={() => handleZoomImages([{ src: newPhoto, alt: 'profile image' }])}
        />
      </div>
      <div className="text-right">
        <h2 className='uppercase'>{fullname}</h2>
        <div className="info">
          <div>
            <div className="option">
              <p>UserID:</p>
              <p>{userid}</p>
            </div>
            <div className="option">
              <p>Usuario:</p>
              <p>{username}</p>
            </div>
            <div className="option">
              <p>Nivel actual:</p>
              <p>{level}</p>
            </div>
          </div>
          <div>
            <p>Estado:</p>
            <p>{status}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
