import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import empty from "is-empty";
import PropTypes from "prop-types";
import { confirmAlert } from "react-confirm-alert";
import "./appMarketing.css";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Table, Button, Container } from "reactstrap";
import AppPagination from "./AppPagination";
import AppMarketingModal from "./AppMarketingModal";
import AppMarketingModalSync from "./AppMarketingModalSync";
import UncontrolledLottie from "./Components/UncontrolledLottie"
import {
  getAllListAppMarketing,
  deleteListAppMarketing,
  createListAppMarketing,
} from "./AppMarketingController";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTrash, faPen, faUserMinus, faRocket } from '@fortawesome/free-solid-svg-icons';

const AppMarketing = (props, context) => {
  const { userInfo } = props;
  const [loading, setLoading] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showLottie, setShowLottie] = useState(false);
  const [showModalSync, setShowModalSync] = useState(false);
  const [list, setList] = useState([]);
  const [getList, setGetList] = useState([]);
  const [data, setData] = useState({});
  const [action, setAction] = useState();
  const [isChecked, setIsChecked] = useState(data.tags || []);
  const [inputs, setInputs] = useState({
    overwrite: true,
    subscription_status: true
  });
  const [errors, setErrors] = useState({});
  const getListsAppMarketing = async () => {
    setLoading(1);
    try {
      const sync = await getAllListAppMarketing(userInfo.UserId);
      setList(sync);
      const listId = sync.map((s) => {
        return {
          value: s.id,
          label: s.name,
        };
      });
      setGetList(listId);
    } catch (error) {
      toast.warn(context.t("Data could not be obtained"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(0);
  };

  const deleteContactsFromList = async (items) => {
    try {
      setLoading(1);
      const datosList = {
        name: items.name,
        type: items.type,
        optin: items.optin,
        tags: items.tags,
      };
      const deletelist = await deleteListAppMarketing(userInfo.UserId, items.id);
      const createList = await createListAppMarketing(
        userInfo.UserId,
        datosList
      );
      setLoading(0);
      getListsAppMarketing();
      if (!empty(deletelist) && !empty(createList)) {
        toast.success(context.t("Successfully deleted contacts"), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.warn(context.t("Information could not be saved"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(0);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: context.t("Delete list"),
      message: context.t("Are you sure you want to delete this list?"),
      buttons: [
        {
          label: context.t("Accept"),
          onClick: () => {
            deleteList(id);
          },
        },
        {
          label: context.t("Cancel"),
          onClick: () => {},
        },
      ],
    });
  };

  const confirmDeleteContacts = (items) => {
    confirmAlert({
      title: context.t("Remove contacts from list"),
      message: context.t(
        "Are you sure you want to remove the contacts from the list?"
      ),
      buttons: [
        {
          label: context.t("Accept"),
          onClick: () => {
            deleteContactsFromList(items);
          },
        },
        {
          label: context.t("Cancel"),
          onClick: () => {},
        },
      ],
    });
  };

  const deleteList = async (id) => {
    setLoading(1);
    try {
      const deletelist = await deleteListAppMarketing(userInfo.UserId, id);
      setLoading(0);
      getListsAppMarketing();
      if (!empty(deletelist)) {
        toast.success(context.t("Successfully removed list"), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.warn(context.t("Information could not be saved"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(0);
  };

  useEffect(() => {
    if (!empty(userInfo)) {
      getListsAppMarketing();
    }
  }, [userInfo]);

  const newList = () => {
    setShowModal(true);
    setAction("crear");
    setData({});
    setIsChecked([]);
    setInputs({});
    setErrors({});
  };

  const syncList = (items) => {
    setShowModalSync(true);
    setAction("crear");
    setData(items);
    setIsChecked(items.tags);
  };

  const editList = (items) => {
    setShowModal(true);
    setAction("edit");
    setIsChecked(items.tags);
    setInputs(items);
    setErrors({});
  };

  const classType = (value) => {
    return value.type === "private" ? "tag private" : "tag public";
  };

  const renderRow = (list) => {
    return list.map((items) => {
      return (
        <tr key={items.id}>
          <td>{items.name.toUpperCase()}</td>
          <td className="tags">
            <span className={classType(items)}>{context.t(items.type)}</span>
            <br />
            <span className="tag single">{context.t(items.optin)}</span>
          </td>
          <td>{items.subscriber_count}</td>
          <td className="tags">
            <div className="tags-levels">
              {items.tags.sort().map((t) => (
                <span className="tag is-small">
                  {"Nivel " + t.toUpperCase()}
                </span>
              ))}
            </div>
          </td>
          <td>
            {moment(items.created_at).tz("America/Lima").format("YYYY-MM-DD HH:mm")}
          </td>
          <td>
            {moment(items.updated_at).tz("America/Lima").format("YYYY-MM-DD HH:mm")}
          </td>
          <td>
            <span title={context.t("Synchronize contacts")}>
              <FontAwesomeIcon icon={faRocket} onClick={()=>syncList(items)} />
            </span>
            <span title={context.t("Remove contacts")}>
              <FontAwesomeIcon icon={faUserMinus} onClick={() => confirmDeleteContacts(items)} />
            </span>
            <span title={context.t("Edit list")}>
              <FontAwesomeIcon icon={faPen} onClick={() => editList(items)} />
            </span>
            <span title={context.t("Delete list")}>
              <FontAwesomeIcon icon={faTrash} onClick={() => confirmDelete(items.id)} />
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className="content-app-marketing">
        <Container className="row-app-marketing">
          <div className="container-header app-header">
            <div className="app-tittle">App Marketing ListMonk</div>
            <Button color="primary" onClick={newList}>
              Nuevo
            </Button>
          </div>
          <AppPagination />
          <div className="container-table">
            <Table hover>
              <thead>
                <tr>
                  <th>
                    <div className="is-relative">
                      <p className="p-1">Nombre</p>
                    </div>
                  </th>
                  <th>
                    <div className="is-relative">
                      <p className="p-1">Tipo</p>
                    </div>
                  </th>
                  <th>
                    <div className="is-relative">
                      <p className="p-1">Suscriptores</p>
                    </div>
                  </th>
                  <th>
                    <div className="is-relative">
                      <p className="p-1">Niveles</p>
                    </div>
                  </th>
                  <th>
                    <div className="is-relative">
                      <p className="p-1">Creado</p>
                    </div>
                  </th>
                  <th>
                    <div className="is-relative">
                      <p className="p-1">Actualizado</p>
                    </div>
                  </th>
                  <th>
                    <div className="is-relative">
                      <p className="p-1"></p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>{renderRow(list)}</tbody>
            </Table>
            <AppPagination />
            <AppMarketingModal
              showModal={showModal}
              setShowModal={setShowModal}
              action={action}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              loading={loading}
              setLoading={setLoading}
              getListsAppMarketing={getListsAppMarketing}
              inputs={inputs}
              setInputs={setInputs}
              errors={errors}
              setErrors={setErrors}
            />
            <AppMarketingModalSync
              showModalSync={showModalSync}
              setShowModalSync={setShowModalSync}
              data={data}
              action={action}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              loading={loading}
              setLoading={setLoading}
              getListsAppMarketing={getListsAppMarketing}
              inputs={inputs}
              setInputs={setInputs}
              errors={errors}
              setErrors={setErrors}
              list={list}
              setList={setList}
              getList={getList}
              setShowLottie={setShowLottie}
            />
          </div>
        </Container>
        <div className={showLottie ? "show-lottie" : "noshow-lottie"}>
        <UncontrolledLottie />
      </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AppMarketing.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AppMarketing);
