import React from 'react';
import './headerPage.css';

const HeaderPage = ({ title, subTitle, headerOptions = [] }) => {
  return (
    <div className="container-header-page">
      <div className="title-section">
        <div className="title-section-left">
          <h2>{title}</h2>
          <p>{subTitle}</p>
        </div>
        <div className="options-section">
          {headerOptions.map(({ className, action, icon, visible = true }, key) => (
            visible && <div
              className={`btn-filters ${className ? className : ''}`}
              onClick={action}
              key={key}>
              <img src={icon} alt={`${icon}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderPage;
