import isEmpty from "is-empty";
import {
  getTicketByNumber,
  getTxIds,
  saveTicketByNumber,
  updateTicketByNumber,
} from "../FallServices/TxHashServices";

class TxHashController {
  static getAllTxIds = async ({
    userInfo,
    setLoading,
    setTotalRows,
    setRows,
    filterConfig,
    filterSearch={},
  }) => {
    try {
      setLoading(true);
      let filters = {};
      if(!isEmpty(filterSearch.ticketNumber)){
        filters["ticketNumber"] = { equalTo: Number(filterSearch.ticketNumber) }
      }
      if(!isEmpty(filterSearch.ticketAccountName)){
        filters["banexcoinUserByBanexcoinUserId"] = {
          banexcoinUsername: { includesInsensitive: filterSearch.ticketAccountName.trim() },
        }
      }
      const response = await getTxIds(
        userInfo.UserId,
        filterConfig,
        filters
      );
      setRows(response.data.nodes);
      setTotalRows(response.data.totalCount);
      return true;
    } catch (err) {
      return false;
    } finally {
      setLoading(false);
    }
  };
  static getTicketByNumber = async ({
    userInfo,
    setLoadingTicketByNumber,
    setTicketData,
    selectedTicket,
  }) => {
    try {
      setLoadingTicketByNumber(true);
      const response = await getTicketByNumber(userInfo.UserId, selectedTicket);
      setTicketData(response.data);
      return true;
    } catch (err) {
      return false;
    } finally {
      setLoadingTicketByNumber(false);
    }
  };
  static updateTicketByNumber = async ({
    userInfo,
    setLoadingUpdateTxHash,
    setTicketData,
    ticketData,
    selectedTicket,
    newTxid,
  }) => {
    try {
      setLoadingUpdateTxHash(true);
      const response = await updateTicketByNumber(
        userInfo.UserId,
        selectedTicket,
        newTxid
      );
      if (!isEmpty(response.data)) {
        setTicketData({ ...ticketData, ticketTxid: newTxid });
      }
      return true;
    } catch (err) {
      return false;
    } finally {
      setLoadingUpdateTxHash(false);
    }
  };
  static saveTicketByNumber = async ({
    userInfo,
    setLoadingUpdateTxHash,
    setTicketData,
    ticketData,
    selectedTicket,
    newTxid,
  }) => {
    try {
      setLoadingUpdateTxHash(true);
      const response = await saveTicketByNumber(
        userInfo.UserId,
        selectedTicket,
        newTxid
      );
      if (!isEmpty(response.data)) {
        setTicketData({ ...ticketData, ticketTxid: newTxid });
      }
      return true;
    } catch (err) {
      return false;
    } finally {
      setLoadingUpdateTxHash(false);
    }
  };
}

export default TxHashController;
