import React from 'react';
import { LoaderWithGif } from '../Loader/Loader';
import './rightResizable.css';

const RightResizable = ({
  headerOptions,
  loadingData={},
  leftSection,
  children
}) => {
  return (
    <React.Fragment>
      {loadingData.isLoading ? (
        <div className="loaderResizable">
          <LoaderWithGif message={loadingData.title} />
        </div>
      ) : (
        <React.Fragment>
          {leftSection}
          <div className="right-resizable">
            <div className="header-options">
              {headerOptions.map(option => {
                if (option.component) return option.component;
                return (
                  <img
                    key={option.alt}
                    src={option.image}
                    alt={option.alt}
                    onClick={option.action}
                  />
                );
              })}
            </div>
            {children}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default RightResizable;
