import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import { useForm, submitFormData } from "./ManualDepositWorkflowComponentPageHooks";
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DatePickerInput } from 'rc-datepicker';
import moment from 'moment';
import empty from 'is-empty';
import { useForm } from '../../../../hooks/formHooks';
import {
  submitFormData,
  getFiatAccounts,
  getBanexcoinAccounts
} from './ManualDepositWorkflowComponentPageHooks';
import { toast } from 'react-toastify';
import Loading from '../../../Loading';
import FiatAccounts from '../../../FiatAccounts';
import BanexcoinAccounts from '../../../BanexcoinAccounts';

//CSS
import './ManualDepositWorkflowComponent.css';

const manualDepositWorkflowComponentClasses = getBEMClasses(
  'manual-deposit-workflow-component'
);

const ManualDepositWorkflowComponent = (props, context) => {
  try {
    let { userInfo, product } = props;
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);
    const [fiatAccounts, setFiatAccounts] = useState({});
    const [banexcoinAccounts, setBanexcoinAccounts] = useState({});
    const [fiatAccountSelected, setFiatAccountSelected] = useState({});

    useEffect(() => {
      async function loadFiatAccounts() {
        setLoading(1);

        try {
          let result = await getFiatAccounts(product.Product, userInfo.UserId);
          setFiatAccounts(result.data.fiataccounts);
        } catch (error) {
          console.log('Errors: ', error);
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      }

      if (!empty(userInfo)) {
        loadFiatAccounts();
      }
    }, []);

    useEffect(() => {
      async function loadBanexcoinAccounts() {
        setLoading(1);

        try {
          let result = await getBanexcoinAccounts(
            product.Product,
            userInfo.UserId
          );
          setBanexcoinAccounts(result.data.banexcoinaccounts);
        } catch (error) {
          console.log('Errors: ', error);
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      }

      if (!empty(userInfo)) {
        loadBanexcoinAccounts();
      }
    }, []);

    useEffect(
      () => {
        console.log('EFFECT INPUTS', inputs);

        async function loadFiatInfoFrom() {
          for (let i = 0; i < fiatAccounts.length; i++) {
            if (fiatAccounts[i].id === inputs.account_source) {
              inputs.account_source = fiatAccounts[i].accountNumber;
              inputs.bank_name_source =
                fiatAccounts[i].swiftBankBySwiftBankId.bankName;
              inputs.bank_country_source =
                fiatAccounts[
                  i
                ].swiftBankBySwiftBankId.countryByCountryCode.countryName;
              break;
            }
          }
        }

        if (!empty(inputs.account_source)) {
          console.log('EFFECT', inputs);
          loadFiatInfoFrom();
        }
      },
      [inputs]
    );

    console.log('PRODUCTS', props.product, product, userInfo);

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        let result = await submitFormData(inputs);
        //console.log('onSubmitCallback', result);
        toast.success(context.t('Information saved successfully'), {
          position: toast.POSITION.TOP_CENTER
        });
      } catch (error) {
        //console.log('Errors: ', error);
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const customValidations = inputs => {
      let errors = {};
      if (empty(inputs.transaction_date)) {
        errors.transaction_date = true;
      } else {
        var m = moment(inputs.transaction_date, 'YYYY-MM-DD');
        if (!m.isValid()) {
          errors.birthday = true;
        }
      }

      return errors;
    };

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : '',
      assetId: product.ProductId,
      assetName: product.Product,
      AccountId: userInfo.AccountId
    };

    let {
      inputs,
      errors,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange,
      setInputs
    } = useForm(initialState, onSubmitCallback, customValidations);

    const onFiatAccountChange = e => {
      console.log('onFiatAccountChange', e.target.value);
      e.persist();
      for (let i = 0; i < fiatAccounts.length; i++) {
        if (fiatAccounts[i].id === e.target.value) {
          setInputs(inputs => ({
            ...inputs,
            account_source: fiatAccounts[i].accountNumber,
            bank_name_source: fiatAccounts[i].swiftBankBySwiftBankId.bankName,
            bank_country_source:
              fiatAccounts[i].swiftBankBySwiftBankId.countryByCountryCode
                .countryName
          }));
          break;
        }
      }
    };

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <div className={manualDepositWorkflowComponentClasses()}>
          {/* <h1>{context.t('Deposit Money')}</h1> */}
          {/* <p>
            {context.t('Step 1:')}
            <br />
            {context.t('Create the deposit ticket.')}
            <br />
            {context.t('Step 2:')}
            <br />
            {context.t('Process deposit instructions on the deposit receipt.')}
          </p> */}

          <Form noValidate validated={inputs.validated} onSubmit={onSubmit}>
            <Row>
              <Col xl="5">
                <Form.Group controlId="transaction_number">
                  <Form.Label>{context.t('Transaction Number')}</Form.Label>
                  <Form.Control
                    required
                    name="transaction_number"
                    type="text"
                    onChange={onInputChange}
                    placeholder={context.t('Transaction Number')}
                  />
                </Form.Group>
              </Col>
              <Col xl="4">
                <Form.Group controlId="transaction_date">
                  <Form.Label>
                    {context.t('Transaction Date')} <span>*</span>
                  </Form.Label>
                  <DatePickerInput
                    showOnInputClick
                    value={inputs.transaction_date}
                    onChange={value => {
                      onInputChangeByName('transaction_date', value);
                    }}
                    displayFormat="DD/MM/YYYY"
                    returnFormat="YYYY-MM-DD"
                    name="transaction_date"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={errors.birthday ? 'showError' : ''}>
                    {context.t('You must choose a valid date of transaction')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="3">
                <Form.Group controlId="amount">
                  <Form.Label>
                    {context.t('Amount')} <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="amount"
                    type="text"
                    onChange={onInputChange}
                    placeholder={context.t('Amount')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must put a amount')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <Form.Group controlId="account_source">
                  <Form.Label>
                    {context.t('Account Source')} <span>*</span>
                  </Form.Label>
                  <FiatAccounts
                    fiatAccounts={fiatAccounts}
                    onChange={onFiatAccountChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must select the Account Source')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <Form.Group controlId="account_destination">
                  <Form.Label>
                    {context.t('Account Destination')} <span>*</span>
                  </Form.Label>
                  <BanexcoinAccounts
                    banexcoinAccounts={banexcoinAccounts}
                    onChange={onInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must select the Account Destination')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <div className="form-group">
                  <Form.Text className="text-muted">
                    * {context.t('Required fields')}
                  </Form.Text>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <Button
                  className="custom__btn orange_custom"
                  variant="primary"
                  type="submit"
                  style={{ padding: '1em', width: '100%' }}>
                  {context.t('Place Deposit Ticket')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.log('Error');
    console.log(e);
    return <div>Error:</div>;
  }
};

ManualDepositWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

export default connect(mapStateToProps)(ManualDepositWorkflowComponent);
