export const alertCalificationTypes = [
    {
      value: 'No Inusual',
      name: 'No Inusual'
    },
    {
      value: 'Justificada',
      name: 'Justificada'
    },
    {
      value: 'Con Riesgo LAFT',
      name: 'Con Riesgo LAFT'
    }
  ];
  