import React, { useState } from 'react'
import Setting from '../../../images/new_icons/setting.svg'
import SettingLight from '../../../images/new_icons/setting-light.svg'
import RotateRight from '../../../images/new_icons/rotate-right.svg'
import HeaderPage from '../HeaderPage/HeaderPage'
import ComponentsHelper from '../../../helpers/Components.helper'
import TableContainer from '../Table/TableContainer'
import { GroupFilters } from '../Filters/Filters'
import { useForm } from '../../../hooks/formHooks'
import { alphapointAccountTypes, alphapointRiskTypes } from '../../../constants'
import isEmpty from 'is-empty'

const AllAlphapointAccountsData = (props) => {

    const {
        handleSelectRow,
        loading,
        rows,
        totalRows,
        withRightSection,
        loadData,
        filterConfig,
        offset,
        showingTo,
        changePage,
        onChangeLimit,
        accountId,
        setAllfilters,
        initialConfig
    } = props;

    const filterInitialValues = {
        AccountId: '',
        AccountName: '',
        AccountType: '',
        RiskType: '',
        VerificationLevel: ''
    };

    const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(filterInitialValues);

    const [showFilters, setShowFilters] = useState(false);

    const handleShowFilters = () => (setShowFilters(!showFilters));

    const handleSearchFilters = () => {
        let newFilters = inputsFilters;
        Object.keys(newFilters).forEach(element => {
            isEmpty(newFilters[element]) && delete newFilters[element];
        });
        const filterList = Object.entries(newFilters);
        const result = filterList.map((data) => (`${data[0]}=${data[1].replaceAll(' ', '')}`)).toString().replaceAll(',', '&');
        if (!isEmpty(result)) loadData(result);
    };

    const handleResetFilters = () => {
        setInputs(filterInitialValues);
        setAllfilters('');
        initialConfig();
    };

    const resRiskType = {
        All: -1,
        Unknown: 0,
        Normal: 1,
        NoRiskCheck: 2,
        NoTrading: 3,
        Credit: 4,
    };
    
    const resAccountType = {
        All: -1,
        Asset: 0,
        Liability: 1,
        Receivable: 2,
        MarginAsset: 3,
    };

    const handleName = (obj, value) => {
        let response = '-';
        Object.keys(obj).forEach(key => {
            if (obj[key] === value) response = key;
        });
        return response;
    }

    const headerOptions = [
        {
            icon: showFilters ? SettingLight : Setting,
            action: handleShowFilters,
            className: showFilters ? 'header-option-active' : ''
        },
        {
            icon: RotateRight,
            action: handleResetFilters
        }
    ];

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.accountId, "Id de cuenta"),
            className: 'minimizer no-Selection'
        },
        {
            tag: 'accountId',
            title: 'ID de cuenta',
            handleSelectRow
        },
        {
            tag: 'accountName',
            title: 'Nombre de cuenta',
            className: 'short-column-name',
            handleSelectRow
        },
        {
            tag: '',
            title: 'Tipo de cuenta',
            cell: ({ data }) => handleName(resAccountType, data.accountType),
            handleSelectRow
        },
        {
            tag: '',
            title: 'Tipo de riesgo',
            cell: ({ data }) => handleName(resRiskType, data.riskType),
            handleSelectRow
        },
        {
            tag: '',
            title: 'Nivel de verificación',
            cell: ({ data }) => data.verificationLevel,
            handleSelectRow
        }
    ];

    const inputs = [
        {
            values: {
                title: 'ID de cuenta',
                name: 'AccountId',
                value: inputsFilters.AccountId,
                type: 'number',
                required: true
            },
            actions: {
                onChange: onInputChangeV2
            }
        },
        {
            values: {
                title: 'Nombre de cuenta',
                name: 'AccountName',
                value: inputsFilters.AccountName,
                type: 'string',
                required: true
            },
            actions: {
                onChange: onInputChangeV2
            }
        },
        {
            values: {
                title: 'Tipo de cuenta',
                name: 'AccountType',
                value: !loading ? inputsFilters.AccountType : null,
                type: 'select',
                options: alphapointAccountTypes,
                required: true,
                isClearable: true
            },
            actions: {
                onChange: onInputChangeV2
            }
        },
        {
            values: {
                title: 'Tipo de riesgo',
                name: 'RiskType',
                value: !loading ? inputsFilters.RiskType : null,
                type: 'select',
                options: alphapointRiskTypes,
                required: true,
                isClearable: true
            },
            actions: {
                onChange: onInputChangeV2
            }
        },
        {
            values: {
                title: 'Nivel de verificación',
                name: 'VerificationLevel',
                value: inputsFilters.VerificationLevel,
                type: 'number',
                required: true
            },
            actions: {
                onChange: onInputChangeV2
            }
        },
    ]

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Cuentas AlphaPoint"
                subTitle="Clientes / Cuentas AlphaPoint"
                headerOptions={headerOptions}
            />
            <TableContainer
                loading={loading}
                totalRows={totalRows}
                rows={rows}
                headers={tableHeaders}
                rowIdSelected={accountId}
                filterConfig={filterConfig}
                offset={offset}
                showingTo={showingTo}
                changePage={changePage}
                onChangeLimit={onChangeLimit}
                identifierSlot={'AccountId'}
            >
                {showFilters &&
                    <GroupFilters
                        handleSearchFilters={handleSearchFilters}
                        withRightSection={withRightSection}
                        inputs={inputs}
                    />
                }
            </TableContainer>
        </div>
    )
}

export default AllAlphapointAccountsData