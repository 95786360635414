import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import empty from 'is-empty';
import { currencyFormat } from '../../helpers/lib';

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, instruments, level1, userInfo, accounts };
};

const BankButtons = (props, context) => {
  moment.tz.setDefault('America/Lima');

  let balanceBCP = props.balances.find(b => b.settingsKey.includes('BCP-PE'));
  let balanceBCPInfo = !empty(balanceBCP)
    ? JSON.parse(JSON.parse(balanceBCP.settingsValue))
    : [];
  balanceBCPInfo = balanceBCPInfo.sort(function(a, b) {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  let balanceAlfin = props.balances.find(b => b.settingsKey.includes('ALFIN-PE'));
  let balanceAlfinInfo = !empty(balanceAlfin)
    ? JSON.parse(JSON.parse(balanceAlfin.settingsValue))
    : [];
  balanceAlfinInfo = balanceAlfinInfo.sort(function(a, b) {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  let balanceComercio = props.balances.find(b => b.settingsKey.includes('COMERCIO-PE'));
  let balanceComercioInfo = !empty(balanceComercio)
    ? JSON.parse(JSON.parse(balanceComercio.settingsValue))
    : [];
  balanceComercioInfo = balanceComercioInfo.sort(function(a, b) {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  let balanceInterbank = props.balances.find(b =>
    b.settingsKey.includes('INTERBANK-PE')
  );
  let balanceInterbankInfo = !empty(balanceInterbank)
    ? JSON.parse(JSON.parse(balanceInterbank.settingsValue))
    : [];
  balanceInterbankInfo = balanceInterbankInfo.sort(function(a, b) {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  let balanceScotia = props.balances.find(b =>
    b.settingsKey.includes('SCOTIA-PE')
  );
  let balanceScotiaInfo = !empty(balanceScotia)
    ? JSON.parse(JSON.parse(balanceScotia.settingsValue))
    : [];
  balanceScotiaInfo = balanceScotiaInfo.sort(function(a, b) {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  return (
    <Row className="block-banks">
      <Col xl={3}>
        <Button
          key={'bcp-button'}
          variant="info"
          style={{
            background: '#002a8c',
            border: '#002a8c'
          }}
          onClick={() => {
            props.syncBankTransactions({
              bankCode: 'BCP-PE',
              from: moment()
                .subtract(3, 'days')
                .format('YYYY-MM-DD'),
              to: moment()
                .add(3, 'days')
                .format('YYYY-MM-DD')
            });
          }}>
          {props.context.t('BCP')}
        </Button>
      </Col>
      <Col xl={3}>
        <Button
          key={'alfin-button'}
          variant="info"
          style={{
            background: '#fa4616',
            border: '#fa4616'
          }}
          onClick={() => {
            props.syncBankTransactions({
              bankCode: 'ALFIN-PE',
              from: moment()
                .subtract(3, 'days')
                .format('YYYY-MM-DD'),
              to: moment()
                .add(3, 'days')
                .format('YYYY-MM-DD')
            });
          }}>
          {props.context.t('AlfinBanco')}
        </Button>
      </Col>

      <Col xl={3}>
        <Button
          key={'comercio-button'}
          variant="info"
          style={{
            background: '#0a4481',
            border: '#0a4481'
          }}
          onClick={() => {
            props.syncBankTransactions({
              bankCode: 'COMERCIO-PE',
              from: moment()
                .subtract(3, 'days')
                .format('YYYY-MM-DD'),
              to: moment()
                .add(3, 'days')
                .format('YYYY-MM-DD')
            });
          }}>
          {props.context.t('Comercio')}
        </Button>
      </Col>

      <Col xl={3}>
        <Button
          key={'interbank-button'}
          variant="info"
          onClick={() => {
            props.syncBankTransactions({
              bankCode: 'INTERBANK-PE',
              from: moment()
                .subtract(3, 'days')
                .format('YYYY-MM-DD'),
              to: moment()
                .add(3, 'days')
                .format('YYYY-MM-DD')
            });
          }}>
          {props.context.t('Interbank')}
        </Button>
      </Col>

      <Col xl={3}>
        {!empty(balanceBCPInfo) ? (
          <div className="accountBalance">
            {props.showBalance === true
              ? balanceBCPInfo.map((account, index) => {
                  return (
                    <p key={index}>
                      {!empty(account.balance)
                        ? currencyFormat(
                            account.balance.currency,
                            account.balance.balance
                          )
                        : 'No se pudo obtener el saldo, reintentar sincronización'}
                    </p>
                  );
                })
              : null}
            <p className="info">({balanceBCPInfo[0].date})</p>
            <p className="info-important">
              ({moment(balanceBCPInfo[0].date)
                .tz('America/Lima')
                .from(moment().tz('America/Lima'))})
            </p>
          </div>
        ) : null}
      </Col>

      <Col xl={3}>
        {!empty(balanceAlfinInfo) ? (
          <div className="accountBalance">
            {props.showBalance === true
              ? balanceAlfinInfo.map((account, index) => {
                  return (
                    <p key={index}>
                      {!empty(account.balance)
                        ? currencyFormat(
                            account.balance.currency,
                            account.balance.balance
                          )
                        : 'No se pudo obtener el saldo, reintentar sincronización'}
                    </p>
                  );
                })
              : null}
            <p className="info">({balanceAlfinInfo[0].date})</p>
            <p className="info-important">
              ({moment(balanceAlfinInfo[0].date)
                .tz('America/Lima')
                .from(moment().tz('America/Lima'))})
            </p>
          </div>
        ) : null}
      </Col>

      <Col xl={3}>
        {!empty(balanceComercioInfo) ? (
          <div className="accountBalance">
            {props.showBalance === true
              ? balanceComercioInfo.map((account, index) => {
                  return (
                    <p key={index}>
                      {!empty(account.balance)
                        ? currencyFormat(
                            account.balance.currency,
                            account.balance.balance
                          )
                        : 'No se pudo obtener el saldo, reintentar sincronización'}
                    </p>
                  );
                })
              : null}
            <p className="info">({balanceComercioInfo[0].date})</p>
            <p className="info-important">
              ({moment(balanceComercioInfo[0].date)
                .tz('America/Lima')
                .from(moment().tz('America/Lima'))})
            </p>
          </div>
        ) : null}
      </Col>

      <Col xl={3}>
        {!empty(balanceInterbankInfo) ? (
          <div className="accountBalance">
            {props.showBalance === true
              ? balanceInterbankInfo.map(account => {
                  return (
                    <p>
                      {currencyFormat(
                        account.balance.currency,
                        account.balance.balance
                      )}
                    </p>
                  );
                })
              : null}
            <p className="info">({balanceInterbankInfo[0].date})</p>
            <p className="info-important">
              ({moment(balanceInterbankInfo[0].date)
                .tz('America/Lima')
                .from(moment().tz('America/Lima'))})
            </p>
          </div>
        ) : null}
      </Col>
    </Row>
  );
};
export default connect(mapStateToProps)(BankButtons);
