import React from 'react';
import PropTypes from 'prop-types';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';

import APButton from 'apex-web/lib/components/common/APButton';

var DepositTemplateFormComponent = function DepositTemplateFormComponent(
  _ref,
  context
) {
  var template = _ref.template,
    submitting = _ref.submitting,
    baseClasses = _ref.baseClasses,
    product = _ref.product;

  if (Object.keys(template).length) {
    var viewOnlyForm = Object.values(template).every(function(val) {
      return !!val;
    });

    return React.createElement(
      'div',
      { className: baseClasses('fields') },
      viewOnlyForm &&
        React.createElement(
          'p',
          null,
          context.t('Please use the following information to make a deposit:')
        ),
      templateFormRenderer(template, baseClasses(), context, product),
      !viewOnlyForm &&
        React.createElement(
          'div',
          { className: 'form-group' },
          React.createElement(
            APButton,
            {
              type: 'submit',
              disabled: submitting,
              customClass: baseClasses(),
              styleName: 'additive'
            },
            submitting
              ? context.t('Submitting...')
              : context.t('Place Deposit Ticket')
          )
        )
    );
  }
  return React.createElement('div', null);
};

DepositTemplateFormComponent.defaultProps = {
  product: PropTypes.object,
  template: {},
  submitting: false,
  baseClasses: function baseClasses() {}
};

DepositTemplateFormComponent.propTypes = {
  template: PropTypes.object,
  submitting: PropTypes.bool,
  baseClasses: PropTypes.func
};

DepositTemplateFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositTemplateFormComponent;
