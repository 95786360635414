import isEmpty from 'is-empty';
import queryString from 'query-string'

export default class UrlHelper {
  static getQueryParam = name => {
    const url = window.location.href;
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  static buildSecureGetParams(params) {
    const result = queryString.stringify(params);
    return btoa(result);
  }

  static getSecureGetParams(params) {
    const decodedParam = atob(params)
    return queryString.parse(decodedParam);
  }

  static fixStorageLink = originalLink => {
    if (isEmpty(originalLink)) return null
    const failedPrelink = 'sgp1.digitaloceanspaces.com/banexcoin';
    const containts = originalLink.includes(failedPrelink);
    if (isEmpty(containts)) return originalLink
    const result = decodeURI(originalLink);
    return result.replace(/\s/g, '%20');
  };

  static  isValidUrl = string => {
    try {
      if(isEmpty(string)) return ""
    return new URL(string)
    } catch (error) {
      return ""
    }
  }

  static validateImgUrl = img => {
    if(isEmpty(img)) return "default"
    else if(UrlHelper.isValidUrl(img)) return img
    return UrlHelper.fixStorageLink(img)
  };

  static addDefaultSrcImage = ev => {
    ev.target.src = '/local/landing-page/images/broken_image.jpg';
  };

  static txidToLink(txid, symbol) {
    const txidQuery = [
      {
        assetSymbol: 'BTC',
        link: `https://mempool.space/es/tx/${txid}`,
        providerName: 'Blackchair'
      },
      {
        assetSymbol: 'USDC',
        link: `https://etherscan.io/tx/${txid}`,
        providerName: 'Etherscan'
      }
    ];
    const found = txidQuery.find(t => t.assetSymbol === symbol);
    return !isEmpty(found) ? found.link : '';
  }
}