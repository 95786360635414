import React from 'react';
import './headerPage.css';

const SubHeaderPage = ({ title, headerOptions = [] }) => {
  return (
    <div className="container-header-page">
      <div className="title-section sub-header">
        <div className="title-section-left">
          <h2>{title}</h2>
        </div>
        <div className="options-section">
          {headerOptions.map((option, key) => (
            <div
              className={`btn-filters ${option.className ? option.className : ''}`}
              onClick={option.action}
              key={key}>
              <img src={option.icon} alt={`${option.icon}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubHeaderPage;
