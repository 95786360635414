import empty from 'is-empty';

export const optionsLevel = [
  {
    name: 'Nivel 0',
    value: 0
  },
  {
    name: 'Nivel 10',
    value: 10
  },
  {
    name: 'Nivel 11',
    value: 11
  },
  {
    name: 'Nivel 12',
    value: 12
  },
  {
    name: 'Nivel 13',
    value: 13
  },
  {
    name: 'Nivel 14',
    value: 14
  },
  {
    name: 'Nivel 100',
    value: 100
  },
  {
    name: 'Nivel 101',
    value: 101
  }
];
export const optionsLevel2 = [
  {
    name: 'Sin filtro',
    value: 'empty'
  },
  {
    name: 'Nivel 0',
    value: '0'
  },
  {
    name: 'Nivel 10',
    value: '10'
  },
  {
    name: 'Nivel 11',
    value: '11'
  },
  {
    name: 'Nivel 12',
    value: '12'
  },
  {
    name: 'Nivel 13',
    value: '13'
  },
  {
    name: 'Nivel 14',
    value: '14'
  },
  {
    name: 'Nivel 100',
    value: '100'
  },
  {
    name: 'Nivel 101',
    value: '101'
  }
];
export const optionsStatus = [
  {
    value: 'empty',
    name: 'Sin filtro'
  },
  {
    value: 1,
    name: 'Pendiente'
  },
  {
    value: 2,
    name: 'Enviado a Acuant'
  },
  {
    value: 3,
    name: 'Recibido por Acuant'
  },
  {
    value: 4,
    name: 'Rechazado'
  },
  {
    value: 5,
    name: 'Información incorrecta'
  },
  {
    value: 6,
    name: 'Modificar información'
  },
  {
    value: 7,
    name: 'Aprobado'
  },
  {
    value: 8,
    name: 'Información editada'
  }
];

export const LEVELS_STATUS = [
  {
    id: 1,
    name: 'Pendiente',
    className: 'primary'
  },
  {
    id: 2,
    name: 'Enviado a DIG_iD_VERIF',
    className: 'secondary'
  },
  {
    id: 3,
    name: 'Recibido por DIG_iD_VERIF',
    className: 'secondary'
  },
  {
    id: 4,
    name: 'Rechazado',
    className: 'warning'
  },
  {
    id: 5,
    name: 'Información incorrecta',
    className: 'warning'
  },
  {
    id: 6,
    name: 'Modificar información',
    className: 'secondary'
  },
  {
    id: 7,
    name: 'Aprobado',
    className: 'success'
  },
  {
    id: 8,
    name: 'Información editada',
    className: 'secondary'
  }
];
export const ACCOUNTS_STATUS = [
  {
    id: 0,
    name: 'Pendiente'
  },
  {
    id: 1,
    name: 'Aprobado'
  },
  {
    id: 2,
    name: 'Rechazado'
  }
];

export const ACTION_TYPE = [
  {
    id: 'LOCK_USER',
    name: 'Usuario Bloqueado'
  },
  {
    id: 'UNLOCK_USER',
    name: 'Usuario Desbloqueado'
  },
  {
    id: 'FREEZE_ACCOUNT',
    name: 'Cuenta Congelada'
  },
  {
    id: 'UNFREEZE_ACCOUNT',
    name: 'Cuenta Descongelada'
  },
  {
    id: 'EDIT_FIELDS',
    name: 'Campos editados'
  },
  {
    id: 'RENIEC_VALIDATION',
    name: 'Validacion a Reniec'
  },
  {
    id: 'RETURNED_FIELDS',
    name: 'Campos devueltos'
  },
  {
    id: 'EDIT_FIELDS_CORPORATE_BASIC_INFO',
    name: 'Campos devueltos (Datos basicos corporativo)'
  },
  {
    id: 'RETURNED_FIELDS_CORPORATE_SHAREHOLDER_INFO',
    name: 'Campos devueltos (Datos de accionista corporativo)'
  },
  {
    id: 'EDIT_FIELDS_CORPORATE_BASIC_CHANGED_INFO',
    name: 'Campos editados (Datos de corporativo)'
  },
  {
    id: 'EDIT_FIELDS_CORPORATE_SHAREHOLDER_INFO',
    name: 'Campos editados (Datos de accionista corporativo)'
  },
  {
    id: 'USER_APROVED',
    name: 'Usuario aprobado'
  },
  {
    id: 'USER_REJECTED',
    name: 'Usuario rechazado'
  },
  {
    id: 'SENT_TO_IDM',
    name: 'Enviado a DIG_iD_VERIF'
  },
  {
    id: 'IDM_SENT',
    name: 'Enviado a DIG_iD_VERIF'
  },
  {
    id: 'RENIEC_VALIDATION',
    name: 'Validacion con Reniec'
  },
  {
    id: 'CORFID_RESPONSE',
    name: 'Respuesta de TRUST_PE'
  },
  {
    id: 'OCR_VALIDATION',
    name: 'Respuesta de OCR'
  },
  {
    id: 'TRUST_PE',
    name: 'Respuesta de TRUST_PE'
  },
  {
    id: 'SERVER_ERROR',
    name: 'Error del server'
  },
  {
    id: 'WORKER_TYPE_CHANGED',
    name: 'Tipo de trabajador cambiado'
  }
];

export const WARNING_STATUS = [
  {
    status: 'OWN_LIST',
    title: 'Usuario en lista propia'
  },
  {
    status: 'LOCAL_PEP',
    title: 'Usuario en PEP local'
  },
  {
    status: 'EXTERNAL_PEP',
    title: 'Usuario en PEP externo'
  },
  {
    status: 'REQUESTED',
    title: 'Usuario requisitoriado'
  },
  {
    status: 'NEGATIVE_NEWS',
    title: 'Usuario en noticias negativas'
  },
  {
    status: 'RELATED_PEP',
    title: 'Usuario en PEP relacionado'
  }
];

export const getLabel = prelabel => {
  const LABELS = [
    {
      id: 'company_legal_name',
      name: 'Denomination or Full Legal Name of Company'
    },
    {
      id: 'legal_entity_type',
      name: 'Type of Legal Entity'
    },
    {
      id: 'non_profit',
      name: 'Non-Profit'
    },
    {
      id: 'other_non_profit',
      name: 'Other Non-Profit'
    },
    {
      id: 'other_legal_entity_type',
      name: 'Other Type of Legal Entity'
    },
    {
      id: 'taxpayer_identification_number_type',
      name: 'Type of Taxpayer Identification Number'
    },
    {
      id: 'other_taxpayer_identification_number_type',
      name: 'Other Type of Taxpayer Identification Number'
    },
    {
      id: 'taxpayer_identification_number',
      name: 'Taxpayer Identification Number'
    },
    {
      id: 'corporate_purpose',
      name: 'Corporate Purpose'
    },
    {
      id: 'company_address',
      name: 'Company Address'
    },
    {
      id: 'city',
      name: 'City'
    },
    {
      id: 'state',
      name: 'State'
    },
    {
      id: 'zip_code',
      name: 'Zip Code'
    },
    {
      id: 'country_incorporation',
      name: 'Country of incorporation'
    },
    {
      id: 'registration_date',
      name: 'Registration Date (Constitution)'
    },
    {
      id: 'linked_economic_group',
      name: 'Linked economic group (if applicable)'
    },
    {
      id: 'company_phone',
      name: 'Company Phone'
    },
    {
      id: 'full_name',
      name: 'Full name'
    },
    {
      id: 'job_position',
      name: 'Job Position'
    },
    {
      id: 'phone',
      name: 'Phone'
    },
    {
      id: 'company_constitution_act',
      name: 'Company Constitution Act'
    },
    {
      id: 'incorporation_document',
      name:
        'Incorporation Document / Validity of Power of Attorney no later than 3 months'
    },
    {
      id: 'receipt_public_service',
      name:
        'Receipt of Public Service (Electricity, Telephone, Water, etc. not more than 3 months)'
    },
    {
      id: 'last_income_tax_return',
      name: 'Last income tax return'
    },
    {
      id: 'affidavit_origins_funds',
      name: 'Affidavit of Origins of funds'
    },
    {
      id: 'is_legal_representative',
      name: 'Is a Legal Representative?'
    },

    {
      id: 'percentage_number',
      name: 'Percentage Number %'
    },
    {
      id: 'last_name',
      name: 'Last Name'
    },
    {
      id: 'second_last_name',
      name: 'Second Last Name'
    },
    {
      id: 'birthday',
      name: 'Date of Birth'
    },
    {
      id: 'address_main',
      name: 'Main address, Zip Code, City, State'
    },
    {
      id: 'occupation',
      name: 'Activity, occupation, trade or profession'
    },
    {
      id: 'work_center',
      name: 'Work Center'
    },
    {
      id: 'first_name',
      name: 'First Name'
    },

    {
      id: 'is_pep',
      name: 'Is a PEP? (People Exposed Politically)'
    },
    {
      id: 'pep_document',
      name: 'Upload required PEP Document'
    },
    {
      id: 'nationality',
      name: 'Nationality'
    },
    {
      id: 'birth_country',
      name: 'Birth Country'
    },
    {
      id: 'residence_country',
      name: 'Country of Residence'
    },
    {
      id: 'billing_country',
      name: 'Billing Country'
    },
    {
      id: 'billing_address',
      name: 'Billing Address'
    },
    {
      id: 'billing_state',
      name: 'Billing State'
    },
    {
      id: 'billing_city',
      name: 'Billing City'
    },
    {
      id: 'billing_zip',
      name: 'Billing Zip'
    },
    {
      id: 'document_type',
      name: 'Document Type'
    },
    {
      id: 'document_country',
      name: 'Document Country'
    },
    {
      id: 'document_number',
      name: 'Document Number'
    },
    {
      id: 'document_data',
      name: 'Document Data'
    },
    {
      id: 'back_document_data',
      name: 'Back Document Data'
    },
    {
      id: 'faces_images',
      name: 'Selfie Photo'
    },
    {
      id: 'NEW_RECORD',
      name: 'New Record'
    },
    {
      id: 'REVIEW_REQUESTED',
      name: 'Review Requested'
    },
    {
      id: 'PERSON_SHAREHOLDER',
      name: 'Shareholder Person'
    },
    {
      id: 'COMPANY_SHAREHOLDER',
      name: 'Shareholder Company'
    },
    {
      id: 'NO_SHAREHOLDER',
      name: 'No Shareholder'
    },
    {
      id: '0',
      name: 'No'
    },
    {
      id: '1',
      name: 'Yes'
    }
  ];
  let label = LABELS.find(l => l.id === prelabel);
  if (!empty(label)) {
    return label.name;
  } else {
    return prelabel;
  }
};

export const transferTypes = [
  {
    type: 'NATIONAL',
    name: 'Local Perú',
    pathEdit: '/settings/accounts/edit/pe/'
  },
  {
    type: 'INTERNATIONAL',
    name: 'Internacional',
    pathEdit: '/settings/accounts/edit/international/'
  },
  {
    type: 'INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK',
    name: 'Internacional sin banco intermediario',
    pathEdit: '/settings/accounts/edit/international/'
  },
  {
    type: 'DOMESTIC_TRANSFER_US',
    name: 'Transferencia domestica US',
    pathEdit: '/settings/accounts/edit/us/'
  },
  {
    type: 'DOMESTIC_TRANSFER_US_WITH_BANK',
    name: 'Transferencia domestica US con banco intermediario',
    pathEdit: '/settings/accounts/edit/us/'
  },
  {
    type: 'DEFAULT',
    name: 'sin banco',
    pathEdit: '/settings/accounts/edit/us/'
  }
];

export const accountTypes = [
  {
    type: 'SAVINGS_ACCOUNT',
    name: 'Cuenta de Ahorros'
  },
  {
    type: 'CURRENT_ACCOUNTS',
    name: 'Cuenta Corriente'
  }
];

export const CURRENT_LEVEL = [
  {
    value: 0,
    title: 'Registro 0'
  },
  {
    value: 10,
    title: 'Explorador'
  },
  {
    value: 11,
    title: 'Amateur'
  },
  {
    value: 12,
    title: 'Intermedio'
  },
  {
    value: 13,
    title: 'Avanzado'
  },
  {
    value: 14,
    title: 'Maestro'
  },
  {
    value: 100,
    title: 'Coink'
  },
  {
    value: 101,
    title: 'Coink'
  }
];

export const DOCUMENT_TYPE = [
  {
    name: 'Pasaporte',
    value: 'PP'
  },
  {
    name: 'Documento Nacional de Identidad',
    value: 'ID'
  },
  {
    name: 'Residence Permit (CE)',
    value: 'RP'
  }
];
export const IS_PEP = [
  {
    name: 'Si',
    value: 1
  },
  {
    name: 'No',
    value: 0
  }
];

export const ocupations = [
  {
    name: 'Housewife',
    value: 'Housewife'
  },
  {
    name: 'Unemployed',
    value: 'Unemployed'
  },
  {
    name: 'Employee',
    value: 'Employee'
  },
  {
    name: 'Employer',
    value: 'Employer'
  },
  {
    name: 'Student',
    value: 'Student'
  },
  {
    name: 'Retired',
    value: 'Retired'
  },
  {
    name: 'Member of the Armed Forces',
    value: 'Member of the Armed Forces'
  },
  {
    name: 'Clergy member',
    value: 'Clergy member'
  },
  {
    name: 'Laborer',
    value: 'Laborer'
  },
  {
    name: 'Domestic Worker',
    value: 'Domestic Worker'
  },
  {
    name: 'Independent Worker',
    value: 'Independent Worker'
  },
  {
    name: 'Does not declare',
    value: 'Does not declare'
  }
];

export const MASTER_WORKER_TYPE = [
  {
    name: 'independent',
    value: 'independent'
  },
  {
    name: 'dependent',
    value: 'dependent'
  }
];

export const OPTIONS_STATUS_BLOCK = [
  {
    name: 'Baja Voluntaria',
    value: 'Baja Voluntaria'
  },
  {
    name: 'Por Lavado de Activos',
    value: 'Por Lavado de Activos'
  },
  {
    name: 'Por Terrorismo',
    value: 'Por Terrorismo'
  },
  {
    name: 'Por noticias vinculadas a LA/FT',
    value: 'Por noticias vinculadas a LA/FT'
  },
  {
    name: 'País sancionado por OFAC',
    value: 'País sancionado por OFAC'
  },
  {
    name: 'FATCA (US person)',
    value: 'FATCA (US person)'
  },
  {
    name: 'Supresión de datos personales',
    value: 'Supresión de datos personales'
  },
  {
    name: 'Duplicidad de cuentas',
    value: 'Duplicidad de cuentas'
  },
  {
    name: 'Usuario de Prueba',
    value: 'Usuario de Prueba'
  },
  {
    name: 'Otros (detallar)',
    value: 'others'
  }
];

// Constant deposit page

export const OPTIONS_STATUS = [
  {
    name: 'New',
    value: 'New'
  },
  {
    name: 'Accepted',
    value: 'Accepted'
  },
  {
    name: 'Rejected',
    value: 'Rejected'
  },
  {
    name: 'SystemProcessing',
    value: 'SystemProcessing'
  },
  {
    name: 'FullyProcessed',
    value: 'FullyProcessed'
  },
  {
    name: 'Failed',
    value: 'Failed'
  },
  {
    name: 'AmlProcessing',
    value: 'AmlProcessing'
  },
  {
    name: 'AmlAccepted',
    value: 'AmlAccepted'
  },
  {
    name: 'AmlRejected',
    value: 'AmlRejected'
  },
  {
    name: 'Aml Failed',
    value: 'Aml Failed'
  },
  {
    name: 'LimitsAccepted',
    value: 'LimitsAccepted'
  },
  {
    name: 'LimitsRejected',
    value: 'LimitsRejected'
  },
  {
    name: 'AdminProcessing',
    value: 'AdminProcessing'
  },
  {
    name: 'Pending',
    value: 'Pending'
  },
  {
    name: 'Limits Accepted',
    value: 'Limits Accepted'
  },
  {
    name: 'Limits Rejected',
    value: 'Limits Rejected'
  }
];

export const statusAdm = [
  "Solicitud Enviada",
  "Retiro Rechazado",
  "Retiro Devuelto",
  "Retiro Procesado",
];

export const bankNameUsd = [
  {
    titulo: "DÓLARES",
    isDisabled: true,
    className: "isDisabled",
  },
  {
    titulo: "Alfin Banco S.A. - USD (Antes Banco Azteca del Perú S.A.)",
    isDisabled: false,
  },
  {
    titulo: "Interbank  (Perú) - USD Cuenta Corriente",
    isDisabled: false,
  },
  {
    titulo: "Prime Trust USD (USA)",
    isDisabled: false,
  },
  {
    titulo: "Caja Piura - USD",
    isDisabled: false,
  },
  {
    titulo: "Banco de Comercio - USD Cuenta Corriente",
    isDisabled: false,
  },
  {
    titulo: "SOLES",
    isDisabled: true,
    className: "isDisabled",
  },
  {
    titulo: "Alfin Banco S.A. - SOLES (Antes Banco Azteca del Perú S.A.)",
    isDisabled: false,
  },
  {
    titulo: "Interbank (Perú) - SOLES (PEN) Cuenta Corriente",
    isDisabled: false,
  },
  {
    titulo: "Caja Piura - SOLES",
    isDisabled: false,
  },
  {
    titulo: "Banco de Comercio - SOLES (PEN) Cuenta Corriente",
    isDisabled: false,
  },
];

export const bankAccountsUsd2 = {
  "Alfin Banco S.A. - USD (Antes Banco Azteca del Perú S.A.)":
    "01770884700002",
  "Interbank (Perú) - USD Cuenta Corriente": "1083001943080",
  "Prime Trust USD (USA)": "420058909641",
  "Alfin Banco S.A. - SOLES (Antes Banco Azteca del Perú S.A.)":
    "01770884700001",
  "Interbank (Perú) - SOLES (PEN) Cuenta Corriente": "1083001943073",
  "Caja Piura - USD": "210023091750",
  "Caja Piura - SOLES": "210014820792",
  "Banco de Comercio - SOLES (PEN) Cuenta Corriente": "410201298409",
  "Banco de Comercio - USD Cuenta Corriente": "410201298393",
};

export const errorValue = "Debes llenar este campo"

export const typeOperation = [
  "Transferencia mismo banco Perú",
  "Transferencia Interbancaria Perú",
  "Transferencia Internacional Perú",
  "Wire Local USA",
  "Wire International USA",
];

export const types = {
  success: "success",
  error: "error",
  warn: "warn",
  info: "info",
}

export const optionsStatusAcuantLogs = [
  {
    value: '',
    name: 'Select an option'
  },
  {
    value: 'PROCESSED',
    name: 'Processed'
  },
  {
    value: 'FAIL-PROVIDER',
    name: 'Failed'
  },
];