import UtilsHelper from "../../helpers/Utils.helper";

export const getClients = async (userId, filterConfig, filterSearch) => {
  const path = `/api/v2/acuant/customers`;
  const params = {
    filterConfig,
    filterSearch,
  };
  const configRequest = {
    userId,
    method: "POST",
    path,
    data: params,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

export const createAcuantQueue = async (userId, data) => {
  const path = `/api/v2/acuant/queue`;
  const configRequest = {
    userId,
    method: "POST",
    path,
    data,
  };
  return await UtilsHelper.sendRequest(configRequest);
};

