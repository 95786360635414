import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TableContainer from "../Table/TableContainer";
import SubHeaderPage from "../HeaderPage/SubHeaderPage";
import isEmpty from "is-empty";

const AllBalancesReportData = (props, context) => {
  const {
    totalRows,
    rows,
    loading,
    productsDecimalPlaces
  } = props;


  const handleBalanceFormatted = data => {
    const amount = Number(data.balance)
    if(amount === 0) return 0
    const decimalPlacesByProduct = productsDecimalPlaces[data.symbol]
    if(isEmpty(decimalPlacesByProduct)) return amount
    return amount.toFixed(productsDecimalPlaces[data.symbol])
  }
   const tableHeaders = [
    {
      tag: "accountId",
      title: "Account ID",
    },
    {
      tag: "accountName",
      title: "Nombre de usuario",
    },
    {
      tag: "symbol",
      title: "Moneda",
    },
    {
      tag: "",
      title: "Balance",
      cell:({data}) => <p>{handleBalanceFormatted(data)}</p>
    }
  ]

  return (
    <div className="space-data-table">
      <SubHeaderPage title="Nuevo reporte de balance" />
      <TableContainer
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        headers={tableHeaders}
        rowIdSelected={" "}
        enablePagination={false}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllBalancesReportData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllBalancesReportData);
