import React, { useState } from "react";
import isEmpty from "is-empty";
import {userStatus} from "../../../../../constants";
import UserPermitsController from "../../../../../pages/FallControllers/UserPermitsController";
import InformationSection from "../../../../FallComponents/InformationSection/InformationSection";

export const Permits = ({
  userInfo,
  data,
  resetUserDetails,
  context
}) => {
  const [inputs, setInputs] = useState({
    user_status: String(data.userStatus),
    role_id: String(data.roleId),
  });

  const handleUpdatePermits = async () => {
    const response = await UserPermitsController.updateUserPermits({
      userInfo,
      id: data.id,
      data:inputs,
    });
    if (response) {
      resetUserDetails();
    }
  };

  const resetValues = () => {
    setInputs({
      user_status: String(data.userStatus),
      role_id: String(data.roleId),
    });
  };
  const infoList = {
    title: "Detalles de estado",
    resetValues: resetValues,
    submitData: handleUpdatePermits,
    fields: [
      {
        label: "Estados de usuario",
        name: "user_status",
        type: "select",
        options: userStatus,
        showCheckbox: false,
      }
    ],
  };

  return (
    <div className="content-tab user-permits-body permits-tab">
      <div className="content-tab-container">
        <InformationSection
          data={infoList}
          inputs={inputs}
          onChange={(target) =>
            setInputs({ ...inputs, [target.name]: target.value })
          }
          checkReturnData={() => {}}
          context={context}
        />
        <br />
        <br />
        <h2 className="subtitle-first-tab">Detalles de perfil</h2>
        <div className="option">
            <p>
              <b>Perfil</b>
            </p>
            <p>{!isEmpty(data.profileByProfileId) ? data.profileByProfileId.name : "-"}</p>
          </div>
      </div>
    </div>
  );
};
