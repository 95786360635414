import React from 'react';
import './comments.css';

const Comments = ({ commentInfo, onSubmitCallback, comment, setComment, editPermission }) => {
  return (
    <div className="comments-dep-main">
      <h3>Comentarios</h3>
      {!!commentInfo.length && <ul>{commentInfo.map(c => c)}</ul>}
      {editPermission &&
        <form onSubmit={onSubmitCallback}>
          <textarea
            value={comment}
            cols="10"
            rows="5"
            onChange={e => setComment(e.target.value)}
            required
          />
          <div className="container-btn">
            <button type="submit">Añadir comentario</button>
          </div>
        </form>
      }
    </div>
  );
};

export default Comments;
