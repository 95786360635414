import React, { useState } from 'react'
import isEmpty from 'is-empty';
import { useForm } from '../../../hooks/formHooks';
import ReportTypeController from '../../../pages/FallControllers/ReportTypeController';
import { GroupFilters } from '../Filters/Filters'
import HeaderPage from '../HeaderPage/HeaderPage'
import TableContainer from '../Table/TableContainer'
import Download from '../../../images/new_icons/download.svg';
import moment from 'moment';

export const DetailedReport = ({ userInfo, decimalPlaces }) => {

    const filterInitialValues = {
        init_time: `${moment().format("YYYY-MM-DD")}T00:00`,
        end_time: `${moment().format("YYYY-MM-DD")}T23:59`,
        account_id: ''
    };

    const { inputs: filterInputs, onInputChangeV2 } = useForm(filterInitialValues);

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const headerOptions = [
        {
            icon: Download,
            action: () => handleDownload()
        },
    ];


    const handleSearchFilters = () => {
        if (isEmpty(filterInputs.init_time) || isEmpty(filterInputs.end_time)) return;
        let newFilterInputs = {
            init_time: filterInputs.init_time.replaceAll("T", " "),
            end_time: filterInputs.end_time.replaceAll("T", " ")
        }
        if(!isEmpty(filterInputs.account_id)) newFilterInputs.account_id = Number(filterInputs.account_id);
        ReportTypeController.AllDetailsReport({
            userInfo,
            decimalPlaces,
            params: newFilterInputs,
            setLoading,
            setRows,
        })
    };

    const handleDownload = () => {
        if (isEmpty(rows)) return;
        ReportTypeController.DownloadAllDetails({
            rows
        })
    }

    const tableHeaders = [
        {
            tag: "accountId",
            title: "ID de cuenta"
        },
        {
            tag: "accountName",
            title: "Nombre de la cuenta"
        },
        {
            tag: "isClient",
            title: "En admin",
        },
        {
            tag: "userLocked",
            title: "Tipo usuario"
        },
        {
            tag: "person",
            title: "Persona"
        },
        {
            tag: "verificationLevel",
            title: "Nivel de verificación"
        },
        {
            tag: "verificationLevelText",
            title: "Nivel"
        },
        {
            tag: "symbol",
            title: "Nombre del activo"
        },
        {
            tag: "acumBtcAmount",
            title: "Bitcoin Retiradas"
        },
        {
            tag: "acumUsdcAmount",
            title: "USDC Retiradas"
        },
        {
            tag: "acumPenAmount",
            title: "Soles Retirados"
        },
        {
            tag: "acumUsdAmount",
            title: "Dólares Retirados"
        },
        {
            tag: "notionalValue",
            title: "Valor nocional"
        },
        {
            tag: "price",
            title: "T/C"
        },
        {
            tag: "feeAmount",
            title: "Monto de comisión"
        },
        {
            tag: "feeAmountUsd",
            title: "Monto comisión en USD"
        },
        {
            tag: "bankNameSource",
            title: "Banco",
        },
        {
            tag: "bankCountrySource",
            title: "País del Banco"
        },
        {
            tag: "statusText",
            title: "Estatus"
        },
        {
            tag: "ticketNumber",
            title: "Número de ticket"
        },
        {
            tag: "timestampUtc",
            title: "UTC"
        },
        {
            tag: "timestampAmericaLimaPeru",
            title: "Perú"
        },
        {
            tag: "dayAmericaLimaPeru",
            title: "Día"
        },
        {
            tag: "monthAmericaLimaPeru",
            title: "Mes"
        },
        {
            tag: "yearAmericaLimaPeru",
            title: "Año"
        },
    ];

    const filters = [
        {
            values: {
                title: "Desde",
                name: "init_time",
                value: filterInputs.init_time,
                type: "datetime-local",
                required: true,
                max: `${moment().format("YYYY-MM-DD")}T23:59`
            },
            actions: {
                onChange: onInputChangeV2,
            },
        },
        {
            values: {
                title: "Hasta",
                name: "end_time",
                value: filterInputs.end_time,
                type: "datetime-local",
                required: true,
                max: `${moment().format("YYYY-MM-DD")}T23:59`
            },
            actions: {
                onChange: onInputChangeV2,
            },
        },
        {
            values: {
                title: "ID de cuenta",
                name: "account_id",
                value: filterInputs.account_id,
                type: "number",
                required: true,
            },
            actions: {
                onChange: onInputChangeV2,
            },
        },
    ];

    return (
        <div className="all-page-data">
            <HeaderPage
                title='Retiro - Reporte detallado'
                subTitle="Reportes / Reporte general / Retiro - Reporte detallado"
                headerOptions={headerOptions}
            />
            <TableContainer
                rows={rows}
                loading={loading}
                headers={tableHeaders}
                rowIdSelected={''}
                enablePagination={false}
            >
                <GroupFilters
                    handleSearchFilters={handleSearchFilters}
                    withRightSection={false}
                    inputs={filters}
                />
            </TableContainer>
        </div>
    )
}