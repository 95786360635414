import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import DepositFormComponent from './DepositFormComponent';
import {
  fetchAllProductDepositTemplates,
  createDepositTicket,
  fetchProductDepositTemplate
} from 'apex-web/lib/redux/actions/depositActions';
import {
  openModal,
  MODAL_TYPES
} from 'apex-web/lib/redux/actions/modalActions';
import config from 'apex-web/lib/config';

var formName = 'createDeposit';

var mapStateToProps = function mapStateToProps(state, _ref) {
  var product = _ref.product;

  return {
    deposit: state.deposit,
    isDasc: product.Product === 'DASC' || product.Product === 'DAS'
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    selectDepositProduct: function selectDepositProduct(productId) {
      return dispatch(fetchAllProductDepositTemplates(productId));
    },
    selectDepositTemplate: function selectDepositTemplate(
      productId,
      templateType
    ) {
      return dispatch(fetchProductDepositTemplate(productId, templateType));
    },
    changeFieldValue: function changeFieldValue(field, value) {
      return dispatch(change(formName, field, value));
    }
  };
};

var DepositFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DepositFormComponent);

export default reduxForm({
  form: formName,
  onSubmit: function onSubmit(payload, dispatch, _ref2) {
    var _ref2$product = _ref2.product,
      ProductId = _ref2$product.ProductId,
      Product = _ref2$product.Product;

    dispatch(
      createDepositTicket(Object.assign({}, payload, { ProductId: ProductId }))
    );
    if (config.Deposit.showDepositIdModal) {
      dispatch(
        openModal(MODAL_TYPES.DEPOSIT_REFERENCE_ID, {
          ProductSymbol: Product
        })
      );
    }
  }
})(DepositFormContainer);
