import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AddForm from "./AddForm";
import "./modal.css";
const ModalBanexcoinAccount = (props, context) => {
  const {
    showModal,
    handleToggleModal,
    title,
    buttonProps,
    userInfo,
    getAllBanexcoinAccounts,
  } = props;
  return (
    <Modal className="custom-modal-container" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {title} | <a className="link-documentation-add-bnx_account" href="https://banexcoinsa.sharepoint.com/:w:/s/Procesos/EdJ30tO2Hk9HoGnmj-CLIW4B6s198etkrvAa_XlNsPEHDA?e=lzBhGn&isSPOFile=1&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA3MDMwMDgxNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D" target="_blank">Instructivo</a>
      </ModalHeader>
      <ModalBody>
        <AddForm
          handleToggleModal={handleToggleModal}
          buttonProps={buttonProps}
          userInfo={userInfo}
          getAllBanexcoinAccounts={getAllBanexcoinAccounts}
        />
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
ModalBanexcoinAccount.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(ModalBanexcoinAccount);
