import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import close from "../../../images/new_icons/close-circle.svg";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../hooks/useFilter";
import AllBankTransactionsData from "../../../components/FallComponents/BankTransactionsPage/AllBankTransactionsData";
import BankTransactionsController from "../../FallControllers/BankTransactionsController";
import { exportcsv } from "../../../components/FallComponents/BankTransactionsPage/exportcsv";
import { generateRowsCsv } from "../../../components/FallComponents/BankTransactionsPage/generateRowsCsv";
import { buildSecureGetParams } from "../../../helpers/lib";
import BankTransactionsDetails from "../../../components/FallComponents/BankTransactionsPage/BankTransactionsDetails";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import { getTotal } from "../../../components/FallComponents/BankTransactionsPage/getTotal";
import ComponentsHelper from "../../../helpers/Components.helper";
import { toastTypes } from "../../../constants";
import { Context as ContextUser } from '../../../providers/UserProvider'

const totalDebits = [
  {
    currency: "PEN",
    total: 0,
  },
  {
    currency: "USD",
    total: 0,
  },
];

const totalCredits = [
  {
    currency: "PEN",
    total: 0,
  },
  {
    currency: "USD",
    total: 0,
  },
];

const feeSubstrings = ["ITF","COMISION TIE","Comisión Bco. Benef. CCE","Com. Bco. Orig. CCE","IMPUESTO ITF","ENVIO.EST.CTA","COM.MANTENIM","DE GRUPO CORIL SOCIEDA"]

const BankTransactionsPage = (props, context) => {
  const { userInfo } = props;
  const {
    modulesByUserProfile,
    handleWritePermission
  } = useContext(ContextUser);
  const [editPermission, setEditPermission] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [withRightSection, setWithRightSection] = useState(false);
  const [balance, setBalance] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState({
    totalDebits,
    totalCredits,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [provider, setProvider] = useState([]);
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
    setFilterSearch,
    resetFilters,
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const handleSelectRow = ({ row }) => {
    setSelectedRow(row);
    setWithRightSection(true);
  };

  const restartData = () => {
    resetFilters();
    setWithRightSection(false);
    setTotal({
      totalDebits,
      totalCredits,
    });
    setSelectedRow({});
  };

  const getSelectedIds = () => {
    const arrayCheckbox = document.getElementsByClassName(
      "input-check-select-bank-transaction"
    );
    let selectedsArray = [];
    for (let item of arrayCheckbox) {
      if (item.checked) {
        selectedsArray.push(item.name);
      }
    }
    return selectedsArray
  }

  const handleActionSendFeeTransactions = () => {
    const selectedIds = getSelectedIds()
    if (selectedIds.length < 1) {
      return ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "Asegúrese de seleccionar al menos una transacción.",
      });
    }
    const notFees = selectedIds.filter(item => {
      const transaction = rows.find((row) => row.id === item) || {};
      return transaction.transactionType === "DEBIT" && isEmpty(feeSubstrings.filter(el => transaction.payload.includes(el)))
    })
    if(notFees.length > 0) {
      return ComponentsHelper.toast({
        type: toastTypes.info,
        message: "Asegúrese de seleccionar solo transacciones de tipo comisión.",
      });
    }
    handleSendFeeTransactions(selectedIds)
  };

  const handleSendFeeTransactions = async (selectedIds) => {
    const selectedsArray = selectedIds.map((item) => {
      const transaction = rows.find((row) => row.id === item) || {};
      const transactionDetails = {
        admin: {
          type: "FEE",
          message: `${transaction.payload}`,
          user: userInfo,
        },
      };
      return {
        id: transaction.id,
        details: new Buffer.from(JSON.stringify(transactionDetails)).toString(
          "base64"
        ),
        reference: transaction.reference,
        path: "/api/v1/bank/freezedeposit",
      };
    });
    await BankTransactionsController.freezeFeeTransactions({
      userInfo,
      data: selectedsArray,
    });
    getAllBankTransactions();
  };

  const handleSyncBankTransactions = (data) => {
    BankTransactionsController.syncBankTransactions({
      userInfo,
      data,
      setLoading,
      setBalance,
    });
  };
  const processClaimTransactionAdmin = async (action, data) => {
    const id = await BankTransactionsController.processTransaction({
      userInfo,
      data: { details: data.details, id: data.id },
    });
    if (id) {
      getAllBankTransactions();
      setWithRightSection(false);
    }
  };
  const processClaimTransactionFee = async (row) => {
    const data = {
      id: row.id,
      payload: row.payload,
    };
    const id = await BankTransactionsController.processClaimTransactionFee({
      userInfo,
      data,
    });
    if (id) {
      getAllBankTransactions();
      setWithRightSection(false);
    }
  };
  const processDeleteTransaction = async (row) => {
    const data = {
      id: row.id,
      payload: row.payload,
      currency: row.currency,
      amount: row.transactionAmount,
    };
    await BankTransactionsController.deleteTransaction({ data, userInfo });
  };
  const goToDepositTicket = (details) => {
    const paramConstructor = buildSecureGetParams({
      accountId: details.deposit.AccountId,
      requestCode: details.deposit.RequestCode,
    });
    window.open(`/depositos/${paramConstructor}`, "_blank");
  };
  const goToWithdrawalTicket = (details) => {
    const paramConstructor = buildSecureGetParams({
      accountId: details.withdrawal.AccountId,
      requestCode: details.withdrawal.RequestCode,
    });
    window.open(`/retiros/${paramConstructor}`, "_blank");
  };

  const processEditTransactions = async ({ row, detailsJson }, { details }) => {
    let data = {
      id: row.id,
      payload: row.payload,
      amount: parseFloat(row.transactionAmount),
      currency: row.currency,
      category: detailsJson.admin.category,
      message: detailsJson.admin.message,
      details,
    };
    const id = await BankTransactionsController.processTransaction({
      userInfo,
      data,
      details,
    });
    if (id) {
      getAllBankTransactions();
      setWithRightSection(false);
    }
  };

  const actions = {
    processClaimTransactionAdmin,
    processClaimTransactionFee,
    processDeleteTransaction,
    goToDepositTicket,
    goToWithdrawalTicket,
    processEditTransactions,
  };

  const downloadRows = () => {
    ComponentsHelper.toast({
      type: toastTypes.info,
      message: "Descargando...",
    });
    const rowsToDownload = generateRowsCsv(rows);
    exportcsv(rowsToDownload, `bankTransactions-${new Date().getTime()}`);
  };

  const getAllBankTransactions = () => {
    BankTransactionsController.getAllBankTransactions({
      userInfo,
      setLoading,
      setRows,
      setTotalRows,
      filterSearch,
      filterConfig,
    });
  };

  const getAllProviders = () => {
    BankTransactionsController.getAllProviders({
      userInfo,
      setProvider
    })
  };

  useEffect(() => {
    if (isEmpty(userInfo) || isEmpty(modulesByUserProfile)) return;
    setEditPermission(handleWritePermission("transacciones-bancarias"))
    getAllBankTransactions();
    BankTransactionsController.getBankAccountBalance({ userInfo, setBalance });
    getAllProviders();
  }, [modulesByUserProfile, userInfo, filterConfig, filterSearch]);

  useEffect(() => {
    if (isEmpty(rows)) {
      return setTotal({ totalCredits, totalDebits });
    }
    const total = getTotal(rows);
    setTotal(total);
  }, [rows]);

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
        setSelectedRow({});
      },
    },
  ];

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        minWidth="550px"
        maxWidth="550px"
        childrenOne={
          <AllBankTransactionsData
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            handleSelectRow={handleSelectRow}
            setFilterSearch={setFilterSearch}
            selectedId={selectedRow.id}
            balance={balance}
            syncBankTransactions={handleSyncBankTransactions}
            userInfo={userInfo}
            downloadRows={downloadRows}
            total={total}
            restartData={restartData}
            handleActionSendFeeTransactions={handleActionSendFeeTransactions}
            editPermission={editPermission}
          />
        }
        childrenTwo={
          <RightResizable
            headerOptions={headerOptions}
            loadingData={{
              title: "",
              isLoading: false,
            }}
          >
            <BankTransactionsDetails
              userInfo={userInfo}
              data={selectedRow}
              actions={actions}
              provider={provider}
              editPermission={editPermission}
            />
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

BankTransactionsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BankTransactionsPage);
