import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import './PageFooterLayout.css';

const pageFooterClasses = getBEMClasses('page-footer');

const PageFooterLayout = (props, context) => {
  return (
    <footer className={pageFooterClasses()}>
      <div className={pageFooterClasses('footer-left-content')}>
        {context.t('{n}™ {y}. All Rights Reserved.', {
          n: process.env.REACT_APP_SITE_NAME,
          y: new Date().getFullYear()
        })}
      </div>
      <div className={pageFooterClasses('footer-right-content')}>
        <div className={pageFooterClasses('footer-nav')}>
          <div className="footer-nav-container">
            <div className="footer-nav-single-link">
              {context.t('More')}
              <span className="triangle-more" />
            </div>
            <div className="footer-nav-cloud">
              <div className="footer-nav-cloud-children">
                <ul>
                  <li>
                    <a href="https://soporte.banexcoin.com">
                      {context.t('Support')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

PageFooterLayout.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PageFooterLayout;
