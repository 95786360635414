import { connect } from 'react-redux';
import GenericPaymentWorkflowComponent from './GenericPaymentWorkflowComponent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    depositInfo: state.deposit.depositInfo.reverse(),
    templateType: state.deposit.templateType
  };
};

export default connect(mapStateToProps)(GenericPaymentWorkflowComponent);
