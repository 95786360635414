export const currencyAssetNames = [
    {
      assetName: 'Peruvian Sol',
      currency: 'PEN'
    },
    {
      assetName: 'United States Dollar',
      currency: 'USD'
    },
    {
      assetName: 'BTC',
      currency: 'BTC'
    }
  ];