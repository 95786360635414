import React from 'react';
import CopyIcon from './../../../images/new_icons/info.svg';
import CopyToClipboard from 'react-copy-to-clipboard';

function Clipboard({ textToCopy = ' ', className = '', onClickClipboard }) {
  return (
    <CopyToClipboard
      text={textToCopy}
      onCopy={() => onClickClipboard(textToCopy)}>
      <img width="16px" src={CopyIcon} />
    </CopyToClipboard>
  );
}

export default Clipboard;
