import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns';
import NaturalCustomerDetails from '../../../../components/FallComponents/NaturalCustomersPage/NaturalCustomersDetails/NaturalCustomersDetails';
import PageDataClients from '../../../../components/FallComponents/PageDataClients/PageDataClients';
import { Context as ContextLevel } from './ProviderLevels'
import isEmpty from 'is-empty';
import statusComponent from '../../../../components/FallComponents/StatusComponent/StatusComponent';
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable';
import ImagesZoom from '../../../../components/FallComponents/ImagesZoom/ImagesZoom';
import close from '../../../../images/new_icons/close-circle.svg';
import NaturalCustomersController from '../../../FallControllers/NaturalCustomersController';
import ComponentsHelper from '../../../../helpers/Components.helper';
import DateHelper from '../../../../helpers/Date.helper';
import {
  ModalImage,
  ModalInfo
} from '../../../../components/FallComponents/Modal/Modal';
import { CropModal } from '../../../../components/FallComponents/Modal/CropModal';
import { useFilter } from '../../../../hooks/useFilter'
import { LEVELS_STATUS, toastTypes} from '../../../../constants';
import { useParams } from 'react-router-dom';
import { Context as ContextUser } from '../../../../providers/UserProvider'
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import { useModal } from '../../../../hooks/useModal';
import AzureModal from '../../../../components/FallComponents/Modal/AzureModal';

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

const AllLevelNatural = (props, context) => {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [showModalAzure, setShowModalAzure] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [editPermission, setEditPermission] = useState(false);
  const [configBanexCard, setConfigBanexCard] = useState({
    isUser: false,
    isActive: false,
    associatedCard: false
  });
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    resetFilters,
    onChangeLimit,
    setFilterSearch
  } = useFilter({ totalRows });
  const {
    loadingUserInfo,
    setLoadingUserInfo,
    userInfo: newUserInfo,
    setUserInfo,
    setLogs,
    setIsCoink,
    seltListCountries,
    setWarningStatus,
    setAccountUserInfo,
    imagesZoom,
    handleImagesToZoom
  } = useContext(ContextLevel);
  const { modulesByUserProfile, handleWritePermission } = useContext(ContextUser);
  const [withRightSection, setWithRightSection] = useState(false);
  const [reniecData, setReniecData] = useState(null);
  const [reniecImageData, setReniecImageData] = useState(null);
  const [loadingReniecData, setLoadingReniecData] = useState(false);
  const [coincidence, setCoincidence] = useState({});
  const [
    loadingSendRequestReniecToValidate,
    setLoadingSendRequestReniecToValidate
  ] = useState(false);
  const [showModalToCropImage, setShowModalToCropImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropData, setCropData] = useState({});
  const { userInfo } = props;
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropData(croppedAreaPixels);
  }, []);
  const { showModal: showActionModal, handleToggleModal: handleToggleActionModal } = useModal();
  const [action, setAction] = useState({
    title:"",
    message:"",
    function: () => {}
  })

  const handleShowModal = () => setShowModal(!showModal);

  const handleShowModalInfo = () => setShowModalInfo(!showModalInfo);

  const handleShowModalAzure = () => setShowModalAzure(!showModalAzure);

  const handleActionCloseCropModal = () => {
    setShowModalToCropImage(false);
    ComponentsHelper.confirmAlert({
      title: 'Enviar a RENIEC',
      message:
        '¿Está seguro de que desea ENVIAR la solicitud de validación a RENIEC?',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () =>
            NaturalCustomersController.requestReniecToValidateBiometricsData({
              setLoadingSendRequestReniecToValidate,
              levelBasicInfo: newUserInfo.userData,
              newUserInfo,
              userInfo,
              setLoadingUserInfo,
              setUserInfo,
              setIsCoink,
              setLogs,
              cropData,
              setCoincidence
            })
        },
        {
          label: 'Cancelar',
          onClick: () => {}
        }
      ]
    });
  };

  const generateSelector = row => {
    row.tooltipTitle = 'Seleccionar';
    return (
      <label>
        <input
          className="input-check-select-hy"
          type="checkbox"
          name={row.id}
          hidden
        />
        <div className={`input-radio`} />
      </label>
    );
  };

  const handleSelectRow = ({ handleClickOnRow, row }) => {
    setWithRightSection(true);
    setConfigBanexCard({
      isUser: false,
      isActive: false,
      associatedCard: false
    });
    handleClickOnRow && handleClickOnRow(row.id);
    setWarningStatus(row.list);
  };


  const headers = [
    {
      tag: 'info',
      title: ' ',
      cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id,"Id"),
      className: 'minimizer no-Selection'
    },
    {
      tag: 'selected',
      title: ' ',
      cell: ({ data }) => generateSelector(data),
      className: 'minimizer no-Selection'
    },
    {
      tag: 'provider',
      title: 'Proveedor',
      cell: ({ data }) => ComponentsHelper.providerImage(data.userLevel),
      className: 'minimizer',
      handleSelectRow
    },
    {
      tag: 'banexcoinUserAccountId',
      title: 'Account ID',
      className: 'minimizer2',
      handleSelectRow
    },
    {
      tag: 'banexcoinUsername',
      title: 'Usuario',
      handleSelectRow
    },
    {
      tag: 'email',
      title: 'E-mail',
      cell: ({ data }) => <p className="text-ellipsis">{data.email}</p>,
      handleSelectRow
    },
    {
      tag: 'fullDocument',
      title: 'Documento de identidad',
      handleSelectRow
    },
    {
      tag: 'firstName',
      title: 'Nombres',
      handleSelectRow
    },
    {
      tag: 'lastName',
      title: 'Apellidos',
      handleSelectRow
    },
    {
      tag: 'createdAt',
      title: 'Fecha de creación',
      cell: ({ data }) => DateHelper.formatDate(data.createdAt),
      handleSelectRow
    },
    {
      tag: '',
      title: 'Nivel Actual',
      className: 'minimizer2',
      handleSelectRow,
      cell:({data}) => <p>{data.status === 7 ? data.nextLevel : data.userLevel}</p>
    },
    {
      tag: '',
      title: 'Bloqueado',
      cell: ({ data }) => <p>{data.userLockStatus ? "Si" : "-"}</p>,
      className: 'minimizer',
      handleSelectRow
    },
    {
      tag: 'status',
      title: 'Estado',
      cell: ({ data }) => statusComponent(data.status, false, LEVELS_STATUS),
      className: 'minimizer',
      handleSelectRow
    },
  ];

  const newListCountries = list => {
    if (!isEmpty(list)) {
      const newList = [];
      list.map(({ countryCode, countryName }) => {
        newList.push({
          name: countryName,
          value: countryCode
        });
      });
      seltListCountries(newList);
    }
  };

  const handleRejectKYC = async (message, setLoader) => {
    const data = {
      "banexcoin_account_id": newUserInfo.userData.banexcoinUserAccountId,
      "observations": message
    }
    await NaturalCustomersController.postRejectKYC({
      userInfo,
      data,
      setLoader,
      context
    })
  }

  useEffect(() => {
    const userId = params.id;
    if (!isEmpty(userInfo)){
      NaturalCustomersController.getListCountries({
        newListCountries,
        userInfo
      });
    }
    if(!isEmpty(userId) && !isEmpty(userInfo)){
      setUserInfo({
        ...newUserInfo,
        userId
      });
      setLoadingUserInfo(true);
      setWithRightSection(true);
    }
  }, [userInfo])

  const handleLoadNaturalUsers = () => {
    NaturalCustomersController.loadLevels({
      filterConfig,
      filterSearch,
      setLoading,
      setRows,
      setTotalRows,
      userInfo,
      context
    });
  }

  useEffect(
    () => {
      if (!isEmpty(userInfo) && !isEmpty(modulesByUserProfile)){
        setEditPermission(handleWritePermission('clientes-naturales'))
        handleLoadNaturalUsers()
      }
    },
    [modulesByUserProfile, userInfo, filterConfig, filterSearch]
  );

  useEffect(
    () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const getData = async () => {
        if (!isEmpty(newUserInfo.userId)) {
          const result = await NaturalCustomersController.getUserDataById({
            source,
            setLoadingUserInfo,
            userInfo,
            newUserInfo,
            setUserInfo,
            setIsCoink,
            setAccountUserInfo,
            setCoincidence,
            setLogs,
            finallyAction: false
          });
          await NaturalCustomersController.getUserInfoB89ByAccountId({
            userInfo,
            banexcoinUserAccountId: result.level.banexcoinUserAccountId,
            setConfigBanexCard
          })
          setLoadingUserInfo(false)
        }
      };
      getData();
      return function() {
        source.cancel();
      };
    },
    [newUserInfo.userId]
  );

  const headerOptions = [
    {
      image: close,
      alt: 'close option',
      action: () => {
        handleImagesToZoom();
        setWithRightSection(false);
        setUserInfo({})
      }
    }
  ];

  const handleSendRequestReniec = () => setShowModalToCropImage(true);

  const handleReniecInfo = () => {
    setShowModalInfo(true);
    NaturalCustomersController.getReniecUserData({
      setReniecData,
      setLoadingReniecData,
      levelBasicInfo: newUserInfo.userData,
      userInfo
    });
  };

  const handleReniecPhoto = () => {
    setShowModal(true);
    NaturalCustomersController.getReniecPhoto({
      setReniecImageData,
      setLoadingReniecData,
      levelBasicInfo: newUserInfo.userData,
      userInfo
    });
  };

  const handleReniecSign = () => {
    setShowModal(true);
    NaturalCustomersController.getReniecSign({
      setReniecImageData,
      setLoadingReniecData,
      levelBasicInfo: newUserInfo.userData,
      userInfo
    });
  };

  const sendIdentityMind = async (reload) => {
    await NaturalCustomersController.updateToIdentityMindById({
      userInfo,
      newUserInfo,
      reload,
      context
    })
  }

  const sendAzureImage = async (image) => {
    return await NaturalCustomersController.sendFaceComparison({
      userInfo,
      image,
      userId: newUserInfo.userId
    })
  }

  const handleSubmitB89Card = async () => {
    ComponentsHelper.toast({ type: toastTypes.info, message: 'Enviando...' })
    if(!configBanexCard.isUser){
      const response = await NaturalCustomersController.postCreateUserB89ByAccountId({
        userInfo,
        banexcoinUserAccountId: newUserInfo.userData.banexcoinUserAccountId
      })
      if(!response) return false
    }
    if(!configBanexCard.associatedCard){
      const response = await NaturalCustomersController.postAssociateCardByAccountId({
        userInfo,
        banexcoinUserAccountId: newUserInfo.userData.banexcoinUserAccountId,
        setConfigBanexCard
      })
      return response
    }
    const response = await NaturalCustomersController.patchUpdateActivenessByAccountId({
      userInfo,
      banexcoinUserAccountId: newUserInfo.userData.banexcoinUserAccountId,
      setConfigBanexCard
    })
    return response
  }

  let handleReniecRequests = {
    handleReniecPhoto,
    handleReniecInfo,
    handleReniecSign,
    handleSendRequestReniec
  };

  return (
    <React.Fragment>
      <div className="beta-content-container">
        <ResizableColumns
          withRightSection={withRightSection}
          minWidth={imagesZoom.show ? '1100px' : '660px'}
          childrenOne={
            <PageDataClients
              Userid={userInfo.UserId}
              setFilterSearch={setFilterSearch}
              resetFilters={resetFilters}
              setWithRightSection={setWithRightSection}
              withRightSection={withRightSection}
              onChangeLimit={onChangeLimit}
              filterConfig={filterConfig}
              filterSearch={filterSearch}
              totalRows={totalRows}
              rows={rows}
              loading={loading}
              offset={offset}
              showingTo={showingTo}
              changePage={changePage}
              headers={headers}
            />
          }
          childrenTwo={
            <RightResizable
              headerOptions={headerOptions}
              loadingData={{
                title: 'Cargando KYC',
                isLoading: loadingUserInfo
              }}
              leftSection={imagesZoom.show ? <ImagesZoom images={imagesZoom.images} onClose={handleImagesToZoom} /> : null}>
              {!isEmpty(newUserInfo.userData) ? (
                <NaturalCustomerDetails
                  userInfo={userInfo}
                  handleReniecRequests={handleReniecRequests}
                  handleShowModalAzure={handleShowModalAzure}
                  sendIdentityMindSubmit={() => {
                    setAction({
                      title:'Enviar datos a ACUANT',
                      message: '¿Está seguro de que desea enviar la solicitud?',
                      function: (reload) => sendIdentityMind(reload)
                    })
                    handleToggleActionModal()
                  }}
                  coincidence={coincidence}
                  setCoincidence={setCoincidence}
                  handleRejectKYC={handleRejectKYC}
                  editPermission={editPermission}
                  handleLoadNaturalUsers={handleLoadNaturalUsers}
                  configBanexCard={configBanexCard}
                  handleSubmitB89Card={handleSubmitB89Card}
                />
              ) : (
                <React.Fragment>
                  <div className="containerNoKYC">
                    <img src="/local/landing-page/images/sad-face.png" />
                    <h3>
                      No existen datos o <span class="other-color">KYC</span>{' '}
                      Eliminado...{' '}
                    </h3>
                    <p>Si cree que es un problema, comuníquese con Soporte..</p>
                  </div>
                </React.Fragment>
              )}
            </RightResizable>
          }
        />
      </div>
      {showModal && (
        <ModalImage
          showModal={showModal}
          handleShowModal={handleShowModal}
          reniecImageData={reniecImageData}
          loadingReniecData={loadingReniecData}
        />
      )}
      {showModalInfo && (
        <ModalInfo
          showModal={showModalInfo}
          handleShowModal={handleShowModalInfo}
          reniecInfo={reniecData}
          loadingReniecData={loadingReniecData}
        />
      )}
      {showModalAzure && (
        <AzureModal
          showModal={showModalAzure}
          handleShowModal={handleShowModalAzure}
          sendAzureImage={sendAzureImage}
          faceImage={newUserInfo.userData.facesImages}
        />
      )}
      {showModalToCropImage && (
        <CropModal
          handleActionCloseCropModal={handleActionCloseCropModal}
          showModalToCropImage={showModalToCropImage}
          faceImage={newUserInfo.userData.facesImages}
          crop={crop}
          zoom={zoom}
          setCrop={setCrop}
          onCropComplete={onCropComplete}
          setZoom={setZoom}
        />
      )}
      <ConfirmModal
        showModal={showActionModal}
        handleToggleModal={() => {
          handleToggleActionModal();
        }}
        title={action.title}
        message={action.message}
        confirmButtonProps={{
          onClick: action.function,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </React.Fragment>
  );
};

AllLevelNatural.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AllLevelNatural);
