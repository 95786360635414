import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logout } from 'apex-web/lib/redux/actions/authActions';
import GlobalSideMenu from './GlobalSideMenu';

var mapDispatchToProps = {
  logout: logout
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(GlobalSideMenu));
