import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AllAutoTradeData from '../../../components/FallComponents/AutoTradePage/AllAutoTradeData'
import ResizableColumns from '../../../components/FallComponents/ResizableColumns/ResizableColumns'
import AutoTradeController from '../../FallControllers/AutoTradeController';
import { useEffect } from 'react';
import isEmpty from 'is-empty';

const AutoTradePage = (props, context) => {

    const { userInfo } = props;

    const [loading, setLoading] = useState(true);
    const [trades, setTrades] = useState([]);
    const [rates, setRates] = useState([]);

    const handleEditSettingByKey = (id, data) => {
        return AutoTradeController.postTradeConfig({
            userInfo,
            id,
            data
        })
    }

    useEffect(() => {
        if (!isEmpty(userInfo)) {
            AutoTradeController.getAutoTradeConfig({
                userInfo,
                setTrades,
                setLoading
            });
            AutoTradeController.getRatePrice({
                userInfo,
                setRates
            })
        }
    }, [userInfo])

    return (
        <div className="beta-content-container">
            <ResizableColumns
                childrenOne={
                    <AllAutoTradeData
                        loading={loading}
                        trades={trades}
                        rates={rates}
                        handleEditSettingByKey={handleEditSettingByKey}
                    />
                }
            />
        </div>
    )
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

AutoTradePage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AutoTradePage)