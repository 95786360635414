import empty from 'is-empty';
import axios from 'axios';
const urlApi = !empty(process.env.REACT_APP_API_BACKEND)
  ? process.env.REACT_APP_API_BACKEND
  : 'http://localhost:3001';

export default class HandlerBackend {
  static async sendRequest({ userId, method, headers, data, path, timeout }) {
    if (empty(headers)) {
      headers = {
        'Content-Type': 'application/json'
      };
    } else {
      if (empty(headers['Content-Type'])) {
        headers['Content-Type'] = 'application/json';
      }
    }

    if (!empty(localStorage.getItem('token'))) {
      headers.authorization = 'bearer ' + localStorage.getItem('token');
      headers.authorization_user_id = userId;
    }

    let axiosConfigRequest = {
      url: `${urlApi + path}`,
      method: method,
      headers,
      timeout
    };

    if (method.toUpperCase() === 'GET' && !empty(data)) {
      axiosConfigRequest.params = data;
    }

    if (method.toUpperCase() === 'POST' && !empty(data)) {
      axiosConfigRequest.data = data;
    }

    if (method.toUpperCase() === 'PUT' && !empty(data)) {
      axiosConfigRequest.data = data;
    }

    let response = await axios(axiosConfigRequest).catch(e => {
      throw e;
    });

    return response;
  }
}
