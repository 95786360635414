import UtilsHelper from "../../helpers/Utils.helper";

export const getAllAssets = async (userId) => {
    const path = `/api/autotrade/config`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}
export const postAllAssets = async (userId, data) => {
    const path = `/api/autotrade/config`;
    const configRequest = {
        userId,
        method: "POST",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getRatePrices = async (userId, data) => {
    const path = `/api/currency/rates/lastest`;
    const configRequest = {
        userId,
        method: "GET",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}