import UtilsHelper from "../../helpers/Utils.helper";

export const getAllProducts = async (userId, filterConfig) => {
  const path = `/api/auto_approval/products/${filterConfig.page}/${filterConfig.limit}`;
  const configRequest = {
    path,
    userId,
    method: "GET",
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const createProduct = async (userId, data) => {
  const path = `/api/auto_approval/products`;
  const configRequest = {
    userId,
    method: "POST",
    path,
    data,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const toggleAutoApprovalStatus = async (userId, type, id, status) => {
  const action = status ? "disable" : "enable";
  const path = `/api/auto_approval/products/toggle/${type}/${id}/${action}`;
  const configRequest = {
    userId,
    method: "PATCH",
    path,
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const updateAutoApprovalData = async (userId, id,data) => {
  const path = `/api/auto_approval/products/update/${id}`;
  const configRequest = {
    userId,
    method: "PATCH",
    path,
    data: {
      productSymbol:data.productSymbol.toUpperCase()
    }
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const addAutoApprovalProduct = async (userId,data) => {
  const path = `/api/auto_approval/products`;
  const configRequest = {
    userId,
    method: "POST",
    path,
    data
  };
  return await UtilsHelper.sendRequest(configRequest);
};
export const deleteAutoApprovalProduct = async (userId,id) => {
  const path = `/api/auto_approval/products/delete/${id}`;
  const configRequest = {
    userId,
    method: "DELETE",
    path
  };
  return await UtilsHelper.sendRequest(configRequest);
};
