import React, { useState } from 'react'
import { ModalLayout } from '../../Modal/ModalLayout';
import { CustomButton } from '../../CustomButton/CustomButton';
import isEmpty from 'is-empty';

const KycIncorrectModal = ({ showModal, changeShowModal, handleSubmit, getUserDataByIdWithLogs }) => {

    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);

    const handleSubmitAu10tix = async (e) => {
        e.preventDefault();
        await handleSubmit(message, setLoader);
        changeShowModal();
        getUserDataByIdWithLogs()
    }

    const handleActivateButton = !loader ? !message.trim().length : true

    return (
        <ModalLayout showModal={showModal} actionClose={changeShowModal} className='modal-legal' classGeneral='custom-modal-incorrect-fiels'>
            <form onSubmit={handleSubmitAu10tix}>
                <h2>¿Estas seguro en rechazar el KYC?</h2>
                <div>
                    <p><b>Agregar observación</b> (Esta observación llegará en el correo del cliente)</p>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} rows="4" />
                    <div className='content-butt-modal'>
                        <CustomButton
                            type='submit'
                            text="Si, devolver campos"
                            className="green"
                            disabled={handleActivateButton}
                            showLoader={loader}
                        />
                        <CustomButton
                            type='button'
                            text="No"
                            onClick={changeShowModal}
                            className="red"
                        />
                    </div>
                </div>
            </form>
        </ModalLayout>
    )
}

export default KycIncorrectModal