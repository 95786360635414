import isEmpty from 'is-empty';
import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { assets } from '../../../../../constants';
import { CustomButton } from '../../../CustomButton/CustomButton';
import InputElement from '../../../InputElement/InputElement'
import { Switch } from '../../../Switch/Switch';
import './modal.css';

const CreateAssets = ({ showModal, handleToggleModal, handleCreateNewAsset }) => {

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [status, setStatus] = useState(false);
    const [restricted, setRestricted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})

    const assetNameElement = {
        values: {
            title: 'Nombre de activo',
            name: 'name',
            value: name,
            type: 'text',
            required: true
        },
        actions: {
            onChange: (data) => setName(data.value)
        },
        error: errors.name
    };

    const assetTypeElement = {
        values: {
            title: 'Tipo de activo',
            name: 'type',
            value: type,
            type: 'select',
            options: assets,
            placeholder: 'Selecione un activo',
            required: true
        },
        actions: {
            onChange: (data) => setType(data.value)
        },
        error: errors.type
    };

    const customError = () => {
        let errors = {};
        if (isEmpty(name)) errors.name = 'Campo requerido';
        if (isEmpty(type)) errors.type = 'Campo requerido';
        setErrors(errors);
        return errors;
    }

    const resetData = () => {
        setName('');
        setType('');
        setStatus(false);
        setRestricted(false);
        setErrors({})
    }

    const submitCreateAssets = async () => {
        const errors = customError();
        if (!isEmpty(errors)) return;
        setLoading(true);
        await handleCreateNewAsset({
            type,
            value: name.toUpperCase(),
            restricted,
            status: status ? 1 : 0
        });
        setLoading(false);
        resetData();
        handleToggleModal();
    }

    return <Modal className="modal-create-assets" isOpen={showModal}>
        <ModalHeader charCode="X" toggle={handleToggleModal}>
            Creación de activo
        </ModalHeader>
        <ModalBody>
            <div className='content-assets-inputs'>
                <InputElement {...assetNameElement} />
                <InputElement {...assetTypeElement} />
                <div className='content-switch-assets'>
                    <p>Habilitar activo</p>
                    <div>
                        <Switch
                            defaultChecked={status}
                            onChange={() => setStatus(!status)}
                            id={`switch-toggle-bnx-account-assetStatus`}
                        />
                    </div>
                </div>
                <div className='content-switch-assets'>
                    <p>Restringir activo</p>
                    <div>
                        <Switch
                            defaultChecked={restricted}
                            onChange={() => setRestricted(!restricted)}
                            id={`switch-toggle-bnx-account-assetRestricted`}
                        />
                    </div>
                </div>
                <div className='container-submit-cancel-buttons'>
                    <CustomButton
                        onClick={handleToggleModal}
                        className="red"
                        text="Cancelar"
                        showLoader={false}
                        disabled={loading}
                    />
                    <CustomButton
                        onClick={submitCreateAssets}
                        className="purple"
                        text="Añadir activo"
                        disabled={loading}
                    />
                </div>
            </div>
        </ModalBody>
    </Modal>

}

export default CreateAssets