import axios from "axios";
import isEmpty from "is-empty";
import { LABELS, WARNING_STATUS } from "../constants";
import * as XLSX from 'xlsx';

export default class UtilsHelper {
  static async sendRequest({
    userId,
    method,
    headers,
    data,
    path,
    timeout,
    source
  }) {
    const urlApi = process.env.REACT_APP_API_BACKEND || 'http://localhost:3001';
    if (isEmpty(headers) || isEmpty(headers['Content-Type'])) {
      headers = {
        ...headers,
        'Content-Type': 'application/json'
      };
    }
    if (!isEmpty(localStorage.getItem('token'))) {
      headers.authorization = 'bearer ' + localStorage.getItem('token');
      headers.authorization_user_id = userId;
    }

    let axiosConfigRequest = {
      url: `${urlApi + path}`,
      method: method,
      headers,
      timeout,
      cancelToken: source ? source.token : null
    };

    if (method.toUpperCase() === 'GET' && !isEmpty(data)) {
      axiosConfigRequest.params = data;
    } else {
      axiosConfigRequest.data = data;
    }
    return await axios(axiosConfigRequest).catch(e => {
      throw e;
    });
  }

  static checkIsImage = (url) => {
    if (isEmpty(url)) return false
    const filetypes = /jpeg|jpg|png|gif/;
    return filetypes.test(url.toLowerCase());
  }

  static getFilename = (url) => {
    return !isEmpty(url) ? decodeURI(url.split('/').pop()) : '';
  }

  static validateParam = (param) => {
    return !isEmpty(param) ? param : '';
  }

  static getLabelByUser = (prelabel) => {
    let label = LABELS.find(l => l.id === prelabel);
    if (!isEmpty(label)) {
      return label.name;
    } else {
      return prelabel;
    }
  }

  static getWarningStatus = (status) => {
    if(isEmpty(status)) return 'Sin advertencia'
    return  WARNING_STATUS.find(item => item.status === status).title
  }

  static validationLevelState = (
    level,
    { userLevel, status, nextLevel }
  ) => {
    if (userLevel >= level) return 'Aprobado';
    if (level === nextLevel && status === 5) return 'Rechazado';
    if (level === nextLevel && status === 1) return 'Pendiente';
    if (userLevel === level && status === 7) return 'Aprobado';
    return '';
  };

  static dowloadFile = ({data,name}) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',name);
    document.body.appendChild(link);
    link.click();
  }

  static readSpreadsheet = (file,setRows,callback) => {
    const reader = new FileReader();
    reader.onload = evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      callback(data,setRows);
    };
    reader.readAsBinaryString(file);
  }

  static sanitizeValue = (value) =>{
    if (isEmpty(value)) return '';
    const specialCases_text = [
      '',
      'null',
      'undefined',
      'NaN',
      null,
      undefined,
      NaN
    ];
    if (specialCases_text.includes(value)) return '';
    return value;
  }

  static firtsLetterInUpperCase = (string) => { return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() }
}
