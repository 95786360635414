import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RotateRight from "../../../../images/new_icons/rotate-right.svg";
import Setting from "../../../../images/new_icons/setting.svg";
import SettingLight from "../../../../images/new_icons/setting-light.svg";
import { useFilter } from "../../../../hooks/useFilter";
import HeaderPage from "../../../../components/FallComponents/HeaderPage/HeaderPage";
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns";
import isEmpty from "is-empty";
import BlackListController from "../../../FallControllers/BlackListController";
import TableContainer from "../../../../components/FallComponents/Table/TableContainer";
import DateHelper from "../../../../helpers/Date.helper";
import { GroupFilters } from "../../../../components/FallComponents/Filters/Filters";
import { useForm } from "../../../../hooks/formHooks";
import { useModal } from "../../../../hooks/useModal";
import Modal from "../../../../components/FallComponents/BlackListPage/LogsModal";
import statusComponent from "../../../../components/FallComponents/StatusComponent/StatusComponent";
import { filesQueueTypes } from "../../../../constants";

const BlackListFilesQueuePage = (props, context) => {
  const { userInfo } = props;
  const [blackListDataByType, setBlackListFiles] = useState({});
  const [loadingBlacklistFiles, setLoadingBlackListFiles] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const { showModal, handleToggleModal } = useModal();
  const [selectedId, setSelectedId] = useState(null);
  const [logs, setLogs] = useState([]);

  const {
    offset,
    showingTo,
    filterConfig,
    onChangeLimit,
    changePage,
    filterSearch,
    resetFilters,
    setFilterSearch,
  } = useFilter({ totalRows: blackListDataByType.totalCount });

  const {
    inputs: inputsFilters,
    onInputChangeV2,
    setInputs: setInputsFilters,
  } = useForm({}, handleSearchFilters);

  const handleSearchFilters = () => {
    Object.keys(inputsFilters).forEach((element) => {
      if (inputsFilters[element] === "" || isEmpty(inputsFilters[element])) {
        delete inputsFilters[element];
      }
    });
    setFilterSearch(inputsFilters);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const getAllBLaklistFiles = () => {
    BlackListController.getBlackListFiles({
      userInfo,
      urlConfig: filterConfig,
      filter: filterSearch,
      setBlackListFiles,
      setLoadingBlackListFiles,
    });
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: RotateRight,
      action: () => {
        resetFilters();
        setInputsFilters({
          queuesTable: "",
          queuesFileName: "",
          "banexcoinUserByBanexcoinUserId.banexcoinUsername": "",
          queuesType:""
        });
      },
    },
  ];

  const handleShowDetails = (data) => {
    setSelectedId(data.id);
    handleToggleModal();
    BlackListController.getLogs({ userInfo, id: data.id, setLogs,setLogsLoading });
  };

  const handleDownloadLogs  = () => {
    BlackListController.downloadLogs({userInfo,id:selectedId})
  }
  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllBLaklistFiles();
  }, [userInfo, filterConfig, filterSearch]);

  const tableHeaders = [
    {
      tag: "",
      title: "Porcentaje",
      cell:({data}) => <p>{data.queuesPercentage} %</p>
    },
    {
      tag: "queuesFileUrl",
      title: "Archivo",
      cell: ({ data }) => (
        <p>
          <a className="queues-file-url-cell" href={data.queuesFileUrl}>
            {data.queuesFileName}
          </a>
        </p>
      ),
    },
    {
      tag: "queuesTable",
      title: "Nombre de lista",
    },
    {
      tag: "",
      title: "Nombre de Usuario",
      cell: ({ data }) => (
        <p>
          {data.banexcoinUserByBanexcoinUserId.banexcoinUsername}
          <strong>
            {" "}
            ({data.banexcoinUserByBanexcoinUserId.banexcoinUserid})
          </strong>
        </p>
      ),
    },
    {
      tag: "",
      title: "Fecha de creación",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "",
      title: "Fecha de actualización",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.updatedAt)}</p>,
    },
    {
      tag: "queuesType",
      title: "Estado",
      cell: ({ data }) =>
        statusComponent(data.queuesType, false, filesQueueTypes),
    },
    {
      tag: "",
      title: "Detalles de logs",
      cell: ({ data }) => (
        <button onClick={() => handleShowDetails(data)}>Ver detalles</button>
      ),
    },
  ];

  const filters = [
    {
      values: {
        title: "Archivo",
        name: "queuesFileName",
        value: inputsFilters.queuesFileName,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Nombre de lista",
        name: "queuesTable",
        value: inputsFilters.queuesTable,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Usuario",
        name: "banexcoinUserByBanexcoinUserId.banexcoinUsername",
        value:
          inputsFilters["banexcoinUserByBanexcoinUserId.banexcoinUsername"],
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Estado",
        name: "queuesType",
        value: inputsFilters.queuesType,
        type: "select",
        options: filesQueueTypes.map((item) => ({ ...item, value: item.id })),
        required: true,
        isClearable: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="beta-content-container">
        <ResizableColumns
          childrenOne={
            <div className="all-container-data">
              <HeaderPage
                title="Lista negra"
                subTitle="Cumplimiento / Lista negra / Cola de archivos"
                headerOptions={headerOptions}
              />
              <TableContainer
                totalRows={blackListDataByType.totalRows}
                filterConfig={filterConfig}
                rows={blackListDataByType.data}
                onChangeLimit={onChangeLimit}
                changePage={changePage}
                loading={loadingBlacklistFiles}
                offset={offset}
                showingTo={showingTo}
                headers={tableHeaders}
                rowIdSelected={selectedId}
              >
                {showFilters && (
                  <GroupFilters
                    handleSearchFilters={handleSearchFilters}
                    inputs={filters}
                  />
                )}
              </TableContainer>
            </div>
          }
        />
      </div>
      {showModal && (
        <Modal
          showModal={showModal}
          title="Detalles de logs"
          handleToggleModal={handleToggleModal}
          logs={logs}
          logsLoading={logsLoading}
          handleDownloadLogs={handleDownloadLogs}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

BlackListFilesQueuePage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BlackListFilesQueuePage);
