import React from 'react'
import DateHelper from '../../../../helpers/Date.helper'
export const TicketDetails = ({context,ticket}) => {
      return (
      <div className="slot-details">
        <div className="option">
          <p>
            <b>{context.t("Request code")}:</b>
          </p>
          <p>{ticket.RequestCode}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Ticket number")}:</b>
          </p>
          <p>{ticket.TicketNumber}</p>
        </div>

        <div className="option">
          <p>
            <b>{context.t("Notional value")}:</b>
          </p>
          <p>{ticket.NotionalValue}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Update by")}:</b>
          </p>
          <p>{ticket.UpdatedByUserName}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Amount")}:</b>
          </p>
          <p>{ticket.Amount}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Asset name")}:</b>
          </p>
          <p>{ticket.AssetName}</p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Created")}:</b>
          </p>
          <p>
            {DateHelper.formatDate(ticket.CreatedTimestamp)}
          </p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Last updated")}:</b>
          </p>
          <p>
            {DateHelper.formatDate(ticket.LastUpdateTimestamp)}
          </p>
        </div>
        <div className="option">
          <p>
            <b>{context.t("Reject reason")}:</b>
          </p>
          <p>
            {ticket.RejectReason}
          </p>
        </div>
      </div>
    );
  };