import React, { useContext, useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AllWithdrawData from '../../../../components/FallComponents/WithdrawPage/AllWithdrawData';
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns';
import { useFilter } from '../../../../hooks/useFilter';
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable';
import close from '../../../../images/new_icons/close-circle.svg';
import WithdrawController from '../../../FallControllers/WithdrawController';
import WithdrawDetails from '../../../../components/FallComponents/WithdrawPage/WithdrawDetails/WithdrawDetails';
import ImagesZoom from '../../../../components/FallComponents/ImagesZoom/ImagesZoom';
import { getSecureGetParams } from '../../../../helpers/lib';
import { Context as ContextUser } from '../../../../providers/UserProvider'


const WithdrawPage = (props, context) => {
  const { userInfo, products } = props;

  const { 
    modulesByUserProfile,
    handleWritePermission
 } = useContext(ContextUser);

  const [changeLimit, setChangeLimit] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [withRightSection, setWithRightSection] = useState(false);
  const [rows, setRows] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [withdrawInfo, setWithdrawInfo] = useState({
    RequestCode: null,
    AccountId: null
  });
  const [withdrawTicketData, setWithdrawTicketData] = useState(null)
  const [levelData, setLevelData] = useState(null)
  const [loadingInfoByID, setLoadingInfoById] = useState(true);
  const [imagesZoom, setImagesZoom] = useState({ show: false, images: null });
  const [editPermission, setEditPermission] = useState(false);

  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    resetFilters,
    onChangeLimit,
    setFilterSearch
  } = useFilter({ totalRows });

  const headerOptions = [
    {
      image: close,
      alt: 'close option',
      action: () => {
        setWithRightSection(false);
      }
    }
  ];

  const handleSelectRow = ({ row }) => {
    setWithRightSection(true);
    if (row.RequestCode !== withdrawInfo.RequestCode) {
      setWithdrawInfo({
        RequestCode: row.RequestCode,
        AccountId: row.AccountId
      });
    }
  };

  useEffect(() => {
    onChangeLimit(300);
    setChangeLimit(true);
    WithdrawController.getProducts({
      setProductList,
      context
    });
  }, []);

  const getWithdrawDataById = () => {
    WithdrawController.getWithdrawById({
      userInfo,
      withdrawInfo,
      setLoadingInfoById,
      context,
      setWithdrawTicketData,
      setLevelData
    });
  }
  useEffect(() => {
    if (!isEmpty(userInfo) && !isEmpty(withdrawInfo.RequestCode)) getWithdrawDataById()
  }, [userInfo, withdrawInfo]);

   const getWithdraws = async () => {
        const result = await WithdrawController.getWithdraws({
          setLoading,
          userInfo,
          totalRows,
          setRows,
          setTotalRows,
          filterConfig,
          filterSearch,
          context
        });
        const lastParam = window.location.pathname.split("/").reverse()[0];
        if (lastParam === "retiros") return;
        const withdraw = result.find((withdraw) => withdraw.TicketNumber == lastParam)
        if (!isEmpty(withdraw)) {
          setWithdrawInfo({
            RequestCode: withdraw.RequestCode,
            AccountId: withdraw.AccountId,
          });
          setWithRightSection(true);
          return;
        }
        const paramDecoded = getSecureGetParams(lastParam);
        if (!isEmpty(paramDecoded)) {
          setWithdrawInfo({
            RequestCode: paramDecoded.RequestCode,
            AccountId: paramDecoded.AccountId,
          });
          setWithRightSection(true);
          return;
        }
   }

  useEffect(() => {
    if (!isEmpty(userInfo) && changeLimit && !isEmpty(modulesByUserProfile)){
      setEditPermission(handleWritePermission('retiros'))
      getWithdraws();
    }      
  }, [modulesByUserProfile, userInfo, filterConfig, filterSearch]);

  const handleImagesToZoom = images => {
    if (!images || (images.length === 1 && images[0].src === 'default')) {
        setImagesZoom({ show: false, images: null });
        return;
    }
    setImagesZoom({ show: true, images });
};

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        minWidth={imagesZoom.show ? '1100px' : '585px'}
        childrenOne={
          <AllWithdrawData
            context={context}
            Userid={userInfo.UserId}
            setFilterSearch={setFilterSearch}
            resetFilters={resetFilters}
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            productList={productList}
            handleSelectRow={handleSelectRow}
            selectedId={withdrawInfo.RequestCode}
          />
        }
        childrenTwo={
          <RightResizable
            headerOptions={headerOptions}
            loadingData={{
              title: 'Cargando ticket',
              isLoading: loadingInfoByID
            }}
            leftSection={imagesZoom.show ? <ImagesZoom images={imagesZoom.images} onClose={handleImagesToZoom} /> : null}
            >
            <WithdrawDetails
              products={products}
              level={levelData}
              userInfo={userInfo}
              context={context}
              getWithdrawDataById={getWithdrawDataById}
              handleImagesToZoom={handleImagesToZoom}
              getWithdraws={getWithdraws}
              editPermission={editPermission}
              {...withdrawTicketData}
            />
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = global => {
  const {
    user: { userInfo },
    product: { products }
  } = global;
  return { userInfo, products };
};
WithdrawPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(WithdrawPage);
