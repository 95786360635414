import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { createAcuantQueue, getClients } from "../FallServices/AcuantServices";

class AcuantController {
  static getAllClients = async ({
    userInfo,
    setRows,
    filterConfig,
    filterSearch,
    setTotalRows,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const response = await getClients(
        userInfo.UserId,
        filterConfig,
        filterSearch
      );
      if (!isEmpty(response.data) && response.status === 200) {
        setRows(response.data.nodes);
        setTotalRows(response.data.totalCount);
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static createAcuantQueue = async ({ userInfo, ids }) => {
    try {
      ComponentsHelper.toast({
        type: toastTypes.info,
        message: "Enviando",
      });
      const response = await createAcuantQueue(userInfo.UserId, { ids });
      if(!isEmpty(response.data) && response.data.code === "200"){
          ComponentsHelper.toast({
            type: toastTypes.success,
            message: "Enviado correctamente",
          });
          return true
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser enviada",
      });
      return false;
    }
  };
}

export default AcuantController;
