import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import Send from "../../../images/new_icons/send.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import DateHelper from "../../../helpers/Date.helper";
import { GroupFilters } from "../Filters/Filters";
import isEmpty from "is-empty";
import "./allAcuantData.css";
import { optionsLevel as userLevels } from "../../../constants";
import {handlerFilterByFile} from './handleFilterByFile'
const AllAcuantData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    selectedCountry,
    withRightSection,
    resetFilters,
    setFilterSearch,
    sendRowsBySelection,
  } = props;

  const [filterInputs, setFilterInputs] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const [filterFileName,setFilterFileName] = useState("Archivo de filtros (.csv)")
  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const handleSearchFilters = () => {
    const filtersToSend = { ...filterInputs };
    Object.keys(filterInputs).forEach((item) => {
      if (isEmpty(filterInputs[item])) {
        delete filtersToSend[item];
      }
    });
    setFilterSearch({ ...filtersToSend });
  };
  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: Send,
      action: sendRowsBySelection,
    },
    {
      icon: RotateRight,
      action: () => {
        resetFilters();
        setFilterInputs({
            userIds: "",
            userAccountIds: "",
            usernames:"",
            excludeAccountIds:"",
        });
        setFilterFileName("Archivo de filtros (.csv)")
      },
    },
  ];

  const generateSelector = (row) => {
    row.tooltipTitle = "Seleccionar";
    return (
      <label>
        <input
          className="input-check-select-acuant"
          type="checkbox"
          name={row.banexcoinUserByBanexcoinUserId.banexcoinUserid}
          hidden
        />
        <div className={`input-radio`} />
      </label>
    );
  };

  const handleFilterByFile = (ev) => {
    if(isEmpty(ev.target.files)) return
    setFilterFileName(ev.target.files[0].name)
    handlerFilterByFile(ev,setFilterInputs,filterInputs)
  }

  const tableHeaders = [
    {
      tag: "selected",
      title: "",
      cell: ({ data }) => generateSelector(data),
      className: "minimizer no-Selection",
    },
    {
      tag: "",
      title: "Banexcoin user ID",
      cell: ({ data }) => (
        <p>{data.banexcoinUserByBanexcoinUserId.banexcoinUserid}</p>
      ),
    },
    {
      tag: "banexcoinUsername",
      title: "Nombre de usuario",
      className: "short-column-name acuant-column",
    },
    {
      tag: "banexcoinUserAccountId",
      title: "Banexcoin user account ID",
    },
    {
      tag: "userLevel",
      title: "Nivel",
    },
    {
      tag: "",
      title: "Tipo de persona",
      cell: ({ data }) => (
        <p>{data.hasOwnProperty("dataForm") ? "Jurídico" : "Natural"}</p>
      ),
    },
    {
      tag: "email",
      title: "Correo electrónico",
      className: "short-column-name acuant-column",
    },
    {
      tag: "",
      title: "Fecha de creación",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
  ];

  const filters = [
    {
      values: {
        title: "Banexcoin user IDs ( , )",
        name: "userIds",
        value: filterInputs.userIds,
        type: "text",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Banexcoin user account IDs ( , )",
        name: "userAccountIds",
        value: filterInputs.userAccountIds,
        type: "text",
        required: true,
        rootClassname:"min-width-filter-input-acuant-accountid"
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
        values: {
        title: "Nombres de usuarios ( , )",
        name: "usernames",
        value: filterInputs.usernames,
        type: "text",
        required: true,
    },
    actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Account IDs excluidos ( , )",
        name: "excludeAccountIds",
        value: filterInputs.excludeAccountIds,
        type: "text",
        required: true,
        rootClassname:"min-width-filter-input-acuant-excludeid"
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: "Nivel ( >= )",
        name: "level",
        value: filterInputs.level,
        type: "select",
        required: true,
        options: userLevels,
        isClearable: true
      },
      actions: {
        onChange: handleOnChange,
      },
    },
    {
      values: {
        title: filterFileName,
        name: "file",
        type: "file",
        required: true,
        accept:".csv",
        isClearable: true,
        rootClassname:"max-width-filter-input-acuant-file"
      },
      actions: {
        onChange: handleFilterByFile,
      },
    },
];

  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Acuant")}
        subTitle="Cumplimiento / Acuant"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedCountry}
      >
        {showFilters && (
          <GroupFilters
            inputs={filters}
            withRightSection={withRightSection}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllAcuantData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllAcuantData);
