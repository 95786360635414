import ComponentsHelper from "../../helpers/Components.helper";
import { toastTypes } from "../../constants";
import { getAllAlphapointAccount, getAlphapointAccountById } from "../FallServices/AlphapointAccountServices";
import isEmpty from "is-empty";

class AlphapointAccountController {
    static loadAlphapointAccounts = async ({
        userInfo,
        setLoading,
        setRows,
        totalRows,
        setTotalRows,
        filterConfig,
        filters,
        setAllfilters
    }) => {
        const errorMessage = () => {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
        try {
            setLoading(true);
            setAllfilters(filters);
            const { limit, page } = filterConfig;
            const newFilters = !isEmpty(filters) ? `&${filters}` : '';
            const result = await getAllAlphapointAccount(userInfo.UserId, page, limit, newFilters);
            if (!isEmpty(result.data) && result.data.message === 'success') {
                setRows(result.data.allDataAccounts);
                if (isEmpty(totalRows)) setTotalRows(!isEmpty(result.data.allDataAccounts) ? result.data.allDataAccounts[0].accountId : 0);
                return true;
            };
            return false;
        }
        catch (err) { errorMessage() }
        finally { setLoading(false) }
    }

    static loadAlphapointAccountById = async ({
        userInfo,
        setRightLoading,
        accountId,
        setAccountDataById
    }) => {
        const errorMessage = () => {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
        try {
            setRightLoading(true);
            const result = await getAlphapointAccountById(userInfo.UserId, accountId);
            if (!isEmpty(result.data) && result.status === 200) {
                setAccountDataById(result.data);
                return true;
            };
            errorMessage();
        }
        catch (err) { errorMessage() }
        finally { setRightLoading(false) }
    }
}

export default AlphapointAccountController;