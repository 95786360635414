import UtilsHelper from "../../helpers/Utils.helper";

export const getAllBalanceReports  = async (userId) => {
    const path= `/api/transaction/report`
    return await UtilsHelper.sendRequest({
        userId,
        method: "GET",
        path,
      });
}
export const getAllBalanceReportsByDate  = async (userId,date) => {
    const path= `/api/transaction/report/${date}`
    return await UtilsHelper.sendRequest({
        userId,
        method: "GET",
        path,
      });
}