import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import AddIcon from "../../../images/add-icon.svg";
import DateHelper from "../../../helpers/Date.helper";
import { Switch } from "../Switch/Switch";
import { useModal } from "../../../hooks/useModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import { GroupFilters } from "../Filters/Filters";
import { useForm } from "../../../hooks/formHooks";
import isEmpty from "is-empty";
import { expired, notificationType, platformType, status } from "../../../constants";

const AllNotificationsData = (props, context) => {
  const {
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    handleSelectRow,
    selectedId,
    getAllNotifications,
    handleEditModal,
    handleUpdateNotification
  } = props;

  const filterInitialValues = {
    title: '',
    type: '',
    platform: '',
    expired: '',
    status: ''
  };

  const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(filterInitialValues);

  const { showModal, handleToggleModal } = useModal();
  const [checkedData, setCheckedData] = useState({
    isChecked: false,
    id: null,
  });

  const [newRows, setNewRows] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilters = () => setShowFilters(!showFilters);

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleEditModal,
    },
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? 'header-option-active' : ''
    },
    {
      icon: RotateRight,
      action: () => {resetFilters();getAllNotifications()}
    },
  ];

  const CheckboxStatus = ({ data }) => {
    const handleToggle = () => {
      handleToggleModal();
      setCheckedData({ isChecked: data.status, id: data.id });
    };
    return (
      <Switch
        defaultChecked={data.status}
        onChange={handleToggle}
        id={`switch-toggle-bnx-account-${data.id}`}
      />
    );
  };

  const handleConfirmActionModal = async () => {
    await handleUpdateNotification(checkedData)
  };

  const tableHeaders = [
    {
      tag: "",
      title: "Fecha de creación",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "",
      title: "Fecha de inicio",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.startAt)}</p>,
    },
    {
      tag: "",
      title: "Fecha de finalización",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.endAt)}</p>,
    },
    {
      tag: "",
      title: "Título",
      handleSelectRow,
      cell: ({ data }) => <p>{data.eventTitle.es}</p>,
    },
    {
      tag: "type",
      title: "Tipo",
      handleSelectRow
    },
    {
      tag: "platform",
      title: "Plataforma",
      handleSelectRow
    },
    {
      tag: "expired",
      title: "Expirado",
      handleSelectRow
    },
    {
      tag: "",
      title: "Activado",
      cell: ({ data }) => <CheckboxStatus data={data} />,
    },
  ];

  const resetFilters = () => {
    setInputs(filterInitialValues)
    setNewRows(rows)
  }

  const handleSearchFilters = () => {
    if (isEmpty(inputsFilters.title) && isEmpty(inputsFilters.type) && isEmpty(inputsFilters.platform) && isEmpty(inputsFilters.expired) && inputsFilters.status === '') return resetFilters();
    const newRows = rows.filter(({ eventTitle, type, platform, expired, status }) => {
      return (eventTitle.es).toLowerCase().includes(inputsFilters.title.toLowerCase()) && type.toLowerCase().includes(inputsFilters.type.toLowerCase()) && platform.toLowerCase().includes(inputsFilters.platform.toLowerCase()) && expired.toLowerCase().includes(inputsFilters.expired.toLowerCase()) && status.toString().includes(inputsFilters.status.toString())
    })
    return setNewRows(newRows)
  };

  const inputs = [
    {
      values: {
        title: 'Título',
        name: 'title',
        value: inputsFilters.title,
        type: 'text',
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Tipo',
        name: 'type',
        value: inputsFilters.type,
        type: 'select',
        options: notificationType,
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Plataforma',
        name: 'platform',
        value: inputsFilters.platform,
        type: 'select',
        options: platformType,
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Expirado',
        name: 'expired',
        value: inputsFilters.expired,
        type: 'select',
        options: expired,
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
    {
      values: {
        title: 'Activado',
        name: 'status',
        value: inputsFilters.status,
        type: 'select',
        options: status,
        required: true
      },
      actions: {
        onChange: onInputChangeV2
      }
    },
  ]

  useEffect(() => {
    if (!isEmpty(rows)) setNewRows(rows);
  }, [rows])

  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Notificaciones")}
        subTitle="Marketing / Notificaciones"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={newRows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}
      >
        {
          showFilters &&
          <GroupFilters
            handleSearchFilters={handleSearchFilters}
            withRightSection={false}
            inputs={inputs}
          />
        }
      </TableContainer>
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={() => {
          handleToggleModal();
        }}
        title="Activar / Desactivar notificación"
        message={`¿Desea ${checkedData.isChecked === 1 ? "desactivar" : "activar"
          } esta notificación?`}
        confirmButtonProps={{
          onClick: handleConfirmActionModal,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllNotificationsData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllNotificationsData);
