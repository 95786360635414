import HandlerBackend from "../../helpers/HandlerBackend";

export const getAllListAppMarketing = async (userId) => {
  try {
    const path = `/api/app-marketing/get-lists`;
    const body = {
      method: "GET",
      path,
      userId,
    };
    const response = await HandlerBackend.sendRequest(body);
    return response.hasOwnProperty("status") ? response.data.list.results : [];
  } catch (error) {
    return false;
  }
};

export const createListAppMarketing = async (userId, inputs) => {
  try {
    const path = `/api/app-marketing/create-list`;
    const data = {
      name: inputs.name.toUpperCase(),
      type: inputs.type,
      optin: inputs.optin,
      tags: inputs.tags,
    };
    const body = {
      method: "POST",
      path,
      userId,
      data,
    };
    const response = await HandlerBackend.sendRequest(body);
    return response.hasOwnProperty("status")
      ? response.data.createListApp.data
      : [];
  } catch (error) {
    return false;
  }
};
export const editListAppMarketing = async (userId, inputs) => {
  try {
    const path = `/api/app-marketing/edit-lists`;
    const data = {
      id: inputs.id,
      name: inputs.name.toUpperCase(),
      type: inputs.type,
      optin: inputs.optin,
      tags: inputs.tags,
    };
    const body = {
      method: "PUT",
      path,
      userId,
      data,
    };
    const response = await HandlerBackend.sendRequest(body);
    return response.hasOwnProperty("status")
      ? response.data.editListById.data
      : [];
  } catch (error) {
    return false;
  }
};

export const deleteListAppMarketing = async (userId, id) => {
  try {
    const path = `/api/app-marketing/delete-lists`;
    const data = {
      id,
    };
    const body = {
      method: "POST",
      path,
      userId,
      data,
    };
    const response = await HandlerBackend.sendRequest(body);
    return response.hasOwnProperty("status")
      ? response.data.deletelistbyId
      : [];
  } catch (error) {
    return false;
  }
};

export const syncUsersAppMarketing = async (userId, inputs) => {
  try {
    const path = `/api/app-marketing/sync-lists`;
    const data = {
      level: inputs.level,
      mode: inputs.mode,
      delim: inputs.delim,
      lists: inputs.lists,
      overwrite: inputs.overwrite,
      subscription_status:
        inputs.subscription_status ? "confirmed" : "unconfirmed",
    };
    const body = {
      method: "POST",
      path,
      userId,
      data,
    };
    const response = await HandlerBackend.sendRequest(body);
    return response.hasOwnProperty("status")
      ? response.data.importUsers.data
      : [];
  } catch (error) {
    return false;
  }
};
