import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modal.css";
import AddForm from "./AddForm";
const AddModal = (props, context) => {
  const {
    showModal,
    handleAddDepositLimitModal,
    loading,
    handleSubmitInputs
  } = props;

  const buttonProps = {
    text:"Añadir umbral",
    customClassName:"add-limit-button",
    className:"green"
  }

  return (
    <Modal className="add-deposit-limit-custom-modal-container" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleAddDepositLimitModal}>
        Añadir nuevo umbral para depósito
      </ModalHeader>
      <ModalBody>
        <AddForm loading={loading} handleSubmitInputs={handleSubmitInputs} handleAddDepositLimitModal={handleAddDepositLimitModal} buttonProps={buttonProps}/>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AddModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AddModal);
