export const alertStatus = [
    {
        value:"PENDING",
        name:"Pendiente"
    },
    {
        value:"REVIEWING",
        name:"En revisión"
    },
    {
        value:"RESOLVED",
        name:"Resuelto"
    }
]