import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';
import './resizableColumns.css';

const ResizableColumns = (props, context) => {
  const {
    childrenOne,
    childrenTwo,
    withRightSection=false,
    minWidth = '520px',
    maxWidth = '70%'
  } = props;

  const sides_resizables = {
    top: false,
    right: false,
    bottom: false,
    left: true,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false
  };

  return (
    <div className="resizable-columns-main">
      <div
        className={`resizable-first-child${withRightSection ? ' open-second-child' : ''}`}>
        {childrenOne}
      </div>
      {withRightSection && (
        <Resizable
          minWidth={minWidth}
          maxWidth={maxWidth}
          className="resizable-second-child"
          enable={sides_resizables}
          defaultSize={{ width: minWidth }}>
          {childrenTwo}
        </Resizable>
      )}
    </div>
  );
};

ResizableColumns.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo, selectedAccountId } }) => {
  return { userInfo, selectedAccountId };
};

export default connect(mapStateToProps)(ResizableColumns);
