import isEmpty from "is-empty";
import React, { useState } from "react";
import UtilsHelper from "../../../../helpers/Utils.helper";
import TableContainer from "../../Table/TableContainer";
import { processFileData } from "../processFileData";
import DeleteIcon from "../../../../images/delete-icon.svg";

export const FileAddForm = ({setRows, rows, setFile, setError}) => {
  const handleChangeFile = (ev) => {
    if (!isEmpty(ev.target.files)) {
        const file = ev.target.files[0]
        setFile(file)
        UtilsHelper.readSpreadsheet(file, setRows, processFileData);
        setError(false)
    }
  };

  const handleClearRows = () => {
    setFile(null)
    setRows([])
    document.getElementById("add-input-file-blacklist").value = ""
  }

  const previewTableHeaders = [
    {
      tag: "paterno_razon_social",
      title: "Razón social",
    },
    {
      tag: "nombre1",
      title: "Primer nombre",
    },
    {
      tag: "nombre2",
      title: "Segundo nombre",
    },
    {
      tag: "materno",
      title: "Apellido materno",
    },
    {
      tag: "tipo_doi",
      title: "Tipo de documento",
    },
    {
      tag: "numero_doi",
      title: "Número de documento",
    },
    {
      tag: "",
      title: "Fuente",
      cell: ({ data }) => <p className="data-source-cell">{data.fuente}</p>,
    },
  ];

  const Description = () => {
    return (
      <div className="add-file-form-description">
        <p>
          Debes crear un archivo CSV, con las siguientes cabeceras (En el mismo
          orden) <br />
          paterno_razon_social, materno, nombre1, nombre2, tipo_doi, numero_doi,
          fuente <br />
        </p>
        <br />
        <b>Observaciones:</b>
        <ul>
          <li>
            {" "}
            No puede haber mas de un registro con el mismo numero de documento,
            el sistema omitirá uno de ellos.
          </li>
          <li>
            Recuerde que excel borra los ceros a la izquierda en los números de
            documento, asegurese que esten correctos antes de cargar el
            documento
          </li>
        </ul>
        <p>
          O puedes descargar la siguiente{" "}
          <a target="_blank" download href="https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/blacklist_template.csv">
            plantilla CSV
          </a>{" "}
          y llenarla.
        </p>
      </div>
    );
  };
  return (
    <div>
      <Description />
      <label htmlFor="add-input-file-blacklist" className="add-input-file">
        <p>Seleccione archivo</p>
      </label>
      <input
        hidden
        id="add-input-file-blacklist"
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleChangeFile}
      />
      {!isEmpty(rows) && (
        <React.Fragment>
          <div className="flex items-center justify-between preview-title-container">
            <h2 className="preview-title">Vista previa</h2>
            <img src={DeleteIcon} onClick={handleClearRows} alt="trash" />
          </div>
          <div className="preview-container">
            <TableContainer
              headers={previewTableHeaders}
              rows={rows}
              enablePagination={false}
              rowIdSelected=""
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
