import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';
const queryString = require('query-string');

export const getUsers = async (userId, filterConfig, filterSearch) => {
  let result = {};
  try {
    let path = `/api/v2/acuant/customers`;

    let params = {
      filterConfig,
      filterSearch
    };

    let configRequest = {
      userId,
      method: 'POST',
      path,
      data: params
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getAuditByEmail = async (userId, email) => {
  let result = {};
  try {
    let path = `/api/audit`;

    let params = {
      email: email
    };

    if (!empty(params)) {
      path += '?' + queryString.stringify(params);
    }

    let configRequest = {
      userId,
      method: 'GET',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const createAcuantQueue = async (userId, data) => {
  let result = {};
  try {
    let path = `/api/v2/acuant/queue`;

    let configRequest = {
      userId,
      method: 'POST',
      path,
      data
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};
