import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFilter } from "../../../hooks/useFilter";
import isEmpty from "is-empty";
import FxRatesController from "../../FallControllers/FxRatesController";
import AllFxRatesData from "../../../components/FallComponents/FxRatesPage/AllLastRatesData";
import AllHistoricalRatesData from "../../../components/FallComponents/FxRatesPage/AllHistoricalRatesData";
import HeaderPage from "../../../components/FallComponents/HeaderPage/HeaderPage";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import { useModal } from "../../../hooks/useModal";
import AddRatesModal from '../../../components/FallComponents/FxRatesPage/Modal'
import AddIcon from "../../../images/add-icon.svg";

const FxRatesPage = (props, context) => {
  const { userInfo } = props;

  const [withRightSection, setWithRightSection] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const {showModal, handleToggleModal} = useModal();
  const [lastRatesRows, setLastRatesRows] = useState([]);
  const [historicalRatesRows, setHistoricalRatesRows] = useState([]);
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
  } = useFilter({
    filterConfigUrl: {
      limit: 10,
      page: 1,
    },
  });

  const getLastRates = () => {
    FxRatesController.getLastRates({
      userInfo,
      setLastRatesRows,
      setLoading:setLoading
    });
  };
  const getHistoricalRates = () => {
    FxRatesController.getHistoricalRates({
      userInfo,
      setHistoricalRatesRows,
      setLoading:setLoading2
    });
  };

  const headerOptions = [
    {
      icon: AddIcon,
      action: handleToggleModal,
    },
    {
      icon: RotateRight,
      action: () => {
        getLastRates()
        getHistoricalRates()
      },
    },
  ];

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getLastRates()
    getHistoricalRates()
  }, [userInfo, filterConfig]);

  return (
    <div className="dashboardPage container-page-data beta-content-container">
      <HeaderPage
        title="Tipos de cambio"
        subTitle="Tarifas / Tipos de cambio"
        headerOptions={headerOptions}
      />
        <div className="dashboard-container-main">
          <AllFxRatesData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            rows={lastRatesRows}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            loading={loading}
          />
          <AllHistoricalRatesData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            rows={historicalRatesRows}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            loading={loading2}
          />
        </div>
        {showModal && (
        <AddRatesModal
          showModal={showModal}
          handleToggleModal={handleToggleModal}
          data={{
            lastRatesRows,
            historicalRatesRows
          }}
          reloadFunctions = {{
            getHistoricalRates: () => getHistoricalRates(),
            getLastRates: () =>getLastRates() 
          }}
          title="Agregar tipo de cambio"
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

FxRatesPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(FxRatesPage);
