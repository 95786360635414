
import IDashboard from '../../images/new_icons/category.svg';
import Market from '../../images/new_icons/market.svg';
import SecurityUser from '../../images/new_icons/security-user.svg';
import ProfileUser from '../../images/new_icons/profile-user.svg';
import ReceiptText from '../../images/new_icons/receipt-text.svg';
import DocumentText from '../../images/new_icons/document-text.svg';
import DollarCircle from '../../images/new_icons/dollar-circle2.svg';
import ProfileTick from '../../images/new_icons/profile-tick2.svg';
import SettingsIcon from '../../images/new_icons/settingN.svg';

export const MenuItemsAccessV2 = [
    {
      name: 'Dashboard',
      nameEs: 'Tablero',
      icon: IDashboard,
      iconMain: 'DashboardIcon',
      childrens: [
        {
          menu: {
            text: 'Dashboard',
            icon: 'DashboardIcon',
            path: '/dashboard'
          },
          roleAccess: [
            'norole_r',
            'admin_rw',
            'cum_rw',
            'cum_r',
            'ope_rw',
            'ope_r',
            'con_rw',
            'con_r',
            'sop_r',
            'onb_rw',
            'onb_r',
            'apr_r',
            'apr_rw'
          ]
        }
      ]
    },
    {
      name: 'Marketing',
      nameEs: 'Marketing',
      icon: Market,
      iconMain: 'EmojiObjectsIcon',
      childrens: [
        {
          menu: {
            text: 'Sendgrid',
            icon: 'EmailIcon',
            path: '/marketing'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Notifications',
            icon: 'AnnouncementIcon',
            path: '/news'
          },
          roleAccess: ['admin_rw', 'ope_rw']
        },
        {
          menu: {
            text: 'Listmonk',
            icon: 'ReceiptLongIcon',
            path: '/appmarketing'
          },
          roleAccess: ['admin_rw', 'ope_rw']
        }
      ]
    },
    {
      name: 'Compliance',
      nameEs: 'Cumplimiento',
      icon: SecurityUser,
      iconMain: 'SecurityIcon',
      childrens: [
        {
          menu: {
            text: 'Black List',
            icon: 'PersonAddDisabledIcon',
            path: '/blacklist'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r']
        },
        {
          menu: {
            text: 'Aml Alerts',
            icon: 'VerifiedUserIcon',
            path: '/audit'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
        },
        {
          menu: {
            text: 'Deposit alerts',
            icon: 'VerifiedUserIcon',
            path: '/alertas-deposito'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
        },
        {
          menu: {
            text: 'Umbrales de depósito',
            icon: 'SettingsIcon',
            path: '/umbrales-deposito'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
        },
        {
          menu: {
            text: 'Countries',
            icon: 'PublicIcon',
            path: '/countries'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
        },
        {
          menu: {
            text: 'Reniec Search',
            icon: 'SearchIcon',
            path: '/reniec-search'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r', 'ope_rw', 'ope_r']
        },
        {
          menu: {
            text: 'Acuant',
            icon: 'AddBoxIcon',
            path: '/acuant'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r']
        },
        {
          menu: {
            text: 'Acuant Logs',
            icon: 'SearchIcon',
            path: '/acuant/logs'
          },
          roleAccess: ['admin_rw', 'cum_rw', 'cum_r']
        }
      ]
    },
    {
      name: 'Customers',
      nameEs: 'Clientes',
      icon: ProfileUser,
      iconMain: 'PeopleAltIcon',
      childrens: [
        {
          menu: {
            text: 'Natural',
            icon: 'NaturePeopleIcon',
            path: '/admin-levels'
          },
          roleAccess: [
            'admin_rw',
            'cum_r',
            'ope_rw',
            'ope_r',
            'con_r',
            'sop_r',
            'onb_rw',
            'onb_r'
          ]
        },
        {
          menu: {
            text: 'Legal',
            icon: 'BusinessIcon',
            path: '/admin-levels/corporate'
          },
          roleAccess: [
            'admin_rw',
            'cum_r',
            'ope_rw',
            'ope_r',
            'con_r',
            'sop_r',
            'onb_rw',
            'onb_r'
          ]
        },
        {
          menu: {
            text: 'Bank Accounts',
            icon: 'AccountBalanceIcon',
            path: '/fiat-accounts'
          },
          roleAccess: ['admin_rw', 'cum_r', 'ope_rw', 'ope_r', 'con_r', 'sop_r']
        },
        {
          menu: {
            text: 'AlphaPoint Accounts',
            icon: 'AccountBalanceIcon',
            path: '/alphapoint-accounts'
          },
          roleAccess: ['admin_rw', 'cum_r', 'ope_rw', 'ope_r', 'con_r', 'sop_r']
        }
      ]
    },
    {
      name: 'Tickets',
      nameEs: 'Tickets',
      icon: ReceiptText,
      iconMain: 'AssignmentIcon',
      childrens: [
        {
          menu: {
            text: 'Deposits',
            icon: 'SystemUpdateAltIcon',
            path: '/deposits'
          },
          roleAccess: ['admin_rw', 'cum_r', 'ope_r', 'con_r', 'sop_r']
        },
        {
          menu: {
            text: 'Withdraws',
            icon: 'OpenInNewIcon',
            path: '/withdraws'
          },
          roleAccess: ['admin_rw', 'cum_r', 'ope_r', 'con_rw', 'con_r', 'sop_r']
        },
        {
          menu: {
            text: 'Tx Hash',
            icon: 'PlaylistAddCheckIcon',
            path: '/tx-ids'
          },
          roleAccess: ['admin_rw', 'cum_r', 'ope_rw', 'ope_r', 'sop_r']
        }
      ]
    },
    {
      name: 'Billing',
      nameEs: 'Facturación',
      icon: DocumentText,
      iconMain: 'ListAltIcon',
      childrens: [
        {
          menu: {
            text: 'Ticket & invoices',
            icon: 'DescriptionIcon',
            path: '/admin-invoices-tickets'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Active Invoice',
            icon: 'ReceiptIcon',
            path: '/invoice-settings'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Series/Correlative',
            icon: 'BuildIcon',
            path: '/serial-correlative'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        }
      ]
    },
    {
      name: 'Finance',
      nameEs: 'Finanzas',
      icon: DollarCircle,
      iconMain: 'CreditCardIcon',
      childrens: [
        {
          menu: {
            text: 'Bank Transactions',
            icon: 'ImportExportIcon',
            path: '/bank-transactions'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Payment attempts (TDC)',
            icon: 'AccountBalanceWalletIcon',
            path: '/payment-attempts-tdc/10/1'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Fees',
            icon: 'TollIcon',
            path: '/admin-fees'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Banking movements',
            icon: 'DescriptionIcon',
            path: '/banking-movements'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Proof of payments',
            icon: 'DescriptionIcon',
            path: '/comprobantes-pago'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        },
        {
          menu: {
            text: 'Reportes de balances',
            icon: 'DescriptionIcon',
            path: '/reporte-de-balances'
          },
          roleAccess: ['admin_rw', 'con_rw', 'con_r']
        }
      ]
    },
    {
      name: 'Reports',
      nameEs: 'Reportes',
      icon: DocumentText,
      iconMain: 'ReportIcon',
      childrens: [
        {
          menu: {
            text: 'KPIs report',
            icon: 'TocIcon',
            path: '/admin-reports/kpis'
          },
          roleAccess: ['admin_rw', 'cum_r', 'ope_r']
        },
        {
          menu: {
            text: 'Trades report',
            icon: 'TocIcon',
            path: '/admin-reports/trades'
          },
          roleAccess: ['admin_rw', 'cum_r', 'ope_r']
        }
      ]
    },
    {
      name: 'Rates',
      nameEs: 'Tarifas',
      icon: DollarCircle,
      iconMain: 'AttachMoneyIcon',
      childrens: [
        {
          menu: {
            text: 'Fx Rates',
            icon: 'AttachMoneyIcon',
            path: '/fx-rates'
          },
          roleAccess: ['admin_rw', 'ope_rw', 'sop_r', 'con_r']
        }
      ]
    },
    {
      name: 'Permits',
      nameEs: 'Permisos',
      icon: ProfileTick,
      iconMain: 'HowToRegSharpIcon',
      childrens: [
        {
          menu: {
            text: 'Users',
            icon: 'SupervisedUserCircleIcon',
            path: '/admin-users'
          },
          roleAccess: ['admin_rw', 'sop_r']
        }
      ]
    },
    {
      name: 'Settings',
      nameEs: 'Configuraciones',
      icon: SettingsIcon,
      iconMain: 'SettingsIcon',
      childrens: [
        {
          menu: {
            text: 'Banexcoin accounts',
            icon: 'AccountBalanceIcon',
            path: '/banexcoin-accounts'
          },
          roleAccess: ['admin_rw', 'con_r', 'con_rw']
        },
        {
          menu: {
            text: 'Configuración General',
            icon: 'AccountBalanceIcon',
            path: '/global-config'
          },
          roleAccess: ['admin_rw', 'con_r', 'con_rw']
        },
        {
          menu: {
            text: 'Categorías y módulos',
            icon: 'AccountBalanceIcon',
            path: '/categories-and-modules'
          },
          roleAccess: ['admin_rw', 'con_r', 'con_rw']
        },
        {
          menu: {
            text: 'Autoaprobador de tickets',
            icon: 'AccountBalanceIcon',
            path: '/autoaprobador-ticket'
          },
          roleAccess: ['admin_rw', 'ope_rw', 'ope_r']
        },
        {
          menu: {
            text: 'Perfiles',
            icon: 'HowToRegSharpIcon',
            path: '/perfiles'
          },
          roleAccess: ['admin_rw', 'ope_rw', 'ope_r']
        }
      ]
    }
  ];
  