import isEmpty from "is-empty";
import { blackListTypes, toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { addRecordsBlacklist, deleteRecord, getAllResume,getBlackListById,getBlacklistErrorLog,getBlacklistErrorLogDownload,getBlacklistRecords, getQueuesLists, updateRecord, uploadRecordsBlacklist } from "../FallServices/BlackListServices";

class BlackListController {
  static getAllResume = async ({
    userInfo,
    setLoadingAllResume,
    setResumenData,
  }) => {
    try {
      setLoadingAllResume(true);
      const response = await getAllResume(userInfo.UserId);
      if (!isEmpty(response.data) && response.status === 200) {
        setResumenData(response.data);
      }
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoadingAllResume(false);
    }
  };

  static getBlacklistRecords = async ({userInfo, type}) => {
    try {
      ComponentsHelper.toast({
        type: toastTypes.info,
        message: "Descargando...",
      });
      await getBlacklistRecords(userInfo.UserId,blackListTypes[type])
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }
  };

  static getBlackListById = async ({userInfo,urlConfig,filter,setBlackListDataByType,setLoadingBlackListData}) => {
    try {
      setLoadingBlackListData(true)
      const response = await getBlackListById(userInfo.UserId,urlConfig,filter)
      if (!isEmpty(response.data) && response.status === 200) {
        setBlackListDataByType({
          data:response.data.rows,
          title:response.data.title,
          totalRows:response.data.totalCount
        })
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }finally{
      setLoadingBlackListData(false)
    }
  }

  static updateBlackListById = async ({userInfo,fields,type}) => {
    try {
      const response = await updateRecord(userInfo.UserId,type,fields)
      if(response.status === 200){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "La información pudo ser actualizada exitosamente",
        });
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser actualizada",
      });
      return false;
    }
  }

  static deleteBlackListById = async ({userInfo,id,type,setLoadingDeleteBlackListById}) => {
    try {
      setLoadingDeleteBlackListById(true)
      const response = await deleteRecord(userInfo.UserId,type,id)
      if(response.status === 200){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "La información pudo ser eliminada exitosamente",
        });
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser eliminada",
      });
      return false;
    }finally{ 
      setLoadingDeleteBlackListById(false)
    }
  }

  static saveCSVRows = async ({userInfo, data, type}) => {
    try {
      const response = await uploadRecordsBlacklist(userInfo.UserId, type, data)
      if(!isEmpty(response.data) && response.data.jobId){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Información agregada correctamente",
        });
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser eliminada",
      });
      return false;
    }
  }

  static getBlackListFiles = async ({userInfo,urlConfig,filter,setBlackListFiles,setLoadingBlackListFiles}) => {
    try {
      setLoadingBlackListFiles(true)
      const response = await getQueuesLists(userInfo.UserId,urlConfig,filter)
      if (!isEmpty(response.data) && response.status === 200) {
        let data =response.data.rows
        if(filter.queuesType === "DONE"){
          data = data.filter(item => item.queuesType === "DONE")
        }
        setBlackListFiles({
          data,
          totalRows:response.data.totalCount
        })
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }finally{
      setLoadingBlackListFiles(false)
    }
  }

  static getLogs = async ({userInfo,id,setLogs, setLogsLoading}) => {
    try {
      setLogsLoading(true)
      const response = await getBlacklistErrorLog(userInfo.UserId, id)
      if (response.status === 200) {
        setLogs(response.data)
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    }finally {
      setLogsLoading(false)
    }
  }

  static saveManuallyRows = async({userInfo, rows, type}) => {
    try {
      const response = await addRecordsBlacklist(userInfo.UserId, type, rows)
      if(!isEmpty(response.data) && !response.hasErrors){
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Información agregada correctamente",
        });
        return true
      }
      return false
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser guardada",
      });
      return false;
    }
  }

  static downloadLogs = async ({userInfo,id}) => {
    try {
      await getBlacklistErrorLogDownload(userInfo.UserId,id)
      return true
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser descargada",
      });
      return false;
    }
  }
}

export default BlackListController;
