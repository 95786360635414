import isEmpty from 'is-empty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import { useForm } from '../../../../hooks/formHooks';
import InformationSection from '../../InformationSection/InformationSection';
import { LoaderButton } from '../../Loader/Loader';
import './globalConfigSections.css'

const MinimumAssetValues = (props, context) => {

    const { allInitialSetting = {}, loaderInitialSetting, handleEditSettingByKey } = props;
    const { GLOBAL_MINIMUM_DEPOSIT, GLOBAL_MINIMUM_WITHDRAW } = allInitialSetting;

    const [minimunDeposit, setMinimunDeposit] = useState({});
    const [minimunWithdraw, setMinimunWithdraw] = useState({});

    const { inputs, onInputChangeV2, setInputs } = useForm(minimunDeposit);
    const { inputs: inputsN2, onInputChangeV2: onInputChangeV2N2, setInputs: setInputsN2 } = useForm(minimunWithdraw);

    const resetValuesDeposit = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = minimunDeposit[name];
        });
        setInputs({
            ...inputs,
            ...newInitialvalues
        });
    };

    const handleInitialValues = (data = {}) => {
        const newArray = Object.keys(data).map((key) => {
            return {
                label: key,
                name: key,
                type: "number",
                showCheckbox: false,
            };
        });
        return newArray;
    };

    const resetValuesWithdraw = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = minimunWithdraw[name];
        });
        setInputsN2({
            ...inputsN2,
            ...newInitialvalues
        });
    };

    const handleEdit = async (type, data, setInputs, setInitialData, initialData) => {
        const newData = {};
        Object.keys(data).forEach((key) => {
            newData[key] = Number(data[key]);
        });
        const response = await handleEditSettingByKey(type, newData);
        if (response) { setInitialData(newData); }
        else { setInputs(initialData); }
    }

    const informationDeposit = {
        title: 'Valores mínimos de Depósito',
        resetValues: resetValuesDeposit,
        submitData: () => handleEdit('minimumDeposit', inputs, setInputs, setMinimunDeposit, minimunDeposit),
        fields: handleInitialValues(minimunDeposit)
    };

    const informationWithdraw = {
        title: 'Valores mínimos de Retiro',
        resetValues: resetValuesWithdraw,
        submitData: () => handleEdit('minimumWithdraw', inputsN2, setInputsN2, setMinimunWithdraw, minimunWithdraw),
        fields: handleInitialValues(minimunWithdraw)
    };

    useEffect(() => {
        if (!isEmpty(allInitialSetting)) {
            setMinimunDeposit(GLOBAL_MINIMUM_DEPOSIT);
            setMinimunWithdraw(GLOBAL_MINIMUM_WITHDRAW);
            setInputs(GLOBAL_MINIMUM_DEPOSIT);
            setInputsN2(GLOBAL_MINIMUM_WITHDRAW);
        }
    }, [allInitialSetting])


    return (
        <section>
            <h2 className='sub-title'>Información de Activos</h2>
            {loaderInitialSetting ?
                <div className='section-loader'>
                    <LoaderButton color='orange' />
                    <p>Cargando información...</p>
                </div> :
                <div id='minimun-section'>
                    <InformationSection
                        data={informationDeposit}
                        inputs={inputs}
                        onChange={onInputChangeV2}
                        context={context}
                    />
                    <InformationSection
                        data={informationWithdraw}
                        inputs={inputsN2}
                        onChange={onInputChangeV2N2}
                        context={context}
                    />
                </div>
            }
        </section >
    )
}

MinimumAssetValues.contextTypes = {
    t: PropTypes.func.isRequired
};

export default MinimumAssetValues