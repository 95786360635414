export const validationParameters = {
  test_date_of_expiry:"Vigencia del documento expirada",
  test_face_recognition_ratio:"Similitud de selfie menor a la requerida",
  test_global_authenticity_value:"Autenticidad Global menor a la requerida",
  test_image_focus:"Imagen desenfocada",
  test_correspondence_viz_mrz_date_of_expiry:"Fecha de expiración no corresponde",
  test_global_authenticity_ratio:"Autenticidad global del documento menor a la requerida",
  test_mrz_fields_integrity_date_of_expiry:"Poca visibilidad de fecha de expiración",
  'document_types_candidate.no_co': 'El país de residencia es distinto a Colombia',
  'document_types_candidate.icao_code_differs': 'El documento no pertenece al país de residencia',
  'document_types_candidate.no_col_id': 'El tipo de documento no es aceptado para registro de Coink',
  'document_types_candidate.no_pe': 'El país de residencia es distinto a Perú',
  'document_types_candidate.no_pe_id': 'El tipo de documento no es aceptado para registro peruano',
  'document_types_candidate.no_passport': 'El tipo de documento no es aceptado para el registro',
}