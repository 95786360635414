import isEmpty from "is-empty";
import React from "react";
import Select from 'react-select'

export const SelectComponent = ({
  title,
  type,
  name,
  options,
  disabled,
  placeholder,
  value,
  onChange,
  ...restValues
}) => {
  const mappedOptions =
    type === "select" && options
      ? options.map((option) => ({
          value: option.value,
          label: option.name,
        }))
      : [];

  const selectedOption = () => {
    if (!options || isEmpty(mappedOptions) || !value) return;
    if (Array.isArray(value)) {
      return value.map((option) => ({
        value: option.value,
        label: option.name,
      }));
    } else {
      return mappedOptions.find((option) => option.value === value);
    }
  };
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      padding: 5,
      color: "var(--black-color)",
      backgroundColor: isFocused ? "var(--orange-color)" : "transparent",
    }),

    control: () => ({
      display: "flex",
      justifyContent: "space-between",
      marginTop: "-1.5rem",
      width: "90%",
    }),

    indicatorSeparator: () => ({
      backgroundColor: "transparent",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1.4rem",
      color: "var(--text-gray)!important",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.4rem",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "var(--orange-color)",
      borderRadius: "20px",
      "> div":{
        color:"var(--white-color)",
      }
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "1rem",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      fontSize: "1.2rem",
      color: "var(--black-color)",
      ":hover": {
        backgroundColor: "transparent",
        color: "var(--black-color)",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      marginTop: restValues.isMulti ? "1.5rem" : "",
    }),
  };

  const changeSelect = (e) => {
    onChange({
      name: name,
      value: (e && !restValues.isMulti) ? e.value : e,
      type: "select-one",
    });
  };

  return (
    <div className="input-select">
      <p>{title}</p>
      <Select
        options={mappedOptions}
        defaultValue={selectedOption()}
        onChange={changeSelect}
        disabled={disabled}
        styles={customStyles}
        placeholder={placeholder}
        {...restValues}
      />
    </div>
  );
};
