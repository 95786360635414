import React, { useState } from "react";
import isEmpty from "is-empty";
import {
  userStatus,
} from "../../../../../constants";
import UserPermitsController from "../../../../../pages/FallControllers/UserPermitsController";
import InformationSection from "../../../../FallComponents/InformationSection/InformationSection";

export const Permits = ({
  userInfo,
  data,
  resetUserDetails,
  profiles,
  context,
}) => {
  const [inputs, setInputs] = useState({
    user_status: String(data.userStatus),
    role_id: String(data.roleId),
  });
  const [profile, setProfile] = useState({
    profile: !isEmpty(data.profileByProfileId)
      ? data.profileByProfileId.id
      : "",
  });

  const handleUpdatePermits = async () => {
    const response = await UserPermitsController.updateUserPermits({
      userInfo,
      id: data.id,
      data:inputs,
    });
    if (response) {
      resetUserDetails();
    }
  };
  const handleUpdateProfile = async () => {
    const response = await UserPermitsController.updateProfile({
      userInfo,
      profileId: profile.profile,
      userUuid: data.id,
    });
    if (response) {
      resetUserDetails();
    }else{
      setProfile({
        profile: !isEmpty(data.profileByProfileId)
          ? data.profileByProfileId.id
          : "",
      })
    }
  };

  const resetValues = () => {
    setInputs({
      user_status: String(data.userStatus),
      role_id: String(data.roleId),
    });
  };
  const infoList = {
    title: "Detalles de permisos",
    resetValues: resetValues,
    submitData: handleUpdatePermits,
    fields: [
      {
        label: "Estados de usuario",
        name: "user_status",
        type: "select",
        options: userStatus,
        showCheckbox: false,
      },
    ],
  };
  const infoList2 = {
    title: "Detalles de perfil",
    resetValues: () => setProfile({
      profile: !isEmpty(data.profileByProfileId)
        ? data.profileByProfileId.id
        : "",
    }),
    submitData: handleUpdateProfile,
    fields: [
      {
        label: "Perfil",
        name: "profile",
        type: "select",
        options: profiles.map((item) => ({
          value: item.profileId,
          name: item.name,
        })),
        showCheckbox: false,
      },
    ],
  };

  return (
    <div className="content-tab user-permits-body permits-tab">
      <div className="content-tab-container">
        <InformationSection
          data={infoList}
          inputs={inputs}
          onChange={(target) =>
            setInputs({ ...inputs, [target.name]: target.value })
          }
          checkReturnData={() => {}}
          context={context}
        />
        <br />
        <br />
        <InformationSection
          data={infoList2}
          inputs={profile}
          onChange={(target) => setProfile({ profile: target.value })}
          checkReturnData={() => {}}
          context={context}
        />
      </div>
    </div>
  );
};
