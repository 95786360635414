export const notificationType = [
    {
        name: 'Sin filtro',
        value: ''
    },
    {
        name: 'Banner',
        value: 'BANNER'
    },
    {
        name: 'Carrusel',
        value: 'CARRUSEL'
    },
    {
        name: 'Cintillo',
        value: 'CINTILLO'
    },
];