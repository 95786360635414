import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../images/new_icons/close-circle.svg";
import DepositAlertsController from "../../FallControllers/DepositAlertsController";
import AllDepositAlertsData from "../../../components/FallComponents/DepositAlertsPage/AllDepositAlertsData";
import DateHelper from "../../../helpers/Date.helper";
import DepositAlertDetails from "../../../components/FallComponents/DepositAlertsPage/DepositAlertsDetails/DepositAlertsDetails";

const DepositAlertsPage = (props, context) => {
    const currentDate = DateHelper.getCurrentDate()
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingDepositsByAccount, setLoadingDepositsByAccount] = useState(true);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [depositsByAccount, setDepositsByAccount] = useState([]);
  const [date, setDate] = useState({ year: currentDate.year, month: currentDate.month });
  const [userId, setUserId] = useState(null);
  const [adminUserId, setAdminUserId] = useState(null);
  const [logs, setLogs] = useState([]);

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const getAlertDepositsByDate = async () => {
    await DepositAlertsController.getDepositAlertsByDate({
      userInfo,
      date,
      setRows,
      setLoading,
    });
  };

  const getDepositsByAccount = async (accountId) => {
    await DepositAlertsController.getDepositAlertsByAccountId({
      userInfo,
      date,
      setLoadingDepositsByAccount,
      setDepositsByAccount,
      accountId,
    });
  };
  const handleSelectRow = async ({ row }) => {
    setWithRightSection(true);
    setSelectedAlert(row);
    getDepositsByAccount(row.userAccountId);
    Promise.all([await DepositAlertsController.getUser({userInfo,id:row.userId,setUserId}), await DepositAlertsController.getLogs({userInfo,id:row.id,setLogs})])
  }

  const handleUpdateStatus = async ({newStatus,data,amlAlertCode}) => {
    const response = await DepositAlertsController.updateAlertStatus({userInfo,newStatus,amlAlertCode,data,adminId:adminUserId})
    if(response){
      getAlertDepositsByDate()
      setWithRightSection(false)
    }
  }

  const handleReloadDeposits = (accountId) => getDepositsByAccount(accountId)

  useEffect(() => {
    if (isEmpty(userInfo) || isEmpty(date)) return;
    getAlertDepositsByDate();
    if(!isEmpty(adminUserId)) return;
    DepositAlertsController.getUser({userInfo,id:userInfo.UserId,setUserId:setAdminUserId})
  }, [userInfo, date]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllDepositAlertsData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            rows={rows}
            loading={loading}
            handleSelectRow={handleSelectRow}
            setDate={setDate}
            selectedId={selectedAlert.idAlert}
            date={date}
          />
        }
        childrenTwo={
          <RightResizable
            loadingData={{
              title: "Cargando información",
              isLoading: false,
            }}
            headerOptions={headerOptions}
          >
            <DepositAlertDetails
              data={selectedAlert}
              loadingDepositsByAccount={loadingDepositsByAccount}
              depositsByAccount={depositsByAccount}
              handleUpdateStatus={handleUpdateStatus}
              userId={userId}
              logs={logs}
              handleReloadDeposits={handleReloadDeposits}
            />
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

DepositAlertsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DepositAlertsPage);
