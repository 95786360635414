import React, { useEffect, useState } from "react";
import DateHelper from "../../../../helpers/Date.helper";
import { EditableText } from "../../../FallComponents/EditableText/EditableText";
import editIcon from "../../../../images/new_icons/edit.svg";
import {CustomButton} from "../../../FallComponents/CustomButton/CustomButton";
import checkIcon from "../../../../images/new_icons/check.svg";
import crossIcon from "../../../../images/new_icons/cross.svg";
import "./blackListDetails.css";

function BlackListDetails({ data, documentTypes, updateBlackListById,deleteBlackListById, loadingDeleteBlackListById}) {
  const [isEditable, setIsEditable] = useState(false);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const initialValues = {
      createdAt: DateHelper.formatDate(data.createdAt),
      updatedAt: DateHelper.formatDate(data.updatedAt),
      paternoRazonSocial: data.paternoRazonSocial,
      secondName: data.secondName,
      lastName: data.lastName,
      typeDocument: data.typeDocument,
      numDocument: data.numDocument,
      blacklistType: data.blacklistType,
      blacklistSource: data.blacklistSource,
    };
    setInputs(initialValues)
  },[data])

  const handleIsEditable = () => setIsEditable(!isEditable);

  const handleConfirmUpdate = () => {
    handleIsEditable();
    if (isEditable) {
      updateBlackListById(
        {
          ...inputs,
          id: data.id,
          updatedAt: data.updatedAt,
          createdAt: data.updatedAt,
        },
        resetToInitialValues
      );
    }
  };
  const handleChange = (target) => {
    setInputs({ ...inputs, [target.name]: target.value });
  };

  const resetToInitialValues = () => {
    handleIsEditable();
    const initialValues = {
      createdAt: DateHelper.formatDate(data.createdAt),
      updatedAt: DateHelper.formatDate(data.updatedAt),
      paternoRazonSocial: data.paternoRazonSocial,
      secondName: data.secondName,
      lastName: data.lastName,
      typeDocument: data.typeDocument,
      numDocument: data.numDocument,
      blacklistType: data.blacklistType,
      blacklistSource: data.blacklistSource,
    }
    setInputs(initialValues);
  };

  const slots = [
    {
      title: "Fecha de creación",
      name: "createdAt",
      value: inputs.createdAt,
      editable: false,
    },
    {
      title: "Fecha de actualización",
      name: "updatedAt",
      value: inputs.updatedAt,
      editable: false,
    },
    {
      title: "Apellido paterno o razón social",
      name: "paternoRazonSocial",
      value: inputs.paternoRazonSocial,
      editable: isEditable,
      rootClassname: "blacklist-details-item",
    },
    {
      title: "Segundo nombre",
      name: "secondName",
      value: inputs.secondName,
      editable: isEditable,
      rootClassname: "blacklist-details-item",
    },
    {
      title: "Apellido materno",
      name: "lastName",
      value: inputs.lastName,
      editable: isEditable,
      rootClassname: "blacklist-details-item",
    },
    {
      title: "Tipo de documento",
      name: "typeDocument",
      value: inputs.typeDocument,
      editable: isEditable,
      type: "select",
      isClearable: true,
      options: documentTypes,
      rootClassname: "blacklist-details-item",
    },
    {
      title: "Número de documento",
      name: "numDocument",
      value: inputs.numDocument,
      editable: isEditable,
      rootClassname: "blacklist-details-item",
    },
    {
      title: "Tipo",
      name: "blacklistType",
      value: inputs.blacklistType,
      editable: isEditable,
      rootClassname: "blacklist-details-item",
    },
    {
      title: "Fuente o descripción",
      name: "blacklistSource",
      value: inputs.blacklistSource,
      editable: isEditable,
      rootClassname: "blacklist-details-item",
    },
  ];

  return (
    <div className="ticket-detail-main">
      <div className="ticket-body-container">
        <div className="flex items-center gap-1 mb-4">
          <h2 className="subtitle m-0">Detalles de usuario</h2>
          <img
            onClick={handleConfirmUpdate}
            src={isEditable ? checkIcon : editIcon}
          />
          {isEditable && <img onClick={resetToInitialValues} src={crossIcon} />}
        </div>
        <div className="slot-details body-section">
          {slots.map(
            ({
              title,
              value,
              type = "text",
              editable,
              name,
              rootClassname,
              ...rest
            }) => (
              <EditableText
                key={title}
                type={type}
                option={title}
                value={value}
                name={name}
                editable={editable}
                rootClassname={rootClassname}
                onChange={handleChange}
                {...rest}
              />
            )
          )}
        </div>
      </div>
      <div className="buttons-container">
        <div className="buttons-left"></div>
        <CustomButton text="Eliminar" className="red" customClassName="delete-blacklist-button" onClick={deleteBlackListById} disabled={loadingDeleteBlackListById}/>
      </div>
    </div>
  );
}

export default BlackListDetails;
