import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import HeaderPage from '../HeaderPage/HeaderPage';
import Profile from '../../../images/new_icons/profile.svg';
import Email from '../../../images/new_icons/email.svg';
import PersonalCard from '../../../images/new_icons/personalcard.svg';
import Chart from '../../../images/new_icons/chart.svg';
import Health from '../../../images/new_icons/health.svg';
import './pageDataClients.css';
import TableContainer from '../../../components/FallComponents/Table/TableContainer';
import { optionsLevel2, optionsStatus } from '../../../helpers/constants';
import { Context as ContextLevel } from '../../../pages/FallPages/Customers/Natural/ProviderLevels';
import { GroupFilters } from '../Filters/Filters';
import { useForm } from '../../../hooks/formHooks';
import NaturalCustomersController from '../../../pages/FallControllers/NaturalCustomersController';
import DataFormatterHelper from '../../../helpers/DataFormatter.helper';
import moment from 'moment';

import Download from '../../../images/new_icons/download.svg';
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';

const PageDataClients = (props, context) => {
  const {
    withRightSection,
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    setFilterSearch,
    resetFilters,
    headers,
    Userid
  } = props;

  const {
    setUserInfo,
    userInfo,
    setLoadingUserInfo,
    setTabSelect,
    handleImagesToZoom
  } = useContext(ContextLevel);

  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilters = () => setShowFilters(!showFilters);
  const [filters, setFilters] = useState({});

  const onChange = e => {
    const { name, value, type } = e;
    onInputChangeV2(e);
    setFilters({
      ...filters,
      [name]:
        type === 'select-one' || type === 'number'
          ? { in: Number(value) }
          : type === 'text'
            ? { includesInsensitive: String(value) }
            : { [name]: value ? moment(new Date(value)).format() : null }
    });
  };

  const handleSearchFilters = () => {
    let newFilters = DataFormatterHelper.cleanJSONFilters(filters);
    if (newFilters.idate && newFilters.fdate) {
      newFilters = {
        ...newFilters,
        createdAt: {
          greaterThanOrEqualTo: newFilters.idate.idate,
          lessThanOrEqualTo: newFilters.fdate.fdate
        }
      };
      delete newFilters.idate;
      delete newFilters.fdate;
    }
    !(newFilters.idate || newFilters.fdate) && setFilterSearch(newFilters);
  };

  const downloadRows = async () => {
    const arrayCheckbox = document.getElementsByClassName('input-check-select-hy');
    let selectedsArray = [];
    for (let item of arrayCheckbox) {
      if (item.checked) {
        selectedsArray.push(item.name);
      }
    }
    if (selectedsArray.length < 1) {
      for (let item of arrayCheckbox) {
        selectedsArray.push(item.name);
      }
    }
    selectedsArray.length > 0 &&
      (await NaturalCustomersController.downloadBasicLevels({
        ids: selectedsArray,
        context,
        Userid
      }));
  };

  const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(
    {
      banexcoinUserid: '',
      banexcoinUsername: '',
      firstName: '',
      middleName: '',
      documentNumber: '',
      email: '',
      userLevel: 'Sin filtro',
      status: '',
      idate: '',
      fdate: ''
    },
    handleSearchFilters
  );

  const inputs = [
    {
      values: {
        title: 'Account ID',
        name: 'banexcoinUserAccountId',
        value: inputsFilters.banexcoinUserAccountId,
        type: 'number',
        min: 1,
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        icon: Profile,
        title: 'Usuario',
        name: 'banexcoinUsername',
        value: inputsFilters.banexcoinUsername,
        type: 'text',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        icon: Profile,
        title: 'Primer nombre',
        name: 'firstName',
        value: inputsFilters.firstName,
        type: 'text',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        icon: Profile,
        title: 'Primer apellido',
        name: 'lastName',
        value: inputsFilters.lastName,
        type: 'text',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        icon: PersonalCard,
        title: 'N° de doc.',
        name: 'documentNumber',
        value: inputsFilters.documentNumber,
        type: 'text',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        icon: Email,
        title: 'Email',
        name: 'email',
        value: inputsFilters.email,
        type: 'text',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        icon: Chart,
        title: 'Nivel actual',
        name: 'userLevel',
        value: inputsFilters.userLevel,
        type: 'select',
        options: optionsLevel2,
        placeholder: 'Seleccione un nivel',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        icon: Health,
        title: 'Status',
        name: 'status',
        value: inputsFilters.status,
        type: 'select',
        options: optionsStatus,
        placeholder: 'Seleccione un estado',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        title: 'Desde',
        name: 'idate',
        value: inputsFilters.idate,
        type: 'date',
        required:true
      },
      actions: {
        onChange: onChange
      }
    },
    {
      values: {
        title: 'Hasta',
        name: 'fdate',
        value: inputsFilters.fdate,
        type: 'date',
        required:true
      },
      actions: {
        onChange: onChange
      }
    }
  ];

  const handleResetFilters = () => {
    setInputs({
      banexcoinUserAccountId: '',
      banexcoinUsername: '',
      firstName: '',
      middleName: '',
      documentNumber: '',
      email: '',
      userLevel: '',
      status: '',
      idate: '',
      fdate: ''
    });
    resetFilters();
  };

  const getUserID = id => {
    setUserInfo({
      ...userInfo,
      userId: id
    });
    if (id !== userInfo.userId) {
      setTabSelect('Cliente');
      setLoadingUserInfo(true);
      handleImagesToZoom();
    }
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? 'header-option-active' : ''
    },
    {
      icon: Download,
      action: downloadRows
    },
    {
      icon: RotateRight,
      action: handleResetFilters
    }
  ];

  return (
    <div className="container-page-data">
      <HeaderPage
        title="Clientes Naturales"
        subTitle="Clientes / Naturales"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={headers}
        getUserID={getUserID}
        rowIdSelected={userInfo.userId || ''}>
        {showFilters && (
          <GroupFilters
            handleSearchFilters={handleSearchFilters}
            withRightSection={withRightSection}
            inputs={inputs}
          />
        )}
      </TableContainer>
    </div>
  );
};

PageDataClients.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PageDataClients;
