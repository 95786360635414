import React from "react";
import DateHelper from "../../../../../helpers/Date.helper";
import Table from "../../../Table/Table";

export const History = ({ logs }) => {

  const descriptionConstructor = (description) => {
    try {
      const descriptionParse = JSON.parse(description)
      let html = ''
      Object.keys(descriptionParse).forEach((key) => {
        if(key === "email"){
          html += `<strong>Email actual: </strong>${descriptionParse[key][1] !== "null" ? descriptionParse[key][1] : "-"} <br/> <strong>Email anterior: </strong>${descriptionParse[key][0] !== "null" ? descriptionParse[key][0] : "-"}`
        } else {
          html += `<p>${descriptionParse[key]}</p>`
        }
      })
      return <div className="container-description" dangerouslySetInnerHTML={{__html: html}}/>
    } catch (error) {
      const descriptionParse = description.slice(1,-1).split(",")
      return (
        <>
          <strong>Actual: </strong>{descriptionParse[1] !== "null" ? descriptionParse[1] : "-"} <br/> 
          <strong>Anterior: </strong>{descriptionParse[0] !== "null" ? descriptionParse[0] : "-"}
        </>
      )
    }
  }
  const headers = [
    {
      tag: "type",
      title: "Acción",
    },
    {
      tag: "executeByName",
      title: "Ejecutado por",
    },
    {
      tag: "",
      title: "Descripción",
      cell:({data}) => descriptionConstructor(data.description)
    },
    {
      tag: "",
      title: "Fecha",
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className="table-wrapper">
        <Table
          showTooltipToCopy={false}
          rowIdSelected=""
          rowHead={headers}
          dataBody={logs}
        />
      </div>
    </div>
  );
};
