import React from 'react';
import PropTypes from 'prop-types';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';

export var HyphenDepositWorkflowComponent = function HyphenDepositWorkflowComponent(
  _ref,
  context
) {
  var Product = _ref.product.Product,
    bankInfo = _ref.bankInfo;
  return React.createElement(
    React.Fragment,
    null,
    React.createElement(APLabelWithText, {
      label: context.t('Bank Name:'),
      text: bankInfo.bankName
    }),
    React.createElement(APLabelWithText, {
      label: context.t('Wire Number:'),
      text: bankInfo.bankWireNumber
    }),
    React.createElement(APLabelWithText, {
      label: context.t('Account Number:'),
      text: bankInfo.bankAccountNumber
    })
  );
};

HyphenDepositWorkflowComponent.propTypes = {
  product: PropTypes.shape({
    Product: PropTypes.string.isRequired
  }).isRequired,
  bankInfo: PropTypes.object.isRequired
};

HyphenDepositWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default HyphenDepositWorkflowComponent;
