import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import points from '../../../../images/new_icons/more.svg';
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns';
import AllLegalData from '../../../../components/FallComponents/LegalPage/AllLegalData';
import { useFilter } from '../../../../hooks/useFilter';
import isEmpty from 'is-empty';
import LegalController from '../../../FallControllers/LegalController';
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable';
import closeIcon from '../../../../images/new_icons/close-circle.svg';
import LegalDetails from '../../../../components/FallComponents/LegalPage/LegalDetails/LegalDetails';
import ComponentsHelper from '../../../../helpers/Components.helper';
import ImagesZoom from '../../../../components/FallComponents/ImagesZoom/ImagesZoom';
import { CropModal } from '../../../../components/FallComponents/Modal/CropModal';

const LegalPage = (props, context) => {

    const { userInfo } = props;

    const [changeLimit, setChangeLimit] = useState(false);
    const [withRightSection, setWithRightSection] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedRow, setSelectedRow] = useState({});
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const [userId, setUserId] = useState('');
    const [rightLoading, setRightLoading] = useState(true);
    const [levelBasicInfo, setLevelBasicInfo] = useState({});
    const [shareholders, setShareholders] = useState([]);
    const [logReniecInfo, setLogReniecInfo] = useState([]);
    const [currentImages, setCurrentImages] = useState({
        url: null,
        document_country: null,
        document_number: null
    });
    const [banexcoinUserInfo, setBanexcoinUserInfo] = useState({});
    const [logUser, setLogUser] = useState([]);
    const [countries, setCountries] = useState([]);

    const [imagesZoom, setImagesZoom] = useState({ show: false, images: null });

    const [showModalToCropImage, setShowModalToCropImage] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [cropData, setCropData] = useState({});

    const defineLevel = (level, status) => {
        if (level === 17 && status === 'ACCEPTED') return 18;
        return level;
    }

    const onCropComplete = useCallback((croppedAreaPixels) => {
        setCropData(croppedAreaPixels);
    }, []);

    const handleImagesToZoom = images => {
        if (!images || (images.length === 1 && images[0].src === 'default')) {
            setImagesZoom({ show: false, images: null });
            return;
        }
        setImagesZoom({ show: true, images });
    };

    const {
        filterSearch,
        filterConfig,
        offset,
        showingTo,
        changePage,
        onChangeLimit,
        resetFilters,
        setFilterSearch
    } = useFilter({ totalRows });

    const handleSelectRow = ({ row }) => {
        setWithRightSection(true);
        setUserId(row.id);
        setSelectedRow(row)
    };

    const handleDownloadLegals = () => {
        LegalController.downloadLegals({
            userInfo
        })
    }

    const initialConfig = () => {
        resetFilters(20)
        setChangeLimit(true);
    }

    const handleLoadLegals = () => {
        LegalController.loadLegals({
            userInfo,
            filterConfig,
            filterSearch,
            setLoading,
            setRows,
            setTotalRows,
            defineLevel,
            context
        })
    }

    const cropDataReniec = (image, documentCountry, documentNumber) => {
        setShowModalToCropImage(true)
        setCurrentImages({
            url: image,
            document_country: documentCountry,
            document_number: documentNumber
        })
    }

    const handleSendRequestReniec = () => {
        LegalController.reniecRequest({
            userInfo,
            currentImages,
            cropData,
            levelBasicInfo,
            handleLoadLegalUserInfoById,
            context
        })
    }

    const handleActionCloseCropModal = () => {
        setShowModalToCropImage(false);
        ComponentsHelper.confirmAlert({
            title: 'Enviar a RENIEC',
            message:
                '¿Está seguro de que desea ENVIAR la solicitud de validación a RENIEC?',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => handleSendRequestReniec()
                },
                {
                    label: 'Cancelar',
                    onClick: () => { }
                }
            ]
        });
    };

    const resetUserInfoById = () => {
        setLevelBasicInfo({});
        setShareholders([]);
        setLogReniecInfo([]);
        setCurrentImages([]);
        setBanexcoinUserInfo({});
        setLogUser([]);
    }

    const handleLoadLegalUserInfoById = () => {
        LegalController.loadLegalUserInfoById({
            userInfo,
            userId,
            resetUserInfoById,
            setRightLoading,
            setLevelBasicInfo,
            setShareholders,
            setLogReniecInfo,
            setBanexcoinUserInfo,
            setLogUser,
            setCountries,
            defineLevel,
            context
        })
    }

    const handleIncrementLegalUserById = (setLoaderAccept) => {
        setLoaderAccept(true)
        LegalController.incrementLegalUserById({
            userInfo,
            userId,
            accountId: levelBasicInfo.banexcoinUserAccountId,
            handleLoadLegalUserInfoById,
            setLoaderAccept,
            handleLoadLegals,
            context
        })
    }

    const handleRejecLegalUserById = (setLoaderReject) => {
        setLoaderReject(true)
        LegalController.rejectLegalUserById({
            userInfo,
            userId,
            accountId: levelBasicInfo.banexcoinUserAccountId,
            handleLoadLegalUserInfoById,
            setLoaderReject,
            context
        })
    }

    const handleTrustPe = () => {
        LegalController.trustPe({
            userInfo,
            shareholders,
            username: levelBasicInfo.banexcoinUsername,
            accountId: levelBasicInfo.banexcoinUserAccountId,
            userId: levelBasicInfo.id,
            handleLoadLegalUserInfoById,
            context
        })
    }

    const handleReturnCorporateInfoFields = (data, message, setLoaderIncorrect) => {
        LegalController.returnCorporateInfoFields({
            userInfo,
            data,
            userId: levelBasicInfo.id,
            message,
            handleLoadLegalUserInfoById,
            setLoaderIncorrect,
            context
        })
    }

    const handleUpdateCorporateInfo = (data) => {
        LegalController.updateCorporateInfo({
            userInfo,
            data,
            handleLoadLegalUserInfoById,
            context
        })
    }

    const handleReturnCorporateShareholderInfoFields = (shareholderUserId, data, message, setLoaderIncorrect) => {
        LegalController.returnCorporateShareholderInfoFields({
            userInfo,
            data,
            shareholderUserId,
            message,
            handleLoadLegalUserInfoById,
            setLoaderIncorrect,
            context
        })
    }

    const handleUpdateCorporateShareholderInfo = (data) => {
        LegalController.updateCorporateShareholderInfo({
            userInfo,
            userId: levelBasicInfo.banexcoinUserid,
            data,
            handleLoadLegalUserInfoById,
            context
        })
    }

    const handleSendToIDM = (shareholderUserId, setLoaderIdVerif) => {
        setLoaderIdVerif(true)
        LegalController.sendToIDM({
            userInfo,
            shareholderUserId,
            setLoaderIdVerif,
            context
        })
    }

    const confirmTrustPe = () => {
        ComponentsHelper.confirmAlert({
            title: 'Enviar data a TRUST_PE',
            message: '¿Está seguro de que desea ENVIAR la solicitud?',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => handleTrustPe()
                },
                {
                    label: 'Cancelar',
                    onClick: () => { }
                }
            ]
        });
    }

    const confirmSendToIDM = (shareholderUserId, setLoaderIdVerif) => {
        ComponentsHelper.confirmAlert({
            title: '¿Enviar a DIG_iD_VERIF?',
            message: '¿Está seguro de que desea ENVIAR la solicitud a DIG_iD_VERIF?',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => handleSendToIDM(shareholderUserId, setLoaderIdVerif)
                },
                {
                    label: 'Cancelar',
                    onClick: () => { }
                }
            ]
        });
    }

    const headerOptions = [
        {
            component: (
                <Dropdown>
                    <Dropdown.Toggle className="ml-0 p-0">
                        <img src={points} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={confirmTrustPe}>
                            Enviar a TRUST_PE
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        },
        {
            image: closeIcon,
            alt: 'Close option',
            action: () => {
                setWithRightSection(false);
            }
        }
    ];

    useEffect(() => {
        if (!changeLimit) {
            initialConfig();
        }
        if (!isEmpty(userInfo) && changeLimit) {
            handleLoadLegals();
        }
    }, [userInfo, filterConfig, filterSearch])

    useEffect(() => {
        if (!isEmpty(userId)) {
            handleLoadLegalUserInfoById();
        }
    }, [userId])


    return (
        <React.Fragment>
            <div className="beta-content-container">
                <ResizableColumns
                    withRightSection={withRightSection}
                    minWidth={imagesZoom.show ? '1000px' : '650px'}
                    childrenOne={
                        <AllLegalData
                            withRightSection={withRightSection}
                            onChangeLimit={onChangeLimit}
                            changePage={changePage}
                            filterConfig={filterConfig}
                            setFilterSearch={setFilterSearch}
                            initialConfig={initialConfig}
                            handleDownloadLegals={handleDownloadLegals}
                            handleSelectRow={handleSelectRow}
                            totalRows={totalRows}
                            rows={rows}
                            loading={loading}
                            offset={offset}
                            showingTo={showingTo}
                            selectedId={userId}
                        />
                    }
                    childrenTwo={
                        <RightResizable
                            headerOptions={headerOptions}
                            loadingData={{
                                title: 'Cargando datos de cliente jurídico',
                                isLoading: rightLoading
                            }}
                            leftSection={imagesZoom.show ? <ImagesZoom images={imagesZoom.images} onClose={handleImagesToZoom} /> : null}
                        >
                            {!isEmpty(levelBasicInfo) ?  <LegalDetails
                                levelBasicInfo={levelBasicInfo}
                                shareholders={shareholders}
                                logUser={logUser}
                                countries={countries}
                                handleIncrementLegalUserById={handleIncrementLegalUserById}
                                handleRejecLegalUserById={handleRejecLegalUserById}
                                handleReturnCorporateInfoFields={handleReturnCorporateInfoFields}
                                handleUpdateCorporateInfo={handleUpdateCorporateInfo}
                                handleReturnCorporateShareholderInfoFields={handleReturnCorporateShareholderInfoFields}
                                handleUpdateCorporateShareholderInfo={handleUpdateCorporateShareholderInfo}
                                confirmSendToIDM={confirmSendToIDM}
                                handleImagesToZoom={handleImagesToZoom}
                                cropDataReniec={cropDataReniec}
                                userInfo={userInfo}
                                context={context}
                                handleLoadLegalUserInfoById={handleLoadLegalUserInfoById}
                                row={selectedRow}
                                handleLoadLegals={handleLoadLegals}
                            /> :  <React.Fragment>
                                    <div className="containerNoKYC">
                                        <img src="/local/landing-page/images/sad-face.png" />
                                        <h3>
                                            No existen datos o <span class="other-color">KYC</span>{' '}
                                            Eliminado...{' '}
                                        </h3>
                                        <p>Si cree que es un problema, comuníquese con Soporte..</p>
                                    </div>
                                </React.Fragment>
                    }
                        </RightResizable>
                    }
                />
            </div>
            {showModalToCropImage && (
                <CropModal
                    handleActionCloseCropModal={handleActionCloseCropModal}
                    showModalToCropImage={showModalToCropImage}
                    faceImage={currentImages.url}
                    crop={crop}
                    zoom={zoom}
                    setCrop={setCrop}
                    onCropComplete={onCropComplete}
                    setZoom={setZoom}
                />
            )}
        </React.Fragment>
    )
}

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

LegalPage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(LegalPage);