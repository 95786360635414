import { toast } from 'react-toastify';
import { toastTypes } from '../../constants';
import ComponentsHelper from '../../helpers/Components.helper';
import { downloadAllFiatAccounts, getAllFiatAccounts, approveFiatAccount, rejectedFiatAccount } from '../FallServices/BankAccountServices';

class BankAccountController {
   static loadBankAccount = async ({
      userInfo,
      filterConfig,
      filterSearch,
      setLoading,
      setRows,
      setTotalRows,
      context
   }) => {
      setLoading(true);
      const result = await getAllFiatAccounts(userInfo.UserId, filterConfig, filterSearch);
      if (result) {
         setRows(result.nodes)
         setTotalRows(result.totalCount)
      } else {
         ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('Information could not accesible') })
      }
      setLoading(false);
   };

   static downloadAccounts = async ({
      userInfo,
      context
   }) => {
      const result = await downloadAllFiatAccounts(userInfo.UserId);
      if (result) {
         ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Downloading...') })
      } else {
         ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('Information could not be saved') })
      }
   };

   static approveAccount = async ({
      userInfo,
      id,
      handleLoadBanksAccounts,
      setWithRightSection,
      setApproveButton,
      context
   }) => {
      setApproveButton(true);
      const result = await approveFiatAccount(userInfo.UserId, id);
      if (result) {
         ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Fiat account was approved successfully') })
         setWithRightSection(false);
         handleLoadBanksAccounts();
      } else {
         ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('Information could not be saved') })
      }
      setApproveButton(false);
   };

   static rejectedAccount = async ({
      userInfo,
      id,
      handleLoadBanksAccounts,
      setWithRightSection,
      setRejectedButton,
      context
   }) => {
      setRejectedButton(true);
      const result = await rejectedFiatAccount(userInfo.UserId, id);
      if (result) {
         ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Fiat account was rejected successfully') })
         setWithRightSection(false);
         handleLoadBanksAccounts();
      } else {
         ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('Information could not be saved') })
      }
      setRejectedButton(false);
   }
}

export default BankAccountController;
