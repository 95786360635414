import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../hooks/useFilter";
import RightResizable from "../../../components/FallComponents/RightResizable/RightResizable";
import close from "../../../images/new_icons/close-circle.svg";
import AllAmlAlertsData from "../../../components/FallComponents/AmlAlertsPage/AllAmlAlertsData";
import AmlAlertsDetails from "../../../components/FallComponents/AmlAlertsPage/AmlAlertsDetails/AmlAlertsDetails";
import AmlAlertsController from "../../FallControllers/AmlAlertsController";

const AmlAlertsPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [amlCodes, setAmlCodes] = useState({});
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAlertDataById, setLoadingAlertDataById] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [banexcoinUserData, setBanexcoinUserData] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [selectedAlert, setSelectedAlertData] = useState({});
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    onChangeLimit,
    setFilterSearch,
    resetFilters,
  } = useFilter({
    totalRows,
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const headerOptions = [
    {
      image: close,
      alt: "close option",
      action: () => {
        setWithRightSection(false);
      },
    },
  ];

  const getAllAlerts = () => {
    AmlAlertsController.getAllAlerts({
      userInfo,
      filterConfig,
      filterSearch,
      setRows,
      setTotalRows,
      setLoading,
    });
  };

  const getAlertDataById = async (id) => {
    const response = await AmlAlertsController.getAlertById({
      userInfo,
      id,
      setLoadingAlertDataById,
      setSelectedAlertData,
    });
    if (!response) return;
    const banexcoinUserData = await AmlAlertsController.getUserByBanexcoinId({
      userInfo,
      setBanexcoinUserData,
    });
    if (!banexcoinUserData) return;
    setBanexcoinUserData(banexcoinUserData);
  };

  const handleSelectRow = async ({ row }) => {
    setWithRightSection(true);
    if (selectedId === row.id) return;
    setSelectedId(row.id);
    getAlertDataById(row.id);
  };

  const handleUpdateUserOnboardingtatus = async (action) => {
    let data = {};
    if (action === "approve") {
      data = {
        level: 10,
        info: selectedAlert,
        logged_user: banexcoinUserData.id,
      };
    }
    if (action === "reject") {
      data = {
        user_status: 2,
        banexcoin_user_id: selectedAlert.banexcoinUserId,
        info: selectedAlert,
        logged_user: banexcoinUserData.id,
      };
    }
    const response = await AmlAlertsController.updateUserOnboardingStatus({
      userInfo,
      setLoadingUpdate,
      banexcoinUserId: banexcoinUserData.id,
      data,
      action,
    });
    if (!response) return;
    getAllAlerts();
  };

  const handleModifyEvent = async (inputs) => {
    const response = await AmlAlertsController.modifyEventDetails({
      userInfo,
      id: selectedId,
      data: inputs,
      setLoadingUpdate,
    });
    if (response) {
      Promise.all([await getAllAlerts(), await getAlertDataById(selectedId)]);
    }
  };
  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllAlerts();
    if (!isEmpty(amlCodes)) return;
    AmlAlertsController.getAllAmlCodes({ userInfo, setAmlCodes });
  }, [userInfo, filterConfig, filterSearch]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        minWidth="550px"
        childrenOne={
          <AllAmlAlertsData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            handleSelectRow={handleSelectRow}
            getAllAlerts={getAllAlerts}
            setFilterSearch={setFilterSearch}
            resetFilters={resetFilters}
            selectedId={selectedId}
            amlCodes={amlCodes}
          />
        }
        childrenTwo={
          <RightResizable
            loadingData={{
              title: "Cargando información",
              isLoading: loadingAlertDataById,
            }}
            headerOptions={headerOptions}
          >
            <AmlAlertsDetails
              data={selectedAlert}
              amlCodes={amlCodes}
              handleUpdateUserOnboardingtatus={handleUpdateUserOnboardingtatus}
              handleModifyEvent={handleModifyEvent}
              setLoadingUpdate={setLoadingUpdate}
              loadingUpdate={loadingUpdate}
            />
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AmlAlertsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AmlAlertsPage);
