import React from 'react';
import isEmpty from "is-empty";
import './tabsContainer.css';
import { Tab, Tabs } from 'react-bootstrap';

export const TabsContainer = ({ tabsOptions, tabSelect, setTabSelect, productInfo }) => {
  if(!isEmpty(productInfo) &&
      productInfo.ProductType  === "CryptoCurrency"){
    tabsOptions.splice(2, 1)
  }  
  return (
    <Tabs
      activeKey={tabSelect}
      onSelect={k => setTabSelect(k)}
      className="tabs-container">
        
      {tabsOptions.map((tab, i) => (
        <Tab
          key={i}
          eventKey={tab.title}
          title={tab.title}
          default={tab.default}
          className="cont-tab"
          disabled={tab.disabled || false}
          >
          {tab.component}
        </Tab>
      ))}
    </Tabs>
  );
};
