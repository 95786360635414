import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import InformationSection from '../../InformationSection/InformationSection'
import { useForm } from '../../../../hooks/formHooks';
import { CustomButton } from '../../CustomButton/CustomButton';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import { useModal } from '../../../../hooks/useModal';
import ModuleDetails from '../ModuleDetails/ModuleDetails';

const CategoryDetails = (props, context) => {

    const { categoryInfo, handleUpdateCategory, handleDeleteCategory, handleCreateModuleByCategoryId, handleUpdateModuleByCategoryId, handleDeleteModuleById, categoryOptions } = props

    const initialValues = {
        name: ''
    };

    const { showModal, handleToggleModal } = useModal();

    const { inputs, onInputChangeV2, setInputs } = useForm(initialValues);

    const resetValue = () => setInputs({ name: categoryInfo.name });

    const submitData = async () => {
        if (!inputs.name.trim()) return resetValue();
        if (inputs.name !== categoryInfo.name) {
            const response = await handleUpdateCategory(inputs.name);
            if (!response) resetValue();
        };
    };

    const deleteCategory = async () => {
        await handleDeleteCategory();
    };

    const assetInformation = {
        title: 'Información de categoría',
        resetValues: resetValue,
        submitData: submitData,
        fields: [
            {
                label: 'Nombre',
                name: 'name',
                type: 'text',
                showCheckbox: false
            }
        ]
    };

    useEffect(() => {
        setInputs({
            name: categoryInfo.name
        });
    }, [categoryInfo]);

    return (
        <React.Fragment>
            <div className="content-tab">
                <div className='category-detail-section'>
                    <InformationSection
                        data={assetInformation}
                        inputs={inputs}
                        onChange={onInputChangeV2}
                        context={context}
                    />
                    <ModuleDetails
                        categoryInfo={categoryInfo}
                        handleCreateModuleByCategoryId={handleCreateModuleByCategoryId}
                        handleUpdateModuleByCategoryId={handleUpdateModuleByCategoryId}
                        handleDeleteModuleById={handleDeleteModuleById}
                        categoryOptions={categoryOptions}
                        context={context}
                    />
                </div>
                <div className="buttons-container">
                    <CustomButton
                        text="Eliminar"
                        onClick={handleToggleModal}
                        className="red"
                    />
                </div>
            </div>
            <ConfirmModal
                showModal={showModal}
                handleToggleModal={() => { handleToggleModal(); }}
                title='Eliminar categoría'
                message='¿Está seguro que desea eliminar esta categoría?'
                confirmButtonProps={{
                    text: "Si",
                    onClick: deleteCategory
                }}
                cancelButtonProps={{
                    text: "No"
                }}
            />
        </React.Fragment>
    )
}

CategoryDetails.contextTypes = {
    t: PropTypes.func.isRequired
};

export default CategoryDetails