import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AllCoinkWithdrawalsPage from './AllCoinkWithdrawalsPage'
import { useFilter } from '../../../../../hooks/useFilter'
import CoinkTransactionsController from '../../../../FallControllers/CoinkTransactionsController'
import isEmpty from 'is-empty'

const CoinkWithdrawalsPage = (props, context) => {

    const { userInfo } = props;

    const [changeLimit, setChangeLimit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [withRightSection, setWithRightSection] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [rows, setRows] = useState([]);
  
    const {
        filterConfig,
        filterSearch,
        offset,
        showingTo,
        changePage,
        onChangeLimit,
        resetFilters,
        setFilterSearch
    } = useFilter({ totalRows });

    const initialConfig = () => {
        resetFilters(20)
        setChangeLimit(true);
    }

    const handleLoadCoinkTransactionsWithdrawals = () => {
        CoinkTransactionsController.handleAllCoinkTransactionsWithdrawals({
            userInfo,
            filterConfig,
            filterSearch,
            setLoading,
            setRows,
            setTotalRows
        })
    }


    const handleDownloadTransactionsDeposit = () => {
        CoinkTransactionsController.handleDownloadAllCoinkTransactionsWithdrawals ({
            userInfo,
            rows
        })
    }

    useEffect(() => {
        if (!changeLimit) initialConfig();
        if (!isEmpty(userInfo) && changeLimit) handleLoadCoinkTransactionsWithdrawals();
    }, [userInfo, filterConfig, filterSearch])

    return (
        <div className="beta-content-container">
                    <AllCoinkWithdrawalsPage
                        loading={loading}
                        setFilterSearch={setFilterSearch}
                        onChangeLimit={onChangeLimit}
                        filterConfig={filterConfig}
                        totalRows={totalRows}
                        offset={offset}
                        showingTo={showingTo}
                        changePage={changePage}
                        rows={rows}
                        withRightSection={withRightSection}
                        initialConfig={initialConfig}
                        context={context}
                        handleDownload={handleDownloadTransactionsDeposit}
                    />
        </div>
    )
};

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

CoinkWithdrawalsPage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(CoinkWithdrawalsPage);
