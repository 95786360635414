import React from 'react';
import '../NaturalCustomersDetails/naturalCustomersDetails.css'
import down from '../../../../images/new_icons/arrow-down.svg';

export const ItemKYCInformation = ({ level }) => {
  return (
    <div className="item-kyc-info">
      <p>
        Nivel {level.value} - {level.title}
      </p>
      <div className="right-options">
        <img src={down} alt="arrow to down" width="15px" />
      </div>
    </div>
  );
};
