import React from 'react';
import { LoaderButton } from '../Loader/Loader';
import './customButton.css';
export const CustomButton = ({
  className,
  onClick = () => {},
  text,
  type = '',
  disabled=false,
  showLoader=true,
  customClassName="",
  ...props
}) => {
  return (
    <button
      type={type}
      className={`custom-button button-${className} ${customClassName}`}
      onClick={onClick}
      disabled={disabled}
      {...props}>
      {text}
      {(showLoader && disabled) && <div className={`loaderBtn button-${className}`}><LoaderButton message='' /></div>}
    </button>
  );
};
