import UtilsHelper from "../../helpers/Utils.helper";

export const getAllAssets = async (userId, page, limit) => {
    const path = `/api/v1/public/config/assets/${page}/${limit}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const postNewAsset = async (userId, data) => {
    const path = "/api/v1/public/config/assets/create";
    const configRequest = {
        userId,
        method: "POST",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const deleteAsset = async (userId, assetId) => {
    const path = `/api/v1/public/config/assets/${assetId}`;
    const configRequest = {
        userId,
        method: "DELETE",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getAllAssetById = async (userId, assetId, page, limit) => {
    const path = `/api/v1/public/config/assets/authorized/${assetId}/${page}/${limit}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const editAssetById = async (userId, assetId, data) => {
    const path = `/api/v1/public/config/assets/edit/${assetId}`;
    const configRequest = {
        userId,
        method: "PATCH",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const createAuthorizedAssetById = async (userId, data) => {
    const path = '/api/v1/public/config/assets/authorized/create';
    const configRequest = {
        userId,
        method: "POST",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}
export const updateAuthorizedAssetById = async (userId, assetId, data) => {
    const path = `/api/v1/public/config/assets/authorized/edit/${assetId}`;
    const configRequest = {
        userId,
        method: "PATCH",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const deleteAssetAuthorizedById = async (userId, assetId) => {
    const path = `/api/v1/public/config/assets/authorized/${assetId}`;
    const configRequest = {
        userId,
        method: "DELETE",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const getAllSetting = async (userId) => {
    const path = `/api/v1/public/config/all/setting`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await UtilsHelper.sendRequest(configRequest);
}

export const editSettingByKey = async (userId, type, data) => {
    const path = `/api/v1/public/config/setting/edit/${type}`;
    const configRequest = {
        userId,
        method: "PUT",
        path,
        data
    };
    return await UtilsHelper.sendRequest(configRequest);
}