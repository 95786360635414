import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getCommissions } from "../FallServices/CommissionsServices";
import moment from "moment";

class CommissionsController {
  static getCommissionsByDate = async ({
    userInfo,
    setRows,
    setLoading,
    filterSearch,
  }) => {
    try {
      setLoading(true);
      const response = await getCommissions(
        userInfo.UserId,
        filterSearch
      );
      if (response.status !== 200) return false
      const data = !isEmpty(response.data) ? response.data : []
      const newData = data.map((item) => ({
        ...item,
        fecha: moment(item.fecha).format("DD/MM/YYYY"),
        fecDoc: moment(item.fecDoc).format("DD/MM/YYYY"),
        fecVen: moment(item.fecVen).format("DD/MM/YYYY"),
        empty: ""
      }))
      setRows(newData);
      if(isEmpty(newData)){
        ComponentsHelper.toast({
          type: toastTypes.warn,
          message: "No se encontraron registros",
        });
      }
      return true
    } catch (error) {
      setRows([])
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
}
export default CommissionsController;
