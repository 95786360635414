import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import AllSpreadUsdcData from "../../../components/FallComponents/ReportSpreadUsdcPage/AllSpreadUsdcData";
import SpreadController from "../../FallControllers/SpreadController";

const ReportSpreadUsdcPage = (props, context) => {
   const { userInfo } = props;
   const [rows, setRows] = useState([]);
   const [loading, setLoading] = useState(false);
   const [inputs, setInputs] = useState({});

   const getSpreadUsdcByDate = async () => {
      await SpreadController.getSpreadUsdcByDate({
         userInfo,
         filterSearch: inputs,
         setRows,
         setLoading,
      });
   };

   const resetData = () => {
      setRows([])
      setInputs({})
   }

   useEffect(() => {
      if (isEmpty(userInfo) || isEmpty(inputs)) return;
      getSpreadUsdcByDate();
   }, [userInfo, inputs]);

   return (
      <div className="beta-content-container">
         <ResizableColumns
            withRightSection={false}
            childrenOne={
               <AllSpreadUsdcData
                  rows={rows}
                  loading={loading}
                  setFilterSearch={setInputs}
                  resetData={resetData}
               />
            }
         />
      </div>
   );
};

const mapStateToProps = ({ user: { userInfo } }) => {
   return { userInfo };
};

ReportSpreadUsdcPage.contextTypes = {
   t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ReportSpreadUsdcPage);
