import React from "react";
import { months } from "../../../../../constants";
import DateHelper from "../../../../../helpers/Date.helper";
import editIcon from "../../../../../images/new_icons/edit.svg";
import checkIcon from "../../../../../images/new_icons/check.svg";
import crossIcon from "../../../../../images/new_icons/cross.svg";
import { EditableText } from "../../../EditableText/EditableText";

export const GeneralDetails = ({
  data,
  isEditable,
  handleCloseEdition,
  handleResetValues,
  alertStatusInputProps,
  alertCodeInputProps,
}) => {
  return (
    <div className="aml-alerts-actions-content">
      <div className="flex items-center gap-1">
        <h2 className="subtitle">Detalles de código de alerta</h2>
        <img
          onClick={handleCloseEdition}
          src={isEditable ? checkIcon : editIcon}
        />
        {isEditable && <img onClick={handleResetValues} src={crossIcon} />}
      </div>
      <div className="slot-details">
        <EditableText {...alertCodeInputProps} />
        <br />
      </div>
      <h2 className="subtitle">Detalle de alerta</h2>
      <div className="slot-details">
        <div className="option">
          <p>Fecha de generación</p>
          <p>{DateHelper.formatDate(data.createdAt)}</p>
        </div>
        <div className="option">
          <p>Fecha de actualización</p>
          <p>{DateHelper.formatDate(data.updatedAt)}</p>
        </div>
        <div className="option">
          <p>ID de alerta</p>
          <p>{data.idAlert}</p>
        </div>
        <div className="option">
          <p>Tipo de alerta</p>
          <p>{data.eventType}</p>
        </div>
        <div className="option">
          <p>Mes</p>
          <p>{months[data.alertMonth - 1]}</p>
        </div>
        <div className="option">
          <p>Año</p>
          <p>{data.alertYear}</p>
        </div>
      </div>
      <br />
      <h2 className="subtitle">Detalle de usuario</h2>
      <div className="slot-details">
        <div className="option">
          <p>Nombre de usuario</p>
          <p>{data.userName}</p>
        </div>
        <div className="option">
          <p>User account ID</p>
          <p>{data.userAccountId}</p>
        </div>
        <div className="option">
          <p>Nivel de usuario</p>
          <p>{data.userLevel}</p>
        </div>
        <div className="option">
          <p>Tipo de usuario</p>
          <p>{data.personType}</p>
        </div>
      </div>
      <div className="flex items-center gap-1">
        <h2 className="subtitle">Actualización de estado de alerta</h2>
        <img
          onClick={handleCloseEdition}
          src={isEditable ? checkIcon : editIcon}
        />
        {isEditable && <img onClick={handleResetValues} src={crossIcon} />}
      </div>
      <div className="slot-details">
        <EditableText {...alertStatusInputProps} />
      </div>
      <br />
    </div>
  );
};
