import React, { useEffect, useState } from "react";
import isEmpty from "is-empty";
import { confirmAlert } from "react-confirm-alert";
import { accountTypes, transferTypes } from "../../../../constants";
import { banksAccountBanexcoin, rejectStatus } from "../../../../helpers/lib";
import RoleAccessHelper from "../../../../helpers/RoleAccess.helper";
import WithdrawController from "../../../../pages/FallControllers/WithdrawController";
import ProcessInfoWithdraw from "./components/ProcessInfoWithdraw";
import BoxLimitsContainer from "../../BoxLimits/BoxLimitsContainer";
import { CustomButton } from "../../CustomButton/CustomButton";
import DateHelper from "../../../../helpers/Date.helper";
import { Alert } from 'react-bootstrap'
import {
  NationalTemplate,
  DomesticTransferUsTemplate,
  DomesticTransferUsWithBankTemplate,
  InternationalTemplate,
  InternationalWithoutIntermediaryBankTemplate,
} from "./commons";
import ModalRejectWithdraw from "./components/ModalRejectWithdraw";
import { TabsContainer } from "../../Tabs/TabsContainer";
import Accordion from "../../Accordion/AccordionComponent";
import { ClientDetails } from './ClientDetails'
import { BankDetails } from './BankDetails'
import { TicketDetails } from './TicketDetails'
import "./withdrawDetails.css";
import { CommentsTemplate } from "./Comments";
import Table from '../../../../components/FallComponents/Table/Table'
import { useModal } from "../../../../hooks/useModal";
import ConfirmModal from "../../../ConfirmModal/ConfirmModal";
function WithdrawDetails({
  limits,
  banexcoin_log: logs,
  ticket,
  context,
  level,
  products,
  userInfo,
  getWithdrawDataById,
  getWithdraws,
  handleImagesToZoom,
  editPermission
}) {
  const [comments, setComments] = useState([]);
  const [withdrawTemplate, setWithdrawTemplate] = useState(null);
  const [formComments, setFormComments] = useState(null);
  const [formStatusAdm, setFormStatusAdm] = useState(null);
  const [fullNameTemplate, setFullNameTemplate] = useState("");
  const [transactionsByCurrency, setTransactionsByCurrency] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [globalTransactionId, setGlobalTransactionId] = useState("");
  const [transferType, setTranferType] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [toStatus, setToStatus] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Usuario")
  const [loading, setLoading] = useState(false)
  const [rejectReason, setRejectReason] = useState({
    rejectReason: ""
  });
  const [productInfo, setProductInfo] = useState(null)
  const [action, setAction] = useState({
    title:"",
    message:"",
    function: () => {}
  })
  const { showModal: showActionModal, handleToggleModal: handleToggleActionModal } = useModal();

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  useEffect(() => {
    setProductInfo(products.find((p) => p.ProductId === ticket.AssetId))
    if (isEmpty(limits) && isEmpty(ticket) && isEmpty(logs)) return;
    if (!isEmpty(logs)) {
      const formInfo = logs[logs.length - 1].aditionalInfo;
      const formLog = JSON.parse(formInfo);
      setFormComments(formLog);
      const formInfoStatus = logs[0].aditionalInfo;
      const formLogstatus = JSON.parse(formInfoStatus);
      setFormStatusAdm(formLogstatus);
    }
    if (!isEmpty(ticket)) {
      const withdrawTemplate = JSON.parse(ticket.TemplateForm);
      setWithdrawTemplate(withdrawTemplate);
      const comments = ticket.Comments.map((c) => ({
        id: c.CommentId,
        date: DateHelper.formatDate(c.EnteredDateTime),
        comment: c.Comment,
      }));
      setComments(comments);
      setToStatus(rejectStatus(ticket.Status));
    }
    if (!isEmpty(level)) {
      const fullName = !isEmpty(level.companyLegalName)
        ? `${level.companyLegalName}, ${level.legalEntityType}`
        : `${level.lastName} ${level.secondLastName}, ${level.firstName} ${level.middleName
        }`;
      setFullNameTemplate(fullName);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(withdrawTemplate)) {
      const transferType = transferTypes.find(
        (tt) => tt.type === withdrawTemplate.accountTransferType
      );
      const accountType = accountTypes.find(
        (tt) => tt.type === withdrawTemplate.accountType
      );
      setTranferType(transferType);
      setAccountType(accountType);
    }
  }, [withdrawTemplate]);


  const getCurrencyByBankAccount = (bankAccount) => {
    const findAccount = banksAccountBanexcoin.find(b => b.bankAccount === bankAccount);
    return !isEmpty(findAccount) ? findAccount.currency : "-";
  };

  const statusToShowButtons = ["New", "AdminProcessing", "Pending2Fa"]
  const validationToShowAcceptButton = () => {
    return (
      !isEmpty(productInfo) &&
      productInfo.ProductType === "CryptoCurrency" &&
      statusToShowButtons.includes(ticket.Status) &&
      RoleAccessHelper.hasAccesstoAction("withdraw_accept")
    );
  };
  const validationToShowRejectButton = () => {
    return (
      !isEmpty(productInfo) &&
      productInfo.ProductType === "CryptoCurrency" &&
      statusToShowButtons.includes(ticket.Status) &&
      RoleAccessHelper.hasAccesstoAction("withdraw_reject")
    );
  };
  const validationToShowFindTransactionButton = () => {
    return (
      (ticket.AssetName === "United States Dollar" || ticket.AssetName === "Peruvian Sol") &&
      RoleAccessHelper.hasAccesstoAction("withdraw_find_transaction")
    );
  };
  const validationToShowWithdrawTemplate = () => {
    return (
      !isEmpty(productInfo) &&
      productInfo.ProductType === "NationalCurrency" &&
      !isEmpty(withdrawTemplate)
    );
  };


  const handleProcessTransaction = async () => {
    const result = await WithdrawController.processTransaction({
      ticket,
      userInfo,
      transactionId: globalTransactionId,
      setLoading,
      setTransactions,
      context
    })
    if (result) getWithdrawDataById()
    setTransactionsByCurrency([])
  }
  const handleFindTransaction = async () => {
    const response = await WithdrawController.findTransaction({
      setLoading,
      ticket,
      userInfo,
      setTransactions,
      getCurrencyByBankAccount,
      setTransactionsByCurrency,
      transactions,
      context,
    });
    if(response){
      setSelectedTab("IPR")
    }
  };
  const handleSubmitNewComment = async (value, setLoadingComments) => {
    const comment = value
    if (isEmpty(comment)) return
    const result = await WithdrawController.sendNewComment({
      setLoadingComments,
      userInfo,
      comment: {
        comment,
        ticketCode: ticket.RequestCode,
        ticketId: ticket.TicketNumber
      },
      context,
    });
    if (result) getWithdrawDataById()
  };
  const handleRejectWithdrawByAdmin = async () => {
    const result = await WithdrawController.rejectWithdrawByAdmin({
      setLoading,
      ticket,
      context,
      userInfo,
      handleCloseModal,
      toStatusName: toStatus.cancelStatus,
      rejectReason: rejectReason.rejectReason,
      aditionalInfo: ''
    });
    if (result){
    getWithdraws();
    getWithdrawDataById();
    }
  };
  
  const handleAcceptWithdrawByAdmin = async () => {
    const result = await WithdrawController.acceptWithdrawByAdmin({
      context,
      setLoading,
      ticket,
      userInfo,
      aditionalInfo: "",
    });
    if (result){
      getWithdraws();
      getWithdrawDataById();
    }
  }
  const handleChangeInputReasonToReject = (e) => {
    e.persist();
    setRejectReason({
      ...rejectReason,
      [e.target.name]: e.target.value,
    });
  };

  const handleModalToConfirmBankTransactionSubmit = () => {
    confirmAlert({
      title: "Asociar Transacción Bancaria",
      message: "¿Está seguro de que desea Asociar Transacción Bancaria?",
      buttons: [
        {
          label: "Aceptar",
          onClick: handleProcessTransaction,
        },
        {
          label: "Cancelar",
          onClick: () => { },
        },
      ],
    });
  };
  const handleModalToConfirmAcceptWithdraw = () => {
    setAction({
      title:"Aprobar solicitud",
      message:"¿Está seguro de que deseas aprobar la solicitud?",
      function: handleAcceptWithdrawByAdmin
    })
    handleToggleActionModal()
  };
  const handleModalToConfirmRejectWithdraw = () => {
    handleOpenModal();
  };


  const HeaderSection = ({ fullNameTemplate, ticketStatus,ticketNumber }) => {
    return (
      <div className="header-section">
        <div>
          <h2>Ticket de Retiro # {ticketNumber}</h2>
          <h2>{fullNameTemplate}</h2>
          <p>Estado:<br /> <strong className={`ticket-status ticket-status-${ticketStatus}`}>{ticketStatus}</strong></p>
        </div>
      </div>
    )
  }

  const validationStatus = (data) => data.status === 1 ? "PENDING" : ""

  const validationToDetailsRequest = (data) => !isEmpty(data.payload) ? JSON.parse(data.payload).detail : "-"

  const FormTransactionByCurrency = () => {
    const [loading, setLoading] = useState(false)

    const handleSelectRow = ({row}) => {
      setGlobalTransactionId(row.id)
    }
    const tableHeaders = [
      {
        tag: 'bankCode',
        title: 'Bank',
        handleSelectRow
      },
      {
        tag: 'sourceBankAccountNumber',
        title: 'Account Number',
        handleSelectRow
      },
      {
        tag: 'transactionReference',
        title: 'Reference ID',
        handleSelectRow
      },
      {
        tag: '',
        title: 'Currency',
        cell: ({ data }) => <p>{getCurrencyByBankAccount(data.sourceBankAccountNumber)}</p>,
        handleSelectRow
      },
      {
        tag: '',
        title: 'Currency',
        cell: ({ data }) => <p>{getCurrencyByBankAccount(data.sourceBankAccountNumber)}</p>,
        handleSelectRow
      },
      {
        tag: 'transactionAmount',
        title: 'Amount',
        handleSelectRow
      },
      {
        tag: 'transactionDate',
        title: 'Transaction Date',
        handleSelectRow
      },
      {
        tag: '',
        title: 'Status',
        cell: ({ data }) => <p>{validationStatus(data)}</p>,
        handleSelectRow
      },
      {
        tag: '',
        title: 'Request Username',
        cell: ({ data }) => <p>{validationToDetailsRequest(data)}</p>,
        handleSelectRow
      },
    ];
    return (
      <div className="container-tbl-dep">
        <Table rowHead={tableHeaders} dataBody={transactionsByCurrency} rowIdSelected={globalTransactionId} />
        {!isEmpty(globalTransactionId) && <CustomButton disabled={loading} text={context.t("Reclamar transacción")} onClick={() => handleModalToConfirmBankTransactionSubmit(setLoading)} className="orange" />}
      </div>
    );
  };
  const IPRFormTemplate = ({handleFindTransaction}) => {
    return (
      (ticket.Status !== 'Pending2Fa') ? (
      <React.Fragment>
        {!isEmpty(productInfo) &&
          productInfo.ProductType === "NationalCurrency" &&
          (
            <ProcessInfoWithdraw
              handleImagesToZoom={handleImagesToZoom}
              ticket={ticket}
              userInfo={userInfo}
              getWithdrawDataById={getWithdrawDataById}
              formComments={formComments}
              formStatusAdm={formStatusAdm}
              handleFindTransaction={handleFindTransaction}
              getWithdraws={getWithdraws}
              comments={comments}
              editPermission={editPermission}
            >
              {!isEmpty(transactionsByCurrency) ? <FormTransactionByCurrency /> : <React.Fragment/>}
            </ProcessInfoWithdraw>
          )}
      </React.Fragment>
          ):  
   ( <div>
    <Alert variant={'warning'}>
      <h3 className="text-wng-ipr">
        {context.t(
          'Para visualizar los datos de este retiro, el status del ticket debe estar aprobado por el Cliente'
        )}
      </h3>
    </Alert>
  </div>)
    )
  }
  const FirstTabOptions = () => {
    return (
      <div className="content-tab">
        <div className="content-tab-container withdraw-details-content">
          {optionsAccordion.map(({ title, component }, key) => (
            <Accordion title={title} Component={component} key={key} />
          ))}
        </div>
        {editPermission && 
          <div className="buttons-container">
            <div className="buttons-left">
              {validationToShowAcceptButton() && (
                <CustomButton
                  text="Aceptar"
                  onClick={handleModalToConfirmAcceptWithdraw}
                  className="green"
                />
              )}
              {validationToShowRejectButton() && (
                <CustomButton
                  text="Rechazar"
                  onClick={handleModalToConfirmRejectWithdraw}
                  className="red"
                />
              )}
              {validationToShowFindTransactionButton() && (
                <CustomButton
                  text="Buscar transacción"
                  onClick={handleFindTransaction}
                  className="orange"
                />
              )}
            </div>
          </div>
        }
      </div>
    )
  }


  const withdrawTemplatesTypesProps = {
    transferType,
    withdrawTemplate,
    context,
    accountType,
  };
  const withdrawTemplatesTypes = {
    NATIONAL: <NationalTemplate {...withdrawTemplatesTypesProps} />,
    DOMESTIC_TRANSFER_US: (
      <DomesticTransferUsTemplate {...withdrawTemplatesTypesProps} />
    ),
    DOMESTIC_TRANSFER_US_WITH_BANK: (
      <DomesticTransferUsWithBankTemplate {...withdrawTemplatesTypesProps} />
    ),
    INTERNATIONAL: <InternationalTemplate {...withdrawTemplatesTypesProps} />,
    INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK: (
      <InternationalWithoutIntermediaryBankTemplate
        {...withdrawTemplatesTypesProps}
      />
    ),
  };

  const optionsAccordion = [
    {
      title: 'Información del Cliente',
      component: (<ClientDetails context={context} level={level} ticket={ticket} />)
    },
    {
      title: 'Información del Ticket',
      component: (<TicketDetails context={context} ticket={ticket} />)
    },
    {
      title: 'Información del Banco',
      component: (<BankDetails
        context={context}
        ticket={ticket}
        validationToShowWithdrawTemplate={validationToShowWithdrawTemplate}
        withdrawTemplate={withdrawTemplate}
        withdrawTemplatesTypes={withdrawTemplatesTypes}
      />)
    }
  ]
  const tabsOptions = [
    {
      title: "Usuario",
      component: (
        <FirstTabOptions />
      ),
      default: true
    },
    {
      title: 'Límites',
      component: (
        <BoxLimitsContainer limits={limits} type="withdraw" />
      ),
      default: false
    },
    {
      title: 'IPR',
      component: (
        <IPRFormTemplate handleFindTransaction={handleFindTransaction} />
      ),
      default: false
    },
    {
      title: 'Comentarios',
      component: (
        <CommentsTemplate context={context} comments={comments} handleSubmitNewComment={handleSubmitNewComment} editPermission={editPermission}/>
      )
    }
  ];

  return (
    <React.Fragment>
      <div className="layout-details withdraw-details">
        <HeaderSection
          fullNameTemplate={fullNameTemplate}
          ticketStatus={ticket.Status}
          ticketNumber={ticket.TicketNumber}
        />
        <div className="container-tabs">
          <TabsContainer
            tabsOptions={tabsOptions}
            tabSelect={selectedTab}
            setTabSelect={setSelectedTab}
            productInfo={productInfo}
          />
        </div>
      </div>
      {showModal && (
         <ModalRejectWithdraw
          loading={loading}
          toStatus={toStatus}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleChangeInputReasonToReject={handleChangeInputReasonToReject}
          handleRejectWithdrawByAdmin={handleRejectWithdrawByAdmin}
          rejectReason={rejectReason}
        />
      )}
      <ConfirmModal
        showModal={showActionModal}
        handleToggleModal={() => {
          handleToggleActionModal();
        }}
        title={action.title}
        message={action.message}
        confirmButtonProps={{
          onClick: action.function,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </React.Fragment>
  );
}

export default WithdrawDetails;
