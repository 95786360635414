import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import AllProofPaymentData from "../../../components/FallComponents/ProofPaymentPage/AllProofPaymentData";
import ProofPaymentController from "../../FallControllers/ProofPaymentController";

const ProofPaymentPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});

  const getProofPaymentsByDate = async () => {
    await ProofPaymentController.getProofPaymentsByDate({
      userInfo,
      filterSearch:inputs,
      setRows,
      setLoading,
    });
  };

  const resetData = () => {
    setRows([])
    setInputs({})
  }

  useEffect(() => {
    if (isEmpty(userInfo) || isEmpty(inputs)) return;
    getProofPaymentsByDate();
  }, [userInfo, inputs]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllProofPaymentData
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            rows={rows}
            loading={loading}
            setFilterSearch={setInputs}
            resetData={resetData}
          />
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

ProofPaymentPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ProofPaymentPage);
