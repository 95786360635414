import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import DateHelper from "../../../helpers/Date.helper";
import { GroupFilters } from "../Filters/Filters";
import { alertStatus } from "../../../constants";
import statusComponent from "../StatusComponent/StatusComponent";

const AllDepositAlertsData = (props, context) => {
  const {
    totalRows,
    rows,
    loading,
    filterConfig,
    handleSelectRow,
    selectedId,
    withRightSection,
    setDate,
    date,
    setWithRightSection
  } = props;
  const [filterInputs, setFilterInputs] = useState({
    date:String(date.month).length < 2 ? `${date.year}-0${date.month}` : `${date.year}-${date.month}`
  });
  const [showFilters, setShowFilters] = useState(true);
  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleOnChange = (target) => {
    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
    });
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? "header-option-active" : "",
    },
    {
      icon: RotateRight,
      action: () => {
        setWithRightSection(false)
        const {year,month} = DateHelper.getCurrentDate()
        setFilterInputs({
          date:String(month).length < 2 ? `${year}-0${month}` : `${year}-${month}`
        });
        setDate({year,month})
      },
    },
  ];

  const handleSearchFilters = () => {
    const [year, month] = filterInputs.date.split("-");
    setDate({ year, month });
  };

  const tableHeaders = [
    {
      tag: "idAlert",
      title: "Id de alerta",
      handleSelectRow,
    },
    {
      tag: "userId",
      title: "User ID",
      handleSelectRow,
    },
    {
      tag: "userName",
      title: "Nombre de usuario",
      handleSelectRow,
    },
    {
      tag: "userAccountId",
      title: "User account ID",
      handleSelectRow,
    },
    {
      tag: "amlAlertCode",
      title: "Código de alerta",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Fecha de creación",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
    },
    {
      tag: "",
      title: "Fecha de actualización",
      handleSelectRow,
      cell: ({ data }) => <p>{DateHelper.formatDate(data.updatedAt)}</p>,
    },
    {
      tag: "personType",
      title: "Tipo de persona",
      handleSelectRow,
    },
    {
      tag: "userLevel",
      title: "Nivel de usuario",
      handleSelectRow,
    },
    {
      tag: "",
      title: "Estado",
      handleSelectRow,
      cell: ({ data }) =>
        statusComponent(
          data.alertStatus.toUpperCase(),
          false,
          alertStatus.map((status) => ({
            id: status.value.toUpperCase(),
            name: status.name,
          }))
        ),
    },
  ];

  const filters = [
    {
      values: {
        title: "Fecha",
        name: "date",
        value: filterInputs.date,
        type: "month",
        placeholder: "Seleccione una fecha",
        required: true,
      },
      actions: {
        onChange: handleOnChange,
      },
    },
  ];

  return (
    <div className="all-container-data-admin-v2">
      <HeaderPage
        title={context.t("Alerta de depósitos")}
        subTitle="Cumplimiento / Alerta de depósitos"
        headerOptions={headerOptions}
      />
      <TableContainer
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        headers={tableHeaders}
        rowIdSelected={selectedId}
        enablePagination={false}
        identifierSlot="idAlert"
      >
        {showFilters && (
          <GroupFilters
            inputs={filters}
            withRightSection={withRightSection}
            handleSearchFilters={handleSearchFilters}
          />
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

AllDepositAlertsData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllDepositAlertsData);
