import isEmpty from 'is-empty';
import UtilsHelper from '../../helpers/Utils.helper';
import queryString from 'query-string';

export const getDeposits = async (params, userId) => {
  try {
    let path = '/api/depositTickets';
    if (!isEmpty(params)) {
      path += '?' + queryString.stringify(params);
    }
    const configRequest = {
      userId,
      method: 'get',
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return response.data.hasOwnProperty('allTickets') ? response.data.allTickets : [];
  } catch (error) {
    throw error;
  }
};

export const getDepositTicketByID = async (accountId, requestCode, userId) => {
  try {
    const path = `/api/depositTickets/${accountId}/${requestCode}`;
    const configRequest = {
      userId,
      method: 'get',
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
};

export const getLevelByAccountId = async (userId, accountId) => {
  try {
    const path = `/api/levels/accountid/${accountId}`;
    const configRequest = {
      userId,
      method: 'get',
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
};

export const bankAccounts = async userId => {
  try {
    const path = '/api/services/bank/accounts';
    const configRequest = {
      userId,
      method: 'POST',
      data: { path: '/api/v1/bank/accounts' },
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }
};

export const bankTransactions = async (userId, data) => {
  try {
    const path = '/api/services/bank/transactions';
    const configRequest = {
      userId,
      method: 'POST',
      data,
      path
    };
    const response = await UtilsHelper.sendRequest(configRequest);
    return !isEmpty(response.data) ? response.data : {};
  } catch (error) {
    throw error;
  }
};

export const acceptDeposit = async (userId, data) => {
  try {
    const path = '/api/depositTickets/accept';
    const configRequest = {
      userId,
      method: 'post',
      path,
      data
    };
    return UtilsHelper.sendRequest(configRequest);
  } catch (error) {
    throw error;
  }
};

export const rejectDeposit = async (userId, data) => {
  try {
    const path = '/api/depositTickets/reject';
    const configRequest = {
      userId,
      method: 'post',
      path,
      data
    };
    return UtilsHelper.sendRequest(configRequest);
  } catch (error) {
    throw error;
  }
};

export const freezeBankTransactions = async (userId, data) => {
  try {
    const path = '/api/services/bank/freezedeposit';
    const configRequest = {
      userId,
      method: 'POST',
      data,
      path
    };
    return UtilsHelper.sendRequest(configRequest);
  } catch (error) {
    throw error;
  }
};

export const submitComment = async (userId, data) => {
  try {
    const path = '/api/depositTickets/comment';
    const configRequest = {
      userId,
      method: 'post',
      path,
      data: data
    };
    return UtilsHelper.sendRequest(configRequest);
  } catch (error) {
    throw error;
  }
};
