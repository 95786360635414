import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ResizableColumns from "../../../../components/FallComponents/ResizableColumns/ResizableColumns"
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable'
import { useFilter } from '../../../../hooks/useFilter';
import isEmpty from 'is-empty'
import AllTradeReportPage from '../../../../components/FallComponents/TradeReportPage/AllTradeReportPage'
import TradeReportController from '../../../FallControllers/TradeReportController'
import TradeReportDetails from '../../../../components/FallComponents/TradeReportPage/TradeReportDetails/TradeReportDetails'
import closeIcon from '../../../../images/new_icons/close-circle.svg'

const TradeReportPage = (props, context) => {

    const { userInfo } = props;

    const [changeLimit, setChangeLimit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [withRightSection, setWithRightSection] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [rows, setRows] = useState([]);
    const [tradeId, setTradeId] = useState('');

    const {
        filterConfig,
        filterSearch,
        offset,
        showingTo,
        changePage,
        onChangeLimit,
        resetFilters,
        setFilterSearch
    } = useFilter({ totalRows });

    const handleSelectRow = ({ row }) => {
        setWithRightSection(true);
        setTradeId(row.id)
    };

    const handleTradeInfo = () => (rows.find(element => element.id === tradeId) || {});
    const tradeInfo = handleTradeInfo();

    const initialConfig = () => {
        resetFilters(20)
        setChangeLimit(true);
    }

    const handleLoadTradesReport = () => {
        TradeReportController.handleAllTradesReport({
            userInfo,
            filterConfig,
            filterSearch,
            setLoading,
            setRows,
            setTotalRows
        })
    }

    const handleDownloadTradesReport = () => {
        TradeReportController.handleDownloadAllTradesReport({
            userInfo
        })
    }

    const headerOptions = [
        {
            image: closeIcon,
            alt: 'Close option',
            action: () => { setWithRightSection(false); }
        }
    ];

    useEffect(() => {
        if (!changeLimit) initialConfig();
        if (!isEmpty(userInfo) && changeLimit) handleLoadTradesReport();
    }, [userInfo, filterConfig, filterSearch])

    return (
        <div className="beta-content-container">
            <ResizableColumns
                withRightSection={withRightSection}
                minWidth='500px'
                childrenOne={
                    <AllTradeReportPage
                        loading={loading}
                        setFilterSearch={setFilterSearch}
                        onChangeLimit={onChangeLimit}
                        filterConfig={filterConfig}
                        totalRows={totalRows}
                        offset={offset}
                        showingTo={showingTo}
                        changePage={changePage}
                        rows={rows}
                        withRightSection={withRightSection}
                        initialConfig={initialConfig}
                        handleSelectRow={handleSelectRow}
                        tradeId={tradeId}
                        handleDownloadTradesReport={handleDownloadTradesReport}
                    />
                }
                childrenTwo={
                    <RightResizable
                        headerOptions={headerOptions}
                        loadingData={{
                            title: 'Cargando información',
                            isLoading: false
                        }}
                    >
                        <TradeReportDetails tradeInfo={tradeInfo} />
                    </RightResizable>
                }
            />
        </div>
    )
};

const mapStateToProps = ({ user: { userInfo } }) => {
    return { userInfo };
};

TradeReportPage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(TradeReportPage)