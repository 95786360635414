import isEmpty from 'is-empty';
import React, { useState } from 'react';
import { reviewStatus } from '../../../../constants';
import statusComponent from '../../StatusComponent/StatusComponent';
import { TabsContainer } from '../../Tabs/TabsContainer';
import History from './Tabs/History';
import './legalDetails.css'
import UserLegal from './Tabs/UserLegal';
import Shareholder from './Tabs/Shareholder';
import Comments from '../../CommentsTab';
import { UserBlocked } from './Tabs/UserBlocked';

const LegalDetails = (props) => {
   const {
      levelBasicInfo,
      shareholders,
      logUser,
      countries,
      handleIncrementLegalUserById,
      handleRejecLegalUserById,
      handleReturnCorporateInfoFields,
      handleUpdateCorporateInfo,
      handleReturnCorporateShareholderInfoFields,
      handleUpdateCorporateShareholderInfo,
      confirmSendToIDM,
      handleImagesToZoom,
      cropDataReniec,
      userInfo: newUserInfo,
      context,
      handleLoadLegalUserInfoById,
      handleLoadLegals,
      row
   } = props;
   const [tabSelect, setTabSelect] = useState('Cliente');
   const tabsOptions = [
      {
         title: 'Cliente',
         component: <UserLegal
            levelBasicInfo={levelBasicInfo}
            countries={countries}
            handleIncrementLegalUserById={handleIncrementLegalUserById}
            handleRejecLegalUserById={handleRejecLegalUserById}
            handleReturnCorporateInfoFields={handleReturnCorporateInfoFields}
            handleUpdateCorporateInfo={handleUpdateCorporateInfo}
         />,
         default: true
      },
      {
         title: 'Shareholders',
         component: <Shareholder
            shareholders={shareholders}
            countries={countries}
            handleReturnCorporateShareholderInfoFields={handleReturnCorporateShareholderInfoFields}
            handleUpdateCorporateShareholderInfo={handleUpdateCorporateShareholderInfo}
            confirmSendToIDM={confirmSendToIDM}
            handleImagesToZoom={handleImagesToZoom}
            cropDataReniec={cropDataReniec}
         />,
         default: false
      },
      {
         title:'Bloqueo de cliente',
         component: <UserBlocked row={row} levelBasicInfo={levelBasicInfo} userInfo={newUserInfo} getUserDataByIdWithLogs={handleLoadLegalUserInfoById} editPermission={true} handleLoadLegals={handleLoadLegals}/>,
         default:false
      },
      {
         title: 'Comentarios',
         component: <Comments context={context} levelId={levelBasicInfo.id} userInfo={newUserInfo} type="corporate"/>,
         default: false
      },
      {
         title: 'Historial',
         component: <History logUser={logUser} />,
         default: false
      }
   ];
   const handleUserInfo = () => {
      const companyLegalName = (JSON.parse(levelBasicInfo.dataForm)).company_legal_name;
      return {
         companyLegalName: !isEmpty(companyLegalName) ? companyLegalName : '',
         userId: levelBasicInfo.banexcoinUserid,
         username: levelBasicInfo.banexcoinUsername,
         level: levelBasicInfo.userLevel,
         status: statusComponent(levelBasicInfo.reviewStatus, true, reviewStatus)
      }
   }

   const userInfo = handleUserInfo();

   return (
      <div className='right-resizable-user-info'>
         <HeaderSection userInfo={userInfo} />
         <div className="container-tabs">
            <TabsContainer
               tabsOptions={tabsOptions}
               tabSelect={tabSelect}
               setTabSelect={setTabSelect}
            />
         </div>
      </div>
   )
}

export default LegalDetails

const HeaderSection = ({ userInfo }) => {
   const { companyLegalName, userId, username, level, status } = userInfo;
   return (
      <div className='header-section-legal'>
         <h2>{companyLegalName}</h2>
         <div className="info">
            <div>
               <div className="option">
                  <p>ID de usuario:</p>
                  <p>{userId}</p>
               </div>
               <div className="option">
                  <p>Usuario:</p>
                  <p>{username}</p>
               </div>
               <div className="option">
                  <p>Nivel:</p>
                  <p>{level}</p>
               </div>
            </div>
            <div>
               <div className="option">
                  <p>Estado:</p>
                  <p>{status}</p>
               </div>
            </div>
         </div>
      </div>
   )
}