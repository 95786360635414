import React, { useState }  from 'react'
import { userRolesById, userTypes } from '../../../../../constants'
import { CustomButton } from '../../../CustomButton/CustomButton'
import { EditableText } from '../../../EditableText/EditableText';
import editIcon from "../../../../../images/new_icons/edit.svg";
import checkIcon from "../../../../../images/new_icons/check.svg";
import crossIcon from "../../../../../images/new_icons/cross.svg";

export const MainDetails = ({data,handleFreeUserSms,loading,updateUserType,updateUser}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isEditable2, setIsEditable2] = useState(false);
  const [userType,setUseType] = useState(data.usertype)
  const [fields,setFields] = useState(data.email)
  const handleIsEditable = () => setIsEditable(!isEditable);
  const sms = !!data.smsProcessingData;
  const email = !!data.emailProcessingData;
  const [checkSms, setCheckSms] = useState(sms);
  const [checkEmail, setCheckEmail] = useState(email);

  const fieldsEdit = {
    email: fields,
    smsProcessingData: checkSms,
    emailProcessingData: checkEmail,
  };

  const handleResetValues = () => {
    setUseType(data.usertype)
    handleIsEditable();
  };

  const handleResetInputs= () => {
    setFields(data.email)
    setCheckSms(sms)
    setCheckEmail(email)
  };
  
  const handleCloseEdition = async () => {
    handleIsEditable();
    if (isEditable) {
      updateUserType(userType,handleResetValues)
    }      
  };

  const handleCloseEdition2 = async () => {
    setIsEditable2(isEditable2 => !isEditable2)
    if (isEditable2 && (fields !== data.email || checkSms !== sms || checkEmail !== email)) {
      const newData = {}
      Object.keys(fieldsEdit).forEach((key) => {
          if(fieldsEdit[key] !== data[key]){
            newData[key] = fieldsEdit[key]
          }
      });
      updateUser(newData, handleResetInputs)
    }      
  };

    const OptionDetail = ({ title, value }) => {
        return (
          <div className="option">
            <p>
              <b>{title}</b>
            </p>
            <p>{value || '-'}</p>
          </div>
        )
      };

    return (
      <div className="content-tab user-permits-body">
        <div className="content-tab-container">
        <div  className='flex items-center gap-1'>
          <h2 className="subtitle-first-tab">Detalles de usuario</h2>
          <img
              onClick={handleCloseEdition2}
              src={isEditable2 ? checkIcon : editIcon}
            />
            {isEditable2 && <img onClick={()=>{setIsEditable2(isEditable2 => !isEditable2); handleResetInputs()}} src={crossIcon} />}
          </div>
          <OptionDetail title="Nombre de usuario" value={data.banexcoinUsername} />
          <EditableText
            type="email"
            editable={isEditable2}
            option="Correo electrónico"
            name="email"
            value={fields}
            onChange={(target) => setFields(target.value)}
            rootClassname="max-w-250"
          />
          <OptionDetail title="Rol" value={userRolesById[data.roleId]} />
          <OptionDetail title="Acceso admin" value={data.roleAdminAccess ? "Si" : "No"} />
          <div className="option">
            <p><b>Campañas comerciales</b></p>
            <div className='check-detail'>
              <div>
                {isEditable2 ? <input type="checkbox" checked={checkSms} onChange={(e)=>setCheckSms(e.target.checked)}/> : checkSms ? `✔️` : `❌`}
                <p>Mensaje de texto</p>
              </div>
              <div>
                {isEditable2 ? <input type="checkbox" checked={checkEmail} onChange={(e)=>setCheckEmail(e.target.checked)}/> : checkEmail ? `✔️` : `❌`}
                <p>Correo electrónico</p>
              </div>
            </div>
          </div>
          <div  className='flex items-center gap-1'>
          <h2 className="subtitle-first-tab">Detalles de tipo de usuario</h2>
          <img
              onClick={handleCloseEdition}
              src={isEditable ? checkIcon : editIcon}
            />
            {isEditable && <img onClick={handleResetValues} src={crossIcon} />}
          </div>
          <EditableText
            type="select"
            editable={isEditable}
            option="Tipo de usuario"
            name="userType"
            value={userType}
            onChange={(target) => setUseType(target.value)}
            options={userTypes}
            rootClassname="max-w-250"
          />
          <br/>
          <h2 className="subtitle-first-tab">Detalles de teléfono</h2>
          <OptionDetail title="Teléfono" value={data.phone}/>
          <OptionDetail title="Teléfono auxiliar" value={data.phoneAux}/>
          <div className="option">
            <p>
              <b>¿Verificado?</b>
            </p>
            <p className={data.phoneVerified ? "text-green" : "text-red"}>{data.phoneVerified ? "Si" : "No"}</p>
          </div>
        </div>
        <div className="buttons-container">
            <div className="buttons-left">
              <CustomButton text="Liberar SMS" disabled={loading} showLoader={true} onClick={handleFreeUserSms} className="green" customClassName="free-sms-button" />
            </div>
          </div>
      </div>
    )
  }
