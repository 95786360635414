import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CustomButton } from "../CustomButton/CustomButton";
import InputElement from "../InputElement/InputElement";
import "./modal.css";

const AddModal = (props, context) => {
  const { showModal, handleToggleAddModal, loading, addDomain, setErrors, errors } =
    props;

  const [domain, setDomain] = useState("")

  const buttonProps = {
    text: "Añadir dominio",
    customClassName: "add-limit-button",
    className: "green",
  };

  const onSubmit = () => {
    if (isEmpty(domain)) {
      setErrors("Campo requerido")
      return
    }
    addDomain(domain)
  }
  const inputProps = {
    values: {
      title: "Dominio",
      value: domain,
      name: "domain",
      required: true,
    },
    actions: {
      onChange: (target) => {
        setErrors("")
        setDomain(target.value)
      },
    },
    error: errors.domain
  };

  return (
    <Modal
      className="add-domain-modal-container"
      isOpen={showModal}
    >
      <ModalHeader charCode="X" toggle={handleToggleAddModal}>
        Añadir nuevo dominio
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="add-modal-inputs">
            <InputElement {...inputProps} />
          </div>
          <div className="container-submit-cancel-buttons">
            <CustomButton
              onClick={handleToggleAddModal}
              className="red"
              text="Cancelar"
              disabled={loading}
              showLoader={false}
            />
            <CustomButton
              {...buttonProps}
              onClick={onSubmit}
              disabled={loading}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AddModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AddModal);
