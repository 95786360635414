import React from "react";
import coinklogo from "../images/new_icons/coinklogo.svg";
import banexlogo from "../images/new_icons/banexlogo.svg";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Clipboard from "../components/FallComponents/Clipboard/Clipboard";

export default class ComponentsHelper {
  static providerImage = (level) => {
    const selectLogo = level === 101 || level === 100 ? coinklogo : banexlogo;
    return <img src={selectLogo} alt="" width="25px" />;
  };

  static toast = ({ type = "success", message = "" }) => {
    switch (type) {
      case "success":
        toast.success(message, { position: toast.POSITION.TOP_CENTER });
        break;
      case "error":
        toast.error(message, { position: toast.POSITION.TOP_CENTER });
        break;
      case "warn":
        toast.warn(message, { position: toast.POSITION.TOP_CENTER });
        break;
      default:
        toast.info(message, { position: toast.POSITION.TOP_CENTER });
        break;
    }
  };

  static confirmAlert = ({
    customUI,
    title,
    message,
    buttons,
    ...otherProps
  }) => {
    if (!customUI) {
      return confirmAlert({
        title,
        message,
        buttons,
        ...otherProps,
      });
    }
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-alert-admin-v2">
            <h1>{title}</h1>
            <p>{message}</p>
            {buttons.map((button) => {
              return (
                <button
                  onClick={async () => {
                    if (button.onClick) {
                      await button.onClick();
                    }
                    onClose();
                  }}
                >
                  {button.label}
                </button>
              );
            })}
          </div>
        );
      },
    });
  };

  static clipboardGenerator = (id, name) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="button-tooltip">Copiar</Tooltip>}
      >
        <div>
          <Clipboard
            onClickClipboard={() => {
              toast.success(`${name} ${id} copiado al portapapeles`, {
                position: toast.POSITION.TOP_CENTER,
              });
            }}
            textToCopy={id}
          />
        </div>
      </OverlayTrigger>
    );
  };
}
