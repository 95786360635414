import React, { useState } from 'react'
import isEmpty from 'is-empty';
import HeaderPage from '../HeaderPage/HeaderPage'
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import Download from '../../../images/new_icons/download.svg';
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import TableContainer from '../Table/TableContainer';
import { ACCOUNTS_STATUS, ACCOUNTS_CURRENCY } from '../../../constants'
import { useForm } from '../../../hooks/formHooks';
import { GroupFilters } from '../Filters/Filters';
import DataFormatterHelper from '../../../helpers/DataFormatter.helper'
import DateHelper from '../../../helpers/Date.helper'

const AllBankAccountData = (props) => {

   const {
      setFilterSearch,
      handleInitialConfig,
      withRightSection,
      handleDownloadRows,
      handleSelectRow,
      onChangeLimit,
      filterConfig,
      totalRows,
      rows,
      loading,
      offset,
      showingTo,
      changePage,
      selectedId = ''
   } = props;

   const filterInitialValues = {
      approvedByBanexcoinUsername: '',
      banexcoinUserAccountId: '',
      banexcoinUserid: '',
      banexcoinUsername: '',
      beneficiaryBankname: '',
      countryName: '',
      currencyCode: '',
      rejectedByBanexcoinUsername: '',
      status: ''
   };

   const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(filterInitialValues);

   const [showFilters, setShowFilters] = useState(false);
   const [filters, setFilters] = useState({
      status: { in: 0 }
   });

   const handleSearchFilters = () => {
      const newFilters = DataFormatterHelper.cleanJSONFilters(filters);
      Object.keys(newFilters).forEach(element => {
         newFilters[element] === '' && delete newFilters[element];
      });
      setFilterSearch(newFilters);
   };

   const handleShowFilters = () => {
      setShowFilters(!showFilters);
   };

   const handleResetFilters = () => {
      setInputs(filterInitialValues);
      setFilters({ status: { in: 0 } })
      handleInitialConfig();
   };

   const handleGetStatusName = (id) => {
      let name = id;
      const accountStatus = ACCOUNTS_STATUS.find(l => l.value === id);
      if (!isEmpty(accountStatus)) {
         name = accountStatus.name;
      };
      return name;
   }

   const handleGetFullName = (data) => {
      let fullName = `${data.firstName} ${data.middleName} ${data.lastName} ${data.secondLastName}`;
      if (!isEmpty(data.levelCorporate)) {
         const levelCorporate = JSON.parse(data.levelCorporate);
         const { company_legal_name, legal_entity_type } = levelCorporate;
         fullName = `${company_legal_name}, ${legal_entity_type}`;
      }
      return !isEmpty(fullName.replaceAll(' ', '')) ? fullName : '-';
   }

   const formatFilterValue = (e) => {
      const { name, value, type } = e;
      let newValue = '';
      if (!isEmpty(value) || value === 0) {
         if (name === 'currencyCode') {
            newValue = { in: String(value) };
         }
         else if (type === 'select-one' || type === 'number') {
            newValue = { in: Number(value) };
         }
         else {
            newValue = { includesInsensitive: String(value) };
         }
      }
      return newValue;
   };

   const handleOnChange = (e) => {
      onInputChangeV2(e);
      setFilters({
         ...filters,
         [e.name]: formatFilterValue(e)
      });
   }

   const headerOptions = [
      {
         icon: showFilters ? SettingLight : Setting,
         action: handleShowFilters,
         className: showFilters ? 'header-option-active' : ''
      },
      {
         icon: Download,
         action: handleDownloadRows
      },
      {
         icon: RotateRight,
         action: handleResetFilters
      }
   ];

   const inputs = [
      {
         values: {
            title: 'Estado',
            name: 'status',
            value: inputsFilters.status,
            type: 'select',
            options: ACCOUNTS_STATUS,
            placeholder: 'Select a status'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'Account ID',
            name: 'banexcoinUserAccountId',
            value: inputsFilters.banexcoinUserAccountId,
            type: 'number'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'User ID',
            name: 'banexcoinUserid',
            value: inputsFilters.banexcoinUserid,
            type: 'number'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'Nombre de usuario',
            name: 'banexcoinUsername',
            value: inputsFilters.banexcoinUsername,
            type: 'text'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'Moneda',
            name: 'currencyCode',
            value: inputsFilters.currencyCode,
            type: 'select',
            options: ACCOUNTS_CURRENCY,
            placeholder: 'Select a currency'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'Banco',
            name: 'beneficiaryBankname',
            value: inputsFilters.beneficiaryBankname,
            type: 'text'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'País',
            name: 'countryName',
            value: inputsFilters.countryName,
            type: 'text'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'Aprobado por',
            name: 'approvedByBanexcoinUsername',
            value: inputsFilters.approvedByBanexcoinUsername,
            type: 'text'
         },
         actions: {
            onChange: handleOnChange
         }
      },
      {
         values: {
            title: 'Rechazado por',
            name: 'rejectedByBanexcoinUsername',
            value: inputsFilters.rejectedByBanexcoinUsername,
            type: 'text'
         },
         actions: {
            onChange: handleOnChange
         }
      }
   ];

   const tableHeaders = [
      {
         tag: '',
         title: 'Register date',
         cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
         handleSelectRow
      },
      {
         tag: '',
         title: 'Estado',
         cell: ({ data }) => <p>{handleGetStatusName(data.status)}</p>,
         handleSelectRow
      },
      {
         tag: 'banexcoinUserAccountId',
         title: 'Account ID',
         handleSelectRow
      },
      {
         tag: 'banexcoinUserid',
         title: 'User ID',
         handleSelectRow
      },
      {
         tag: 'banexcoinUsername',
         title: 'Nombre de usuario',
         handleSelectRow
      },
      {
         tag: 'currencyCode',
         title: 'Moneda',
         handleSelectRow
      },
      {
         tag: '',
         title: 'Nombre de cliente',
         cell: ({ data }) => <p>{handleGetFullName(data)}</p>,
         handleSelectRow
      },
      {
         tag: '',
         title: 'Banco',
         cell: ({ data }) => <p className='text-ellipsis w-200' title={data.beneficiaryBankname}>
            {!isEmpty(data.beneficiaryBankname) ? data.beneficiaryBankname : '-'}
         </p>,
         handleSelectRow
      },
      {
         tag: 'accountNumber',
         title: "Número de cuenta",
         handleSelectRow
      },
      {
         tag: 'countryName',
         title: 'País',
         handleSelectRow
      },
      {
         tag: 'approvedByBanexcoinUsername',
         title: 'Aprobado por',
         handleSelectRow
      },
      {
         tag: '',
         title: 'Fecha de aprobación',
         cell: ({ data }) => <p>{!isEmpty(data.approvedAt) ? DateHelper.formatDate(data.approvedAt) : '-'}</p>,
         handleSelectRow
      },
      {
         tag: 'rejectedByBanexcoinUsername',
         title: 'Rechazado por',
         handleSelectRow
      },
      {
         tag: '',
         title: 'Fecha de rechazo',
         cell: ({ data }) => <p>{!isEmpty(data.rejectedAt) ? DateHelper.formatDate(data.rejectedAt) : '-'}</p>,
         handleSelectRow
      },
      {
         tag: '',
         title: 'Fecha de eliminación',
         cell: ({ data }) => <p>{!isEmpty(data.removedBy) ? DateHelper.formatDate(data.removedBy) : '-'}</p>,
         handleSelectRow
      },
   ]

   return (
      <div className="all-page-data">
         <HeaderPage
            title="Cuentas bancarias"
            subTitle="Clientes / Cuentas bancarias"
            headerOptions={headerOptions}
         />
         <TableContainer
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            headers={tableHeaders}
            rowIdSelected={selectedId}>
            {showFilters && (
               <GroupFilters
                  handleSearchFilters={handleSearchFilters}
                  withRightSection={withRightSection}
                  inputs={inputs}
               />
            )}
         </TableContainer>
      </div>
   )
}

export default AllBankAccountData