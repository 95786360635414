import isEmpty from "is-empty";
import ComponentsHelper from "../../helpers/Components.helper";
import { getAllAvailableKpiReports } from "../FallServices/KpiServices";

class KpiReportsController {
  static getKpiReport = async ({
    setLoading,
    setAllKpis,
    userInfo,
    setSelectedKpi,
  }) => {
    try {
      setLoading(true);
      const result = await getAllAvailableKpiReports(userInfo.UserId);
      if (isEmpty(result.data)) {
        return;
      }
      setAllKpis(result.data);
      if (!isEmpty(result.data.data)) {
        const firstKpi = result.data.data[0]
        setSelectedKpi({
          id: firstKpi.id,
          embedUrl: firstKpi.embedUrl,
          title:firstKpi.title
        });
      }
    } catch (error) {
      ComponentsHelper.toast({
        type: "warn",
        message: "No se pudo cargar los reportes",
      });
    } finally {
      setLoading(false);
    }
  };
}

export default KpiReportsController;
