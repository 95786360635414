import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TabsContainer } from '../../Tabs/TabsContainer';
import './depositDetails.css';
import isEmpty from 'is-empty';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import BoxLimitsContainer from '../../../FallComponents/BoxLimits/BoxLimitsContainer';
import DepositController from '../../../../pages/FallControllers/DepositController';
import {
  currencyAssetNames,
  banksAccountBanexcoin
} from '../../../../constants';
import UtilsHelper from '../../../../helpers/Utils.helper';
import Comments from './Tabs/Comments';
import User from './Tabs/User';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import { useModal } from '../../../../hooks/useModal';

const DepositDetails = (props, context) => {
  const {
    userInfo,
    ticketInfo,
    levelInfo,
    levelCorporate,
    depositInfo,
    getTicketByIdInit,
    limits,
    commentInfo,
    handleImagesToZoom,
    editPermission,
    getAllDeposits
  } = props;

  const [eventFindTransaction, setEventFindTransaction] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [comment, setComment] = useState('');
  const [fullName, setFullName] = useState('');
  const [transactionsByCurrency, setTransactionsByCurrency] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Usuario');
  const [action, setAction] = useState({
    title:"",
    message:"",
    function: () => {}
  })
  const { showModal: showActionModal, handleToggleModal: handleToggleActionModal } = useModal();


  const getCurrencyByAssetName = assetName => {
    const currency = currencyAssetNames.find(a => a.assetName === assetName);
    return !isEmpty(currency) ? currency.currency : '';
  };

  const getCurrencyByBankAccount = bankAccount => {
    const findAccount = banksAccountBanexcoin.find(
      b => b.bankAccount === bankAccount
    );
    return !isEmpty(findAccount) ? findAccount.currency : '-';
  };

  const handleFindTransaction = () => {
    DepositController.findTransaction({
      userInfo,
      ticketInfo,
      setEventFindTransaction,
      setTransactions,
      context
    });
  };

  const handleAcceptDepositByAdmin = async () => {
    await DepositController.acceptDepositByAdmin({
      userInfo,
      ticketInfo,
      getTicketByIdInit,
      context
    });
    getAllDeposits()
  };
  
  const handleRejectDepositByAdmin = async () => {
    await DepositController.rejectDepositByAdmin({
      userInfo,
      ticketInfo,
      getTicketByIdInit,
      context
    });
    getAllDeposits()
  };

  const handleProcessTransaction = async id => {
    await DepositController.processTransaction({
      ticketInfo,
      userInfo,
      transactionId: id,
      getTicketByIdInit,
      setEventFindTransaction,
      setTransactions,
      context
    });
  };

  const onSubmitCallback = e => {
    e.preventDefault();
    DepositController.sendComment({
      userInfo,
      comment,
      ticketInfo,
      getTicketByIdInit,
      context
    });
  };

  const confirmSubmit = () => {
    setAction({
      title:"Aprobar solicitud",
      message:"¿Está seguro de que deseas aprobar la solicitud? Al aprobar la solicitud el cliente recibirá el saldo en su cuenta.",
      function: handleAcceptDepositByAdmin
    })
    handleToggleActionModal()
  };

  const rejectedSubmit = () => {
    setAction({
      title:"Rechazar solicitud",
      message:"¿Está seguro de que deseas rechazar la solicitud? Al rechazar la solicitud el cliente no recibirá el saldo en su cuenta.",
      function: handleRejectDepositByAdmin
    })
    handleToggleActionModal()
  };

  const confirmBankTransactionSubmit = id => {
    setAction({
      title:"Aprobar solicitud",
      message:"¿Está seguro de que deseas aprobar la solicitud? Al aprobar la solicitud el cliente recibirá el saldo en su cuenta.",
      function: async () => await handleProcessTransaction(id)
    })
    handleToggleActionModal()
  };

  const buildFullname = () => {
    let tempFullName = '';
    if (!isEmpty(levelCorporate)) {
      tempFullName = UtilsHelper.validateParam(levelCorporate.companyLegalName);
    } else {
      tempFullName = `${UtilsHelper.validateParam(levelInfo.lastName)} ${UtilsHelper.validateParam(
        levelInfo.secondLastName
      )}, ${UtilsHelper.validateParam(levelInfo.firstName)} ${UtilsHelper.validateParam(
        levelInfo.middleName
      )}`;
      if (
        isEmpty(tempFullName.replace(',', '').trim()) &&
        !isEmpty(levelInfo.registerFields)
      ) {
        tempFullName = `${UtilsHelper.validateParam(
          levelInfo.registerFields.dataApellidos
        )}, ${UtilsHelper.validateParam(levelInfo.registerFields.dataNombres)}`;
      }
    }
    setFullName(tempFullName.toUpperCase());
  };

  const tabsOptions = [
    {
      title: 'Usuario',
      component: (
        <User
          ticketInfo={ticketInfo}
          depositInfo={depositInfo}
          levelCorporate={levelCorporate}
          confirmSubmit={confirmSubmit}
          rejectedSubmit={rejectedSubmit}
          handleFindTransaction={handleFindTransaction}
          transactions={transactions}
          eventFindTransaction={eventFindTransaction}
          transactionsByCurrency={transactionsByCurrency}
          confirmBankTransactionSubmit={confirmBankTransactionSubmit}
          getCurrencyByBankAccount={getCurrencyByBankAccount}
          handleImagesToZoom={handleImagesToZoom}
          setEventFindTransaction={setEventFindTransaction}
          editPermission={editPermission}
        />
      ),
      default: true
    },
    {
      title: 'Límites',
      component: <BoxLimitsContainer limits={limits} type="deposit" />,
      default: false
    },
    {
      title: 'Comentarios',
      component: (
        <Comments
          commentInfo={commentInfo}
          onSubmitCallback={onSubmitCallback}
          comment={comment}
          setComment={setComment}
          editPermission={editPermission}
        />
      ),
      default: false
    }
  ];

  const HeaderSection = () => {
    return (
      <div className="header-section">
        <h2>Ticket de Depósito</h2>
        <h2>{fullName}</h2>
        {ticketInfo.Status !== 'Rejected' && (
          <p>
            Estado:
            <br />
            <strong
              className={
                ticketInfo.Claim ? 'status-success-dep' : 'status-warning-dep'
              }>
              {ticketInfo.Claim ? 'Claim' : 'Pending Claim'}
            </strong>
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    buildFullname();
  }, []);

  useEffect(
    () => {
      if (!isEmpty(transactions)) {
        const depositCurrency = getCurrencyByAssetName(ticketInfo.AssetName);
        const newTransactionsByCurrency = transactions.filter(t => depositCurrency === getCurrencyByBankAccount(t.sourceBankAccountNumber));
        setTransactionsByCurrency(newTransactionsByCurrency);
      }
    },
    [transactions]
  );

  return (
    <React.Fragment>
      <div className="deposit-detail-main">
        <HeaderSection />
        <div className="deposit-body-container">
          <TabsContainer
            tabsOptions={tabsOptions}
            tabSelect={selectedTab}
            setTabSelect={setSelectedTab}
          />
        </div>
      </div>
      <ConfirmModal
        showModal={showActionModal}
        handleToggleModal={() => {
          handleToggleActionModal();
        }}
        title={action.title}
        message={action.message}
        confirmButtonProps={{
          onClick: action.function,
          text: "Si",
        }}
        cancelButtonProps={{
          text: "No",
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, instruments, level1, userInfo, accounts };
};

DepositDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(DepositDetails);
