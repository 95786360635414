import React, { useState } from 'react';
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import Download from '../../../images/new_icons/download.svg';
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import HeaderPage from '../HeaderPage/HeaderPage';
import TableContainer from '../Table/TableContainer';
import { GroupFilters } from '../Filters/Filters';
import { legalLevelOptions, reviewStatus } from '../../../constants';
import isEmpty from 'is-empty';
import DateHelper from '../../../helpers/Date.helper';
import { useForm } from '../../../hooks/formHooks';
import DataFormatterHelper from '../../../helpers/DataFormatter.helper';
import ComponentsHelper from '../../../helpers/Components.helper';
import statusComponent from '../StatusComponent/StatusComponent';

const AllLegalData = (props) => {

    const {
        withRightSection,
        onChangeLimit,
        filterConfig,
        setFilterSearch,
        initialConfig,
        handleDownloadLegals,
        handleSelectRow,
        totalRows,
        rows,
        loading,
        offset,
        showingTo,
        changePage,
        selectedId = ''
    } = props;

    const filterInitialValues = {
        banexcoinUserAccountId: '',
        userLevel: '',
        banexcoinUsername: '',
        bnxEmail: '',
        companyLegalName: '',
        taxpayerIdentificationNumber: '',
        reviewStatus: ''
    };

    const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(filterInitialValues);

    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});

    const handleShowFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleResetFilters = () => {
        setInputs(filterInitialValues);
        setFilters({})
        initialConfig();
    };

    const generateSelector = (id) => {
        return (
            <label>
                <input
                    className="input-check-select-hy"
                    type="checkbox"
                    name={id}
                    hidden
                />
                <div className={`input-radio`} />
            </label>
        );
    };

    const handleSearchFilters = () => {
        const newFilters = DataFormatterHelper.cleanJSONFilters(filters);
        Object.keys(newFilters).forEach(element => {
            newFilters[element] === '' && delete newFilters[element];
        });
        setFilterSearch(newFilters)
    };

    const formatFilterValue = (e) => {
        const { name, value, type } = e;
        let newValue = '';
        if (!isEmpty(value) || value === 0) {
            if (name === 'reviewStatus') {
                newValue = { includesInsensitive: String(value) };
            }
            else if (type === 'select-one' || type === 'number') {
                newValue = { in: Number(value) };
            }
            else {
                newValue = { includesInsensitive: String(value) };
            }
        }
        return newValue;
    };

    const handleOnChange = (e) => {
        onInputChangeV2(e);
        setFilters({
            ...filters,
            [e.name]: formatFilterValue(e)
        });
    }

    const headerOptions = [
        {
            icon: showFilters ? SettingLight : Setting,
            action: handleShowFilters,
            className: showFilters ? 'header-option-active' : ''
        },
        {
            icon: Download,
            action: handleDownloadLegals
        },
        {
            icon: RotateRight,
            action: handleResetFilters
        }
    ];

    const inputs = [
        {
            values: {
                title: 'ID de cuenta',
                name: 'banexcoinUserAccountId',
                value: inputsFilters.banexcoinUserAccountId,
                type: 'number',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Nivel de usuario',
                name: 'userLevel',
                value: inputsFilters.userLevel,
                type: 'select',
                options: legalLevelOptions,
                placeholder: 'Seleccione un nivel',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Nombre de usuario',
                name: 'banexcoinUsername',
                value: inputsFilters.banexcoinUsername,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Correo electrónico',
                name: 'bnxEmail',
                value: inputsFilters.bnxEmail,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Nombre legal o razón social',
                name: 'companyLegalName',
                value: inputsFilters.companyLegalName,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Número de identificación fiscal',
                name: 'taxpayerIdentificationNumber',
                value: inputsFilters.taxpayerIdentificationNumber,
                type: 'text',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        },
        {
            values: {
                title: 'Estado de revisión',
                name: 'reviewStatus',
                value: inputsFilters.reviewStatus,
                type: 'select',
                options: reviewStatus,
                placeholder: 'Seleccione un estado',
                required: true
            },
            actions: {
                onChange: handleOnChange
            }
        }
    ];

    const tableHeaders = [
        {
            tag: '',
            title: '',
            cell: ({ data }) => ComponentsHelper.clipboardGenerator(data.id, "Id"),
            className: 'minimizer no-Selection'
        },
        {
            tag: '',
            title: '',
            cell: ({ data }) => generateSelector(data.id),
            className: 'minimizer no-Selection'
        },
        {
            tag: 'banexcoinUserAccountId',
            title: 'ID de cuenta',
            handleSelectRow
        },
        {
            tag: 'banexcoinUsername',
            title: 'Nombre de usuario',
            handleSelectRow
        },
        {
            tag: 'bnxEmail',
            title: 'Correo electrónico',
            handleSelectRow
        },
        {
            tag: 'companyLegalName',
            title: 'Nombre legal o razón social',
            handleSelectRow
        },
        {
            tag: 'taxpayerIdentificationNumber',
            title: 'Número de identificación fiscal',
            handleSelectRow
        },
        {
            tag: '',
            title: 'Fecha de creación',
            cell: ({ data }) => <p>{DateHelper.formatDate(data.createdAt)}</p>,
            handleSelectRow
        },
        {
            tag: '',
            title: 'Nivel',
            cell: ({ data }) => <p>{data.userLevel}</p>,
            handleSelectRow
        },
        {
            tag: '',
            title: 'Bloqueado',
            cell: ({ data }) => <p>{data.userLockStatus ? "Si" : "-"}</p>,
            className: 'minimizer',
            handleSelectRow
          },
        {
            tag: '',
            title: 'Estado',
            cell: ({ data }) => statusComponent(data.reviewStatus, false, reviewStatus),
            handleSelectRow
        },
    ];

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Clientes Jurídicos"
                subTitle="Clientes / Jurídicos"
                headerOptions={headerOptions}
            />
            <TableContainer
                onChangeLimit={onChangeLimit}
                filterConfig={filterConfig}
                totalRows={totalRows}
                rows={rows}
                loading={loading}
                offset={offset}
                showingTo={showingTo}
                changePage={changePage}
                headers={tableHeaders}
                rowIdSelected={selectedId}
            >
                {
                    showFilters &&
                    <GroupFilters
                        handleSearchFilters={handleSearchFilters}
                        withRightSection={withRightSection}
                        inputs={inputs}
                    />
                }
            </TableContainer>
        </div>
    )
}

export default AllLegalData;