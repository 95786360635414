import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  createNewProvider,
  deleteProvider,
  getAllProviders,
  getProviderByRuc,
  updateProvider,
} from "../FallServices/ProvidersServices";

class ProvidersController {
  static getAllProviders = async ({
    userInfo,
    setLoading,
    setRows,
    setTotalRows,
  }) => {
    try {
      setLoading(true);
      const response = await getAllProviders(userInfo.UserId);
      const providersSortByUpdatedAt = !isEmpty(response.data)
          ? response.data.sort(
              (a, b) =>
                new Date(b.updatedAt) -
                new Date(a.updatedAt)
            )
          : [];
      setRows(providersSortByUpdatedAt);
      setTotalRows(response.data.length)
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible.",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static addNewProvider = async ({
    userInfo,
    data,
    setLoading,
    setFetchError,
  }) => {
    try {
      setLoading(true);
      const response = await createNewProvider(userInfo.UserId, data);
      if (response.data && response.data.id) {
        return true;
      }
      return false;
    } catch (error) {
      if (
        !isEmpty(error.response) &&
        !isEmpty(error.response.data) &&
        !isEmpty(error.response.data.message)
      ) {
        setFetchError({
          error: "Estos datos ya fueron añadidos en la lista de proveedores.",
        });
      }
      return false;
    } finally {
      setLoading(false);
    }
  };
  static updateProvider = async ({ userInfo, data, id }) => {
    try {
      ComponentsHelper.toast({
        type: toastTypes.info,
        message: "Actualizando proveedor",
      });
      const response = await updateProvider(userInfo.UserId, data, id);
      if (response.data && response.data.id) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Actualizado exitosamente.",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser editada. Es posible que los datos del proveedor ya existan.",
      });
      return false;
    }
  };
  static deleteProvider = async ({ userInfo, id }) => {
    try {
      const response = await deleteProvider(userInfo.UserId, id);
      if (response.data && response.data.message === "success") {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Eliminado exitosamente.",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser eliminada.",
      });
      return false;
    }
  };
  static getProviderByRuc = async ({
    userInfo,
    setInputs,
    setLoadingEnterpriseByRuc,
    ruc,
    setFetchError
  }) => {
    try {
      setLoadingEnterpriseByRuc(true);
      const response  = await getProviderByRuc(userInfo.UserId,ruc)
      if(response.data.success){
        setInputs(inputs => ({ ...inputs, businessName: response.data.data.nombre_o_razon_social }));
        return true;
      }
      setFetchError({error:"No se encontro un registro en SUNAT asociado a este RUC."})
      return false
    } catch (error) {
        return false;
    } finally {
      setLoadingEnterpriseByRuc(false);
    }
  };
}

export default ProvidersController;
