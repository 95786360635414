import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import logo from '../../../images/isotipo.svg';
import './APLogo.css';

const APLogo = props => {
  const { customClass } = props;
  const bemClasses = getBEMClasses('ap-logo', customClass);

  return (
    <div className={bemClasses() + ' my-first-step'}>
      <img src={logo} className={bemClasses('img')} alt="Logo" />
    </div>
  );
};

APLogo.defaultProps = {
  customClass: ''
};

APLogo.propTypes = {
  customClass: PropTypes.string
};

export default APLogo;
