import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Form, Button, Row, Col, Card, Modal } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { buildSecureGetParams } from '../../../helpers/lib';
import { LEVELS_STATUS } from '../../../helpers/constants';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import PopoverContent from 'react-bootstrap/PopoverContent';
import PopoverTitle from 'react-bootstrap/PopoverTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faMinusCircle,
  faQuestionCircle,
  faPaperPlane,
  faPen,
  faUserLock,
  faUserEdit,
  faSave,
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { states } from '../../../config/staticWorldContent';
import { province } from '../../../config/staticPeruProv';
import { districts } from '../../../config/staticPeruDist';
import { translationFunction } from './../TranslationFunction';
import { returnCorporateShareholderInfoFields } from './../AdminLevelsHooks';

const constructSchemaLegalRepresentative = (inputs, countries) => {
  let optionsDocument = [];
  if (!empty(inputs) && inputs.nationality === 'PE') {
    optionsDocument = [{ value: 'ID', name: 'National identity document' }];
  } else {
    optionsDocument = [{ value: 'PP', name: 'Passport' }];
  }
  let schema = [
    {
      title: 'Personal Data',
      hint: [
        {
          p:
            'Complete the form with all the personal data of the Legal Representative'
        },
        {
          p: '',
          links: [
            {
              title: 'See limits',
              href:
                'https://soporte.banexcoin.com/support/solutions/articles/60000609834'
            }
          ]
        }
      ],
      fields: [
        {
          label: 'Is a Shareholder?',
          name: 'select_percentage_number',
          type: 'select',
          options: [{ value: '0', name: 'No' }, { value: '1', name: 'Yes' }],
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Percentage Number %',
          name: 'percentage_number',
          type: 'number',
          displayOn: [
            {
              parent: 'select_percentage_number',
              conditionValue: '1'
            }
          ],
          props: [
            {
              autoComplete: 'off',
              step: '0.01',
              min: '0',
              max: '100'
            }
          ]
        },
        {
          label: 'First Name',
          name: 'first_name',
          type: 'text',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Middle name',
          name: 'middle_name',
          type: 'text',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Last Name',
          name: 'last_name',
          type: 'text',
          props: [{}]
        },
        {
          label: 'Second Last Name',
          name: 'second_last_name',
          type: 'text',
          props: [{}]
        },
        {
          label: 'Date of Birth',
          name: 'birthday',
          type: 'date',
          props: [
            {
              isEighteen: true
            }
          ]
        },
        {
          label: 'Nationality',
          name: 'nationality',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Birth Country',
          name: 'birth_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Country of Residence',
          name: 'residence_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Residence address',
          name: 'address_main',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Residence address'
            }
          ]
        },
        {
          label: 'Building number or name',
          name: 'building',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Building number or name',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Interior # / Apartment # / Office #',
          name: 'apt_number',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Zonification / Town',
          name: 'town',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'State',
          name: 'state',
          type: 'select',
          options: states[inputs.residence_country],
          props: [{}]
        },
        inputs.residence_country === 'PE'
          ? {
              label: 'City / Province',
              name: 'city',
              type: 'select',
              options: province[inputs.state],
              props: [{}]
            }
          : {
              label: 'City / Province',
              name: 'city',
              type: 'text',
              props: [{}]
            },
        inputs.residence_country === 'PE'
          ? {
              label: 'Municipality / District',
              name: 'district',
              type: 'select',
              options: districts[inputs.city],
              props: [{}]
            }
          : {
              label: 'Municipality / District',
              name: 'district',
              type: 'text',
              props: [{}]
            },
        {
          label: 'Zip Code',
          name: 'zip_code',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Zip Code',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Activity, occupation, trade or profession',
          name: 'occupation',
          type: 'text',
          props: [
            {
              placeholder: 'Activity, occupation, trade or profession'
            }
          ]
        },
        {
          label: 'Work Center',
          name: 'work_center',
          type: 'text',
          props: [{}]
        },
        {
          label: 'Phone',
          name: 'phone',
          type: 'tel',
          props: [{}]
        },
        {
          label: 'Email',
          name: 'email',
          type: 'email',
          props: [
            {
              pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
            }
          ]
        },
        {
          label: 'Is a PEP? (People Exposed Politically)',
          name: 'is_pep',
          type: 'select',
          options: [{ value: '0', name: 'No' }, { value: '1', name: 'Yes' }],
          props: [{}]
        },
        {
          label: 'Upload required PEP Document',
          name: 'pep_document',
          type: 'file',
          subdata: [
            {
              type: 'download-link',
              className: 'important',
              label:
                'Download the document file and fill it out. Then, upload the file to validate it.',
              href:
                'https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pep-document/en/Annex%20A%20-%20PEP%20Form%20Banexcoin.docx'
            }
          ],
          displayOn: [
            {
              parent: 'is_pep',
              conditionValue: '1'
            }
          ],
          props: [
            {
              accept: '.pdf,.doc,.docx'
            }
          ]
        }
      ]
    },
    {
      title: 'Billing Information',
      hint: [
        {
          p:
            'At this address, we can send you some communication if necessary, normally it is where you receive your bank statements or any service'
        }
      ],
      fields: [
        {
          label: 'Same as Residence Address',
          name: 'is_billing_address_residence',
          type: 'checkbox',
          props: [{}]
        },
        {
          label: 'Billing Address',
          name: 'billing_address',
          type: 'text',
          size: 12,
          props: [
            {
              autoComplete: 'off',
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_address
                : 'Billing Address'
            }
          ]
        },
        {
          label: 'Billing Building number or name',
          name: 'billing_building',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_building
                : 'Building number or name',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Billing Interior # / Apartment #',
          name: 'billing_apt_number',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_apt_number
                : 'Interior # / Apartment #',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Zonification',
          name: 'billing_town',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_town
                : 'Zonification',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Billing Country',
          name: 'billing_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        inputs.billing_country === 'PE'
          ? {
              label: 'Billing State',
              name: 'billing_state',
              type: 'select',
              options: states[inputs.billing_country],
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_state
                    : ''
                }
              ]
            }
          : {
              label: 'Billing State',
              name: 'billing_state',
              type: 'text',
              props: [
                {
                  autoComplete: 'off',
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_state
                    : 'Billing State'
                }
              ]
            },
        inputs.billing_country === 'PE'
          ? {
              label: 'Billing City / Province',
              name: 'billing_city',
              type: 'select',
              options: province[inputs.billing_state],
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_city
                    : ''
                }
              ]
            }
          : {
              label: 'Billing City / Province',
              name: 'billing_city',
              type: 'text',
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_city
                    : 'Billing City / Province'
                }
              ]
            },
        inputs.billing_country === 'PE'
          ? {
              label: 'Billing Municipality / District',
              name: 'billing_district',
              type: 'select',
              options: districts[inputs.billing_city],
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_district
                    : ''
                }
              ]
            }
          : {
              label: 'Billing Municipality / District',
              name: 'billing_district',
              type: 'text',
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_district
                    : 'District'
                }
              ]
            },
        {
          label: 'Billing Zip Code',
          name: 'billing_zip',
          type: 'text',
          props: [
            {
              autoComplete: 'off',
              placeholder: inputs.is_billing_address_residence
                ? inputs.zip_code
                : 'Zip Code'
            }
          ]
        }
      ]
    },
    {
      title: 'Upload document and selfie',
      hint: [
        {
          p: 'Crop the images before uploading if you wish'
        }
      ],
      fields: [
        {
          label: 'Document Country',
          name: 'document_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Document Type',
          name: 'document_type',
          type: 'select',
          options: optionsDocument,
          props: [{}]
        },
        inputs.document_country === 'PE'
          ? {
              label:
                inputs.document_type === 'ID' || inputs.document_type === 'PP'
                  ? 'Document Number (8/12 digits)'
                  : 'RUC Number (11 digits)',
              name: 'document_number',
              type: 'text',
              props: [
                {
                  autoComplete: 'off'
                }
              ]
            }
          : {
              label: 'Document Number',
              name: 'document_number',
              type: 'text',
              props: [
                {
                  autoComplete: 'off'
                }
              ]
            },
        {
          label: 'Document Data',
          name: 'document_data',
          type: 'file',
          filetype: 'image',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png'
            }
          ]
        },
        {
          label:
            inputs.document_type === 'PP'
              ? 'Identity Document of the Country where you reside'
              : 'Back Document Data',
          name: 'back_document_data',
          type: 'file',
          filetype: 'image',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png'
            }
          ]
        },
        {
          label: 'Selfie Photo',
          name: 'faces_images',
          type: 'file',
          filetype: 'image',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              accept: 'image/x-png,image/png,image/jpeg,image/heic,image/heif'
            }
          ]
        },
        {
          label: 'Instructions for your selfie photo',
          name: 'photo_selfie_instructions',
          type: 'info',
          subdata: {
            type: 'text',
            content: ''
          },
          props: [{}]
        },
        {
          label: '',
          name: 'photo_selfie_2',
          type: 'info',
          subdata: {
            type: 'image',
            content:
              inputs.document_type === 'PP'
                ? 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_pp.png'
                : inputs.document_type === 'ID'
                  ? 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_dni.jpg'
                  : ''
          },
          props: [{}]
        }
      ]
    }
  ];
  return schema;
};

const constructSchemaShareholdersNoLegal = (inputs, countries) => {
  let optionsDocument = [];
  if (!empty(inputs) && inputs.nationality === 'PE') {
    optionsDocument = [{ value: 'ID', name: 'National identity document' }];
  } else {
    optionsDocument = [{ value: 'PP', name: 'Passport' }];
  }
  let schema = [
    {
      title: 'Personal Data',
      hint: [
        {
          p: 'Complete the form with all your personal data'
        },
        {
          p: '',
          links: [
            {
              title: 'See limits',
              href:
                'https://soporte.banexcoin.com/support/solutions/articles/60000609834'
            }
          ]
        }
      ],
      fields: [
        {
          label: 'Percentage Number %',
          name: 'percentage_number',
          type: 'number',
          props: [
            {
              autoComplete: 'off',
              step: '0.01',
              min: '0',
              max: '100'
            }
          ]
        },
        {
          label: 'First Name',
          name: 'first_name',
          type: 'text',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Middle name',
          name: 'middle_name',
          type: 'text',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Last Name',
          name: 'last_name',
          type: 'text',
          props: [{}]
        },
        {
          label: 'Second Last Name',
          name: 'second_last_name',
          type: 'text',
          props: [{}]
        },
        {
          label: 'Date of Birth',
          name: 'birthday',
          type: 'date',
          props: [
            {
              isEighteen: true
            }
          ]
        },
        {
          label: 'Nationality',
          name: 'nationality',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Birth Country',
          name: 'birth_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Country of Residence',
          name: 'residence_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Residence address',
          name: 'address_main',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Residence address'
            }
          ]
        },
        {
          label: 'Building number or name',
          name: 'building',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Building number or name',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Interior # / Apartment # / Office #',
          name: 'apt_number',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Zonification / Town',
          name: 'town',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'State',
          name: 'state',
          type: 'select',
          options: states[inputs.residence_country],
          props: [{}]
        },
        inputs.residence_country === 'PE'
          ? {
              label: 'City / Province',
              name: 'city',
              type: 'select',
              options: province[inputs.state],
              props: [{}]
            }
          : {
              label: 'City / Province',
              name: 'city',
              type: 'text',
              props: [{}]
            },
        inputs.residence_country === 'PE'
          ? {
              label: 'Municipality / District',
              name: 'district',
              type: 'select',
              options: districts[inputs.city],
              props: [{}]
            }
          : {
              label: 'Municipality / District',
              name: 'district',
              type: 'text',
              props: [{}]
            },
        {
          label: 'Zip Code',
          name: 'zip_code',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Zip Code',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Activity, occupation, trade or profession',
          name: 'occupation',
          type: 'text',
          props: [
            {
              placeholder: 'Activity, occupation, trade or profession'
            }
          ]
        },
        {
          label: 'Work Center',
          name: 'work_center',
          type: 'text',
          props: [{}]
        },
        {
          label: 'Phone',
          name: 'phone',
          type: 'tel',
          props: [{}]
        },
        {
          label: 'Email',
          name: 'email',
          type: 'email',
          props: [
            {
              pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
            }
          ]
        },
        {
          label: 'Is a PEP? (People Exposed Politically)',
          name: 'is_pep',
          type: 'select',
          options: [{ value: '0', name: 'No' }, { value: '1', name: 'Yes' }],
          props: [{}]
        },
        {
          label: 'Upload required PEP Document',
          name: 'pep_document',
          type: 'file',
          subdata: [
            {
              type: 'download-link',
              className: 'important',
              label:
                'Download the document file and fill it out. Then, upload the file to validate it.',
              href:
                'https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pep-document/en/Annex%20A%20-%20PEP%20Form%20Banexcoin.docx'
            }
          ],
          displayOn: [
            {
              parent: 'is_pep',
              conditionValue: '1'
            }
          ],
          props: [
            {
              accept: '.pdf,.doc,.docx'
            }
          ]
        }
      ]
    },
    {
      title: 'Billing Information',
      hint: [
        {
          p:
            'At this address, we can send you some communication if necessary, normally it is where you receive your bank statements or any service'
        }
      ],
      fields: [
        {
          label: 'Same as Residence Address',
          name: 'is_billing_address_residence',
          type: 'checkbox',
          props: [{}]
        },
        {
          label: 'Billing Address',
          name: 'billing_address',
          type: 'text',
          size: 12,
          props: [
            {
              autoComplete: 'off',
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_address
                : 'Billing Address'
            }
          ]
        },
        {
          label: 'Billing Building number or name',
          name: 'billing_building',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_building
                : 'Building number or name',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Billing Interior # / Apartment #',
          name: 'billing_apt_number',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_apt_number
                : 'Interior # / Apartment #',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Zonification',
          name: 'billing_town',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: inputs.is_billing_address_residence
                ? inputs.billing_town
                : 'Zonification',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Billing Country',
          name: 'billing_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        inputs.billing_country === 'PE'
          ? {
              label: 'Billing State',
              name: 'billing_state',
              type: 'select',
              options: states[inputs.billing_country],
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_state
                    : ''
                }
              ]
            }
          : {
              label: 'Billing State',
              name: 'billing_state',
              type: 'text',
              props: [
                {
                  autoComplete: 'off',
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_state
                    : 'Billing State'
                }
              ]
            },
        inputs.billing_country === 'PE'
          ? {
              label: 'Billing City / Province',
              name: 'billing_city',
              type: 'select',
              options: province[inputs.billing_state],
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_city
                    : ''
                }
              ]
            }
          : {
              label: 'Billing City / Province',
              name: 'billing_city',
              type: 'text',
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_city
                    : 'Billing City / Province'
                }
              ]
            },
        inputs.billing_country === 'PE'
          ? {
              label: 'Billing Municipality / District',
              name: 'billing_district',
              type: 'select',
              options: districts[inputs.billing_city],
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_district
                    : ''
                }
              ]
            }
          : {
              label: 'Billing Municipality / District',
              name: 'billing_district',
              type: 'text',
              props: [
                {
                  placeholder: inputs.is_billing_address_residence
                    ? inputs.billing_district
                    : 'District'
                }
              ]
            },
        {
          label: 'Billing Zip Code',
          name: 'billing_zip',
          type: 'text',
          props: [
            {
              autoComplete: 'off',
              placeholder: inputs.is_billing_address_residence
                ? inputs.zip_code
                : 'Zip Code'
            }
          ]
        }
      ]
    },
    {
      title: 'Upload document and selfie',
      hint: [
        {
          p: 'Crop the images before uploading if you wish'
        }
      ],
      fields: [
        {
          label: 'Document Country',
          name: 'document_country',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'Document Type',
          name: 'document_type',
          type: 'select',
          options: optionsDocument,
          props: [{}]
        },
        inputs.document_country === 'PE'
          ? {
              label:
                inputs.document_type === 'ID' || inputs.document_type === 'PP'
                  ? 'Document Number (8/12 digits)'
                  : 'RUC Number (11 digits)',
              name: 'document_number',
              type: 'text',
              props: [
                {
                  autoComplete: 'off'
                }
              ]
            }
          : {
              label: 'Document Number',
              name: 'document_number',
              type: 'text',
              props: [
                {
                  autoComplete: 'off'
                }
              ]
            },
        {
          label: 'Document Data',
          name: 'document_data',
          type: 'file',
          filetype: 'image',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png'
            }
          ]
        },
        {
          label:
            inputs.document_type === 'PP'
              ? 'Identity Document of the Country where you reside'
              : 'Back Document Data',
          name: 'back_document_data',
          type: 'file',
          filetype: 'image',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png'
            }
          ]
        },
        {
          label: 'Selfie Photo',
          name: 'faces_images',
          type: 'file',
          filetype: 'image',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              accept: 'image/x-png,image/png,image/jpeg,image/heic,image/heif'
            }
          ]
        },
        {
          label: 'Instructions for your selfie photo',
          name: 'photo_selfie_instructions',
          type: 'info',
          subdata: {
            type: 'text',
            content: ''
          },
          props: [{}]
        },
        {
          label: '',
          name: 'photo_selfie_2',
          type: 'info',
          subdata: {
            type: 'image',
            content:
              inputs.document_type === 'PP'
                ? 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_pp.png'
                : inputs.document_type === 'ID'
                  ? 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_dni.jpg'
                  : ''
          },
          props: [{}]
        }
      ]
    }
  ];
  return schema;
};

const constructSchemaCompanyShareholder = (inputs, countries) => {
  let schema = [
    {
      title: 'Corporate Data',
      hint: [
        {
          p: ''
        },
        {
          p:
            'You must provide detailed information about the company that is a shareholder.',
          links: [
            {
              title: 'See limits',
              href:
                'https://soporte.banexcoin.com/support/solutions/articles/60000609834'
            }
          ]
        }
      ],
      fields: [
        {
          label: 'Percentage Number %',
          name: 'percentage_number',
          type: 'number',
          props: [
            {
              autoComplete: 'off',
              step: '0.01'
            }
          ]
        },
        {
          label: 'Denomination or Full Legal Name of Company',
          name: 'company_legal_name',
          type: 'text',
          props: [
            {
              autoComplete: 'off',
              placeholder: 'Enter Denomination or Full Legal Name of Company'
            }
          ]
        },
        {
          label: 'Type of Legal Entity',
          name: 'legal_entity_type',
          type: 'select',
          options: [
            { value: 'Ltd', name: 'Ltd' },
            { value: 'SE', name: 'SE' },
            { value: 'SPE', name: 'SPE' },
            { value: 'EEIG', name: 'EEIG' },
            { value: 'PLC', name: 'PLC' },
            { value: 'Corp', name: 'Corp' },

            { value: 'SA', name: 'SA' },
            { value: 'SAA', name: 'SAA' },
            { value: 'SAC', name: 'SAC' },
            { value: 'SRL', name: 'SRL' },
            { value: 'EIRL', name: 'EIRL' },
            { value: 'CA.', name: 'CA.' },

            { value: 'Non-Profit', name: 'Non-Profit (specify)' },
            { value: 'Other', name: 'Other (specify)' }
          ],
          props: [{}]
        },
        {
          label: 'Non-Profit',
          name: 'non_profit',
          type: 'select',
          options: [
            { value: 'Association', name: 'Association' },
            { value: 'Foundation', name: 'Foundation' },
            { value: 'Committee', name: 'Committee' },
            { value: 'Other', name: 'Other (specify)' }
          ],
          displayOn: [
            {
              parent: 'legal_entity_type',
              conditionValue: 'Non-Profit'
            }
          ],
          props: [{}]
        },
        {
          label: 'Other Non-Profit',
          name: 'other_non_profit',
          type: 'text',
          displayOn: [
            {
              parent: 'legal_entity_type',
              conditionValue: 'Non-Profit'
            },
            {
              parent: 'non_profit',
              conditionValue: 'Other'
            }
          ],
          props: [{}]
        },
        {
          label: 'Other Type of Legal Entity',
          name: 'other_legal_entity_type',
          type: 'text',
          displayOn: [
            {
              parent: 'legal_entity_type',
              conditionValue: 'Other'
            }
          ],
          props: [{}]
        },
        {
          label: 'Type of Taxpayer Identification Number',
          name: 'taxpayer_identification_number_type',
          type: 'select',
          options: [
            { value: 'TIN', name: 'TIN' },
            { value: 'EIN', name: 'EIN' },
            { value: 'VAT', name: 'VAT' },
            { value: 'NINO', name: 'NINO' },

            { value: 'RUC', name: 'RUC' },
            { value: 'RUT', name: 'RUT' },
            { value: 'NIF', name: 'NIF' },
            { value: 'RIF', name: 'RIF' },

            { value: 'Other', name: 'Other (specify)' }
          ],
          props: [{}]
        },
        {
          label: 'Other Type of Taxpayer Identification Number',
          name: 'other_taxpayer_identification_number_type',
          type: 'text',
          displayOn: [
            {
              parent: 'taxpayer_identification_number_type',
              conditionValue: 'Other'
            }
          ],
          props: [{}]
        },
        {
          label: 'Taxpayer Identification Number',
          name: 'taxpayer_identification_number',
          type: 'text',
          props: [{}]
        },
        {
          label:
            'Describe Corporate Purpose / Products or Services that the company provides',
          name: 'corporate_purpose',
          type: 'text',
          size: 12,
          props: [{}]
        },
        {
          label: 'Enter Street / Avenue',
          name: 'company_address',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Enter Street / Avenue'
            }
          ]
        },
        {
          label: 'Building number or name',
          name: 'building',
          type: 'text',
          size: '6',
          props: [
            {
              placeholder: 'Building number or name',
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Interior # / Apartment # / Office #',
          name: 'apt_number',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Zonification / Town',
          name: 'town',
          type: 'text',
          size: '6',
          props: [
            {
              autoComplete: 'off'
            }
          ]
        },
        {
          label: 'Country',
          name: 'country_incorporation',
          type: 'select',
          options: countries,
          props: [{}]
        },
        {
          label: 'State',
          name: 'state',
          type: 'select',
          options: states[inputs.country_incorporation],
          props: [{}]
        },
        inputs.country_incorporation === 'PE'
          ? {
              label: 'City / Province',
              name: 'city',
              type: 'select',
              options: province[inputs.state],
              props: [{}]
            }
          : {
              label: 'City / Province',
              name: 'city',
              type: 'text',
              props: [{}]
            },
        inputs.country_incorporation === 'PE'
          ? {
              label: 'Municipality / District',
              name: 'district',
              type: 'select',
              options: districts[inputs.city],
              props: [{}]
            }
          : {
              label: 'Municipality / District',
              name: 'district',
              type: 'text',
              props: [{}]
            },
        {
          label: 'Zip Code',
          name: 'zip_code',
          type: 'text',
          props: [{}]
        },

        {
          label: 'Registration Date (Constitution)',
          name: 'registration_date',
          type: 'date',
          props: [
            {
              isNormal: true
            }
          ]
        },
        {
          label: 'Activity begin date',
          name: 'begin_date',
          type: 'date',
          props: [
            {
              isNormal: true,
              defaultMinDate: !empty(inputs.registration_date)
                ? inputs.registration_date
                : false
            }
          ]
        },
        {
          label: 'Linked economic group (if applicable)',
          name: 'linked_economic_group',
          type: 'text',
          props: [{}]
        },
        {
          label: 'Company Phone',
          name: 'company_phone',
          type: 'tel',
          props: [{}]
        },
        {
          label: 'Company Email',
          name: 'company_email',
          type: 'email',
          props: [
            {
              pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
            }
          ]
        }
      ]
    }
  ];
  return schema;
};

const findFieldsInSchema = (field, schema) => {
  if (!empty(schema)) {
    for (let i in schema) {
      let g = schema[i];
      for (let f in g.fields) {
        let c = g.fields[f];
        if (c.name === field) {
          return {
            label: c.label,
            name: c.name
          };
        }
      }
    }
  }
};

const FieldsForm = (props, context) => {
  let { formsString, schema, rowData, setLoading, userInfo, onClose } = props;

  const [shareholderInputsReturn, setShareholderInputsReturn] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [textObservacion, setTextObservacion] = useState({});

  const onSetObservation = e => {
    e.persist();
    let value = e.target.value;
    setTextObservacion({
      [e.target.name]: value
    });
  };

  const onRemoveReturnDataInput = e => {
    e.persist();
    let value = null;
    setShareholderInputsReturn(shareholderInputsReturn => ({
      ...shareholderInputsReturn,
      [e.target.name]: value
    }));
  };

  const onSetShareholderReturnDataInput = e => {
    e.persist();
    let value;
    if (e.target.checked) {
      value = e.target.name;
    } else {
      console.log('no checkado');
      value = null;
    }
    setShareholderInputsReturn(shareholderInputsReturn => ({
      ...shareholderInputsReturn,
      [e.target.name]: value
    }));
  };

  const FieldsToReturn = () => {
    const handleModalClose = () => {
      setShowModal(false);
    };
    const handleModalShow = () => {
      setShowModal(true);
    };

    let ListItems = [];
    let ListItemsLi = [];
    let ListItemsToReturn = [];
    let contador = 0;
    for (let d in shareholderInputsReturn) {
      if (!empty(shareholderInputsReturn[d])) {
        let { label, name } = findFieldsInSchema(
          shareholderInputsReturn[d],
          schema
        );
        ListItems.push(
          <ListGroup.Item key={d} style={{ display: 'flex' }}>
            <b style={{ width: '100%' }}>{translationFunction(label)}</b>
            <Button
              variant="outline-danger"
              name={name}
              onClick={onRemoveReturnDataInput}
              style={{
                marginLeft: '1rem',
                float: 'right',
                verticalAlign: 'middle'
              }}>
              X
            </Button>
          </ListGroup.Item>
        );
        ListItemsLi.push(
          <li key={d} style={{ width: '100%' }}>
            {translationFunction(label)}
          </li>
        );
        ListItemsToReturn.push({
          label_en: label,
          label_es: translationFunction(label),
          name
        });
        contador++;
      }
    }
    if (contador < 1) {
      ListItems.push(
        <ListGroup.Item key={1} variant="dark">
          Ningun campo ha sido seleccionado
        </ListGroup.Item>
      );
    }
    let nationality = [
      'PE',
      'CO',
      'VE',
      'ES',
      'PR',
      'PY',
      'PA',
      'NI',
      'MX',
      'CR',
      'CL',
      'BO',
      'AR',
      'CU',
      'NI'
    ];
    let country = '';
    if (rowData.shareholderType === 'NO_SHAREHOLDER') {
      let { residence_country } = !empty(formsString)
        ? JSON.parse(formsString.dataForm)
        : {};
      country = residence_country;
    } else if (rowData.shareholderType === 'PERSON_SHAREHOLDER') {
      let { residence_country } = !empty(formsString)
        ? JSON.parse(formsString.dataForm)
        : {};
      country = residence_country;
    } else if (rowData.shareholderType === 'COMPANY_SHAREHOLDER') {
      let { country_incorporation } = !empty(formsString)
        ? JSON.parse(formsString.dataForm)
        : {};
      country = country_incorporation;
    }

    return (
      <ListGroup>
        {ListItems}
        <Modal
          show={showModal}
          onHide={handleModalClose}
          className="modal-return">
          <Modal.Header closeButton />
          <div className="custom-ui">
            <h1>¿Estas seguro en devolver los siguientes campos?</h1>
            <ul>{ListItemsLi}</ul>
            <Form noValidate encType="multipart/form-data" autoComplete="off">
              <Form.Group controlId="text_observacion">
                <Form.Label>
                  Agregar Observación{' '}
                  <b>(Esta observación llegará en el correo del cliente)</b>
                  <br />
                  Idioma del mensaje:{' '}
                  <b>
                    {!empty(country)
                      ? nationality.includes(country)
                        ? 'ES'
                        : 'EN'
                      : 'EN'}
                  </b>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="text_observacion"
                  rows={3}
                  onChange={onSetObservation}
                  value={textObservacion.text_observacion || ''}
                />
              </Form.Group>
              <div className="button-fields-botton">
                <Button
                  disabled={
                    !empty(textObservacion.text_observacion)
                      ? textObservacion.text_observacion.trim().length < 1
                        ? true
                        : false
                      : true
                  }
                  onClick={async () => {
                    try {
                      setLoading(1);
                      handleModalClose();
                      onClose();
                      toast.info('Enviando...', {
                        position: toast.POSITION.TOP_CENTER
                      });
                      let response = await returnCorporateShareholderInfoFields(
                        userInfo.UserId,
                        ListItemsToReturn,
                        rowData.id,
                        textObservacion.text_observacion
                      );
                      if (response.data.message === 'success') {
                        toast.success('Registro guardado exitosamente', {
                          position: toast.POSITION.TOP_CENTER
                        });
                        toast.info('Actualizando pagina...', {
                          position: toast.POSITION.TOP_CENTER
                        });
                        window.location.reload();
                      }
                    } catch (error) {
                      console.log('errorrrrr', error.message);
                      handleModalClose();
                      toast.warn(
                        translationFunction('Information could not be saved'),
                        {
                          zIndex: 9999,
                          position: toast.POSITION.TOP_CENTER
                        }
                      );
                    }
                    setLoading(0);
                  }}
                  className="confirmation">
                  Si, devolver campos
                </Button>
                <button type="button" onClick={handleModalClose}>
                  No
                </button>
              </div>
            </Form>
          </div>
        </Modal>
        {contador > 0 ? (
          <Button
            style={{
              margin: '1rem 0',
              padding: '1rem',
              fontSize: '1.3rem',
              fontWeight: '500'
            }}
            onClick={() => {
              handleModalShow();
              //returnCorporateInfoFieldsAlert(inputsReturn, inputs.id);
            }}>
            Solicitar corrección de campos
          </Button>
        ) : (
          ''
        )}
      </ListGroup>
    );
  };

  const GetFilename = url => {
    if (url) {
      return decodeURI(
        url
          .toString()
          .split('/')
          .pop()
      );
    }
    return url;
  };

  if (!empty(formsString.dataForm)) {
    let edit_fields = !empty(rowData.editFields)
      ? JSON.parse(rowData.editFields)
      : [];
    let json = JSON.parse(formsString.dataForm);

    let structureScheme = schema;

    let Groups = [];

    for (let i in structureScheme) {
      let g = structureScheme[i];
      let Fields = [];
      for (let f in g.fields) {
        let field = g.fields[f];
        let { size, name, label, type } = field;
        let value =
          json[name] !== 'undefined' && json[name] !== 'null'
            ? !empty(json[name])
              ? translationFunction(json[name])
              : null
            : null;
        if (!empty(value)) {
          Fields.push(
            <Col key={f} xl={size || '6'}>
              <Form.Group>
                <Form.Label>{translationFunction(label)}</Form.Label>
                <div className="container-check-input">
                  <Form.Check
                    className="left-check"
                    name={name}
                    onChange={onSetShareholderReturnDataInput}
                    aria-label="Return"
                    checked={
                      !empty(shareholderInputsReturn[name]) ? true : false
                    }
                    disabled={edit_fields.includes(name) ? true : false}
                  />
                  {type === 'file' ? (
                    field.hasOwnProperty('filetype') &&
                    field.filetype === 'image' ? (
                      <img src={value} className="image-preview" width="95%" />
                    ) : (
                      <a
                        className="form-control btn btn-success"
                        href={value}
                        target="_blank">
                        {' Descargar '}
                        <b>{`${GetFilename(value)}`}</b>
                      </a>
                    )
                  ) : (
                    <Form.Control
                      name={`${name}_field`}
                      type="text"
                      readOnly={true}
                      defaultValue={
                        !empty(value)
                          ? value.toString().toUpperCase()
                          : 'NO INGRESADO'
                      }
                      className={!empty(value) ? '' : 'no-aplica'}
                    />
                  )}
                </div>
                <Form.Control.Feedback type="invalid">
                  {translationFunction('You must put {name}', { name: label })}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        } else {
          Fields.push(<React.Fragment key={f} />);
        }
      }
      Groups.push(
        <Card className={`group-form col-xl-6`} key={i}>
          <Card.Header>{g.title}</Card.Header>
          <Card.Body>
            <Row>{Fields}</Row>
          </Card.Body>
        </Card>
      );
    }
    return (
      <React.Fragment>
        <div className="col-xl-9">
          <Row>{Groups}</Row>
        </div>
        <div className="col-xl-3">
          <Card
            style={{
              position: 'sticky',
              top: '0px'
            }}>
            <Card.Header>
              <h3>{translationFunction('Campos a devolver')}</h3>
            </Card.Header>
            <Card.Body
              style={{
                padding: '1rem',
                maxHeight: '300px',
                overflowY: 'auto',
                marginBottom: '1rem'
              }}>
              {FieldsToReturn()}
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    );
  }
};

async function viewPersonShareholder(
  fields,
  row,
  schema,
  setLoading,
  userInfo
) {
  //setShareholderInputsReturn({});
  let share = {
    dataForm: JSON.stringify(fields)
  };
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui-alert">
          <button
            className="close"
            onClick={() => {
              onClose();
            }}>
            X
          </button>
          <h1>
            Ver Datos de{' '}
            {row.shareholderType === 'COMPANY_SHAREHOLDER'
              ? fields.company_legal_name
              : fields.first_name}
          </h1>
          <div>
            <Row>
              <FieldsForm
                formsString={share}
                schema={schema}
                rowData={row}
                setLoading={setLoading}
                userInfo={userInfo}
                onClose={onClose}
              />
            </Row>
          </div>
        </div>
      );
    }
  });
}

const RowsCompanyShareholder = (props, context) => {
  let {
    shareholders,
    getLabel,
    togglePopup,
    onSetSidebarFormShareholderOpen,
    countries,
    setLoading,
    userInfo
  } = props;
  let rows = [];

  for (let i in shareholders) {
    let row = shareholders[i];
    let fields = JSON.parse(row.dataForm);
    if (row.shareholderType === 'COMPANY_SHAREHOLDER') {
      rows.push(
        <tr className={'rowstatus' + row.status} key={i}>
          <td>{translationFunction(getLabel(row.shareholderType))}</td>
          <td>{fields.percentage_number}</td>
          <td>
            {fields.is_legal_representative !== 'undefined'
              ? fields.is_legal_representative
              : 'NO'}
          </td>
          <td>
            {fields.company_legal_name.toUpperCase() !== 'undefined'
              ? fields.company_legal_name.toUpperCase()
              : ''}
          </td>
          <td>
            {fields.company_email.toUpperCase() !== 'undefined'
              ? fields.company_email.toUpperCase()
              : ''}
          </td>
          <td />
          <td />
          <td>
            {moment(row.created)
              .format('DD-MM-YYYY h:mm:ss a')
              .toString()}
          </td>
          <td className="button-box">
            <Button
              variant="warning"
              onClick={() => {
                onSetSidebarFormShareholderOpen(
                  true,
                  constructSchemaCompanyShareholder(fields, countries),
                  row
                );
              }}>
              Editar
            </Button>
            <Button
              onClick={() => {
                viewPersonShareholder(
                  fields,
                  row,
                  constructSchemaCompanyShareholder(fields, countries),
                  setLoading,
                  userInfo
                );
              }}>
              Ver
            </Button>
          </td>
        </tr>
      );
    }
  }

  return (
    <Table>
      <thead>
        <tr className="header-title-table">
          <th colSpan="9">Empresas Accionistas</th>
        </tr>
        <tr className="header-titles-table">
          <th>Tipo de Accionista</th>
          <th>Porcentaje Accionario</th>
          <th>¿Es representante legal?</th>
          <th>Nombre del corporativo</th>
          <th>Email</th>
          <th />
          <th />
          <th className="created_at">Fecha</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!empty(rows) ? (
          rows
        ) : (
          <tr className="rowstatusNone">
            <th colSpan="11">Sin registros</th>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const RowsLegalRepresentative = (props, context) => {
  let {
    shareholders,
    getLabel,
    togglePopup,
    sendToIdm,
    logReniecInfo,
    onSetSidebarFormShareholderOpen,
    countries,
    setLoading,
    userInfo
  } = props;
  let rows = [];

  for (let i in shareholders) {
    let row = shareholders[i];
    let fields = JSON.parse(row.dataForm);
    if (fields.is_legal_representative === '1') {
      let f = {};
      if (!empty(logReniecInfo) && fields.hasOwnProperty('document_number')) {
        f = logReniecInfo.find(
          r => r.documentNumber === fields.document_number
        );
      }
      let match_info = {};
      if (!empty(f)) {
        if (
          !empty(f.reniec_response) &&
          f.reniec_response.hasOwnProperty('result')
        ) {
          if (!empty(f.reniec_response.result)) {
            match_info = JSON.parse(f.reniec_response.result);
          }
        }
      }

      let fullname = [
        fields.first_name.toUpperCase(),
        fields.middle_name.toUpperCase(),
        fields.last_name.toUpperCase(),
        fields.second_last_name.toUpperCase()
      ];
      let notNullNames = fullname.filter(x => x !== null);
      let returnValue = notNullNames.join(' ');
      returnValue = returnValue.replace(/  +/g, ' ');

      const popover = (
        <Popover id="popover-basic">
          {!empty(f) ? (
            <React.Fragment>
              {fields.document_country === 'PE' &&
              fields.document_type === 'ID' ? (
                f.reniec_response.validated === false ? (
                  <React.Fragment>
                    <Popover.Title as="h3" style={{ color: 'red' }}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                      <b> User NO validated by RENIEC</b>
                    </Popover.Title>
                    <Popover.Content>
                      <div>
                        <b>Reject Reason: </b>
                        <span>{f.reniec_response.reason}</span>
                      </div>
                      <hr />
                      <div>
                        <b>Fecha de validación: </b>
                        <span>{moment(f.updatedAt).format('YYYY-MM-DD')}</span>
                      </div>
                      <div>
                        <b>Ejecutado por: </b>
                        <span>{f.executeByName}</span>
                      </div>
                    </Popover.Content>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Popover.Title as="h3">
                      <FontAwesomeIcon icon={faCheckCircle} />{' '}
                      <b> User validated by RENIEC</b>
                    </Popover.Title>
                    <Popover.Content>
                      <div>
                        <b>DNI: </b>
                        <span>{match_info.dni}</span>
                      </div>
                      <div>
                        <b>Nombre: </b>
                        <span>{`${match_info.name} ${
                          match_info.lastName
                        }`}</span>
                      </div>
                      <div>
                        <b>Fecha Expiración: </b>
                        <span>{match_info.expDate}</span>
                      </div>
                    </Popover.Content>
                  </React.Fragment>
                )
              ) : (
                <React.Fragment>
                  <Popover.Title as="h3">
                    <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                    <b>No cumple los requerimientos para validación</b>
                  </Popover.Title>
                  <Popover.Content>
                    El usuario no tiene documento emitido en Perú y/o tipo de
                    documento no es DNI
                  </Popover.Content>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Popover.Title as="h3">
                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                <b>Sin datos</b>
              </Popover.Title>
              <Popover.Content>
                No hay información o aún no ha sido enviada a Reniec
                {/* El usuario no tiene documento emitido en Perú y/o tipo de documento no es DNI */}
              </Popover.Content>
            </React.Fragment>
          )}
        </Popover>
      );
      rows.push(
        <tr className={'rowstatus' + row.status} key={i}>
          <td>{translationFunction(getLabel(row.shareholderType))}</td>
          <td>{fields.percentage_number}</td>
          <td>{getLabel(fields.is_legal_representative)}</td>
          <td>
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
              <Button variant={empty(f) ? 'secondary' : 'success'}>
                {empty(f) ? 'NO HAY DATOS' : 'Ver'}
              </Button>
            </OverlayTrigger>
          </td>
          <td>
            <img src={fields.faces_images} className="selfie_img" />
          </td>
          <td>{returnValue}</td>
          <td>
            <b>{fields.document_country}</b>
          </td>
          <td>{fields.document_type}</td>
          <td>{fields.document_number}</td>
          <td>
            {moment(row.created)
              .format('DD-MM-YYYY h:mm:ss a')
              .toString()}
          </td>
          <td className="button-box">
            <Button
              variant="warning"
              onClick={() => {
                onSetSidebarFormShareholderOpen(
                  true,
                  constructSchemaLegalRepresentative(fields, countries),
                  row
                );
              }}>
              Editar
            </Button>
            <Button
              onClick={() => {
                viewPersonShareholder(
                  fields,
                  row,
                  constructSchemaLegalRepresentative(fields, countries),
                  setLoading,
                  userInfo
                );
              }}>
              Ver
            </Button>
            {fields.document_country === 'PE' &&
            fields.document_type === 'ID' ? (
              <Button
                onClick={e => {
                  togglePopup(i);
                }}
                variant="success"
                type="button"
                style={{
                  padding: '1em',
                  fontSize: '12px',
                  border: 0
                }}>
                <span>{translationFunction('Send to RENIEC')}</span>
              </Button>
            ) : (
              ''
            )}
            <Button
              onClick={e => {
                sendToIdm(row.id);
              }}
              variant="success"
              type="button"
              style={{
                padding: '1em',
                fontSize: '12px',
                border: 0
              }}>
              <span>{translationFunction('Send to DIG_iD_VERIF')}</span>
            </Button>
          </td>
        </tr>
      );
    }
  }

  return (
    <Table>
      <thead>
        <tr className="header-title-table">
          <th colSpan="11">Representantes Legales</th>
        </tr>
        <tr className="header-titles-table">
          <th>Tipo de Accionista</th>
          <th>Porcentaje Accionario</th>
          <th>¿Es representante legal?</th>
          <th>Reniec</th>
          <th>Selfie</th>
          <th>Nombre del representante legal</th>
          <th>País del documento</th>
          <th>Tipo de documento</th>
          <th>Numero de documento</th>
          <th className="created_at">Fecha</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!empty(rows) ? (
          rows
        ) : (
          <tr className="rowstatusNone">
            <th colSpan="11">Sin registros</th>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const RowsShareholders = (props, context) => {
  let {
    shareholders,
    getLabel,
    togglePopup,
    sendToIdm,
    logReniecInfo,
    onSetSidebarFormShareholderOpen,
    countries,
    setLoading,
    userInfo
  } = props;
  let rows = [];

  for (let i in shareholders) {
    let row = shareholders[i];
    let fields = JSON.parse(row.dataForm);
    if (
      row.shareholderType === 'PERSON_SHAREHOLDER' &&
      fields.is_legal_representative !== '1'
    ) {
      let f = {};
      if (!empty(logReniecInfo) && fields.hasOwnProperty('document_number')) {
        f = logReniecInfo.find(
          r => r.documentNumber === fields.document_number
        );
      }
      let match_info = {};
      if (!empty(f)) {
        if (
          !empty(f.reniec_response) &&
          f.reniec_response.hasOwnProperty('result')
        ) {
          if (!empty(f.reniec_response.result)) {
            match_info = JSON.parse(f.reniec_response.result);
          }
        }
      }

      let fullname = [
        fields.first_name.toUpperCase(),
        fields.middle_name.toUpperCase(),
        fields.last_name.toUpperCase(),
        fields.second_last_name.toUpperCase()
      ];
      let notNullNames = fullname.filter(x => x !== null);
      let returnValue = notNullNames.join(' ');
      returnValue = returnValue.replace(/  +/g, ' ');

      const popover = (
        <Popover id="popover-basic">
          {!empty(f) ? (
            <React.Fragment>
              {fields.document_country === 'PE' &&
              fields.document_type === 'ID' ? (
                f.reniec_response.validated === false ? (
                  <React.Fragment>
                    <Popover.Title as="h3" style={{ color: 'red' }}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                      <b> User NO validated by RENIEC</b>
                    </Popover.Title>
                    <Popover.Content>
                      <div>
                        <b>Reject Reason: </b>
                        <span>{f.reniec_response.reason}</span>
                      </div>
                      <hr />
                      <div>
                        <b>Fecha de validación: </b>
                        <span>{moment(f.updatedAt).format('YYYY-MM-DD')}</span>
                      </div>
                      <div>
                        <b>Ejecutado por: </b>
                        <span>{f.executeByName}</span>
                      </div>
                    </Popover.Content>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Popover.Title as="h3">
                      <FontAwesomeIcon icon={faCheckCircle} />{' '}
                      <b> User validated by RENIEC</b>
                    </Popover.Title>
                    <Popover.Content>
                      <div>
                        <b>DNI: </b>
                        <span>{match_info.dni}</span>
                      </div>
                      <div>
                        <b>Nombre: </b>
                        <span>{`${match_info.name} ${
                          match_info.lastName
                        }`}</span>
                      </div>
                      <div>
                        <b>Fecha Expiración: </b>
                        <span>{match_info.expDate}</span>
                      </div>
                    </Popover.Content>
                  </React.Fragment>
                )
              ) : (
                <React.Fragment>
                  <Popover.Title as="h3">
                    <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                    <b>No cumple los requerimientos para validación</b>
                  </Popover.Title>
                  <Popover.Content>
                    El usuario no tiene documento emitido en Perú y/o tipo de
                    documento no es DNI
                  </Popover.Content>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Popover.Title as="h3">
                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                <b>Sin datos</b>
              </Popover.Title>
              <Popover.Content>
                No hay información o aún no ha sido enviada a Reniec
                {/* El usuario no tiene documento emitido en Perú y/o tipo de documento no es DNI */}
              </Popover.Content>
            </React.Fragment>
          )}
        </Popover>
      );
      rows.push(
        <tr className={'rowstatus' + row.status} key={i}>
          <td>{translationFunction(getLabel(row.shareholderType))}</td>
          <td>{fields.percentage_number}</td>
          <td>{getLabel(fields.is_legal_representative)}</td>
          <td>
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
              <Button variant={empty(f) ? 'secondary' : 'success'}>
                {empty(f) ? 'NO APLICA' : 'Ver'}
              </Button>
            </OverlayTrigger>
          </td>
          <td>
            <img src={fields.faces_images} className="selfie_img" />
          </td>
          <td>{returnValue}</td>
          <td>
            <b>{fields.document_country}</b>
          </td>
          <td>{fields.document_type}</td>
          <td>{fields.document_number}</td>
          <td>
            {moment(row.created)
              .format('DD-MM-YYYY h:mm:ss a')
              .toString()}
          </td>
          <td className="button-box">
            <Button
              variant="warning"
              onClick={() => {
                onSetSidebarFormShareholderOpen(
                  true,
                  constructSchemaShareholdersNoLegal(fields, countries),
                  row
                );
              }}>
              Editar
            </Button>
            <Button
              onClick={() => {
                viewPersonShareholder(
                  fields,
                  row,
                  constructSchemaShareholdersNoLegal(fields, countries),
                  setLoading,
                  userInfo
                );
              }}>
              Ver
            </Button>
            {fields.document_country === 'PE' &&
            fields.document_type === 'ID' ? (
              <Button
                onClick={e => {
                  togglePopup(i);
                }}
                variant="success"
                type="button"
                style={{
                  padding: '1em',
                  fontSize: '12px',
                  border: 0
                }}>
                <span>{translationFunction('Send to RENIEC')}</span>
              </Button>
            ) : (
              ''
            )}
            <Button
              onClick={e => {
                sendToIdm(row.id);
              }}
              variant="success"
              type="button"
              style={{
                padding: '1em',
                fontSize: '12px',
                border: 0
              }}>
              <span>{translationFunction('Send to DIG_iD_VERIF')}</span>
            </Button>
          </td>
        </tr>
      );
    }
  }

  return (
    <Table>
      <thead>
        <tr className="header-title-table">
          <th colSpan="11">Accionistas (No representantes legales)</th>
        </tr>
        <tr className="header-titles-table">
          <th>Tipo de Accionista</th>
          <th>Porcentaje Accionario</th>
          <th>¿Es representante legal?</th>
          <th>Reniec</th>
          <th>Selfie</th>
          <th>Nombre del representante legal</th>
          <th>País del documento</th>
          <th>Tipo de documento</th>
          <th>Numero de documento</th>
          <th className="created_at">Fecha</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!empty(rows) ? (
          rows
        ) : (
          <tr className="rowstatusNone">
            <th colSpan="11">Sin registros</th>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const TableLegalsShareholders = (props, context) => {
  const {
    shareholders,
    getLabel,
    sendToIdm,
    togglePopup,
    logReniecInfo,
    onSetSidebarFormShareholderOpen,
    countries,
    setLoading,
    loading,
    userInfo
  } = props;

  if (!shareholders) {
    return (
      <React.Fragment>
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="table-banexcoin">
        <RowsLegalRepresentative
          shareholders={shareholders}
          getLabel={getLabel}
          togglePopup={togglePopup}
          sendToIdm={sendToIdm}
          logReniecInfo={logReniecInfo}
          onSetSidebarFormShareholderOpen={onSetSidebarFormShareholderOpen}
          countries={countries}
          setLoading={setLoading}
          loading={loading}
          userInfo={userInfo}
        />
        <RowsShareholders
          shareholders={shareholders}
          getLabel={getLabel}
          togglePopup={togglePopup}
          sendToIdm={sendToIdm}
          logReniecInfo={logReniecInfo}
          onSetSidebarFormShareholderOpen={onSetSidebarFormShareholderOpen}
          countries={countries}
          setLoading={setLoading}
          loading={loading}
          userInfo={userInfo}
        />
        <RowsCompanyShareholder
          shareholders={shareholders}
          getLabel={getLabel}
          togglePopup={togglePopup}
          sendToIdm={sendToIdm}
          logReniecInfo={logReniecInfo}
          onSetSidebarFormShareholderOpen={onSetSidebarFormShareholderOpen}
          countries={countries}
          setLoading={setLoading}
          loading={loading}
          userInfo={userInfo}
        />
      </div>
    </React.Fragment>
  );
};

TableLegalsShareholders.contextTypes = {
  t: PropTypes.func.isRequired
};
RowsCompanyShareholder.contextTypes = {
  t: PropTypes.func.isRequired
};
RowsLegalRepresentative.contextTypes = {
  t: PropTypes.func.isRequired
};
RowsShareholders.contextTypes = {
  t: PropTypes.func.isRequired
};
export default TableLegalsShareholders;
