import React from 'react'
import InformationSection from '../../InformationSection/InformationSection'
import { useForm } from '../../../../hooks/formHooks';

const Cards = (props) => {
    const { allInitialSetting, editPermission, handleSubmitB89Card, context } = props

    const initialValues = { isActive: `${allInitialSetting.isActive}` }

    const resetValues = (array) => {
        let newInitialvalues = {};
        array.map(({ name }) => {
            newInitialvalues[name] = initialValues[name];
        });
        setInputs({
            ...inputs,
            ...newInitialvalues
        })
    };

    const handleSubmit = async () => {
        if(inputs.isActive === initialValues.isActive) return;
        const response = await handleSubmitB89Card();
        if (!response) {
            setInputs({
                ...inputs,
                isActive: initialValues.isActive
            })
        }
    }

    const { inputs, onInputChangeV2, setInputs } = useForm(initialValues);

    const informationB89 = {
        title: 'Tarjeta B89',
        resetValues: resetValues,
        submitData: handleSubmit,
        fields: [
            {
                label: 'Habilitado',
                name: 'isActive',
                type: 'select',
                showCheckbox: false,
                options: [
                    {
                        name: 'Si',
                        value: "true",
                    },
                    {
                        name: 'No',
                        value: "false",
                    }
                ]
            }
        ]
    };
    return (
        <div>
            <div className='card-body'>
                <InformationSection
                    data={informationB89}
                    inputs={inputs}
                    onChange={onInputChangeV2}
                    enableButtons={editPermission}
                    context={context}
                />
            </div>
        </div>
    )
}

export default Cards