import React, { useState } from 'react'
import { CustomButton } from '../../../CustomButton/CustomButton';
import { ModalLayout } from '../../../Modal/ModalLayout';

const IncorrectInformationModal = ({ showModal, setShowModal, handleSubmit, listData, defineLanguage }) => {

    const [message, setMessage] = useState('');

    const activeButton = !message.trim();

    return (
        <ModalLayout showModal={showModal} actionClose={() => setShowModal(false)} className='modal-legal' classGeneral='custom-modal-incorrect-fiels'>
            <form onSubmit={(e) => handleSubmit(e, message)}>
                <h2>¿Estas seguro en devolver los siguientes campos?</h2>
                <div className='content-list-modal'>
                    <ul>
                        {listData.map(({ label_es }, key) =>
                            <li key={key}>{label_es}</li>
                        )}
                    </ul>
                </div>
                <div>
                    <p><b>Agregar observación</b> (Esta observación llegará en el correo del cliente)</p>
                    <p><b>Idioma del mensaje:</b> {defineLanguage()}</p>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} rows="4" />
                    <div className='content-butt-modal'>
                        <CustomButton
                            type='submit'
                            text="Si, devolver campos"
                            className="green"
                            disabled={activeButton}
                            showLoader={false}
                        />
                        <CustomButton
                            type='button'
                            text="No"
                            onClick={() => setShowModal(false)}
                            className="red"
                        />
                    </div>
                </div>
            </form>
        </ModalLayout>
    )
}

export default IncorrectInformationModal