import React, { useEffect, useState } from 'react'
import close from "../../../../images/new_icons/close-circle.svg";
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns'
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable'
import { useHistory, useParams } from 'react-router-dom';
import isEmpty from 'is-empty';
import { ConsolidatedReport } from '../../../../components/FallComponents/ReportPage/ConsolidatedReport';
import { connect } from 'react-redux';
import { DetailedReport } from '../../../../components/FallComponents/ReportPage/DetailedReport';
import ReportTrades from "../ReportTrades/ReportTrades"
import SpreadReport from "../SpreadReport/SpreadReport";
import AffiliateReport from "../Affiliate/AffiliateReport"
import ReportWithdraws from "../ReportWithdraws/ReportWithdraws"
import ReportDeposit from '../ReportDeposits/ReportDeposit';

const ReportTypePage = (props) => {

    const { userInfo, decimalPlaces } = props;

    const params = useParams();
    const history = useHistory()

    const [withRightSection, setWithRightSection] = useState(false)
    const [report, setRepost] = useState({
        typeId: '',
        leftComponent: <></>,
        rightComponent: <></>
    })

    const headerOptions = [
        {
            image: close,
            alt: "close option",
            action: () => { setWithRightSection(false); }
        },
    ];

    const reportList = [
        {
            id: 'reportes',
            types: [
                {
                    typeId: 'reporte-consolidado',
                    leftComponent: <ConsolidatedReport userInfo={userInfo} decimalPlaces={decimalPlaces}/>,
                    rightComponent: <></>
                },
                {
                    typeId: 'reporte-detallado',
                    leftComponent: <DetailedReport userInfo={userInfo} decimalPlaces={decimalPlaces}/>,
                    rightComponent: <></>
                },
                {
                    typeId: 'reporte-de-trade',
                    leftComponent: <ReportTrades />,
                    rightComponent: <></>
                },
                {
                    typeId: 'reporte-de-spread',
                    leftComponent: <SpreadReport />,
                    rightComponent: <></>
                },
                {
                    typeId: 'comisión-por-referidos',
                    leftComponent: <AffiliateReport />,
                    rightComponent: <></>
                },
                {
                    typeId: 'reporte-de-deposito',
                    leftComponent: <ReportDeposit />,
                    rightComponent: <></>
                },
                {
                    typeId: 'reporte-de-retiro',
                    leftComponent: <ReportWithdraws />,
                    rightComponent: <></>
                }
            ]
        }
    ]

    useEffect(() => {
        try {
            const report = reportList.find(element => element.id === params.cardId)
            const components = report.types.find(element => element.typeId === params.itemId)
            if (isEmpty(components)) throw "";
            setRepost(components)
        } catch (error) {
            return history.push("/reporte-general")
        }
    }, [])

    return (
        <div className="beta-content-container">
            <ResizableColumns
                withRightSection={withRightSection}
                minWidth='650px'
                childrenOne={report.leftComponent}
                childrenTwo={
                    <RightResizable
                        loadingData={{ isLoading: false }}
                        headerOptions={headerOptions}
                    >
                        {report.rightComponent}
                    </RightResizable >
                }
            />
        </div >
    )
}

const mapStateToProps = ({ user: { userInfo }, product: { decimalPlaces } }) => {
    return { userInfo, decimalPlaces };
};

export default connect(mapStateToProps)(ReportTypePage);