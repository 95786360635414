export const levelsNames = {
    "0":"Sin verificar",
    "10":"Explorador",
    "11":"Amateur",
    "12":"Intermedio",
    "13":"Avanzado",
    "14":"Maestro",
    "17":"Corporativo sin verificar",
    "18":"Corporativo verificado",
    "100":"Coink sin verificar",
    "101":"Coink verificado"
}