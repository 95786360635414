import isEmpty from 'is-empty'

export const getTotal = data => {
  const rows = [...data]
  let totalDebits = [
    {
      currency: 'PEN',
      total: 0
    },
    {
      currency: 'USD',
      total: 0
    }
  ];

  let totalCredits = [
    {
      currency: 'PEN',
      total: 0
    },
    {
      currency: 'USD',
      total: 0
    }
  ];
    let maxRows = 0;
    
    if(isEmpty(rows)) return []
    
    rows.forEach(r => {
      maxRows++;
      r.number = maxRows;

      if (r.transactionType === 'DEBIT') {
        const currentDebitsIndex = totalDebits.findIndex(
          t => t.currency === r.currency
        );

        if(isEmpty(totalDebits[currentDebitsIndex])){
          totalDebits[currentDebitsIndex] = { total : 0}
        }

        const amountDebit =
          r.transactionAmount < 0
            ? r.transactionAmount * -1
            : r.transactionAmount;
        totalDebits[currentDebitsIndex].total =
          totalDebits[currentDebitsIndex].total + amountDebit;
      }

      if (r.transactionType === 'CREDIT') {
        const currentCreditsIndex = totalDebits.findIndex(
          t => t.currency === r.currency
        );
        if(isEmpty(totalCredits[currentCreditsIndex])){
          totalCredits[currentCreditsIndex] = { total : 0}
        }
        const amountCredit =
          r.transactionAmount < 0
            ? r.transactionAmount * -1
            : r.transactionAmount;
        totalCredits[currentCreditsIndex].total =
          totalCredits[currentCreditsIndex].total + amountCredit;
      }
    });

    rows.reverse();

    for (let t in totalCredits) {
      let totalCredit = totalCredits[t];
      let totalDebit = totalDebits[t];

      let row = {
        id: '',
        status: null,
        created: '',
        transactionDate: '',
        bankCode: '',
        bankName: '',
        claimDate: null,
        claimDetails: null,
        created: '',
        currency: totalCredit.currency,
        number: null,
        payload: `TOTAL ${totalCredit.currency}`,
        sourceBankAccountNumber: '',
        sourceClientName: '',
        transactionAmount: 0,
        transactionAmountCredit: totalCredit.total,
        transactionAmountDebit: totalDebit.total,
        transactionDate: '',
        transactionReference: '',
        transactionType: 'TOTAL'
      };

      if (
        !isEmpty(row.transactionAmountCredit) ||
        !isEmpty(row.transactionAmountDebit)
      ) {
        rows.push(row);
      }
    }
    return {totalCredits,totalDebits};
  };

