import React, { useContext, useState } from 'react';
import isEmpty from 'is-empty';
import { actionTypes } from '../../../../constants';
import { Context } from '../../../../pages/FallPages/Customers/Natural/ProviderLevels';
import Table from '../../Table/Table';
import DateHelper from '../../../../helpers/Date.helper';

export const History = () => {
  const { logs } = useContext(Context);

  const handleDescription = (type, description) => {
    const typeSplit = {
      EDIT_FIELDS: 'Información del KYC editada ',
      RETURNED_FIELDS: ':'
    };
    let title = '';
    let detail = '';
    const text = description.split(typeSplit[type]);
    if (type === 'RETURNED_FIELDS' || type === 'EDIT_FIELDS') {
      const status = (type === "RETURNED_FIELDS");
      title = status ? text[0].trim() : typeSplit[type];
      detail = text[1] ? text[1].slice(status ? 0 : 1, -1) : "";
      return <ShowMoreDetails title={title} information={detail} />
    }
    return <p>{description.replaceAll('DIG_iD_VERIF','ACUANT')}</p>
  };

  const handleSelectActionType = (data) => actionTypes.find(action => data.type === action.id).name
  const headers = [
    {
      tag: 'pos',
      title: '#',
      className: 'minimizer2'
    },
    {
      tag: 'type',
      title: 'Acción',
      cell: ({ data }) => <p>{handleSelectActionType(data)}</p>
    },
    {
      tag: 'description',
      title: 'Descripción',
      cell: ({ data }) => handleDescription(data.type, data.description)
    },
    {
      tag: 'executeByName',
      title: 'Ejecutado por'
    },
    {
      tag: 'createdAt',
      title: 'Fecha',
      cell: ({ data }) => DateHelper.formatDate(data.createdAt)
    }
  ];

  return (
    <div style={{ position: 'relative' }}>
      <div className="table-wrapper">
        <Table showTooltipToCopy={false} rowHead={headers} dataBody={logs} />
      </div>
    </div>
  );
};

const ShowMoreDetails = ({ title = '', information = '' }) => {
  const [show, setShow] = useState(false);
  return (
    <div className='show-mode-details'>
      <p>{title} |<span className='text-primary' onClick={() => setShow(!show)}>{`${show ? ' Ocultar' : ' Mostrar'}`} detalles</span></p>
      {(show && !isEmpty(information)) && <p>{information}</p>}
    </div>
  )
}