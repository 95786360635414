import React from 'react'
import HeaderPage from '../HeaderPage/HeaderPage'
import CardConfiguration from './GeneralconfigSections/CardConfiguration'

const AllGeneralConfigPage = (props) => {

    const { editPermission, loaderInitialCard, allInitialSetting, handleSubmitB89Card } = props;

    return (
        <div className='all-page-data'>
            <HeaderPage
                title="Configuración General"
                subTitle="Tarjetas / Configuración General"
            />
            <div className='global-config-data'>
                <div className='content-config-data'>
                    <CardConfiguration
                        editPermission={editPermission}
                        loaderInitialCard={loaderInitialCard}
                        allInitialSetting={allInitialSetting}
                        handleSubmitB89Card={handleSubmitB89Card}
                    />
                </div>
            </div>
        </div>
    )
}

export default AllGeneralConfigPage