import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getSpreadUsdc } from "../FallServices/SpreadServices";

class SpreadController {
   static getSpreadUsdcByDate = async ({
      userInfo,
      filterSearch,
      setRows,
      setLoading,
   }) => {
      try {
         setLoading(true);
         const response = await getSpreadUsdc(
            userInfo.UserId,
            filterSearch
         );
         if (response.status !== 200) return false
         const data = response.data.data.results || [];
         if (isEmpty(data)) {
            ComponentsHelper.toast({
               type: toastTypes.warn,
               message: "No se encontraron registros",
            });
         } else {
            const newData = data.map((e) => {
               return {
                  ...e,
                  bnxPrice: Number(e.bnxPrice),
                  bnxQuantity: Number(e.bnxQuantity),
                  creditAmount: Number(e.creditAmount),
                  debitAmount: Number(e.debitAmount),
                  spread: Number(e.spread),
                  fee: Number(e.fee),
               }
            })
            setRows(newData);
         }
         return true
      } catch (error) {
         setRows([])
         ComponentsHelper.toast({
            type: toastTypes.warn,
            message: "La información no pudo ser accesada",
         });
         return false;
      } finally {
         setLoading(false);
      }
   };
}
export default SpreadController;
