import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import DepositTemplateFormComponent from './DepositTemplateFormComponent';
import DepositWorkflowsContainer from './DepositWorkflows/DepositWorkflowsContainer';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import APSelect from 'apex-web/lib/components/common/APSelect';

import './DepositFormComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClasses = getBEMClasses('deposit-form');
var slidePaneClasses = getBEMClasses('slide-pane');

var DepositFormComponent = (function(_React$Component) {
  _inherits(DepositFormComponent, _React$Component);

  function DepositFormComponent() {
    _classCallCheck(this, DepositFormComponent);

    return _possibleConstructorReturn(
      this,
      (
        DepositFormComponent.__proto__ ||
        Object.getPrototypeOf(DepositFormComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(DepositFormComponent, [
    {
      key: 'componentWillMount',
      value: function componentWillMount() {
        var _props = this.props,
          selectDepositProduct = _props.selectDepositProduct,
          product = _props.product;

        selectDepositProduct(product.ProductId);
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props2 = this.props,
          handleSubmit = _props2.handleSubmit,
          submitting = _props2.submitting,
          deposit = _props2.deposit,
          product = _props2.product,
          changeFieldValue = _props2.changeFieldValue,
          selectDepositTemplate = _props2.selectDepositTemplate,
          isDasc = _props2.isDasc;

        var showTemplateTypeSelect =
          deposit.templateTypes.length > 1 &&
          !Object.keys(deposit.templateInfo).length &&
          !deposit.isLoading;
        var templateTypes = [{ value: '', label: 'Select an option' }].concat(
          _toConsumableArray(
            deposit.templateTypes.map(function(template) {
              return {
                value: template.TemplateName,
                label: template.TemplateName
              };
            })
          )
        );
        var useTemplateDepositWorkflow =
          deposit.depositInfo && !!deposit.depositInfo.length;

        if (deposit.depositInfo.length && isDasc) {
          var dataArr = deposit.depositInfo[0].split('?dt=');
          deposit.template = { exchangeAddress: dataArr[0], memo: dataArr[1] };

          setTimeout(function() {
            //update data outside render
            changeFieldValue(
              'exchangeAddress',
              deposit.template.exchangeAddress
            );
            changeFieldValue('memo', deposit.template.memo);
          });
        }

        return React.createElement(
          React.Fragment,
          null,
          React.createElement(DepositWorkflowsContainer, {
            submitting: submitting,
            onSubmit: handleSubmit
          }),
          deposit.isLoading
            ? React.createElement(Spinner, { customClass: baseClasses })
            : React.createElement(
                React.Fragment,
                null,
                deposit.error &&
                  React.createElement(
                    'p',
                    { className: baseClasses('error') },
                    this.context.t(deposit.error)
                  ),
                useTemplateDepositWorkflow &&
                  React.createElement(
                    'form',
                    {
                      className: slidePaneClasses('body'),
                      onSubmit: handleSubmit
                    },
                    React.createElement(
                      'div',
                      { className: baseClasses('form-body') },
                      showTemplateTypeSelect &&
                        React.createElement(
                          React.Fragment,
                          null,
                          React.createElement(
                            'p',
                            null,
                            this.context.t(
                              'Select an option to continue the deposit process'
                            )
                          ),
                          React.createElement(APSelect, {
                            name: 'TemplateType',
                            customClass: baseClasses(),
                            label: this.context.t('Options for deposit'),
                            onSelect: function onSelect(value) {
                              return selectDepositTemplate(
                                product.ProductId,
                                value
                              );
                            },
                            options: templateTypes
                          })
                        ),
                      !deposit.depositInfo.length &&
                        React.createElement(DepositTemplateFormComponent, {
                          product: product,
                          template: deposit.template,
                          submitting: submitting,
                          baseClasses: baseClasses
                        })
                    )
                  )
              )
        );
      }
    }
  ]);

  return DepositFormComponent;
})(React.Component);

DepositFormComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  }
};

DepositFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  selectDepositProduct: PropTypes.func.isRequired,
  showSnack: PropTypes.func,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

DepositFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositFormComponent;
