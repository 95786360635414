import empty from 'is-empty';
import queryString from 'query-string';
import HandlerBackend from '../helpers/HandlerBackend';

export function buildSecureGetParams(params) {
  try {
    const result = queryString.stringify(params);
    return btoa(result);
  } catch (error) {
    console.error('Error: buildSecureGetParams :: ', error);
    return '';
  }
}

export const currencyFormat = (currency, amount) => {
  return `${currency} ${amount
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const currencyAssetNames = [
  {
    assetName: 'Peruvian Sol',
    currency: 'PEN'
  },
  {
    assetName: 'United States Dollar',
    currency: 'USD'
  },
  {
    assetName: 'BTC',
    currency: 'BTC'
  }
];

export const banksAccountBanexcoin = [
  {
    bank: 'INTERBANK-PE',
    currency: 'USD',
    bankAccount: '108-3001943080'
  },
  {
    bank: 'INTERBANK-PE',
    currency: 'PEN',
    bankAccount: '108-3001943073'
  },
  {
    bank: 'SCOTIA-PE',
    currency: 'USD',
    bankAccount: '0004969054'
  },
  {
    bank: 'SCOTIA-PE',
    currency: 'PEN',
    bankAccount: '0007962010'
  },
  {
    bank: 'BBVA-PE',
    currency: 'PEN',
    bankAccount: '001101830100128469'
  },
  {
    bank: 'BBVA-PE',
    currency: 'USD',
    bankAccount: '001101830100128477'
  },
  {
    bank: 'COMERCIO-PE',
    currency: 'PEN',
    bankAccount: '410201298409'
  },
  {
    bank: 'COMERCIO-PE',
    currency: 'USD',
    bankAccount: '410201298393'
  },
  {
    bank: 'ALFIN-PE',
    currency: 'PEN',
    bankAccount: '01770884700001'
  },
  {
    bank: 'ALFIN-PE',
    currency: 'USD',
    bankAccount: '01770884700002'
  },
  {
    bank: 'BCP-PE',
    currency: 'PEN',
    bankAccount: '1933252040088'
  },
  {
    bank: 'BCP-PE',
    currency: 'USD',
    bankAccount: '1932153983112'
  }
];

export function getSecureGetParams(params) {
  try {
    const result = queryString.parse(atob(params));
    return result;
  } catch (error) {
    console.error('Error: getSecureGetParams::', error);
    return {};
  }
}

export async function getCurrentIpClient(userId) {
  let path = `/api/ip`;
  let result = await HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
  let ipClient = !empty(result.data) ? result.data.ip : null;
  return ipClient;
}

export async function validateIp(userId) {
  let result = false;
  const ipv4 = (await getCurrentIpClient(userId)) || '';
  const allowIps = ['209.45.51.218', '132.251.194.138', '132.251.195.10'];

  if (allowIps.includes(ipv4) || window.location.hostname == 'localhost') {
    result = true;
  } else {
    console.log('Invalid IP ::::', ipv4);
  }

  return result;
}

export function formatNumber(currency, num) {
  num = parseFloat(num);
  return (
    currency + ' ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
}

export function fixedNumbers(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

export function jsonClean(jsonText) {
  let jsonCleaned = {};
  try {
    if (!empty(jsonText)) {
      jsonCleaned = JSON.parse(jsonText.replace(/\t/g, ''));
    }
  } catch (error) {
    console.log('error on jsonClean', error);
  }
  return jsonCleaned;
}

export function fixStorageLink(originalLink) {
  try {
    if (!empty(originalLink)) {
      let failedPrelink = 'sgp1.digitaloceanspaces.com/banexcoin';
      let containts = originalLink.includes(failedPrelink);
      if (containts) {
        let res = originalLink.replace(
          failedPrelink,
          'https://banexcoin.sgp1.digitaloceanspaces.com'
        );
        return res;
      } else {
        return originalLink;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error on fixStorageLink', error);
  }
}

export function getFilename(url) {
  try {
    if (url) {
      return decodeURI(url.split('/').pop());
    }
    return '';
  } catch (error) {
    console.log('Error on GetFilename', error);
  }
}

export function checkIsImage(url) {
  let result = false;
  try {
    const filetypes = /jpeg|jpg|png|gif/;
    const extname = filetypes.test(url.toLowerCase());
    result = extname;
  } catch (error) {
    console.log('Error on checkIsImage', error);
    throw error;
  }
  return result;
}

export function txidToLink(txid, symbol) {
  let result = '';
  try {
    let txidQuery = [
      {
        assetSymbol: 'BTC',
        link: `https://mempool.space/es/tx/${txid}`,
        providerName: 'Blackchair'
      },
      {
        assetSymbol: 'USDC',
        link: `https://etherscan.io/tx/${txid}`,
        providerName: 'Etherscan'
      }
    ];
    let found = txidQuery.find(t => t.assetSymbol === symbol);
    result = !empty(found) ? found.link : '';
  } catch (error) {
    console.log('Error on txidToLink', error);
    throw error;
  }
  return result;
}

export function rejectStatus(currentStatus) {
  let returnStatus = [
    {
      currentStatus: 'Pending2FA',
      cancelStatus: 'Rejected',
      requestReason: true
    },
    {
      currentStatus: 'New',
      cancelStatus: 'Rejected',
      requestReason: true
    },
    {
      currentStatus: 'Submitted',
      cancelStatus: 'AdminCancelled',
      requestReason: false
    },
    {
      currentStatus: 'AmlFailed',
      cancelStatus: 'Rejected',
      requestReason: true
    },
    {
      currentStatus: 'Failed',
      cancelStatus: 'AdminCancelled',
      requestReason: false
    }
  ];
  let defaultStatus = {
    currentStatus: 'Unknown',
    cancelStatus: 'Rejected',
    requestReason: true
  };
  let found = returnStatus.find(s => s.currentStatus === currentStatus);
  return !empty(found) ? found : defaultStatus;
}
