import React from 'react'
import ConfirmModal from '../../../../ConfirmModal/ConfirmModal'

const ConfirmAuthorizedModal = ({ showModal, handleToggleModal, titleModal, messageModal, handleUpdate }) => {
    return (
        <ConfirmModal
            showModal={showModal}
            handleToggleModal={handleToggleModal}
            title={titleModal}
            message={messageModal}
            confirmButtonProps={{
                onClick: () => handleUpdate(),
                text: "Si"
            }}
            cancelButtonProps={{
                text: "No",
            }}
        />
    )
}

export default ConfirmAuthorizedModal