import React from 'react'
import isEmpty from "is-empty";

export const Textarea = ({
  error,
  context,
  title,
  name,
  value,
  onChange,
  disabled,
  required,
  placeholder,
}) => {
  return (
    <div className={`textarea-container ${!isEmpty(error) ? "error" : ""}`}>
      <span>{context.t(title)}</span>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
      ></textarea>
    </div>
  );
};
