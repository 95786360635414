import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { createRestrictedDomain, deleteRestrictedDomain, getAllRestrictedDomains, updateRestrictedDomain } from "../FallServices/RestrictedDomainsServices"

class RestrictedDomainsController {
	static getAll = async ({ userInfo, setLoading, filterSearch, filterConfig, setRows, setTotalRows }) => {
		try {
			setLoading(true)
			const data = { ...filterConfig, ...filterSearch }
			const response = await getAllRestrictedDomains(userInfo.UserId, data);
			if (response.status === 200 && response.data) {
				const sortedByupdatedAt = response.data.data.results.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
				setRows(sortedByupdatedAt)
				setTotalRows(response.data.data.info.totalCount)
				return true
			}
			return false
		} catch (error) {
			ComponentsHelper.toast({
				type: toastTypes.warn,
				message: "Hubo un problema al intentar obtener la información. Por favor, vuelva a intentarlo.",
			});
			return false;
		} finally {
			setLoading(false);
		}
	}
	static async create({ userInfo, setLoadingAction, data, setErrors }) {
		try {
			setLoadingAction(true)
			const response = await createRestrictedDomain(userInfo.UserId, { domain: data.domain });
			if (response.status === 201) {
				ComponentsHelper.toast({
					type: toastTypes.success,
					message: "El dominio ha sido creado exitosamente.",
				});
				return true
			}
			return false
		} catch (error) {
			if (error && error.response && error.response.data) {
				setErrors({
					domain: "Este dominio ya fue registrado."
				})
			}
			return false;
		} finally {
			setLoadingAction(false)
		}
	}
	static async update({ userInfo, setLoadingAction, data, id }) {
		try {
			setLoadingAction(true)
			const response = await updateRestrictedDomain(userInfo.UserId, id, data);
			if (response.status === 200) {
				ComponentsHelper.toast({
					type: toastTypes.success,
					message: "El dominio ha sido editado exitosamente.",
				});
				return true
			}
			return false
		} catch (error) {
			ComponentsHelper.toast({
				type: toastTypes.warn,
				message: "Hubo un problema al intentar editar el dominio. Por favor, vuelva a intentarlo.",
			});
			return false;
		} finally {
			setLoadingAction(false);
		}
	}
	static async delete({ userInfo, setLoadingAction, id }) {
		try {
			setLoadingAction(true)
			const response = await deleteRestrictedDomain(userInfo.UserId, id);
			if (response.status === 200) {
				ComponentsHelper.toast({
					type: toastTypes.success,
					message: "El dominio ha sido eliminado exitosamente.",
				});
				return true
			}
			return false
		} catch (error) {
			ComponentsHelper.toast({
				type: toastTypes.warn,
				message: "Hubo un problema al intentar eliminar el dominio. Por favor, vuelva a intentarlo.",
			});
			return false;
		} finally {
			setLoadingAction(false);
		}
	}

}

export default RestrictedDomainsController;