import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modal.css";
import SelectRowsForm from "./SelectRowsForm";

const CustomRowsDownloadModal = (props, context) => {
  const {
    showModal,
    handleToggleModal,
    title,
    rows
  } = props;
  return (
    <Modal className="custom-modal-container add-rates" isOpen={showModal}>
      <ModalHeader charCode="X" toggle={handleToggleModal}>
        {title}
      </ModalHeader>
      <ModalBody>
       <SelectRowsForm handleToggleModal={handleToggleModal} rows={rows}/>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
CustomRowsDownloadModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(CustomRowsDownloadModal);
