import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";

const AppPagination = (context) => {
  return (
    <React.Fragment>
      <div className="app-pagination">
        <Pagination aria-label="Page navigation example" size="lg">  
          <PaginationItem active>
            <PaginationLink href="#">1</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">2</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">3</PaginationLink>
          </PaginationItem>
          <PaginationItem disabled>
            <PaginationLink href="#" previous disabled/>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" next />
          </PaginationItem>
        </Pagination>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo },
}) => {
  return { selectedAccountId, accounts, userInfo };
};
AppPagination.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(AppPagination);
