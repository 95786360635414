import isEmpty from 'is-empty';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CustomButton } from '../../../CustomButton/CustomButton';
import InputElement from '../../../InputElement/InputElement';

const CreateCategoryModal = ({ showModal, handleToggleModal, handleCreateCategory, context }) => {

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');

    const assetNameElement = {
        values: {
            title: 'Nombre de categoría',
            name: 'name',
            value: name,
            type: 'text',
            required: true
        },
        actions: {
            onChange: (data) => setName(data.value)
        },
        error: errors.name
    };

    const customError = () => {
        let errors = {};
        if (isEmpty(name) || !name.trim()) errors.name = 'Campo requerido';
        setErrors(errors);
        return errors;
    }

    const closeModal = () => {
        setName('');
        setErrors({});
        setMessage('');
        handleToggleModal();
    }

    const submitCreateCategory = async () => {
        setMessage('');
        const errors = customError();
        if (!isEmpty(errors)) return;
        setLoading(true);
        const response = await handleCreateCategory(name, setMessage);
        setLoading(false);
        if (response) closeModal();
    }

    return <Modal className='modal-general-v2' isOpen={showModal}>
        <ModalHeader charCode="X" toggle={closeModal}>
            Creación de categoría
        </ModalHeader>
        <ModalBody>
            <div className='body-content-modal'>
                <InputElement {...assetNameElement} />
                {!isEmpty(message) && <p className='text-red'>{context.t(message)}</p>}
                <div className=''>
                    <CustomButton
                        onClick={closeModal}
                        className="red"
                        text="Cancelar"
                        showLoader={false}
                        disabled={loading}
                    />
                    <CustomButton
                        onClick={submitCreateCategory}
                        className="purple"
                        text="Añadir categoría"
                        disabled={loading}
                    />
                </div>
            </div>
        </ModalBody>
    </Modal>
}

export default CreateCategoryModal