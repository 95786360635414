import FileSaver from "file-saver";
import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { exportCSV, getAcuantLogs } from "../FallServices/AcuantLogsServices";

class AcuantLogsController {
  static getAllAcuantLogs = async ({
    userInfo,
    filterConfig,
    filterSearch,
    setRows,
    setTotalRows,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const response = await getAcuantLogs(
        userInfo.UserId,
        filterConfig,
        filterSearch
      );
      if (!isEmpty(response) && !isEmpty(response.data)) {
        setRows(response.data.data.nodes);
        setTotalRows(response.data.data.totalCount);
      }
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser accesada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static downloadRows = async ({ userInfo, filterConfig, filterSearch }) => {
    try {
      ComponentsHelper.toast({
        type: toastTypes.info,
        message: "Descargando",
      });
      const response = await exportCSV(
        userInfo.UserId,
        filterConfig,
        filterSearch
      );
      if (!isEmpty(response) && !isEmpty(response.data)) {
        const csvData = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        FileSaver.saveAs(csvData, "acuant_logs.csv");
      }
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser descargada",
      });
      return false;
    }
  };
}

export default AcuantLogsController;
