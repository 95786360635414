import UtilsHelper from '../../helpers/Utils.helper';

export const getLastRates = async userId => {
  const path = '/api/currency/rates/lastest';
  return UtilsHelper.sendRequest({
    method: 'GET',
    userId,
    path
  });
};

export const addCurrency = async (userId, data) => {
  const path = '/api/currency/rate';
  return UtilsHelper.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
};

export const getHistoricalRates = async (userId,params) => {
    const path = '/api/currency/rate/get';
    const configRequest = {
      method: 'POST',
      userId,
      path,
      data: params
    };
    return await UtilsHelper.sendRequest(configRequest);
};
