import React from 'react'
import { Form } from 'react-bootstrap'
import { FormFeedback } from 'reactstrap'
const FormGroup = ({ editPermission = true, labelProps, inputProps, error, children }) => {
    const {
      className = "",
      name, disabled = false,
      value,
      onChange = () => { },
      ...restInputProps
    } = inputProps
    const { text, ...restLabelProps } = labelProps
    return (
      <Form.Group>
        <Form.Label {...restLabelProps} className='form-title'>
          {text}
        </Form.Label>
        {editPermission ? 
          <React.Fragment>
            <Form.Control
              className={`input-color ${className}`}
              name={name}
              disabled={disabled}
              value={value}
              onChange={onChange}
              {...restInputProps}
            >
              {children && children}
            </Form.Control>
            <FormFeedback>{error && error}
            </FormFeedback>
          </React.Fragment> :
          <p className='form-text'>{value || '-'}</p>
        }
      </Form.Group>
    )
  }

  export default FormGroup