import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResizableColumns from "../../../components/FallComponents/ResizableColumns/ResizableColumns";
import { useFilter } from "../../../hooks/useFilter";
import isEmpty from "is-empty";
import AllSeriesCorrelativeData from "../../../components/FallComponents/SeriesCorrelativePage/AllSeriesCorrelativeData";
import SeriesCorrelativesController from "../../FallControllers/SeriesCorrelativeController";
import { useModal } from "../../../hooks/useModal";
import AddSerieCorrelativeModal from '../../../components/FallComponents/SeriesCorrelativePage/Modal'
const SerieCorrelativePage = (props, context) => {
  const { userInfo } = props;
  const {showModal:showAddModal, handleToggleModal: handleAddToggleModal} = useModal();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    onChangeLimit,
  } = useFilter({
    filterConfigUrl: {
      limit: 100,
      page: 1,
    },
  });

  const getAllSeriesCorrelative = () => {
    SeriesCorrelativesController.getAllSeriesCorrelatives({
      userInfo,
      setLoading,
      setRows,
    });
  };

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getAllSeriesCorrelative();
  }, [userInfo, filterConfig,filterSearch]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        childrenOne={
          <AllSeriesCorrelativeData
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            userInfo={userInfo}
            setRows={setRows}
            getAllSeriesCorrelative={getAllSeriesCorrelative}
            handleAddToggleModal={handleAddToggleModal}
          />
        }
      />
      {showAddModal && (
        <AddSerieCorrelativeModal
          showModal={showAddModal}
          handleToggleModal={handleAddToggleModal}
          getAllSeriesCorrelative={getAllSeriesCorrelative}
          title="Agregar serie y correlativo"
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

SerieCorrelativePage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(SerieCorrelativePage);
