export const userStatus = [
  {
    value: "0",
    name: "Activo",
  },
  {
    value: "1",
    name: "Bloqueado",
  },
  {
    value: "2",
    name: "Borrado",
  },
  {
    value: "3",
    name: "Inactivo",
  },
];
