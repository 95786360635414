import React, { useState } from 'react';
import HeaderPage from '../HeaderPage/HeaderPage';
import TableContainer from '../Table/TableContainer';
import RotateRight from '../../../images/new_icons/rotate-right.svg';
import Setting from '../../../images/new_icons/setting.svg';
import SettingLight from '../../../images/new_icons/setting-light.svg';
import { GroupFilters } from '../Filters/Filters';
import { useForm } from '../../../hooks/formHooks';
import DateHelper from '../../../helpers/Date.helper'
import AddIcon from "../../../images/add-icon.svg";
import Download from '../../../images/new_icons/download.svg';
import { Switch } from '../Switch/Switch';

const RestrictedDomains = props => {
  const {
    withRightSection,
    onChangeLimit,
    filterConfig,
    totalRows,
    rows,
    loading,
    offset,
    showingTo,
    changePage,
    setFilterSearch,
    resetFilters,
    handleSelectRow,
    context,
    selectedId,
    setWithRightSection,
    handleToggleAddModal,
    handleToggleStatus: handleToggleStatusProps,
    downloadExcelFormat,
  } = props;

  const [showFilters, setShowFilters] = useState(false);

  const filterInitialValues = {
    name: ''
  }

  const { inputs: inputsFilters, onInputChangeV2, setInputs } = useForm(
    filterInitialValues,
    handleSearchFilters
  );

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const CheckboxStatus = ({ data }) => {
    const handleToggleStatus = () => {
      handleToggleStatusProps(data.id);
    };
    return (
      <Switch
        defaultChecked={data.status}
        onChange={handleToggleStatus}
        id={`switch-toggle-bnx-account-${data.id}`}
      />
    );
  };


  const handleSearchFilters = () => {
    Object.keys(inputsFilters).forEach(element => {
      inputsFilters[element] === '' && delete inputsFilters[element];
    });
    setFilterSearch(inputsFilters);
  };

  const handleResetFilters = () => {
    setInputs(filterInitialValues);
    setWithRightSection(false)
    resetFilters();
  };

  const headerOptions = [
    {
      icon: showFilters ? SettingLight : Setting,
      action: handleShowFilters,
      className: showFilters ? 'header-option-active' : ''
    },
    {
      icon: AddIcon,
      action: handleToggleAddModal,
    },
    {
      icon: Download,
      action: () => downloadExcelFormat(tableHeaders)
    },
    {
      icon: RotateRight,
      action: handleResetFilters
    }
  ];


  const tableHeaders = [
    {
      tag: 'createdAt',
      title: 'Fecha de creación',
      handleSelectRow,
      cell: ({ data }) => <p> {DateHelper.formatDate(data.createdAt)}</p>
    },
    {
      tag: 'updatedAt',
      title: 'Fecha de actualización',
      handleSelectRow,
      cell: ({ data }) => <p> {DateHelper.formatDate(data.updatedAt)}</p>
    },
    {
      tag: 'domain',
      title: 'Dominio',
      handleSelectRow,

    },
    {
      tag: 'status',
      title: 'Restringido',
      cell: ({ data }) => <CheckboxStatus data={data} />
    },
  ];

  const inputs = [
    {
      values: {
        title: "Dominio",
        name: "name",
        value: inputsFilters.name,
        type: "text",
        required: true,
      },
      actions: {
        onChange: onInputChangeV2,
      },
    }
  ];


  return (
    <div className="all-withdraw-data">
      <HeaderPage
        title={context.t("Dominios restringidos")}
        subTitle="Seguridad / Dominios restringidos"
        headerOptions={headerOptions}
      />
      <TableContainer
        onChangeLimit={onChangeLimit}
        filterConfig={filterConfig}
        totalRows={totalRows}
        rows={rows}
        loading={loading}
        offset={offset}
        showingTo={showingTo}
        changePage={changePage}
        headers={tableHeaders}
        rowIdSelected={selectedId}>
        {showFilters && (
          <GroupFilters
            handleSearchFilters={handleSearchFilters}
            withRightSection={withRightSection}
            inputs={inputs}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default RestrictedDomains;
