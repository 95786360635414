import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HeaderPage from "../HeaderPage/HeaderPage";
import TableContainer from "../Table/TableContainer";
import RotateRight from "../../../images/new_icons/rotate-right.svg";
import Setting from "../../../images/new_icons/setting.svg";
import SettingLight from "../../../images/new_icons/setting-light.svg";
import Download from '../../../images/new_icons/download.svg';
import { GroupFilters } from "../Filters/Filters";
import { toastTypes } from "../../../constants";
import xlsx from "json-as-xlsx";
import ComponentsHelper from "../../../helpers/Components.helper";
import isEmpty from "is-empty";
import DateHelper from "../../../helpers/Date.helper";
import moment from "moment";
import { spreadHeaders } from "../../../constants/components/Reports/spreadHeaders";

const AllSpreadUsdcData = (props, context) => {
   const {
      rows,
      loading,
      setFilterSearch,
      resetData
   } = props;
   const initialState = {
      from: "",
      to: "",
   }
   const [filterInputs, setFilterInputs] = useState(initialState);
   const [showFilters, setShowFilters] = useState(true);
   const handleShowFilters = () => setShowFilters(!showFilters);

   const handleOnChange = (target) => {
      setFilterInputs({
         ...filterInputs,
         [target.name]: target.value,
      });
   };

   const headerOptions = [
      {
         icon: showFilters ? SettingLight : Setting,
         action: handleShowFilters,
         className: showFilters ? "header-option-active" : "",
      },
      {
         icon: Download,
         action: () => downloadNewExcelFormat()
      },
      {
         icon: RotateRight,
         action: () => {
            setFilterInputs(initialState)
            resetData()
         },
      },
   ];

   const handleSearchFilters = () => {
      if (isEmpty(filterInputs.to) || isEmpty(filterInputs.from)) {
         return ComponentsHelper.toast({ type: toastTypes.warn, message: "Ingrese un rango de fechas adecuado" })
      }
      setFilterSearch(filterInputs)
   };

   const downloadNewExcelFormat = () => {
      if(isEmpty(rows)) return;
      const columns = spreadHeaders.map(item => ({ value: item.tag, label: item.title }))
      const data = [
         {
            sheet: "Datos",
            columns,
            content: rows
         }
      ];
      const settings = {
         fileName: `Spread_USDC_${DateHelper.formatDateToYear(filterInputs.from)}_${DateHelper.formatDateToYear(filterInputs.to)}`,
         extraLength: 10,
         writeOptions: {}
      };
      xlsx(data, settings)
   }

   const filters = [
      {
         values: {
            title: "Fecha de inicio",
            name: "from",
            value: filterInputs.from,
            type: "date",
            placeholder: "Seleccione una fecha",
            required: true,
            max: moment().format('YYYY-MM-DD'),
         },
         actions: {
            onChange: (e) => {
               setFilterInputs({
                  from: e.value,
                  to: '',
               });
            },
         },
      },
      {
         values: {
            title: "Fecha de fin",
            name: "to",
            value: filterInputs.to,
            type: "date",
            placeholder: "Seleccione una fecha",
            required: true,
            max: moment().format('YYYY-MM-DD'),
            min: filterInputs.from
         },
         actions: {
            onChange: handleOnChange,
         },
      },
   ];

   return (
      <div className="all-container-data-admin-v2">
         <HeaderPage
            title={context.t("Reporte de Spread USDC")}
            subTitle="Reportes / Reporte de Spread USDC"
            headerOptions={headerOptions}
         />
         <TableContainer
            rows={rows}
            loading={loading}
            headers={spreadHeaders}
            rowIdSelected={" "}
            enablePagination={false}
         >
            {showFilters && (
               <GroupFilters
                  inputs={filters}
                  handleSearchFilters={handleSearchFilters}
               />
            )}
         </TableContainer>
      </div>
   );
};

const mapStateToProps = ({ user: { userInfo } }) => {
   return { userInfo };
};

AllSpreadUsdcData.contextTypes = {
   t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AllSpreadUsdcData);
