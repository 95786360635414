import isEmpty from 'is-empty';
import React from 'react';
import DataFormatterHelper from '../../../../../helpers/DataFormatter.helper';
import DateHelper from '../../../../../helpers/Date.helper';
import Table from '../../../Table/Table'

export const History = ({logs,context}) => {

  const formatLogValue = value => {
    if(typeof value === "boolean"){
      return value ? "Si" : "No"
    }
    return DataFormatterHelper.currencyFormatter({currency:"USD",amount:Number(value)})
  }
  const handleDetailsLog = (log) => {
    const details = JSON.parse(log.aditionalInfo)
    if(isEmpty(details.updated_columns)) return 
    const data = details.updated_columns.map((item,i) => {
      return {
        slot:item,
        oldValue:details.updated_old_values[i],
        newValue:details.updated_new_values[i],
      }
    })
    return (
      <div>
        {data.map(item => {
          return <React.Fragment>
            <p>{`${context.t(item.slot)} / valor anterior: ${formatLogValue(item.oldValue)} / valor actual: ${formatLogValue(item.newValue)}`}</p>
            <br/>
          </React.Fragment>
        })}
      </div>
    )
  }
        
    const headers = [
    {
      tag: '',
      title: 'Acción',
      cell: ({data}) => <p>{context.t(JSON.parse(data.aditionalInfo).action)}</p>
    },
    {
      tag: '',
      title: 'Detalles',
      cell: ({data}) => <p>{handleDetailsLog(data)}</p>
    },
    {
      tag: 'executeByName',
      title: 'Ejecutado por'
    },
    {
      tag: '',
      title: 'Fecha',
      cell:({data}) => <p>{DateHelper.formatDate(data.createdAt)}</p>
    }
  ];

  return (
    <div style={{ position: 'relative' }}>
      <div className="table-wrapper">
        <Table showTooltipToCopy={false} rowIdSelected="" rowHead={headers} dataBody={logs} />
      </div>
    </div>
  );
};
