import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { alertStatus, months } from "../../../../constants";
import { TabsContainer } from "../../Tabs/TabsContainer";
import "./depositAlertsDetails.css";
import { GeneralDetails } from "./Tabs/GeneralDetails";
import { Comments } from "./Tabs/Comments";
import { AssociatedDeposits } from "./Tabs/AssociatedDeposits";
import { History } from "./Tabs/History";

function DepositAlertsDetails(props, context) {
  const {
    data: row,
    loadingDepositsByAccount,
    depositsByAccount,
    handleUpdateStatus,
    getAllDepositAlerts,
    userInfo,
    userId,
    logs,
    handleReloadDeposits
  } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(row);
  const [selectedTab, setSelectedTab] = useState("General");
  const [alertStatusInput, setAlertStatusInput] = useState(
    data.alertStatus.toUpperCase()
  );
  const [alertCode,setAlertCode] = useState(row.amlAlertCode)

  const handleIsEditable = () => setIsEditable(!isEditable);

  const handleCloseEdition = async () => {
    handleIsEditable();
    if (isEditable) {
      const response = await handleUpdateStatus({
        newStatus: alertStatusInput,
        data,
        amlAlertCode:alertCode
      });
      if (!response) return handleResetValues()
      getAllDepositAlerts()
    }
  };

  const handleResetValues = () => {
    setAlertStatusInput(data.alertStatus.toUpperCase());
    setAlertCode(data.amlAlertCode);
    setIsEditable(false)
  };

  const alertStatusInputProps = {
    editable: isEditable,
    type: "select",
    option: "Estado",
    options: alertStatus,
    name: "alertStatus",
    value: alertStatusInput,
    onChange: (target) => setAlertStatusInput(target.value),
    rootClassname:"input-element-aml-alert"
  };
  const alertCodeInputProps = {
    editable: isEditable,
    type: "text",
    option: "Código de alerta",
    name: "alertCode",
    value: alertCode,
    onChange: (target) => setAlertCode(target.value),
    rootClassname:"input-element-aml-alert"
  };
  
  const getStatus =
    alertStatus.find(
      (alert) => alert.value.toLowerCase() === data.alertStatus.toLowerCase()
    ) || {};

  const formatDataCallback = (comment) => JSON.stringify({alertId: data.id, comment})

  const filterDataCallback = (comments) => {
    return comments.map(item => {
      if(typeof item.message === "string") return item 
      if(item.message.alertId === data.id){
        return {...item, message: item.message.comment}
      }
      return null
    }).filter(item => item)
  }

  useEffect(() => {
    setData(row)
    setAlertCode(data.amlAlertCode)
  },[row])

  const HeaderSection = () => {
    return (
      <div className="header-section">
        <div>
          <h2>
            #{data.idAlert} - {data.userName}
          </h2>
          <p>Mes: {months[data.alertMonth - 1]}</p>
          <p>Año: {data.alertYear}</p>
        </div>
        <div className="badge-container-status">
          <p className={`color-status-${data.alertStatus.toLowerCase()}`}>
            {getStatus.name}
          </p>
        </div>
      </div>
    );
  };

  const tabsOptions = [
    {
      title: "General",
      component: (
        <GeneralDetails
          data={data}
          isEditable={isEditable}
          handleCloseEdition={handleCloseEdition}
          handleResetValues={handleResetValues}
          alertStatusInputProps={alertStatusInputProps}
          alertCodeInputProps={alertCodeInputProps}
        />
      ),
      default: true,
    },
    {
      title: "Comentarios",
      component: (
        <Comments
          type="aml-deposit"
          context={context}
          userInfo={userInfo}
          levelId={userId}
          formatDataCallback={formatDataCallback}
          filterDataCallback={filterDataCallback}
        />
      ),
    },
    {
      title: "Depósitos",
      component: (
        <AssociatedDeposits
          userAccountId={data.userAccountId}
          loadingDepositsByAccount={loadingDepositsByAccount}
          depositsByAccount={depositsByAccount}
          handleReloadDeposits={handleReloadDeposits}
        />
      ),
    },
    {
      title: "Historial",
      component: <History context={context} logs={logs} />,
    },
  ];

  useEffect(() => {
    setAlertStatusInput(data.alertStatus.toUpperCase());
  }, [data]);

  return (
    <div className="aml-alerts-details deposits-alerts-details">
      <HeaderSection />
      <div className="container-tabs">
        <TabsContainer
          tabsOptions={tabsOptions}
          tabSelect={selectedTab}
          setTabSelect={setSelectedTab}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (global) => {
  const {
    user: { userInfo },
    product: { products },
  } = global;
  return { userInfo, products };
};

DepositAlertsDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DepositAlertsDetails);
