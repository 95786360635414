import React from 'react';
import InputElement from '../InputElement/InputElement';
import Search from '../../../images/new_icons/search.svg';
import './filters.css';

export const GroupFilters = ({
  handleSearchFilters,
  inputs,
  withRightSection
}) => {

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSearchFilters();
  }

  return (
    <div
      className={`filter-group ${
        withRightSection ? 'styles-with-right-section' : ''
      }`}>
      {inputs.map(({ values, actions }, key) => (
        <InputElement
          values={values}
          actions={{...actions,  onKeyDown: handleKeyDown}}
          key={key}
          autoComplete={'Off'}
        />
      ))}
      <div className="btn-filters search" onClick={handleSearchFilters}>
        <img src={Search} alt="Search" />
        <p className="search-text">Buscar</p>
      </div>
    </div>
  );
};
