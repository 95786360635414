import React from 'react';
import PropTypes from 'prop-types';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var InterswitchDepositWorkflowComponent = (function(_React$Component) {
  _inherits(InterswitchDepositWorkflowComponent, _React$Component);

  function InterswitchDepositWorkflowComponent() {
    _classCallCheck(this, InterswitchDepositWorkflowComponent);

    return _possibleConstructorReturn(
      this,
      (
        InterswitchDepositWorkflowComponent.__proto__ ||
        Object.getPrototypeOf(InterswitchDepositWorkflowComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(InterswitchDepositWorkflowComponent, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.form.submit();
      }
    },
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var options = this.props.options;

        return React.createElement(
          'div',
          null,
          React.createElement('p', null, 'Redirecting to Interswitch...'),
          React.createElement(
            'form',
            {
              id: 'interswitchForm',
              style: { visibility: 'hidden' },
              action: options.postUrl,
              method: 'post',
              ref: function ref(form) {
                return (_this2.form = form);
              }
            },
            React.createElement('input', {
              name: 'product_id',
              type: 'text',
              value: options.productId
            }),
            React.createElement('input', {
              name: 'pay_item_id',
              type: 'text',
              value: options.itemId
            }),
            React.createElement('input', {
              name: 'amount',
              type: 'text',
              value: options.amount
            }),
            React.createElement('input', {
              name: 'currency',
              type: 'text',
              value: options.currency
            }),
            React.createElement('input', {
              name: 'site_redirect_url',
              type: 'text',
              value: options.siteRedirectUrl
            }),
            React.createElement('input', {
              name: 'txn_ref',
              type: 'text',
              value: options.transRef
            }),
            React.createElement('input', {
              name: 'cust_id',
              type: 'text',
              value: options.customerId
            }),
            React.createElement('input', {
              name: 'hash',
              type: 'text',
              value: options.hash
            })
          )
        );
      }
    }
  ]);

  return InterswitchDepositWorkflowComponent;
})(React.Component);

InterswitchDepositWorkflowComponent.defaultProps = {
  options: {
    postUrl: '',
    productId: '',
    itemId: '',
    amount: '',
    currency: '',
    siteRedirectUrl: '',
    transRef: '',
    customerId: '',
    hash: ''
  }
};

InterswitchDepositWorkflowComponent.propTypes = {
  options: PropTypes.shape({
    postUrl: PropTypes.string,
    productId: PropTypes.string,
    itemId: PropTypes.string,
    amount: PropTypes.string,
    currency: PropTypes.string,
    siteRedirectUrl: PropTypes.string,
    transRef: PropTypes.string,
    customerId: PropTypes.string,
    hash: PropTypes.string
  })
};

export default InterswitchDepositWorkflowComponent;
