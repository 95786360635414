import { connect } from 'react-redux';
import FiatWithdrawModalComponent from './FiatWithdrawModalComponent';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { createWithdrawTicket } from 'apex-web/lib/redux/actions/withdrawActions';

var mapStateToProps = function mapStateToProps(state) {
  return {
    products: state.apexCore.product
  };
};

var mapDispatchToProps = {
  submitWithdraw: createWithdrawTicket
};

var container = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiatWithdrawModalComponent);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_FIAT_WITHDRAW
})(container);
