export const transferTypes = [
    {
      type: 'NATIONAL',
      name: 'Local Perú',
      pathEdit: '/settings/accounts/edit/pe/'
    },
    {
      type: 'INTERNATIONAL',
      name: 'Internacional',
      pathEdit: '/settings/accounts/edit/international/'
    },
    {
      type: 'INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK',
      name: 'Internacional sin banco intermediario',
      pathEdit: '/settings/accounts/edit/international/'
    },
    {
      type: 'DOMESTIC_TRANSFER_US',
      name: 'Transferencia domestica US',
      pathEdit: '/settings/accounts/edit/us/'
    },
    {
      type: 'DOMESTIC_TRANSFER_US_WITH_BANK',
      name: 'Transferencia domestica US con banco intermediario',
      pathEdit: '/settings/accounts/edit/us/'
    },
    {
      type: 'DEFAULT',
      name: 'sin banco',
      pathEdit: '/settings/accounts/edit/us/'
    }
  ];