import isEmpty from 'is-empty'
import React from 'react'

export const DomesticTransferUsTemplate = ({ transferType, withdrawTemplate, context }) => {
    return (
        <div className='slot-details'>
            <div className='option'>
                <p>
                    <b>{context.t('Transfer Type')}:</b>
                </p>
                <p>
                    {!isEmpty(transferType) ? transferType.name : ''}
                </p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Account Number')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.accountSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Bank Address')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankAddressSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('ABA / Rounting Number')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.abaRoutingNumberSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Bank name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankNameSource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Country Name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.bankCountrySource}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Address')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryAddress}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Country Name')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryCountryName}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary City')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryCity}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary State')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryState}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Zip')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryZip}</p>
            </div>
            <div className='option'>
                <p>
                    <b>{context.t('Beneficiary Reference')}:</b>
                </p>
                <p>{!isEmpty(withdrawTemplate) && withdrawTemplate.beneficiaryReference}</p>
            </div>
        </div>
    )
}
