import React from 'react';
import './loader.css';

export const Loader = ({ message = 'Cargando...', className='' }) => {
  return (
    <div className={`loader-spinner-container ${className}`}>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
      <p>{message}</p>
    </div>
  );
};

export const LoaderWithGif = ({
  message = 'Cargando...',
  gif = '/local/landing-page/images/search-kyc.gif'
}) => {
  return (
    <React.Fragment>
      <div className="container-loading-kyc">
        <span>{message}</span>
        <img src={gif} />
      </div>
    </React.Fragment>
  );
};

export const LoaderButton = ({color=''}) => {
  return (
    <svg
      className={`ring ${color}`}
      viewBox="25 25 50 50"
      strokeWidth="5"
    >
      <circle cx="50" cy="50" r="20" />
    </svg>
  )
}