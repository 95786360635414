import isEmpty from 'is-empty';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AllUsersData from '../../../components/FallComponents/UsersPage/AllUsersData';
import ResizableColumns from '../../../components/FallComponents/ResizableColumns/ResizableColumns';
import { useFilter } from '../../../hooks/useFilter';
import UserPermitsController from '../../FallControllers/UserPermitsController';
import RightResizable from '../../../components/FallComponents/RightResizable/RightResizable';
import close from '../../../images/new_icons/close-circle.svg';
import UsersDetails from '../../../components/FallComponents/UsersPage/UsersDetails/UsersDetails';

const UsersPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedUserId,setSelectedUserId] = useState(null)
 const [loading, setLoading] = useState(true);
 const [loadingDetails, setLoadingDetails] = useState(true);
  const [userDataById, setUserDataById] = useState(true);
  const [logs, setLogs] = useState([]);


  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    resetFilters,
    onChangeLimit,
    setFilterSearch
  } = useFilter({ totalRows });

  const headerOptions = [
    {
      image: close,
      alt: 'close option',
      action: () => {
        setWithRightSection(false);
      }
    }
  ];

  const getAllUsers = () => {
    UserPermitsController.getAllUsers({
      setLoading,
      userInfo,
      setRows,
      setTotalRows,
      filterConfig,
      filterSearch,
    });
  }

  const getUserById = (id) => {
    UserPermitsController.getUserById({
      setLoadingDetails,
      userInfo,
      id,
      setUserDataById
      });
  }

  const getAllLogs = (id) => {
    UserPermitsController.getLogsUsers({
      userInfo,
      setLogs,
      selectedUserId:id
    });
  }

  const handleSelectRow = ({ row }) => {
    setWithRightSection(true);
    setSelectedUserId(row.id)
    getUserById(row.id)
    getAllLogs(row.id)
  };

  const resetUserDetails = () => {
    setWithRightSection(false)
    getAllUsers()
  }

  const resetOnlyDetails = () => {
    getUserById(selectedUserId)
    getAllLogs(selectedUserId)
  }

  useEffect(() => {
      if (!isEmpty(userInfo)){
        getAllUsers()
      }
    },[userInfo, filterConfig, filterSearch]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllUsersData
            context={context}
            setFilterSearch={setFilterSearch}
            resetFilters={resetFilters}
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            handleSelectRow={handleSelectRow}
            selectedId={selectedUserId}
          />
        }
        childrenTwo={
          <RightResizable
            headerOptions={headerOptions}
            loadingData={{
              title: 'Cargando información',
              isLoading: loadingDetails
            }}>
            <UsersDetails context={context} data={userDataById} userInfo={userInfo}  resetUserDetails={resetUserDetails} resetOnlyDetails={resetOnlyDetails} logs={logs}/>
          </RightResizable>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

UsersPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(UsersPage);
