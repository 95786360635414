import isEmpty from "is-empty";

export default class DataFormatterHelper {

  static cleanJSONFilters = filtersObject => {
    Object.keys(filtersObject).forEach(key => {
      Object.keys(filtersObject[key]).forEach(key2 => {
        if (
          (Number.isNaN(filtersObject[key][key2]) || !filtersObject[key][key2]) &&
          filtersObject[key][key2] !== 0 || filtersObject[key][key2] === 'empty'
        )
          delete filtersObject[key];
      });
    });
    if (
      filtersObject.hasOwnProperty('banexcoinUserid') &&
      filtersObject['banexcoinUserid']['in'] === 0
    ) {
      delete filtersObject['banexcoinUserid'];
    }
    if (
      filtersObject.hasOwnProperty('banexcoinUserAccountId') &&
      filtersObject['banexcoinUserAccountId']['in'] === 0
    ) {
      delete filtersObject['banexcoinUserAccountId'];
    }
    return filtersObject;
  };

  static convertToCSV = ({
    data = null,
    columnDelimiter = ',',
    lineDelimiter = '\n'
  }) => {
    let result, ctr, keys;
    if (data === null || !data.length) {
      return null;
    }
    keys = Object.keys(data[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(item => {
      ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) {
          result += columnDelimiter;
        }
        result +=
          typeof item[key] === 'string' && item[key].includes(columnDelimiter)
            ? `"${item[key]}"`
            : item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  static jsonClean = (jsonText) => {
      let jsonCleaned = {};
      if (!isEmpty(jsonText)) {
        jsonCleaned = JSON.parse(jsonText.replace(/\t/g, ''));
      }
      return jsonCleaned;
  }

  static selectArrayData = (array) => {
    const newArray = array.map(({ id, name }) => {
      return {
        name,
        value: id
      }
    })
    return newArray;
  }

  static currencyFormatter = ({currency,amount}) => {
    if(isEmpty(currency) || (isEmpty(amount) && amount !== 0)) return ""
    try {
      return new Intl.NumberFormat(`${currency === "USD" ? 'en-US' : "es-PE"}`, { style: 'currency', currency }).format(amount)
    } catch (error) {
      return ""
    }
  }

  static bigNumberFormatter = (value) => new Intl.NumberFormat("es-MX").format(Number(value))

  static csvToJSON = (csv) => {
    const newCsv = csv.replaceAll('"', '');
    const lines = newCsv.split("\n");
    let result = [];
    const headers = lines[0].replaceAll(/\r/g, '').split(",");
  
    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      const currentline = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = (currentline[j] || '').replaceAll(/\r/g, '');
      }
      result.push(obj);
    }
    return result;
  }
  static fixNumberWithoutRound = (x, digits = 2, currency = null) => {
    try {
      const re = new RegExp('(\\d+\\.\\d{0,' + digits + '})(\\d)');
      const m = x.toString().match(re);
      const response = m ? parseFloat(m[0]) : x.valueOf();
      if (!isEmpty(currency) && (currency === 'PEN' || currency === 'USD')) {
        const locale = {
          "PEN": "es-PE",
          "USD": "en-US"
        }
        return response.toLocaleString(locale[currency], { style: "currency", currency: currency })
      }
      return +(`${x < 0 ? '-' : ''}${response}`);
    } catch (error) {
      return '';
    }
};
}   