import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AllUsersData from '../../../components/FallComponents/InternalUsersPage/AllUsersData';
import ResizableColumns from '../../../components/FallComponents/ResizableColumns/ResizableColumns';
import { useFilter } from '../../../hooks/useFilter';
import UserPermitsController from '../../FallControllers/UserPermitsController';
import RightResizable from '../../../components/FallComponents/RightResizable/RightResizable';
import close from '../../../images/new_icons/close-circle.svg';
import UsersDetails from '../../../components/FallComponents/InternalUsersPage/UsersDetails/UsersDetails';
import ProfilesController from '../../FallControllers/ProfilesController';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { useModal } from "../../../hooks/useModal";

const UsersPage = (props, context) => {
  const { userInfo } = props;
  const [withRightSection, setWithRightSection] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedUserId,setSelectedUserId] = useState(null)
  const [loading, setLoading] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [userDataById, setUserDataById] = useState({});
  const [lastLoginInfo, setLastLoginInfo] = useState({});
  const [logs, setLogs] = useState([]);
  const [profiles,setProfiles] = useState([])
  const {handleToggleModal, showModal} = useModal()
  const {
    filterSearch,
    filterConfig,
    offset,
    showingTo,
    changePage,
    resetFilters,
    onChangeLimit,
    setFilterSearch
  } = useFilter({ totalRows });
  const location = useLocation()
  const history = useHistory()
  const [mainIsEditable, setMainIsEditable] = useState(false);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        mainIsEditable ? handleToggleModal() : setWithRightSection(false)
      }
    }
    document.addEventListener("keydown",handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const headerOptions = [
    {
      image: close,
      alt: 'close option',
      action: () => mainIsEditable ? handleToggleModal() : setWithRightSection(false)
    }
  ];

  const getAllUsers = (filters) => {
    UserPermitsController.getAllUsers({
      setLoading,
      userInfo,
      setRows,
      setTotalRows,
      filterConfig,
      filterSearch: !isEmpty(filters) ? filters : filterSearch,
    });
  }
  const getAllLogs = (id) => {
    UserPermitsController.getLogs({
      userInfo,
      setLogs,
      selectedUserId:id
    });
  }
  const getAllProfiles = () => {
    ProfilesController.getAllProfiles({
      setRows: setProfiles,
      userInfo
    });
  }

  const getUserById = (id) => {
    UserPermitsController.getUserById({
      setLoadingDetails,
      userInfo,
      id,
      setUserDataById
      });
  }

  const handleSelectRow = ({ row }) => {
    setWithRightSection(true);
    setSelectedUserId(row.id)
    getUserById(row.id)
    UserPermitsController.getLastLogin({userInfo,userId:row.banexcoinUserid, setLastLoginInfo})
    getAllLogs(row.id)
  };

  const resetUserDetails = () => {
    setWithRightSection(false)
    getAllUsers()
  }
  useEffect(() => {
    if (isEmpty(userInfo)) return 
    let filters = {}
    if(!isEmpty(location.state)){
      const { profile } = location.state
      filters = {
        "profileByProfileId.name":profile.name
      }
    }
    getAllUsers(filters)
    history.replace(location.pathname,{}) 
    },[userInfo, filterConfig, filterSearch]);

  useEffect(() => {
      if (isEmpty(userInfo)) return
      getAllProfiles()
    },[userInfo]);

  return (
    <div className="beta-content-container">
      <ResizableColumns
        withRightSection={withRightSection}
        childrenOne={
          <AllUsersData
            context={context}
            setFilterSearch={setFilterSearch}
            resetFilters={resetFilters}
            setWithRightSection={setWithRightSection}
            withRightSection={withRightSection}
            onChangeLimit={onChangeLimit}
            filterConfig={filterConfig}
            filterSearch={filterSearch}
            totalRows={totalRows}
            rows={rows}
            loading={loading}
            offset={offset}
            showingTo={showingTo}
            changePage={changePage}
            handleSelectRow={handleSelectRow}
            selectedId={selectedUserId}
            profiles={profiles}
            mainIsEditable={mainIsEditable}
          />
        }
        childrenTwo={
          <RightResizable
            headerOptions={headerOptions}
            loadingData={{
              title: 'Cargando información',
              isLoading: loadingDetails
            }}>
            <UsersDetails profiles={profiles} mainIsEditable={mainIsEditable} setMainIsEditable={setMainIsEditable} context={context} data={userDataById} userInfo={userInfo}  resetUserDetails={resetUserDetails} lastLoginInfo={lastLoginInfo} logs={logs} />
          </RightResizable>
        }
      />
      <ConfirmModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
        title="Cancelar cambios"
        message="¿Deseas salir sin guardar cambios?"
        confirmButtonProps={{
          onClick: () => {
            setWithRightSection(false)
            setMainIsEditable(false)
          },
          text: "Sí",
          customClassName:"delete-comment-button"
        }}
        cancelButtonProps={{
          text: "No",
          customClassName:"delete-comment-button"
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

UsersPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(UsersPage);
