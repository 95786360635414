export const expired = [
    {
        name: 'Sin filtro',
        value: ''
    },
    {
        name: 'Si',
        value: 'Si'
    },
    {
        name: 'No',
        value: 'No'
    },
];