import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import empty from 'is-empty';

class Countries extends Component {
  loadOptions(countries) {
    let options = [];

    //console.log('Countries::::::::loadOptions',this.props.countries)
    options.push(
      <option key="0" value="">
        Seleccionar País
      </option>
    );
    countries.map(country =>
      options.push(
        <option key={country.countryCode} value={country.countryCode}>
          {country.countryName}
        </option>
      )
    );

    return options;
  }

  render() {
    //console.log('Countries::::::::Component',this.props.countries);
    let countries = this.props.countries;

    return !empty(countries) ? (
      <Form.Control
        as="select"
        name={this.props.name}
        onChange={this.props.handleCountryChange}
        defaultValue={this.props.defaultValue}
        required={this.props.required || false}>
        {this.loadOptions(countries)}
      </Form.Control>
    ) : (
      <p>Cargando Paises</p>
    );
  }
}

export default Countries;
