import isEmpty from "is-empty";
import moment from "moment";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  deleteNotification,
  getAllNotifications,
  updateNotification,
} from "../FallServices/NotificationsServices";

class NotificationsController {
  static getAllNotifications = async ({
    userInfo,
    setLoading,
    setRows,
    setTotalRows,
  }) => {
    try {
      setLoading(true);
      const response = await getAllNotifications(userInfo.UserId);
      if (!isEmpty(response.data) && response.data.hasOwnProperty("messages")) {
        const newResponse = response.data.messages.map((data)=> {
          return {
            ...data,
            type: data.type.includes("popup") ? "BANNER" : data.type === "carousel" ? "CARRUSEL" : "CINTILLO",
            platform: data.type.includes("app") ? "APP" : "WEB",
            expired: Number(moment(data.endAt)) - Number(moment()) <= 0 ? "Si" : "No"
          }
        })
        setRows(newResponse);
        setTotalRows(newResponse.length);
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  static updateNotification = async ({ userInfo, data }) => {
    try {
      const response = await updateNotification(userInfo.UserId, data);
      if (!isEmpty(response.data) && response.data.messages === "success") {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Notificación actualizada exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser editada",
      });
      return false;
    }
  };
  
  static deleteNotification = async ({ userInfo, id }) => {
    try {
      const response = await deleteNotification(userInfo.UserId, id);
      if (!isEmpty(response.data) && response.data.messages === "success") {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Notificación eliminada exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La informacion no pudo ser eliminada",
      });
      return false;
    }
  };
}

export default NotificationsController;
