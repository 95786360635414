export const identificationNumberType = [
    {
        name: 'TIN',
        value: 'TIN'
    },
    {
        name: 'EIN',
        value: 'EIN'
    },
    {
        name: 'VAT',
        value: 'VAT'
    },
    {
        name: 'NINO',
        value: 'NINO'
    },
    {
        name: 'RUC',
        value: 'RUC'
    },
    {
        name: 'RUT',
        value: 'RUT'
    },
    {
        name: 'NIF',
        value: 'NIF'
    },
    {
        name: 'RIF',
        value: 'RIF'
    },
    {
        name: 'Otro (especifique)',
        value: 'Other'
    }
];