import isEmpty from "is-empty";
import React, { useState } from "react";
import { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "../../../../hooks/formHooks";
import ReportTypeController from "../../../FallControllers/ReportTypeController";
import { GroupFilters } from "../../../../components/FallComponents/Filters/Filters";
import HeaderPage from "../../../../components/FallComponents/HeaderPage/HeaderPage";
import TableContainer from "../../../../components/FallComponents/Table/TableContainer";
import Download from "../../../../images/new_icons/download.svg";
import moment from "moment";
import { getCountries } from "../../../../components/AccountComponents/AccountHooks";

const ReportWithdraws = (props, context) => {
  const { userInfo, decimalPlaces } = props;
  const filterInitialValues = {
    initDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  const { inputs: filterInputs, onInputChangeV2 } =
    useForm(filterInitialValues);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableFooters, setTableFooters] = useState([]);
  const [countries, setCountries] = useState([]);

  const headerOptions = [
    {
      icon: Download,
      action: () => handleDownload(),
    },
  ];

  useEffect(() => {
    const getCountriesName = async () => {
      if (!userInfo) return "";
      const countries = await getCountries(userInfo.UserId);
      if (!countries) return "Error al obtener los paises";
      setCountries(countries.data.countries);
    };
    getCountriesName();
  }, [userInfo]);

  const handleSearchFilters = () => {
    if (isEmpty(filterInputs.initDate) || isEmpty(filterInputs.endDate)) return;
    ReportTypeController.AllDepositDataReport({
      userInfo,
      params: filterInputs,
      setLoading,
      setRows,
      countries
    });
  };

  const handleDownload = () => {
    if (isEmpty(rows)) return;
    ReportTypeController.DownloadAllDeposit({
      rows,
      tableFooters,
    });
  };

  const tableHeaders = [
    {
      tag: "idUsuario",
      title: "IDUsuario",
    },
    {
      tag: "idCuenta",
      title: "IDCuenta",
    },
    {
      tag: "nombreCuenta",
      title: "NombreCuenta",
      className: "table-title-v2",
    },
    {
      tag: "fechaCreacionPeru",
      title: "FechaRegistro",
      className: "table-title-v2",
    },
    {
      tag: "fechaNacimiento",
      title: "FechaNacimiento",
    },
    {
      tag: "edad",
      title: "Edad",
    },
    {
      tag: "rangoEdad",
      title: "RangoEdad",
    },
    {
      tag: "generoTexto",
      title: "Genero",
    },
    {
      tag: "verficiacionCuentaText",
      title: "VerficiacionCuenta",
    },
    {
      tag: "idAfiliado",
      title: "IdAfiliado",
    },
    {
      tag: "idReferido",
      title: "IdReferido",
    },
    {
      tag: "persona",
      title: "TipoPersona",
    },
    {
      tag: "tipoCliente",
      title: "TipoCliente",
    },
    {
      tag: "paisResidencia",
      title: "PaisResidencia",
    },
    {
      tag: "residente",
      title: "Residente",
    },
    {
      tag: "nivelKyc",
      title: "NivelKYC",
    },
    {
      tag: "timestampUtc",
      title: "FechaUtc",
    },
    {
      tag: "fechaCreacionAmericaLimaPeru",
      title: "FechaPeru",
    },
    {
      tag: "diaDepositoPeru",
      title: "DiaDepositoPeru",
    },
    {
      tag: "mesDepositoPeru",
      title: "MesDepositoPeru",
    },
    {
      tag: "anioDepositoPeru",
      title: "AnioDepositoPeru",
    },
    {
      tag: "diaSemanaDepositoPeru",
      title: "DiaSemanaDepositoPeru",
    },
    {
      tag: "idActivo",
      title: "IdActivo",
    },
    {
      tag: "idActivoSimbolo",
      title: "Activo",
    },
    {
      tag: "montoEnDolares",
      title: "MontoEnDolares",
    },
    {
      tag: "precioCripto",
      title: "PrecioCripto",
    },
    {
      tag: "nroTicket",
      title: "NroTicket",
    },
    {
      tag: "estatusTicket",
      title: "EstatusTicket",
    },
    {
      tag: "bancoTrx",
      title: "BancoTrx",
    },
    {
      tag: "bancoTipoTransferencia",
      title: "BancoTipoTransferencia",
    },
    {
      tag: "bancoCuentaCliente",
      title: "BancoCuentaCliente",
    },
    {
      tag: "bancoCuentaClienteCci",
      title: "BancoCuentaClienteCci",
    },
    {
      tag: "bancoNombreCliente",
      title: "BancoNombreCliente",
    },
    {
      tag: "bancoPaisCliente",
      title: "BancoPaisCliente",
    },
    {
      tag: "bancoBxn",
      title: "BancoBxn",
    },
    {
      tag: "bancoPaisBxn",
      title: "BancoPaisBxn",
    },
    {
      tag: "bancoCuentaBxn",
      title: "BancoCuentaBxn",
    },
    {
      tag: "rango",
      title: "rango",
    }
  ];

  const filters = [
    {
      values: {
        title: "Desde",
        name: "initDate",
        value: filterInputs.initDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
    {
      values: {
        title: "Hasta",
        name: "endDate",
        value: filterInputs.endDate,
        type: "date",
        required: true,
        max: moment().format("YYYY-MM-DD"),
      },
      actions: {
        onChange: onInputChangeV2,
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="beta-content-container">
            <div className="all-page-data">
               <HeaderPage
                title="Depósito - Reporte de depósito"
                subTitle="Reportes / Reporte general / Depósito - Reporte de depósito"
                headerOptions={headerOptions}
              />
              <TableContainer
                rows={rows}
                loading={loading}
                headers={tableHeaders}
                footers={!isEmpty(rows) && !loading ? tableFooters : null}
                rowIdSelected={""}
                enablePagination={false}
              >
                <GroupFilters
                  handleSearchFilters={handleSearchFilters}
                  withRightSection={false}
                  inputs={filters}
                />
              </TableContainer>
            </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user:{userInfo},product:{ decimalPlaces } }) => {
    return { userInfo,decimalPlaces };
  };

  
ReportWithdraws.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ReportWithdraws);
