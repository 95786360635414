import React, { useEffect, useState } from 'react';
import { EditableText } from '../EditableText/EditableText';
import edit from '../../../images/new_icons/edit.svg';
import check from '../../../images/new_icons/check.svg';
import cross from '../../../images/new_icons/cross.svg';
import DateHelper from '../../../helpers/Date.helper';
import './restrictedDomains.css';
import { CustomButton } from '../CustomButton/CustomButton';

const RestrictedDomainsDetails = ({ data, handleDeleteDomain, handleUpdateDomain, editable, setEditable }) => {
	const [inputs, setInputs] = useState(data);

	const onInputChange = (target) => {
		const { name, value } = target;
		setInputs((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleEditable = () => {
		if (editable) {
			handleUpdateDomain(inputs);
		}
			setEditable(!editable);
	};

	const cancelInfoUser = () => {
    setInputs(data)
    setEditable(false);
  };

	useEffect(() => {
		setInputs(data);
	}, [data]);

	const OptionDetail = ({ title, value }) => {
		return (
			<div className="option">
				<p>
					<b>{title}</b>
				</p>
				<p>{value || '-'}</p>
			</div>
		)
	};

	return (
		<div className='bank-account-detail-main restricted-domains'>
			<div className='bank-account-body-container'>
				<div className='header-section'>
					<h2>Detalles de dominio</h2>
				</div>
				<div className='card-body'>
				<OptionDetail title="Fecha de creación" value={DateHelper.formatDate(inputs.createdAt)}/>
				<OptionDetail title="Fecha de actualización" value={DateHelper.formatDate(inputs.updatedAt)}/>
				<div className="content-level-title with-img">
          <h2>Nombre de Dominio</h2>
            <React.Fragment>
              <button
                onClick={handleEditable}>
                <img src={!editable ? edit : check} alt="edit image" />
              </button>
              {editable && (
                <button type="button" onClick={cancelInfoUser}>
                  <img src={cross} alt="cross" style={{ marginLeft: '10px' }} />
                </button>
              )}
            </React.Fragment>
        </div>
				<div className="slot-details body-section">
					<EditableText
						type="text"
						editable={editable}
						option="Dominio"
						name="domain"
						value={inputs.domain}
						onChange={onInputChange}
					/>
				</div>
				</div>
			</div>
			<div className='buttons-container'>
          <div className='buttons-left'/>
            <CustomButton
              text="Borrar"
              onClick={() => handleDeleteDomain(inputs.id)}
              className="red"
            />
        </div>
		</div>
	)
}

export default RestrictedDomainsDetails