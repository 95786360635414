import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AllAlphapointAccountsData from '../../../../components/FallComponents/AlphapointAccountsPage/AllAlphapointAccountsData'
import ResizableColumns from '../../../../components/FallComponents/ResizableColumns/ResizableColumns'
import AlphapointAccountController from '../../../FallControllers/AlphapointAccountController.js'
import isEmpty from 'is-empty'
import closeIcon from '../../../../images/new_icons/close-circle.svg';
import { useFilter } from '../../../../hooks/useFilter'
import RightResizable from '../../../../components/FallComponents/RightResizable/RightResizable'
import AlphapointAccountDetails from '../../../../components/FallComponents/AlphapointAccountsPage/AlphapointAccountDetails/AlphapointAccountDetails'

const AlphapointAccountsPage = (props, context) => {

    const { userInfo, decimalPlaces } = props;
    const [changeLimit, setChangeLimit] = useState(false);
    const [withRightSection, setWithRightSection] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rightLoading, setRightLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [accountId, setAccountId] = useState('');
    const [allfilters, setAllfilters] = useState('');
    const [accountDataById, setAccountDataById] = useState({
        accountInfo: {},
        positions: [],
        volume: []
    });

    const {
        filterConfig,
        offset,
        showingTo,
        changePage,
        onChangeLimit,
        resetFilters
    } = useFilter({ totalRows });

    const initialConfig = () => {
        resetFilters(20);
        setChangeLimit(true);
    }

    const handleSelectRow = ({ row: { accountId } }) => {
        setWithRightSection(true);
        setAccountId(accountId);
    };

    const handleLoadAlphapointAccounts = (filters = allfilters) => {
        AlphapointAccountController.loadAlphapointAccounts({
            userInfo,
            setLoading,
            setRows,
            totalRows,
            setTotalRows,
            filterConfig,
            filters,
            setAllfilters
        })
    }

    const handleLoadAlphapointAccountById = () => {
        AlphapointAccountController.loadAlphapointAccountById({
            userInfo,
            setRightLoading,
            accountId,
            setAccountDataById
        })
    }

    const headerOptions = [
        {
            image: closeIcon,
            alt: 'Close option',
            action: () => {
                setWithRightSection(false);
            }
        }
    ];

    useEffect(() => {
        if (!changeLimit) initialConfig();
        if (!isEmpty(userInfo) && changeLimit) handleLoadAlphapointAccounts();
    }, [userInfo, filterConfig])

    useEffect(() => {
        if (!isEmpty(accountId)) handleLoadAlphapointAccountById();
    }, [accountId])

    return (
        <div className="beta-content-container">
            <ResizableColumns
                withRightSection={withRightSection}
                minWidth={'585px'}
                childrenOne={
                    <AllAlphapointAccountsData
                        handleSelectRow={handleSelectRow}
                        loading={loading}
                        rows={rows}
                        totalRows={totalRows}
                        withRightSection={withRightSection}
                        loadData={handleLoadAlphapointAccounts}
                        filterConfig={filterConfig}
                        offset={offset}
                        showingTo={showingTo}
                        changePage={changePage}
                        onChangeLimit={onChangeLimit}
                        accountId={accountId}
                        setAllfilters={setAllfilters}
                        initialConfig={initialConfig}
                    />
                }
                childrenTwo={
                    <RightResizable
                        headerOptions={headerOptions}
                        loadingData={{
                            title: 'Cargando datos de cuenta',
                            isLoading: rightLoading
                        }}
                    >
                        <AlphapointAccountDetails
                            accountDataById={accountDataById}
                            decimalPlaces={decimalPlaces}
                        />
                    </RightResizable>
                }
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        decimalPlaces: state.product.decimalPlaces,
        userInfo: state.user.userInfo
    };
};

AlphapointAccountsPage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AlphapointAccountsPage);