import React from 'react'
import ConfirmModal from '../../../../ConfirmModal/ConfirmModal'

const ConfirmAssetStatusChange = ({ showModal, handleToggleModal, checkedData, handleDisableActive, handleActiveAsset }) => {
    const actions = {
        0: {
            text: 'activar',
            onClick: () => handleActiveAsset(checkedData.id, { status: 1 }, 'Activado de manera exitosa')
        },
        1: {
            text: 'desactivar',
            onClick: () => handleDisableActive(checkedData.id)
        }
    }
    return <ConfirmModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
        title="Activar / Desactivar activo"
        message={`¿Desea ${actions[checkedData.isChecked].text} el activo?`}
        confirmButtonProps={{
            onClick: () => actions[checkedData.isChecked].onClick(),
            text: "Si"
        }}
        cancelButtonProps={{
            text: "No",
        }}
    />
}

export default ConfirmAssetStatusChange