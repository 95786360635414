import React, { useContext } from 'react';
import '../NaturalCustomersDetails/naturalCustomersDetails.css'
import { Card, Accordion } from 'react-bootstrap';
import { ItemKYCInformation } from './ItemKYCInformation';
import { ContentLevel } from './ContentLevel';
import { Context } from '../../../../pages/FallPages/Customers/Natural/ProviderLevels';
import {
  IS_PEP,
  ocupations,
  MASTER_WORKER_TYPE,
  rucStatus,
  is_pep_numbers
} from '../../../../constants';
import { states } from '../../../../config/staticWorldContent';
import { province } from '../../../../config/staticPeruProv';
import { districts } from '../../../../config/staticPeruDist';
import { OcupationsDetails } from '../../../../config/staticOcupationDetails';
import isEmpty from 'is-empty';
import UtilsHelper from '../../../../helpers/Utils.helper';
import ComponentsHelper from '../../../../helpers/Components.helper';
export const LevelsContainer = ({
  inputs,
  onInputChange,
  onInputFileChange,
  onSubmit,
  reset,
  onSetReturnDataInput,
  status,
  levelsUser,
  editPermission
}) => {
  const {
    userInfo: newUserInfo,
    listCountries,
    isCoink
  } = useContext(Context);
  const { userData, userId } = newUserInfo;

  const {
    is_pep,
    pep_document,
    billing_address,
    building,
    apt,
    town,
    residence_country,
    state,
    city,
    district,
    zip_code,
    occupation,
    occupation_details,
    work_center,
    proof_of_residence,
    master_affidavit_document,
    master_worker_type,
    master_origin_document,
    master_origin_founds,
    is_invoice_with_ruc,
    personal_ruc,
    other_occupation,
    address_main = "",
    nationality = ""
  } = inputs;

  const onChangeDataUser = e => {
    const { value } = e;
    if (value !== master_worker_type) {
      onInputChange(e);
      renderConfirmAlert();
    }
  };

  const renderConfirmAlert = () => {
    ComponentsHelper.confirmAlert({
      title: 'Cambiar tipo de trabajador',
      message:
        'Esta a punto de cambiar el tipo de trabajador ¿Desea devolver támbien todos los campos correspondientes al usuario?\n Se cambiará el estado de la solicitud a Información Incorrecta.',
      buttons: [
        {
          label: 'Si, devolver',
          onClick: () => onInputChange({ value: true, name: 'return_all_rows' })
        },
        {
          label: 'No, sólo cambia de tipo',
          onClick: () =>
            onInputChange({ value: false, name: 'return_all_rows' })
        },
        {
          label: 'Cancelar',
          onClick: () => reset('master_worker_type')
        }
      ]
    });
  };

  const updatePlace = (place, placeId) => {
    const newList = [];
    const placeById = place[placeId];

    if (!isEmpty(placeById)) {
      placeById.map(({ name, id }) =>
        newList.push({
          name,
          value: id
        })
      );
    }

    return newList;
  };

  const optionsToFirstLevelContent = {
    options: [
      {
        title: 'PEP',
        name: 'is_pep',
        value: isEmpty(is_pep) ? "0" : String(is_pep),
        type: 'select',
        options: is_pep_numbers
      },
      {
        title: 'Documento PEP',
        name: 'pep_document_new',
        nameCheck: 'pep_document',
        check: false,
        titleFile: 'Ver documento PEP',
        value: pep_document,
        type: 'file',
        accept: 'image/x-png,image/png,image/jpeg,image/heic,image/heif',
        visible: String(is_pep) === "1"
      },
      {
        title: 'Usuario con RUC',
        name: 'is_invoice_with_ruc',
        value: is_invoice_with_ruc,
        type: 'select',
        options: rucStatus
      },
      {
        title: 'RUC',
        name: 'personal_ruc',
        value: personal_ruc,
        type: 'number',
        visible: is_invoice_with_ruc === 'true'
      },
    ]
  };

  const optionsToSecondLevelContent = {
    options: [
      {
        title: 'Dirección de residencia',
        name: 'billing_address',
        nameCheck: 'address_main',
        value: billing_address,
        type: 'text'
      },
      {
        title: 'Número de edificio',
        name: 'building',
        value: building,
        type: 'text'
      },
      {
        title: 'Número de departamento',
        name: 'apt',
        value: apt,
        type: 'text'
      },
      { title: 'Urb. / AH / CP.', name: 'town', value: town, type: 'text' },
      {
        title: 'País de residencia',
        name: 'residence_country',
        value: residence_country,
        type: 'select',
        options: listCountries
      },
      {
        title: 'Estado / Región / Departamento',
        name: 'state',
        value: state,
        type: `${residence_country === 'PE' ? 'select' : 'text'}`,
        options: updatePlace(states, residence_country)
      },
      {
        title: 'Ciudad / Provincia',
        name: 'city',
        value: city,
        type: `${residence_country === 'PE' ? 'select' : 'text'}`,
        options: updatePlace(province, state)
      },
      {
        title: 'Municipio / Distrito',
        name: 'district',
        value: district,
        type: `${residence_country === 'PE' ? 'select' : 'text'}`,
        options: updatePlace(districts, city)
      },
      {
        title: 'Código postal',
        name: 'zip_code',
        value: zip_code,
        type: 'text'
      },
      { title: 'Ubigeo', value: userData.ubigeoCode },
      {
        title: 'Ocupación',
        name: 'occupation',
        value: occupation,
        type: 'select',
        options: ocupations
      },
      {
        title: 'Detalle su ocupación',
        name: 'occupation_details',
        value: occupation_details,
        type: 'select',
        options: updatePlace(OcupationsDetails, occupation)
      },
      {
        title: 'Otro',
        name: 'other_occupation',
        value: other_occupation,
        type: 'text',
        visible: occupation_details === 'Other'
      },
      {
        title: 'Centro de trabajo',
        name: 'work_center',
        value: work_center,
        type: 'text'
      }
    ]
  };

  const optionsToThridLevelContent = {
    options: [
      {
        title: 'Prueba de residencia',
        name: 'proof_of_residence_new',
        nameCheck: 'proof_of_residence',
        titleFile: 'Prueba de residencia',
        value: proof_of_residence,
        type: 'file',
        accept: '.pdf,.doc,.docx'
      }
    ]
  };

  const optionsToFourthLevelContent = {
    options: [
      {
        title: 'Tipo de trabajador',
        name: 'master_worker_type',
        check: false,
        value: master_worker_type,
        type: 'select',
        options: MASTER_WORKER_TYPE
      },
      {
        title: 'Documentos que Respaldan los Estados Financieros de Fondos',
        name: 'master_origin_document_new',
        nameCheck: 'master_origin_document',
        titleFile: 'Documentos que Respaldan los Estados Financieros de Fondos',
        value: master_origin_document,
        type: 'file',
        accept: '.pdf,.doc,.docx'
      },
      {
        title: 'Documentos que Respaldan el Origen de los Fondos',
        name: 'master_affidavit_document_new',
        nameCheck: 'master_affidavit_document',
        titleFile: 'Documentos que Respaldan el Origen de los Fondos',
        value: master_affidavit_document,
        type: 'file',
        accept: '.pdf,.doc,.docx'
      },
      {
        title: 'Declaración jurada de origen de fondos',
        name: 'master_origin_founds_new',
        nameCheck: 'master_origin_founds',
        titleFile: 'Declaración jurada de origen de fondos',
        visible: master_worker_type !== 'dependent',
        value: master_origin_founds,
        type: 'file',
        accept: '.pdf,.doc,.docx'
      }
    ]
  };

  const optionsToFirstLevelContentCoink = {
    options: [
      {
        title: 'Estado',
        name: 'state',
        nameCheck: 'state',
        value: state,
        type: 'text'
      },
      {
        title: 'Ciudad',
        name: 'city',
        nameCheck: 'city',
        value: city,
        type: 'text'
      },
      {
        title: 'Ciudad',
        name: 'address_main',
        nameCheck: 'address_main',
        value: address_main,
        type: 'text'
      },
      {
        title: 'Nacionalidad',
        name: 'nationality',
        nameCheck: 'nationality',
        value: nationality,
        type: 'text'
      },
      {
        title: 'PEP',
        name: 'is_pep',
        value: isEmpty(is_pep) ? "0" : String(is_pep),
        type: 'select',
        options: is_pep_numbers
      },
      {
        title: 'Ocupación',
        name: 'occupation',
        value: occupation,
        type: 'select',
        options: ocupations
      },
      {
        title: "Detalles de ocupación",
        name: 'occupation_details',
        value: occupation_details,
        type: 'select',
        options: Array.isArray(userData) ? OcupationsDetails[occupation].map(item => ({ value: item, label: item })) : []
      },
    ]
  };

  const showCheckboxByUserLevel = level =>
  levelsUser.userLevel === 0 || (levelsUser.userLevel === level && levelsUser.nextLevel === level + 1);

  const levels = [
    {
      value: 11,
      title: 'Nivel Amateur',
      state: UtilsHelper.validationLevelState(11, userData),
      component: (
        <ContentLevel
          status={status}
          showCheckbox={showCheckboxByUserLevel(10)}
          onSetReturnDataInput={onSetReturnDataInput}
          title="Nivel Amateur"
          content={optionsToFirstLevelContent}
          onInputChange={onInputChange}
          onInputFileChange={onInputFileChange}
          onSubmit={onSubmit}
          reset={reset}
          editPermission={editPermission}
        />
      )
    },
    {
      value: 12,
      title: 'Nivel Intermedio',
      state: UtilsHelper.validationLevelState(12, userData),
      component: (
        <ContentLevel
          status={status}
          showCheckbox={showCheckboxByUserLevel(11)}
          onSetReturnDataInput={onSetReturnDataInput}
          title="Nivel Intermedio"
          content={optionsToSecondLevelContent}
          onInputChange={onInputChange}
          onSubmit={onSubmit}
          reset={reset}
          editPermission={editPermission}
        />
      )
    },
    {
      value: 13,
      title: 'Nivel Avanzado',
      state: UtilsHelper.validationLevelState(13, userData),
      component: (
        <ContentLevel
          status={status}
          showCheckbox={showCheckboxByUserLevel(12)}
          onSetReturnDataInput={onSetReturnDataInput}
          title="Nivel Avanzado"
          content={optionsToThridLevelContent}
          onInputFileChange={onInputFileChange}
          onSubmit={onSubmit}
          editPermission={editPermission}
        />
      )
    },
    {
      value: 14,
      title: 'Nivel Maestro',
      state: UtilsHelper.validationLevelState(14, userData),
      component: (
        <ContentLevel
          status={status}
          showCheckbox={showCheckboxByUserLevel(13)}
          onSetReturnDataInput={onSetReturnDataInput}
          title="Nivel Maestro"
          content={optionsToFourthLevelContent}
          onInputChange={onChangeDataUser}
          onInputFileChange={onInputFileChange}
          onSubmit={onSubmit}
          reset={reset}
          editPermission={editPermission}
        />
      )
    }
  ];

  const coinkLevels = [
    {
      value: 100,
      title: 'Nivel 100',
      state: UtilsHelper.validationLevelState(100, userData),
      component: (
        <ContentLevel
          status={status}
          showCheckbox={false}
          onSetReturnDataInput={onSetReturnDataInput}
          title="Nivel 100"
          content={optionsToFirstLevelContentCoink}
          onSubmit={onSubmit}
          editPermission={editPermission}
        />
      )
    }
  ];

  let levelsArray = levels;
  if (isCoink) {
    levelsArray = coinkLevels;
  }
  return (
    <React.Fragment>
      {levelsArray.map((level, i) => (
        <Accordion key={i}>
          <Card className="accordion-card">
            <Card.Header>
              <Accordion.Toggle variant="link" eventKey={i + 1}>
                <ItemKYCInformation level={level} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i + 1}>
              <Card.Body>{level.component}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}
    </React.Fragment>
  );
};
