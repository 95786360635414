import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getAllAssets, postAllAssets, getRatePrices } from "../FallServices/AutoTradeServices";

class AutoTradeController {
    static getAutoTradeConfig = async ({
        userInfo,
        setTrades,
        setLoading,
    }) => {
        try {
            setLoading(true);
            const response = await getAllAssets(userInfo.UserId);
            if (!isEmpty(response.data.success)) {
                setTrades(response.data.data)
            }
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally {
            setLoading(false);
        }
    };

    static getRatePrice = async ({
        userInfo,
        setRates,
    }) => {
        try {
            const response = await getRatePrices(userInfo.UserId);
            if (!isEmpty(response.data)) {
                setRates(response.data)
            }
            return true;
        } catch (err) {
            console.error(err)
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };

    static postTradeConfig = async ({
        userInfo,
        id,
        data,
    }) => {
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Enviando.",
            });
            const newData = {
                id,
                settingsValue: data
            }
            const response = await postAllAssets(userInfo.UserId, newData);
            return true;
        } catch (err) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    };
}
export default AutoTradeController;