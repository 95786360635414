import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { createModuleByCategoryId, deleteModuleById, updateModuleByCategoryId } from "../FallServices/ModuleServices";

class ModulesController {

    static createModulesByCategoryId = async ({
        userInfo,
        name,
        categoryId,
        setCategoryList,
        setMessage
    }) => {
        try {
            const data = {
                name,
                category: { id: categoryId }
            };
            const response = await createModuleByCategoryId(userInfo.UserId, data);
            if (response.status === 200) {
                const newModule = response.data.id;
                setCategoryList(categoryList => categoryList.map(element => {
                    if (element.id !== categoryId) return element;
                    return {
                        ...element,
                        modulesList: [newModule, ...element.modulesList]
                    };
                }));
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "Módulo creado exitosamente."
                });
                return true;
            }
            setMessage('Hubo un problema. Por favor, vuelva a intentarlo.');
            return false;
        } catch (err) {
            setMessage(err.response.data.message);
            return false;
        };
    };

    static updateModuleByCategoryId = async ({
        userInfo,
        name,
        moduleId,
        categoryId,
        setMessage,
        status,
        setCategoryList,
        oldCategoryId
    }) => {
        try {
            const data = {
                name,
                category: {
                    id: categoryId
                }
            }
            const response = await updateModuleByCategoryId(userInfo.UserId, moduleId, data);
            if (response.status !== 200) {
                setMessage('Hubo un problema. Por favor, vuelva a intentarlo.')
                return false;
            }
            if (status) {
                setCategoryList(categoryList => categoryList.map((element) => {
                    if (element.id !== categoryId) return element;
                    return {
                        ...element,
                        modulesList: element.modulesList.map((module) => {
                            if (module.id !== moduleId) return module;
                            return { ...module, name }
                        })
                    };
                }));
            } else {
                setCategoryList(categoryList => {
                    const newModule = categoryList.find(category => category.id === oldCategoryId).modulesList.find(module => module.id === moduleId);
                    const newCategorList = categoryList.map((element) => {
                        if (element.id === categoryId) {
                            const newList = [newModule, ...element.modulesList];
                            const newModuleList = newList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                            return { ...element, modulesList: newModuleList };
                        } else if (element.id === oldCategoryId) {
                            return { ...element, modulesList: element.modulesList.filter((module) => module.id !== moduleId) };
                        } else { return element; }
                    })
                    return newCategorList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                })
            }
            ComponentsHelper.toast({
                type: toastTypes.success,
                message: "Módulo actualizado exitosamente."
            });
            return true;
        } catch (err) {
            setMessage(err.response.data.message);
            return false;
        };
    };

    static deleteModuleById = async ({
        userInfo,
        moduleId,
        setCategoryList,
        categoryId
    }) => {
        try {
            const response = await deleteModuleById(userInfo.UserId, moduleId);
            if (response.status !== 200) {
                ComponentsHelper.toast({
                    type: toastTypes.warn,
                    message: "Hubo un problema. Por favor, vuelva a intentarlo."
                });
                return false;
            }
            setCategoryList(categoryList => categoryList.map(element => {
                if (element.id !== categoryId) return element;
                return {
                    ...element,
                    modulesList: element.modulesList.filter(module => module.id !== moduleId)
                };
            }));
            ComponentsHelper.toast({
                type: toastTypes.success,
                message: "Módulo eliminado exitosamente."
            });
            return true;
        } catch (err) {
            let message = "Hubo un problema. Por favor, vuelva a intentarlo.";
            if (!isEmpty(err.response.data) && err.response.data.code === "DB002") message = 'No se puede eliminar este módulo, asegúrese que no contenga perfiles relacionados.';
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message
            });
            return false;
        };
    };
}

export default ModulesController;