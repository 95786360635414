import isEmpty from "is-empty";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import {
  addSerieCorrelative,
  changeStatus,
  deleteInvoice,
  getAllSeriesCorrelatives,
} from "../FallServices/SeriesCorrelativeServices";

class SeriesCorrelativesController {
  static getAllSeriesCorrelatives = async ({
    userInfo,
    setRows,
    setLoading,
  }) => {
    try {
      setLoading(true);
      const response = await getAllSeriesCorrelatives(userInfo.UserId);
      if (!isEmpty(response.data) && response.data.hasOwnProperty("sc")) {
        setRows(response.data.sc);
      }
      return true;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no es accesible",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  static deleteInvoice = async ({ userInfo, id }) => {
    try {
      const response = await deleteInvoice(userInfo.UserId, id);
      if (!isEmpty(response.data)) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Registro eliminado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser eliminada",
      });
      return false;
    }
  };

  static changeStatus = async ({ userInfo, data }) => {
    try {
      const response = await changeStatus(userInfo.UserId, data);
      if (!isEmpty(response.data) && response.data.message === "success") {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Estado actualizado exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser actualizada",
      });
      return false;
    }
  };
  static addSerieCorrelative = async ({ userInfo, data, setLoading }) => {
    try {
      setLoading(true);
      const response = await addSerieCorrelative(userInfo.UserId, data);
      if (!isEmpty(response.data)) {
        ComponentsHelper.toast({
          type: toastTypes.success,
          message: "Añadido exitosamente",
        });
        return true;
      }
      return false;
    } catch (error) {
      ComponentsHelper.toast({
        type: toastTypes.warn,
        message: "La información no pudo ser guardada",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
}

export default SeriesCorrelativesController;
