import React from "react";
import moment from "moment-timezone";
import isEmpty from "is-empty";
import { CustomButton } from "../CustomButton/CustomButton";
import DataFormatterHelper from "../../../helpers/DataFormatter.helper";

const BankButtons = ({ balance, syncBankTransactions, showBalance = true, editPermission }) => {
  moment.tz.setDefault("America/Lima");

  const balanceBCP = balance.find((b) => b.settingsKey.includes("BCP-PE"));
  let balanceBCPInfo = !isEmpty(balanceBCP)
    ? JSON.parse(JSON.parse(balanceBCP.settingsValue))
    : [];
  balanceBCPInfo = balanceBCPInfo.sort((a, b) => {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  let balanceAlfin = balance.find((b) => b.settingsKey.includes("ALFIN-PE"));
  let balanceAlfinInfo = !isEmpty(balanceAlfin)
    ? JSON.parse(JSON.parse(balanceAlfin.settingsValue))
    : [];
  balanceAlfinInfo = balanceAlfinInfo.sort((a, b) => {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  const balanceComercio = balance.find((b) =>
    b.settingsKey.includes("COMERCIO-PE")
  );
  let balanceComercioInfo = !isEmpty(balanceComercio)
    ? JSON.parse(JSON.parse(balanceComercio.settingsValue))
    : [];
  balanceComercioInfo = balanceComercioInfo.sort((a, b) => {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  const balanceInterbank = balance.find((b) =>
    b.settingsKey.includes("INTERBANK-PE")
  );
  let balanceInterbankInfo = !isEmpty(balanceInterbank)
    ? JSON.parse(JSON.parse(balanceInterbank.settingsValue))
    : [];
  balanceInterbankInfo = balanceInterbankInfo.sort((a, b) => {
    return a.currency < b.currency ? -1 : a.currency > b.currency ? 1 : 0;
  });

  const formatDateAgo = (data) =>
    moment(data[0].date).tz("America/Lima").from(moment().tz("America/Lima"));

  const getBalance = (account) => {
    return !isEmpty(account.balance)
      ? DataFormatterHelper.currencyFormatter({
          currency: account.balance.currency,
          amount: account.balance.balance,
        })
      : "No se pudo obtener el saldo, reintentar sincronización";
  };

  const syncProps = {
    COMERCIO: {
      bankCode: "COMERCIO-PE",
      from: moment().subtract(3, "days").format("YYYY-MM-DD"),
      to: moment().add(3, "days").format("YYYY-MM-DD"),
    },
    INTERBANK: {
      bankCode: "INTERBANK-PE",
      from: moment().subtract(3, "days").format("YYYY-MM-DD"),
      to: moment().add(3, "days").format("YYYY-MM-DD"),
    },
    ALFIN: {
      bankCode: "ALFIN-PE",
      from: moment().subtract(3, "days").format("YYYY-MM-DD"),
      to: moment().add(3, "days").format("YYYY-MM-DD"),
    },
    BCP: {
      bankCode: "BCP-PE",
      from: moment().subtract(3, "days").format("YYYY-MM-DD"),
      to: moment().add(3, "days").format("YYYY-MM-DD"),
    },
  };

  return ( 
    <div className="bank-buttons-container">
      <div>
        <CustomButton
          className="blue"
          text={`BCP -${ editPermission ? ' Actualizar' : '' } transacciones`}
          onClick={() => {
            editPermission && syncBankTransactions(syncProps.BCP);
          }}
        />
        {!isEmpty(balanceBCPInfo) && (
          <div className="bank-buttons-details">
            <details>
              <summary>Detalles de balances</summary>
              {showBalance &&
                balanceBCPInfo.map((account, index) => (
                  <p className="bank-buttons-details-amount" key={index}>
                    {getBalance(account)}
                  </p>
                ))}
              <p>{balanceBCPInfo[0].date}</p>
              <p>{formatDateAgo(balanceBCPInfo)}</p>
            </details>
          </div>
        )}
      </div>
      <div>
        <CustomButton
          className="red"
          text={`Alfin -${ editPermission ? ' Actualizar' : '' } transacciones`}
          onClick={() => {
            editPermission && syncBankTransactions(syncProps.ALFIN);
          }}
        />
        {!isEmpty(balanceAlfinInfo) && (
          <div className="bank-buttons-details">
            <details>
              <summary>Detalles de balances</summary>
              {showBalance &&
                balanceAlfinInfo.map((account, index) => (
                  <p className="bank-buttons-details-amount" key={index}>
                    {getBalance(account)}
                  </p>
                ))}
              <p>{balanceAlfinInfo[0].date}</p>
              <p>{formatDateAgo(balanceAlfinInfo)}</p>
            </details>
          </div>
        )}
      </div>
      <div>
        <CustomButton
          className="purple"
          text={`Comercio -${ editPermission ? ' Actualizar' : '' } transacciones`}
          onClick={() => {
            editPermission && syncBankTransactions(syncProps.COMERCIO);
          }}
        />
        {!isEmpty(balanceComercioInfo) && (
          <div className="bank-buttons-details">
            <details>
              <summary>Detalles de balances</summary>
              {showBalance &&
                balanceComercioInfo.map((account, index) => (
                  <p className="bank-buttons-details-amount" key={index}>
                    {getBalance(account)}
                  </p>
                ))}
              <p>{balanceComercioInfo[0].date}</p>
              <p>{formatDateAgo(balanceComercioInfo)}</p>
            </details>
          </div>
        )}
      </div>
      <div>
        <CustomButton
          className="green"
          text={`Interbank -${ editPermission ? ' Actualizar' : '' } transacciones`}
          onClick={() => {
            editPermission && syncBankTransactions(syncProps.INTERBANK);
          }}
        />
        {!isEmpty(balanceInterbankInfo) && (
          <div className="bank-buttons-details">
            <details>
              <summary>Detalles de balances</summary>
              {showBalance &&
                balanceInterbankInfo.map((account, index) => (
                  <p className="bank-buttons-details-amount" key={index}>
                    {getBalance(account)}
                  </p>
                ))}
              <p>{balanceInterbankInfo[0].date}</p>
              <p>{formatDateAgo(balanceInterbankInfo)}</p>
            </details>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankButtons;
