import React from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Button, Col } from 'react-bootstrap';
import moment from 'moment';
import Table from 'react-bootstrap/Table';

const ConfirmationCodes = (props, context) => {
  const { fetching, rows } = props;

  if (fetching) {
    return (
      <React.Fragment>
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const confirmationCodesStatus = [
    {
      status: 1,
      name: 'CREATED'
    },
    {
      status: 3,
      name: 'CLAIM/EXPIRED'
    }
  ];

  return (
    <React.Fragment>
      <div className="table-banexcoin">
        <h3 style={{ padding: '2rem 2rem 0 2rem' }}>
          Codigos de verificación SMS
        </h3>
        <Table striped hover responsive>
          <thead>
            <tr>
              <th className="roleName">CODIGO</th>
              <th className="roleName">ESTADO</th>
              <th className="roleName">TIPO</th>
              <th className="roleName">Created At (DD-MM-YYYY)</th>
              <th className="roleName">Updated At (DD-MM-YYYY)</th>
            </tr>
          </thead>
          <tbody>
            {!empty(rows) ? (
              rows.map((row, i) => {
                return (
                  <tr key={i} className={'rowstatus' + row.status}>
                    <td>
                      <b>{row.confirmationCode}</b>
                    </td>
                    <td>
                      {!empty(
                        confirmationCodesStatus.find(
                          c => c.status == row.status
                        )
                      )
                        ? confirmationCodesStatus.find(
                            c => c.status == row.status
                          ).name
                        : 'PENDING'}
                      {row.status}
                    </td>
                    <td>{row.confirmationType}</td>
                    <td>
                      {moment(row.createdAt)
                        .format('DD-MM-YYYY h:mm:ss a')
                        .toString()}
                    </td>
                    <td>
                      {moment(row.updatedAt)
                        .format('DD-MM-YYYY h:mm:ss a')
                        .toString()}
                    </td>
                  </tr>
                );
              })
            ) : (
              <React.Fragment>
                <tr className="emptydata">
                  <td colSpan="12">No existen datos</td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

ConfirmationCodes.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ConfirmationCodes;
