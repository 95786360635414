import React, { useState }  from 'react'
import { userRolesById, userTypes } from '../../../../../constants'
import { EditableText } from '../../../EditableText/EditableText';
import editIcon from "../../../../../images/new_icons/edit.svg";
import checkIcon from "../../../../../images/new_icons/check.svg";
import crossIcon from "../../../../../images/new_icons/cross.svg";
import DateHelper from '../../../../../helpers/Date.helper';

export const MainDetails = ({data,updateUserType, lastLoginInfo, isEditable, setIsEditable}) => {
  const [userType,setUseType] = useState(data.usertype)
  const handleIsEditable = () => setIsEditable(!isEditable);
  
  const handleResetValues = () => {
    setUseType(data.usertype)
    handleIsEditable();
  };
  
  const handleCloseEdition = async () => {
    handleIsEditable();
    if (isEditable) {
      updateUserType(userType,handleResetValues)
    }      
  };

    const OptionDetail = ({ title, value }) => {
        return (
          <div className="option">
            <p>
              <b>{title}</b>
            </p>
            <p>{value || '-'}</p>
          </div>
        )
      };
      
    return (
      <div className="content-tab user-permits-body">
        <div className="content-tab-container">
          <OptionDetail title="Nombre de usuario" value={data.banexcoinUsername} />
          <OptionDetail title="Correo electrónico" value={data.email} />
          <OptionDetail title="Rol" value={userRolesById[data.roleId]} />
          <OptionDetail title="Acceso admin" value={data.roleAdminAccess ? "Si" : "No"} />
          <div  className='flex items-center gap-1'>
          <h2 className="subtitle-first-tab">Detalles de tipo de usuario</h2>
          <img
              onClick={handleCloseEdition}
              src={isEditable ? checkIcon : editIcon}
            />
            {isEditable && <img onClick={handleResetValues} src={crossIcon} />}
          </div>
          <EditableText
            type="select"
            editable={isEditable}
            option="Tipo de usuario"
            name="userType"
            value={userType}
            onChange={(target) => setUseType(target.value)}
            options={userTypes}
            rootClassname="max-w-250"
          />
          <br/>
          <h2 className="subtitle-first-tab">Último inicio de sesión</h2>
          <OptionDetail title="Fecha" value={lastLoginInfo.loginActionTime ? DateHelper.formatDate(lastLoginInfo.loginActionTime) : 'N/A'} />
          <OptionDetail title="IP" value={lastLoginInfo.ipAddress || 'N/A'} />
        </div>
      </div>
    )
  }
