import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import yetti from "./../../../images/404_yetti.png";
import { Link } from "react-router-dom";

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

const ErrorPage = (props, context) => {
  const isBeta = localStorage.getItem("isBeta");

  const handleSwitchToAdminV2 = () => {
      window.location.reload();
      localStorage.setItem("isBeta", "true");
  }
  return (
    <React.Fragment>
      <div className="beta-content-container container-error">
        <img src={yetti} className="yetti-error" />
        {isBeta === "false" ? (
          <>
            <h2>🚀 Disponible en la nueva versión del Admin de Banexcoin</h2>
            <p
              onClick={handleSwitchToAdminV2}
            >
              Cambiar a la nueva versión
            </p>
          </>
        ) : (
          <h2>🚀 Estamos trabajando en este módulo. Pronto estará disponible. </h2>
        )}
      </div>
    </React.Fragment>
  );
};

ErrorPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ErrorPage);
