import isEmpty from "is-empty";
import xlsx from "json-as-xlsx";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { postAllTicketsAndInvoices, postCreateCreditNoteSubmit } from "../FallServices/TicketAndInvoiceServices";

class TicketsAndInvoicesController {
    static handleAllTicketsAndInvoices = async ({
        userInfo,
        filterConfig,
        filterSearch,
        setLoading,
        setRows,
        setTotalRows
    }) => {
        try {
            setLoading(true);
            const params = { filterConfig, filterSearch }
            const response = await postAllTicketsAndInvoices(userInfo.UserId, params);
            if (!isEmpty(response.data)) {
                const newData = response.data.levels.nodes.map(element => {
                    return {
                        ...element,
                        documento: (JSON.parse(element.faPayload) || {}).documento,
                        observaciones: (JSON.parse(element.faResponse) || {}).observaciones
                    }
                })
                setRows(newData);
                setTotalRows(response.data.levels.totalCount);
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        } finally { setLoading(false); }
    }

    static handleCreateCreditNoteSubmit = async ({
        userInfo,
        data,
        handleLoadTicketsAndInvoices
    }) => {
        try {
            const model = {
                serie: data.serie,
                correlative: Number(data.correlative)
            };
            const response = await postCreateCreditNoteSubmit(userInfo.UserId, model);
            if (!isEmpty(response.data) && response.data.message === "success") {
                ComponentsHelper.toast({
                    type: toastTypes.success,
                    message: "Nota de credito creada exitosamente.",
                });
                handleLoadTicketsAndInvoices()
            }
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: error.response.data.data.error_message,
            });
            return false;
        }
    }

    static handleDownloadAllTicketsInvoices = async ({
        userInfo,
        rows
    }) => {
        const buildColumns = (data) => {
            const getKeys = Object.keys(data[0]);
            const columns = getKeys.map((name) => {
                return {
                    label: name,
                    value: (row) => row[name],
                };
            });
            return columns;
        };
        try {
            ComponentsHelper.toast({
                type: toastTypes.info,
                message: "Descargando...",
            });
            const columns = buildColumns(rows);
            const data = [
                {
                    sheet: "Datos",
                    columns,
                    content: rows
                }
            ];
            const settings = {
                fileName: "Reporte de Tickets y Facturas",
                extraLength: 10,
                writeOptions: {}
            };
            setTimeout(() => {
                 xlsx(data, settings)
            }, 1);
            return true;
        } catch (error) {
            ComponentsHelper.toast({
                type: toastTypes.warn,
                message: "Hubo un problema. Por favor, vuelva a intentarlo.",
            });
            return false;
        }
    }
}
export default TicketsAndInvoicesController;