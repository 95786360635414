import React from 'react'
import isEmpty from 'is-empty';
import InputElement from '../../InputElement/InputElement';
import DeleteIcon from '../../../../images/delete-icon.svg'
import { v4 as uuidv4 } from 'uuid';
import {CustomButton} from '../../../../components/FallComponents/CustomButton/CustomButton'

export const ManuallyAddForm = ({rowsList,setRowsList,setError}) => {
  const initialState = {
    paternoRazonSocial:"",
    firstName:"",
    secondName:"",
    lastName:"",
    typeDocument:"",
    numDocument:"",
    blacklistSource:""
  }


  const onInputsChange = (target,index) => {
    setError(false)
    rowsList[index][target.name] = target.value
  }
  
  const deleteRowByIndex = (id) => {
    setError(false)
    const filteredRows = rowsList.filter(item => item.id !==  id)
    setRowsList(filteredRows)
  }

  const Row = ({data, index}) => {
    const paternoRazonSocial = {
      values: {
        title: "Apellido paterno o Razon Social",
        name: "paternoRazonSocial",
        defaultValue:data.paternoRazonSocial,
        required:true
      },
      actions: {
        onChange: (target) => onInputsChange(target,index),
      },
    };
    const firstName = {
      values: {
        title: "Primer nombre",
        name: "firstName",
        defaultValue:data.firstName,
        required:true
      },
      actions: {
        onChange: (target) => onInputsChange(target,index),
      },
    };
    const secondName = {
      values: {
        title: "Segundo nombre",
        name: "secondName",
        defaultValue:data.secondName,
        required:true
      },
      actions: {
        onChange: (target) => onInputsChange(target,index),
      },
    };
    const lastName = {
      values: {
        title: "Apellido materno",
        name: "lastName",
        defaultValue:data.lastName,
        required:true
      },
      actions: {
        onChange: (target) => onInputsChange(target,index),
      },
    };
    const typeDocument = {
      values: {
        title: "Tipo de documento",
        name: "typeDocument",
        defaultValue:data.typeDocument,
        required:true
      },
      actions: {
        onChange: (target) => onInputsChange(target,index),
      },
    };
    const numDocument = {
      values: {
        title: "Número de documento",
        name: "numDocument",
        defaultValue:data.numDocument,
        required:true
      },
      actions: {
        onChange: (target) => onInputsChange(target,index),
      },
    };
    const blacklistSource = {
      values: {
        title: "Fuente",
        name: "blacklistSource",
        defaultValue:data.blacklistSource,
        required:true
      },
      actions: {
        onChange: (target) => onInputsChange(target,index),
      },
    };
    return (
      <div className='flex items-center add-manually-row'>
        <p>{index + 1}</p>
        <InputElement {...paternoRazonSocial}/>
        <InputElement {...lastName}/>
        <InputElement {...firstName}/>
        <InputElement {...secondName}/>
        <InputElement {...typeDocument}/>
        <InputElement {...numDocument}/>
        <InputElement {...blacklistSource}/>
        <img src={DeleteIcon} alt="" onClick={() => deleteRowByIndex(data.id)} style={{cursor:"pointer"}}/>
      </div>
    );
  };

  const handleAddRow = () => {
    const id = uuidv4();
   const newRow = {...initialState,id}
    setRowsList([...rowsList, newRow]);
  };

  const RowsListComponent = () => {
    return (
      rowsList.map((data,i) => <Row index={i} data={data}/>)
    )
  }

  return (
    <div>
      <CustomButton text="Añadir fila" onClick={handleAddRow} className="orange"/>
      {!isEmpty(rowsList) && <RowsListComponent/>}
    </div>
  )
}
