export const ACCOUNTS_STATUS = [
  {
    name: 'All',
    value: 'empty',
  },
  {
    name: 'Pending',
    value: 0,
  },
  {
    name: 'Approved',
    value: 1
  },
  {
    name: 'Rejected',
    value: 2
  },
  {
    name: 'Deleted',
    value: 3
  }
];