import React from 'react';
import PropTypes from 'prop-types';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var FennasDepositWorkflowComponent = (function(_React$Component) {
  _inherits(FennasDepositWorkflowComponent, _React$Component);

  function FennasDepositWorkflowComponent() {
    _classCallCheck(this, FennasDepositWorkflowComponent);

    return _possibleConstructorReturn(
      this,
      (
        FennasDepositWorkflowComponent.__proto__ ||
        Object.getPrototypeOf(FennasDepositWorkflowComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(FennasDepositWorkflowComponent, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.form.submit();
      }
    },
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var options = this.props.options;

        return React.createElement(
          'div',
          null,
          React.createElement('p', null, 'Redirecting to Fennas...'),
          React.createElement(
            'form',
            {
              id: 'fennasForm',
              style: { visibility: 'hidden' },
              action: options.PostUrl,
              method: 'post',
              ref: function ref(form) {
                return (_this2.form = form);
              }
            },
            React.createElement('input', {
              name: 'MerchantID',
              type: 'text',
              value: options.MerchantID
            }),
            React.createElement('input', {
              name: 'MerchantRefNo',
              type: 'text',
              value: options.MerchantRefNo
            }),
            React.createElement('input', {
              name: 'MerchantPaysFees',
              type: 'text',
              value: options.MerchantPaysFees
            }),
            React.createElement('input', {
              name: 'SendJSONCallBack',
              type: 'text',
              value: options.SendJSONCallBack
            }),
            React.createElement('input', {
              name: 'MerchantLanguage',
              type: 'text',
              value: options.MerchantLanguage
            }),
            React.createElement('input', {
              name: 'RequestHasCardOrder',
              type: 'text',
              value: options.RequestHasCardOrder
            }),
            React.createElement('input', {
              name: 'ResponseFormat',
              type: 'text',
              value: options.ResponseFormat
            }),
            React.createElement('input', {
              name: 'RedirectionURLOK',
              type: 'text',
              value: options.RedirectionURLOK
            }),
            React.createElement('input', {
              name: 'RedirectionURLFailed',
              type: 'text',
              value: options.RedirectionURLFailed
            }),
            React.createElement('input', {
              name: 'RedirectionURLCancelled',
              type: 'text',
              value: options.RedirectionURLCancelled
            }),
            React.createElement('input', {
              name: 'RedirectionURLError',
              type: 'text',
              value: options.RedirectionURLError
            }),
            React.createElement('input', {
              name: 'NormalAmount',
              type: 'text',
              value: options.NormalAmount
            }),
            React.createElement('input', {
              name: 'NormalCurrency',
              type: 'text',
              value: options.NormalCurrency
            }),
            React.createElement('input', {
              name: 'NormalDescription',
              type: 'text',
              value: options.NormalDescription
            }),
            React.createElement('input', {
              name: 'RequestType',
              type: 'text',
              value: options.RequestType
            }),
            React.createElement('input', {
              name: 'Version',
              type: 'text',
              value: options.Version
            }),
            React.createElement('input', {
              name: 'CheckSum',
              type: 'text',
              value: options.CheckSum
            })
          )
        );
      }
    }
  ]);

  return FennasDepositWorkflowComponent;
})(React.Component);

FennasDepositWorkflowComponent.defaultProps = {
  options: {
    MerchantID: '',
    MerchantRefNo: '',
    MerchantPaysFees: '',
    SendJSONCallBack: '',
    MerchantLanguage: '',
    RequestHasCardOrder: '',
    ResponseFormat: '',
    RedirectionURLOK: '',
    RedirectionURLFailed: '',
    RedirectionURLCancelled: '',
    RedirectionURLError: '',
    NormalAmount: '',
    NormalCurrency: '',
    NormalDescription: '',
    RequestType: '',
    Version: '',
    CheckSum: ''
  }
};

FennasDepositWorkflowComponent.propTypes = {
  options: PropTypes.shape({
    MerchantID: PropTypes.string,
    MerchantRefNo: PropTypes.string,
    MerchantPaysFees: PropTypes.string,
    SendJSONCallBack: PropTypes.string,
    MerchantLanguage: PropTypes.string,
    RequestHasCardOrder: PropTypes.string,
    ResponseFormat: PropTypes.string,
    RedirectionURLOK: PropTypes.string,
    RedirectionURLFailed: PropTypes.string,
    RedirectionURLCancelled: PropTypes.string,
    RedirectionURLError: PropTypes.string,
    NormalAmount: PropTypes.string,
    NormalCurrency: PropTypes.string,
    NormalDescription: PropTypes.string,
    RequestType: PropTypes.string,
    Version: PropTypes.string,
    CheckSum: PropTypes.string
  })
};

export default FennasDepositWorkflowComponent;
