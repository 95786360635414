import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';
const queryString = require('query-string');

export const getUsers = async (userId, filterConfig, filterSearch) => {
  let result = {};
  try {
    let path = `/api/users/get-all`;

    let params = {
      filterConfig,
      filterSearch
    };

    let configRequest = {
      userId,
      method: 'POST',
      path,
      data: params
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getUserById = async (userId, id) => {
  let result = {};
  try {
    let path = `/api/users/${id}`;

    let configRequest = {
      userId,
      method: 'GET',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const freeSmsUserById = async (userId, id) => {
  let result = {};
  try {
    let path = `/api/users/free-sms/${id}`;

    let configRequest = {
      userId,
      method: 'POST',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getUserByBanexcoinId = async (userId, id) => {
  let result = {};
  try {
    let path = `/api/${id}/users`;

    let configRequest = {
      userId,
      method: 'GET',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getUserByEmail = async (userId, email) => {
  let result = {};
  try {
    let path = `/api/users`;

    let params = {
      email: email
    };

    if (!empty(params)) {
      path += '?' + queryString.stringify(params);
    }

    let configRequest = {
      userId,
      method: 'GET',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const getUserByUsername = async (userId, username) => {
  let result = {};
  try {
    let path = `/api/users`;

    let params = {
      username: username
    };

    if (!empty(params)) {
      path += '?' + queryString.stringify(params);
    }

    let configRequest = {
      userId,
      method: 'GET',
      path
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};

export const updateUserById = async (userId, id, data) => {
  let result = {};
  try {
    let path = `/api/users/${id}`;

    let configRequest = {
      userId,
      method: 'PUT',
      path,
      data
    };

    let response = await HandlerBackend.sendRequest(configRequest);
    result = !empty(response.data) ? response.data : [];
  } catch (error) {
    throw error;
  }

  return result;
};
