export const alphapointRiskTypes = [
    {
        name: 'All',
        value: 'All',
    },
    {
        name: 'Unknown',
        value: 'Unknown',
    },
    {
        name: 'Normal',
        value: 'Normal',
    },
    {
        name: 'NoRiskCheck',
        value: 'NoRiskCheck',
    },
    {
        name: 'NoTrading',
        value: 'NoTrading',
    },
    {
        name: 'Credit',
        value: 'Credit',
    }
]