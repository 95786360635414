import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import apex from "apex-web/lib/apex";
import empty from "is-empty";
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { rejectStatus } from "../../../../../helpers/lib";
import RoleAccessHelper from "../../../../../helpers/RoleAccess.helper";
import WithdrawController from "../../../../../pages/FallControllers/WithdrawController";
import { CustomButton } from "../../../CustomButton/CustomButton";
import FormGroup from "../../../FormGroup/FormGroup";
import isEmpty from "is-empty";
import { operationTypes } from "../../../../../constants";
import addImageLogo from "../../../../../images/new_icons/folder-add.svg";
import DeleteIcon from "../../../../../images/delete-icon.svg";
import { useUploadFile } from "../../../../../hooks/useUploadFile";
import InputElement from "../../../../FallComponents/InputElement/InputElement";

const errorValue = "Debes llenar este campo";
const errorValueFile = "Debes cargar una imagen";
const ProcessInfoWithdraw = (props, context) => {
  const {
    userInfo,
    ticket: ticketInfo,
    formComments,
    formStatusAdm,
    getWithdrawDataById,
    children,
    handleFindTransaction,
    getWithdraws,
    handleImagesToZoom,
    comments,
    editPermission
  } = props;
  const [loading, setLoading] = useState(false);
  const [processInputs, setProcessInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [banks, setBanks] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [newImg, setNewImg] = useState("");
  const toStatus = rejectStatus(ticketInfo.Status);

  const setField = (field, value) => {
    setProcessInputs((processInputs) => ({
      ...processInputs,
      [field]: value,
    }));

    if (errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const getBanks = async () => {
    setLoading(true);
    const currencyCode = ["USD", "PEN"];
    const result = await WithdrawController.getListBanks({
      userInfo,
      currencyCode,
      setLoading,
      context,
    });
    if (!empty(result)) {
      const data = (result.data.data.nodes || []).map((banks) => {
        const { accountNumber, swiftBankBySwiftBankId, currencyCode } = banks;
        return {
          value: accountNumber,
          titulo: `${swiftBankBySwiftBankId.bankName} - ${currencyCode}`,
          currencyCode: currencyCode,
        };
      });

      const PEN = data.filter((item) => item.currencyCode === 'PEN');
      const USD = data.filter((item) => item.currencyCode === 'USD');

      const finalBanks = [...PEN, ...USD];
      setBanks(finalBanks);
      setLoading(false);
    }
  };


  useEffect(() => {
    if (isEmpty(userInfo)) return;
    getBanks();
    if (!isEmpty(formComments)) {
      setProcessInputs(formComments);
    }
    validateSecondImg();
    if (products.length < 1) {
      getProducts();
    }
  }, [userInfo]);

  const {
    uploadFile,
    uploadFilePaste,
    previewFile,
    fileData,
    deleteImageData,
    handleErrors: handleFileErrors,
  } = useUploadFile({
    src:
      !empty(formComments) && !empty(formComments.ipr_url)
        ? formComments.ipr_url
        : "",
    fileType: "image",
    maxFileDimension: {
      maxWidth: 1000,
      maxHeight: 1000,
    },
  });

  const statusAdms = [
    "Retiro Procesado",
    "Retiro Rechazado",
    "Retiro Devuelto",
  ];

  const {
    uploadFilePaste : uploadFilePaste2,
    uploadFile: uploadFile2,
    previewFile: previewFile2,
    fileData: fileData2,
    deleteImageData: deleteImageData2,
    handleErrors: handleFileErrors2,
  } = useUploadFile({
    src: newImg,
    fileType: "image",
    maxFileDimension: {
      maxWidth: 1000,
      maxHeight: 1000,
    },
  });

  const validateSecondImg = () => {
    const filterComents = comments
      .filter((object) => {
        try {
          const newComment = JSON.parse(object.comment);
          if (statusAdms.includes(newComment.statusAdm)) {
            return newComment;
          }
        } catch (error) {}
        return false;
      })
      .map((item) => JSON.parse(item.comment).ipr_url2);
    setNewImg(filterComents[0]);
  };

  const existPreviewData = previewFile && previewFile.data;
  const existPreviewData2 =
    !empty(formComments) && previewFile2 && previewFile2.data;

  const handleDeleteImageData = () => {
    deleteImageData();
    document.getElementById("input-file-bnx-form").value = "";
    delete errors["accountLogo"];
  };

  const customValidation = () => {
    let newErrors = {};
    if (empty(processInputs.tipoOperacion)) {
      newErrors.tipoOperacion = errorValue;
    }
    if (empty(processInputs.tipoMoneda)) {
      newErrors.tipoMoneda = errorValue;
    }
    if (empty(processInputs.reference)) {
      newErrors.reference = errorValue;
    }
    if (empty(processInputs.bankFee)) {
      newErrors.bankFee = errorValue;
    }
    if (empty(processInputs.comments)) {
      newErrors.comments = errorValue;
    }
    if (empty(processInputs.originBank)) {
      newErrors.originBank = errorValue;
    }
    if (empty(processInputs.statusAdm)) {
      newErrors.statusAdm = errorValue;
    }
    if (empty(processInputs.processDate)) {
      newErrors.processDate = errorValue;
    }
    if (empty(processInputs.file)) {
      newErrors.file = errorValueFile;
    }
    if (existPreviewData2 && empty(processInputs.ipr_url2)) {
      newErrors.ipr_url2 = errorValueFile;
    }
    setErrors(newErrors);
    return newErrors;
  };

  const getProducts = async () => {
    const payload = { OMSId: 1 };
    const result = await apex.connection.GetProducts(payload);
    const newResult = result
      .filter((r) => r.ProductType === "NationalCurrency")
      .map((value) => value.ProductFullName);
    setProducts(newResult);
  };

  const confirmSubmitUpdate = () => {
    confirmAlert({
      title: "Actualizar Ticket",
      message: "¿Está seguro de que desea actualizar Ticket?",
      buttons: [
        {
          label: "Aceptar",
          onClick: handleUpdateWithdraw,
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };
  const handleModalToAcceptWithdraw = () => {
    confirmAlert({
      title: "Aprobar solicitud",
      message: "¿Está seguro de que desea aprobar la solicitud?",
      buttons: [
        {
          label: "Aceptar",
          onClick: handleAcceptWithdraw,
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const errors = customValidation();
    if (empty(errors)) {
      handleModalToAcceptWithdraw();
    }
  };

  const handleModalToRejecttWithdraw = () => {
    confirmAlert({
      title: "Rechazar Ticket",
      message: `¿Está seguro de que desea enviar el ticket a estado ${context.t(
        toStatus.cancelStatus
      )}?`,
      buttons: [
        {
          label: "Aceptar",
          onClick: handleRejectWithdrawByAdmin,
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleAcceptWithdraw = async () => {
    const aditionalInfo = {
      ...processInputs,
      amountTransfer: (ticketInfo.Amount || 0) - (processInputs.bankFee || 0),
      totalAmount: ticketInfo.Amount,
    };
    const result = await WithdrawController.acceptWithdrawByAdmin({
      setLoading,
      ticket: ticketInfo,
      userInfo,
      context,
      aditionalInfo: {
        ...aditionalInfo,
        ipr_img: fileData,
        ipr_img2: fileData2,
      },
    });
    if (result) getWithdrawDataById();
  };
  const handleRejectWithdrawByAdmin = async () => {
    await WithdrawController.rejectWithdrawByAdmin({
      toStatusName: toStatus.cancelStatus,
      setLoading,
      ticket: ticketInfo,
      userInfo,
      handleCloseModal: handleClose,
      context,
      rejectReason: processInputs.comments,
      aditionalInfo: {
        ...processInputs,
        ipr_img: fileData || processInputs.ipr_img,
        ipr_img2: fileData2 || processInputs.ipr_img2,
        comments: processInputs.comments,
      },
    });
    getWithdraws();
    getWithdrawDataById();
  };
  const handleUpdateWithdraw = async () => {
    await WithdrawController.updateWithdrawByAdmin({
      setLoading,
      userInfo,
      ticket: ticketInfo,
      processInputs: {
        ...processInputs,
        ipr_img: fileData || formComments.ipr_img,
        ipr_img2: fileData2 || formComments.ipr_img2,
      },
      context,
    });
    getWithdraws();
    getWithdrawDataById();
  };

  const internationalTranfer = [
    "Transferencia Internacional Perú",
    "Wire International USA",
  ];
  const nationalTranfer = [
    "Transferencia mismo banco Perú",
    "Transferencia Interbancaria Perú",
    "Wire Local USA",
  ];
  const [optionsStatusAdm, setOptionsStatusAdm] = useState([]);

  const statusPass = ["Solicitud Enviada", "Retiro Procesado"];
  const statusReject = ["Retiro Devuelto", "Retiro Rechazado"];

  const statusTicketArr = ["Rejected", "FullyProcessed"];
  const statusTicket = statusTicketArr.includes(ticketInfo.Status);

  useEffect(() => {
    let opt = [];
    if (isEmpty(formStatusAdm)) {
      opt.push("Retiro Rechazado");
      if (internationalTranfer.includes(processInputs.tipoOperacion)) {
        opt.push("Solicitud Enviada");
      }
      if (nationalTranfer.includes(processInputs.tipoOperacion)) {
        opt.push("Retiro Procesado");
      }
    } else {
      if (internationalTranfer.includes(processInputs.tipoOperacion)) {
        opt.push("Retiro Devuelto");
        opt.push("Retiro Rechazado");
        opt.push("Retiro Procesado");
      }
    }
    setOptionsStatusAdm(opt);
  }, [formStatusAdm, processInputs ]);

  const valueAmountTransfer = () => {
    return !empty(formComments) && !empty(formComments.bankFee)
      ? (ticketInfo.Amount || 0) - (formComments.bankFee || 0)
      : empty(processInputs.bankFee)
        ? 0
        : (ticketInfo.Amount || 0) - (processInputs.bankFee || 0);
  };
  const handleAssetNameInfo = () => {
    if (ticketInfo.AssetName === "US Dollar") return "United States Dollar";
    return ticketInfo.AssetName;
  };

  const statusOptionValue = () => {
    let statusOptionValue = "Seleccionar opción";
    if (
      !empty(formStatusAdm) &&
      formStatusAdm.statusAdm !== "Solicitud Enviada"
    ) {
      statusOptionValue = formStatusAdm.statusAdm;
    } else if (
      !empty(formComments) &&
      formComments.statusAdm === "Solicitud Enviada"
    ) {
      statusOptionValue = formComments.statusAdm;
    }
    return statusOptionValue;
  };

  const OptionsOperationType = () => {
    return (
      <React.Fragment>
        <option className="d-none">Seleccionar opción</option>
        {operationTypes.map((option, index) => (
          <option key={"tipoOperacion" + index} value={option}>
            {option}
          </option>
        ))}
      </React.Fragment>
    );
  };

  const OptionsCurrencyType = () => {
    return (
      <React.Fragment>
        <option className="d-none">{handleAssetNameInfo()}</option>
        {products.map((option, index) => (
          <option key={"tipoMoneda" + index} value={option}>
            {option}
          </option>
        ))}
      </React.Fragment>
    );
  };
  const OptionsOriginBank = () => {
    return (
      <React.Fragment>
        <option className="d-none">Seleccionar opción</option>
        {banks.map((option, index) => (
          <option key={"originBank" + index} value={option.titulo}>
            {option.titulo}
          </option>
        ))}
      </React.Fragment>
    );
  };
  const OptionsStatusAdministrativo = () => {
    return (
      <React.Fragment>
        <option className="d-none">{statusOptionValue()}</option>
        {optionsStatusAdm.map((option, index) => (
          <option key={"statusAdm" + index} value={option}>
            {option}
          </option>
        ))}
      </React.Fragment>
    );
  };
  const isDisabledStatusAdm = () => {
    let isDisabledStatusAdm = true;
    if (ticketInfo.Status !== "Rejected") {
      if (
        !empty(formComments) &&
        formComments.statusAdm === "Solicitud Enviada"
      ) {
        isDisabledStatusAdm = false;
      } else {
        isDisabledStatusAdm =
          !empty(formStatusAdm) &&
            formStatusAdm.statusAdm === "Retiro Procesado"
            ? true
            : false;
      }
    }
    return isDisabledStatusAdm;
  };

  const obstNotes = () => {
    let labelNote =   <label className="label-reject">Comentario</label>;
    const isReject =
      !statusTicket && statusReject.includes(processInputs.statusAdm);
    const isProcess =
      !statusTicket && !empty(formStatusAdm) &&
      formStatusAdm.statusAdm === "Solicitud Enviada" &&
      processInputs.statusAdm === "Retiro Procesado";
    if (isReject) {
      labelNote = (
        <label className="label-reject">
          {" "}
          Comentario{" "}
          <p className="notes-reject">
            {" "}
            agregar comentario para ticket de rechazo
          </p>
        </label>
      );
    } else if (isProcess) {
      labelNote = (
        <label className="label-reject">
          {" "}
          Comentario{" "}
          <p className="notes-reject">
            {" "}
            agregue o cambie el comentario para confirmar el retiro.
          </p>
        </label>
      );
    }
    return labelNote;
  };

  const configInputsToIPRForm = [
    {
      labelProps: {
        text: "Referencia",
      },
      inputProps: {
        className: "css-control form-width",
        name: "reference",
        type: "text",
        disabled:
          ticketInfo.Status == "Rejected" ||
          (!empty(formComments) && !empty(formComments.reference)),
        value: formComments && formComments.reference,
        isInvalid: !!errors.reference,
        onChange: (e) => setField("reference", e.target.value),
      },
      error: errors.reference,
    },
    {
      labelProps: {
        text: "Tipo de Operación",
      },
      inputProps: {
        as: "select",
        className: "form-width",
        name: "tipoOperacion",
        disabled:
          ticketInfo.Status === "Rejected" ||
          (!empty(formComments) && !empty(formComments.tipoOperacion)),
        defaultValue: !isEmpty(formComments) && formComments.tipoOperacion,
        value:
          (!isEmpty(formComments) && formComments.tipoOperacion) ||
          processInputs.tipoOperacion,
        isInvalid: !!errors.tipoOperacion,
        onChange: (e) => setField("tipoOperacion", e.target.value),
      },
      children: <OptionsOperationType />,
      error: errors.tipoOperacion,
    },
    {
      labelProps: {
        text: "Tipo de Moneda",
      },
      inputProps: {
        as: "select",
        className: "form-width",
        name: "tipoMoneda",
        disabled:
          ticketInfo.Status === "Rejected" ||
          (!empty(formComments) && !empty(formComments.tipoMoneda)),
        defaultValue: formComments && formComments.tipoMoneda,
        value: processInputs.tipoMoneda,
        isInvalid: !!errors.tipoMoneda,
        onChange: (e) => setField("tipoMoneda", e.target.value),
      },
      children: <OptionsCurrencyType />,
      error: errors.tipoMoneda,
    },
    {
      labelProps: {
        text: "Fecha de Procesamiento",
      },
      inputProps: {
        className: "css-control form-width",
        name: "processDate",
        type: "datetime-local",
        disabled:
          ticketInfo.Status === "Rejected" ||
          (!empty(formComments) && !empty(formComments.processDate)),
        defaultValue: formComments && formComments.processDate,
        value: processInputs.processDate,
        isInvalid: !!errors.processDate,
        onChange: (e) => setField("processDate", e.target.value),
      },
      error: errors.processDate,
    },
    {
      labelProps: {
        text: "Monto Transferido",
      },
      inputProps: {
        className: "css-control form-width",
        name: "amountTransfer",
        type: "number",
        disabled: true,
        value: valueAmountTransfer(),
      },
      error: errors.amountTransfer,
    },
    {
      labelProps: {
        text: "Comisión Bancaria",
      },
      inputProps: {
        className: "css-control form-width",
        name: "bankFee",
        type: "number",
        step: "0.01",
        disabled:
          ticketInfo.Status == "Rejected" ||
          (!empty(formComments) && !empty(formComments.bankFee)),
        value: formComments && formComments.bankFee,
        isInvalid: !!errors.bankFee,
        onChange: (e) => setField("bankFee", e.target.value),
      },
      error: errors.bankFee,
    },
    {
      labelProps: {
        text: "Monto Total",
      },
      inputProps: {
        className: "css-control form-width",
        name: "totalAmount",
        type: "number",
        disabled: true,
        value: ticketInfo.Amount,
      },
      error: errors.totalAmount,
    },
    {
      labelProps: {
        text: "Banco Origen (Banexcoin)",
      },
      inputProps: {
        as: "select",
        className: "form-width",
        name: "originBank",
        disabled:
          ticketInfo.Status === "Rejected" ||
          (!empty(formComments) && !empty(formComments.originBank)) ||
          loading,
        defaultValue: formComments && formComments.originBank,
        value:
          (formComments && formComments.originBank) || processInputs.originBank,
        isInvalid: !!errors.originBank,
        onChange: (e) => {
          setField("originBank", e.target.value);
          setProcessInputs({
            ...processInputs,
            originBank: e.target.value,
            accountOrigin: banks.find((item) => item.titulo === e.target.value)
              .value,
          });
        },
      },
      children: <OptionsOriginBank />,
      error: errors.originBank,
    },
    {
      labelProps: {
        text: "Cuenta Origen (Banexcoin)",
      },
      inputProps: {
        className: "form-width",
        name: "accountOrigin",
        disabled: true,
        defaultValue: formComments && formComments.accountOrigin,
        value: processInputs.accountOrigin,
        isInvalid: !!errors.accountOrigin,
        onChange: (e) => setField("accountOrigin", e.target.value),
      },
    },
    {
      labelProps: {
        text: "Operador",
      },
      inputProps: {
        className: "css-control form-width",
        name: "operator",
        type: "text",
        disabled: true,
        value: userInfo.UserName,
      },
      error: errors.operator,
    },
    {
      labelProps: {
        text: "Estado del ticket en AP",
      },
      inputProps: {
        className: "css-control form-width",
        name: "statusAP",
        type: "text",
        disabled: true,
        value: ticketInfo.Status,
      },
      error: errors.statusAP,
    },
    {
      labelProps: {
        text: "Estado Administrativo",
      },
      inputProps: {
        as: "select",
        className: "form-width",
        name: "statusAdm",
        type: "text",
        disabled: isDisabledStatusAdm(),
        defaultValue: formComments && formComments.statusAdm,
        value: processInputs.statusAdm,
        onChange: (e) => {
          if (statusReject.includes(e.target.value)) {
            processInputs.comments = "";
          }
          setField("statusAdm", e.target.value);
        },
        isInvalid: !!errors.statusAdm,
      },
      children: <OptionsStatusAdministrativo />,
      error: errors.statusAdm,
    },
    {
      labelProps: {
        text: obstNotes(),
      },
      inputProps: {
        as: "textarea",
        className: "css-control",
        name: "comments",
        type: "text",
        disabled:
          ticketInfo.Status === "Rejected" ||
          ticketInfo.Status === "FullyProcessed",
        defaultValue: formComments && formComments.comments,
        value: processInputs.comments,
        onChange: (e) => setField("comments", e.target.value),
        isInvalid: !!errors.comments,
      },
      error: errors.comments,
    },
  ];

  const validateBtnAcept =
    (!formStatusAdm &&
      processInputs.statusAdm === "Solicitud Enviada" &&
      internationalTranfer.includes(processInputs.tipoOperacion)) ||
    (processInputs.statusAdm === "Retiro Procesado" &&
      nationalTranfer.includes(processInputs.tipoOperacion));

  const validateBtnUpdate =
    formStatusAdm &&
    formStatusAdm.statusAdm === "Solicitud Enviada" &&
    processInputs.statusAdm === "Retiro Procesado";

  const validateBtnReject =
    processInputs.statusAdm === "Retiro Rechazado" ||
    processInputs.statusAdm === "Retiro Devuelto";

  const ButtonOptionsToForm = () => {
    return (
      <React.Fragment>
        {(statusTicket) || validateBtnAcept && (
          <CustomButton
            disabled={loading}
            onClick={submitForm}
            text={context.t("Aceptar")}
            className="green"
          />
        )}
        {validateBtnUpdate && (
          <CustomButton
            disabled={loading}
            onClick={confirmSubmitUpdate}
            className="orange"
            customClassName="send-pr-btn"
            text={context.t("Actualizar")}
          />
        )}
        {(statusTicket) || (validateBtnReject)  && (
          <CustomButton
            disabled={loading}
            className="red"
            text={context.t("Rechazar")}
            onClick={handleModalToRejecttWithdraw}
          />
        )}
        <CustomButton
          disabled={loading}
          text="Buscar transacción"
          onClick={handleFindTransaction}
          className="orange"
        />
      </React.Fragment>
    );
  };


  const voucherLabel1 = () => {
    const validatyevoucher1 =
      (!empty(formComments) && statusPass.includes(formComments.statusAdm)) ||
      statusPass.includes(processInputs.statusAdm);
    if (empty(formComments) && formComments === undefined) return;
    if (validatyevoucher1) {
      return <label className="form-title">Comprobante de Retiro</label>;
    }
    return <label className="form-title">Comprobante de Rechazo</label>;
  };
  const voucherLabel2 = () => {
    const validationProofOfWithdrawProcess =
      (!empty(formComments) &&
        formComments.statusAdm === "Solicitud Enviada" &&
        processInputs.statusAdm === "Retiro Procesado") ||
      formStatusAdm.statusAdm === "Retiro Procesado";
    const validationRejectedProof =
      (!empty(formComments) &&
        formComments.statusAdm === "Solicitud Enviada" &&
        statusReject.includes(processInputs.statusAdm)) ||
      statusReject.includes(formStatusAdm.statusAdm);
    if (empty(formComments) && formComments === undefined) return;
    if (validationProofOfWithdrawProcess) {
      return <label className="form-title">Comprobante de Retiro Procesado</label>;
    }
    if (validationRejectedProof) {
      return <label className="form-title">Comprobante de Rechazo</label>;
    }
    return null;
  };
  const addImg = () => {
    let add;
    if (
      !empty(formStatusAdm) &&
      formStatusAdm.statusAdm === "Solicitud Enviada"
    )
      return (add = false);
    if (!statusTicket) return (add = true);
    return add;
  };
  const inputTitle = statusReject.includes(processInputs.statusAdm)
    ? "Rechazo"
    : "Retiro";

  const inputTitle2 = statusReject.includes(processInputs.statusAdm)
    ? "Rechazo"
    : "Retiro Procesado";

  const accountLogoInputProps = {
    values: {
      title: `Comprobante de  ${inputTitle}`,
      value: !isEmpty(previewFile) ? previewFile.data : previewFile.name,
      name: "accountLogo",
      type: "text",
      required: true,
      isInvalid: errors.file,
      error: errors.file,
    },
    actions:{
      onPaste:(e)=>handlePaste(e)
    },
    error: errors.file,
  };

  const accountLogoInputProps2 = {
    values: {
      title: `Comprobante de  ${inputTitle2}`,
      value: !isEmpty(previewFile) ? previewFile.data : previewFile.name,
      name: "accountLogo2",
      type: "text",
      required: true,
    },
    actions:{
      onPaste:(e)=>handlePasteReject(e)
    },
  };
  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      const fileObjectName = e.clipboardData.files[0].name;
      setField("file", fileObjectName);
      uploadFilePaste(e);

    }
  };

  const handlePasteReject = (e) => {
    if (e.clipboardData.files.length) {
      const fileObjectName = e.clipboardData.files[0].name;
      setField("file", fileObjectName);
      uploadFilePaste2(e);

    }
  };

  return (
    <div className="content-tab">
      <div className="content-tab-container ipr-content">
        {children}
        <h2>{context.t("Información de Procesamiento de Retiros")}</h2>
        <div className="ipr-content-inputs">
          {configInputsToIPRForm.map((props) => {
            return <FormGroup {...props} editPermission={editPermission} />;
          })}
          {!empty(formComments) &&
            statusAdms.includes(processInputs.statusAdm) ? (
            editPermission ?
              <div className="input-file-preview-container">
                <InputElement {...accountLogoInputProps2} />
                <label htmlFor="input-file-bnx-form">
                  <input
                    hidden
                    type="file"
                    name="ipr_url2"
                    id="input-file-bnx-form"
                    onPaste={handlePaste}
                    onChange={(e) => {
                      setField("ipr_url2", e.target.files[0].name);
                      uploadFile2(e);
                    }}
                  />
                  {!statusTicket && (
                    <img src={addImageLogo} alt="addimage" width="16px" />
                  )}
                </label>
              </div> :
              <Form.Group>
                <Form.Label className='form-title'>
                  {accountLogoInputProps2.values.title}:
                </Form.Label>
                <p className='form-text'>{accountLogoInputProps2.values.value || '-'}</p>
              </Form.Group>
          ) : (
            editPermission ?
            <div className="input-file-preview-container">
              <InputElement {...accountLogoInputProps} />
              <label htmlFor="input-file-bnx-form">
                <input
                  hidden
                  type="file"
                  name="file"
                  id="input-file-bnx-form"
                  onPaste={handlePasteReject}
                  onChange={(e) => {
                    setField("file", e.target.files[0].name);
                    uploadFile(e);
                  }}
                />
                {addImg() && (
                  <img src={addImageLogo} alt="addimage" width="16px" />
                )}
              </label>
            </div> :
            <Form.Group>
              <Form.Label className='form-title'>
                {accountLogoInputProps.values.title}:
              </Form.Label>
              <p className='form-text'>{accountLogoInputProps.values.value || '-'}</p>
            </Form.Group>
          )}
          {existPreviewData && (
            <div className="input-file-preview-container form-group withdraw-voucher ipr-img-label">
              {voucherLabel1()}
              <div className="logo-1">
                <img
                  className="logo-preview-img"
                  id="logo-preview-img"
                  src={!isEmpty(previewFile.data) ? previewFile.data : ""}
                />
                {!isEmpty(previewFile.data) &&
                  !previewFile.data.includes("https") && (
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      width="16px"
                      onClick={handleDeleteImageData}
                    />
                  )}
              </div>
            </div>
          )}
          {existPreviewData2 && (
            <div className="input-file-preview-container form-group withdraw-voucher file2">
              {voucherLabel2()}
              <div className="logo-1">
                <img
                  className="logo-preview-img"
                  id="logo-preview-img"
                  src={!isEmpty(previewFile2.data) ? previewFile2.data : ""}
                />
                {!isEmpty(previewFile.data) &&
                  !previewFile.data.includes("https") && (
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      width="16px"
                      onClick={handleDeleteImageData}
                    />
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      {editPermission &&
        <div className="buttons-container">
          <div className="buttons-left">
            <ButtonOptionsToForm />
          </div>
        </div>
      }
    </div>
  );
};

ProcessInfoWithdraw.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ProcessInfoWithdraw;
