import React, { useState, useEffect } from "react";
import isEmpty from "is-empty";
import "./depositLimitsDetails.css";
import { TabsContainer } from "../../Tabs/TabsContainer";
import {History} from './Tabs/History'
import { GeneralDetails } from "./Tabs/GeneralDetails";
import DataFormatterHelper from "../../../../helpers/DataFormatter.helper";

function DepositLimitsDetails({ data, handleUpdateLimit, getAllDepositLimits,logs,context }) {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedTab, setSelectedTab] = useState("General");
  const [newAmountsValues, setNewAmountsValues] = useState({
    active:data.active
  });

  const handleChangeDetails = (target) => {
    setNewAmountsValues({
      ...newAmountsValues,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    if(isEmpty(data)) return;
    setNewAmountsValues({
      dailyAmount: data.dailyAmount,
      monthlyAmount: data.monthlyAmount,
    })
  },[data])

  const handleIsEditable = () => setIsEditable(!isEditable);

  const handleCloseEdition = async () => {
    handleIsEditable()
    if(isEditable){
      const response = await handleUpdateLimit({newAmounts:newAmountsValues,data});
      if(response){
        getAllDepositLimits();
        return
      }
      handleResetValues()
    }
  }

  const handleResetValues = () => {
    setNewAmountsValues({
      dailyAmount: data.dailyAmount,
      monthlyAmount: data.monthlyAmount,
    });
    setIsEditable(false)
  }

  const dailyAmountInputProps = {
    editable: isEditable,
    type: "number",
    option: "Umbral de transacción única",
    name: "dailyAmount",
    value: isEditable ? newAmountsValues.dailyAmount : DataFormatterHelper.currencyFormatter({currency:"USD",amount:newAmountsValues.dailyAmount}),
    onChange: handleChangeDetails,
    rootClassname:"input-element-aml-alert"
  };
  const monthlyAmountInputProps = {
    editable: isEditable,
    type: "number",
    option: "Umbral de transacción múltiple",
    name: "monthlyAmount",
    value: isEditable ? newAmountsValues.monthlyAmount : DataFormatterHelper.currencyFormatter({currency:"USD",amount:newAmountsValues.monthlyAmount}),
    onChange: handleChangeDetails,
    rootClassname:"input-element-aml-alert"
  };

  const tabsOptions = [
    {
      title: "General",
      component: (
        <GeneralDetails
          data={data}
          isEditable={isEditable}
          handleResetValues={handleResetValues}
          handleCloseEdition={handleCloseEdition}
          dailyAmountInputProps={dailyAmountInputProps}
          monthlyAmountInputProps={monthlyAmountInputProps}
        />
      ),
      default: true,
    },
    {
      title: "Historial",
      component: <History logs={logs} context={context} />,
    },
  ];

  return (
    <div className="resizable-details-container deposit-limits-details">
        <div className="header-section">
          <h2>Detalles de nivel {data.userLevel}</h2>
          <div>
             <p className={`color-status-${data.active ? "activado" : "desactivado"}`}>{data.active ? "Activado" : "Desactivado"}</p>
          </div>
        </div>
        <div className="container-tabs">
        <TabsContainer
          tabsOptions={tabsOptions}
          tabSelect={selectedTab}
          setTabSelect={setSelectedTab}
        />
      </div>
    </div>
  );
}

export default DepositLimitsDetails;
