import { connect } from 'react-redux';
import HyphenDepositWorkflowComponent from './HyphenDepositWorkflowComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state) {
  var product = depositProductSelector(state);
  return {
    product: product,
    bankInfo: config.Deposit[product.Product]
  };
};

export default connect(mapStateToProps)(HyphenDepositWorkflowComponent);
