import React, { useState } from "react";
import { CustomButton } from "../CustomButton/CustomButton";
import { TabsContainer } from "../Tabs/TabsContainer";
import { FileAddForm } from "./AddFormTabs/FileAddForm";
import { ManuallyAddForm } from "./AddFormTabs/ManuallyAddForm";
import BlackListController from "../../../pages/FallControllers/BlackListController";
import isEmpty from "is-empty";
import { useHistory } from "react-router-dom";

function AddForm({
  buttonProps,
  handleToggleModal,
  userInfo,
  type,
  getAllResumeByType
}) {
  const [selectedTab, setSelectedTab] = useState("Manual");
  const [uploadBlackListLoading, setUploadBlackListLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false)
  const [file, setFile] = useState(null);
  const history = useHistory()

  const validationRows = (rows) => {
    let response = true
    if(isEmpty(rows)) return false
      rows.forEach(item => {
        Object.keys(item).forEach(val => {
          if(isEmpty(item[val])){
            response = false
          }
        })
    });
    return response
  }
  async function handleSubmitInputs() {
    let response = false
    if(isEmpty(rows)){
      setError("Cargar un archivo válido")
      return
    }
    if(selectedTab === "Manual"){
      const newRows = rows.map(item => {
        const {id, ...newItem} = item
        return newItem
      })
      const isValid = validationRows(newRows)
      if(!isValid){
        setError("Completar los campos requeridos")
        setUploadBlackListLoading(false)
        return
      }
      setUploadBlackListLoading(true)
      response = await BlackListController.saveManuallyRows({userInfo, type, rows:newRows})
    }else{
      if(!file){
        setError("Cargar un archivo válido")
        return
      }
      setUploadBlackListLoading(true)
      response = await BlackListController.saveCSVRows({userInfo,data:file,type})
    }
    setUploadBlackListLoading(false)
    if(response){
      handleToggleModal()
      if(selectedTab === "Manual"){
        getAllResumeByType()
      }else{
        history.push("/lista-negra/files")
      }
    }
  }

  const tabsOptions = [
    {
      title: "Manual",
      component: <ManuallyAddForm setRowsList={setRows} rowsList={rows} setError={setError}/>,
    },
    {
      title: "Por CSV",
      component: <FileAddForm setFile={setFile} setRows={setRows} rows={rows} setError={setError}/>,
    },
  ];

  return (
    <div noValidate className="add-form-blacklist-container">
      <div>
        <TabsContainer
          tabsOptions={tabsOptions}
          tabSelect={selectedTab}
          setTabSelect={setSelectedTab}
        />
        {error && <p className="error-color">{error}</p>}
      </div>
      <div className="container-submit-cancel-buttons">
        <CustomButton
          onClick={handleToggleModal}
          className="red"
          text="Cancelar"
          disabled={uploadBlackListLoading}
          showLoader={false}
        />
        <CustomButton
          {...buttonProps}
          onClick={handleSubmitInputs}
          disabled={uploadBlackListLoading}
          type="submit"
        />
      </div>
    </div>
  );
}

export default AddForm;
