import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import empty from 'is-empty';
import queryString from 'query-string';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  Button,
  Row,
  Col,
  Badge,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import moment from 'moment-timezone';
import './BankTransactions.css';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  textFilter,
  selectFilter
} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { buildSecureGetParams } from '../../helpers/lib';
import { preparePaginationOptions } from '../../helpers/pagination';
import {
  bankTransactions,
  synBankTransactions,
  bankAccountBalance,
  freezeBankTransactions,
  deleteBankTransaction
} from '../Deposits/DepositsHooks';
import {
  getSecureGetParams,
  banksAccountBanexcoin,
  currencyAssetNames,
  currencyFormat
} from '../../helpers/lib';

import { DatePicker, DatePickerInput } from 'rc-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BankButtons from './BankButtons';
import { ExportCSV } from './ExportCSV';
import { confirmAlert } from 'react-confirm-alert';

const layoutClasses = getBEMClasses('bank-transactions-page');

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

const BankTransactionsPage = (props, context) => {
  const { userInfo } = props;

  if (empty(userInfo)) {
    return (
      <React.Fragment>
        <Loading loading={1} />
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading information..')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const getCurrencyByBankAccount = bankAccount => {
    let findAccount = banksAccountBanexcoin.find(
      b => b.bankAccount === bankAccount
    );
    return !empty(findAccount) ? findAccount.currency : '-';
  };

  const rowsProccesing = rows => {
    let maxRows = 0;
    let totalDebits = [
      {
        currency: 'PEN',
        total: 0
      },
      {
        currency: 'USD',
        total: 0
      }
    ];

    let totalCredits = [
      {
        currency: 'PEN',
        total: 0
      },
      {
        currency: 'USD',
        total: 0
      }
    ];

    rows.map(r => {
      maxRows++;
      r.number = maxRows;

      // Debit Total
      if (r.transactionType == 'DEBIT') {
        let currentDebitsIndex = totalDebits.findIndex(
          t => t.currency == r.currency
        );

        let amountDebit =
          r.transactionAmount < 0
            ? r.transactionAmount * -1
            : r.transactionAmount;
        totalDebits[currentDebitsIndex].total =
          totalDebits[currentDebitsIndex].total + amountDebit;
      }

      // Credits Total
      if (r.transactionType == 'CREDIT') {
        let currentCreditsIndex = totalDebits.findIndex(
          t => t.currency == r.currency
        );

        let amountCredit =
          r.transactionAmount < 0
            ? r.transactionAmount * -1
            : r.transactionAmount;
        totalCredits[currentCreditsIndex].total =
          totalCredits[currentCreditsIndex].total + amountCredit;
      }
    });

    // Reverse row order
    rows.reverse();

    // Create the new rows
    for (let t in totalCredits) {
      let totalCredit = totalCredits[t];
      let totalDebit = totalDebits[t];

      let row = {
        id: '',
        status: null,
        created: '',
        transactionDate: '',
        bankCode: '',
        bankName: '',
        claimDate: null,
        claimDetails: null,
        created: '',
        currency: totalCredit.currency,
        number: null,
        payload: `TOTAL ${totalCredit.currency}`,
        sourceBankAccountNumber: '',
        sourceClientName: '',
        transactionAmount: 0,
        transactionAmountCredit: totalCredit.total,
        transactionAmountDebit: totalDebit.total,
        transactionDate: '',
        transactionReference: '',
        transactionType: 'TOTAL'
      };

      if (
        !empty(row.transactionAmountCredit) ||
        !empty(row.transactionAmountDebit)
      ) {
        rows.push(row);
      }
    }

    return rows;
  };

  const findTransations = async body => {
    let rows = [];
    let result = await bankTransactions(userInfo.UserId, body).catch(e => {
      throw e;
    });

    let resultBankAccounts = await bankAccountBalance(
      userInfo.UserId,
      {}
    ).catch(e => {
      throw e;
    });

    if (!empty(resultBankAccounts.data)) {
      setBalances(resultBankAccounts.data);
    }

    if (result.data.total === 0) {
      toast.warn(context.t('No se encontraron transacciones.'), {
        position: toast.POSITION.TOP_CENTER
      });
    }

    for (let r of result.data.data) {
      let payloadObj = JSON.parse(r.payload);

      // Form Filters
      if (
        !empty(filters.bankCurrency) &&
        getCurrencyByBankAccount(r.sourceBankAccountNumber) !=
          filters.bankCurrency
      ) {
        continue;
      }

      if (
        !empty(filters.bankTransactionType) &&
        r.transactionType != filters.bankTransactionType
      ) {
        continue;
      }

      rows.push({
        ...r,
        created: moment(r.created).format('YYYY-MM-DD HH:mm'),
        currency: getCurrencyByBankAccount(r.sourceBankAccountNumber),
        payload: payloadObj.detail
      });
    }

    setRows(rowsProccesing(rows));
  };

  const onClickFilter = async () => {
    setLoading(1);

    try {
      let today = moment().format('YYYY-MM-DD');
      let body = {
        from: !empty(filters.from) ? filters.from : today,
        to: !empty(filters.to) ? filters.to : today,
        status: !empty(filters.bankTransactionStatus)
          ? parseInt(filters.bankTransactionStatus)
          : null,
        path: '/api/v1/bank/transactions'
      };

      if (!empty(filters.amount)) {
        body.amount = filters.amount;
      }

      if (!empty(filters.bankCode)) {
        body.bankCode = filters.bankCode;
      }

      await findTransations(body);
    } catch (error) {
      console.log('Errors Load: ', error);
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  useEffect(() => {
    async function loadRows() {
      setLoading(1);

      try {
        let body = {
          from: moment()
            .format('YYYY-MM-DD')
            .toString(),
          path: '/api/v1/bank/transactions'
        };

        await findTransations(body);
      } catch (error) {
        console.log('Errors Load: ', error);
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    }
    if (!empty(userInfo)) {
      loadRows();
    }
  }, []);

  const [loading, setLoading] = useState(0);
  const [filters, setFilters] = useState({
    from: '',
    to: '',
    amount: '',
    bankCode: '',
    bankCurrency: ''
  });
  const [rows, setRows] = useState([]);
  const [balances, setBalances] = useState([]);

  const goToTicket = async ticket => {
    window.open(`/deposit-ticket/view/${ticket}`, '_blank');
  };

  const goToTicketWithdrawal = async ticket => {
    window.open(`/withdraw-ticket/view/${ticket}`, '_blank');
  };

  /**
   * Function to process a bank transaction
   * @returns
   * @throws error
   */
  async function processTransaction(params) {
    try {
      if (empty(params.id)) {
        throw new Error('Invalid field Id');
      }

      let body = {
        id: params.id,
        details: !empty(params.details) ? params.details : null,
        reference: !empty(params.reference) ? params.reference : null,
        path: '/api/v1/bank/freezedeposit'
      };

      setLoading(1);

      await freezeBankTransactions(userInfo.UserId, body).catch(e => {
        throw e;
      });

      toast.success(context.t('Transaction Updated'), {
        position: toast.POSITION.TOP_CENTER
      });

      setLoading(0);
      onClickFilter();
    } catch (e) {
      setLoading(0);
      console.log('error:processTransaction', e);
      toast.warn(context.t(`Error Processing Transaction`), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function serviceDeleteTransaction(params) {
    try {
      if (empty(params.id)) {
        throw new Error('Invalid field Id');
      }

      let body = {
        id: params.id,
        path: '/api/v1/bank/transaction/delete'
      };

      setLoading(1);

      await deleteBankTransaction(userInfo.UserId, body).catch(e => {
        throw e;
      });

      toast.success(context.t('Transaction Deleted'), {
        position: toast.POSITION.TOP_CENTER
      });

      setLoading(0);
      onClickFilter();
    } catch (e) {
      setLoading(0);
      console.log('error:processTransaction', e);
      toast.warn(context.t(`Error Processing Transaction`), {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const processClaimTransactionFee = params => {
    confirmAlert({
      title: 'Fee Transaction',
      message: 'Are you sure you want to Claim a Fee Transaction?',
      buttons: [
        {
          label: 'Accept',
          onClick: () => {
            let paramsProcess = {
              id: params.id,
              details: {
                admin: {
                  type: 'FEE',
                  message: `${params.payload}`,
                  user: userInfo
                }
              }
            };

            paramsProcess.details = new Buffer.from(
              JSON.stringify(paramsProcess.details)
            ).toString('base64');

            processTransaction(paramsProcess);
          }
        },
        {
          label: 'Cancel',
          onClick: () => {}
        }
      ]
    });
  };

  const processClaimTransactionAdmin = params => {
    let transactionAdminDetails = {
      category: !empty(params.category) ? params.category : '',
      message: !empty(params.message) ? params.message : '',
      error: ''
    };

    confirmAlert({
      title: 'Admin Transaction',
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>
              Claim Admin Transaction <br /> {params.payload} -{' '}
              {currencyFormat(params.currency, params.amount)}
            </h1>
            <Form>
              <Form.Group controlId="form.ControlCategory">
                <Form.Label>Category *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Admin Category"
                  defaultValue={
                    !empty(transactionAdminDetails.category)
                      ? transactionAdminDetails.category
                      : ''
                  }
                  onChange={e => {
                    console.log('change::category', e.target.value);
                    transactionAdminDetails = {
                      ...transactionAdminDetails,
                      category: e.target.value.toUpperCase()
                    };
                  }}
                />
              </Form.Group>
              <Form.Group controlId="form.ControlPayload">
                <Form.Label>Message *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Message"
                  defaultValue={
                    !empty(transactionAdminDetails.message)
                      ? transactionAdminDetails.message
                      : ''
                  }
                  onChange={e => {
                    transactionAdminDetails = {
                      ...transactionAdminDetails,
                      message: e.target.value.toUpperCase()
                    };
                  }}
                />
              </Form.Group>
              <Form.Group
                controlId="form.errors"
                className="actions-error"
                id="form-errors"
              />
              <Form.Group controlId="form.actions" className="actions-buttons">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    let el = document.getElementById('form-errors');
                    el.innerHTML = ``;

                    let error = '';
                    if (empty(transactionAdminDetails.category)) {
                      error = `Category Field is Required`;
                    }

                    if (empty(transactionAdminDetails.message)) {
                      error = `Message Field is Required`;
                    }

                    if (!empty(error)) {
                      el.innerHTML = `<Alert key='error-form' variant='danger'>
                    ${error}
                  </Alert>`;
                      return false;
                    }

                    let paramsProcess = {
                      id: params.id,
                      details: {
                        admin: {
                          type: 'ADMIN',
                          category: transactionAdminDetails.category,
                          message: transactionAdminDetails.message,
                          user: userInfo
                        }
                      }
                    };
                    paramsProcess.details = new Buffer.from(
                      JSON.stringify(paramsProcess.details)
                    ).toString('base64');
                    console.log('paramsProcess::', paramsProcess);
                    processTransaction(paramsProcess);
                    onClose();
                  }}>
                  Save
                </Button>
                <Button variant="primary" type="submit" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Group>
            </Form>
          </div>
        );
      }
    });
  };

  const processDeleteTransaction = params => {
    confirmAlert({
      title: 'Delete Transaction',
      message: `Are you sure you want to delete this transaction? ${
        params.payload
      } - ${currencyFormat(params.currency, params.amount)}`,
      buttons: [
        {
          label: 'Accept',
          onClick: () => {
            let paramsProcess = {
              id: params.id
            };

            serviceDeleteTransaction(paramsProcess);
          }
        },
        {
          label: 'Cancel',
          onClick: () => {}
        }
      ]
    });
  };

  /**
   * Function sync bank transactions by bank
   * @param {*} params
   * @returns transactions
   * @throw error
   */
  async function syncBankTransactions(params) {
    try {
      setLoading(1);

      let body = {
        from: params.from,
        to: params.to,
        bankCode: params.bankCode,
        path: '/api/v1/bank/transactions/sync'
      };

      let result = await synBankTransactions(userInfo.UserId, body).catch(e => {
        throw e;
      });

      let resultBankAccounts = await bankAccountBalance(
        userInfo.UserId,
        {}
      ).catch(e => {
        throw e;
      });

      if (!empty(resultBankAccounts.data)) {
        setBalances(resultBankAccounts.data);
      }

      if (!empty(result.data)) {
        toast.success(context.t('Transacciones sincronidas con exito.'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);

      onClickFilter();

      return;
    } catch (e) {
      setLoading(0);
      toast.warn(
        context.t(
          `No se pudo procesar la actualización de transacciones, intente en unos minutos`
        ),
        {
          position: toast.POSITION.TOP_CENTER
        }
      );
    }
  }

  const handleTableChange = async (type, tableData) => {
    const { page, sizePerPage, filters } = tableData;

    const params = {};

    setLoading(1);
    try {
    } catch (error) {
      console.log('Errors Handle: ', error);
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const columns = [
    {
      dataField: 'number',
      text: context.t('#')
    },
    {
      dataField: 'transactionDate',
      text: context.t('Transaction Date'),
      formatter: (cellContent, row) => {
        if (empty(row.created)) {
          return null;
        }

        return (
          <div>
            <p>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">Created: {row.created}</Tooltip>
                }>
                <FontAwesomeIcon
                  style={{ fontSize: '15px', color: '#ccc' }}
                  icon={'info-circle'}
                />
              </OverlayTrigger>
              {row.transactionDate}
            </p>
          </div>
        );
      }
    },
    {
      dataField: 'bankCode',
      text: context.t('Bank'),
      formatter: (cellContent, row) => {
        if (empty(row.sourceBankAccountNumber)) {
          return null;
        }

        return (
          <div>
            <p>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">
                    Bank Account: {row.sourceBankAccountNumber}
                  </Tooltip>
                }>
                <FontAwesomeIcon
                  style={{ fontSize: '15px', color: '#ccc' }}
                  icon={'info-circle'}
                />
              </OverlayTrigger>
              {row.bankCode}
            </p>
          </div>
        );
      }
    },
    {
      dataField: 'transactionReference',
      text: context.t('Reference')
    },
    {
      dataField: 'payload',
      text: context.t('Details'),
      formatter: (cellContent, row) => {
        let resultExtra = [];

        let dateClaim = moment(new Date(row.claimDate))
          .tz('America/Lima')
          .format('YYYY-MM-DD HH:mm');

        if (!empty(row.claimDetails)) {
          let detailsBase64 = row.claimDetails;
          let detailsJson = '';
          let detailsJsonString = new Buffer.from(
            detailsBase64,
            'base64'
          ).toString('ascii');
          detailsJsonString = detailsJsonString.replace(/[^\x20-\x7E]+/g, '');

          try {
            detailsJson = JSON.parse(detailsJsonString);
          } catch (error) {
            console.log('Error::', detailsJsonString);
          }

          if (!empty(detailsJson) && !empty(detailsJson.deposit)) {
            resultExtra.push(
              <div>
                <p>Deposit Ticket: {detailsJson.deposit.TicketNumber}</p>
                <p style={{ fontStyle: 'italic' }}>
                  {!empty(detailsJson.user)
                    ? `${detailsJson.user.UserName} - `
                    : ''}
                  {dateClaim}
                </p>
              </div>
            );
          }

          if (!empty(detailsJson) && !empty(detailsJson.withdrawal)) {
            resultExtra.push(
              <div>
                <p>Withdraw Ticket: {detailsJson.withdrawal.TicketNumber}</p>
                <p style={{ fontStyle: 'italic' }}>
                  {!empty(detailsJson.user)
                    ? `${detailsJson.user.UserName} - `
                    : ''}
                  {dateClaim}
                </p>
              </div>
            );
          }

          if (
            !empty(detailsJson) &&
            !empty(detailsJson.admin) &&
            detailsJson.admin.type == 'FEE' &&
            !empty(detailsJson.admin.user)
          ) {
            resultExtra.push(
              <div>
                <p style={{ fontStyle: 'italic' }}>
                  {detailsJson.admin.user.UserName} - {dateClaim}
                </p>
              </div>
            );
          }

          if (
            !empty(detailsJson) &&
            !empty(detailsJson.admin) &&
            detailsJson.admin.type == 'FEE' &&
            empty(detailsJson.admin.user)
          ) {
            resultExtra.push(
              <div>
                <p style={{ fontStyle: 'italic' }}>{dateClaim}</p>
              </div>
            );
          }

          if (
            !empty(detailsJson) &&
            !empty(detailsJson.admin) &&
            detailsJson.admin.type == 'ADMIN' &&
            !empty(detailsJson.admin.user)
          ) {
            resultExtra.push(
              <div>
                <p style={{ fontStyle: 'italic' }}>
                  {detailsJson.admin.category} - {detailsJson.admin.message}
                </p>
                <p style={{ fontStyle: 'italic' }}>
                  {detailsJson.admin.user.UserName} - {dateClaim}
                </p>
              </div>
            );
          }

          if (
            !empty(detailsJson) &&
            !empty(detailsJson.admin) &&
            detailsJson.admin.type == 'ADMIN' &&
            empty(detailsJson.admin.user)
          ) {
            resultExtra.push(
              <div>
                <p style={{ fontStyle: 'italic' }}>
                  {detailsJson.admin.category} - {detailsJson.admin.message}
                </p>
                <p style={{ fontStyle: 'italic' }}>{dateClaim}</p>
              </div>
            );
          }
        }

        let extraData = '';
        if (!empty(row.accountDetails)) {
          extraData = `- ${row.accountDetails.lastName} ${
            row.accountDetails.secondLastName
          }, ${row.accountDetails.firstName} ${
            row.accountDetails.middleName
          }`.toUpperCase();
        }

        return (
          <div>
            <p>
              {row.payload}
              {extraData}
            </p>
            {resultExtra}
          </div>
        );
      }
    },
    {
      dataField: 'transactionAmountCredit',
      text: context.t('Credit'),
      formatter: (cellContent, row) => {
        let amount = '';
        if (row.transactionType == 'CREDIT') {
          amount = currencyFormat(
            row.currency,
            parseFloat(row.transactionAmount)
          );
        }

        if (row.transactionType == 'TOTAL') {
          amount = currencyFormat(
            row.currency,
            parseFloat(row.transactionAmountCredit)
          );
        }

        return `${amount}`;
      }
    },
    {
      dataField: 'transactionAmountDebit',
      text: context.t('Debit'),
      formatter: (cellContent, row) => {
        let amount = '';

        if (row.transactionType == 'DEBIT') {
          amount = currencyFormat(
            row.currency,
            parseFloat(row.transactionAmount)
          );
        }

        if (row.transactionType == 'TOTAL') {
          amount = currencyFormat(
            row.currency,
            parseFloat(row.transactionAmountDebit)
          );
        }

        return `${amount}`;
      }
    },
    {
      dataField: 'status',
      text: context.t('Status'),
      formatter: (cellContent, row) => {
        if (empty(row.sourceBankAccountNumber)) {
          return null;
        }

        return (
          <Badge variant={row.status == 1 ? `light` : `success`}>
            {row.status == 1 ? `Pending` : `Claim`}
          </Badge>
        );
      }
    },
    {
      dataField: 'actions',
      text: context.t('Actions'),
      formatter: (cellContent, row) => {
        let resultExtra = [];

        let allowAdminUsers = ['andrewvergel', 'lc', 'Admin_Assist_RH'];
        let detailsJson = '';

        if (!empty(row.claimDetails)) {
          let detailsBase64 = row.claimDetails;
          let detailsJsonString = new Buffer.from(
            detailsBase64,
            'base64'
          ).toString('ascii');
          detailsJsonString = detailsJsonString.replace(/[^\x20-\x7E]+/g, '');

          try {
            detailsJson = JSON.parse(detailsJsonString);
          } catch (error) {
            console.log('Error::', detailsJsonString);
          }
        }

        if (!empty(row.payload) && row.status == 1) {
          if (allowAdminUsers.includes(userInfo.UserName)) {
            resultExtra.push(
              <div>
                <Button
                  variant="danger"
                  style={{ marginBottom: '5px', width: '100%' }}
                  onClick={() => {
                    let params = {
                      id: row.id,
                      payload: row.payload,
                      amount: parseFloat(row.transactionAmount),
                      currency: row.currency
                    };
                    processClaimTransactionAdmin(params);
                  }}>
                  Claim Admin
                </Button>
              </div>
            );
          }
        }

        if (
          (!empty(row.payload) &&
            row.transactionType == 'DEBIT' &&
            row.status == 1) ||
          (!empty(row.payload) && row.transactionAmount == 0 && row.status == 1)
        ) {
          if (allowAdminUsers.includes(userInfo.UserName)) {
            resultExtra.push(
              <div>
                <Button
                  variant="secondary"
                  style={{ marginBottom: '5px', width: '100%' }}
                  onClick={() => {
                    let params = {
                      id: row.id,
                      payload: row.payload
                    };
                    processClaimTransactionFee(params);
                  }}>
                  Claim Fee
                </Button>
              </div>
            );
          }
        }

        if (!empty(row.payload) && row.status == 1) {
          if (allowAdminUsers.includes(userInfo.UserName)) {
            resultExtra.push(
              <div>
                <Button
                  variant="warning"
                  style={{ marginBottom: '5px', width: '100%' }}
                  onClick={() => {
                    let params = {
                      id: row.id,
                      payload: row.payload,
                      currency: row.currency,
                      amount: row.transactionAmount
                    };
                    processDeleteTransaction(params);
                  }}>
                  Delete
                </Button>
              </div>
            );
          }
        }

        if (!empty(detailsJson) && !empty(detailsJson.deposit)) {
          resultExtra.push(
            <div>
              <Button
                style={{ marginBottom: '5px', width: '100%' }}
                variant="info"
                onClick={() => {
                  goToTicket(
                    buildSecureGetParams({
                      accountId: detailsJson.deposit.AccountId,
                      requestCode: detailsJson.deposit.RequestCode,
                      AccountName: detailsJson.deposit.RequestUserName
                    })
                  );
                }}>
                Deposit View
              </Button>
            </div>
          );
        }

        if (!empty(detailsJson) && !empty(detailsJson.withdrawal)) {
          resultExtra.push(
            <div>
              <Button
                style={{ marginBottom: '5px', width: '100%' }}
                variant="info"
                onClick={() => {
                  goToTicketWithdrawal(
                    buildSecureGetParams({
                      accountId: detailsJson.withdrawal.AccountId,
                      requestCode: detailsJson.withdrawal.RequestCode
                    })
                  );
                }}>
                Withdrawal View
              </Button>
            </div>
          );
        }

        if (
          !empty(detailsJson) &&
          !empty(detailsJson.admin) &&
          (detailsJson.admin.type == 'ADMIN')
        ) {
          resultExtra.push(
            <div>
              <Button
                style={{ marginBottom: '5px', width: '100%' }}
                variant="warning"
                onClick={() => {
                  let params = {
                    id: row.id,
                    payload: row.payload,
                    amount: parseFloat(row.transactionAmount),
                    currency: row.currency,
                    category: detailsJson.admin.category,
                    message: detailsJson.admin.message
                  };
                  processClaimTransactionAdmin(params);
                }}>
                Edit Transaction
              </Button>
              <Button
                variant="danger"
                style={{ marginBottom: '5px', width: '100%' }}
                onClick={() => {
                  let params = {
                    id: row.id,
                    payload: row.payload,
                    currency: row.currency,
                    amount: row.transactionAmount
                  };
                  processDeleteTransaction(params);
                }}>
                Delete
              </Button>
            </div>
          );
        }

        return <div>{resultExtra}</div>;
      }
    }
  ];

  let rowsCsv = [];
  rows.map(r => {
    if (!empty(r.id) && !empty(r.payload) && r.transactionAmount != 0) {
      if (!empty(r.transactionDate)) {
        let detailsJson = '';
        let conceptoRow = '';
        let beneficiarioRow = '';
        let numeroDocumentoRow = '';
        let cargoRow = 0;
        let abonoRow = 0;

        if (!empty(r.claimDetails)) {
          let detailsBase64 = r.claimDetails;
          let detailsJsonString = new Buffer.from(
            detailsBase64,
            'base64'
          ).toString('ascii');
          detailsJsonString = detailsJsonString.replace(/[^\x20-\x7E]+/g, '');

          try {
            detailsJson = JSON.parse(detailsJsonString);
          } catch (error) {
            console.log('Error::', detailsJsonString);
          }
        }

        // Deposit
        if (!empty(detailsJson) && !empty(detailsJson.deposit)) {
          conceptoRow = `${r.payload}`;
          beneficiarioRow = `BANEXCOIN`;
          numeroDocumentoRow = `Deposit Ticket ${
            detailsJson.deposit.TicketNumber
          }`;
        }

        // Withdrawal
        if (!empty(detailsJson) && !empty(detailsJson.withdrawal)) {
          conceptoRow = `${r.payload}`;
          beneficiarioRow = ``;
          numeroDocumentoRow = `Withdrawal Ticket ${
            detailsJson.withdrawal.TicketNumber
          }`;
        }

        // Admin
        if (!empty(detailsJson) && !empty(detailsJson.admin)) {
          // Fee
          if (
            detailsJson.admin.type == 'FEE' ||
            detailsJson.admin.type == 'IMPUESTO'
          ) {
            conceptoRow = `${r.payload}`;
            beneficiarioRow = ``;
            numeroDocumentoRow = ``;
          }

          // Admin
          if (detailsJson.admin.type == 'ADMIN') {
            conceptoRow = `${detailsJson.admin.category} - ${r.payload}`;
            beneficiarioRow = `${detailsJson.admin.message}`;
            numeroDocumentoRow = ``;
          }
        }

        if (r.transactionType == 'DEBIT') {
          cargoRow =
            r.transactionAmount < 0
              ? r.transactionAmount * -1
              : r.transactionAmount;
        }

        if (r.transactionType == 'CREDIT') {
          abonoRow =
            r.transactionAmount < 0
              ? r.transactionAmount * -1
              : r.transactionAmount;
        }

        if (!empty(beneficiarioRow)) beneficiarioRow = `- ${beneficiarioRow}`;
        if (!empty(numeroDocumentoRow))
          numeroDocumentoRow = `- ${numeroDocumentoRow}`;

        let extraData = '';
        if (!empty(r.accountDetails)) {
          extraData = `- ${r.accountDetails.lastName} ${
            r.accountDetails.secondLastName
          }, ${r.accountDetails.firstName} ${
            r.accountDetails.middleName
          }`.toUpperCase();
        }

        rowsCsv.push({
          fechaOperacion: moment(r.transactionDate).format('DD/MM'),
          numeroOperacion: r.transactionReference,
          concepto: `${conceptoRow} ${beneficiarioRow} ${numeroDocumentoRow} ${extraData}`,
          cargo: cargoRow,
          abono: abonoRow
        });
      }
    }
  });
  rowsCsv.reverse();

  return (
    <React.Fragment>
      <div className="content-container">
        <Loading loading={loading} />
        <Row style={{ margin: 20 }}>
          <Col xs="8" lg="8" className="text-right" />
          <Col xs="4" lg="4" className="text-right">
            <BankButtons
              showBalance={true}
              syncBankTransactions={syncBankTransactions}
              context={context}
              balances={balances}
            />
          </Col>
          <Col xs="12" lg="12">
            <h1 style={{ textAlign: 'left' }}>
              {context.t('Bank Transactions')}
            </h1>
          </Col>
          <Col xs="12" lg="12" style={{ marginTop: 20 }}>
            <Row className={'banex-form-filter-content'}>
              <Col xl={2}>
                <Form.Label>From</Form.Label>
                <DatePickerInput
                  onChange={(jsDate, dateString) => {
                    setFilters({
                      ...filters,
                      from: moment(jsDate).format('YYYY-MM-DD')
                    });
                  }}
                  defaultValue={moment().format('YYYY-MM-DD')}
                  value={!empty(filters.from) ? filters.from : ''}
                  className="banex-admin-datepicker"
                />
              </Col>
              <Col xl={2}>
                <Form.Label>To</Form.Label>
                <DatePickerInput
                  onChange={(jsDate, dateString) => {
                    setFilters({
                      ...filters,
                      to: moment(jsDate).format('YYYY-MM-DD')
                    });
                  }}
                  value={
                    !empty(filters.to)
                      ? filters.to
                      : moment().format('YYYY-MM-DD')
                  }
                  className="banex-admin-datepicker"
                />
              </Col>
              <Col xl={2}>
                <Form.Label>Banks</Form.Label>
                <Form.Control
                  as="select"
                  id="inlineFormCustomSelectPref"
                  onChange={e => {
                    setFilters({
                      ...filters,
                      bankCode: e.target.value
                    });
                  }}>
                  <option value="">All Banks...</option>
                  <option value="SCOTIA-PE">SCOTIA-PE</option>
                  <option value="INTERBANK-PE">INTERBANK-PE</option>
                  <option value="BBVA-PE">BBVA-PE</option>
                  <option value="COMERCIO-PE">COMERCIO-PE</option>
                  <option value="ALFIN-PE">ALFIN-PE</option>
                  <option value="BCP-PE">BCP-PE</option>
                </Form.Control>
              </Col>
              <Col xl={1}>
                <Form.Label>Currency</Form.Label>
                <Form.Control
                  as="select"
                  id="inlineFormCustomSelectPref"
                  onChange={e => {
                    setFilters({
                      ...filters,
                      bankCurrency: e.target.value
                    });
                  }}>
                  <option value="">All Currencies...</option>
                  <option value="PEN">PEN</option>
                  <option value="USD">USD</option>
                </Form.Control>
              </Col>
              <Col xl={1}>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  id="inlineFormCustomSelectPref"
                  onChange={e => {
                    setFilters({
                      ...filters,
                      bankTransactionType: e.target.value
                    });
                  }}>
                  <option value="">All Types...</option>
                  <option value="DEBIT">DEBIT</option>
                  <option value="CREDIT">CREDIT</option>
                </Form.Control>
              </Col>
              <Col xl={1}>
                <Form.Label>Claim</Form.Label>
                <Form.Control
                  as="select"
                  id="inlineFormCustomSelectPref"
                  onChange={e => {
                    setFilters({
                      ...filters,
                      bankTransactionStatus: e.target.value
                    });
                  }}>
                  <option value="">All...</option>
                  <option value="1">PENDING</option>
                  <option value="2">CLAIM</option>
                </Form.Control>
              </Col>
              <Col xl={1}>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  onChange={e => {
                    setFilters({
                      ...filters,
                      amount: e.target.value
                    });
                  }}
                />
              </Col>
              <Col xl={2}>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => onClickFilter()}>
                  Filter
                </Button>
                <ExportCSV
                  csvData={rowsCsv}
                  fileName={`bankTransactions-${new Date().getTime()}`}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="12" style={{ marginTop: 20 }}>
            <BootstrapTable
              remote
              keyField="TicketNumber"
              data={rows}
              columns={columns}
              onTableChange={handleTableChange}
              filter={filterFactory()}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

BankTransactionsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(BankTransactionsPage);
