import {useState } from "react";
import isEmpty from "is-empty";
import { useEffect } from "react";

export const useUploadFile = ({ fileType = "image", maxFileDimension, src=null }) => {
  const [fileData, setFileData] = useState(null);
  const [previewFile, setPreviewFile] = useState({
      data: src, name:""
  });

  const imageTypes = ["image/jpg", "image/png", "image/jpeg", "image/gif"];

  useEffect(()=>{
    setPreviewFile({ data: src, name:""})
  },[src])

  const imageValidations = (file) => {
    let errors = [];
    if(!file) return
    if (!imageTypes.includes(file.type)) {
      errors = [...errors, `Solo se admiten formatos jpg, png, jpeg, gif`];
    }
    const element = document.getElementById("logo-preview-img");
    if (
      element &&
      (element.naturalWidth > maxFileDimension.maxWidth ||
        element.naturalHeight > maxFileDimension.maxHeight)
    ) {
      errors = [...errors, `La dimensión máxima admitida es de ${maxFileDimension.maxWidth} x ${maxFileDimension.maxHeight}`];
    }
    return errors;
  };

  const availableFileType = {
    image: (fileData) => imageValidations(fileData),
  };
  const handleErrors = () => {
    let errors = []
    if (isEmpty(fileData)){
        errors = ["Campo requerido"]
    }else{
        errors = availableFileType[fileType](fileData);
    }
    return errors
  }

  const chargeImagePreview = (file) => {
    const reader = new FileReader();
    reader.onload = function () {
      setPreviewFile({
        data: reader.result,
        name: file.name,
      });
    };
    reader.readAsDataURL(file);
  };
  const uploadFile = (ev) => {
    if (isEmpty(ev.target.files) || ev.target.files.length < 1) return;
    const file = ev.target.files[0];
    chargeImagePreview(file);
    setFileData(file);
  };

  const uploadFilePaste = (ev) => {
    if (isEmpty(ev.clipboardData.files) || ev.clipboardData.files.length < 1) return;
    const file = ev.clipboardData.files[0];
    chargeImagePreview(file);
    setFileData(file);
  };

  const deleteImageData = () => {
    setFileData(null);
    setPreviewFile({
      data: src, name:""
  });
  };

  return {
    previewFile,
    fileData,
    uploadFile,
    uploadFilePaste,
    deleteImageData,
    handleErrors
  };
};
