import isEmpty from 'is-empty';
import React from 'react'
import UrlHelper from '../../../../helpers/Url.helper';

const ReniecDetails = ({ reniecUserInfo, handleImagesToZoom }) => {

  const { date_expedition, birth_date, sex, height, district_address_birth, province_address_birth, country_address_birth, address, address_number, district_address, province_address, department_address, country_address, father_name, last_name_father, mother_name, last_name_mother, sign } = reniecUserInfo;

  const dateExpedition = `${date_expedition.slice(6, 8)}/${date_expedition.slice(4, 6)}/${date_expedition.slice(0, 4)}`;
  const birthDate = `${birth_date.slice(6, 8)}/${birth_date.slice(4, 6)}/${birth_date.slice(0, 4)}`;
  const newSex = sex === '1' ? 'M' : 'F';
  const addressBirth = `${district_address_birth} ${province_address_birth} ${country_address_birth}`;
  const newAddress = `${address} ${address_number} ${district_address} ${province_address} ${department_address} ${country_address}`;
  const fatherName = `${father_name} ${last_name_father}`;
  const motherName = `${mother_name} ${last_name_mother}`;
  const srcImage = !isEmpty(sign) ? `data:image/${sign.image_ext};base64, ${sign.data}` : 'default';

  return (
    <div className='right-resizable-user-info'>
      <HeaderSection reniecUserInfo={reniecUserInfo} handleImagesToZoom={handleImagesToZoom} />
      <div className='container-reniec-info'>
        <div className='separator-info'>
          <div className="option">
            <p>Fecha de emisión:</p>
            <p>{dateExpedition}</p>
          </div>
          <div className="option">
            <p>Fecha de nacimiento:</p>
            <p>{birthDate}</p>
          </div>
        </div>
        <div className='separator-info'>
          <div className="option">
            <p>Sexo:</p>
            <p>{newSex}</p>
          </div>
          <div className="option">
            <p>Altura:</p>
            <p>{`${height}cm`}</p>
          </div>
        </div>
        <div className="option">
          <p>Dirección de nacimiento:</p>
          <p>{addressBirth}</p>
        </div>
        <div className="option">
          <p>Dirección de residencia:</p>
          <p>{newAddress}</p>
        </div>
        <div className='separator-info'>
          <div className="option">
            <p>Nombre del padre:</p>
            <p>{fatherName}</p>
          </div>
          <div className="option">
            <p>Nombre de la madre:</p>
            <p>{motherName}</p>
          </div>
        </div>
        <div className="option">
          <p>Firma:</p>
          <img
            className="profile-image"
            src={srcImage}
            alt="profile image"
            onError={ev => UrlHelper.addDefaultSrcImage(ev)}
            onClick={() => handleImagesToZoom([{ src: srcImage, alt: 'sign image' }])}
          />
        </div>
      </div>
    </div>
  )
}

export default ReniecDetails

const HeaderSection = ({ reniecUserInfo, handleImagesToZoom }) => {

  const { photo, names, last_name_father, last_name_mother, dni_number, document_supportive_number } = reniecUserInfo;
  const fullName = `${names} ${last_name_father} ${last_name_mother}`;
  const srcImage = !isEmpty(photo) ? `data:image/${photo.image_ext};base64, ${photo.data}` : 'default';

  return (
    <div className="header-section">
      <img
        className="profile-image"
        src={srcImage}
        alt="profile image"
        onError={ev => UrlHelper.addDefaultSrcImage(ev)}
        onClick={() => handleImagesToZoom([{ src: srcImage, alt: 'profile image' }])}
      />
      <div className="text-right">
        <h2>{`${fullName}`}</h2>
        <div className="option">
          <p>Número de documento:</p>
          <p>{dni_number}</p>
        </div>
        <div className="option">
          <p>Número de documento de soporte:</p>
          <p>{document_supportive_number}</p>
        </div>
      </div>
    </div>
  );
};