import React, { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import { Button, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { buildSecureGetParams } from '../../../helpers/lib';
import { LEVELS_STATUS } from '../../../helpers/constants';
import moment from 'moment';
import Pagination from 'react-js-pagination';

const TableContainerBeta = (props, context) => {
  const {
    fetching,
    rows,
    loadLevels,
    filterConfig,
    setFilterConfig,
    filterSearch,
    setFilterSearch,
    downloadBasicLevels
  } = props;
  //let totalPages =  Math.ceil(filterConfig.totalCount / filterConfig.limit)
  let limit = filterConfig.limit;
  let totalItems = filterConfig.totalCount;
  let totalPages = Math.ceil(filterConfig.totalCount / limit);
  let currentPage = filterConfig.page;
  let delayTimer;
  let offset = (currentPage - 1) * limit + 1;
  let showingTo =
    currentPage * limit > totalItems ? totalItems : currentPage * limit;

  if (fetching) {
    return (
      <React.Fragment>
        <Col xs lg="8">
          <h1 style={{ textAlign: 'center', padding: 20 }}>
            {context.t('Loading')}
          </h1>
        </Col>
      </React.Fragment>
    );
  }

  const goToView = async id => {
    props.history.push(`/admin-levels/corporate/view/${id}`);
  };

  const buscar = e => {
    e.persist();
    let type = e.target.type;
    let name = e.target.name;
    if (name === 'reviewStatus') {
      type = 'text';
    }
    let value =
      type === 'number' || type === 'select-one'
        ? Number(e.target.value)
        : e.target.value;
    clearTimeout(delayTimer);
    delayTimer = setTimeout(function() {
      filterSearch[name] =
        type === 'number' || type === 'select-one'
          ? { in: value }
          : { includesInsensitive: value };
      filterConfig.page = 1;

      if (Number.isNaN(value) || value === 'ALL') {
        delete filterSearch[name];
      }
      console.log({ filterSearch });
      loadLevels(filterConfig, filterSearch);
    }, 1000);
  };
  const onChangeLimit = e => {
    e.persist();
    let name = e.target.name;
    let value = Number(e.target.value);
    filterConfig.limit = value;
    filterConfig.page = 1;
    loadLevels(filterConfig, filterSearch);
  };

  const changePage = num => {
    filterConfig.page = num;
    loadLevels(filterConfig, filterSearch);
  };

  const PaginationField = () => {
    return (
      <div className="row react-bootstrap-table-pagination">
        <div className="col-md-3 col-xs-3 col-sm-3 col-lg-3">
          <span className="react-bootstrap-table-pagination-total">
            {context.t(
              'Showing {offset} to {showingTo} of {totalCount} Results',
              {
                offset: offset,
                showingTo: showingTo,
                totalCount: filterConfig.totalCount
              }
            )}
          </span>
        </div>
        <div className="col-md-3 col-xs-3 col-sm-3 col-lg-3">
          <span className="react-bootstrap-table-pagination-limit">
            <b>Filtrar registros por pagina</b>
            <select onChange={onChangeLimit} defaultValue={limit}>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </span>
        </div>
        <div className="react-bootstrap-table-pagination-list col-md-4 col-xs-4 col-sm-4 col-lg-4">
          <Pagination
            innerClass="pagination react-bootstrap-table-page-btns-ul"
            itemClass="page-item"
            linkClass="page-link"
            activePage={currentPage}
            itemsCountPerPage={limit}
            totalItemsCount={totalItems || 0}
            pageRangeDisplayed={5}
            onChange={changePage.bind(this)}
          />
        </div>
        <div className="col-md-2 col-xs-2 col-sm-2 col-lg-2 text-right">
          <Button variant="success" onClick={() => downloadBasicLevels()}>
            {context.t('Download CSV')}
          </Button>
        </div>
      </div>
    );
  };

  const blacklistRows = {
    'OWN_LIST':'Usuario en lista propia',
    'LOCAL_PEP':'Usuario en PEP local',
    'EXTERNAL_PEP':'Usuario en PEP externo',
    'REQUESTED':'Usuario requisitoriado',
    'NEGATIVE_NEWS':'Usuario en noticias negativas',
    'RELATED_PEP':'Usuario en PEP relacionado',
    'OWN':'Usuario en lista propia',
    'LOCAL':'Usuario en PEP local',
    'EXTERNAL':'Usuario en PEP externo',
    'REQUESTED':'Usuario requisitoriado',
    'NEGATIVE':'Usuario en noticias negativas',
    'RELATED':'Usuario en PEP relacionado'
  }

  const blacklistFilter=(row)=>{
    const color=!empty(blacklistRows[row]) ? 'red' : ''
    return <div style={{ color: color }}>{blacklistRows[row]  || "Usuario sin adventencia"}</div>
  };
  
   return (
    <React.Fragment>
      <div className="table-banexcoin">
        <PaginationField />
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>Id del usuario</th>
              <th>
                <input
                  type="number"
                  name="banexcoinUserAccountId"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Account Id"
                />
              </th>
              <th>
                <select
                  name="userLevel"
                  onChange={e => {
                    buscar(e);
                  }}>
                  <option value="ALL">Todos los niveles actuales</option>
                  <option value="0">Nivel 0</option>
                  <option value="1">Nivel 1</option>
                  <option value="2">Nivel 2</option>
                  <option value="3">Nivel 3</option>
                  <option value="10">Nivel 10</option>
                  <option value="11">Nivel 11</option>
                  <option value="12">Nivel 12</option>
                  <option value="13">Nivel 13</option>
                  <option value="14">Nivel 14</option>
                </select>
              </th>
              <th>
                <input
                  type="text"
                  name="banexcoinUsername"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Nombre de usuario"
                />
              </th>
              <th>
                <input
                  type="text"
                  name="bnxEmail"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Email"
                />
              </th>
              <th>
                <input
                  type="text"
                  name="companyLegalName"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Nombre Legal o Razón Social"
                />
              </th>
              <th>
                <input
                  type="text"
                  name="taxpayerIdentificationNumber"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Número de identificación fiscal"
                />
              </th>
              <th>
                <input
                  type="text"
                  name="countryIncorporationName"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Pais"
                />
              </th>
              <th>
                <input
                  type="text"
                  name="city"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Ciudad"
                />
              </th>
              <th>
                <input
                  type="text"
                  name="state"
                  onChange={e => {
                    buscar(e);
                  }}
                  placeholder="Estado"
                />
              </th>
              <th>
                <select
                  name="reviewStatus"
                  onChange={e => {
                    buscar(e);
                  }}>
                  <option value="ALL">Todos</option>
                  <option value="ACCEPTED">Aceptada</option>
                  <option value="REJECTED">Rechazada</option>
                  <option value="INPROCCESS">Revisión en proceso</option>
                  <option value="NEW_RECORD">Nuevo registro pendiente</option>
                  <option value="INCORRECT_INFORMATION">
                    Informacion incorrecta/Rechazada
                  </option>
                  <option value="REVIEW_REQUESTED">Revisión Solicitada</option>
                </select>
              </th>
              <th className="created_at">Creado el</th>
              <th>Blacklist</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {!empty(rows) ? (
              rows.map((row, i) => {
                let levelName = LEVELS_STATUS.find(l => l.id == row.status);
                return (
                  <tr key={i} className={'rowstatus' + row.status}>
                    <td>{row.banexcoinUserid}</td>
                    <td>{row.banexcoinUserAccountId}</td>
                    <td>{row.userLevel}</td>
                    <td>{row.banexcoinUsername}</td>
                    <td>{row.bnxEmail.toUpperCase()}</td>
                    <td>{row.companyLegalName.toUpperCase()}</td>
                    <td>{row.taxpayerIdentificationNumber}</td>
                    <td>{row.countryIncorporationName}</td>
                    <td>{row.city.toUpperCase()}</td>
                    <td>{row.state.toUpperCase()}</td>
                    <td>{context.t(row.reviewStatus)}</td>
                    <td>
                      {moment(row.createdAt)
                        .format('DD-MM-YYYY h:mm:ss a')
                        .toString()}
                    </td>
                    <td>
                     {blacklistFilter(row.list)}
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => {
                          goToView(buildSecureGetParams({ id: row.id }));
                        }}>
                        {context.t('View')}
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <React.Fragment>
                <tr className="emptydata">
                  <td colSpan="12">No existen datos</td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </Table>
        <PaginationField />
      </div>
    </React.Fragment>
  );
};

TableContainerBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TableContainerBeta;
