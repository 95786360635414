import isEmpty from "is-empty";
import xlsx from "json-as-xlsx";
import { toastTypes } from "../../constants";
import ComponentsHelper from "../../helpers/Components.helper";
import { getAllLegals, getAllLogReniecById, getBanexcoinUserById, getComments, getDownloadAllLegals, getIncrementLegalUserById, getLegalUserById, getLogs, getRejectLegalUserById, postReturnCorporateInfoFields, postTrustPe, getCountries, postCorporateInfo, postReturnCorporateShareholderInfoFields, postCorporateShareholderInfo, postSendToIDM, postReniecRequest, postReniecLogRequest, postGeneralActionLog, updatedUserBlockStatus, deleteUserKYC } from "../FallServices/LegalServices";

class LegalController {
    static loadLegals = async ({
        userInfo,
        filterConfig,
        filterSearch,
        setLoading,
        setRows,
        setTotalRows,
        defineLevel,
        context
    }) => {
        setLoading(true);
        const result = await getAllLegals(userInfo.UserId, filterConfig, filterSearch);
        if (result) {
            const newResult = result.nodes.map(element => {
                return {
                    ...element,
                    userLevel: defineLevel(element.userLevel, element.reviewStatus)
                }
            })
            setRows(newResult);
            setTotalRows(result.totalCount);
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo obtener información') });
        }
        setLoading(false);
    }

    static downloadLegals = async ({ userInfo }) => { 
        const buildColumns = (data) => {
          const getKeys = Object.keys(data[0]);
          const columns = getKeys.map((name) => {
              return {
                  label: name,
                  value: (row) => row[name],
              };
          });
          return columns;
        };
        try {
          ComponentsHelper.toast({ type: toastTypes.info, message: 'Descargando...' })
          const response = await getDownloadAllLegals(userInfo.UserId);
          const currentdate = new Date();
          const datetime = `${currentdate.getDate()}_${currentdate.getMonth() + 1}_${currentdate.getFullYear()}_${currentdate.getHours()}_${currentdate.getMinutes()}`;
          const columns = buildColumns(response.data);
          const data = [
              {
                  sheet: "Clientes jurídicos",
                  columns,
                  content: response.data
              }
          ];
          const settings = {
              fileName: `corporate_levels_report_${datetime}`,
              extraLength: 10,
              writeOptions: {}
          };
          setTimeout(() => {
              xlsx(data, settings)
          }, 1);
        } catch (error) {
          ComponentsHelper.toast({ type: toastTypes.warn, message: 'Información no pudo ser accesada.' })
        }
      };

    static loadLegalUserInfoById = async ({
        userInfo,
        userId,
        resetUserInfoById,
        setRightLoading,
        setLevelBasicInfo,
        setShareholders,
        setLogReniecInfo,
        setBanexcoinUserInfo,
        setLogUser,
        setCountries,
        defineLevel,
        context
    }) => {
        setRightLoading(true);
        resetUserInfoById();
        const result = await getLegalUserById(userInfo.UserId, userId);
        if (result) {
            if (!isEmpty(result.level) && !isEmpty(result.level.nodes)) {
                let newBasicInfo = result.level.nodes[0];
                newBasicInfo.userLevel = defineLevel(newBasicInfo.userLevel, newBasicInfo.reviewStatus);
                setLevelBasicInfo(newBasicInfo);
                if (!isEmpty(result.shareholders.nodes)) {
                    setShareholders(result.shareholders.nodes);
                    const logReniec = await getAllLogReniecById(userInfo.UserId, result.level.nodes[0].banexcoinUserid);
                    if (logReniec) {
                        const newLogReniec = logReniec.map((log) => {
                            const description = log.description.split('::');
                            return {
                                ...log,
                                userId: description[2],
                                documentNumber: description[3],
                                reniec_response: JSON.parse(log.aditionalInfo).reniec_response
                            };
                        });
                        setLogReniecInfo(newLogReniec);
                    } else {
                        ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo obtener información de Reniec del usuario') });
                    }
                }
                if (!isEmpty(result.level.updateBy)) {
                    const resultUserBanex = await getBanexcoinUserById(userInfo.UserId, result.level.updateBy);
                    if (resultUserBanex) {
                        setBanexcoinUserInfo(resultUserBanex.banexcoinUser || {});
                    } else {
                        ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo obtener información del usuario') });
                    }
                }
            }
            const logLegalUserById = await getLogs(userInfo.UserId, userId);
            if (logLegalUserById) {
                const newLogLegalUserById = logLegalUserById.map((data, key) => {
                    return {
                        ...data,
                        position: key + 1
                    }
                })
                setLogUser(newLogLegalUserById)
            } else {
                ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo obtener el historial de cambios del usuario') });
            }
            const countries = await getCountries(userInfo.UserId);
            if (countries) {
                const newContry = countries.map(({ countryCode, countryName }) => {
                    return {
                        name: countryName,
                        value: countryCode
                    }
                })
                setCountries(newContry);
            } else {
                ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo obtener la lista de países') });
            }
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo obtener información') });
        }
        setRightLoading(false);
    }

    static trustPe = async ({
        userInfo,
        shareholders,
        username,
        accountId,
        userId,
        handleLoadLegalUserInfoById,
        context
    }) => {
        if (isEmpty(shareholders)) {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('La corporación debe tener al menos 1 representante legal') });
            return;
        }
        const body = {
            username: username,
            account_id: accountId,
            user_type: 'JURIDICO',
            id: userId
        }
        ComponentsHelper.toast({ type: toastTypes.info, message: context.t('Enviando...') });
        const result = await postTrustPe(userInfo.UserId, body);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Información enviada exitosamente') });
            handleLoadLegalUserInfoById();
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('La información no pudo ser procesada por TRUST_PE') });
        }
    }

    static incrementLegalUserById = async ({
        userInfo,
        userId,
        accountId,
        handleLoadLegalUserInfoById,
        setLoaderAccept,
        handleLoadLegals,
        context
    }) => {
        const result = await getIncrementLegalUserById(userInfo.UserId, userId, accountId);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Información enviada exitosamente') });
            handleLoadLegals();
            handleLoadLegalUserInfoById();
        }
        else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo guardar la información') });
        }
        setLoaderAccept(false)
    }

    //----- Por definir en una V2
    static rejectLegalUserById = async ({
        userInfo,
        userId,
        accountId,
        handleLoadLegalUserInfoById,
        setLoaderReject,
        context
    }) => {
        const result = await getRejectLegalUserById(userInfo.UserId, userId, accountId);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Información enviada exitosamente') });
            handleLoadLegalUserInfoById();
        }
        else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('No se pudo guardar la información') });
        }
        setLoaderReject(false)
    }

    static returnCorporateInfoFields = async ({
        userInfo,
        data,
        userId,
        message,
        handleLoadLegalUserInfoById,
        setLoaderIncorrect,
        context
    }) => {
        ComponentsHelper.toast({ type: toastTypes.info, message: context.t('Enviando...') });
        const result = await postReturnCorporateInfoFields(userInfo.UserId, data, userId, message);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Información enviada exitosamente') });
            handleLoadLegalUserInfoById();
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('La información no pudo ser procesada') });
        }
        setLoaderIncorrect(false)
    }

    static updateCorporateInfo = async ({
        userInfo,
        data,
        handleLoadLegalUserInfoById,
        context
    }) => {
        ComponentsHelper.toast({ type: toastTypes.info, message: context.t('Enviando...') });
        let dataForm = new FormData();
        Object.keys(data).forEach((item) => {
            dataForm.append(item, data[item]);
        });
        const result = await postCorporateInfo(userInfo.UserId, dataForm);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Información actualizada exitosamente') });
            handleLoadLegalUserInfoById();
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('La información no pudo ser actualizada') });
        }
    }

    static returnCorporateShareholderInfoFields = async ({
        userInfo,
        data,
        shareholderUserId,
        message,
        handleLoadLegalUserInfoById,
        setLoaderIncorrect,
        context
    }) => {
        ComponentsHelper.toast({ type: toastTypes.info, message: context.t('Enviando...') });
        const result = await postReturnCorporateShareholderInfoFields(userInfo.UserId, data, shareholderUserId, message);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Información enviada exitosamente') });
            handleLoadLegalUserInfoById();
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('La información no pudo ser procesada') });
        }
        setLoaderIncorrect(false)
    }

    static updateCorporateShareholderInfo = async ({
        userInfo,
        userId,
        data,
        handleLoadLegalUserInfoById,
        context
    }) => {
        ComponentsHelper.toast({ type: toastTypes.info, message: context.t('Enviando...') });
        data.userId = userId
        let dataForm = new FormData();
        Object.keys(data).forEach((item) => {
            dataForm.append(item, data[item]);
        });
        const result = await postCorporateShareholderInfo(userInfo.UserId, dataForm);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Información actualizada exitosamente') });
            handleLoadLegalUserInfoById();
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('La información no pudo ser actualizada') });
        }
    }

    static sendToIDM = async ({
        userInfo,
        shareholderUserId,
        setLoaderIdVerif,
        context
    }) => {
        const result = await postSendToIDM(userInfo.UserId, shareholderUserId);
        if (result) {
            ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Registro enviado a DIG_iD_VERIF exitosamente') });
        } else {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('Error al enviar DIG_iD_VERIF') });
        }
        setLoaderIdVerif(false)
    }

    static reniecRequest = async ({
        userInfo,
        currentImages,
        cropData,
        levelBasicInfo,
        handleLoadLegalUserInfoById,
        context
    }) => {
        let error_reniec = null;
        try {
            const body = {
                path: '/api/v1/identity/biometrics/faceverify',
                provider: 'RENIECPROD',
                country: currentImages.document_country,
                dni: currentImages.document_number,
                ipCliente: '192.168.5.0',
                macCliente: 'D4:C9:EF:D6:4C:0C',
                facialCapture: currentImages.url,
                cropData
            };
            ComponentsHelper.toast({ type: toastTypes.info, message: context.t('Enviando...') });
            const reniec_result = await postReniecRequest(userInfo.UserId, body)
                .catch(
                    e => {
                        if (e.hasOwnProperty('response') && !isEmpty(e.response.data)) {
                            error_reniec = e.response.data.errors;
                        } else if (e.hasOwnProperty('response') && e.response.data.includes('Gateway')) {
                            throw e.response.data;
                        }
                    }
                );

            let log = {
                description: `USER::CORPORATE::${levelBasicInfo.banexcoinUserid}::${currentImages.document_number}`,
                aditional_info: {
                    reniec_response: {
                        validated: false,
                        reason: !isEmpty(error_reniec) ? error_reniec : 'Imagen facial capturada no es válida',
                        result: JSON.stringify(reniec_result)
                    }
                },
                id: levelBasicInfo.id
            };

            if (!isEmpty(reniec_result) && !reniec_result.hasOwnProperty('error')) {
                log.aditional_info.reniec_response.reason = '';
                log.aditional_info.reniec_response.validated = true;
            }

            const res = await postReniecLogRequest(userInfo.UserId, log).catch(e => {
                throw e;
            });

            if (!isEmpty(res.id)) {
                ComponentsHelper.toast({ type: toastTypes.success, message: context.t('Enviado a RENIEC exitosamente') });
                await handleLoadLegalUserInfoById();
            }
        } catch (error) {
            ComponentsHelper.toast({ type: toastTypes.warn, message: context.t('Reniec service is down') });
            const data = {
                description: `USER::${levelBasicInfo.banexcoinUserId}`,
                aditional_info: {
                    server_error: error
                },
                type: 'SERVER_ERROR',
                id: levelBasicInfo.id
            };
            await postGeneralActionLog(userInfo.UserId, data);
            await handleLoadLegalUserInfoById();
        }
    }

    static updatedUserBlockStatus = async ({ userInfo, type, data, email, inputs = {}  }) => {
        try {  
            ComponentsHelper.toast({ type: toastTypes.info, message: `Ejecutando ${type === "lock" ? "bloqueo" : "desbloqueo"} de usuario.` });

            const response = await updatedUserBlockStatus(userInfo.UserId,data,type)

            if(isEmpty(response.data) || response.data.message !== "success") return false

            if(type === "unlock" || inputs.userLockReason !== "Baja Voluntaria") return true
            
            await deleteUserKYC(userInfo.UserId, data.kycUserId, {
                email,
                BanexcoinUsername:data.banexcoinUsername
            })
            return true
        } catch (error) {
            ComponentsHelper.toast({
              type: toastTypes.warn,
              message: "No se pudo ejecutar la acción. Por favor vuelva a intentarlo.",
            });
            return false;
          }
    }
}

export default LegalController;