import isEmpty from "is-empty";
import React, { useState } from "react";
import { CURRENT_LEVEL } from "../../../constants/components/Levels/currentLevel";
import { CustomButton } from "../CustomButton/CustomButton";
import InputElement from "../InputElement/InputElement";

function AddForm({
  loading,
  buttonProps,
  handleSubmitInputs,
  handleAddDepositLimitModal,
}) {
  const [inputs, setInputs] = useState({
    daily: 0,
    monthly: 0,
  });
  const [errors,setErrors] = useState({})

  const onDefaultChange = (target) => {
    setInputs({
      ...inputs,
      [target.name]: target.value,
    });
    delete errors[target.name]
  };

  const customErrors = () => {
    let errors = {}
    if(isEmpty(inputs.daily)){
      errors.daily = "Campo requerido"
    }else if(inputs.daily < 1){
      errors.daily = "El monto debe ser mayor a 0"
    }
    if(isEmpty(inputs.monthly)){
      errors.monthly = "Campo requerido"
    }else if(inputs.monthly < 1){
      errors.monthly = "El monto debe ser mayor a 0"
    }
    if(!inputs.level & inputs.level !== 0){
      errors.level = "Campo requerido"
    }
    setErrors(errors)
    return errors
  }

  const handleAddNewLimit = () => {
    const errors = customErrors()
    if(!isEmpty(errors)) return;
    handleSubmitInputs(inputs)
  }
  const dailyInputProps = {
    values: {
      title: "Umbral de transacción única",
      value: inputs.daily || "",
      name: "daily",
      type: "number",
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.daily,
  };
  const monthlyInputProps = {
    values: {
      title: "Umbral de transacción múltiple",
      value: inputs.monthly || "",
      name: "monthly",
      type: "number",
      required: true,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.monthly,
  };
  const levelInputProps = {
    values: {
      title: "Elige nivel",
      value: inputs.level || "",
      name: "level",
      type: "select",
      required: true,
      options: CURRENT_LEVEL.map((item) => ({ ...item, name:`${item.title} (Nivel ${item.value})` })).filter(item => item.value !== 100 && item.value > 0),
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.level,
  };

  return (
    <div>
      <div className="add-modal-inputs">
        <InputElement {...levelInputProps} />
        <InputElement {...dailyInputProps} />
        <InputElement {...monthlyInputProps} />
      </div>
      <div className="container-submit-cancel-buttons">
        <CustomButton
          onClick={handleAddDepositLimitModal}
          className="red"
          text="Cancelar"
          disabled={loading}
          showLoader={false}
        />
        <CustomButton
          {...buttonProps}
          onClick={handleAddNewLimit}
          disabled={loading}
        />
      </div>
    </div>
  );
}

export default AddForm;
